import { DebtTranDatum, SalesOrderDatum, IQRepMonthSummary, IQSaleSummary, GraphData } from '../interfaces/statistics-models';
import { DaySummaryDatum, DebtTransactionCodes, MonthSummaryDatum, StatisticsFilterCodes } from '../interfaces';
import { groupByKeyGetter, isObjectEmpty, toFirebaseTimestamp, toMoment } from './static.functions';
import { DateSettings, OrganizationSettings } from '../interfaces/fe-models/organization-models';
// import * as everyMoment from '@newgenus/every-moment';
// import * as Moment from 'moment';
// const moment = everyMoment.extendMoment(Moment as any);
import Moment from 'moment';
import extendMoment from '@newgenus/every-moment';
const moment = extendMoment(Moment as any);

/**
 * This will return the month range with the offset of GMT+2 for IQ's server.
 *
 * @export
 * @param {Date} date The current date, or the date to be used as the base for the month range.
 * @param {OrganizationSettings} settings The organization settings, to determine what is the start and end of month.
 * @param {string} [offset='+02:00'] Defaulted to GMT+2, as IQ is hosted in South Africa. The offset must be compatible with moment.js.
 * @return {*}  {{ fromDate: moment.Moment, toDate: moment.Moment }} The month range.
 */
export function getCompanyMonthStartAndEnd(date: Date, settings: OrganizationSettings, offset = '+02:00'): { monthStart: moment.Moment, monthEnd: moment.Moment } {
    const presentDayOfMonth = moment(date).date();
    const isStartOfMonthAfterEndOfMonth = settings.dayOfMonthStart > settings.dayOfMonthEnd;
    const isStartOfMonthSameAsEndOfMonth = settings.dayOfMonthStart == settings.dayOfMonthEnd;
    let monthStart = moment(date), monthEnd = moment(date);

    if (isStartOfMonthSameAsEndOfMonth) {

        if (presentDayOfMonth >= settings.dayOfMonthStart) {
            monthEnd = monthEnd
                .add(1, 'month');
        } else {
            monthStart = monthStart
                .subtract(1, 'month');
        }

    } else if (isStartOfMonthAfterEndOfMonth) {
        if (presentDayOfMonth >= settings.dayOfMonthStart) {
            monthEnd = monthEnd
                .add(1, 'month');

        } else {
            monthStart = monthStart
                .subtract(1, 'month');
        }

    } else { // Start of month before end of month 
        // Do nothing further here...
    }


    monthStart
        .atOrNearestToDayOfMonth(settings.dayOfMonthStart)
        .utcOffset(offset)
        .set('hour', settings.hourOfDayStart)
        .startOf('hour');

    monthEnd = monthEnd
        .atOrNearestToDayOfMonth(settings.dayOfMonthEnd)
        .utcOffset(offset)
        .set('hour', settings.hourOfDayEnd)
        .startOf('hour');

    return { monthStart, monthEnd };
}

export function getCompanyMonthStartAndEndByDateSettings(date: Date, settings: DateSettings, offset = '+02:00'): { monthStart: moment.Moment, monthEnd: moment.Moment } {
    const existingProfileMonths = tryFindMonthInCycle(date, settings);

    let monthStart: moment.Moment, monthEnd: moment.Moment;

    // Try match to the monthsOfYear array.
    if (existingProfileMonths && existingProfileMonths.length > 0 && settings) {

        // Find the relevant month. If the month's start and end date are the same, eg 15-15th, then we choose the month that's closest to the date.
        // For example, january 15th will select the month December 15th - January 15th. If the date is the 16th of January, it will select January 15th - February 15th.
        const nearestMonth = existingProfileMonths.reduce((prev, curr) => {
            const prevDiff = Math.abs(toMoment(prev.value.monthEnd.monthEndDate).diff(date));
            const currDiff = Math.abs(toMoment(curr.value.monthEnd.monthEndDate).diff(date));
            return prevDiff < currDiff ? prev : curr;
        }, existingProfileMonths[0]);

        monthStart = toMoment(nearestMonth.value.monthStart.monthStartDate)
            .set('hour', settings?.hourOfDayStart)
            .startOf('hour');
        monthEnd = toMoment(nearestMonth.value.monthEnd.monthEndDate)
            .set('hour', settings?.hourOfDayEnd)
            .startOf('hour');

    } else { // Do the regular thing.
        const result = getCompanyMonthStartAndEnd(date, settings, offset);
        monthStart = result.monthStart;
        monthEnd = result.monthEnd;
    }

    return { monthStart, monthEnd };
}

function tryFindMonthInCycle(date: Date, settings: DateSettings) {
    if (date && settings?.monthsOfYear && settings.cycle) {

        switch (settings.cycle.type) {
            case 'any days':
                return settings.monthsOfYear?.filter(month => {
                    return toMoment(month.value.monthEnd.monthEndDate).isSame(date, 'day');
                });

            case 'next business day':
                return settings.monthsOfYear?.filter(month => {
                    if (month.value.monthEnd.custom) {
                        return toMoment(month.value.monthEnd.monthEndDate).isSame(date, 'day');
                    } else {
                        return toMoment(month.value.monthEnd.monthEndNextBusinessDate).isSame(date, 'day');
                    }
                });

            case 'previous business day':
                return settings.monthsOfYear?.filter(month => {
                    if (month.value.monthEnd.custom) {
                        return toMoment(month.value.monthEnd.monthEndDate).isSame(date, 'day');
                    } else {
                        return toMoment(month.value.monthEnd.monthEndPrevBusinessDate).isSame(date, 'day');
                    }
                });

            default: break;
        }
    }

    return undefined;
    // return settings.monthsOfYear?.filter(month => toMoment(month.value.monthEnd.monthEndDate).isSame(date, 'day'));
}

export function atCompanyStartOfMonth(month: moment.Moment | Date, settings: OrganizationSettings): moment.Moment {
    const isStartOfMonthAfterEndOfMonth = settings.dayOfMonthStart > settings.dayOfMonthEnd;
    const isStartOfMonthSameAsEndOfMonth = settings.dayOfMonthStart == settings.dayOfMonthEnd;
    let monthStart = moment(month);
    const presentDayOfMonth = moment(month).date();

    if (isStartOfMonthSameAsEndOfMonth) {

        if (presentDayOfMonth >= settings.dayOfMonthStart) {
            // Do nothing further here...
        } else {
            monthStart = monthStart
                .subtract(1, 'month');
        }

    } else if (isStartOfMonthAfterEndOfMonth) {
        if (presentDayOfMonth >= settings.dayOfMonthStart) {
            // Do nothing further here...
        } else {
            monthStart = monthStart
                .subtract(1, 'month');
        }

    } else { // Start of month before end of month 
        // Do nothing further here...
    }


    return monthStart
        .atOrNearestToDayOfMonth(settings.dayOfMonthStart)
        .startOf('date')
        .hour(settings.hourOfDayStart);
}

export function atCompanyEndOfMonth(month: moment.Moment | Date, settings: OrganizationSettings): moment.Moment {
    const isStartOfMonthAfterEndOfMonth = settings.dayOfMonthStart > settings.dayOfMonthEnd;
    const isStartOfMonthSameAsEndOfMonth = settings.dayOfMonthStart == settings.dayOfMonthEnd;
    let monthEnd = moment(month);
    const presentDayOfMonth = moment(month).date();

    if (isStartOfMonthSameAsEndOfMonth) {

        if (presentDayOfMonth >= settings.dayOfMonthStart) {
            monthEnd = monthEnd.add(1, 'month');
        } else {
            // Do nothing further here...
        }

    } else if (isStartOfMonthAfterEndOfMonth) {

        if (presentDayOfMonth >= settings.dayOfMonthStart) {
            monthEnd = monthEnd.add(1, 'month');
        } else {
            // Do nothing further here...
        }

    } else { // Start of month before end of month 
        // Do nothing further here...
    }

    // monthEnd = atOrNearestToDate(monthEnd, settings.dayOfMonthEnd);
    monthEnd = monthEnd
        .atOrNearestToDayOfMonth(settings.dayOfMonthEnd)
        .startOf('date')
        .hour(settings.hourOfDayEnd);

    return monthEnd;
}

export function fromMonthlySummaryFieldToCode(code: string): DebtTransactionCodes | null {
    switch (code) {
        case 'bankChargesTax':
        case 'bankCharges':
            return 'BC';
        case 'creditNoteTax':
        case 'creditNote':
            return 'CN';
        case 'discountTax':
        case 'discount':
            return 'DS';
        case 'interestChargeTax':
        case 'interestCharge':
            return 'IT';
        case 'invoiceTax':
        case 'invoice':
            return 'IN';
        case 'journalCreditTax':
        case 'journalCredit':
            return 'JC';
        case 'journalDebitTax':
        case 'journalDebit':
            return 'JD';
        case 'paymentTax':
        case 'payment':
            return 'PM';
        case 'rebatesTax':
        case 'rebates':
            return 'RE';
        case 'refundTax':
        case 'refund':
            return 'RF';
        default:
            return null;
    }

}

export function toMonthlySummaryField(code: string): keyof MonthSummaryDatum {
    switch (code) {
        case 'BC':
            return 'bankCharges';
        case 'CN':
            return 'creditNote';
        case 'DS':
            return 'discount';
        case 'IN':
            return 'invoice';
        case 'IT':
            return 'interestCharge';
        case 'JC':
            return 'journalCredit';
        case 'JD':
            return 'journalDebit';
        case 'PM':
            return 'payment';
        case 'RE':
            return 'rebates';
        case 'RF':
            return 'refund';
        default:
            throw new Error('Invalid code type');
    }
}

export function toMonthlySummaryTaxField(code: string): keyof MonthSummaryDatum {
    switch (code) {
        case 'BC':
            return 'bankChargesTax';
        case 'CN':
            return 'creditNoteTax';
        case 'DS':
            return 'discountTax';
        case 'IN':
            return 'invoiceTax';
        case 'IT':
            return 'interestChargeTax';
        case 'JC':
            return 'journalCreditTax';
        case 'JD':
            return 'journalDebitTax';
        case 'PM':
            return 'paymentTax';
        case 'RE':
            return 'rebatesTax';
        case 'RF':
            return 'refundTax';
        default:
            throw new Error('Invalid code type');
    }
}

export function groupSalesIntoSummaries(data: Array<DebtTranDatum | SalesOrderDatum>, sqlDateStr: string): IQRepMonthSummary {
    const groupedRecords = groupByKeyGetter(data, x => x.collection === 'debtTran' ? (x.rep || x.user) : x.rep);
    const parsedRecords: IQRepMonthSummary = {};

    for (const [repNum, values] of groupedRecords) {

        // Create base summary, with zero numeric value - this will be iterated over in the next step.
        const dateSummary = newIqSummaryByDate();
        dateSummary['debtTran-count'] = values?.filter(x => x.collection === 'debtTran')?.length || 0;
        dateSummary['salesOrder-count'] = values?.filter(x => x.collection === 'salesOrder')?.length || 0;
        dateSummary['total-count'] = values?.length || 0;

        // Iterate over all records, and add to the summary and codeTypeSummary.
        values.forEach(c => {

            if (c.collection === 'salesOrder') { // TODO "collection = salesOrder" is deprecated...
                dateSummary['salesOrder-gross'] += (c.total * c.currencyrate);
                dateSummary['salesOrder-nett'] += (c.total * c.currencyrate) - (c.totalvat * c.currencyrate);
                dateSummary['salesOrder-tax'] += (c.totalvat * c.currencyrate);
                // dateSummary.codeTypeSummary['salesOrder-open-nett'] += (c.total * c.currencyrate) - (c.totalvat * c.currencyrate);
                dateSummary.codeTypeSummary['salesOrder-open'] += (c.total * c.currencyrate);

            } else if (c.collection === 'debtTran') {
                dateSummary['debtTran-credits'] += c.credit;
                dateSummary['debtTran-creditTax'] += c.creditTax;
                dateSummary['debtTran-debits'] += c.debit;
                dateSummary['debtTran-debitTax'] += c.debitTax;
                dateSummary['debtTran-profit'] += c.profit;
                dateSummary.codeTypeSummary['debtTran-' + c.type] += c.amount;

                // if (c.type === 'IN') {
                if (c.dc === 'D') {
                    dateSummary.codeTypeTax['debtTran-' + c.type] += c.debitTax;
                } else {
                    dateSummary.codeTypeTax['debtTran-' + c.type] += c.creditTax;
                }
            }

        });

        // Append the dateSummary to the repDocData, at the date of this transaction.
        parsedRecords[repNum] = { [sqlDateStr]: dateSummary };
    }
    return parsedRecords;
}

export function groupDailySummariesIntoRepSummaries(data: Array<DaySummaryDatum>, sqlDateStr: string, includeClosedSalesOrdersInAmounts = false, roundFigures = true): IQRepMonthSummary {
    const groupedRecords = groupByKeyGetter(data, x => x.userKey);
    const parsedRecords: IQRepMonthSummary = {};

    for (const [repNum, values] of groupedRecords) {

        // Create base summary, with zero numeric value - this will be iterated over in the next step.
        const dateSummary = newIqSummaryByDate();

        // Iterate over all records, and add to the summary and codeTypeSummary.
        values.forEach(tx => {

            if (tx.type === 'sales_o' || tx.type === 'open_so') {
                if (tx.type === 'open_so' || includeClosedSalesOrdersInAmounts) {
                    dateSummary['salesOrder-gross'] += tx.salesOrderGross;
                    dateSummary['salesOrder-nett'] += tx.salesOrderNett;
                    dateSummary['salesOrder-tax'] += tx.salesOrderTax;
                }

                if (tx.type === 'open_so') {
                    dateSummary.codeTypeSummary['salesOrder-open'] += tx.nettOutstanding;
                } else if (tx.type === 'sales_o') {
                    dateSummary.codeTypeSummary['salesOrder-closed'] += tx.salesOrderGross;
                    dateSummary.codeTypeTax['salesOrder-closed'] += tx.salesOrderTax;
                }

            } else if (tx.type === 'invoice' || tx.type === 'debtTran') {
                dateSummary['debtTran-credits'] += tx.creditNoteGross;
                dateSummary['debtTran-creditTax'] += tx.creditNoteTax;
                dateSummary['debtTran-debits'] += tx.invoicedGross;
                dateSummary['debtTran-debitTax'] += tx.invoicedTax;
                // dateSummary['debtTran-profit'] += tx.profit;


                // Debit transactions: "IN - D", "RF - D", "JD - D", "IT - D"
                if (tx.transactionType === 'IN' || tx.transactionType === 'RF' || tx.transactionType === 'JD' || tx.transactionType === 'IT') {
                    dateSummary.codeTypeSummary['debtTran-' + tx.transactionType] += tx.invoicedGross;
                    dateSummary.codeTypeTax['debtTran-' + tx.transactionType] += tx.invoicedTax;
                }

                // Credit transactions: "CN - C", "JC - C", "BC - C", "RE - C", "PM - C",  "DS - C"
                else if (tx.transactionType === 'CN' || tx.transactionType === 'JC' || tx.transactionType === 'BC' || tx.transactionType === 'RE' || tx.transactionType === 'PM' || tx.transactionType === 'DS') {
                    dateSummary.codeTypeSummary['debtTran-' + tx.transactionType] += tx.creditNoteGross;
                    dateSummary.codeTypeTax['debtTran-' + tx.transactionType] += tx.creditNoteTax;
                }

            }

        });

        // Calculate the gross amount from the nett amount.
        // First, save the nettAmount for later.
        const nettAmount = dateSummary.codeTypeSummary['salesOrder-open'] + 0;
        // Calculate the gross amount from the nett amount.
        dateSummary.codeTypeSummary['salesOrder-open'] = (dateSummary.codeTypeSummary['salesOrder-open'] * 1.15); // Vat rate is 15% for IQ/RSA.
        // Open sales order tax would be 15% of the open sales order amount, in other words the difference between the nett and gross amount.
        dateSummary.codeTypeTax['salesOrder-open'] = dateSummary.codeTypeSummary['salesOrder-open'] - nettAmount;

        // Append the dateSummary to the repDocData, at the date of this transaction.
        parsedRecords[repNum] = { [sqlDateStr]: dateSummary };
    }

    // Round all numbers up to 2 decimal places.
    if (roundFigures) {
        for (const repNum in parsedRecords) {
            for (const dateStr in parsedRecords[repNum]) {
                for (const key in parsedRecords[repNum][dateStr]) {
                    if (typeof parsedRecords[repNum][dateStr][key] === 'number') {
                        parsedRecords[repNum][dateStr][key] = +Number(parsedRecords[repNum][dateStr][key]).toFixed(2)
                        // parsedRecords[repNum][dateStr][key] = Math.round(parsedRecords[repNum][dateStr][key] * 100) / 100;
                    }
                }
            }
        }
    }

    return parsedRecords;
}

export function parseMonthlySummariesIntoRepSummaries(groupedData: Map<string, Array<MonthSummaryDatum>>, sqlDateStr: string, filtersByCode: Record<string, boolean> | null, roundFigures = true): IQRepMonthSummary {
    // Collect the fields to read from the summary data.
    const { debitFields, creditFelids, debitTaxFields, creditTaxFelids } = extractRelevantFieldsToReadFromSummaries(filtersByCode);

    const parsedRecords: IQRepMonthSummary = {};

    for (const [repNum, values] of groupedData) {

        // Create base summary, with zero numeric value - this will be iterated over in the next step.
        const dateSummary = newIqSummaryByDate();

        // Iterate over all records, and add to the summary and codeTypeSummary.
        values.forEach(summary => {
            // Rules:
            // Nett Sales = debits - debitTax - credits + creditTax.
            // Gross = debits.
            // Credits Notes = credits - creditTax.
            // Nett Sales and value are shared at the moment.

            // Extract Debit values:
            debitFields.forEach(field => {
                if (typeof summary[field] === 'number') {
                    // Gross
                    dateSummary['debtTran-debits'] += (summary[field] || 0) as number;
                }
            });

            // Extract Debit Tax values:
            debitTaxFields.forEach(field => {
                if (typeof summary[field] === 'number') {
                    // Tax
                    dateSummary['debtTran-debitTax'] += (summary[field] || 0) as number;
                    // dateSummary['debtTran-debits'] += (summary[field] || 0) as number;
                }
            });

            // Extract Credit values:
            creditFelids.forEach(field => {
                if (typeof summary[field] === 'number') {
                    // Gross
                    dateSummary['debtTran-credits'] += (summary[field] || 0) as number;
                }
            });

            // Extract Credit Tax values:
            creditTaxFelids.forEach(field => {
                if (typeof summary[field] === 'number') {
                    // Tax
                    dateSummary['debtTran-creditTax'] += (summary[field] || 0) as number;
                    // dateSummary['debtTran-debits'] += (summary[field] || 0) as number;
                }
            });

            // Calculate the code type summaries.
            Object.entries(summary).forEach(([fieldKey, value]) => {

                const code = fromMonthlySummaryFieldToCode(fieldKey);

                // One results per iteration will always be null - for the field "date".
                if (code) {
                    // Add tax to codeTypeTax.
                    if (fieldKey.toLowerCase().includes('tax'))
                        dateSummary.codeTypeTax['debtTran-' + code] += value;
                    // Add value to codeTypeSummary.
                    else dateSummary.codeTypeSummary['debtTran-' + code] += value;
                }
            });

        });

        // Append the dateSummary to the repDocData, at the date of this transaction.
        parsedRecords[repNum] = { [sqlDateStr]: dateSummary };
    }

    // Round all numbers up to 2 decimal places.
    if (roundFigures) {
        for (const repNum in parsedRecords) {
            for (const dateStr in parsedRecords[repNum]) {
                for (const key in parsedRecords[repNum][dateStr]) {
                    if (typeof parsedRecords[repNum][dateStr][key] === 'number') parsedRecords[repNum][dateStr][key] = +Number(parsedRecords[repNum][dateStr][key]).toFixed(2)
                }
            }
        }
    }

    return parsedRecords;
}

export function extractRelevantFieldsToReadFromSummaries(filtersByCode: Record<string, boolean> | null) {
    const debitFields: Array<keyof MonthSummaryDatum> = [], creditFelids: Array<keyof MonthSummaryDatum> = [];
    const debitTaxFields: Array<keyof MonthSummaryDatum> = [], creditTaxFelids: Array<keyof MonthSummaryDatum> = [];
    // const mockFilters = {
    //     "debtTran-BC": false,
    //     "debtTran-CN": true,
    //     "debtTran-DS": false,
    //     "debtTran-IN": true,
    //     "debtTran-IT": false,
    //     "debtTran-JC": false,
    //     "debtTran-JD": false,
    //     "debtTran-PM": false,
    //     "debtTran-RE": false,
    //     "debtTran-RF": false,
    //     "salesOrder-open": false
    // };
    // filtersByCode = mockFilters;
    const useAllFields = filtersByCode === null || isObjectEmpty(filtersByCode) || Object.values(filtersByCode).every(x => x === true);

    // For the filter to apply, it must be non-null and at least one of the values must be false.
    if (!useAllFields && filtersByCode && Object.values(filtersByCode).some(f => f === false)) {
        // List only filtered fields where the filter = true.
        Object.entries(filtersByCode).forEach(([key, value]) => {

            // Only accept the filter if it is true and only for debtTran.
            if (value === true && key.startsWith('debtTran')) {
                const transactionCode = key.split('-')[1];
                appendTransactionFieldByCode(transactionCode);
            }

        });
    } else if (useAllFields) {
        // List all debtTran codes and their corresponding fields.
        const allCodes = ['BC', 'CN', 'DS', 'IN', 'IT', 'JC', 'JD', 'PM', 'RE', 'RF'];

        allCodes.forEach(transactionCode => {
            appendTransactionFieldByCode(transactionCode);
        });

    } else {
        // No fields accepted, do nothing...
    }

    function appendTransactionFieldByCode(transactionCode: string) {
        const grossKey = toMonthlySummaryField(transactionCode);
        const taxKey = toMonthlySummaryTaxField(transactionCode);
        // Debit transactions: "IN - D", "RF - D", "JD - D", "open - D" (sales orders = D), "IT - D"
        if (['IN', 'RF', 'JD', 'open', 'IT'].includes(transactionCode)) {
            debitFields.push(grossKey);
            debitTaxFields.push(taxKey);
        }

        // Credit transactions: "CN - C", "JC - C", "BC - C", "RE - C", "PM - C",  "DS - C"
        if (['CN', 'JC', 'BC', 'RE', 'PM', 'DS'].includes(transactionCode)) {
            creditFelids.push(grossKey);
            creditTaxFelids.push(taxKey);
        }
    }

    return {
        debitFields,
        creditFelids,
        debitTaxFields,
        creditTaxFelids
    };
}

export function parseSummaryDataToGraphData(summaryData: MonthSummaryDatum[], debitFields: (keyof MonthSummaryDatum)[], debitTaxFields: (keyof MonthSummaryDatum)[], creditFelids: (keyof MonthSummaryDatum)[], creditTaxFelids: (keyof MonthSummaryDatum)[]) {
    return summaryData.map(c => {

        // Rules:
        // Nett Sales = debits - debitTax - credits + creditTax.
        // Gross = debits.
        // Sales = debits - debitTax.
        // Credits Notes = credits - creditTax.
        // Nett Sales and value are shared at the moment.
        const entry = {
            date: toFirebaseTimestamp(c.date).toDate(),
            key: '',
            repKey: c.repKey || -1,
            repKeys: [], // TODO include in summary data.
            credits: 0,
            gross: 0,
            nett: 0,
            sales: 0,
            value: 0,
            valueCompounded: 0,
        } as GraphData;

        // Extract Debit values:
        debitFields.forEach(field => {
            if (entry.sales !== undefined && typeof c[field] === 'number') {
                entry.gross += (c[field] || 0) as number;

                // Nett Sales = debits - debitTax - credits + creditTax.
                entry.nett += (c[field] || 0) as number;
                entry.value += (c[field] || 0) as number;

                // Sales = debits - debitTax.
                entry.sales += (c[field] || 0) as number;
            }
        });

        // Extract Debit Tax values:
        debitTaxFields.forEach(field => {
            if (entry.sales !== undefined && typeof c[field] === 'number') {
                // entry.gross -= (c[field] || 0) as number
                // Nett Sales = debits - debitTax - credits + creditTax.
                entry.nett -= (c[field] || 0) as number;
                entry.value -= (c[field] || 0) as number;

                // Sales = debits - debitTax.
                entry.sales -= (c[field] || 0) as number;
            }
        });

        // Extract Credit values:
        creditFelids.forEach(field => {
            if (entry.sales !== undefined && entry.credits !== undefined && typeof c[field] === 'number') {
                // Credits Notes = credits - creditTax.
                entry.credits += (c[field] || 0) as number;

                // Nett Sales = debits - debitTax - credits + creditTax.
                entry.nett -= (c[field] || 0) as number;
                entry.value -= (c[field] || 0) as number;
            }
        });

        // Extract Credit Tax values:
        creditTaxFelids.forEach(field => {
            if (entry.sales !== undefined && entry.credits !== undefined && typeof c[field] === 'number') {
                // Credits Notes = credits - creditTax.
                entry.gross += (c[field] || 0) as number;

                // Nett Sales = debits - debitTax - credits + creditTax.
                entry.nett += (c[field] || 0) as number;
                entry.value += (c[field] || 0) as number;
            }
        });

        return entry;
    });
}

export const removeWeekendsAndPublicHolidays = (d: Date, weekends: number[]) => {
    // weekends = [0, 6] for Saturday and Sunday.
    return !weekends.includes(d.getDay());
}

export const onlyBusinessDays = (d: Date) => {
    return moment(d).isBusinessDay();
}

function newIqSummaryByDate() {
    return {
        ['debtTran-count']: 0,
        ['debtTran-debits']: 0,
        ['debtTran-credits']: 0,
        ['debtTran-profit']: 0,
        ['debtTran-debitTax']: 0,
        ['debtTran-creditTax']: 0,

        ['salesOrder-count']: 0,
        ['salesOrder-gross']: 0,
        ['salesOrder-nett']: 0,
        ['salesOrder-tax']: 0,

        ['total-count']: 0,
        ['total-debits']: 0,
        ['total-credits']: 0,
        ['total-profit']: 0,
        ['total-debitTax']: 0,
        ['total-creditTax']: 0,

        // Create a codeTypeSummary (summarizes values of the different transaction types), with zero numeric value - this will be iterated over in the next step.
        codeTypeSummary: {
            ['debtTran-BC']: 0,
            ['debtTran-CN']: 0,
            ['debtTran-DS']: 0,
            ['debtTran-IN']: 0,
            ['debtTran-IT']: 0,
            ['debtTran-JC']: 0,
            ['debtTran-JD']: 0,
            ['debtTran-PM']: 0,
            ['debtTran-RE']: 0,
            ['debtTran-RF']: 0,
            // ['salesOrder-open-nett']: 0,
            ['salesOrder-open']: 0,
            ['salesOrder-closed']: 0,
        },
        codeTypeTax: {
            ['debtTran-BC']: 0,
            ['debtTran-CN']: 0,
            ['debtTran-DS']: 0,
            ['debtTran-IN']: 0,
            ['debtTran-IT']: 0,
            ['debtTran-JC']: 0,
            ['debtTran-JD']: 0,
            ['debtTran-PM']: 0,
            ['debtTran-RE']: 0,
            ['debtTran-RF']: 0,
            // ['salesOrder-open-nett']: 0,
            ['salesOrder-open']: 0,
            ['salesOrder-closed']: 0,
        },
    } as IQSaleSummary;
}

export function createFiltersByCode(filters: Array<StatisticsFilterCodes>): Record<StatisticsFilterCodes, boolean> {
    // Declare all filters, and set them to false/unchecked.
    const filtersByCode: Record<StatisticsFilterCodes, boolean> = {
        'debtTran-IN': false,
        'debtTran-CN': false,
        'debtTran-DS': false,
        'debtTran-JC': false,
        'debtTran-RF': false,
        'debtTran-BC': false,
        'debtTran-RE': false,
        'debtTran-JD': false,
        'debtTran-PM': false,
        'debtTran-IT': false,
        'salesOrder-closed': false,
        'salesOrder-open': false,
    };

    // Set the filters to true/checked.
    filters.forEach(filter => {
        filtersByCode[filter] = true;
    });

    return filtersByCode;
}