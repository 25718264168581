import { IQConfiguration } from "../interfaces/fe-models/app.models";
import { BasicState } from "../interfaces/fe-models/enums";

export class IQConfigurationBuilder {
    public config: IQConfiguration;

    constructor(config?: IQConfiguration) {
        if (config) {
            this.config = config;
        } else {
            this.config = {
                nickName: '',
                orgKey: '',
                type: 'iq-enterprise',
                previousIteration: {
                    debtTransactionDate: new Date() as any,
                    debtTransactionNumber: 0
                },
                key: '',
                status: BasicState.draft,
                userKey: '',
                apiProfile: { // Basic API Profile.
                    companyCode: '',
                    host: '',
                    port: '43',
                    protocol: 'https',
                    userNumber: 0,
                    userPsw: '',
                },
                settings: { // Standard company settings.
                    dayOfMonthStart: 26,
                    dayOfMonthEnd: 25,
                    monthWorkingDays: 21,
                    dayOfWeekStart: 1,
                    dayOfWeekEnd: 5,
                    weekWorkingDays: 5,
                    hourOfDayStart: 8,
                    hourOfDayEnd: 17,
                    workingHours: 9,
                },
            } as any;
        }

    }
}