// import extendMoment from '@newgenus/every-moment';
import moment from 'moment';
// const moment = extendMoment(Moment);



export abstract class DateFunctions {


    /**
     * Sets the global moment locale for the application (including @Every-Moment).
     *
     * @static
     * @param {string} locale Moment locale string. e.g. 'en_GB'
     * @memberof DateFunctions
     */
    public static setGlobalMomentLocale(locale: string): void {
        moment.locale(locale);
    }

    /**
     * Sets the
     *
     * @static
     * @memberof DateFunctions
     */
    public static setupMomentForSouthAfricaOfYear2023(): void {

        // Public holidays of south africa for the year 2023
        const year2023 = [
            '2023-01-01', // New Year's Day
            '2023-01-02', // New Year's Day (Observed)
            '2023-04-21', // Human Rights Day 
            '2023-04-07', // Good Friday
            '2023-04-10', // Easter Monday
            '2023-04-27', // Freedom Day
            '2023-05-01', // Workers' Day
            '2023-06-16', // Youth Day
            '2023-08-09', // National Women's Day
            '2023-09-24', // Heritage Day
            '2023-09-25', // Heritage Day (Observed)
            '2023-12-16', // Day of Reconciliation
            '2023-12-25', // Christmas Day
            '2023-12-26', // Day of Goodwill
        ];

        moment.locale('en_ZA');
        moment.updateLocale('en_ZA', {
            holidays: year2023,
            holidayFormat: 'YYYY-MM-DD',
            forcedBusinessDays: [], // Enter the dates that are holidays, which will be working days for the company in the format YYYY-MM-DD (e.g. 2020-01-01).
            forcedBusinessDaysFormat: 'YYYY-MM-DD'
        });
    }



}