/* eslint-disable no-restricted-syntax */
/* eslint-disable @angular-eslint/no-input-rename */
import { DateSettings, DebtTranDatum, SalesOrderDatum, Organization, toOrdinalString, onlyBusinessDays, GraphData, GraphDataConsolidated, GraphStatus, IQConfiguration, toFirebaseTimestamp, parseEmbeddedTimestampsToFirebaseDate, deepCopy, FirebaseMonthSummary, Themes, extractRelevantFieldsToReadFromSummaries, getCompanyMonthStartAndEndByDateSettings, TargetSnapshot, isObjectEmpty, toDate, RepDoc, parseSummaryDataToGraphData } from '@newgenus/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as everyMoment from '@newgenus/every-moment';
import { default as _rollupMoment } from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { Timestamp } from 'firebase/firestore';
import * as _moment from 'moment';
import * as d3 from 'd3';

const moment = everyMoment.extendMoment(_rollupMoment || _moment);

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'shared-7r-graph-month',
    styleUrls: ['../graph-base.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
    template: `
        <div *ngIf="showDateRange"  style="display: flex; flex-wrap: wrap; justify-content: center;">
            <div class="graph-date" title="Graph Date Range" [ngClass]="{'graph-date-expanded': isExpanded}"
                >{{(fromDate | date : 'MMMM ') + toOrdinalString(fromDate.getDate()) + (fromDate | date: ' y, HH:mm') }} - {{(toDate | date : 'MMMM ') + toOrdinalString(toDate.getDate()) + (toDate | date: ' y, HH:mm') }}
            </div>
        </div>
        <div class="graph-parent-container">
          <div>
                <section class="graph-wrapper">

                    <button mat-icon-button title="Toggle Expand Mode" (click)="onExpandToggle()" class="expand-shrink-btn"><mat-icon>{{ isExpanded ? 'unfold_less' : 'unfold_more' }}</mat-icon></button>
                    
                    <shared-7r-graph-base
                        title="Monthly 7R Graph"
                        [consolidateData]="consolidateData"
                        [data]="graphData"
                        [expanded]="isExpanded"
                        [graphMargin]="margin"
                        [graphType]="'monthly'"
                        [height]="height"
                        [parentProps]="parentProperties"
                        [repNames]="repNames"
                        [target]="target"
                        [theme]="theme"
                        [weekends]="consolidateWeekends"
                        [width]="width"
                        [xExtent]="xExtent"
                        [xFormat]="xFormat"
                        [xTicks]="xTicks"
                        [yExtent]="yExtent"
                        [yScale]="yScale"
                        [fromDate]="fromDate"
                        [toDate]="toDate"
                        (graphStatus)="onGraphStatusChange($event)"
                        (clickEntry)="onClickEntry($event)"
                    ></shared-7r-graph-base>

                    <div class="text-center" *ngIf="showDatePicker">
                        <button
                            mat-icon-button
                            (click)="previousDate()"
                            class="nav-btn"
                            color="primary"
                            aria-label="Button which shows the previous day's data."
                            title="Button which shows the previous day's data."
                        >
                            <mat-icon>chevron_left</mat-icon>
                        </button>

                        <mat-form-field appearance="outline">
                            <mat-label>Choose a date</mat-label>
                            <input
                                matInput
                                disabled
                                [matDatepicker]="monthYearPicker"
                                [max]="maxDate"
                                [min]="minDate"
                                [(ngModel)]="dateForData"
                                (dateChange)="onDateChange()"
                            />
                            <mat-hint>MM/YYYY</mat-hint>
                            <mat-datepicker-toggle matSuffix [for]="monthYearPicker"></mat-datepicker-toggle>
                            <mat-datepicker
                                #monthYearPicker
                                [startAt]="startDate"
                                disabled="false"
                                startView="year"
                                (monthSelected)="chosenMonthHandler($event, monthYearPicker)"
                                (yearSelected)="chosenYearHandler($event)"
                            ></mat-datepicker>
                        </mat-form-field>

                        <button
                            mat-icon-button
                            (click)="nextDate()"
                            class="nav-btn"
                            color="primary"
                            aria-label="Button which shows the previous day's data."
                            title="Button which shows the previous day's data."
                        >
                            <mat-icon>chevron_right</mat-icon>
                        </button>
                    </div>
                </section>

                <div style="display: flex; flex-wrap: wrap; justify-content: center;" *ngIf="showConsolidateHours">
                    <div style="display: flex; flex: 1 auto;">
                        <mat-checkbox [(ngModel)]="consolidateData" (change)="onChangeShowConsolidatedHours()"
                            >Show Consolidated Hours<mat-icon
                                matTooltip="When checked, sales recorded outside of working hours will appear on the start of the next working day."
                            >info</mat-icon
                        ></mat-checkbox>
                    </div>
                </div>
                
            </div>

            <div *ngIf="showEmbeddedEntryOnClick && selectedEntry">
              <mat-card class="selected-entry-details">
                    <ul>
                        <!-- <li>Rep: <strong [innerHtml]="toRepName(selectedEntry.repKey)"></strong></li> -->
                        <li>
                            Transaction: <strong>R{{ selectedEntry.value | number }}</strong>
                        </li>
                        <li>
                            Accumulated Value: <strong>R{{ selectedEntry.valueCompounded | number }}</strong>
                        </li>
                        <li>
                            Date: <strong>{{ selectedEntry.date | date: 'EEEE, MMMM d, y, h:mm a' }}</strong>
                        </li>
                        <li>
                            Reps: <strong [innerHtml]="toRepKeysStr(selectedEntry.repKeys)"></strong>
                        </li>
                        <!-- <li>
                            Data: <strong>{{ selectedEntry.data?.length }}</strong>
                        </li> -->
                    </ul>
                </mat-card>
            </div>

            <div class="save-loader" *ngIf="showSpinner">
                <mat-spinner diameter="51"></mat-spinner>
            </div>
        </div>
    `,
})
export class Shared7rMonthComponent implements OnDestroy, OnChanges {

    @Input()
    public showDateRange = true;

    @Input()
    public showConsolidateHours = true;

    @Input()
    public showDatePicker = true;

    @Input('date')
    public dateForData: Date | null = null;

    @Input('repKeys')
    public repKeys$!: BehaviorSubject<string[]>;

    @Input()
    public filtersByCode: null | Record<string, boolean> = null;

    @Input()
    public showCompanyTargets: boolean | null = false;

    // @Input('weekends')
    public consolidateWeekends = true;

    @Input()
    public width = 470;

    @Input()
    public height = 440;

    @Input('parentProps')
    public parentProperties = { height: 0, width: 0 };

    @Input('showEntryOnClick')
    public showEmbeddedEntryOnClick = false;

    @Input()
    public mayViewCompanyStats: boolean | null = false;

    @Input()
    public mayViewRepStats: boolean | null = false;

    @Input()
    public selectedOrg: null | Organization = null;

    @Input()
    public dateSettings: null | DateSettings = null;

    @Input()
    public selectedConfig: null | Partial<IQConfiguration> = null;

    @Input()
    public data: DebtTranDatum[] | null = [];

    @Input()
    public summaryData: FirebaseMonthSummary[] | null = [];

    @Input()
    public theme: Themes = Themes['default-theme'];

    @Input()
    public signedInUsersRepMapping: RepDoc | undefined;

    // eslint-disable-next-line @angular-eslint/no-output-rename
    @Output('clickEntry')
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    public onClickedEntry = new EventEmitter<GraphData[] | null>();

    public margin = { top: 30, right: 20, bottom: 50, left: 70 };
    public target = 0;
    // public startDate = new Date('2022-04-26T06:15:34.000Z');
    public startDate = new Date();
    public repNames: Record<number, string> = {};

    public graphData: Array<GraphData> = [];
    private monthData: Array<GraphData> = [];

    public xScale!: d3.ScaleTime<number, number, never>;
    public yScale!: d3.ScaleLinear<number, number, never>;
    public selectedEntry: GraphData | null = null;
    public isExpanded = false;

    public xTicks = 10;
    public xFormat = '%a %d %b';
    public yExtent: [number, number] = [0, 0];
    public xExtent!: [Date, Date];
    private isSettingsStartHourBefore = false;
    private isSettingsEndHourAfter = false;
    private hourAndDayOfMonthStart!: Date;
    private hourAndDayOfMonthEnd!: Date;
    public minDate: Date;
    public maxDate: Date;

    /**
     * This flag indicates if sales made outside of the working hours are grouped and shown in the graph.
     * For example, a working day of 8-17 with consolidateData set to true, sales made at 7am will show from 8am. Likewise, sales made after 17 will show on the next day, from 8am.
     *
     * @memberof LineGraphComponent
     */
    @Input()
    public consolidateData = false;

    public showSpinner = true;

    private dateChange$ = new BehaviorSubject<Date>(new Date());
    private destroy$ = new Subject<void>();

    @Output()
    public fromDateChange = new EventEmitter<Date>();
    public fromDate: Date = new Date();

    @Output()
    public toDateChange = new EventEmitter<Date>();
    public toDate: Date = new Date();

    constructor() {
        const currentYear = new Date().getFullYear();
        this.minDate = new Date(currentYear - 20, 0, 1);
        this.maxDate = new Date(new Date());
    }

    private setTargetForGraph(repKeys?: string[]) {

        // Use target from summary data.
        if (this.summaryData && this.summaryData.length > 0) {

            const targetsToExtract: Record<string, TargetSnapshot[]> = {};

            // If the user has permission to view company stats, show company targets.
            if (this.mayViewCompanyStats && this.showCompanyTargets && this.selectedConfig?.key) {
                // Find the selected reps summaries, and extract their targets.
                if (repKeys && repKeys?.length > 0) {
                    for (const repKey of repKeys) {
                        const repSummaryTarget = this.summaryData.find(summary => summary.summaryIsForKey === repKey);
                        if (repSummaryTarget) targetsToExtract[repKey] = repSummaryTarget.targets;
                    }
                }
                // Find the org summary, and extract the targets.
                else {
                    const orgSummaryTarget = this.summaryData.find(summary => summary.summaryIsForKey === this.selectedConfig?.orgKey);
                    if (orgSummaryTarget) targetsToExtract[this.selectedConfig.orgKey as string] = orgSummaryTarget.targets;
                }

            } else if (this.mayViewRepStats && !this.showCompanyTargets && this.selectedConfig?.key) {
                // Find the selected reps summaries, and extract their targets.
                if (repKeys && repKeys?.length > 0) {
                    for (const repKey of repKeys) {
                        const repSummaryTarget = this.summaryData.find(summary => summary.summaryIsForKey === repKey);
                        if (repSummaryTarget) targetsToExtract[repKey] = repSummaryTarget.targets;
                    }
                }
                // Find the signed in user's summary, and extract the targets.
                else if (this.signedInUsersRepMapping) {
                    const repSummaryTarget = this.summaryData.find(summary => summary.summaryIsForKey === this.signedInUsersRepMapping?.repNum);
                    if (repSummaryTarget) targetsToExtract[this.signedInUsersRepMapping.repNum] = repSummaryTarget.targets;
                }
            }

            if (isObjectEmpty(targetsToExtract)) {
                // 0 = don't show target line.
                this.target = 0;
            } else {
                // Extract the targets from the summaries.
                const extractedTargets = Object.entries(targetsToExtract).map(([key, value]) => {
                    // Select most recent target from the array.
                    if (value && value.length > 0) {
                        const mostRecentTarget = value.reduce((a, b) => toDate(a.date) >= toDate(b.date) ? a : b);
                        return mostRecentTarget?.monthTarget || 0;
                    } else return 0;
                });

                // Sum the targets.
                this.target = extractedTargets.reduce((a, b) => a + b, 0);
            }
        }

        // Use targets from org.
        if (!(this.summaryData && this.summaryData.length > 0) || this.target <= 0) {
            // If the user has permission to view company stats, show company targets.
            if (this.mayViewCompanyStats && this.showCompanyTargets && this.selectedConfig?.key) {

                // If the user has selected reps, show a sum of targets for the selected reps.
                if (repKeys && repKeys?.length > 0 && this.selectedOrg) {
                    this.target = 0;

                    for (const userKey of this.selectedOrg.orgUsers.userKeys) {
                        const orgUser = this.selectedOrg.orgUsers[userKey];
                        if (orgUser?.targets && orgUser?.statistics
                            && orgUser.targets[this.selectedConfig.key]
                            && orgUser?.statistics[this.selectedConfig.key]
                            && repKeys.includes(orgUser.statistics[this.selectedConfig.key]?.repNo)) {

                            this.target += orgUser?.targets[this.selectedConfig.key].monthlyTarget;
                        }
                    }

                }

                // If no reps are selected, show the company stats if they are available.
                else if (this.selectedOrg?.targets && this.selectedOrg.targets[this.selectedConfig.key]) {
                    this.target = this.selectedOrg.targets[this.selectedConfig.key].monthlyTarget;
                } else {
                    // console.debug('No target set');
                    this.target = 0;
                }
            } else if (this.mayViewRepStats && !this.showCompanyTargets && this.selectedConfig?.key && this.selectedOrg) {
                this.target = 0;

                // If the user has permission to view rep stats, show rep targets.
                for (const userKey of this.selectedOrg.orgUsers.userKeys) {
                    const orgUser = this.selectedOrg.orgUsers[userKey];
                    if (orgUser?.targets && orgUser.targets[this.selectedConfig.key] && (repKeys?.length === 0
                        || repKeys && repKeys.includes(orgUser.statistics[this.selectedConfig.key]?.repNo))) {

                        this.target += orgUser?.targets[this.selectedConfig.key].monthlyTarget;
                    }
                }

            } else {
                // Otherwise, hide targets.
                this.target = 0;
            }
        }

        // If the target is set to below 0, set it to 0.
        if (this.target < 0) this.target = 0;
    }

    public previousDate() {
        this.dateForData = moment(this.dateForData).subtract(1, 'month').toDate();
        this.onDateChange();
    }

    public nextDate() {
        this.dateForData = moment(this.dateForData).add(1, 'month').toDate();
        this.onDateChange();
    }

    public toOrdinalString(str?: number) {
        return toOrdinalString(str);
    }

    public onGraphStatusChange(status: GraphStatus) {
        // console.log('onGraphStatusChange > status: ', status);
    }

    public onClickEntry(entries: unknown[]) {

        if (this.showEmbeddedEntryOnClick) {
            this.selectedEntry = entries[0] && (entries as GraphData[])[0]?.valueCompounded !== undefined ? (entries as GraphData[])[0] : null;
        }

        this.onClickedEntry.emit(entries as GraphData[]);
    }

    public onExpandToggle(): void {
        this.isExpanded = !this.isExpanded;
        this.updateYExtent();
    }

    public chosenYearHandler(normalizedYear: Date) {
        // console.log('chosenYearHandler > normalizedYear.year():', normalizedYear.year());
        // this.dateForData = moment(this.dateForData).year(normalizedYear.year()).toDate();
    }

    public chosenMonthHandler(normalizedMonth: Date, datepicker: MatDatepicker<Date>) {
        datepicker.close();
        this.dateForData = moment(this.dateForData)
            .set('year', moment(normalizedMonth).year())
            .set('month', moment(normalizedMonth).month() - 1)
            // .set('date', this.selectedOrg.settings.dayOfMonthStart)
            .set('date', this.dateSettings?.dayOfMonthStart || 1)
            .toDate();
        this.onDateChange();
    }

    private updateFromAndToDate(): void {
        // Avoid processing if dateForData or dateSettings is not defined.
        if (!this.dateForData || !this.dateSettings) return;

        const result = getCompanyMonthStartAndEndByDateSettings(this.dateForData, this.dateSettings);

        if (!this.consolidateData) {
            this.fromDate = result.monthStart.startOf('day').toDate();
            this.toDate = result.monthEnd.endOf('day').toDate();
        } else {
            this.fromDate = result.monthStart.toDate();
            this.toDate = result.monthEnd.toDate();
        }

        this.fromDateChange.emit(this.fromDate);
        this.toDateChange.emit(this.toDate);
    }

    public onChangeShowConsolidatedHours(): void {
        this.updateFromAndToDate();
        this.prepareGraphData(this.monthData);
    }

    public onDateChange() {
        this.updateFromAndToDate();
        this.clearCurrentDataAndSelectedEntry();
        if (this.dateForData) this.dateChange$.next(this.dateForData);
    }

    private clearCurrentDataAndSelectedEntry() {
        this.monthData = [];
        this.selectedEntry = null;
        this.onClickedEntry.emit(null);
    }

    private prepareEmptyGraph() {
        this.showSpinner = true;

        // const settings = this.selectedOrg?.settings || {
        const settings = this.dateSettings || {
            dayOfMonthStart: 26,
            dayOfMonthEnd: 25,
            consolidateHours: true,
            dayOfWeekEnd: 5,
            dayOfWeekStart: 1,
            hourOfDayEnd: 17,
            hourOfDayStart: 9,
        };

        // set scale domains
        this.yExtent = d3.extent([0, 10000000]) as [number, number]; // Default yExtent is 0 to 10 million.
        this.setTargetForGraph(); // Then set the target for the graph if the data is available.
        let xExtent = d3.extent([this.fromDate, this.toDate]);

        if (!xExtent[0] || !xExtent[1]) {
            xExtent = [this.fromDate, this.toDate];
        }

        this.hourAndDayOfMonthStart = moment(xExtent[0])
            .startOf('D')
            .set('hour', settings.hourOfDayStart)
            .toDate();
        this.hourAndDayOfMonthEnd = moment(xExtent[1])
            .startOf('D')
            .set('hour', settings.hourOfDayEnd)
            .toDate();
        this.isSettingsStartHourBefore = this.hourAndDayOfMonthStart.getTime() < xExtent[0].getTime();
        this.isSettingsEndHourAfter = this.hourAndDayOfMonthEnd.getTime() > xExtent[1].getTime();

        if (this.consolidateData) {
            this.xExtent = [this.hourAndDayOfMonthStart, this.hourAndDayOfMonthEnd];
        } else {
            this.xExtent = xExtent;
        }

        // Calculate xTicks.
        this.xTicks = 10;

        this.graphData = [];

        this.showSpinner = false;
    }

    private prepareGraphData(data: Array<GraphData>) {

        if (this.dateSettings === undefined) return;

        this.showSpinner = true;
        let consolidated: Array<GraphData> = [];
        let nonBusinessDayData: Array<GraphData> = [];

        const workingMonthStart = this.fromDate.getTime();
        const workingMonthEnd = this.toDate.getTime();
        const previousMonthEnd = moment(this.toDate).subtract(1, 'month').toDate().getTime();

        if (this.consolidateData) {
            // Consolidated = sum of all sales between yesterday's working day end and today's working day start.
            consolidated = data.filter(
                (c) => c.date.getTime() > previousMonthEnd && c.date.getTime() <= workingMonthStart
            );

            // Data = sum of all sales between this month's start and end.
            data = data.filter((c) => c.date.getTime() > workingMonthStart && c.date.getTime() <= workingMonthEnd);
        } else {
            // Filter dates to show between mid-Night and mid-Night.
            data = data.filter((x) => x.date.getTime() >= workingMonthStart && x.date.getTime() <= workingMonthEnd);
        }

        // Filter out weekend data.
        const dateProfile = this.selectedOrg?.orgDateCycles?.find(cycle => cycle.key == this.dateSettings?.dateProfileKey);

        if (dateProfile && dateProfile.businessDaySelection !== 'any days') {
            nonBusinessDayData = data.filter(data => !onlyBusinessDays(data.date));
            data = data.filter(data => onlyBusinessDays(data.date));
            // data = data.filter((x) => x.date.getDay() !== 0 && x.date.getDay() !== 6);
            this.consolidateWeekends = true;
        } else {
            this.consolidateWeekends = false;
        }

        data = this.groupDataByDayOfMonthAndCompoundValue(data);
        nonBusinessDayData = this.groupDataByDayOfMonthAndCompoundValue(nonBusinessDayData);

        // Join the weekend data to the primary data by next/previous business day from the weekend.
        // if (this.consolidateWeekends) {
        if (dateProfile && dateProfile.businessDaySelection !== 'any days') {
            nonBusinessDayData.forEach((nonBusinessDayData) => {
                let entryToCompound: GraphData | undefined;

                if (dateProfile.businessDaySelection === 'next business day') {
                    const nextBusinessDay = moment(nonBusinessDayData.date).nextBusinessDay().toDate();
                    entryToCompound = data.find((x) => x.date.getTime() === nextBusinessDay.getTime());

                } else if (dateProfile.businessDaySelection === 'previous business day') {
                    const previousBusinessDay = moment(nonBusinessDayData.date).prevBusinessDay().toDate();
                    entryToCompound = data.find((x) => x.date.getTime() === previousBusinessDay.getTime());

                } else {
                    throw new Error('Unknown business day selection: ' + dateProfile.businessDaySelection);
                }


                if (entryToCompound) {
                    // console.log('Business day from ', nonBusinessDayData.date, ' is ', nextBusinessDay, ' and data is ', entryToCompound, '');
                    entryToCompound.value += nonBusinessDayData.value;
                    entryToCompound.valueCompounded += nonBusinessDayData.valueCompounded;
                    entryToCompound.gross += nonBusinessDayData.gross;
                    entryToCompound.nett += nonBusinessDayData.nett;
                    if (entryToCompound.sales === undefined) entryToCompound.sales = 0;
                    entryToCompound.sales += nonBusinessDayData.sales || 0;

                    if (entryToCompound.credits === undefined) entryToCompound.credits = 0;
                    entryToCompound.credits += nonBusinessDayData.credits || 0;

                    entryToCompound.repKeys = [...(entryToCompound.repKeys || []), ...(nonBusinessDayData.repKeys || [])];
                } else {
                    console.warn('No business day found for ', nonBusinessDayData.date, '');
                }

            });
        }

        if (data?.length > 0) {
            // The graphData is already sorted, add the consolidated data to the top of the array.
            if (consolidated.length > 0) {
                const consolidatedEntry: GraphDataConsolidated = {
                    date: moment(workingMonthStart).add(10, 'millisecond').toDate(), // Adding 10 milliseconds will render the consolidated entry after the zero based entry.
                    data: consolidated,
                    key: '',
                    value: 0,
                    valueCompounded: consolidated[consolidated.length - 1].valueCompounded, // The last entry will have the accurate valueCompounded.
                    repKey: 0,
                    gross: 0,
                    nett: 0,
                    sales: 0,
                    credits: 0
                };
                data.unshift(consolidatedEntry);
            }

            // if (moment(data[0].date).format('YYYY-MM-DD') != this.fromDate.format('YYYY-MM-DD')) {
            if (moment(this.fromDate).isBefore(moment(data[0].date))) {
                // The zeroBasedEntry allows the first line to plot from 0 to the destined value.
                const zeroBasedEntry: GraphData = {
                    date: data[0].date,
                    key: '',
                    value: 0,
                    valueCompounded: 0,
                    repKey: 0,
                    gross: 0,
                    nett: 0,
                    sales: 0,
                    credits: 0,
                };
                data.unshift(zeroBasedEntry);
            }
        }

        // Sort the data based on date objects.
        data.sort((a, b) => {
            return (<any>a).date - (<any>b).date;
        });

        // set scale domains
        this.updateYExtent(data);
        // this.yExtent = d3.extent(data, (d: GraphData) => d.valueCompounded);
        let xExtent = d3.extent(data, (d) => new Date(d.date));

        if (!xExtent[0] || !xExtent[1]) {
            xExtent = [this.fromDate, this.toDate];
        }

        if (moment(this.toDate).isAfter(xExtent[1])) {
            xExtent[1] = this.toDate;
        }

        this.hourAndDayOfMonthStart = moment(xExtent[0])
            .startOf('D')
            // .set('hour', this.selectedOrg.settings.hourOfDayStart)
            .set('hour', this.dateSettings?.hourOfDayStart || 0)
            .toDate();
        this.hourAndDayOfMonthEnd = moment(xExtent[1])
            .startOf('D')
            // .set('hour', this.selectedOrg.settings.hourOfDayEnd)
            .set('hour', this.dateSettings?.hourOfDayEnd || 0)
            .toDate();
        this.isSettingsStartHourBefore = this.hourAndDayOfMonthStart.getTime() < xExtent[0].getTime();
        this.isSettingsEndHourAfter = this.hourAndDayOfMonthEnd.getTime() > xExtent[1].getTime();


        // if (this.consolidateData) {
        //     this.xExtent = [this.hourAndDayOfMonthStart, this.hourAndDayOfMonthEnd];

        //     // Set first and last month entries to have time.
        //     if (data.length > 0) {
        //         if (data[0].date.getDate() === this.fromDate.date()) {
        //             data[0].date = moment(data[0].date).set('hour', this.selectedOrg.settings.hourOfDayStart).toDate();
        //         }
        //         if (data[data.length - 1].date.getDate() === this.toDate.date()) {
        //             data[data.length - 1].date = moment(data[data.length - 1].date).set('hour', this.selectedOrg.settings.hourOfDayEnd).toDate();
        //         }
        //     }
        // } else {
        this.xExtent = xExtent;
        // if (this.isSettingsStartHourBefore) this.xExtent[0] = this.hourOfDayStart;
        // if (this.isSettingsEndHourAfter) this.xExtent[1] = this.hourOfDayEnd;
        // }

        // Calculate xTicks.
        this.xTicks = 10;
        const diff = moment(this.xExtent[1]).diff(this.xExtent[0], 'days', true).toFixed(0);
        this.xTicks = Math.round(+(+diff / 2));


        this.graphData = [];
        this.graphData = data;

        this.showSpinner = false;
    }

    private updateYExtent(data?: GraphData[]): void {
        const primaryExtent = d3.extent(data || this.graphData, (d: GraphData) => d.valueCompounded) as [number, number];
        let yExtendMargin10Percent: [number, number] = [0, 1000000];

        if (this.isExpanded) {
            const orZero = (value: number) => value !== undefined && !isNaN(value) ? value : 0;
            const yMin = Math.min(orZero(primaryExtent[0]));
            const yMax = Math.max(orZero(primaryExtent[1]), orZero(this.target));

            // yExtendMargin10Percent = [yMin / 0.9, yMax * 1.1];
            yExtendMargin10Percent = [
                yMin > 0 ? yMin / 1.1 : yMin * 0.9,
                yMax > 0 ? yMax * 1.1 : yMax / 0.9,
            ];
        } else {
            // yExtendMargin10Percent = [primaryExtent[0] / 0.9, primaryExtent[1] * 1.1];
            yExtendMargin10Percent = [
                primaryExtent[0] > 0 ? primaryExtent[0] / 1.1 : primaryExtent[0] * 0.9,
                primaryExtent[1] > 0 ? primaryExtent[1] * 1.1 : primaryExtent[1] / 0.9,
            ];
        }

        this.yExtent = yExtendMargin10Percent;
    }

    private groupDataByDayOfMonthAndCompoundValue(data: GraphData[]): GraphData[] {
        const toSQLStr = (a: GraphData) => a.date.getFullYear() + '-' + (a.date.getMonth() + 1) + '-' + a.date.getDate();
        // const toSQLStr = (a) => a.date.toDateString();
        const groupedData: Record<string, GraphData[]> = data.reduce((r, a) => {
            r[toSQLStr(a)] = r[toSQLStr(a)] || [];
            r[toSQLStr(a)].push(a);
            return r;
        }, Object.create(null));
        const dateKeys = Object.keys(groupedData).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

        const groupedDataArray: GraphData[] = [];

        for (let i = 0; i < dateKeys.length; i++) {
            const key = dateKeys[i];
            const data = groupedData[key];

            const entryDate = moment(new Date(data[0].date));
            const entry: GraphData = {
                // date:
                //     entryDate.date() === this.fromDate.date()
                //         ? this.fromDate.toDate()
                //         : entryDate.startOf('d').toDate(),
                date: entryDate.startOf('d').toDate(),
                key: '',
                value: 0,
                valueCompounded: 0,
                repKey: 0,
                repKeys: [],
                gross: 0,
                nett: 0,
                sales: 0,
                credits: 0
            };

            data.forEach((item, j) => {
                if (j === 0) {
                    const previousDateValueCompound = (i > 0) ? groupedDataArray[groupedDataArray.length - 1].valueCompounded : 0;
                    entry.valueCompounded = previousDateValueCompound + item.value;
                    entry.value = entry.value + item.value;
                } else {
                    // entry.valueCompounded += groupedData[key][j - 1].value;
                    // entry.value += groupedData[key][j - 1].value;
                    entry.valueCompounded += item.value;
                    entry.value += item.value;
                }
                if (entry.repKeys && !entry.repKeys.includes(item.repKey)) entry.repKeys.push(item.repKey);
            });

            groupedDataArray.push(entry);
        }

        return groupedDataArray;
    }

    private parseToGraphData(docData: DebtTranDatum | SalesOrderDatum): GraphData {
        const entry = {
            date: docData.created,
            key: '',
            valueCompounded: 0,
        } as Partial<GraphData>;

        if (docData.collection == 'salesOrder') {
            entry.repKey = docData.rep;
            // Nett Sales = total * currencyrate - totalvat * currencyrate.
            entry.value = Number((docData.total * docData.currencyrate - docData.totalvat * docData.currencyrate).toFixed(2));
            // Gross = total * currencyrate.
            entry.gross = Number((docData.total * docData.currencyrate).toFixed(2));
            entry.repKey = docData.rep;

        } else if (docData.collection == 'debtTran') {
            // Nett Sales = debits - debitTax - credits + creditTax.
            entry.value = Number((docData.debit - docData.debitTax - docData.credit + docData.creditTax).toFixed(2));
            // Gross = debits.
            entry.gross = Number((docData.debit).toFixed(2));
            // Sales = debits - debitTax.
            entry.sales = Number((docData.debit - docData.debitTax).toFixed(2));
            entry.repKey = docData.rep || docData.user;
            // Credits Notes = credits - creditTax.
            // entry.credits = Number((docData.credit - docData.creditTax).toFixed(2));
        }

        // Nett Sales and value are shared at the moment.
        entry.nett = entry.value;

        return entry as GraphData;

        // if (docData.dc === 'C') {
        //     return {
        //         value: -(docData.credit - docData.creditTax).toFixed(2),
        //         date: docData.created,
        //         key: '',
        //         valueCompounded: 0,
        //         repKey: docData.rep || docData.user,
        //     };
        // } else {
        //     return {
        //         value: +(docData.debit - docData.debitTax).toFixed(2),
        //         date: docData.created,
        //         key: '',
        //         valueCompounded: 0,
        //         repKey: docData.rep || docData.user,
        //     };
        // }
    }

    public toRepName(repKey: number): string {
        return this.repNames[repKey] ? this.repNames[repKey] : '<i>Unavailable</i>';
    }

    public toRepKeysStr(repKeys?: number[]): string {
        if (!repKeys || repKeys.length === 0) return '';
        return repKeys.sort().map(key => this.toRepName(key) + ` (${key})`).join(', ');
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['dateForData'] && !changes['dateForData'].isFirstChange() && this.selectedOrg) {
            this.onDateChange();
        }

        if (changes['selectedOrg']) {
            setTimeout(() => {
                // Set the dateSettings if they are not set.
                if (!this.dateSettings && this.selectedOrg) {
                    this.dateSettings = this.selectedOrg.settings;
                }

                if (this.dateSettings) {
                    // Capture consolidateData setting.
                    // this.consolidateData = this.dateSettings.consolidateHours;
                }

                // Set the date range for the data and graph - does emit update on dateChanges$.
                this.updateFromAndToDate();
            });
        }

        if (changes['dateSettings']) {
            setTimeout(() => { this.onDateChange(); });
        }

        if (changes['selectedConfig']) {
            setTimeout(() => {
                // Reset local repNames.
                this.repNames = {};

                // Add repNames to local repNames.
                Object.values(this.selectedConfig?.userMapping || {}).forEach(rep => this.repNames[+rep.key] = rep.repName);
            });
        }

        if (changes['summaryData'] || changes['filtersByCode']) {
            setTimeout(() => {
                this.processNewSummaryData();
            });
        }
    }

    private processNewData(): void {

        // If null is returned, clear the graph.
        if (!this.data || this.data.length === 0 || !this.dateSettings) {
            this.monthData = []
            this.target = 0;
            this.prepareEmptyGraph();
            return;
        }

        const salesData = this.data;
        const repKeys = this.repKeys$.value;
        this.setTargetForGraph(repKeys);

        if (salesData && repKeys) {
            this.monthData = [];
            let newData: GraphData[];


            // If the user has selected reps, and the number of selected reps is equal to the number of reps in the data, then we can assume that no filtering is required.
            if (Object.keys(this.selectedConfig?.userMapping || {}).length === repKeys.length
                && (this.filtersByCode == null || Object.values(this.filtersByCode).every(x => x === true))) {
                // Map to GraphData.
                newData = salesData.map(c => this.parseToGraphData(c));
            } else {
                newData = salesData.filter((c) => {
                    let accepted = true;

                    // For the filter to apply, it must be non-null and at least one of the values must be false.
                    if (this.filtersByCode && Object.values(this.filtersByCode).some(f => f === false)) {
                        if (c.collection === 'debtTran') {
                            accepted = this.filtersByCode[`debtTran-${c.type}`];
                        }
                    }

                    if (!accepted) {
                        return false;
                    }

                    // Filter out data that is not in the selected reps, if any.
                    if (repKeys?.length > 0)
                        return repKeys.includes((c.collection == 'debtTran' ? (c.rep || c.user) : c.rep) + '');
                    // If no reps are selected, show all available data.
                    else return true;
                })
                    // Map to GraphData.
                    .map((c) => this.parseToGraphData(c));
            }

            this.monthData = [...this.monthData, ...newData];
            this.prepareGraphData(this.monthData);

        } else {
            console.debug('not doing anything...')
        }
    }

    private processNewSummaryData(): void {
        // If null is returned, clear the graph.
        if (!this.summaryData || this.summaryData.length === 0 || !this.dateSettings) {
            this.monthData = []
            this.target = 0;
            this.prepareEmptyGraph();
            return;
        }

        const repKeys = this.repKeys$.value;
        const summariesParsedAndFiltered = this.summaryData
            // Remove summaries for reps that are not selected, or if no reps are selected, render all data.
            .filter(summary => {
                if (repKeys?.length > 0)
                    return repKeys.includes(summary.summaryIsForKey + '');
                else return true;
            })
            .map(summary => parseEmbeddedTimestampsToFirebaseDate(deepCopy(summary)));
        let summaryData = summariesParsedAndFiltered.flatMap(summary => summary.fullSales)
            .map(tx => parseEmbeddedTimestampsToFirebaseDate(deepCopy(tx)));
        this.setTargetForGraph(repKeys);

        if (summaryData && repKeys) {
            this.monthData = [];
            let newData: GraphData[] = [];
            summaryData = summaryData.filter(tx => {
                const txDate = moment((tx.date as Timestamp).toDate());
                const isBetweenWorkingMonth = txDate.isBetween(this.fromDate, this.toDate, 'day', '[]');

                return isBetweenWorkingMonth;
            });

            const { debitFields, creditFelids, debitTaxFields, creditTaxFelids } = extractRelevantFieldsToReadFromSummaries(this.filtersByCode);


            // Map to GraphData.
            newData = parseSummaryDataToGraphData(summaryData, debitFields, debitTaxFields, creditFelids, creditTaxFelids);

            this.monthData = [...this.monthData, ...newData];
            this.prepareGraphData(this.monthData);

        } else {
            console.debug('not doing anything...')
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }
}

