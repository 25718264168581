<h2 mat-dialog-title>How long would you like to pend the Particle for?</h2>
<hr>

<div mat-dialog-content>

    <div class="container">
        <div class="row">

            <div class="col-12" style="margin:0;padding:0">
                <span
                    style="font-size: 1rem;margin-bottom: .5rem; font-weight: 500; line-height: 1.2; color: #373757;">Pend
                    until: </span>
                <span>
                    Date: {{ model.year }}-{{ model.month }}-{{ model.day }}:{{ time.hour }}:{{ time.minute }}
                </span>
                <!-- <button class="btn btn-primary" id="pend" data-cy="pend"
                    (click)="pendingParticle()">Pend
                </button> -->
            </div>
        </div>

        <br>
        <div class="row">
            <div class="col-12 d-flex justify-content-center">
                <ngb-datepicker #dp [(ngModel)]="model" (navigate)="date = $event.next">
                </ngb-datepicker>
            </div>
        </div>

        <div class="row d-flex justify-content-center">
            <!-- <ngx-mat-timepicker [(ngModel)]="time" [meridian]="meridian">
            </ngx-mat-timepicker> -->


            
            <!-- <button
                class="btn btn-sm btn-outline-{{meridian ? 'success' : 'danger'}}"
                (click)="toggleMeridian()">
                Meridian - {{meridian ? "ON" : "OFF"}}
            </button> -->
        </div>
        <div class="row d-flex justify-content-center">
            <button class="btn btn-sm btn-outline-primary mr-2" (click)="setPendingTimePlusFiveMinutes()">Plus 5
                min</button>
            <button class="btn btn-sm btn-outline-primary mr-2" (click)="setPendingTimePlusThirtyMinutes()">Plus 30
                min</button>
            <button class="btn btn-sm btn-outline-primary mr-2" (click)="setPendingTimePlusOneDay()">Plus 1 day</button>
            <button class="btn btn-sm btn-outline-primary mr-2" (click)="selectToday()"
                data-cy="selectPresentTime">Select
                Today
            </button>
        </div>
        <hr>
        <div class="row">
            <textarea class="w-100" style="min-height: 57px; max-height: 79.8px;" [(ngModel)]="noteText"
                placeholder="Leave a note for when this Particle returns from pending..."></textarea>
        </div>
    </div>

</div>

<div mat-dialog-actions class="d-flex">
    <div class="flex-spacer"></div>
    <button mat-raised-button [mat-dialog-close]="undefined" data-cy="dialog-cancel" cdkFocusInitial>Cancel</button>
    <button mat-raised-button (click)="submit()" data-cy="dialog-confirm" color="primary">Pend</button>
</div>