<div class="container">
  <form (ngSubmit)="onSubmit()" [formGroup]="form" class="form-horizontal">
 
    <div *ngFor="let question of questions">
      <newgenus-df-question [question]="question" [form]="form"></newgenus-df-question>
    </div>
 
    <div>
      <button type="submit" [disabled]="!form.valid" class="btn btn-primary">Save</button>
    </div>
  </form>
 
  <div *ngIf="payLoad" class="form-row">
    <strong>Saved the following values</strong><br>{{payLoad}}
  </div>
</div>