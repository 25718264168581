export interface Plan {
    plan?: UserPlan;
    features?: Features;
    routes?: Routes;
    roles?: Roles;
    name: string;
}

export interface Roles {
    subscriber?: boolean;
    editor?: boolean;
    admin?: boolean;
}

export interface Features {
    guestNotification?: boolean;
    voiceControl?: boolean;
    adminArea?: boolean;
    invoices?: boolean;
    pricing?: boolean;
    chat?: boolean;
}

export interface Routes {
    guestNotification?: string[];
    adminArea?: string[];
    invoices?: string[];
    pricing?: string[];
    chat?: string[];
}

export enum UserPlan {
    Free = 'free',
    Paid = 'paid',
    Dev = 'dev'
}
