import { SelectFolderOrderDisplayModalComponentComponent } from '../../shared/modals/select-folder-order-display-modal-component/select-folder-order-display-modal-component.component';
import { PendParticleDisplayModalComponent } from '../../shared/modals/pend-particle-display-modal/pend-particle-display-modal.component';
import { deepCompare, deepCopy, parseEmbeddedTimestampsToFirebaseDate, toFirebaseTimestamp } from '@newgenus/common';
import { MenuConfiguration, CurrentConfig, Particle, Folder } from '@newgenus/common';
import { selectOutBasketParticles } from '../../redux/particles/particles.selectors';
import { selectWorkspace } from '../../redux/workspace/workspace.selectors';
import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { SecurityService } from '../../shared/services/security.service';
import { ParticleService } from '../../shared/services/particle.service';
import { Subject, debounceTime, skipWhile, take, takeUntil } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ThemeService } from '../../shared/services/theme.service';
import { MenuConfigurations } from '../../shared/utils/constants';
import { trigger, state, style } from '@angular/animations';
import { StoreUser } from '../../workspace/user.abstract';
import { NavigationEnd, Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from '../../redux/reducers';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';

@Component({
  selector: 'newgenus-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('subNavAnimation', [
      state('collapsed', style({
        'width': '60px',
        // 'font-size': '0px'
      })),
      state('expanded', style({ 'width': '250px' }))
    ])
  ]
})
export class SidebarComponent extends StoreUser implements OnInit, OnDestroy {

  public sectionToHighlight!: string; // TODO this is used in the broken modal of custom folders. See what it's for...

  private sidebarCollapsedLocally = false;
  public isSubNavSidebarClosed = false;
  public subNavSidebarState = "expanded";
  public isSmallDisplay = false;
  public inbasketCount!: number;
  public pendingCount!: number;

  public customFolders: any;
  public allFolders: string[] = [];
  public basketTools: any[];

  private userMenuConfigurations!: MenuConfiguration;
  private currentMenuProfile = '';
  public configuration: CurrentConfig = {
    header: '',
    showFolders: false,
    topTierMenuItems: [],
    middleTierMenuItems: [],
  }

  private isSubscribedToUserChangesForSidebarUpdates = false;
  private allowLoopingSidebarNavigation = false;
  /**
   * Only change this variable for desktop displays.
   * @memberof SidebarComponent
   */
  public isHoveringSideBar = false;
  public expandOnHover = false;

  private destroy$ = new Subject<void>();
  private onResizeSubject: Subject<number> = new Subject();

  constructor(
    private particleService: ParticleService,
    public security: SecurityService,
    public override store: Store<AppState>,
    private themeService: ThemeService,
    private dialog: MatDialog,
    private router: Router,
  ) {
    super(store);
    this.initStoreUser(this.destroy$);

    this.basketTools = this.particleService.basketTools;
  }

  public ngOnInit(): void {

    // Initially set the sidebar from the user preferences.
    this.user$.pipe(
      skipWhile(users => !users || users.length === 0),
      take(1)
    ).subscribe(users => {
      if (users && users.length > 0 && this.isSubNavSidebarClosed !== users[0].preferences.sidebarStatus) {
        this.toggleExpand(true);
      }
    });

    this.router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        if (event instanceof NavigationEnd && event.url !== "/splash") {
          this.allowLoopingSidebarNavigation = false;
          this.updateSidebarNavigation(event);
          this.subscribeToUserChangesForSidebarUpdates();
        }
      });

    this.particleService.userFolders$
      .pipe(takeUntil(this.destroy$))
      .subscribe(customFolders => {
        if (customFolders?.length > 0) {
          this.customFolders = this.particleService.sortFolders(customFolders);
          this.allFolders = this.allFolders.concat([...customFolders.map((c: { id: any; }) => c.id)]);
        }
      });

    this.onResizeSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe(windowWidth => {
        const hasNewWindowWidth = (windowWidth < 768);

        if (hasNewWindowWidth !== this.isSmallDisplay) {

          this.isSmallDisplay = hasNewWindowWidth;
          this.themeService.localUpdateOfIsSmallDisplay(this.isSmallDisplay);

          if (this.isSmallDisplay && !this.isSubNavSidebarClosed) {
            this.toggleExpand(true);
            this.sidebarCollapsedLocally = true;
          } else if (!this.isSmallDisplay && this.isSubNavSidebarClosed && this.sidebarCollapsedLocally) {
            this.toggleExpand(true);
            this.sidebarCollapsedLocally = false;
          }
        }
      });


    this.store.pipe(select(selectWorkspace))
      .pipe(takeUntil(this.destroy$))
      .subscribe(workspace => {
        this.inbasketCount = workspace.length > 0 ? workspace[0].inbasketCount : this.inbasketCount;
        this.pendingCount = workspace.length > 0 ? workspace[0].pendingCount : this.pendingCount;

        if (!this.userMenuConfigurations || !this.userMenuConfigurations['WORKSPACE']) {
          MenuConfigurations['WORKSPACE'].topTierMenuItems[0].count = this.inbasketCount || 0;
          MenuConfigurations['WORKSPACE'].topTierMenuItems[1].count = this.pendingCount || 0;
        } else {
          // Update inbasket count.
          const inbasketIdx = this.userMenuConfigurations['WORKSPACE'].topTierMenuItems.findIndex(item => item.id === 'basket-1');
          if (~inbasketIdx) {
            this.userMenuConfigurations['WORKSPACE'].topTierMenuItems[inbasketIdx].count = this.inbasketCount || 0;
          }

          // Update pending count.
          const pendingIdx = this.userMenuConfigurations['WORKSPACE'].topTierMenuItems.findIndex(item => item.id === 'basket-2');
          if (~pendingIdx) {
            this.userMenuConfigurations['WORKSPACE'].topTierMenuItems[pendingIdx].count = this.pendingCount || 0;
          }
        }
        // MenuConfigurations.WORKSPACE.topTierMenuItems[2].count = doc.filedCount || 0; // TODO this is outbasket
        // MenuConfigurations.WORKSPACE.middleTierMenuItems[0].count = doc.filedCount || 0;
      })

    this.store.pipe(select(selectOutBasketParticles))
      .pipe(takeUntil(this.destroy$))
      .subscribe(particlesPromise => {
        // show only last 24 hours in outbasket
        // const forLastDay = moment().subtract(1, "days");
        if (particlesPromise !== undefined) {
          particlesPromise.then((particles) => {

            const selectedParticles = [];
            for (const particle of particles) {
              if (this.todayOnly(particle)) {
                selectedParticles.push(particle);
              }
            }

            const outbasketParticleCount = selectedParticles.length;

            if (!this.userMenuConfigurations || !this.userMenuConfigurations['WORKSPACE']) {
              MenuConfigurations['WORKSPACE'].topTierMenuItems[2].count = outbasketParticleCount || 0;
            } else {

              // Update outbasket count.
              const outbasketIdx = this.userMenuConfigurations['WORKSPACE'].topTierMenuItems.findIndex(item => item.id === 'basket-3');
              if (~outbasketIdx) {
                this.userMenuConfigurations['WORKSPACE'].topTierMenuItems[outbasketIdx].count = outbasketParticleCount || 0;
              }
              // this.userMenuConfigurations.WORKSPACE.topTierMenuItems[2].count = this.particleCount || 0;
            }
          })
        }
      });

    this.onResizeSubject.next(window.innerWidth);
  }

  public onMouseEnterSidebar(): void {
    // Only change this variable for desktop displays.
    if (!this.isSmallDisplay && this.isSubNavSidebarClosed) {
      this.isHoveringSideBar = true;
    }
  }

  public onMouseLeaveSidebar(): void {
    // Only change this variable for desktop displays.
    if (!this.isSmallDisplay) {
      this.isHoveringSideBar = false;
    }
  }

  private subscribeToUserChangesForSidebarUpdates(): void {
    if (this.isSubscribedToUserChangesForSidebarUpdates) return;

    this.isSubscribedToUserChangesForSidebarUpdates = true;
    this.user$.pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe(users => {
        if (users && users.length > 0) {
          setTimeout(async () => {

            const newMenu = await this.security.generateSubMenuFromUserAccess();

            if ((newMenu && !this.userMenuConfigurations) || !deepCompare(newMenu, this.userMenuConfigurations)) {
              this.userMenuConfigurations = newMenu as MenuConfiguration;
              this.updateSidebarNavigation();
            }
          });
        }
      });
  }

  public todayOnly(particle: Particle) {
    return particle.filedDate && moment(toFirebaseTimestamp(particle.filedDate).toDate()).isSame(moment(), 'day');
  }

  private updateSidebarNavigation(event?: NavigationEnd) {
    const url = event ? (event.urlAfterRedirects || event.url) : this.router.url;
    let moduleFragment = url.substring(url.indexOf('/', 1) + 1, url.indexOf('/', url.indexOf('/', 1) + 1));
    moduleFragment = moduleFragment.replace('/', '').replace('/', '');

    if (!this.userMenuConfigurations && moduleFragment.toUpperCase() !== 'LOGIN') {
      this.allowLoopingSidebarNavigation = true;
      setTimeout(() => {
        // Don't call this function with an old parameter if the user has navigated away (can happen if the user navigates away while the timeout is still running).
        if (this.allowLoopingSidebarNavigation) this.updateSidebarNavigation(event);
      }, 500);
    } else {
      if (moduleFragment.toUpperCase() !== 'LOGIN') {

        // if (this.currentMenuProfile != moduleFragment && moduleFragment !== 'SETTINGS') {
        if (this.currentMenuProfile && moduleFragment !== 'SETTINGS') {
          this.currentMenuProfile = moduleFragment.toUpperCase();
          // this.configuration = MenuConfigurations[this.currentMenuProfile];
          this.configuration = this.userMenuConfigurations[this.currentMenuProfile];
          this.highlightCurrentNavigation(event as NavigationEnd);
        }

        if (!this.configuration?.header) {
          // this.configuration = MenuConfigurations['WORKSPACE'];
          this.configuration = this.userMenuConfigurations['HOME'];
          this.currentMenuProfile = 'HOME';
          this.highlightCurrentNavigation(event as NavigationEnd);

          // This is merely a workaround to adjust the margin of the inner menu container when the sidebar has loaded up.
          this.particleService.sideBarInstantiated$.next(true);
        }

      } else {
        // Reset sidebar menu
        this.configuration = {
          header: '',
          showFolders: false,
          topTierMenuItems: [],
          middleTierMenuItems: [],
        };
      }
    }
  }

  public highlightNav(el: any): void {
    // De-highlight all other menu items.
    this.configuration?.topTierMenuItems?.forEach(c => { c.isHighlighted = false; });
    this.configuration?.middleTierMenuItems?.forEach(c => { c.isHighlighted = false; });
    this.customFolders?.forEach((c: { isHighlighted: boolean; }) => { c.isHighlighted = false; });

    // Highlight the current menu item.
    el.isHighlighted = true;
  }

  private highlightCurrentNavigation(event: NavigationEnd): void {
    if (!this.configuration) return;

    try {
      const url = event ? (event.urlAfterRedirects || event.url) : this.router.url;
      const currentNavPath = url.substring(url.lastIndexOf("/") + 1)?.toLowerCase();

      // Mark the next item as highlighted.
      const topTierMatch = this.configuration.topTierMenuItems.findIndex(x => x.name.toLowerCase() === currentNavPath);
      if (topTierMatch > -1) this.highlightNav(this.configuration.topTierMenuItems[topTierMatch]);
      else {
        const middleTierMatch = this.configuration.middleTierMenuItems.findIndex(x => x.name.toLowerCase() === currentNavPath);
        if (middleTierMatch > -1) this.highlightNav(this.configuration.middleTierMenuItems[middleTierMatch]);
        else {
          const customFolderMatch = this.customFolders?.findIndex((x: { name: string; }) => x.name.toLowerCase() === currentNavPath);
          if (customFolderMatch > -1) this.highlightNav(this.customFolders[customFolderMatch]);
        }
      }

    } catch (error) {
      console.warn('highlightCurrentNavigation > error:', error);
    }
  }

  public deleteFolder(folderToDelete: Folder) {
    alert('Deleting folders was using NGB modal, which is now broken. Please fix it with a mat-dialog.')
    // const modalRef = this.manageFolderNameModal.open(ManageFolderModalContentComponent);
    // modalRef.componentInstance.folder = folderToDelete;
    // modalRef.componentInstance.folderAction = "delete";
    // modalRef.componentInstance.isSectionHightlighted = folderToDelete.name === this.sectionToHighlight;
  }

  public addNewFolder() {
    alert('Adding folders was using NGB modal, which is now broken. Please fix it with a mat-dialog.')
    // const modalRef = this.manageFolderNameModal.open(ManageFolderModalContentComponent);
    // modalRef.componentInstance.folderAction = "add";
  }

  public updateFolder(folderToUpdate: Folder) {
    alert('Updating folders was using NGB modal, which is now broken. Please fix it with a mat-dialog.')
    // const modalRef = this.manageFolderNameModal.open(ManageFolderModalContentComponent);
    // modalRef.componentInstance.folder = folderToUpdate;
    // modalRef.componentInstance.folderAction = "update";
    // modalRef.componentInstance.isSectionHightlighted = folderToUpdate.name === this.sectionToHighlight;
  }

  public openSelectFolderOrderModal(foldersToSort: Folder[]): void {
    const dialogRef = this.dialog.open(SelectFolderOrderDisplayModalComponentComponent, {
      data: foldersToSort
    })

    dialogRef.afterClosed()
      .subscribe(result => {
        // Do nothing...
      });
  }

  public drop(event: CdkDragDrop<string[]>, destinationFolder: any): void {
    const particle: any = (event.previousContainer.data) ? event.previousContainer.data[event.previousIndex] : null;
    if (event.container.id === 'basket-1') { // Inbasket
      this.particleService.moveParticleToInbasketViaStore(particle, this.inbasketCount, this.pendingCount);
    }

    if (event.container.id === 'basket-2') { // Pending
      this.openPendParticleModal(particle);
    }

    if (event.container.id === 'basket-3') { // Outbasket
      this.particleService.fileParticleViaStore(particle, this.inbasketCount, this.pendingCount);
    }

    if (event.previousContainer.id === 'folder-1') {
      moveItemInArray(event.previousContainer.data, event.previousIndex, event.currentIndex);
    }
    else if (destinationFolder.section.includes('folder')) {

      if (particle.folders === undefined) {
        particle.folders = {};
      }

      if (particle.folders[destinationFolder.id] === undefined) {
        particle.folders[destinationFolder.id] = destinationFolder.name;
        this.particleService.updateParticleFolderViaStore(particle);
      }
    }
  }

  private openPendParticleModal(particle: any): void {
    const dialogRef = this.dialog.open(PendParticleDisplayModalComponent, {
      data: particle,
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          // this.onPendParticle(result);
          this.particleService.pendParticleViaStore(result); // this.inbasketCount, this.pendingCount
        }
      });
  }

  public browseTo(section: string, folderName: string) {
    if (folderName !== 'folder') {
      const path = `app/${section}` + (folderName ? `/${folderName.toLowerCase()}` : '');
      this.router.navigate([path]);
    }
  }

  // public onPendParticle = (args: any) => {
  //   this.particleService.pendParticleViaStore(result); // this.inbasketCount, this.pendingCount
  // }

  public onRightClick(event: MouseEvent, menuRef: MatMenuTrigger): void {
    if (menuRef) menuRef.toggleMenu();

    event.preventDefault();
    event.stopPropagation();
  }

  public toggleExpand(shouldSkipUpdatePreference: boolean = false): void {
    const newIsSubNavClosed = !this.isSubNavSidebarClosed
    const newSubNavState = newIsSubNavClosed ? "collapsed" : "expanded";

    this.themeService.localUpdateOfSidebarStatus(newIsSubNavClosed);
    if (!shouldSkipUpdatePreference) {
      const preferenceCopy = parseEmbeddedTimestampsToFirebaseDate(deepCopy(this.user.preferences));
      preferenceCopy.sidebarStatus = newIsSubNavClosed;

      this.updateUserStoreDoc({ preferences: preferenceCopy });
    }

    if (newSubNavState === 'expanded') {
      const children_list = document.getElementsByTagName('body')[0].getElementsByClassName('child-list');
      this.resetAllChildElements(children_list);
    }

    setTimeout(() => {
      this.isSubNavSidebarClosed = newIsSubNavClosed;
      this.subNavSidebarState = newSubNavState;
    });

    setTimeout(() => {
      this.expandOnHover = this.isSubNavSidebarClosed
    }, 250);
  }

  private resetAllChildElements(children: any) {
    if (children) {
      for (let i = 0; i < children.length; i++) {
        children[i].style.display = 'none';
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: { target: { innerWidth: number; }; }) {
    this.onResizeSubject.next(event.target.innerWidth);
  }


  // -- DOM Functions Ends -- //

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

}
