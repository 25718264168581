import { SharedDashboardIntermediateComponent } from './dashboard-grid/dashboard-intermediate/dashboard-intermediate.component';
import { SharedInlineClientAbcColumnComponent } from './client-abc/client-abc-table-colum/client-abc-table-colum.component';
import { ClientAbcGroupDialogComponent } from './dialogs/client-abc-group-dialog/client-abc-group-dialog.component';
import { WidgetSettingsDialogComponent } from './dialogs/widget-settings-dialog/widget-settings-dialog.component';
import { AddWidgetDialogComponent } from './dialogs/add-widget-dialog/add-widget-dialog.component';
import { SharedClientAbcGraphComponent } from './graph/client-abc/line-graph-client-abc.component';
import { DashboardGridComponent } from './dashboard-grid/dashboard-grid.component';
import { ResizeObserverDirective } from '../directives/resize-observer.directive';
import { SharedClientAbcComponent } from './client-abc/client-abc.component';
import { Shared7rMonthComponent } from './graph/7r-month/7r-month.component';
import { SharedGraphBaseComponent } from './graph/graph-base.component';
import { Shared7rDayComponent } from './graph/7r-day/7r-day.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MaterialModule } from './material.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
    declarations: [
        SharedGraphBaseComponent,
        DashboardGridComponent,
        Shared7rDayComponent,
        Shared7rMonthComponent,
        SharedClientAbcGraphComponent,
        SharedDashboardIntermediateComponent,
        SharedClientAbcComponent,
        // SearchableSelectComponent, // Converted to standlone.

        // Dialogs.
        WidgetSettingsDialogComponent,
        AddWidgetDialogComponent,
        ClientAbcGroupDialogComponent,

        // Directives.
        ResizeObserverDirective,

        // Not exported.
        SharedInlineClientAbcColumnComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        DragDropModule,
        ReactiveFormsModule
    ],
    exports: [
        SharedGraphBaseComponent,
        DashboardGridComponent,
        Shared7rDayComponent,
        Shared7rMonthComponent,
        SharedClientAbcGraphComponent,
        SharedDashboardIntermediateComponent,
        SharedClientAbcComponent,
        // SearchableSelectComponent, // Converted to standlone.

        // Dialogs.
        WidgetSettingsDialogComponent,
        AddWidgetDialogComponent,
        ClientAbcGroupDialogComponent,

        // Directives.
        ResizeObserverDirective
    ],
})
export class AngularSharedComponentsModule { }
