import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, CanLoad, Route, RouterState } from '@angular/router';
import { areRoutesLoaded, selectAllRoutes, selectRouteByURL, } from './redux/routes/routes.selectors';
import { take, map, switchMap, first, debounceTime, Observable, of, from, timeout, catchError } from 'rxjs';
import { SecurityService } from './shared/services/security.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { allRoutesLoaded } from './redux/routes/routes.actions';
import { AuthService } from './shared/services/auth.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { NewgenusRoute, User } from '@newgenus/common';
import { AppState } from './redux/reducers';
import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { metaObject } from './global';

type Purpose = 'CanActivate' | 'CanActivateChild' | 'CanLoad';

interface UserAuth {
  url: string;
  user: User;
  purpose: Purpose;
  authenticated: boolean | null;
  authorized: boolean | null;
  redirect: string | null;
  meta?: any
}

/**
 * Protect routes that should only be viewed by:
 * 1 - authenticated and initilized (via user service) and
 * 2 - authorized (via feature service)
 * users.
 */
@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  state: RouterState;
  snapshot: RouterStateSnapshot;
  root: ActivatedRouteSnapshot;
  child: any;
  url: any;

  constructor(
    private authService: AuthService,
    public afAuth: AngularFireAuth,
    public db: AngularFirestore,
    private router: Router,
    private security: SecurityService,
    public store: Store<AppState>
  ) {
    this.state = router.routerState;
    this.url = router.url;
    this.snapshot = this.state.snapshot;
    this.root = this.snapshot.root;
    this.child = this.root.firstChild;

  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.check(state.url, 'CanActivate').pipe(
      map((userAuth) => {
        if (!userAuth.authenticated) {
          // console.log("userAuth 1: ", userAuth);
          this.router.navigate(['/login']);
          return false;
        } else if (!userAuth.authorized) {
          // console.log("userAuth 2: ", userAuth);
          this.router.navigate([userAuth.redirect]);
          return false;
        } else {
          // console.log("userAuth 3: ", userAuth);
          return true;
        }
      })
    );
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.check(state.url, 'CanActivateChild');
  }

  public canLoad(route: Route): Observable<boolean> {
    return this.check(route.path as string, 'CanLoad');
  }

  // private functions ---------------------------------------------------------

  private check(url: string, purpose: Purpose): Observable<any> {
    // Take first value or timeout after 3 seconds
    return this.authService.user$.pipe(
      first(),
      // timeout(5000),
      switchMap((user) => {
        return this.checkAuthenticated({
          authenticated: null,
          authorized: null,
          purpose,
          redirect: null,
          url,
          user,
        });
      }),
      map((userAuth) => {
        this.authService.user = userAuth.user;
        return userAuth;
      }),
      switchMap((userAuth) => this.checkAuthorized(userAuth)),
      map((userAuth) => {
        // console.log('auth guard userAuth: ', userAuth);
        if (userAuth.redirect) {
          if (!userAuth.authenticated) {
            this.authService.redirectUrl = url;
          }
          if (!userAuth.authorized) {
            this.authService.redirectUrl = url;
          }
        }
        return userAuth; // userAuth.authenticated && userAuth.authorized;; //  && userAuth.authorized;
      }),
      catchError(error => {
        // console.error(`Auth request timed out after: 5s > error`, error);
        return of(false);
      })
    );
  }

  private checkAuthenticated(userAuth: UserAuth): Observable<UserAuth> {
    if (userAuth.user === null) {
      // console.log("step 1!");
      userAuth.authenticated = false;
      userAuth.redirect = userAuth.redirect || '/login';
      return of(userAuth);
    } else {
      // console.log("step 2!", userAuth);
      userAuth.authenticated = true;
      return of(userAuth);
    }
  }

  private checkAuthorized(userAuth: UserAuth): Observable<UserAuth> {
    return from(
      new Promise<UserAuth>((resolve, reject) => {
        if (userAuth.user === null) {
          // not logged in
          console.error('No user session.');
          reject();
        }

        // const featureKeys = userAuth.user.organizations[userAuth.user.organizations.selected].features;

        this.fetchRoutesByURL(userAuth.url)
          .pipe(first(), debounceTime(300))
          .toPromise()
          .then(async (routes) => {
            let meta = {} as any;

            if (!routes || routes.length === 0) {
              console.error('checkAuthorized > fetchRoutesByURL > routes.length === 0');
              // reject("routes not loaded into store yet");
              if (userAuth.user.isDeveloper) {
                userAuth.authorized = true;
                userAuth['meta'] = meta;
                metaObject['userAuth'] = userAuth;
                this.security.userAuth = userAuth;
                resolve(userAuth);
                return;
              }
            }

            if (!userAuth.user.organizations) {
              return;
            }

            const featureKeys = userAuth.user.organizations[userAuth.user.organizations.selected].features;

            let routeFeatureExists = false;
            // setup features meta
            for await (const route of routes || []) {
              // routes.forEach(async (route) => {
              meta['features'] = route.features;
              meta = { ...meta, ...route };

              // const routeFound = routes.find(r => r.route === userAuth.url);
              const featureKeyFound = featureKeys.find((item) => item === route.routeFeature);

              // console.log('checkAuthorized > featureKeyFound:', featureKeyFound);
              // console.log('checkAuthorized > onRoute doc:', route);
              // console.log('checkAuthorized > featureKeys:', featureKeys);

              let mayAccessSystemRoute = false;
              if (route.systemRoute) {
                mayAccessSystemRoute = await this.burrowParentHierarchy(route, featureKeys);
                // console.log('checkAuthorized > burrowParentHierarchy > mayAccessBySystemFeature:', mayAccessSystemRoute);
              }

              // Developers can access all routes.
              if (userAuth.user.isDeveloper || featureKeyFound || mayAccessSystemRoute) {
                routeFeatureExists = true;

                if (userAuth.user.isDeveloper && featureKeyFound === undefined)
                  console.warn(
                    `Developer user <${userAuth.user?.email}> is missing feature <${route?.routeFeature}> for route <${route.route}>.`
                  );
              } else {
                routeFeatureExists = false;
              }
              // });
            }

            // setup permissions meta
            const org = userAuth.user.organizations[userAuth.user.organizations.selected];

            const permissions = [];
            for (const permission of org.permissions) {
              permissions.push(permission);
            }

            meta['permissions'] = permissions;

            if (userAuth.authenticated !== true) {
              userAuth.authorized = false;
              userAuth.redirect = userAuth.redirect || '/app/home';
              resolve(userAuth);
            } else if (routeFeatureExists) {
              userAuth.authorized = true;
              userAuth['meta'] = meta;
              metaObject['userAuth'] = userAuth;
              this.security.userAuth = userAuth;
              resolve(userAuth);
            } else {
              userAuth.authorized = false;
              userAuth.redirect = userAuth.redirect || '/app/home';
              resolve(userAuth);
            }
          });
      })
    );

  }

  private async burrowParentHierarchy(currentRoute: NewgenusRoute, featureKeys: string[]) {
    return new Promise<boolean>((resolve) => {
      this.store
        .select(selectAllRoutes)
        .pipe(first())
        .toPromise()
        .then((allRoutes) => {

          const mayAccessSystemRoute: boolean = allRoutes
            ?.filter((r) => r.systemRoute)
            ?.some((sr) => {
              return (!sr.parentFeatures || sr.parentFeatures.length === 0) &&
                // Check the the current route is listed as a feature in the parent route.
                sr.features.includes(currentRoute.routeFeature)
                &&
                // Check that the features the user has access to includes a match in the parent route.
                sr.features.some((rFeatureKey) => featureKeys.includes(rFeatureKey))
            }) || false;

          resolve(mayAccessSystemRoute);
        });
    });
  }

  private fetchRoutesByURL(url: string): Observable<NewgenusRoute[]> {
    // strip query params
    const strippedUrl = url.split('?')[0];

    return this.store.pipe(select(areRoutesLoaded)).pipe(
      take(1),
      switchMap((routesAreLoaded) => {
        if (routesAreLoaded) {
          return (
            this.store
              // Fetch all matching routes.
              .pipe(select(selectRouteByURL(strippedUrl)))
              .pipe(map((resp) => ({ routes: resp, from: 'ngrx' })))
          );
        } else {
          return this.db
            .collection<NewgenusRoute>('routes')
            .get()
            .pipe(
              map((routes) => {
                // Parse from Firestore Documents.
                const allRoutes = routes.docs.map((doc) => doc.data());

                // Dispatch the fetched routes to the store.
                this.store.dispatch(allRoutesLoaded({ routes: allRoutes }));

                // Filter matching routes.
                const matchingRoutes = allRoutes.filter((routeDoc) => {
                  return routeDoc.route === strippedUrl;
                });

                // Return the matching routes.
                return { routes: matchingRoutes, from: 'firestore' };
              })
            );
        }
      }),
      // tap((result => console.log(result))),
      map((results) => results.routes)
    );
  }
}
