<div style="padding: 0 0.5rem;" >
    <span [id]="number" [innerText]="truncate(fileName)"></span>
    <button mat-icon-button
        color="accent"
        id="removeBtn"
        (click)="remove(fileName, particleKey)"
        [disabled]="currentFileUpload"
        title="Remove this attachment"
        aria-label="Remove this attachment"
    ><mat-icon>cancel</mat-icon>
    </button>
    <mat-progress-bar *ngIf="currentFileUpload" [value]="progress.percentage" color="accent" mode="determinate"></mat-progress-bar>
</div>

<!-- {{number}}  {{fileName}} <span id="{{number}}">-->
    <!-- <button (click)="remove(fileName, particleKey)" [disabled]="currentFileUpload"
        class="btn btn-sm btn-primary ml-2 mb-2" id="removeBtn" type="button">
        <fa-icon [icon]="faTimes"></fa-icon>
    </button> -->
<!-- <div *ngIf="currentFileUpload" class="progress">
    <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
        attr.aria-valuenow="{{progress.percentage}}" aria-valuemin="0" aria-valuemax="100"
        [ngStyle]="{width:progress.percentage+'%'}">
        {{progress.percentage}}%
    </div>
</div> -->