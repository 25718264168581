import { Particle, IQConfiguration, SearchMeta } from '@newgenus/common';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, catchError } from 'rxjs';
import { ErrorUrgency } from '../shared.models';
import { ErrorService } from './error.service';
import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';

const suffix = '-default';

/**
 * General API requests
 */
@Injectable()
export class ApiService {

  constructor(
    private errorService: ErrorService,
    private http: HttpClient,
  ) { }

  // ******************** Google cloud functions *****************************

  /**
   * @deprecated - pattern is not used anymore.
   */
  public async retrieveIQConfigurations(): Promise<{ message: string, payload: Array<IQConfiguration> }> {
    const idToken = await firebase.auth().currentUser?.getIdToken() as string;
    // us-central1-api-integrationsConfigurations-default
    const url = this.getGoogleCloudEnvURL() + `integrationsConfigurations${suffix}/iq`;
    return this.http
      .get<any>(url, this.getOptions(idToken))
      .toPromise();
  }

  public async retrySendParticle(particle: Particle): Promise<{ message: string }> {
    const idToken = await firebase.auth().currentUser?.getIdToken() as string;
    const url = this.getGoogleCloudEnvURL() + `emailsSendAgain${suffix}`;

    return this.http
      .post<any>(url, JSON.stringify({ particleKey: particle.particleKey, errorDocId: particle.sendMeta?.errorDocKey }), this.getOptions(idToken))
      .toPromise();
  }

  public retrieveGmailAliases(idToken: string, emailAddress: string): Observable<any> {
    const url = this.getGoogleCloudEnvURL() + `integrationsRetrieveGmailAliases${suffix}?idToken=${idToken}`;
    return this.http
      .post<object>(url, JSON.stringify({ tokenId: idToken, emailAddress: emailAddress }), this.getOptions())
      .pipe(
        catchError(this.handleError<any>('integrationsRetrieveGmailAliases')),
      );
  }

  // public sendNewDraftWithUserToken(idToken: string, particle: Particle, shouldSend: boolean): Observable<any> {
  //   const url = this.getGoogleCloudEnvURL() + `draftsSendDraftEmail?idToken=${idToken}`;
  //   return this.http
  //     // tslint:disable-next-line: object-literal-shorthand
  //     .post<any>(url, JSON.stringify({ tokenId: idToken, particle: particle, send: shouldSend }), this.getOptions())
  //     .pipe(
  //       catchError(this.handleError<any>('sendNewDraftWithUserToken')),
  //     );
  // }

  public sendNewDraftAndAttachmentsWithUserToken(idToken: string, particle: Particle, shouldSend: boolean): Observable<any> {
    const url = this.getGoogleCloudEnvURL() + `draftsSendDraftEmailWithAttachment${suffix}?idToken=${idToken}`;

    return this.http
      // tslint:disable-next-line: object-literal-shorthand
      .post<any>(url, JSON.stringify({ tokenId: idToken, particle: particle, send: shouldSend }), this.getOptions())
      .pipe(
        catchError(this.handleError<any>('sendNewDraftAndAttachmentsWithUserToken')),
      );
  }

  public downloadAttachment(idToken: string, particle: Partial<Particle>, attachmentId: string): Observable<any> {
    const url = this.getGoogleCloudEnvURL() + `emailsDownloadAttachment${suffix}?attachmentId=${attachmentId}&messageId=${particle.emailId}&particleType=${particle.particleType}&emailAddress=${particle.clientEmailAddress}`;

    return this.http
      // tslint:disable-next-line: object-literal-shorthand
      .get<any>(url, this.getCacheOptions(idToken))
      .pipe(
        catchError(this.handleError<any>('downloadAttachment')),
      );
  }

  public fetchEmailContent(idToken: string, messageId: string, particleType: string, clientEmailAddress: string): Observable<any> {
    const url = this.getGoogleCloudEnvURL() + `emailsFetchMessage-content?idToken=${idToken}`;

    return this.http
      // tslint:disable-next-line: object-literal-shorthand
      .post<any>(url, JSON.stringify({ particleType: particleType, messageId: messageId, emailAddress: clientEmailAddress }), this.getOptions())
      .pipe(
        catchError(this.handleError<any>('fetchEmailContent')),
      );
  }

  public fetchEmailAttachments(idToken: string, messageId: string, particleType: string, clientEmailAddress: string): Observable<any> {
    const url = this.getGoogleCloudEnvURL() + `emailsFetchMessage-attachments?idToken=${idToken}`;

    return this.http
      // tslint:disable-next-line: object-literal-shorthand
      .post<any>(url, JSON.stringify({ particleType: particleType, messageId: messageId, emailAddress: clientEmailAddress }), this.getOptions())
      .pipe(
        catchError(this.handleError<any>('fetchEmailAttachments')),
      );
  }

  // public importEmailWithUserToken(idToken: string, messageId: string): Observable<any> {
  //   const url = this.getGoogleCloudEnvURL() + `syncImportMessage?idToken=${idToken}&gmailId=messageId`;

  //   return this.http
  //     // tslint:disable-next-line: object-literal-shorthand
  //     .post<any>(url, JSON.stringify({ tokenId: idToken, messageId: messageId }), this.getOptions())
  //     .pipe(
  //       catchError(this.handleError<any>('importEmailWithUserToken')),
  //     );
  // }

  public importEmailParticleWithUserToken(idToken: string, particle: Particle): Observable<any> {
    const url = this.getGoogleCloudEnvURL() + `syncImportEmailParticle-default?idToken=${idToken}`;

    return this.http
      // tslint:disable-next-line: object-literal-shorthand
      .post<any>(url, JSON.stringify({ tokenId: idToken, particle: particle }), this.getOptions())
      .pipe(
        catchError(this.handleError<any>('importEmailParticleWithUserToken')),
      );
  }

  public sendNewEmailWithUserToken(idToken: string, particle: Particle): Observable<any> {
    const url = this.getGoogleCloudEnvURL() + `emailsSendNewEmailMessage?idToken=${idToken}`;

    return this.http
      // tslint:disable-next-line: object-literal-shorthand
      .post<any>(url, JSON.stringify({ tokenId: idToken, particle: particle }), this.getOptions())
      .pipe(
        catchError(this.handleError<any>('sendNewEmailWithUserToken')),
      );
  }

  public searchWithUserToken(idToken: string, searchMeta: SearchMeta): Observable<any> {
    const url = this.getGoogleCloudEnvURL() + `searchSearchMessages${suffix}?idToken=${idToken}`;
    return this.http
      // tslint:disable-next-line: object-literal-shorthand
      .post<any>(url, JSON.stringify({ searchMeta: searchMeta }), this.getOptions())
      .pipe(
        catchError(this.handleError<any>('searchWithUserToken error!!')),
      );
  }

  public setUserClaimsForRegistration(idToken: string): Observable<any> {
    const url = this.getGoogleCloudEnvURL() + `adminSetUserClaimsForRegistration${suffix}?idToken=${idToken}`;
    return this.http
      // tslint:disable-next-line: object-literal-shorthand
      .post<any>(url, this.getOptions())
      .pipe(
        catchError(this.handleError<any>('setUserClaimsForRegistration error!!')),
      );
  }

  public unlinkFromGmail(idToken: string, gmailEmailAddress: string): Observable<any> {
    const url = this.getGoogleCloudEnvURL() + `securityToolsInitWatchStop${suffix}?idToken=${idToken}&emailAddress=${gmailEmailAddress}`;

    return this.http
      // tslint:disable-next-line: object-literal-shorthand
      .post<any>(url, {}, this.getOptions())
      .pipe(
        catchError(this.handleError<any>('unlinkFromGmail')),
      );
  }

  public unlinkMicrosoftAccount(idToken: string, emailAddress: string): Observable<any> {
    const url = this.getGoogleCloudEnvURL() + `securityToolsAzure-oauth/hold-your-horses?idToken=${idToken}`;

    return this.http
      // tslint:disable-next-line: object-literal-shorthand
      .post<any>(url, { emailAddress: emailAddress }, this.getOptions())
      .pipe(
        catchError(this.handleError<any>('unlinkMicrosoftAccount')),
      );
  }

  // Helper functions
  //

  /**
   * Returns base url for the current environment you are in
   * 
   * @TODO move to environment settings
   */
  private getGoogleCloudEnvURL(): string {
    if (environment.useEmulators) {
      return environment.emulator_config.url;
    } else {
      return environment.gcp.baseUrl;
    }
  }

  /**
   * Returns default API request-options
   */
  private getOptions(idToken?: string): { headers: HttpHeaders } {
    return idToken ? {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authentication': idToken
      })
    }
      : {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };
  }

  /**
   * Returns default API request-options with cache header appended.
   */
  private getCacheOptions(idToken: string): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control': 'max-age=259200',
        'Authentication': idToken
        // 3 days: 259200 
      })
    };
  }

  /**
   * Handles API errors
   */
  private handleError<T>(operation: string, urgency = ErrorUrgency.Error, result?: T): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      const errorMsg = `Error while fetching ${operation}: ${error.body ? error.body.error : (error.message ? error.message : error)}`;
      console.error('errorMsg: ', errorMsg);
      if (urgency === ErrorUrgency.Crucial) {
        this.errorService.throwError(errorMsg, urgency);
      }
      return of(result as T);
    };
  }
}