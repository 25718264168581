import { OrgAssignSecurityGroupsDialogComponent } from '../dialogs/org-assign-security-groups-dialog';
import { OrgNewHatDialogComponent } from '../dialogs/org-new-hat-dialog/org-new-hat-dialog';
import { OrgPolicyDialogComponent } from '../dialogs/org-policy-dialog/org-policy-dialog';
import { OrgUserDetailsDialogComponent } from '../dialogs/org-user-details-dialog';
import { OrgAssignUserDialogComponent } from '../dialogs/org-assign-user-dialog';
import { OrgHatDialogComponent } from '../dialogs/org-hat-dialog/org-hat-dialog';
import { OrgInviteUserDialogComponent } from '../dialogs/org-new-user-dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ECONode, Organization } from '@newgenus/common';
import { OrgBoardService } from './org-board-service';
import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { take } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OrgDialogServiceService {

    /**
     * The organization to be used in the dialog.
     * This should be set before opening the dialog, preferably in the parent/main org board component.
    *
     * @type {(Organization | undefined)}
     * @memberof OrgDialogServiceService
     */
    public organization: Organization | undefined;

    constructor(public dialog: MatDialog,
        private snackbar: MatSnackBar,
        private obService: OrgBoardService
    ) {
    }


    public openOrgUserDetailsDialog(node: ECONode<any>, user: any): void {
        this.dialog.open(OrgUserDetailsDialogComponent, {
            data: {
                node,
                user,
                organization: this.organization
            },
            height: '85%',
            width: '85%',
            maxHeight: 'unset',
            maxWidth: 'unset',
        })
            .afterClosed()
            .pipe(take(1))
            .subscribe(result => {
                console.log('dialog close result: ', result);

            });
    }

    public openHat(node: ECONode<any>, user?: any): void {
        this.dialog.open(OrgHatDialogComponent, {
            data: {
                node,
                user,
                organization: this.organization
            },
            height: '85%',
            width: '85%',
            maxHeight: 'unset',
            maxWidth: 'unset',
        })
            .afterClosed()
            .pipe(take(1))
            .subscribe(result => {
                console.log('dialog close result: ', result);

            });
    }

    public openSecurityGroupsDialog(node: ECONode<any>, user?: any): void {
        this.dialog.open(OrgAssignSecurityGroupsDialogComponent, {
            data: {
                node,
                user,
                organization: this.organization
            },
            height: '85%',
            width: '85%',
            maxHeight: 'unset',
            maxWidth: 'unset',
        })
            .afterClosed()
            .pipe(take(1))
            .subscribe(result => {
                console.log('dialog close result: ', result);

            });
    }

    public openAssignUserDialog(node: ECONode<any>, user?: any): void {
        this.dialog.open(OrgAssignUserDialogComponent, {
            data: {
                node,
                user,
                organization: this.organization
            },
            height: '85%',
            width: '85%',
            maxHeight: 'unset',
            maxWidth: 'unset',
        })
            .afterClosed()
            .pipe(take(1))
            .subscribe(result => {
                console.log('dialog close result: ', result);

            });
    }

    public openInviteUserDialog(): void {
        this.dialog.open<OrgInviteUserDialogComponent, any, Array<string>>
            (OrgInviteUserDialogComponent, {
                data: {
                    organization: this.organization
                },
                height: '45%',
                width: '65%',
                maxHeight: 'unset',
                maxWidth: 'unset',
            })
            .afterClosed()
            .pipe(take(1))
            .subscribe(result => {
                console.log('dialog close result: ', result);
                // Send invitations to the users.
                if (result !== undefined && this.organization) {
                    this.obService.inviteUsersToOrg(this.organization.key, result)
                }
            });
    }

    public openPolicyDialog(): void {
        this.dialog.open<OrgPolicyDialogComponent, any, Array<string>>
            (OrgPolicyDialogComponent, {
                data: {
                    organization: this.organization
                },
                height: '65%',
                width: '65%',
                maxHeight: 'unset',
                maxWidth: 'unset',
            })
            .afterClosed()
            .pipe(take(1))
            .subscribe(result => {
                console.log('dialog close result: ', result);

            });
    }

    public openNewHatDialog(node?: ECONode<any>): void {
        this.dialog.open<OrgNewHatDialogComponent, any, Array<string>>
            (OrgNewHatDialogComponent, {
                data: {
                    organization: this.organization,
                    node: node
                },
                height: '65%',
                width: '65%',
                maxHeight: 'unset',
                maxWidth: 'unset',
            })
            .afterClosed()
            .pipe(take(1))
            .subscribe(result => {
                console.log('dialog close result: ', result);

            });
    }
}

