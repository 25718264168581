import { AngularFireDatabase } from '@angular/fire/compat/database';
import { ErrorHandler, Injectable, Optional } from '@angular/core';


@Injectable({
	providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {

	private counter = 0;

	constructor(@Optional() db: AngularFireDatabase) { }

	private saveLog(error: any) {
		this.counter++;
		if (JSON.stringify(error) === '{}') {
			error = {
				mesage: error.message,
				stack: error.stack
			};
		}

		// console.log('GlobalErrorHandlerService > postLog (SKIPPED) > error:', error);
		// TODO: Read: https://firebase.google.com/docs/firestore/ttl
		// And create a collection of logs with a TTL of 30 days.
		// this.db.collection('logs').add({
		// 	error: error,
		// 	id: this.counter,
		// 	timestamp: Date.now()
		// });

	}

	public handleError(error: any): void {
		console.error('GlobalErrorHandlerService > handleError > error:', error);
		this.saveLog(error);
	}

}
