import { NgxLoggerLevel } from 'ngx-logger';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  env: 'develop',
  production: false,
  useServiceWorker: false,
  useEmulators: false,
  isDockerized: false,
  fileServerBaseUrlTimeLine: '//localhost:4200/',
  mediaServerBaseUrlTimeLine: '//localhost:4200/',
  fileServerBaseUrl: 'api/',
  mediaServerBaseUrl: 'api/',
  logServerUrl: '',
  logLevel: NgxLoggerLevel.DEBUG,
  logServerLevel: NgxLoggerLevel.INFO,
  tinyMceKey: "u1sxmv104legrc3yoerd9dmfwywzljcf48n8pkwz7yd3oa0z",
  firebase: {
    apiKey: "AIzaSyAGG9phd2ujUZxxMrikl5WRFnxZXkZJ90A",
    authDomain: "newgenus-dev-19da7.firebaseapp.com",
    databaseURL: "https://newgenus-dev-19da7.firebaseio.com",
    projectId: "newgenus-dev-19da7",
    storageBucket: "newgenus-dev-19da7.appspot.com",
    messagingSenderId: "1040159889146",
    appId: "1:1040159889146:web:3e24ebacaf9690b6f59b43",
    measurementId: "G-3ZJE5R4FCH"
  },
  emulator_config: {
    region: 'us-central1',
    url: 'http://localhost:5001/newgenus-dev-19da7/us-central1/api-',
    azureOauthLink: 'http://localhost:5001/newgenus-dev-19da7/us-central1/api-securityToolsAzure-oauth/donkey-boy-init',
    gmailOauthLink: 'http://localhost:5001/newgenus-dev-19da7/us-central1/api-securityToolsOauth2Init-default',
    useAuthEmulator: false
  },
  gcp: {
    oauthLink: 'https://us-central1-newgenus-dev-19da7.cloudfunctions.net/api-securityToolsOauth2Init-default',
    toolsOauthInit: 'https://us-central1-newgenus-dev-19da7.cloudfunctions.net/api-securityToolsOauth2Init-default',
    baseUrl: 'https://us-central1-newgenus-dev-19da7.cloudfunctions.net/api-'
  },
  azure: {
    oauthLink: 'https://us-central1-newgenus-dev-19da7.cloudfunctions.net/api-securityToolsAzure-oauth/donkey-boy-init'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
