import { Component, OnDestroy, OnInit, AfterContentInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ParticleService } from '../../services/particle.service';
import { DisplayService } from '../../services/display.service';
import { StoreUser } from '../../../workspace/user.abstract';
import { Folder, Particle } from '@newgenus/common';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

@Component({
  selector: 'newgenus-new-folder-modal-content',
  template: `
    <div class="modal-header">
      <!--<h4 class="modal-title">Provide your folder name</h4>-->
      <h4 class="modal-title">{{title}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form [formGroup]="folderForm">
      <div class="modal-body">
      <input id="name" formControlName="name" data-cy="input-folder"  placeholder="{{actionMessage}}"><br/>
        <span *ngIf="displayError" style="color:red;font-size:11px">{{errorMessage}}</span>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-outline-dark" data-cy="proceed-folder" (click)="onSubmit()">{{operation}}</button>
        <button type="button" class="btn btn-outline-dark" data-cy="cancel-folder" (click)="onClose()">Cancel</button>
      </div>
    </form> 
  `,
  styles: ['input { width: 100%; border: none; outline: none; }']
})
export class ManageFolderModalContentComponent extends StoreUser implements OnInit, AfterContentInit, OnDestroy {

  private destroy$ = new Subject<void>();

  // -- folder variables
  public folder: Folder;
  public folderNames: string[] = [];

  // -- particle variables 
  private particles!: Particle[];

  // -- profile variables -- //
  public userProfileSubscription: any;

  // -- other variables -- //
  public folderForm: FormGroup;
  public displayError = false;
  public folderAction!: string;
  public isSectionHightlighted!: boolean;

  // -- folder display variables
  public title!: string;
  public actionMessage!: string;
  public errorMessage!: string;
  public operation!: string;
  userFolderSubscription: any;
  userParticleSubscription: any;
  public activeModal: any;
  
  constructor( //public activeModal: NgbActiveModal,
    private particleService: ParticleService,
    private displayService: DisplayService,
    private router: Router,
    override store: Store) {
    super(store);
    this.initStoreUser(this.destroy$)

    this.folderForm = new FormGroup({
      name: new FormControl('', [Validators.required])
    });

    this.folderForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {

        if ((this.folderForm.get('name')?.valid && this.folderForm.get('name')?.touched) || value) {
          this.displayError = false;
        }

      })

    this.folder = {
      folderKey: '',
      id: '',
      uid: '',
      name: '',
      count: 0,
      icon: '',
      particles: [],
      section: '',
      position: 0
    };

    this.userParticleSubscription = this.particleService.userParticles$
      .pipe(takeUntil(this.destroy$))
      .subscribe((particles: Array<Particle>) => {

        this.particles = particles ? particles.filter(c => c.folders && c.folders[this.folder.id] != void 0) : [];
      });

    this.userFolderSubscription = this.particleService.userFolders$ // TODO: duplicate code between baskets, cleanup.
      .pipe(takeUntil(this.destroy$))
      .subscribe(folders => {
        if (folders?.length > 0) {
          this.folderNames = [...folders.map((folder: any) => folder.name)];
        }
      });
  }

  public ngOnInit(): void {
    switch (this.folderAction) {
      case "add":
        this.loadFolderAddingDisplay();
        break;

      case "delete":
        this.loadFolderDeleteDisplay();
        break;

      case "update":
        this.loadFolderUpdateDisplay();
        break;
    }
  }

  public ngAfterContentInit(): void {
    const nameField = document.getElementById('name');
    if (nameField) nameField.focus();
  }

  // -- initialization functions -- //
  loadFolderAddingDisplay() {
    this.title = "New folder creation Section";
    this.actionMessage = "enter the name of the folder to create.";
    this.errorMessage = "the folder name supplied do not match a valid folder name."
    this.operation = "Create";
  }

  loadFolderDeleteDisplay() {
    this.title = `You are about to delete: ${this.folder.name}`;
    this.actionMessage = "enter the name of the folder to delete.";
    this.errorMessage = "the folder name supplied do not match the folder selected.";
    this.operation = "Delete";
  }

  loadFolderUpdateDisplay() {
    this.title = `You are about to rename: ${this.folder.name}`;
    this.actionMessage = "enter the new name of the folder.";
    this.errorMessage = "the folder name supplied do not match a valid folder name.";
    this.operation = "Update";
  }
  // -- initialization functions end -- //

  // -- add folder -- //
  addFolder() {
    const nameIsAvailaible = this.checkNameIsAvailable(this.folderForm.get('name')?.value);

    if (this.folderForm.get('name')?.valid && nameIsAvailaible) {

      const folderName: string = this.folderForm.get('name')?.value;

      if (folderName || folderName !== '') {
        this.folder.name = folderName;
        this.folder.count = 0;
        this.folder.icon = "far fa-check-circle";
        this.folder.particles = [];
        this.folder.uid = this.userId;
        this.folder.section = "folder";
        this.folder.id = folderName.replace(' ', '_');

        this.particleService.createFolder(this.folder);
      }

      this.onClose();
    }
    else {
      this.displayError = true;
      this.errorMessage = this.getAddingError(this.folderForm.get('name')?.valid || false, nameIsAvailaible)
    }
  }

  checkNameIsAvailable(folderName: string): boolean {
    let isNotAvailable = false;

    for (let i = 0; i < this.folderNames.length; i++) {
      const foundFolderName = this.folderNames[i];

      if (foundFolderName === folderName) {
        isNotAvailable = true;
        break;
      }
    }

    return !(isNotAvailable);
  }

  getAddingError(folderNameIsvalid: boolean, nameIsAvailaible: boolean): string {

    if (!folderNameIsvalid && !nameIsAvailaible) {
      return "The name supplied is not available to use.";
    }
    else if (this.folderForm.get('name')?.valid && !nameIsAvailaible) {
      return "The folder name supplied is already taken.";
    }
    else if (!this.folderForm.get('name')?.valid && nameIsAvailaible) {
      return "The folder name supplied is not valid.";
    } else {
      return '';
    }
  }
  // -- add folder ends -- //

  // -- delete folder -- //
  deleteFolder() {
    const nameIsValid = this.checkNameIsValid(this.folderForm.get('name')?.value);

    if (this.folderForm.get('name')?.valid && nameIsValid) {
      this.particleService.deleteFolder(this.folder);
      this.removeFolderReferenceInParticles(this.particles, this.folder);

      if (this.isSectionHightlighted) {
        this.displayService.updateHighLightedValue('inbasket');
        this.navigateTo('app/workspace/inbasket')
      }

      this.onClose();
    }
    else {
      this.displayError = true;
      this.errorMessage = this.getDeletingErrorMessage(this.folderForm.get('name')?.valid || false, nameIsValid)
    }
  }

  removeFolderReferenceInParticles(particles: Particle[], folder: Folder) {

    for (let i = 0; i < particles.length; i++) {
      const particle = particles[i];
      this.particleService.removeCustomFolderInParticle(particle, folder);
    }
  }

  navigateTo(sectionName: string) {
    this.router.navigate([`app/workspace/${sectionName.toLowerCase()}`]);
  }

  getDeletingErrorMessage(folderNameInvalid: boolean, nameIsAMatch: boolean): string {

    if (!folderNameInvalid) {
      return "The name supplied is not a valid name for folders."
    }
    else if (!nameIsAMatch) {
      return "The name supplied does not match the name of the folder to delete."
    } else {
      return '';
    }
  }

  checkNameIsValid(value: any) {
    return value === this.folder.name;
  }
  // -- delete folder ends -- //

  // -- update folder -- //
  updateFolder() {
    const nameIsAvailable = this.checkNameIsAvailable(this.folderForm.get('name')?.value);

    const oldFolder = Object.assign({}, this.folder);
    const newFolder = Object.assign({}, this.folder);

    newFolder.name = this.folderForm.get('name')?.value;
    newFolder.id = this.folderForm.get('name')?.value;
    const nameIsNotSame = oldFolder.name !== newFolder.name;

    if (this.folderForm.get('name')?.valid && nameIsAvailable && nameIsNotSame) {

      this.updateFolderReference(this.particles, oldFolder, newFolder);
      this.particleService.updateCustomFolder(newFolder);

      if (this.isSectionHightlighted) {
        this.navigateTo(newFolder.id);
        this.displayService.updateHighLightedValue(newFolder.id);
      }

      this.onClose();
    }
    else {
      this.displayError = true;
      this.errorMessage = this.getUpdatingErrorMessage(this.folderForm.get('name')?.valid || false, nameIsAvailable, nameIsNotSame)
    }
  }

  getUpdatingErrorMessage(folderNameIsvalid: boolean, nameIsAvailaible: any, nameIsNotSame: boolean): string {

    if (!nameIsNotSame) {
      return "the name supplied matches the folder to update";
    }
    else if (!folderNameIsvalid && !nameIsAvailaible) {
      return "The name supplied is not available to use.";
    }
    else if (this.folderForm.get('name')?.valid && !nameIsAvailaible) {
      return "The folder name supplied is already taken.";
    }
    else if (!this.folderForm.get('name')?.valid && nameIsAvailaible) {
      return "The folder name supplied is not valid.";
    } else {
      return '';
    }
  }

  updateFolderReference(particles: Particle[], oldFolder: Folder, newFolder: Folder) {

    for (let i = 0; i < particles.length; i++) {
      const particle = particles[i];
      this.particleService.updateCustomFolderInParticle(particle, oldFolder, newFolder);
    }
  }
  // -- update folder ends -- //

  onSubmit(): void {

    switch (this.folderAction) {
      case "add":
        this.addFolder();
        break;

      case "delete":
        this.deleteFolder();
        break;

      case "update":
        this.updateFolder();
        break;
    }
  }

  onClose(): void {
    this.activeModal.close('Close click')
  }

  ngOnDestroy(): void {
    this.userProfileSubscription.unsubscribe();
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

}