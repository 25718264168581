import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RightSideBarService {

    // The current state of the right sidebar.
    public isOpen = false;
    // The current state of the right sidebar as an observable.
    public isOpen$ = new BehaviorSubject<boolean>(this.isOpen);

    // BehaviorSubject triggering the opening of the right sidebar.
    public open$ = new BehaviorSubject<boolean>(false);
    // BehaviorSubject triggering the closing of the right sidebar.
    public close$ = new BehaviorSubject<boolean>(false);

    public templateRef: TemplateRef<any> | null = null;
    public templateData: any | null = null;
    public sidebarMode: 'push' | 'side' | 'over' = 'over';


    public markAsClosed(): void {
        // console.log('markAsClosed');
        this.isOpen = false;
        this.isOpen$.next(this.isOpen);
        setTimeout(() => {
            this.templateData = null;
            this.templateRef = null;
        }, 500);
    }

    public markAsOpened(): void {
        // console.log('markAsOpened');
        this.isOpen = true;
        this.isOpen$.next(this.isOpen);
    }

    public openTemplate<T>(template: TemplateRef<T>, data?: any): void {
        // console.log('openTemplate > template: ', template);
        // console.log('openTemplate > data: ', data);

        this.templateRef = template;
        this.templateData = data ? { data: data } : null;
        this.open$.next(true);
    }


}