import { Pipe, PipeTransform } from '@angular/core';
import { Timestamp } from 'firebase/firestore';
import firebase from 'firebase/compat/app';
import * as moment from 'moment';

@Pipe({
    name: 'fsDate'
})
export class FirebaseDatePipe implements PipeTransform {
    transform(date: any, toMoment = false, momentFormat?: string) {
        let dateConverted: any = null;

        if (date instanceof Timestamp) {
            dateConverted = date.toDate();
        } else {
            dateConverted = date;
        }

        if (!toMoment){
            return dateConverted;
        } else {
            const mDate = moment(dateConverted).local();
            return momentFormat ? mDate.format(momentFormat) : mDate;
        }

    }
}
