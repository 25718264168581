import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { User, DeleteProcess, AttachmentProcess, deepCopy } from '@newgenus/common';
import { selectParticle } from '../../redux/particles/particles.selectors';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as mimes from '../../../assets/lib/mime-db.json';
import { map, take, takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AppState } from '../../redux/reducers';
import { AuthService } from './auth.service';
import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

interface DeleteAttachment {
    filename: string;
    particleKey: string;
    deleteProgress: DeleteProcess;
    saved: boolean;
}

interface AttachmentUpload {
    attachmentProgress: AttachmentProcess;
    particleKey: string;
    doneUploadingAttachments: Array<{ [particleKey: string]: string[] }>;
    attachmentsBusyUploading: Array<{ [particleKey: string]: string[] }>;
    saved: boolean;
    uploaded?: string;
}

@Injectable({
    providedIn: 'root',
})
export class FileService {
    private destroy$ = new Subject<void>();
    user!: User;
    uploadPercentArray: { subscribe: (arg0: (val: any) => void) => void; }[]; // : [Observable<number>];
    uploadPercent$!: Observable<number | undefined>;
    downloadURL!: Observable<string>;
    url!: string;
    public imageSubscription: Subject<string> = new Subject<string>();
    public attachmentInProgress$ = new BehaviorSubject<AttachmentUpload | null>(null);
    public deletedAttachmentsOb$ = new BehaviorSubject<DeleteAttachment | null>(null);
    sendingInProgress: boolean;

    task!: AngularFireUploadTask;
    cachedParticle: any;
    files: string[];
    shouldDeleteAttachment: boolean;
    deletedAttachmentNames: string[];
    shouldAddAttachment!: boolean;
    fileUploadPercentArray: any;
    // uploadState$: Observable<unknown>;

    attachmentsBusyUploading: any[];
    doneUploadingAttachments: any[]; // to be replaced by active drafts
    attachmentsToDelete: any[];
    combineLatestDeletedAttachmentsAndUploadStatusOb$: any;

    constructor(
        public auth: AuthService,
        private storage: AngularFireStorage,
        private logger: NGXLogger,
        private http: HttpClient,
        private store: Store<AppState>
    ) {
        // storage.useEmulator("localhost", 9199);
        this.auth.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => (this.user = user));
        this.uploadPercentArray = [];
        this.combineLatestDeletedAttachmentsAndUploadStatusOb$ = new BehaviorSubject<string | null>(null);
        this.sendingInProgress = false;
        this.files = [];
        this.shouldDeleteAttachment = false;
        this.deletedAttachmentNames = [];

        this.attachmentsBusyUploading = [];
        this.doneUploadingAttachments = []; // to be replaced by active draft
        this.attachmentsToDelete = [];
        this.fileUploadPercentArray = [];

        this.deletedAttachmentsOb$.pipe(takeUntil(this.destroy$)).subscribe((deleteObj: any) => {
            //deleting of file attachment
            //call save to backend when filename removed from attachments array
        });
    }

    removeFilesFromStorage(fileToRemove: any, particleKey: string) {
        try {
            const filename = fileToRemove;
            const uid = this.user.uid;
            const filePath =
                `user/${uid}/email/attachments/` +
                this.generateImageFileName(filename.split('.').slice(0, -1).join('.')) +
                '.' +
                this.extractFileExtension(filename);
            const fileRef = this.storage.ref(filePath);

            fileRef.delete();
        } catch (error) {
            console.error('files-services error: ', error);
        }
    }

    public pushParticleAttachmentsToStorage(fileToUpload: any, particleKey: string) {
        //refactored version

        try {
            const file = fileToUpload;
            const uid = this.user.uid;
            const filePath =
                `user/${uid}/email/attachments/` +
                this.generateImageFileName(file.name.split('.').slice(0, -1).join('.')) +
                '.' +
                this.extractFileExtension(file.name);
            const fileRef = this.storage.ref(filePath);

            // this.task = fileRef.put(file, { customMetadata: { filename: file.name, particleKey: particleKey } });
            const uploadTask = fileRef.put(file, { customMetadata: { filename: file.name, particleKey: particleKey } });
            const taskUploadTracker = this.storage.upload(filePath, file);
            this.uploadPercent$ = taskUploadTracker.percentageChanges();
            this.uploadPercentArray.push(this.uploadPercent$);

            //array of objects with particleKey [{particleKey1: [filename1 , filename2]}, {particleKey2: [filename3, filename4]}]
            const currentAttachmentUploading = {};

            this.attachmentsBusyUploading = this.populateCurrentAttachmentUploading(
                this.attachmentsBusyUploading,
                particleKey,
                file.name
            );

            //create unique upload observable for files uploaded together
            // this.uploadState$ = uploadTask.snapshotChanges().pipe(
            const uploadState$ = uploadTask.snapshotChanges().pipe(
                map((taskSnapshot) => {
                    if (!taskSnapshot) return;

                    const filename = taskSnapshot.metadata.customMetadata?.['filename'] || 'noName' + Date.now();
                    const particleKey = taskSnapshot.metadata.customMetadata?.['particleKey'] || 'noParticleKey';
                    const bytes_TO_Transfer = taskSnapshot.bytesTransferred;
                    const bytes_DONE_Transferred = taskSnapshot.totalBytes;

                    if (
                        bytes_TO_Transfer === bytes_DONE_Transferred &&
                        taskSnapshot?.state?.toLowerCase() !== 'running'
                    ) {
                        // send particleKey & AttachmentProcess & this.doneUploadingAttachments
                        const fileAttachmentStatus: any = {
                            attachmentProgress: AttachmentProcess.startedAndEnded,
                            particleKey: particleKey,
                            doneUploadingAttachments: this.doneUploadingAttachments,
                            attachmentsBusyUploading: this.attachmentsBusyUploading,
                            uploaded: filename,
                            saved: false,
                        };

                        const attachmentsBusyUploadingIndex = this.attachmentsBusyUploading.findIndex(
                            (x) => x[particleKey]
                        );
                        const doneUploadingAttachmentsIndex = this.doneUploadingAttachments.findIndex(
                            (x) => x[particleKey]
                        );

                        if (attachmentsBusyUploadingIndex > -1) {
                            const doneAttachments = this.attachmentsBusyUploading.splice(
                                attachmentsBusyUploadingIndex,
                                1
                            );

                            const filesAlreadyUploaded = [];
                            filesAlreadyUploaded.push(this.doneUploadingAttachments[doneUploadingAttachmentsIndex]);

                            const x = this.mergeFilesUploadedArraysForParticle(
                                filesAlreadyUploaded,
                                doneAttachments,
                                particleKey
                            );

                            this.doneUploadingAttachments[doneUploadingAttachmentsIndex][particleKey] = x[0];

                            fileAttachmentStatus.doneUploadingAttachments = this.doneUploadingAttachments;
                            fileAttachmentStatus.attachmentsBusyUploading = this.attachmentsBusyUploading;
                            // fileAttachmentStatus.uploaded = filename;
                        }

                        return fileAttachmentStatus;
                    }
                })
            );

            // this.uploadState$.pipe(takeUntil(this.destroy$)).subscribe((fileAttachmentStatus: any) => {
            uploadState$.pipe(takeUntil(this.destroy$)).subscribe(
                (fileAttachmentStatus: any) => {
                    if (fileAttachmentStatus !== null && fileAttachmentStatus !== undefined) {
                        // check and see if it was saved to store and that it fired off observable only once
                        this.store
                            .pipe(select(selectParticle(fileAttachmentStatus.particleKey)))
                            .pipe(take(1))
                            .subscribe(async (particle) => {
                                const selectedParticle = deepCopy(particle[0]);

                                const isAppendedToParticleAttachments = selectedParticle.attachments.includes(
                                    fileAttachmentStatus.uploaded
                                );

                                if (
                                    fileAttachmentStatus !== null &&
                                    fileAttachmentStatus !== undefined &&
                                    fileAttachmentStatus.attachmentProgress === AttachmentProcess.startedAndEnded &&
                                    !isAppendedToParticleAttachments // ensure that we dont fire off two triggers to the stream
                                ) {
                                    this.attachmentInProgress$.next(fileAttachmentStatus);
                                }
                            });
                    }
                },
                (exception) => {
                    console.error('fileService > uploadState$ sub 2 > exception:', exception);
                }
            );
        } catch (error) {
            console.error('fileService > error:', error);
        }
    }

    public mergeFilesUploadedArraysForParticle(existingFilesArray: any, filesToMergeArray: any, particleKey: string) {
        let existingFilesArrayIndex;
        // find array index of correct particle
        if (existingFilesArray === undefined || existingFilesArray === null) {
            existingFilesArrayIndex = -1;
            existingFilesArray = [];
        } else {
            existingFilesArrayIndex = existingFilesArray.findIndex((x: { [x: string]: any; }) => x[particleKey]);
        }
        const filesToMergeArrayIndex = filesToMergeArray.findIndex((x: { [x: string]: any; }) => x[particleKey]);
        let newArray;

        if (existingFilesArrayIndex > -1) {
            // merge UPDATE as array exist
            newArray = [...existingFilesArray];
            const a = filesToMergeArray[filesToMergeArrayIndex][particleKey];
            const b = existingFilesArray[existingFilesArrayIndex][particleKey];
            const c = a.concat(b.filter((item: any) => a.indexOf(item) < 0)); // resolve duplicate file names

            newArray[filesToMergeArrayIndex] = c;
        } else {
            // merge CREATE as array does not exist
            newArray = [...existingFilesArray];
            newArray.push(filesToMergeArray);
        }

        return newArray;
    }

    private populateCurrentAttachmentUploading(attachmentsBusyUploading: any, particleKey: string, fileName: string) {
        //check if particle exist on currentAttachmentUploading
        if (attachmentsBusyUploading[particleKey] !== undefined && attachmentsBusyUploading[particleKey] !== null) {
            //get the current array if it exist
            attachmentsBusyUploading[particleKey].push(fileName);
        } else {
            const currentAttachmentUploading: any = {};
            const fileNamesArray: any[] = [];
            fileNamesArray.push(fileName);
            currentAttachmentUploading[particleKey] = fileNamesArray;
            attachmentsBusyUploading.push(currentAttachmentUploading);
        }

        return attachmentsBusyUploading;
    }

    public uploadEmailSignatureAttachmentToStorage(file: { id: string; src: string; contentType: string }) {
        let ext = (<any>mimes)[file.contentType].extensions[0];
        if (!ext) {
            throw Error('Content Type not supported');
        }
        ext = '.' + ext;
        const fileName = this.user.uid.slice(0, 7) + file.id.slice(0, 7) + ext;
        const filePath = `public/emailSignature/${fileName}`;
        const fileRef = this.storage.ref(filePath);

        const task = fileRef.putString(file.src, 'base64', {
            contentType: file.contentType,
            customMetadata: {
                filename: fileName,
                fileId: file.id,
            },
        });
        return task.snapshotChanges();
    }

    deleteFileOnstorage(fileName: string) {
        // huh?
    }

    public getImage(imageUrl: string) {
        return this.http.get(imageUrl, {});
    }

    public getProfileImageURL(user: User) {
        if (user.profileImage) {
            const ref = this.storage.ref(
                'profileImage/' + (user.profileImage.fileName ? user.profileImage.fileName : '')
            );
            const ref2 = this.storage.ref('profileImage/');
            ref2.child(user.profileImage.fileName ? user.profileImage.fileName : '')
                .getDownloadURL()
                .subscribe(
                    (url) => {
                        // `url` is the download URL for 'images/stars.jpg'

                        // This can be downloaded directly:
                        const xhr = new XMLHttpRequest();
                        xhr.responseType = 'blob';
                        xhr.onload = (event) => {
                            this.logger.trace('xhr/.response', xhr.response);
                            const blob = xhr.response;
                        };
                        xhr.open('GET', url);
                        xhr.send();

                        this.logger.trace('xhr', xhr);
                    },
                    (error) => {
                        this.logger.error('found an error:', error); // TODO check if no sensitive date is printed out
                    }
                );
        }
    }

    // public getProfileImageBlob(user: User): Observable<any> {
    //     if (user.profileImage) {
    //         /* Create an observable that emits 'Hello' and 'World' on subscription.*/
    //         const theDude = Observable.create((observer: { next: (arg0: string) => void; complete: (arg0: string) => void; }) => {
    //             observer.next('Hello');
    //             observer.next('World');

    //             const ref2 = this.storage.ref('profileImage/');
    //             ref2.child(user.profileImage.fileName ? user.profileImage.fileName : '')
    //                 .getDownloadURL()
    //                 .subscribe(
    //                     (url) => {
    //                         // This can be downloaded directly:
    //                         const xhr = new XMLHttpRequest();
    //                         xhr.responseType = 'blob';
    //                         xhr.onload = (event) => {
    //                             this.logger.trace('the response: ', xhr);
    //                             this.logger.trace('xhr event', event);
    //                             const blob = xhr.response;
    //                             this.logger.trace('the blob: ', blob);
    //                             observer.next(blob);
    //                             observer.complete('done and kla');
    //                         };
    //                         xhr.open('GET', url);
    //                         xhr.send();

    //                         this.logger.trace('xhr', xhr);
    //                     },
    //                     (error) => {
    //                         this.logger.error('found an error:', error); // TODO check if no sensitive date is printed out
    //                     }
    //                 );
    //         });

    //         return theDude;
    //     }
    //     // if no profile image exist then we set initials of first and last name
    //     // this.noProfileImage = this.nameAcronym(user.firstName, user.lastName);
    // }


    /**
     * Used to generate file name of profile image uploaded to
     * firestore & firebase storage.
     */
    private generateImageFileName(filename: string): string {
        // TODO long file names need to be truncated here, or their name must be replaced.
        // return filename.length > 20 ? filename.substring(0, 19) : filename;
        return filename;
    }

    /**
     * Used to extract the files extension to use with
     * renaming the filename for firestore & firebase storage.
     */
    private extractFileExtension(string: string): string {
        const regex = /(?:\.([^.]+))?$/;
        const result = regex.exec(string) as RegExpExecArray;
        return result[1];
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }
}
