import { Alert, AlertOptions, AlertType } from '../components/alerts/alert.model';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class AlertService {
  // Observable sources
  private alertSource = new Subject<Alert>();

  // Observable streams
  alert$ = this.alertSource.asObservable();

  // Service message commands
  addAlert(message: string, type: AlertType, options?: AlertOptions) {
    const alert = new Alert(message, type, options);
    this.alertSource.next(alert);
  }
}
