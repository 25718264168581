import { Timestamp } from "firebase/firestore";

export interface BasicCrud extends BasicCrudUpdate {
    key: string;
    createdOn: Date;
    createdBy: string;
}

export interface BasicCrudUpdate {
    lastUpdatedOn: Date;
    lastUpdatedBy: string;
    lastUpdatedName?: string;

    updateHistory: Array<{
        on: Date;
        uid: string;
        update?: string;
        by?: string;
    }>;
}

export interface CapturedErrorDocument {
    caughtExceptions?: Array<CaughtException>;
    className?: string;
    dataArgs: string;
    dateCaught: Timestamp;
    dateLastProcessed?: Timestamp;
    dateMarkedCritical?: Timestamp;
    dateResolved?: Timestamp;
    errorType: RetryErrorType;
    functionName?: string;
    isResolved: boolean;
    manuallyResolved: boolean;
    exceptionMessage: string;
    exceptionName: string;
    exceptionStack?: string;
    performAt: Timestamp;
    processingCount: number;
    state?: string;
    status: ErrorState;
    uid?: string;
    batch?: number;
    lastTransaction?: Timestamp;
}

export interface CaughtException {
    caughtDate: Timestamp;
    onProcessCount: number;
    caughtMessage?: string;
    caughtName?: string;
    caughtStack?: string;
    exceptionJSON?: string;
}

export enum ErrorState {
    QUEUED = 'queued',
    PROCESSING = 'processing',
    CRITICAL = 'critical',
    RESOLVED = 'resolved'
}

export enum RetryErrorType {
    REFRESH_TASK_AZURE = 'refreshTaskAzure',
    REFRESH_TASK_GMAIL = 'refreshTaskGmail',

    RETRIEVE_EMAIL_AZURE = 'retrieveEmailAzure',
    RETRIEVE_EMAIL_GMAIL = 'retrieveEmailGmail',

    SEND_EMAIL_AZURE = 'sendEmailAzure',
    SEND_EMAIL_GMAIL = 'sendEmailGmail',

    NONSPECIFIC = 'nonspecific',
}

export interface ErrorStatistic {
    unresolvedGeneralErrors: number;
    unresolvedUserErrors: number;
    timestamp: Timestamp;
    categories: { [errorTypeKey: string]: any };
    criticalErrors: number;
    queuedErrors: number;
    resolvedErrors: number;
    unresolvedErrors: number;
    archivedErrors: number;
    change?: any;
}

export interface TableAction<T = any> {
    display: string;
    action: (row: T) => any;
    color: 'primary' | 'accent' | 'warn' | 'basic' | '';
    disabled: boolean;
}

export interface DynamicTableColumn<T = any> {
    columnDef: string;
    header: string;
    cell: (row: T) => string;
}