import { Observable, of } from 'rxjs';

export class CypressTestData {

    // -- particle objects mock
    public particles: any[] = [];

    constructor() {
        this.particles =

            [{
                "uid": "MTRNg0bz8UVj8uqZH4ft0Qu6WXE3",
                "id": "",
                "from": "newgenus.test@gmail.com",
                "to": "newgenustest123@gmail.com",
                "cc": "",
                "bcc": "",
                "subject": "Inbasket - particle subject 01",
                "snippet": "content No 1, goes here.",
                "content": "content No 1, goes here, and more.",
                "threadId": "",
                "labelIds": ["INBOX"],
                "creationDate": 1590051910000,
                "order": 0,
                "header": "",
                "app": "newgenus",
                "folders": {},
                "internalDate": 1590051910000,
                "particleKey": "particle-test-01",
                "particleType": "gmail",
                "position": 0,
                "mimeType": "text/html",
                "readStatus": "false"
            },
            {
                "uid": "MTRNg0bz8UVj8uqZH4ft0Qu6WXE3",
                "id": "",
                "from": "newgenus.test@gmail.com",
                "to": "newgenustest123@gmail.com",
                "cc": "",
                "bcc": "",
                "subject": "Inbasket - particle subject 02",
                "snippet": "content No 2, goes here.",
                "content": "content No 2, goes here, and more.",
                "threadId": "",
                "labelIds": ["DISPATCH"],
                "creationDate": 1590051920000,
                "order": 0,
                "header": "",
                "app": "newgenus",
                "folders": {},
                "internalDate": 1590051920000,
                "particleKey": "particle-test-02",
                "particleType": "gmail",
                "position": 0,
                "mimeType": "text/html",
                "readStatus": "false"
            },
            {
                "uid": "MTRNg0bz8UVj8uqZH4ft0Qu6WXE3",
                "id": "",
                "from": "newgenus.test@gmail.com",
                "to": "newgenustest123@gmail.com",
                "cc": "",
                "bcc": "",
                "subject": "Inbasket - particle subject 03",
                "snippet": "content No 3, goes here.",
                "content": "content No 3, goes here, and more.",
                "threadId": "",
                "labelIds": ["ORDER"],
                "creationDate": 1590051930000,
                "order": 0,
                "header": "",
                "app": "newgenus",
                "folders": {},
                "internalDate": 1590051930000,
                "particleKey": "particle-test-03",
                "particleType": "gmail",
                "position": 0,
                "mimeType": "text/html",
                "readStatus": "false"
            },
            {
                "uid": "MTRNg0bz8UVj8uqZH4ft0Qu6WXE3",
                "id": "",
                "from": "newgenus.test@gmail.com",
                "to": "newgenustest123@gmail.com",
                "cc": "",
                "bcc": "",
                "subject": "Outbasket - particle subject 04",
                "snippet": "content No 4, goes here.",
                "content": "content No 4, goes here, and more.",
                "threadId": "",
                "labelIds": ["DONE"],
                "creationDate": 1590051940000,
                "order": 0,
                "header": "",
                "app": "newgenus",
                "folders": {},
                "internalDate": 1590051940000,
                "particleKey": "particle-test-04",
                "particleType": "gmail",
                "position": 0,
                "mimeType": "text/html",
                "readStatus": "false"
            },
            {
                "uid": "MTRNg0bz8UVj8uqZH4ft0Qu6WXE3",
                "id": "",
                "from": "newgenus.test@gmail.com",
                "to": "newgenustest123@gmail.com",
                "cc": "",
                "bcc": "",
                "subject": "Outbasket - particle subject 05",
                "snippet": "content No 5, goes here.",
                "content": "content No 5, goes here, and more.",
                "threadId": "",
                "labelIds": ["DONE", "ORDER"],
                "creationDate": 1590051950000,
                "order": 0,
                "header": "",
                "app": "newgenus",
                "folders": {},
                "internalDate": 1590051950000,
                "particleKey": "particle-test-05",
                "particleType": "gmail",
                "position": 0,
                "mimeType": "text/html",
                "readStatus": "false"
            },
            {
                "uid": "MTRNg0bz8UVj8uqZH4ft0Qu6WXE3",
                "id": "",
                "from": "newgenus.test@gmail.com",
                "to": "newgenustest123@gmail.com",
                "cc": "",
                "bcc": "",
                "subject": "Outbasket - particle subject 06",
                "snippet": "content No 6, goes here.",
                "content": "content No 6, goes here, and more.",
                "threadId": "",
                "labelIds": ["DONE", "DISPATCH"],
                "creationDate": 1590051960000,
                "order": 0,
                "header": "",
                "app": "newgenus",
                "folders": {},
                "internalDate": 1590051960000,
                "particleKey": "particle-test-06",
                "particleType": "gmail",
                "position": 0,
                "mimeType": "text/html",
                "readStatus": "false"
            },
            {
                "uid": "MTRNg0bz8UVj8uqZH4ft0Qu6WXE3",
                "id": "",
                "from": "newgenus.test@gmail.com",
                "to": "newgenustest123@gmail.com",
                "cc": "",
                "bcc": "",
                "subject": "Pending - subject No 07",
                "snippet": "content No 7, goes here.",
                "content": "content No 7, goes here, and more.",
                "threadId": "",
                "labelIds": ["PENDING"],
                "creationDate": 1590051970000,
                "order": 0,
                "header": "",
                "app": "newgenus",
                "folders": {},
                "internalDate": 1590051970000,
                "particleKey": "particle-test-07",
                "particleType": "gmail",
                "position": 0,
                "mimeType": "text/html",
                "readStatus": "false",
            },
            {
                "uid": "MTRNg0bz8UVj8uqZH4ft0Qu6WXE3",
                "id": "",
                "from": "newgenus.test@gmail.com",
                "to": "newgenustest123@gmail.com",
                "cc": "",
                "bcc": "",
                "subject": "Pending - subject No 08",
                "snippet": "content No 8, goes here.",
                "content": "content No 8, goes here, and more.",
                "threadId": "",
                "labelIds": ["PENDING", "ORDER"],
                "creationDate": 1590051980000,
                "order": 0,
                "header": "",
                "app": "newgenus",
                "folders": {},
                "internalDate": 1590051980000,
                "particleKey": "particle-test-08",
                "particleType": "gmail",
                "position": 0,
                "mimeType": "text/html",
                "readStatus": "false"
            },
            {
                "uid": "MTRNg0bz8UVj8uqZH4ft0Qu6WXE3",
                "id": "",
                "from": "newgenus.test@gmail.com",
                "to": "newgenustest123@gmail.com",
                "cc": "",
                "bcc": "",
                "subject": "Pending - subject No 09",
                "snippet": "content No 9, goes here.",
                "content": "content No 9, goes here, and more.",
                "threadId": "",
                "labelIds": ["PENDING", "DISPATCH"],
                "creationDate": 1590051990000,
                "order": 0,
                "header": "",
                "app": "newgenus",
                "folders": {},
                "internalDate": 1590051990000,
                "particleKey": "particle-test-09",
                "particleType": "gmail",
                "position": 0,
                "mimeType": "text/html",
                "readStatus": "false"
            }]


    }

}