/* eslint-disable no-restricted-syntax */
/* eslint-disable @angular-eslint/no-input-rename */
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ClientAbcTableDatumShared, GraphData, GraphStatus, toOrdinalString } from '@newgenus/common';
import { Subject } from 'rxjs';
import * as d3 from 'd3';
import moment from 'moment';

@Component({
    selector: 'shared-client-abc-graph',
    styleUrls: ['../graph-base.component.scss'],
    template: `
        <div style="display: flex; flex-wrap: wrap; justify-content: center;">
            <div class="graph-date">{{fromDate | date : 'MMMM YYYY'}} - {{toDate | date : 'MMMM YYYY'}}
            </div>
        </div>
        <div class="graph-parent-container">
          <div>
            
                <section class="graph-wrapper">

                    <shared-7r-graph-base
                        graphType="client-abc"
                        [data]="graphData"
                        [expanded]="true"
                        [fromDate]="fromDate"
                        [graphMargin]="margin"
                        [height]="height"
                        [parentProps]="parentProperties"
                        [target]="target"
                        [toDate]="toDate"
                        [width]="width"
                        [xExtent]="xExtent"
                        [xFormat]="xFormat"
                        [xTicks]="xTicks"
                        [yExtent]="yExtent"
                        [yScale]="yScale"
                        (graphStatus)="onGraphStatusChange($event)"
                        (clickEntry)="onClickEntry($event)"
                    ></shared-7r-graph-base>

                </section>
            </div>

            <div class="save-loader" *ngIf="showSpinner">
                <mat-spinner diameter="51"></mat-spinner>
            </div>
        </div>
    `,
})
export class SharedClientAbcGraphComponent implements OnDestroy, OnChanges, OnInit {

    @Input()
    public width = 500;

    @Input()
    public height = 500;

    @Input()
    public past6Months: moment.Moment[] = [];

    @Input()
    public data: ClientAbcTableDatumShared | null = null;

    @Input('parentProps')
    public parentProperties = { height: 500, width: 450 };

    // eslint-disable-next-line @angular-eslint/no-output-rename
    @Output('clickEntry')
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    public onClickedEntry = new EventEmitter<GraphData[] | null>();

    public margin = { top: 20, right: 20, bottom: 50, left: 70 };
    public target = 0;
    public graphData: Array<GraphData> = [];

    public xScale!: d3.ScaleTime<number, number, never>;
    public yScale!: d3.ScaleLinear<number, number, never>;
    public selectedEntry: GraphData | null = null;

    public xTicks = 6;
    public xFormat = '%b, %Y';
    public yExtent: [number, number] = [0, 0];
    public xExtent!: [Date, Date];

    public showSpinner = true;

    private destroy$ = new Subject<void>();
    public fromDate: Date = new Date();
    public toDate: Date = new Date();

    public ngOnInit(): void {
        if (!this.data && !this.past6Months) {
            this.prepareEmptyGraph();
        }
    }

    public toOrdinalString(str?: number) {
        return toOrdinalString(str);
    }

    public onGraphStatusChange(status: GraphStatus) {
        // console.log('onGraphStatusChange > status: ', status);
    }

    public onClickEntry(entries: unknown[]) {
        this.onClickedEntry.emit(entries as GraphData[]);
    }

    private clearCurrentDataAndSelectedEntry() {
        this.selectedEntry = null;
        this.onClickedEntry.emit(null);
    }

    private prepareEmptyGraph() {
        this.showSpinner = true;

        // set scale domains
        this.yExtent = d3.extent([0, 10000000]) as [number, number];
        let xExtent = d3.extent([this.fromDate, this.toDate]);

        if (!xExtent[0] || !xExtent[1]) {
            xExtent = [this.fromDate, this.toDate];
        }

        this.xExtent = xExtent;

        // Calculate xTicks.
        this.xTicks = 6;

        this.graphData = [];

        this.showSpinner = false;
    }

    private prepareGraphData(data: Array<GraphData>) {
        this.showSpinner = true;

        // Sort the data based on date objects.
        data.sort((a, b) => {
            return (<any>a).date - (<any>b).date;
        });

        // set scale domains
        this.updateYExtent(data);

        const xExtent = d3.extent(data, (d) => new Date(d.date));

        this.xExtent = xExtent as [Date, Date];
        if (this.xExtent[0] && this.xExtent[1]) {
            this.fromDate = this.xExtent[0];
            this.toDate = this.xExtent[1];
        }

        // Calculate xTicks.
        this.xTicks = data.length;

        this.graphData = [];
        this.graphData = data;

        this.showSpinner = false;
    }

    private updateYExtent(data?: GraphData[]): void {
        const primaryExtent = d3.extent(data || this.graphData, (d: GraphData) => d.valueCompounded) as [number, number];
        let yExtendMargin10Percent: [number, number] = [0, 1000000];

        yExtendMargin10Percent = [
            primaryExtent[0] > 0 ? primaryExtent[0] / 1.1 : primaryExtent[0] * 0.9,
            primaryExtent[1] > 0 ? primaryExtent[1] * 1.1 : primaryExtent[1] / 0.9,
        ];

        this.yExtent = yExtendMargin10Percent;
    }

    private parseToGraphData(docData: ClientAbcTableDatumShared): Array<GraphData> {
        if (!this.past6Months || this.past6Months.length === 0) {
            throw new Error('past6Months is not defined or  it\'s empty.');
        }

        const parsedArr: GraphData[] = [];
        for (let i = 0; i < this.past6Months.length; i++) {
            const month = this.past6Months[i];
            const entry = {} as Partial<GraphData>;

            // current month 
            if (i === 0) {
                entry.valueCompounded = docData['current month'];
                entry.value = docData['current month'];
                entry.date = month.toDate();
            }
            // current month -1...-6
            else {
                if ((<any>docData)['current month -' + i] !== undefined) {
                    entry.valueCompounded = (<any>docData)['current month -' + i];
                    entry.value = (<any>docData)['current month -' + i];
                    entry.date = month.toDate();
                }
            }

            parsedArr.push(entry as GraphData);
        }

        return parsedArr;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if ((changes['data'] || changes['past6Months']) && this.data && this.past6Months) {
            this.prepareGraphData(this.parseToGraphData(this.data));
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }
}
