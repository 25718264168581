<!-- Title / header -->
<h2 mat-dialog-title>Custom Client ABC Groups</h2>

<!-- Content / Body -->
<div mat-dialog-content class="pb-0 fit-content">
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>

  <form [formGroup]="form" class="flexy-container">
    <mat-stepper orientation="vertical" (selectionChange)="onStep()" [linear]="true" #stepper>

      <mat-step [stepControl]="form.controls['key']" [completed]="form.controls['title'].valid" errorMessage="Title is required.">
        <ng-template matStepLabel>Select or Create Group</ng-template>
        <ng-template matStepContent>

          <mat-form-field class="w-100 mt-2" appearance="fill">
            <mat-label>Group Name</mat-label>
            <input type="text"
                   placeholder="Enter group name"
                   aria-label="Group Name"
                   matInput
                   maxlength="50"
                   formControlName="title"
                   (keyup)="onKeyGroupName($event)"
                   [matAutocomplete]="clientAbcAutoComplete">
            <button *ngIf="form.controls['title'].value" matSuffix mat-icon-button aria-label="Clear" (click)="clearGroupSelection()">
              <mat-icon>close</mat-icon>
            </button>

            <mat-autocomplete autoActiveFirstOption #clientAbcAutoComplete="matAutocomplete" (optionSelected)="onSelectGroupOption($event)">
              <mat-option *ngFor="let opt of existingGroups" [value]="opt.display">{{opt.display}}</mat-option>
            </mat-autocomplete>
            <mat-hint>Select an existing group or enter a new name to create a new group</mat-hint>
          </mat-form-field>

          <div class="animate-height" [ngClass]="{'h-0': !isExistingGroupSelected}">
            <mat-form-field class="mt-2">
              <mat-label>Rename Group</mat-label>
              <input matInput maxlength="50" [(ngModel)]="newGroupName" [ngModelOptions]="{standalone: true}" placeholder="Group Title">
              <mat-error *ngIf="form.controls['title'].hasError('maxlength')">Title must be less than 50 characters long.</mat-error>
              </mat-form-field>

              <!-- Rename button -->
              <button color="primary" [disabled]="newGroupName === form.controls['title'].value" mat-icon-button matSuffix matTooltip="Rename Group" (click)="renameGroup()">
                <mat-icon>save</mat-icon>
              </button>
          </div>
        
          <!-- 
          <section class="alternating-flex-container">
            <mat-form-field>
              <mat-label>Existing Group</mat-label>
              <mat-select formControlName="key" (selectionChange)="onSelectExistingGroup($event.value)">
                <mat-option [value]="null" selected></mat-option>
                <mat-option *ngFor="let opt of existingGroups" [value]="opt.value">{{opt.display}}</mat-option>
              </mat-select>
            </mat-form-field>

            <div class="vertical-divider">
              <span class="line-top"></span>
              <span class="line-msg">Or</span>
              <span class="line-bottom"></span>
            </div>

            <mat-form-field>
              <mat-label>New Group Title</mat-label>
              <input matInput maxlength="20" formControlName="title" placeholder="Group Title">
              <mat-error *ngIf="form.controls['title'].hasError('maxlength')">Title must be less than 20 characters
                long.</mat-error>
            </mat-form-field>
          </section> -->

          <!-- Controls -->
          <button mat-button class="float-right" matStepperNext>Next</button>
        </ng-template>
      </mat-step>

      <mat-step [stepControl]="form.controls['accounts']" [completed]="form.controls['accounts'].valid" interacted="false" errorMessage="Accounts are required.">
        <ng-template matStepLabel>Select Accounts For Group <small matTooltip="Number of accounts selected.">(<b>{{getSelectedAccountsCount() | number}}</b>)</small></ng-template>

        <section class="table-flex-container">
          <mat-form-field class="w-100 mt-2">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" [disabled]="isLoading" placeholder="Ex. Mia" #input>
          </mat-form-field>

          <table mat-table matSort multiTemplateDataRows [dataSource]="dataSource" class="mat-elevation-z8">

            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? toggleAllRows() : null"
                              [checked]="tableSelection.hasValue() && isAllOnTableSelected()"
                              [indeterminate]="tableSelection.hasValue() && !isAllOnTableSelected()"
                              matTooltipPosition="above"
                              [matTooltip]="checkboxLabel()"
                              [aria-label]="checkboxLabel()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="$event ? onToggleTableRow(row) : null"
                              [checked]="isRowSelected(row)"
                              [indeterminate]="isSubListIntermediate(row)"
                              matTooltipPosition="above"
                              [matTooltip]="checkboxLabel(row)"
                              [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
              </td>
            </ng-container>
          
            <!-- Account Column -->
            <ng-container matColumnDef="groupKey">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Account </th>
              <td mat-cell *matCellDef="let element"> {{element.groupKey}} </td>
            </ng-container>
          
            <!-- Name Column -->
            <ng-container matColumnDef="groupName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
              <td mat-cell *matCellDef="let element"> {{element.groupName}} ({{element.data.length}})</td>
            </ng-container>

            <ng-container matColumnDef="expand">
              <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                  <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                  <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                </button>
              </td>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
                <div class="example-element-detail"
                    [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                  <mat-selection-list class="w-100">
                    <mat-list-option *ngFor="let debtor of element.data"
                      [value]="debtor.account" 
                      [selected]="isListItemSelected(debtor, element)" 
                      (selectedChange)="onListItemSelectionChange($event, debtor, element)"
                    >
                      <strong>{{debtor.account}}</strong>&nbsp;|&nbsp;{{debtor.name}}
                      <small class="debtor-address">{{ (debtor.country ? debtor.country + ', ' : '')  +(debtor.address1 ? debtor.address1 + ', ' : '') + (debtor.address2  || '' ) + ( debtor.address3 ? ', ' + debtor.address3 : '' )}}</small>
                    </mat-list-option>
                  </mat-selection-list>
                </div>
              </td>
            </ng-container>
          
            <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                (click)="selection.toggle(row)">
            </tr> -->

            <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsToDisplayWithExpand;"
                class="example-element-row"
                [class.example-expanded-row]="expandedElement === row"
                (click)="tableSelection.toggle(row)">
                <!-- (click)="expandedElement = expandedElement === element ? null : element" -->
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
          </table>

          <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [showFirstLastButtons]="true" aria-label="Select page of debtors"></mat-paginator>
          
        </section>

        <!-- Controls -->
        <button mat-button matStepperPrevious class="mt-3 float-right">Back</button>
        <button mat-button matStepperNext class="mt-3 float-right">Next</button>
      </mat-step>

      <mat-step [stepControl]="form.controls['settings']" [completed]="form.controls['settings'].valid" interacted="false" errorMessage="Transaction Types are required.">
        <ng-template matStepLabel>Group Behaviour Settings</ng-template>

        <form [formGroup]="form.controls['settings']">
          <div class="flexy-container">
            <mat-form-field class="my-2 flex-grow-1">
              <mat-label>Transaction Types</mat-label>
              <mat-select formControlName="transactionTypes" multiple>
                <mat-option *ngFor="let opt of clientAbcTransactionOptions" [value]="opt.value">{{opt.display}}</mat-option>
              </mat-select>
              <mat-hint>Types of transactions to include</mat-hint>
            </mat-form-field>
          </div>

          <p>
            Dynamic Grouping:
            <mat-button-toggle-group formControlName="dynamicGrouping" class="density-down-3 mt-2">
              <mat-button-toggle value="Main Account">Main Account</mat-button-toggle>
              <mat-button-toggle value="No grouping">No grouping</mat-button-toggle>
            </mat-button-toggle-group>
            <small class="d-block" style="border-bottom: 1px solid #ccc; padding-bottom: 0.5rem;">Effects the table showing individual accounts within the group: Select <b>Main Account</b> to group all sub-accounts by their Main Account.</small>
          </p>
          
          <p>
            Show Values as:
            <mat-button-toggle-group formControlName="appearance" class="density-down-3 mt-2">
              <mat-button-toggle value="nett">Exclusive of Tax</mat-button-toggle>
              <mat-button-toggle value="gross">Inclusive of Tax</mat-button-toggle>
            </mat-button-toggle-group>
          </p>
        </form>

        <!-- Controls -->
        <button mat-button matStepperPrevious class="mt-3 float-right">Back</button>
        <button mat-button matStepperNext class="mt-3 float-right">Next</button>
      </mat-step>

      <mat-step state="final">
        <ng-template matStepLabel>Review</ng-template>

        <div class="flexy-container">

          <div class="w-50">
            <h2 style="margin-bottom:8px;">Basic Details:</h2>
            <ul style="font-size:0.9rem;padding-left:8px;">
              <li>Title: <strong>{{reviewModel.title}}</strong></li>
            </ul>
          </div>

          <div class="w-50">
            <h2 style="margin-bottom:8px;">Display:</h2>
            <ul style="font-size:0.9rem;padding-left:8px;">
              <li>Accounts: <strong>{{reviewModel.accounts}}</strong></li>
            </ul>
          </div>

        </div>

        <!-- Controls -->
        <button mat-button matStepperPrevious class="mt-3 float-right">Back</button>
      </mat-step>

    </mat-stepper>
  </form>
</div>

<!-- Controls / Footer -->
<div mat-dialog-actions class="d-flex">
  <div class="flex-spacer"></div>
  <button mat-button [disabled]="form.invalid" [mat-dialog-close]="submit()" cdkFocusInitial color="primary">Save</button>
  <button mat-button [mat-dialog-close]="null" color="warn">Cancel</button>
</div>