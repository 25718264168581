<!-- Title / header -->
<h2 mat-dialog-title>Add new Widget</h2>

<!-- Content / Body -->
<div mat-dialog-content class="h-75 pb-0 pl-3">
  <form [formGroup]="newWidgetForm" class="flexy-container">
    <mat-stepper orientation="vertical" (selectionChange)="onStep()" [linear]="true" #stepper>

      <mat-step [stepControl]="newWidgetForm.controls['type']"
        [completed]="newWidgetForm.controls['type'].valid && newWidgetForm.controls['title'].valid"
        errorMessage="Widget Type is required.">
        <ng-template matStepLabel>Select Widget</ng-template>
        <ng-template matStepContent>

          <section class="flex-pair">
            <!-- Widget Type control. -->
            <mat-form-field>
              <mat-label>Widget Type</mat-label>
              <mat-select formControlName="type" (selectionChange)="onSelectWidgetType($event.value)">
                <mat-option *ngFor="let opt of widgetOptions" [value]="opt.value">{{opt.display}}</mat-option>
              </mat-select>

              <!-- Information / tips on the different types of widgets. -->
              <mat-hint>
                <ng-container [ngSwitch]="newWidgetForm.controls['type'].value">
                  <span *ngSwitchCase="'daily7r'">A 7R Graph plotted for a day</span>
                  <span *ngSwitchCase="'monthly7r'">A 7R Graph plotted for a month</span>
                  <span *ngSwitchCase="'clientAbcGraph'" disabled>A linear graph of sales for a single client</span>
                  <span *ngSwitchCase="'clientAbc'">Client ABC sales analysis</span>
                  <span *ngSwitchCase="'clientAbcGroup'">Client ABC sales analysis of a custom group</span>
                  <span *ngSwitchCase="'clientAbcGroupList'">List overview of Client ABC sales analysis</span>
                  <span *ngSwitchDefault>Choose the type of widget you want to add.</span>
                </ng-container>
              </mat-hint>

            </mat-form-field>

            <!-- Title of the Widget -->
            <mat-form-field class="ml-sm-2">
              <mat-label>Widget Title</mat-label>
              <input matInput maxlength="20" formControlName="title" placeholder="Widget Title">
              <mat-error *ngIf="newWidgetForm.controls['title'].hasError('maxlength')">Title must be less than 20 characters long.</mat-error>
              <mat-hint>Name the Widget something helpful to you.</mat-hint>
            </mat-form-field>
          </section>

          <!-- Controls -->
          <button class="mt-3 float-right" mat-button matStepperNext>Next</button>
        </ng-template>
      </mat-step>

      <mat-step [stepControl]="newWidgetForm.controls['settings']" [completed]="newWidgetForm.controls['settings'].valid"
        interacted="false" errorMessage="Configuration is required.">
        <ng-template matStepLabel>Configure Widget</ng-template>
        <ng-template matStepContent>

          <ng-container [ngSwitch]="newWidgetForm.controls['type'].value">

            <!-- ====================== Daily ====================== -->
            <form *ngSwitchCase="'daily7r'" [formGroup]="newWidgetForm.controls['settings']" class="flex-row-wrap mt-2">

              <!-- implement shared fields template -->
              <ng-container *ngTemplateOutlet="sharedFields"></ng-container>

              <!-- implement secondary shared fields template - hidden from view until feature is fully supported -->
              <!-- <ng-container *ngTemplateOutlet="secondarySharedFields"></ng-container> -->

            </form>

            <!-- ====================== Monthly ====================== -->
            <form *ngSwitchCase="'monthly7r'" [formGroup]="newWidgetForm.controls['settings']" class="flex-row-wrap mt-2">

              <!-- implement shared fields template -->
              <ng-container *ngTemplateOutlet="sharedFields"></ng-container>

              <!-- implement secondary shared fields template - hidden from view until feature is fully supported -->
              <!-- <ng-container *ngTemplateOutlet="secondarySharedFields"></ng-container> -->

            </form>

            <!-- ====================== Client ABC Graph ====================== -->
            <form *ngSwitchCase="'clientAbcGraph'" [formGroup]="newWidgetForm.controls['settings']"
              class="flex-row-wrap mt-2">

              <!-- implement shared fields template -->
              <ng-container *ngTemplateOutlet="sharedFields"></ng-container>

              <!-- implement secondary shared fields template - hidden from view until feature is fully supported -->
              <!-- <ng-container *ngTemplateOutlet="secondarySharedFields"></ng-container> -->

            </form>

            <!-- ====================== Client ABC ====================== -->
            <form *ngSwitchCase="'clientAbc'" [formGroup]="newWidgetForm.controls['settings']" class="flex-row-wrap mt-2">
              <ng-container *ngTemplateOutlet="clientAbcTemplate"></ng-container>

              <section class="flex-pair">
                <span class="d-flex">Dynamic Grouping:</span>
                <mat-button-toggle-group formControlName="dynamicGrouping" class="density-down-3 mt-2">
                  <mat-button-toggle value="Main Account">Main Account</mat-button-toggle>
                  <mat-button-toggle value="No grouping">No grouping</mat-button-toggle>
                </mat-button-toggle-group>
              </section>
              <section class="flex-pair">
                <span class="d-flex">Show Values as:</span>
                <mat-button-toggle-group formControlName="appearance" class="density-down-3 mt-2">
                  <mat-button-toggle value="nett">Exclusive of Tax</mat-button-toggle>
                  <mat-button-toggle value="gross">Inclusive of Tax</mat-button-toggle>
                </mat-button-toggle-group>
              </section>
            </form>
            
            <!-- ====================== Client ABC Group ====================== -->
            <form *ngSwitchCase="'clientAbcGroup'" [formGroup]="newWidgetForm.controls['settings']" class="flex-row-wrap mt-2">
              <ng-container *ngTemplateOutlet="clientAbcTemplate"></ng-container>
            </form>
            
            <!-- ====================== Client ABC Group List ====================== -->
            <form *ngSwitchCase="'clientAbcGroupList'" [formGroup]="newWidgetForm.controls['settings']" class="flex-row-wrap mt-2">
              <ng-container *ngTemplateOutlet="clientAbcTemplate"></ng-container>
            </form>

            <p *ngSwitchDefault>No configuration available for unrecognized widgets.</p>

          </ng-container>

          <!-- Controls -->
          <button class="mt-3 float-right" mat-button matStepperPrevious>Back</button>
          <button class="mt-3 float-right" mat-button matStepperNext>Next</button>
        </ng-template>
      </mat-step>

      <mat-step state="final">
        <ng-template matStepLabel>Review</ng-template>

        <div class="flexy-container">

          <div class="w-50">
            <h2 style="margin-bottom:8px;">Basic Details:</h2>
            <ul style="font-size:0.9rem;padding-left:8px;">
              <li>Widget Type: <strong>{{reviewModel.type}}</strong></li>
              <li>Date Profile: <strong>{{reviewModel.dateProfile}}</strong></li>
              <li>Integration: <strong>{{reviewModel.integration}}</strong></li>
              <li>{{isMultipleRepsEnabled ? 'Reps' : 'Rep'}}: <strong>{{reviewModel.repKeys}}</strong></li>
              <li *ngIf="reviewModel.dynamicGrouping">Dynamic Grouping: {{reviewModel.dynamicGrouping}}</li>
            </ul>
          </div>
          
          <div class="w-50">
            <h2 style="margin-bottom:8px;">Display:</h2>
            <ul style="font-size:0.9rem;padding-left:8px;">
              <li>Title: <strong>{{reviewModel.title}}</strong></li>
              <li>Show Data For: <strong>{{reviewModel.showFor}}</strong></li>
              <li>Columns: <strong>{{reviewModel.cols}}</strong></li>
              <li>Rows: <strong>{{reviewModel.rows}}</strong></li>
              <li *ngIf="reviewModel.appearance">Show Values as: {{reviewModel.appearance}}</li>
            </ul>
          </div>
          
        </div>

        <!-- Controls -->
        <!-- <button class="mt-3 float-right" mat-button matStepperPrevious>Back</button> -->
      </mat-step>

    </mat-stepper>
  </form>
</div>

<!-- Controls / Footer -->
<div mat-dialog-actions class="d-flex">
  <div class="flex-spacer"></div>
  <button mat-button [disabled]="newWidgetForm.invalid"[mat-dialog-close]="submit()" color="primary" cdkFocusInitial>Save</button>
  <button mat-button [mat-dialog-close]="null" color="warn">Cancel</button>
</div>


<!-- Templates -->

<ng-template #clientAbcTemplate>
  <form [formGroup]="newWidgetForm.controls['settings']" class="flex-row-wrap mt-2">

    <!-- implement shared fields template -->
    <ng-container *ngTemplateOutlet="sharedFields"></ng-container>

    <!-- implement secondary shared fields template - hidden from view until feature is fully supported -->
    <!-- <ng-container *ngTemplateOutlet="secondarySharedFields"></ng-container> -->
    <section class="flex-pair">
      <mat-form-field *ngIf="newWidgetForm.controls['type'].value !== 'clientAbcGroupList'" [ngClass]="{'flex-1': newWidgetForm.controls['type'].value !== 'clientAbc'}">
        <mat-label>Table Columns</mat-label>
        <mat-select formControlName="tableColumns" multiple>
          <mat-option *ngFor="let opt of clientAbcTableColumns" [value]="opt.value">{{opt.display}}</mat-option>
        </mat-select>
        <mat-hint>What columns to show</mat-hint>
      </mat-form-field>

      <mat-form-field *ngIf="newWidgetForm.controls['type'].value === 'clientAbcGroupList'">
        <mat-label>Table Columns</mat-label>
        <mat-select formControlName="tableColumns" multiple>
          <mat-option *ngFor="let opt of clientAbcGroupTableColumns" [value]="opt.value">{{opt.display}}</mat-option>
        </mat-select>
        <mat-hint>What columns to show</mat-hint>
      </mat-form-field>

      <mat-form-field class="mb-2" *ngIf="newWidgetForm.controls['type'].value === 'clientAbc'">
        <mat-label>Transaction Types</mat-label>
        <mat-select formControlName="transactionTypes" multiple>
          <mat-option *ngFor="let opt of clientAbcTransactionOptions" [value]="opt.value">{{opt.display}}</mat-option>
        </mat-select>
        <mat-hint>Types of transactions to include</mat-hint>
      </mat-form-field>
    </section>
<!-- 
    <section class="flex-pair">
        <span class="d-flex">Dynamic Grouping:</span>
        <mat-button-toggle-group formControlName="dynamicGrouping" class="density-down-3 mt-2">
          <mat-button-toggle value="Main Account">Main Account</mat-button-toggle>
          <mat-button-toggle value="No grouping">No grouping</mat-button-toggle>
        </mat-button-toggle-group>
    </section>
    <section class="flex-pair">
        <span class="d-flex">Show Values as:</span>
        <mat-button-toggle-group formControlName="appearance" class="density-down-3 mt-2">
          <mat-button-toggle value="nett">Exclusive of Tax</mat-button-toggle>
          <mat-button-toggle value="gross">Inclusive of Tax</mat-button-toggle>
        </mat-button-toggle-group>
    </section> -->
  </form>
</ng-template>


<ng-template #sharedFields>
  <form [formGroup]="newWidgetForm.controls['settings']" class="flex-row-wrap mt-2">

    <section class="flex-pair">
      <mat-form-field>
        <mat-label>Date Profile</mat-label>
        <mat-select formControlName="dateProfileKey">
          <mat-option *ngFor="let opt of dateProfileOptions" [value]="opt.value">{{opt.display}}</mat-option>
        </mat-select>
      </mat-form-field>
      
      <mat-form-field>
        <mat-label>Integration</mat-label>
        <mat-select formControlName="integrationKey" (selectionChange)="onSelectIntegration($event)">
          <mat-option *ngFor="let opt of integrationOptions" [value]="opt.value">{{opt.display}}</mat-option>
        </mat-select>
      </mat-form-field>
    </section>

    <section class="flex-pair" *ngIf="!isClientAbcGroup">
      <!-- Only shown for 7R Graphs. -->
      <mat-slide-toggle *ngIf="showForCompanyControl" class="fit-to-form-field" formControlName="forCompany" (change)="onToggleForCompany($event)"><span class="ml-2">For Company</span>
        <mat-icon matTooltip="When checked, you won't have to select reps, as all reps for the company will be used for this widget - even when new reps onboard.">info</mat-icon>
      </mat-slide-toggle>
      
      <mat-form-field [ngStyle]="{'flex: 1 auto;': !showForCompanyControl}">
        <mat-label>{{isMultipleRepsEnabled ? 'Reps' : 'Rep'}}</mat-label>
        <mat-select formControlName="repKeys" [multiple]="isMultipleRepsEnabled" (selectionChange)="onSelectRep($event)">
          <mat-option *ngFor="let opt of repOptions" [value]="opt.value">{{opt.display}}</mat-option>
        </mat-select>
      </mat-form-field>
    </section>

    <section class="flex-pair" *ngIf="isClientAbcGroup">
      <mat-form-field [ngStyle]="{'flex: 1 auto;': newWidgetForm.controls['type'].value !== 'clientAbcGroupList'}">
        <mat-label>{{isMultipleRepsEnabled ? 'Users' : 'User'}}</mat-label>
        <mat-select formControlName="userKeys" [multiple]="isMultipleRepsEnabled" (selectionChange)="onSelectClientAbcUser($event)">
          <mat-option *ngFor="let opt of repOptions" [value]="opt.value">{{opt.display}}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Only shown for Client ABC Group LIST type. -->
      <mat-slide-toggle *ngIf="newWidgetForm.controls['type'].value === 'clientAbcGroupList'" class="fit-to-form-field" formControlName="forAllGroups" (change)="onToggleForAllGroups($event)"><span class="ml-2">For All Groups</span>
        <mat-icon matTooltip="When checked, all groups for available will be listed and automatically updated when groups are are created, modified or deleted.">info</mat-icon>
      </mat-slide-toggle>

      <!-- Create new Group button -->
      <!-- <button *ngIf="newWidgetForm.controls['type'].value !== 'clientAbcGroupList'" [disabled]="newWidgetForm.controls['settings'].controls['userKeys'].value !== data.currentUser.uid" mat-raised-button color="accent" class="ml-2" (click)="onCreateNewGroup()"><mat-icon>add</mat-icon>Create New Group</button> -->
    </section>

    <section class="flex-pair" *ngIf="isClientAbcGroup">
      <mat-form-field style="flex: 1 auto;">
        <mat-label>{{newWidgetForm.controls['type'].value === 'clientAbcGroupList' ? 'Groups' : 'Group'}}</mat-label>
        <mat-select formControlName="groupKeys" [multiple]="newWidgetForm.controls['type'].value === 'clientAbcGroupList'" (selectionChange)="onSelectClientAbcGroup($event)">
          <mat-option *ngFor="let opt of clientAbcGroupOptions" [value]="opt.value">{{opt.display}}</mat-option>
        </mat-select>
      </mat-form-field>
    </section>

    <mat-form-field class="mb-2" *ngIf="!isClientAbcGroup && (newWidgetForm.controls['type'].value === 'monthly7r' || newWidgetForm.controls['type'].value === 'daily7r')">
      <mat-label>Transaction Types to Show</mat-label>
      <mat-select formControlName="filtersByCode" multiple>
        <mat-option *ngFor="let opt of (newWidgetForm.controls['type'].value === 'monthly7r' ? monthlyTransactionOptions : dailyTransactionOptions)" [value]="opt.value">{{opt.display}}</mat-option>
      </mat-select>
      <mat-hint>Types of transactions to include</mat-hint>
    </mat-form-field>

    <section class="flex-pair">
      <mat-form-field>
        <mat-label>Columns</mat-label>
        <mat-select formControlName="columns">
          <mat-option *ngFor="let opt of columnOptions" [value]="opt.value">{{opt.display}}</mat-option>
        </mat-select>
      </mat-form-field>
      
      <mat-form-field>
        <mat-label>Rows</mat-label>
        <mat-select formControlName="rows">
          <mat-option *ngFor="let opt of rowOptions" [value]="opt.value">{{opt.display}}</mat-option>
        </mat-select>
      </mat-form-field>
    </section>

  </form>
</ng-template>

<ng-template #secondarySharedFields>
  <form [formGroup]="newWidgetForm.controls['settings']" class="flex-row-wrap mt-2">

    <mat-form-field>
      <mat-label>Show For</mat-label>
      <mat-select formControlName="showFor">
        <mat-option *ngFor="let opt of showForOptions" [value]="opt.value">{{opt.display}}</mat-option>
      </mat-select>
    </mat-form-field>

  </form>
</ng-template>