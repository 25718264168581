import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { ParticleActions } from '../particles/action-types';
import { createReducer, on } from '@ngrx/store';
import { Particle } from '@newgenus/common';

// Entity adapter & initial state
export interface ParticlesState extends EntityState<Particle> {
    allParticlesLoaded: boolean; // set initial data
    particleSortOrder: any;
}

export const adapter = createEntityAdapter<Particle>({
    selectId: particle => particle.particleKey,
});

export const initialParticlesState = adapter.getInitialState({
    allParticlesLoaded: false, // This was false before and prevented empty workspace baskets from loading..But seems to work fine as true?
    particleSortOrder: {
        name: 'creationDate',
        direction: 'asc',
    },
});

// Reducer functions

export const particlesReducer = createReducer(
    initialParticlesState,

    on(ParticleActions.allParticlesLoaded, (state, action) => {
        return adapter.setMany(action.particles, {
            ...state,
            allParticlesLoaded: true,
        });
    }),

    on(ParticleActions.clearAllParticles, (state, action) => {
        return adapter.removeAll({ ...state, selectId: null });
    }),

    on(ParticleActions.particleAdded, (state, action) => {
        return adapter.setOne(action.payload, {
            ...state,
            allParticlesLoaded: true,
        });
    }),

    on(ParticleActions.particleDraftAdded, (state, action) => {
        return adapter.setOne(action.payload, {
            ...state,
            allParticlesLoaded: true,
        });
    }),

    on(ParticleActions.particleRemoved, (state, action) => {
        return adapter.removeOne(action.payload.id as string, state);
    }),

    on(ParticleActions.particlesRemoved, (state, action) => {
        return adapter.removeMany(action.particles.ids, {
            ...state,
            // allParticlesLoaded: true,
        });
    }),

    on(ParticleActions.particleModified, (state, action) => {
        return adapter.updateOne({ id: action.payload.id as string, changes: action.payload }, state);
    }),

    on(ParticleActions.particlesModified, (state, action) => {
        return adapter.updateMany(action.particles, state);
    }),

    on(ParticleActions.particleUpdated, (state, action) => {
        return adapter.updateOne({ id: action.payload.id as string, changes: action.payload }, state);
    }),

    on(ParticleActions.particleReadStatusUpdate, (state, action) => {
        return adapter.updateOne({ id: action.payload.id as string, changes: action.payload }, state);
    }),

    on(ParticleActions.particleDraftUpdate, (state, action) => {
        return adapter.updateOne({ id: action.payload.id as string, changes: action.payload }, state);
    }),

    on(ParticleActions.particleUpdateLabels, (state, action) => {
        return adapter.updateOne({ id: action.payload.id as string, changes: action.payload }, state);
    }),

    on(ParticleActions.particlePend, (state, action) => {
        return adapter.updateOne({ id: action.payload.id as string, changes: action.payload }, state);
    }),

    on(ParticleActions.allParticlesDetailsLoaded, (state, action) => {
        return adapter.updateOne({ id: action.payload.id as string, changes: action.payload }, state);
    })
);

// function itemEqual(item1, item2) {
//     // Need here to do a /deep/ equality check between 
//     // the two items, e.g.:
//     return (item1.id == item2.id && 
//            item1.propertyA == item2.propertyA &&
//            item1.propertyB == item2.propertyB)
// }

export const { selectAll } = adapter.getSelectors();
