import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { QuestionBase } from './question-base';
import { FormGroup } from '@angular/forms';


// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
// export const MY_FORMATS = {
//   useUtc: true,
//   parse: {
//       dateInput: 'DD/MM/YYYY',
//   },
//   display: {
//       dateInput: 'DD/MM/YYYY',
//       monthYearLabel: 'MMM YYYY',
//       dateA11yLabel: 'LL',
//       monthYearA11yLabel: 'MMMM YYYY',
//   },
// };

@Component({
  selector: 'newgenus-df-question',
  templateUrl: './dynamic-form-question.component.html',
  // providers: [
  //   {
  //     provide: DateAdapter,
  //     useClass: MomentDateAdapter,
  //     deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
  // },
  // { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  // ]
})
export class DynamicFormQuestionComponent implements OnChanges {

  @Output()
  public dateChange = new EventEmitter<any>();

  @Input()
  public question!: QuestionBase<any>;

  @Input()
  public form!: FormGroup;

  public embeddedFormControl?: FormGroup;


  public ngOnChanges(changes: SimpleChanges): void {
    // setup the reference to the embedded form control.
    if (changes['question'] && this.embeddedFormControl === undefined) {
      this.embeddedFormControl = this.form.controls[this.question.key] as FormGroup;
    }
  }

  public onDateChange(event: any) {
    this.dateChange.emit(event);
  }


  get isValid() {
    if (this.form && this.form.controls[this.question.key]) {
      return this.form.controls[this.question.key].valid;
    } else {
      return false;
    }
  }
}