import { NewgenusSalesTransactionSalesOrder, NewgenusSalesTransaction, DebtTranDatum, ClientAbcTableDatum, SettingsAppearance, ClientAbcDynamicGroups, ClientAbcGroupTableDatum, ClientAbcGroup } from "../statistics-models";
import { FirebaseDaySummary, FirebaseMonthSummary } from "../summary.models";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { IQConfiguration, IqConfigurationStripped } from "./app.models";
import { Organization, DateSettings } from "./organization-models";
import { ClientAbcTransactionTypes, StatisticsFilterCodes, User } from "./user";
import { Moment } from "moment";


/**
 * Keys used to identify the different dashboards.
 * Add new keys on here to create a new dashboard definition.
 */
export type DashboardKeys = 'management-tools';


// #region Widget Interfaces =================================================== Widget

/**
 * An instantiated widget is a widget that has been initialized with data, subscriptions and settings.
 *
 * @interface InstantiatedWidget This is the interface that gets passed to the dashboard-intermediate component.
 * @extends {Widget<SettingsType>} The widget interface, coming straight from the Dashboard from the DB.
 * @template DataType Generic type parameter for specific widgets, e.g. Daily7rData. This is the data that gets passed to the widget.
 * @template SettingsType Generic type parameter for specific widgets, e.g. Daily7rSettings. This is the settings that gets passed to the widget.
 */
export interface InstantiatedWidget<DataType = any, SettingsType = any> extends Widget<SettingsType> {

    /**
     * The data for the widget.
     *
     * @type {DataType} Generic type parameter for specific widgets, e.g. Daily7rData. This is the data that gets passed to the widget.
     * @memberof InstantiatedWidget
     */
    data: DataType;

    /**
     * A subject that emits settings changes of the widget.
     * This gets used in the subscriptions of the widget.
     *
     * @memberof InstantiatedWidget
     */
    settingsSub: BehaviorSubject<SettingsType>;

    /**
     * The subtitle below the widget in the dashboard.
     * This does not save to the DB.
     * 
     * @memberof InstantiatedWidget
     */
    subtitle?: string;

    /**
     * A flag indicating if the widget is loading - this should be used to show a spinner.
     */
    isLoading: boolean;

    /**
     * A flag indicating if the widget has an error
     */
    hasError: boolean;

    /**
     * The type of error that the widget has.
     * This should be used to show a specific error message and create a specific error handling.
     */
    typeOfError?: string;
}

/**
 * A widget is a definition of a widget from the DB.
 *
 * @export
 * @interface Widget This is the interface that gets saved to the DB.
 * @template SettingsType Generic type parameter for specific widgets, e.g. Daily7rSettings. This is the settings that gets passed to the widget.
 */
export interface Widget<SettingsType> {

    /**
     * The authentication settings for the widget.
     * This is used to determine if the widget should be shown to the user.
     *
     * @type {{
     *         userKeys?: string[];
     *         forReps?: string[];
     *         forFeature: string;
     *     }}
     * @memberof Widget
     */
    auth: {
        userKeys?: string[];
        forReps?: string[];
        forFeature: string;
    };

    /**
     *  Auto generated ID.
     */
    id: string;

    /**
     * GENERIC PARAMETER - settings for the widget.
     * @example ```TypeScript
     * const settings: Daily7rSettings = {
     *  columns: 1,
     *  rows: 1,
     *  dateForData: new Date(),
     *  filtersByCode: {},
     *  isOwnStats: false,
     *  showFor: 'today',
     *  forCompany: false,
     * };
     * 
     * const widget: Widget<Daily7rSettings> = { ... };
     * ```
     */
    settings: SettingsType;

    /**
     * The title/name of the widget.
     */
    title: string;

    /**
     * The type of widget.
     */
    type: WidgetType;

    // TODO something like this...
    functions?: {
        [key: string]: (...args: any[]) => any;
    }
}

export type WidgetType = '' | 'daily7r' | 'monthly7r' | 'clientAbcGraph' | 'clientAbc' | 'clientAbcGroup' | 'clientAbcGroupList';

// #endregion Widget Interfaces ===================================================
// #region Dialog Interfaces ---------------------------------------------------

export interface WidgetSettingsDialogInputData extends AddWidgetDialogInputData {
    widget: InstantiatedWidget<any, any>;
}

export interface AddWidgetDialogInputData {
    organization?: Organization;
    integrations?: IqConfigurationStripped[];
    currentUser: User;
    allColumnWidths: number[];
    allRowsWidths: number[];
    filterBySalesCodes?: any[];
}

// #endregion Dialog Interfaces ---------------------------------------------------
// #region Daily7r Widget Interfaces =================================================== Daily7r

export interface IntermediateDaily7rData extends Shared7RGraphProperties {
    dataObj: {
        data: Subject<NewgenusSalesTransaction[]>;
        summaries: Subject<FirebaseDaySummary[]>;
    },
    subscriptions?: Subscription[];
}

export interface IntermediateDaily7rSettings extends ShareWidgetSettings {
    dateForData: Date;
    filtersByCode: Array<StatisticsFilterCodes>;
    isOwnStats: boolean;
    showFor: DailyShowFor;
    forCompany: boolean;
}

// #endregion Daily7r Widget Interfaces ===================================================
// #region Monthly7r Widget Interfaces --------------------------------------------------- Monthly7r

export interface IntermediateMonthly7rData extends Shared7RGraphProperties {
    summaryData: Subject<FirebaseMonthSummary[]>;
    data: Subject<DebtTranDatum[]>;
    subscriptions?: Subscription[];
}

export interface IntermediateMonthly7rSettings extends ShareWidgetSettings {
    monthForData: Date;
    filtersByCode: Array<StatisticsFilterCodes>;
    isOwnStats: boolean;
    showFor: MonthlyShowFor;
    forCompany: boolean;
}

// #endregion Monthly7r Widget Interfaces ---------------------------------------------------
// #region ClientABC Widget Interfaces =================================================== ClientABC

export interface IntermediateClientAbcData {
    dataForCurrentMonth: Subject<DebtTranDatum[]>;
    data: Subject<ClientAbcTableDatum[]>;
    dateForData: Subject<Date>;
    dateSettings: Observable<DateSettings>;
    filterStr: Subject<string>;
    past6Months: Moment[];
    mayViewCompanyAbc: Observable<boolean>;
    mayViewOwnAbc: Observable<boolean>;
    selectedConfig: Observable<Partial<IQConfiguration>>;
    selectedOrg: Observable<Organization>;
    selectedRep: Subject<number>;
    tableColumns: BehaviorSubject<string[]>;
    tooltips: Record<string, string>;

    subscriptions?: Subscription[];
}

export interface IntermediateClientAbcSettings extends ShareWidgetSettings {
    transactionTypes: ClientAbcTransactionTypes[];
    monthForData: Date;
    showFor: MonthlyShowFor;
    tableColumns: string[];
    appearance: SettingsAppearance;
    dynamicGrouping: ClientAbcDynamicGroups;
}



// #endregion ClientABC Widget Interfaces ===================================================
// #region ClientABC-Group Widget Interfaces --------------------------------------------------- ClientABC-Group

export interface IntermediateClientAbcGroupData {
    dataForCurrentMonth: Subject<DebtTranDatum[]>;
    data: Subject<ClientAbcTableDatum[]>;
    dateForData: Subject<Date>;
    dateSettings: Observable<DateSettings>;
    filterStr: Subject<string>;
    past6Months: Moment[];
    mayViewCompanyGroups: Observable<boolean>;
    mayViewOwnGroups: Observable<boolean>;
    selectedConfig: Observable<Partial<IQConfiguration>>;
    selectedOrg: Observable<Organization>;
    groupKey: Subject<string>;
    tableColumns: BehaviorSubject<string[]>;
    tooltips: Record<string, string>;

    subscriptions?: Subscription[];
}

export interface IntermediateClientAbcGroupSettings extends ShareWidgetSettings {
    monthForData: Date;
    showFor: MonthlyShowFor;
    tableColumns: string[];
    forAllGroups: boolean;
    userKeys: string[];
    groupKeys: string[];

    // -- Come from the group itself.
    transactionTypes: ClientAbcTransactionTypes[];
    appearance: SettingsAppearance;
    dynamicGrouping: ClientAbcDynamicGroups;
    // --
}

// #endregion ClientABC-Group Widget Interfaces ---------------------------------------------------
// #region ClientABC-Group-List Widget Interfaces =================================================== ClientABC-Group-List

export interface IntermediateClientAbcGroupListData {
    groupDocs: ClientAbcGroup[];
    dataForCurrentMonth: Subject<DebtTranDatum[]>;
    data: Subject<ClientAbcGroupTableDatum[]>;
    dateForData: Subject<Date>;
    dateSettings: Observable<DateSettings>;
    filterStr: Subject<string>;
    past6Months: Moment[];
    mayViewCompanyGroups: Observable<boolean>;
    mayViewOwnGroups: Observable<boolean>;
    selectedConfig: Observable<Partial<IQConfiguration>>;
    selectedOrg: Observable<Organization>;
    groupKeys: Subject<string[]>;
    tableColumns: BehaviorSubject<string[]>;
    tooltips: Record<string, string>;
    expandFetchDataSubject: Subject<any>;

    subscriptions?: Subscription[];
}

export interface IntermediateClientAbcGroupListSettings extends ShareWidgetSettings {
    monthForData: Date;
    showFor: MonthlyShowFor;
    tableColumns: string[];
    forAllGroups: boolean;
    groupKeys: string[];
    userKeys: string[];
    // -- Come from the group itself.
    transactionTypes: ClientAbcTransactionTypes[];
    appearance: SettingsAppearance;
    dynamicGrouping: ClientAbcDynamicGroups;
    // --
}

// #endregion ClientABC-Group-List Widget Interfaces ===================================================
// #region Shared Interfaces --------------------------------------------------- Shared

export interface ShareWidgetSettings {
    columns: number;
    rows: number;
    dateProfileKey: string;
    integrationKey: string;
    repKeys: string[], // TODO move out from here...

    // expanded: boolean;
}

interface Shared7RGraphProperties {
    selectedReps$: BehaviorSubject<string[]>;
    summaryByCodeFilter$: BehaviorSubject<null | Record<string, boolean>>;

    dateForData: BehaviorSubject<Date>;
    shouldShowCompanyTarget: BehaviorSubject<boolean>;
    mayViewCompanyStats: Observable<boolean>;
    mayViewRepStats: Observable<boolean>;
    selectedOrg?: undefined | Observable<Organization>;
    dateSettings?: undefined | BehaviorSubject<DateSettings>;
    selectedConfig?: undefined | Observable<Partial<IQConfiguration>>;
}

type DailyShowFor = 'today' | 'yesterday' | 'dayBeforeYesterday' | 'previousBusinessDay' | 'dayBeforePreviousBusinessDay';
type MonthlyShowFor = 'thisMonth' | 'lastMonth' | 'monthBeforeLast';

// #endregion Shared Interfaces ---------------------------------------------------
// #region Deprecated / old / unused --------------------------------------------------- Deprecated / old / unused
export interface IntermediateClientAbcGraphData {
    data: any//Record<string, any>,
    past6Months: Moment[];
}
export interface IntermediateClientAbcGraphSettings extends ShareWidgetSettings {
    monthForData: Date;
    showFor: MonthlyShowFor;
}

// #endregion Deprecated / old / unused ---------------------------------------------------