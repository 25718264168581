
import { environment } from '../../../../environments/environment';
import { Folder, Particle } from '@newgenus/common';
import { Injectable, Input } from '@angular/core';
import { Timestamp } from "firebase/firestore";
import * as moment from 'moment';

@Injectable()
export class ParticleHelper {

  @Input()
  calendar!: any; // NgbCalendar;

  // -- particle -- //
  public previousParticleType: any;
  public currentParticleType: any;
  public previousParticleDate: string | null = null;

  // -- date -- //
  private today!: Date;
  private yesterday!: Date;
  private lastWeek!: Date;
  private olderDay!: Date;
  private weekStart!: Date;
  public dateLabels: any[];

  // -- DOM -- //
  private badge!: string;

  // -- pending variables -- //
  // public model!: NgbDateStruct;
  public model: any;
  public date!: { year: number; month: number; };
  public time = { hour: 12, minute: 0 };
  public meridian = true;
  datePipe: any;

  constructor(
    // private afs: Firestore,
  ) {

    if (this.calendar) {
      this.model = this.calendar.getToday();
    }
    const today = new Date();
    const now = new Date(today.getTime() + 200000); // add 2 minutes to current time
    this.time = { hour: now.getHours(), minute: now.getMinutes() };

    const newDate = new Date();
    const date_now = moment(Date.now());
    const date_today = new Date(newDate);

    const emptyDateLabels = this.createDateLabel(date_today);
    this.dateLabels = this.loadDateLabels(emptyDateLabels, date_now);
  }

  //-- sort folders -- //
  sortFolders(customFolders: Folder[]): Folder[] {

    customFolders.sort((a, b) => {
      return (this.folderSort(a, b)) ? 1 : -1;
    });
    return customFolders;
  }

  folderSort(a: Folder, b: Folder) {
    return a.position > b.position;
  }

  // -- date labels -- //
  createDateLabel(today: Date): Date[] {

    today.setHours(0, 0, 0, 0);

    this.today = today;
    this.yesterday = moment(today).subtract(1, 'day').toDate();
    this.weekStart = moment(today).startOf('week').toDate();
    this.lastWeek = moment(today).startOf('week').subtract(7, 'day').toDate();
    this.olderDay = moment(today).startOf('week').subtract(8, 'day').toDate();

    return [this.today, this.yesterday, this.lastWeek, this.olderDay];
  }

  loadDateLabels(dateLabels: Date[], now: any) {

    const loadedDateLabels = [];
    for (let i = 0; i < dateLabels.length; i++) {
      loadedDateLabels.push(moment(dateLabels[i]).calendar(now, {
        sameDay: '[Today]',
        lastDay: '[Yesterday]',
        lastWeek: '[Last Week]',
        sameElse: '[Older Dates]'
      }));
    }
    return loadedDateLabels;
  }

  // -- date labels ends -- //

  // -- helper function -- //

  shouldBeDisplayed(particle: Particle, fieldToBeDefinedAtAllTime: string[]): boolean {

    let canBeDisplayed: boolean;
    let index = 0;

    do {

      canBeDisplayed = !(this.isFieldUndefined(particle, fieldToBeDefinedAtAllTime[index]));
      ++index;

      if (index === fieldToBeDefinedAtAllTime.length) {
        break;
      }

    } while (canBeDisplayed);
    return canBeDisplayed;
  }

  isFieldUndefined(particle: any, fieldName: string): boolean {
    let isFieldUndefined = false;

    isFieldUndefined =
      (typeof (particle[fieldName]) === "undefined") ||
      (particle[fieldName] === null) ||
      (particle[fieldName] === "")

    if (fieldName === "labelIds") {
      isFieldUndefined = (Array.isArray(particle[fieldName]) && particle[fieldName].length === 0)
    }

    if (isFieldUndefined) {
      console.log("field ", fieldName, " has an issue!");
    }

    return isFieldUndefined;
  }

  getDateLabel(providedDate: any) {

    let label: string;
    const checkdate = providedDate;

    if (checkdate >= this.today.getTime()) {
      label = 'Today'
    }
    else if (checkdate >= this.yesterday.getTime() && checkdate < this.today.getTime()) {
      label = 'Yesterday'
    }
    else if (checkdate < this.yesterday.getTime() && checkdate >= this.weekStart.getTime()) {
      label = 'Earlier this Week'
    }
    else if (checkdate < this.weekStart.getTime() && checkdate >= this.lastWeek.getTime()) {
      label = 'Last Week'
    }
    else if (checkdate < this.lastWeek.getTime()) {
      label = 'Older Dates'
    }
    else {
      label = 'Other Dates'
    }

    return label;
  }

  getLocalDate(creationDate: any) {

    let particledate: Date;
    if (creationDate instanceof Timestamp) {
      particledate = creationDate.toDate();
    }
    else if (creationDate && creationDate['nanoseconds']) {
      particledate = new Date();
      particledate.setMilliseconds(creationDate.nanoseconds);
    }
    else if (creationDate && creationDate['seconds']) {
      particledate = new Date();
      particledate.setSeconds(creationDate.seconds)
    }
    else {
      particledate = creationDate;
    }
    return particledate as Date;
  }

  assignDefaultOrderToParticles(particles: Particle[], particleLabels: string | any[]): Particle[] {

    for (let i = 0; i < particles.length; i++) {

      for (let o = 0; o < particleLabels.length; o++) {

        const label = particleLabels[o];

        if (!(particles[i].labelIds.includes("ORDER")) && !(particles[i].labelIds.includes("DISPATCH"))) {
          particles[i].order = particleLabels.indexOf('EMAIL') + 1;
        }
        else if (particles[i].labelIds.includes(label)) {
          particles[i].order = particleLabels.indexOf(particleLabels[o]) + 1;
        }
        //#region 
        // if (particles[i].labelIds.includes((label === "EMAIL") ? "INBOX" : label)) {

        //   particles[i].order = particleLabels.indexOf(particleLabels[o]) + 1;
        // }
        //#endregion

      }
    }
    return particles;
  }

  assignHeadersToParticles(particles: any[], userSortType: string) {
    for (let i = 0; i < particles.length; i++) {

      const header = this.getParticleHeader(particles[i], userSortType)
      particles[i].header = header !== '' ? header : null; //userPrefSortType
    }
    this.previousParticleType = null;
    this.previousParticleDate = null;
    return particles;
  }

  getStatus(labelIds: string[]) {
    for (const label in labelIds) {
      if (labelIds[label] === "PENDED")
        this.badge = labelIds[label];
      else if (labelIds[label] === "FILED")
        this.badge = labelIds[label];
      else if (labelIds[label] === "NEW")
        this.badge = labelIds[label];
    }

    return this.badge;
  }

  getParticleHeader(particle: { labelIds: any[]; creationDate: any; }, userPrefSortType: string) {

    if (userPrefSortType === "type" || userPrefSortType === "type_date") {

      // const particleType = particle.labelIds.find((element: string) => element === 'INBOX' ||
      //   element === 'DISPATCH' || element === 'ORDER'
      //   // || element === 'SENT' || element === 'DRAFT');
      const particleType = this.identifyParticleType(particle);

      if (this.previousParticleType !== particleType) {
        this.previousParticleType = particleType;
        return particleType;
      } else {
        this.previousParticleType = particleType;
        return '';
      }
    }
    else if (userPrefSortType === "date" || userPrefSortType === "date_type") {

      const particleDate = this.getDateLabel(particle.creationDate);

      if (this.previousParticleDate !== particleDate) {
        this.previousParticleDate = particleDate;
        return particleDate;
      } else {
        this.previousParticleDate = particleDate;
        return '';
      }
    } else {
      return '';
    }
  }

  identifyParticleType(particle: any) {

    if (particle.labelIds.includes("ORDER")) {
      return "ORDER"
    }
    else if (particle.labelIds.includes("DISPATCH")) {
      return "DISPATCH"
    }
    else if (!(particle.labelIds.includes("ORDER")) && !(particle.labelIds.includes("DISPATCH"))) {
      return "EMAIL"
    } else {
      throw new Error("Particle type not identified");
    }
  }

  sortParticlesByUserPreference(particles: any[], typeofsorts: string, dateOrderDirection: boolean) {

    let sortedParticlesOnUserPreference = [];

    if (particles && (typeofsorts === "type_date")) {
      sortedParticlesOnUserPreference = this.sortParticlesPerTypeThenDate(particles, dateOrderDirection);
    }

    if (particles && (typeofsorts === "date_type")) {
      sortedParticlesOnUserPreference = this.sortParticlesPerDateThenType(particles, dateOrderDirection);
    }

    if (particles && (typeofsorts === "type")) {
      sortedParticlesOnUserPreference = this.sortParticlesPerTypeThenDate(particles, false);
    }

    if (particles && (typeofsorts === "date")) {
      sortedParticlesOnUserPreference = this.sortByDate(particles, dateOrderDirection)
    }

    return sortedParticlesOnUserPreference;
  }

  identifyUnreadParticles(particles: Particle[]) {

    //#region Will check with Paul first before using this logic
    // for (let i = 0; i < particles.length; i++) {

    //   if(particles[i].labelIds.includes('UNREAD'))
    //   {
    //     particles[i].readStatus = false;
    //   }
    // }
    //#endregion
    return particles;
  }
  // -- helper function ends -- //

  // -- sort -- //

  sortParticlesRef(ref: any, batchOffset: any, particleBatchLimit: number, userSortPref: string) {

    if (userSortPref === "type_date") {

      // -- order items in firestore by order
      return ref
        .orderBy('order')
        .orderBy('creationDate')
        .startAfter(batchOffset)
        .limit(particleBatchLimit)
    }

    if (userSortPref === "date_type") {
      return ref
        .orderBy('creationDate')
        .orderBy('order')
        .startAfter(batchOffset)
        .limit(particleBatchLimit)
    }

    if (userSortPref === "type") {
      return ref
        .orderBy('order')
        .startAfter(batchOffset)
        .limit(particleBatchLimit)
    }

    if (userSortPref === "date") {
      return ref
        .orderBy('creationDate')
        .startAfter(batchOffset)
        .limit(particleBatchLimit)
    }


  }
  // -- Date - Type -- //
  sortParticlesPerDateThenType(particles: Particle[], asc: boolean) {
    const dateLabelledParticles = this.groupParticlesOnDateLabels(particles, asc);
    const sortedParticles = this.sortParticlesGroupsPerType(dateLabelledParticles);
    return sortedParticles;
  }

  groupParticlesOnDateLabels(particles: Particle[], asc: boolean): any {

    const groupOfParticles: any = {};
    const sortedParticles = this.sortByDate(particles, asc);

    for (let i = 0; i < sortedParticles.length; i += 1) {

      const particleDateLabel = this.getDateLabel(sortedParticles[i].creationDate);

      if (!groupOfParticles[particleDateLabel]) {
        groupOfParticles[particleDateLabel] = [];
      }
      groupOfParticles[particleDateLabel].push(sortedParticles[i]);
    }

    return groupOfParticles;
  }

  sortParticlesGroupsPerType(particleGroups: any) {

    const sortedParticles = [];
    for (const particleGroup in particleGroups) {

      if (particleGroups[particleGroup]) {

        particleGroups[particleGroup] = this.sortByType(particleGroups[particleGroup]);

        particleGroups[particleGroup].reverse();

        for (let i = 0; i < particleGroups[particleGroup].length; i++) {

          const sortedParticle = particleGroups[particleGroup][i];
          sortedParticles.push(sortedParticle);
        }
      }
    }

    return sortedParticles.reverse(); // final reverse happens here, order to allow the particle list to have a descending order look.

  }
  // type - date -- //
  sortParticlesPerTypeThenDate(particles: Particle[], asc: boolean) {

    const typeLabelledParticles = this.groupParticlesOnTypeLabels(particles);
    const sortedParticles = this.sortParticlesGroupsPerDate(typeLabelledParticles, asc);
    return sortedParticles;
  }

  sortParticlesGroupsPerDate(particleGroups: any, asc: boolean) {

    const sortedParticles = [];

    for (const group in particleGroups) {

      if (particleGroups[group]) {

        particleGroups[group] = this.sortByDate(particleGroups[group], asc);

        particleGroups[group].reverse(); // so that particles of higher order remain on top after the final reverse.

        for (let i = 0; i < particleGroups[group].length; i++) {
          const sortedParticle = particleGroups[group][i];
          sortedParticles.push(sortedParticle);
        }
      }
    }

    return sortedParticles;
  }

  groupParticlesOnTypeLabels(particles: Particle[]): any {

    const groupedHeader: any = {};

    for (let i = 0; i < particles.length; i += 1) {

      if (!groupedHeader[particles[i].order]) {
        groupedHeader[particles[i].order] = [];
      }

      groupedHeader[particles[i].order].push(particles[i]);
    }
    return groupedHeader;
  }

  sortByDate(particles: Particle[], asc: boolean) {

    if (asc) {
      particles.sort((a, b) => {
        return this.dateSortAsc(a, b) ? 1 : -1;
      });
    }
    else {
      particles.sort((a, b) => {
        return this.dateSortDesc(a, b) ? 1 : -1;
      });
    }
    return particles;
  }

  dateSortAsc(a: Particle, b: Particle) {
    const time1 = a.creationDate;
    const time2 = b.creationDate;
    return time1 < time2;
  }

  dateSortDesc(a: Particle, b: Particle) {
    const time1 = a.creationDate;
    const time2 = b.creationDate;
    return time1 > time2;
  }

  sortByType(particles: Particle[]) {

    particles.sort((a, b) => {
      return (this.typeSort(a, b)) ? 1 : -1;
    });
    return particles;
  }

  typeSort(a: Particle, b: Particle) {
    return a.order > b.order;
  }
  // -- sort ends -- //

  //Old and unused codes
  sortBy(typeofsorts: string, particles: Particle[]) {

    switch (typeofsorts) {
      case 'type':
        return this.sortByType(particles);

      case 'date':
        return this.sortByDate(particles, false);

      default:
        return particles;
    }
  }

  filterParticles(particles: Particle[], folder: string) {

    const displaylist: Particle[] = [];

    if (particles !== undefined) {

      let search_term: string;

      if (folder === undefined || folder === '') {
        search_term = 'NEW';
      }
      else {
        search_term = folder.toUpperCase();
      }

      for (let i = 0; i < particles.length; i++) {

        switch (search_term) {
          case 'INBOX':
            if (particles[i].labelIds.includes(search_term) &&
              particles[i].labelIds.includes('FILED') &&
              !particles[i].labelIds.includes('PENDED')) {
              displaylist.push(particles[i]);
            }
            break;
          case 'OUTBOX':
          case 'SENT':
            if (particles[i].labelIds.includes(search_term) &&
              (particles[i].labelIds.includes('FILED') ||
                particles[i].labelIds.includes('PENDED'))) {
              displaylist.push(particles[i]);
            }
            break;
          default:
            if (particles[i].labelIds.includes(search_term)) {
              displaylist.push(particles[i]);
            }
            break;
        }
      }
    }

    return displaylist;
  }

  groupsortBy(typeofsorts: string, particles: Particle[], descending: boolean) {

    if (typeofsorts === "type_date") {

      particles.sort((a, b) => {

        if (a.order >= b.order) {

          if (descending) {

            if (a.creationDate > b.creationDate) {
              return 1;
            } else {
              return -1;
            }
          }
          else {

            if (a.creationDate < b.creationDate) {
              return 1;
            } else {
              return -1;
            }
          }

        } else {

          //WORKS!!!
          // if (a.creationDate < b.creationDate) {
          //   return 1;
          // } else {
          //   return -1;
          // }

          if (descending) {
            if (a.creationDate < b.creationDate) {
              return 1;
            } else {
              return -1;
            }
          }
          else {

            if (a.creationDate > b.creationDate) {
              return 1;
            } else {
              return -1;
            }
          }



        }


      })

    }

    if (typeofsorts === "date_type") {

      particles.sort((a, b) => {

        if (descending) {
          if (a.creationDate > b.creationDate) {

            if (a.order > b.order) {

              return 1
            }
            else {

              return -1;
            }

          }
          else {
            // return -1;

            if (a.order < b.order) {

              return 1
            }
            else {

              return -1;
            }

          }
        }
        else {
          if (a.creationDate < b.creationDate) {

            if (a.order > b.order) {

              return 1
            }
            else {

              return -1;
            }

          }
          else {
            // return -1;

            if (a.order < b.order) {

              return 1
            }
            else {

              return -1;
            }

          }
        }

      })

    }

    return particles;
  }

  searchParticles(particles: Particle[], searchTerm: string) {
    let fromSearch: string[] = [];
    let subjectSearch: string[] = [];
    let bodySearch: string[] = [];

    particles = particles.filter(p => {

      fromSearch = p.from.match(searchTerm) as RegExpMatchArray;
      subjectSearch = p.subject.match(searchTerm) as RegExpMatchArray;
      bodySearch = p.content.match(searchTerm) as RegExpMatchArray;

      if (fromSearch !== null || subjectSearch !== null || bodySearch !== null)
        return true;
      else
        return false;

    });

  }

  groupParticleDatesIntoHeadersAsc(particles: Particle[]) {

    const finalListToReturn = [];
    const groupedHeader: Record<number, Particle[]> = {};
    let theDate: number;

    for (let i = 0; i < particles.length; i++) {

      theDate = particles[i].creationDate.toDate().getTime();

      if (!groupedHeader[theDate]) {
        groupedHeader[theDate] = [];
      }

      groupedHeader[theDate].push(particles[i]);
    }


    for (const group in groupedHeader) {

      if (groupedHeader[group]) {

        groupedHeader[group] = this.sortByType(groupedHeader[group]);

        groupedHeader[group].reverse(); // so that particles of higher order remain on top after the final reverse line no 353.

        for (let i = 0; i < groupedHeader[group].length; i++) {

          const element = groupedHeader[group][i];
          finalListToReturn.push(element);
        }
      }
    }

    return finalListToReturn;
  }


  convertParticleDatesToLocal(particles: any[]) {

    const particlesWithLocalDates = [];

    for (let i = 0; i < particles.length; i++) {

      if (particles[i].creationDate !== null) {
        particles[i].creationDate = this.getLocalDate(particles[i].creationDate);
        particlesWithLocalDates.push(particles[i]);
      }
    }

    return particlesWithLocalDates;
  }

  sortParticles(particles: Particle[], particleLabels: string[], userPrefSortType: string, dateSortDirection: string): Particle[] {


    const defaultOrderParticles = this.assignDefaultOrderToParticles(particles, particleLabels);

    const sortedParticles = this.sortParticlesByUserPreference(defaultOrderParticles, userPrefSortType, (dateSortDirection === "asc"));
    return sortedParticles;
  }

  // assignHeadersToParticle(particles: Particle[], userPrefSortType: string): Particle[] {
  //   return particles = this.assignHeadersToParticles(particles, userPrefSortType);
  // }

  // ---- particle List Functions ----- //

  filterOutParticlesForComponent(componentName: string, particles: Particle[], labels: { [x: string]: { labels: string | string[]; }; }): Particle[] {

    let filteredOutParticles: Particle[] = [];

    switch (componentName) {
      case 'inbasket':
        filteredOutParticles = particles.filter(particle => {
          if (labels[particle.particleKey].labels.includes('INBASKET') && particle.temp === false) { // && labels[particle.particleKey].processing === false
            return true;
          } else {
            return false;
          }
        });
        break;
      case 'pending':
        filteredOutParticles = particles.filter(particle => { // TODO: BINGO --> make me happy.
          if (labels[particle.particleKey].labels.includes('PENDING') && particle.temp === false) { // particle.labelIds.includes('PENDING')
            return true;
          } else {
            return false;
          }
        });
        break;
      case 'outbasket':
        filteredOutParticles = particles.filter(particle => {
          if (labels[particle.particleKey].labels.includes('DONE') && particle.temp === false) { //  particle.labelIds.includes('DONE')  && labels[particle.particleKey].processing === false
            return true;
          } else {
            return false;
          }
        });
        break;
      case 'filed':
        filteredOutParticles = particles.filter(particle => {
          if (labels[particle.particleKey] && labels[particle.particleKey].labels.includes('DONE') && particle.temp === false) {
            return true;
          }
          else if (particle.labelIds.includes('NOT_IMPORTED')) {
            return true;
          } else {
            return false;
          }
        });
        break;
      case 'search':
        filteredOutParticles = particles.filter(particle => {
          if (labels[particle.particleKey] && (
            labels[particle.particleKey].labels.includes('DONE') ||
            labels[particle.particleKey].labels.includes('INBASKET') ||
            labels[particle.particleKey].labels.includes('PENDING')
          )) {
            return true;
          } else if (particle.labelIds.includes('NOT_IMPORTED')) {
            return true;
          } else {
            return false;
          }
        });
        break;
      default:
        return [];

    }
    return filteredOutParticles;
  }

  filterOutParticleForFolder(folderName: string, particlesWithAssignedFolders: Particle[]) {

    let filteredOutParticles: Particle[] = [];

    if (particlesWithAssignedFolders) {
      filteredOutParticles = particlesWithAssignedFolders.filter(particle => {

        const particleFolder = particle.folders;
        const result = particleFolder[folderName] !== undefined;
        return result;
      });
    }

    return filteredOutParticles;
  }

  assignFoldersToParticles(particles: Particle[], folders: any) {

    for (let i = 0; i < particles.length; i++) {

      if (!(particles[i].labelIds.includes('DONE') || particles[i].labelIds.includes('PENDING'))) {
        const folderForParticle = folders.find((folder: { name: string; }) => folder.name.toLowerCase() === 'inbasket');

        particles[i].folders = this.getTheFolderReference(particles[i], folderForParticle);
      }
      if (particles[i].labelIds.includes('PENDING')) {
        const folderForParticle = folders.find((folder: { name: string; }) => folder.name.toLowerCase() === 'pending');

        particles[i].folders = this.getTheFolderReference(particles[i], folderForParticle);
      }
      if (particles[i].labelIds.includes('DONE')) {
        const folderForParticle = folders.find((folder: { name: string; }) => folder.name.toLowerCase() === 'outbasket');

        particles[i].folders = this.getTheFolderReference(particles[i], folderForParticle);
      }
      if (particles[i].labelIds.includes('DONE')) {
        const folderForParticle = folders.find((folder: { name: string; }) => folder.name.toLowerCase() === 'filed');

        particles[i].folders = this.getTheFolderReference(particles[i], folderForParticle);
      }
    }


    return particles;
  }

  getTheFolderReference(particle: Particle, folderForParticle: Folder) {

    let folders: any = {};
    const particleFolders = (particle.folders) ? particle.folders : {};

    if (Object.keys(particleFolders).length > 0) {
      folders = particle.folders;
    }
    else {

      const folderId = folderForParticle.id;

      if (!folders[folderId]) {
        folders[folderId] = {};
        folders[folderId] = folderForParticle.name;
      }
    }

    return folders;
  }

  updateDropDownName(typeofsorts: string): string {

    let displayText = "";

    switch (typeofsorts) {
      case "type_date":
        displayText = "Particle - Date Sort"
        break;

      case "date_type":
        displayText = "Date - Particle Sort"
        break;

      case "date":
        displayText = "Date Sort"
        break;

      case "type":
        displayText = "Particle Sort"
        break;

      default:
        displayText = "Change Order"
        break;
    }

    return displayText;
  }

  linkGmailAccount() {
    (window as any).location = environment.gcp.toolsOauthInit;
  }
  // --- particle List Functions end ---- //


  // ---- particle Pend Functions ---- //
  selectPendingTime() {
    this.model = this.calendar.getToday();
    const today = new Date();
    const now = new Date(today.getTime() + 200000); // add 2 minutes to current time
    this.time = { hour: now.getHours(), minute: now.getMinutes() };
  }

  setPendingTimePlusFiveMinutes() {
    const monthIndex = this.model.month !== 0 ? this.model.month - 1 : this.model.month;
    const oldDate = new Date(this.model.year, monthIndex, this.model.day, this.time.hour, this.time.minute, 0);
    const newDate = this.addMinutes(oldDate, 5); // add 2 minutes to current time
    const minute = newDate.getMinutes();
    const hour = newDate.getHours();
    this.time = { hour, minute };
  }

  setPendingTimePlusThirtyMinutes() {
    const monthIndex = this.model.month !== 0 ? this.model.month - 1 : this.model.month;
    const oldDate = new Date(this.model.year, monthIndex, this.model.day, this.time.hour, this.time.minute, 0);
    const newDate = this.addMinutes(oldDate, 30); // add 2 minutes to current time
    const minute = newDate.getMinutes();
    const hour = newDate.getHours();
    this.time = { hour, minute };
  }

  setPendingTimePlusOneDay() {
    // const date: NgbDate = new NgbDate(this.model.year, this.model.month, this.model.day);
    const date = new Date(this.model.year, this.model.month, this.model.day);
    this.model = this.calendar.getNext(date, 'd', 1);
  }

  addMinutes(date: Date, minutes: number) {
    return new Date(date.getTime() + minutes * 60000);
  }

  selectToday() {
    this.model = this.calendar.getToday();
    const currentTime = new Date();
    const now = new Date(currentTime.getTime() + 0); // add 2 minutes to current time
    this.time = { hour: now.getHours(), minute: now.getMinutes() };
  }

  pendParticle(particle: any, callback: (particle: any, model: any, time: any) => void) {
    callback(particle, this.model, this.time);
    this.selectToday();
  }

  toggleMeridian() {
    this.meridian = !this.meridian;
  }
  // --- particle Pend Functions End --- //

  mergeArray(array1: any[], array2: any[]) {
    const result_array: any[] = [];
    const arr = array1.concat(array2);
    let len = arr.length;
    const assoc: any = {};

    while (len--) {
      const item = arr[len];

      if (!assoc[item]) {
        result_array.unshift(item);
        assoc[item] = true;
      }
    }

    return result_array;
  }

  addElementToArray(array: any, array2: string[]) {
    const newArray = this.mergeArray(array, array2);
    return newArray;
  }

  removeElementFromArray(array: any, element: string) {
    const index = array.indexOf(element);
    if (index > -1) {
      array.splice(index, 1);
    }
    return array;
  }

  removeLabelFromParticle(particle: any, element: string): Particle {

    const newParticle = particle;
    const particlelabelIds = particle.labelIds.length > 0 ? particle.labelIds : [];
    const newArray = this.removeElementFromArray(particlelabelIds, element);

    newParticle.labelIds = newArray;
    return newParticle;
  }

}