<ng-container [ngSwitch]="type"> 

  <div *ngSwitchCase="'daily7r'" [id]="id" class="h-100 w-100">
    <!-- [consolidateData]="(daily7rData.dateSettings | async)?.consolidateHours" -->
    <shared-7r-graph-day
      [consolidateData]="true"
      [summaryData]="daily7rData.dataObj.summaries| async"
      [data]="daily7rData.dataObj.data | async"
      [date]="daily7rData.dateForData | async"
      [dateSettings]="daily7rData.dateSettings | async"
      [filtersByCode]="daily7rData.summaryByCodeFilter$ | async"
      [mayViewCompanyStats]="daily7rData.mayViewCompanyStats | async"
      [mayViewRepStats]="daily7rData.mayViewRepStats | async"
      [parentProps]="containerProps"
      [repKeys]="daily7rData.selectedReps$"
      [selectedConfig]="daily7rData.selectedConfig | async"
      [selectedOrg]="daily7rData.selectedOrg | async"
      [showCompanyTargets]="daily7rData.shouldShowCompanyTarget | async"
      [showConsolidateHours]="false"
      [showDatePicker]="false"
      [showDateRange]="false"

      (fromDateChange)="fromDateChange.emit($event)"
      (toDateChange)="toDateChange.emit($event)"
    ></shared-7r-graph-day>
  </div>

  <div *ngSwitchCase="'monthly7r'" [id]="id" class="h-100 w-100">
    <shared-7r-graph-month
      [consolidateData]="false"
      [summaryData]="monthly7rData.summaryData | async"
      [data]="monthly7rData.data | async"
      [date]="monthly7rData.dateForData | async"
      [dateSettings]="monthly7rData.dateSettings | async"
      [filtersByCode]="monthly7rData.summaryByCodeFilter$ | async"
      [mayViewCompanyStats]="monthly7rData.mayViewCompanyStats | async"
      [mayViewRepStats]="monthly7rData.mayViewRepStats | async"
      [parentProps]="containerProps"
      [repKeys]="monthly7rData.selectedReps$"
      [selectedConfig]="monthly7rData.selectedConfig | async"
      [selectedOrg]="monthly7rData.selectedOrg | async"
      [showCompanyTargets]="monthly7rData.shouldShowCompanyTarget | async"
      [showConsolidateHours]="false"
      [showDatePicker]="false"
      [showDateRange]="false"

      (fromDateChange)="fromDateChange.emit($event)"
      (toDateChange)="toDateChange.emit($event)"
    ></shared-7r-graph-month>
  </div>

  <!-- <div *ngSwitchCase="'clientAbcGraph'" [id]="id">
    <shared-client-abc-graph
      [data]="clientAbcGraphData.data"
      [past6Months]="clientAbcGraphData.past6Months"
      [parentProps]="containerProps"
    ></shared-client-abc-graph>
  </div> -->

  <div *ngSwitchCase="'clientAbc'" [id]="id" class="h-100 w-100">
    <shared-client-abc
      [dataForCurrentMonth]="clientAbcData.dataForCurrentMonth | async"
      [dateForData]="clientAbcData.dateForData | async"
      [dateProfile]="clientAbcData.dateSettings | async"
      [filterValue]="clientAbcData.filterStr | async"
      [gatewayConfig]="clientAbcData.selectedConfig | async"
      [organization]="clientAbcData.selectedOrg | async"
      [past6MonthsIncludingCurrentMonth]="clientAbcData.past6Months"
      [selectedRep]="clientAbcData.selectedRep | async"
      [tableColumns]="clientAbcData.tableColumns | async"
      [tableData]="clientAbcData.data | async"
      [tooltips]="clientAbcData.tooltips"
      
      ></shared-client-abc>
      <!-- [showFooter]="false" -->
      <!-- [settings]="clientAbcSettings" -->
      
  </div>
  
  <div *ngSwitchCase="'clientAbcGroup'" [id]="id" class="h-100 w-100">
    <shared-client-abc
      [dataForCurrentMonth]="clientAbcGroupData.dataForCurrentMonth | async"
      [dateForData]="clientAbcGroupData.dateForData | async"
      [dateProfile]="clientAbcGroupData.dateSettings | async"
      [filterValue]="clientAbcGroupData.filterStr | async"
      [gatewayConfig]="clientAbcGroupData.selectedConfig | async"
      [organization]="clientAbcGroupData.selectedOrg | async"
      [past6MonthsIncludingCurrentMonth]="clientAbcGroupData.past6Months"
      [selectedRep]="null"
      [tableColumns]="clientAbcGroupData.tableColumns | async"
      [tableData]="clientAbcGroupData.data | async"
      [tooltips]="clientAbcGroupData.tooltips"
      
      ></shared-client-abc>
  </div>
  
  <div *ngSwitchCase="'clientAbcGroupList'" [id]="id" class="h-100 w-100">
    <shared-client-abc
      [dataForCurrentMonth]="clientAbcGroupListData.dataForCurrentMonth | async"
      [dateForData]="clientAbcGroupListData.dateForData | async"
      [dateProfile]="clientAbcGroupListData.dateSettings | async"
      [filterValue]="clientAbcGroupListData.filterStr | async"
      [gatewayConfig]="clientAbcGroupListData.selectedConfig | async"
      [organization]="clientAbcGroupListData.selectedOrg | async"
      [past6MonthsIncludingCurrentMonth]="clientAbcGroupListData.past6Months"
      [selectedRep]="null"
      [tableColumns]="clientAbcGroupListData.tableColumns | async"
      [tableData]="clientAbcGroupListData.data | async"
      [tooltips]="clientAbcGroupListData.tooltips"
      [isClientAbcGroup]="true"
      [isWidget]="true"

      (expandRow)="onClientAbcGroupExpand($event)"
      ></shared-client-abc>
      <!-- TODO: this functionality isn't really needed here... -->
      <!-- (deleteGroup)="onClientAbcGroupDeleteGroup($event)"
      (editGroup)="onClientAbcEditGroup($event)" -->
  </div>

  <div *ngSwitchDefault>Widget type not supported...</div>

</ng-container>

