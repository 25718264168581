import { OrgDialogServiceService } from '../services/org-dialog.service';
import { OrbBoardBaseComponent } from '../org-board-base-component';
import { Component, inject, Input } from '@angular/core';
import { CommonModule, NgStyle } from '@angular/common';
import { MaterialModule } from '../../material.module';
import { Position } from '../services/tree-builder.service';
import { ECONode } from '@newgenus/common';

@Component({
  selector: 'shared-sidebar-empty-user',
  standalone: true,
  imports: [CommonModule, MaterialModule, NgStyle],
  template: `
    <!-- Stub user details. -->
    <mat-card class="my-2">
      <!-- Stub user name and profile picture. -->
      <mat-card-header>
        <div mat-card-avatar class="example-header-image" [ngStyle]="{'background-image': 'url(https://via.placeholder.com/40)'}">
        </div>
        <mat-card-title>Unoccupied</mat-card-title>
        <mat-card-subtitle>No Hat</mat-card-subtitle>
      </mat-card-header>

      <!-- Stub user detail. -->
      <mat-card-content>
        <p>There is no hat in this position.</p>

        <span>Hats:</span>
        <div class="description-list-container">
          <dl>
            <dt>None</dt>
            <dd>-</dd>
          </dl>
        </div>
      </mat-card-content>

      <!-- Stub user actions. -->
      <mat-card-actions align="end" class="button-margin">
        <button mat-stroked-button color="primary" (click)="dialogService.openSecurityGroupsDialog(node)"><mat-icon>add_circle</mat-icon>Assign Hat</button>
        <!-- <button mat-button color="primary">BUTTON</button> -->
        <!-- <button mat-raised-button color="primary">BUTTON</button> -->
        <!-- <button mat-stroked-button color="primary">BUTTON</button> -->
        <!-- <button mat-flat-button color="warn">BUTTON</button> -->
      </mat-card-actions>
    </mat-card>`,
  styleUrls: ['../organization-board.component.scss']
})
export class SidebarEmptyUserComponent extends OrbBoardBaseComponent {

  @Input()
  public isDev = false;

  @Input() // TODO: this should come from the Org doc, and be linked to a UID. Only the matching UID should be allowed to edit?
  public isEditing = false;

  @Input()
  public node!: ECONode<Position>;

  public override ngOnInit(): void {
    // Doesn't need to do anything.
  }

}
