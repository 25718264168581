import { ECOTree, Organization, deepCopy, flatten } from '@newgenus/common';
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class TreeBuilderService {

    public buildOrg(org: Organization, treeId: string) {
        return OrgTree.withOrg(org, "", treeId, org.orgUsers, true);
    }

    public insertPositionsToEcoTree(orgTree: OrgTree, ecoTree: ECOTree, config = {
        width: 300,
        height: 150,
        color: fromStringToHex('black'),
        background: fromStringToHex('white'),
        linkColor: fromStringToHex('red'),
    }) {
        const positions = orgTree.positions;

        // Map child positions to parent positions using the partKey and parentPartKey.
        const parentChildMap = new Map<string, string[]>();
        positions
            // Sort by depth to ensure that parents are processed before children.
            .sort((a, b) => a.depth - b.depth)
            .forEach((position) => {
                if (position.parentPartKey) {
                    const children = parentChildMap.get(position.parentPartKey) || [];
                    children.push(position.partKey);
                    parentChildMap.set(position.parentPartKey, children);
                }
            });

        // Create position array with parents and children.
        let relationalPositions: Position[] = positions
            .sort((a, b) => a.depth - b.depth)
            .map((position) => {
                const children = parentChildMap.get(position.partKey) || [];
                const childrenData = positions.filter((p) => children.includes(p.partKey));
                const parents = positions.filter((p) => p.partKey === position.parentPartKey);
                const parentsData = parents.length > 0 ? parents : undefined;

                return {
                    ...position,
                    children: childrenData,
                    parents: parentsData,
                };
            }
            );

        // Shift all the children into the parents. If there are no parents, then the position is a root position.
        relationalPositions.forEach((position) => {
            if (position.parents) {
                position.parents[0].children = position.parents[0].children || [];
                position.parents[0].children.push(position);
            }
        })

        const rootPosition = relationalPositions.find((position) => !position.parents);
        // console.log('rootPosition: ', rootPosition);

        // Create the tree structure.
        this.recursivelyAddNodes(ecoTree, rootPosition);
        ecoTree.UpdateTree();
        // console.log('updated ecoTree: ', ecoTree);
    }

    private recursivelyAddNodes(tree: ECOTree, node: any, parent: any = null) {
        parent = parent || {
            id: -1,
            width: 300,
            height: 150,
            color: fromStringToHex('black'),
            background: fromStringToHex('white'),
            linkColor: fromStringToHex('red'),
        };
        node.data = { ...node, children: null, parents: null };
        node.data.userDetailsArray = node.data.userDetailsArray || [];
        node.width = node.width || parent.width;
        node.height = node.height || parent.height;
        node.id = tree.nDatabaseNodes.length;

        node.color = fromStringToHex(node?.colour?.color || parent.color);
        node.background = fromStringToHex(node?.colour?.background || parent.background);
        node.linkColor = fromStringToHex(node?.colour?.linkColor || parent.background);
        if (node.data?.colour) {
            node.data.colour.color = fromStringToHex(node.data.colour.color || node.color);
            node.data.colour.background = fromStringToHex(node.data.colour.background || node.background);
            node.data.colour.linkColor = fromStringToHex(node.data.colour.linkColor || node.linkColor);
        }

        const firstLine = (node?.id || '') + (node.userDetailsArray ? node.userDetailsArray[0].name : '');
        const secondLine = node.userDetailsArray ? node.userDetailsArray[0].primaryPositionSecurityGroupsForUser[0]?.name || '' : '';
        const thirdLine = ''; // node.data?.user?.securityGroup?.name || '';
        let fourthLine = '', fifthLine = '';
        if (node.userDetailsArray && node.userDetailsArray.length > 0) {
            node.userDetailsArray.forEach((user: UserDetails) => {
                fourthLine += user?.name ? user.name + ' ' : '';
            });
        }
        // if (node.data?.heldFromAboveUid) {
        //     fifthLine = node.data.heldFromAboveUserName;
        // }

        // Calculate height based on the amount of text in the node.data.
        // The container should minimum be 80px high.
        // The height should be 20px per line of text.
        // Width is set to 300px with a padding of 10px.
        // Characters per line should be 25, except for firstLine where the font size is larger, there we will consider 20 characters per line.
        const characterLength = firstLine.length + secondLine.length + thirdLine.length + fourthLine.length + fifthLine.length;
        const calculatedHeight = 170 + (45 * Math.ceil(characterLength / 25));

        tree.add(
            node.id,
            parent.id,
            node.title,
            node.width,
            calculatedHeight,
            // node.height,
            node.color,
            node.background,
            node.linkColor,
            node.data,
            node.selected
        );

        if (node.children) {
            node.children.forEach((x: any) => {
                this.recursivelyAddNodes(tree, x, node);
            });
        }
    }

}

function fromStringToHex(color: string): string {
    // Possible example of the objects that will call this function include:
    //     const partData = {
    //         linkColor: "blue",
    //         background: "blue",
    //         color: "white",
    //         selected: true,
    //     };
    //     const partData = {
    //         linkColor: "orange",
    //         background: "orange",
    //         color: "white",
    //         selected: true,
    //     };
    //     const partData = {
    //         linkColor: "purple",
    //         background: "purple",
    //         color: "white",
    //         selected: true,
    //     };
    //     const partData = {
    //         linkColor: "pink",
    //         background: "pink",
    //         color: "black",
    //         selected: true,
    //     };
    //     const partData = {
    //         linkColor: "lime",
    //         background: "lime",
    //         color: "black",
    //         selected: true,
    //     };
    //     const partData = {
    //         linkColor: "grey",
    //         background: "grey",
    //         color: "white",
    //         selected: true,
    //     };
    //     const partData = {
    //         linkColor: "yellow",
    //         background: "yellow",
    //         color: "black",
    //         selected: true,
    //     };
    // Taking this into consideration, we must return the 7 code HEX value of the color.
    // For example, if the color is "blue", we must return "#0000FF".

    switch (color) {
        case "blue":
            return "#0000FF";
        case "orange":
            return "#FFA500";
        case "purple":
            return "#800080";
        case "pink":
            return "#FFC0CB";
        case "lime":
            return "#00FF00";
        case "grey":
            return "#808080";
        case "yellow":
            return "#FFFF00";
        case "red":
            return "#FF0000";
        case "green":
            return "#008000";
        case "white":
            return "#FFFFFF";
        case "black":
            return "#000000";
        default:
            return color;
    }

}

class Org {
    org: any; // Organization;
    positions: any;
    orgTree: any;
    orgUsers?: any;
    tree?: string;

    static withOrg(organization: Organization, orgTree?: any, t2?: string, orgUsers?: any) {
        // console.log(' >>> ', ' withOrg: ', t2);
        return new this(organization, orgTree, t2, orgUsers);
    }

    constructor(org: Organization, orgTree?: any, tree?: string, orgUsers?: any) {

        this.org = deepCopy(org);
        this.orgTree = deepCopy(orgTree);
        // console.log(' >>> ', ' constructor: ', tree);
        this.tree = deepCopy(tree);
        this.orgUsers = deepCopy(orgUsers);
    }

    public run() {
        // do everything in intent list - all updates to org doc and sub-collection docs
        return this.org;
    }

    public processChanges() {
        // do everything in intent list - all updates to org doc and sub-collection docs
        return this.org;
    }

    /**
     *
     * @param {string} tree
     * @return {*}
     * @memberof OrgTree
     */
    public newInstance(tree: string) {
        // console.log(' >>> ', ' newInstance: ', tree);
        return new Org(this.org, this.orgTree, tree, this.orgUsers);
    }

    /**
     * This method adds a sg to a user in the org. It does not add the sg to the org.
     *
     * @param {*} securityGroup - sg to add to the org
     * @param {*} user - user to add the sg to
     * @return {*}
     * @memberof OrgTree
     * @example ```typescript
     * const orgTree = OrgTree.withOrg(org,"","tree1").addSecurityGroupToUser(securityGroup1,user1).log();
     * ```
     */
    public addSecurityGroupToUser(securityGroup: any, user: any) {
        // generate orgTree from parts
        for (let i = 0; i < this.org.orgParts.length; i++) {
            const part = this.org.orgParts[i];
        }
        // find user in this.org
        const userFound = this.org.orgUsers.find((u: any) => u.key === user.key);
        // find the securityGroup in this.org
        const sgFound = this.org.orgSecurityGroups.find((h: any) => h.key === securityGroup.key);

        let message = '';
        if (userFound && sgFound) {
            // add securityGroup to user
            securityGroup.assigned = true;
            securityGroup.assignedTo = user.key;
            userFound.securityGroupDetails[securityGroup.key] = securityGroup;
            userFound.features = [...userFound.features, ...securityGroup.features];
            userFound.featureDetails = { ...userFound.featureDetails, ...securityGroup.featureDetails };
            this.org.orgUsers = [...this.org.orgUsers.filter((u: any) => u.key !== user.key), userFound];
            this.org.orgSecurityGroups = [...this.org.orgSecurityGroups.filter((h: any) => h.key !== securityGroup.key), securityGroup];
        } else {
            message = 'user or security group not found';
            // can handle errors here
        }

        // console.log(' message >>> ', message);
        return this;
    }

    public addSecurityGroupToOrg(securityGroup: any) {
        this.org.orgSecurityGroups.push(securityGroup);
        return this;
    }

    public addUserToOrg(user: any) {
        this.org.orgUsers.push(user);
        return this;
    }

    public log() {
        // console.log(' >>> ', ' tree: ', this.tree);
        // console.log(' >>> ', ' tree: ', this.tree, ' : ', this.org);
        // console.log(' >>> ', ' orgTree: ', this.orgTree);
        return this;
    }


    // public writeFile(filename: string) {
    //     fs.writeFileSync(path.join(__dirname, `${filename}.json`), JSON.stringify(this.run()));
    //     return this;
    // }
}

class OrgTree extends Org {
    override org: any;
    override positions: Position[] = [];
    override orgTree: any;
    override orgUsers?: any;
    override tree?: string;

    private partsSwatch: any = {
        org: {
            color: 'white',
            background: 'red',
        },
        securityGroup: {
            color: 'white',
            background: 'green',
        },
        feature: {
            color: 'white',
            background: 'blue',
        },
        featureDetails: {
            color: 'white',
            background: '#6060f4',
        },
        permission: {
            color: 'white',
            background: '#6060f4',
        },
        role: {
            color: 'white',
            background: 'yellow',
        },
        user: {
            color: 'white',
            background: '#c2185b',
        },
        orgSecurityGroupsSubCollection: {
            color: 'black',
            background: '#04d704',
        },
        userOnOtherSecurityGroupsWithSamePosition: {
            color: 'white',
            background: '#4d1c2f',
        },
        securityGroupsOnPositionObject: {
            color: 'black',
            background: '#04d704',
        },
    };
    private securityGroupObject: any = null;
    private securityGroupsTreeObjectForOrgSecurityGroups: any = {}; // All the sgs for the selected org derived from org doc - orgSecurityGroups object

    /**
     * This static method creates a new instance of the OrgTree class.
     *
     * @static
     * @memberof OrgTree
     */
    static override withOrg(v: any, t?: any, t2?: string, orgUsers?: any, isInitial: boolean = false) {
        // console.log(' >>> ', ' withOrg: ', t2);
        return new this(v, t, t2, orgUsers, isInitial);
    }

    constructor(org: any, orgTree?: any, tree?: string, orgUsers?: any, isInitial: boolean = false) {
        super(deepCopy(org), deepCopy(orgTree), deepCopy(tree));
        // console.log(' >>> ', ' constructor: ', tree);

        if (isInitial) {
            this.positions = (this.initial(this.tree || "")).positions;
            // // console.log("this.positions: ", this.positions);
        }

        this.org = deepCopy(org);
        this.orgTree = deepCopy(orgTree);
        this.tree = deepCopy(tree);
        this.orgUsers = deepCopy(orgUsers);

    }

    /**
     *
     * @param {string} tree
     * @return {*}
     * @memberof OrgTree
     */
    override newInstance(tree: string) {
        // console.log(' >>> ', ' newInstance: ', tree);
        return new OrgTree(this.org, this.orgTree, tree, this.orgUsers);
    }

    initial(tree: string) {
        // do everything in intent list - all updates to org doc and sub-collection docs
        this.orgTree = { tree: tree };

        const orgParts = this.org.orgParts;
        const positionsWillInheritFromSecurityGroupAbove2 = this.appendAllChildPositionsToOrgParts(orgParts);

        // find the first section of the path in partToStartFrom and identify the substring section to remove
        const partToStartFromPath = deepCopy(orgParts[0].path);
        const partToStartFromPathSplit = partToStartFromPath.split(".");

        let partToStartFromPathSplitString = "";
        for (let i = 0; i < partToStartFromPathSplit.length - 1; i++) {
            partToStartFromPathSplitString += partToStartFromPathSplit[i] + ".";
        }

        // process the parts/positions that will inherit from the sg above
        const positionsCopy = deepCopy(positionsWillInheritFromSecurityGroupAbove2);

        // // console.log("positionsCopy : ", positionsCopy);

        let positions = this.buildObjectForAllSecurityGroupsForPositions(positionsCopy, partToStartFromPathSplitString, this.org, this.org.key, this.tree || "tree5");
        // let positions = positionsCopy;

        this.extractSecurityGroupsForInheritedPositions(positions);
        const userObjectArray: any = this.extractUserDetailsForPositions(positions);
        const userObjectArrayCopy = deepCopy(userObjectArray);

        // *****************************************************
        // ***** add the user details to the parts         *****
        // *****************************************************
        positions = this.appendUserDetailsToParts(userObjectArrayCopy, positions, this.orgUsers); //?

        // const filename = "positions";
        // fs.writeFileSync(path.join(__dirname, `${filename}.json`), JSON.stringify(positions));
        this.positions = positions;

        return this;
    }

    buildTree(tree: string) {

        return this;
    }

    buildTreeObjectForAllSecurityGroupsForAllPositions(tree: string) {
        for (let i = 0; i < this.positions.length; i++) {
            const securityGroupObjectArray2: any = [];
            for (const sg of this.positions[i].securityGroupsArray) {
                const orgSecurityGroupsSubColFeatureObject = [];

                for (const featureKey in sg.featureDetails) {
                    const feature = sg.featureDetails[featureKey];

                    const featureObject: any = {
                        selected: true,
                        color: this.fetchColor('feature'),
                        background: this.fetchBackground('feature'),
                        data: {
                            depth: 2,
                            key: featureKey,
                            expandedKey: tree + "-" + this.org.key + "-" + "-" + sg.key + "-" + featureKey + "-orgSecurityGroupsSubCol",
                            name: featureKey,
                            type: 'feature'
                        },
                        children: [
                            {
                                selected: true,
                                color: this.fetchColor('featureDetails'),
                                background: this.fetchBackground('featureDetails'),
                                data: {
                                    depth: 2,
                                    key: featureKey,
                                    expandedKey: tree + "-" + this.org.key + "-" + "-" + sg.key + "-" + featureKey + "-featureDetails" + "-orgSecurityGroupsSubCol",
                                    name: feature.permissions,
                                    type: 'featureDetails',
                                },
                                children: [],
                            },
                        ],
                    };
                    orgSecurityGroupsSubColFeatureObject.push(featureObject);
                }

                if (orgSecurityGroupsSubColFeatureObject.length > 0) {
                    const securityGroupObject: any = {
                        selected: true,
                        color: this.fetchColor('securityGroup'),
                        background: this.fetchBackground('securityGroup'),
                        data: {
                            depth: 2,
                            key: sg.key,
                            expandedKey: tree + "-" + this.org.key + "-" + "-" + sg.key,
                            name: sg.name,
                            multiUsers: sg.multiUsers,
                            type: 'securityGroup'
                        },
                        children: orgSecurityGroupsSubColFeatureObject,
                    };

                    securityGroupObjectArray2.push(securityGroupObject);
                }

            }

            if (securityGroupObjectArray2.length > 0) {
                const securityGroupsOnPositionObject: any = {
                    selected: true,
                    color: this.fetchColor('securityGroupsOnPositionObject'),
                    background: this.fetchBackground('securityGroupsOnPositionObject'),
                    data: {
                        depth: 2,
                        key: this.positions[i].name,
                        expandedKey: tree + "-" + this.org.key + "-securityGroupOnPositionObject",
                        name: 'Org Security Groups on Position: ** ' + this.positions[i].name + " **",
                        type: 'securityGroupsOnPositionObject',
                    },
                    children: securityGroupObjectArray2,
                };


                const flattenSecurityGroupsObject = flatten(securityGroupsOnPositionObject);

                this.positions[i].securityGroupsFlat = flattenSecurityGroupsObject;

                this.positions[i].securityGroupsObject = securityGroupsOnPositionObject;
            } else {
                this.positions[i].securityGroupsFlat = {};

                this.positions[i].securityGroupsObject = {};
            }
        } return this;
    }

    getUserDetailsForPosition(uid: any, position: any) {

        const positionFound = this.positions.find((u: any) => u.key === position);
        if (positionFound) {
            const userFound = positionFound.userDetailsArray.find((u: any) => u.key === uid);
            if (userFound) {
                const positionsObjects = userFound.positionsObjects[position];
                return positionsObjects;
            }
        }

        return {};
    }

    getUserPrimarySecurityGroupsForPosition(uid: any, position: any) {

        const positionFound = this.positions.find((u: any) => u.key === position);
        if (positionFound) {
            const userFound = positionFound.userDetailsArray.find((u: any) => u.key === uid);
            if (userFound) {
                const positionsObjects = userFound.positionsObjects[position];
                const primarySecurityGroups = positionsObjects.primaryPositionSecurityGroups;
                return primarySecurityGroups;
            }
        }
        return [];
    }

    getUserSecondarySecurityGroupForPosition(uid: any, position: any) {

        const positionFound = this.positions.find((u: any) => u.key === position);
        if (positionFound) {
            const userFound = positionFound.userDetailsArray.find((u: any) => u.key === uid);
            if (userFound) {
                const positionsObjects = userFound.positionsObjects[position];
                const primarySecurityGroups = positionsObjects.secondaryPositionsSecurityGroups;
                return primarySecurityGroups;
            }
        }
        return [];
    }

    getUserPositions(uid: any) {

        const userPositions: any = [];
        const positions = this.positions;
        for (const position of positions) {
            const userFound = position.userDetailsArray ? position.userDetailsArray.find((u: any) => u.key === uid) : undefined;
            if (userFound) {
                userPositions.push(userFound.positionsObjects);
            }
        }

        return userPositions;
    }

    getUserFeatureDetailsForPosition(uid: any, departmentKey: any) {

        const userPositions: any = [];
        const positions = this.positions;
        for (const position of positions) {
            const userFound = position.userDetailsArray ? position.userDetailsArray.find((u: any) => u.key === uid) : undefined;
            if (userFound) {
                userPositions.push(userFound.positionsObjects);
            }
        }

        return userPositions[0][departmentKey] !== undefined ? userPositions[0][departmentKey].featureDetails : {};
    }

    getUserFeatureDetailsForAllPositions(uid: any) {

        const userPositions: any = [];
        const positions = this.positions;
        for (const position of positions) {
            const userFound = position.userDetailsArray ? position.userDetailsArray.find((u: any) => u.key === uid) : undefined;
            if (userFound) {
                userPositions.push(userFound.positionsObjects);
            }
        }

        // console.log("userPositions : ", userPositions);

        const featuresAndPermissionMerged: any = {};
        for (const positionKey in userPositions[0]) {
            // console.log("positionKey : ", positionKey);
            const position = userPositions[0][positionKey]; //?
            // // console.log("position : ", position); //?
            const positionsFeatureDetails = position.featureDetails;
            // console.log("positionsFeatureDetails : ", positionsFeatureDetails);
            for (const featureKey in positionsFeatureDetails) {
                if (featuresAndPermissionMerged[featureKey] === undefined) {
                    // console.log(positionsFeatureDetails[featureKey].permissions);
                    featuresAndPermissionMerged[featureKey] = { "permissions": [...positionsFeatureDetails[featureKey].permissions] };
                } else {
                    featuresAndPermissionMerged[featureKey].permissions = [...new Set([...featuresAndPermissionMerged[featureKey].permissions, ...positionsFeatureDetails[featureKey].permissions])];
                }
            }
            // const mergedFeatureDetailsOfPrimeAndInherited = {
            //     permissions: [...new Set([...inheritedFeatureDetail.permissions])],
            //     name: inheritedFeatureDetail.name,
            //     key: inheritedFeatureDetail.key
            // };
        }

        return featuresAndPermissionMerged; // userPositions[0][departmentKey].featureDetails;
    }

    override processChanges() {
        // do everything in intent list - all updates to org doc and sub-collection docs
        return this.orgTree;
    }

    override log() {
        // // console.log(' >>> ', ' tree: ', this.tree);
        // // console.log(' >>> ', ' orgTree: ', this.orgTree);

        return this;
    }

    fetchColor(type: string) {
        const color = this.partsSwatch[type].color;
        return color;
    }

    fetchBackground(type: string) {
        const background = this.partsSwatch[type].background;
        return background;
    }

    setSecurityGroupsTreeObject(childObjectArray: any, org: any, sg: any, tree: string) {

        if (this.securityGroupObject === null || this.securityGroupObject === undefined) {

            this.securityGroupObject = {
                selected: true,
                color: this.fetchColor('securityGroup'),
                background: this.fetchBackground('securityGroup'),
                data: {
                    depth: 1,
                    key: sg.key,
                    expandedKey: tree + "-" + org.key + "-" + sg.key,
                    name: sg.name,
                    multiUsers: sg.multiUsers,
                    type: 'securityGroup'
                },
                children: [],
            };

        }

        this.securityGroupObject.children.push(childObjectArray);

        return this.securityGroupObject;
    }

    buildTreeObjectForAllFeaturesAndPermissionsOfSecurityGroup(org: any, sg: any, tree: string) {
        const orgKey = org.key;
        const securityGroupObjectArray: any = [];
        // // console.log('org >>>>>: ', org);
        // // console.log('sg >>>>>: ', sg);
        for (const SGKey of org.orgSecurityGroups.securityGroupsKeys) {
            const securityGroupFeatureObjectArray: any = [];
            const foundSg = org.orgSecurityGroups[SGKey].key === sg.key ? org.orgSecurityGroups[SGKey] : undefined;

            // // console.log('foundSg >>>>>: ', foundSg);
            if (foundSg) {

                for (const featureKey of foundSg.features) {
                    const featureDetail = foundSg.featureDetails[featureKey];
                    const featureObject: any = {
                        selected: true,
                        color: this.fetchColor('feature'),
                        background: this.fetchBackground('feature'),
                        data: {
                            depth: 2,
                            key: featureKey,
                            expandedKey: tree + "-" + orgKey + "-" + foundSg.key + "-" + SGKey + "-" + featureKey,
                            name: featureKey,
                            type: 'feature'
                        },
                        children: [
                            {
                                selected: true,
                                color: this.fetchColor('featureDetails'),
                                background: this.fetchBackground('featureDetails'),
                                data: {
                                    depth: 2,
                                    key: 'featureDetails-' + featureKey,
                                    expandedKey: tree + "-" + orgKey + "-" + foundSg.key + "-" + 'featureDetails-' + SGKey + "-" + featureKey,
                                    name: featureDetail.permissions,
                                    type: 'featureDetails',
                                },
                            },
                        ],
                    };
                    securityGroupFeatureObjectArray.push(featureObject);
                }
                const sgObject: any = {
                    selected: true,
                    color: this.fetchColor('securityGroup'),
                    background: this.fetchBackground('securityGroup'),
                    data: {
                        depth: 2,
                        key: sg.key,
                        expandedKey: tree + "-" + orgKey + "-" + foundSg.key + "-orgSecurityGroups-" + sg.key,
                        name: sg.name,
                        multiUsers: sg.multiUsers,
                        type: 'securityGroup'
                    },
                    children: securityGroupFeatureObjectArray,
                };
                securityGroupObjectArray.push(sgObject);
            }
        }

        // All the sgs for the selected position derived from org doc - orgSecurityGroups object
        const securityGroupsForOrgSecurityGroupsObject: any = {
            selected: true,
            color: this.fetchColor('securityGroup'),
            background: this.fetchBackground('securityGroup'),
            data: {
                depth: 2,
                key: sg.key + "-" + org.key + "-orgSecurityGroups",
                expandedKey: tree + "-" + orgKey + "-" + sg.key + "-orgSecurityGroups",
                name: 'orgSecurityGroups for ' + sg.key,
                type: 'securityGroups'
            },
            children: securityGroupObjectArray,
        };

        return securityGroupsForOrgSecurityGroupsObject;
    }

    buildTreeObjectForAllFeaturesAndPermissionsOfSecurityGroup2(orgSecurityGroupsSubColObject: any, org: any, securityGroup: any, tree: string) {
        const securityGroupObjectArray2: any = [];
        for (const sg of orgSecurityGroupsSubColObject) {
            const orgSecurityGroupsSubColFeatureObject = [];

            for (const featureKey in sg.featureDetails) {
                const feature = sg.featureDetails[featureKey];

                const featureObject: any = {
                    selected: true,
                    color: this.fetchColor('feature'),
                    background: this.fetchBackground('feature'),
                    data: {
                        depth: 2,
                        key: featureKey,
                        expandedKey: tree + "-" + org.key + "-" + "-" + sg.key + "-" + featureKey + "-orgSecurityGroupsSubCol",
                        name: featureKey,
                        type: 'feature'
                    },
                    children: [
                        {
                            selected: true,
                            color: this.fetchColor('featureDetails'),
                            background: this.fetchBackground('featureDetails'),
                            data: {
                                depth: 2,
                                key: featureKey,
                                expandedKey: tree + "-" + org.key + "-" + "-" + sg.key + "-" + featureKey + "-featureDetails" + "-orgSecurityGroupsSubCol",
                                name: feature.permissions,
                                type: 'featureDetails',
                            },
                            children: [],
                        },
                    ],
                };
                orgSecurityGroupsSubColFeatureObject.push(featureObject);
            }

            const sgObject: any = {
                selected: true,
                color: this.fetchColor('securityGroup'),
                background: this.fetchBackground('securityGroup'),
                data: {
                    depth: 2,
                    key: sg.key,
                    expandedKey: tree + "-" + org.key + "-" + "-" + sg.key,
                    name: sg.name,
                    multiUsers: sg.multiUsers,
                    type: 'securityGroup'
                },
                children: orgSecurityGroupsSubColFeatureObject,
            };
            securityGroupObjectArray2.push(sgObject);
        }

        // All the sgs for the selected position derived from org sgs sub-collection
        const orgSecurityGroupsSubCollectionsObject: any = {
            selected: true,
            color: this.fetchColor('orgSecurityGroupsSubCollection'),
            background: this.fetchBackground('orgSecurityGroupsSubCollection'),
            data: {
                depth: 2,
                key: securityGroup.key,
                expandedKey: tree + "-" + org.key + "-" + securityGroup.key + "-orgSecurityGroupsSubCol",
                name: 'Org Security Groups Sub-Collections ' + securityGroup.key,
                type: 'orgSecurityGroupsSubCollection',
            },
            children: securityGroupObjectArray2,
        };

        return orgSecurityGroupsSubCollectionsObject;
    }

    extractAllOrgUsersForPosition(userDetails: any, org: any, sg: any, tree: string) {
        const userKeysOnSecurityGroup: any = [];
        if (sg.multiUsers && sg.multiUsersArray > 0) {
            userKeysOnSecurityGroup.push(...sg.multiUsersArray);
        } else {
            userKeysOnSecurityGroup.push(sg.assignedTo);
        }

        const userDetailsArray: any = [];
        for (const userKey of userKeysOnSecurityGroup) {
            // get user details and create user object
            const user = userDetails.users.find((o: any) => {
                if (o.uid === userKey) {
                    return o;
                }
            });

            const userDetailsObject: any = {
                selected: true,
                color: this.fetchColor('user'),
                background: this.fetchBackground('user'),
                data: {
                    depth: 1,
                    key: userKey,
                    expandedKey: tree + "-" + org.key + "-" + sg.key + "-" + userKey,
                    name: user?.name || userKey,
                    type: 'user'
                },
                children: [],
            };

            userDetailsArray.push(userDetailsObject);
        }

        const usersOnSecurityGroup: any = {
            selected: true,
            color: this.fetchColor('user'),
            background: this.fetchBackground('user'),
            data: {
                depth: 1,
                key: "userOnSecurityGroup: " + sg.key,
                expandedKey: tree + "-" + org.key + "-" + sg.key + "userOnSecurityGroup",
                name: "Users on security group: " + sg.key,
                type: 'usersOnSecurityGroup'
            },
            children: userDetailsArray,
        };

        return usersOnSecurityGroup;
    }

    extractUsersWithSamePositionOnOtherSecurityGroups(userDetails: any, org: any, sg: any, tree: string) {

        // Find other sgs in sgs sub collections with same position as selected sg
        const securityGroupsWithSamePositionArray: any = this.getSecurityGroupsWithSamePosition(org, sg);

        const securityGroupsWithSamePositionObjectArray: any = [];
        for (const securityGroupWithPosition of securityGroupsWithSamePositionArray) {
            // get user keys from the securityGroupWithPosition
            const userKeysOnSecurityGroup: any = [];
            if (securityGroupWithPosition.multiUsers && securityGroupWithPosition.multiUsersArray > 0) {
                userKeysOnSecurityGroup.push(...securityGroupWithPosition.multiUsersArray);
            } else {
                userKeysOnSecurityGroup.push(securityGroupWithPosition.assignedTo);
            }

            // create user objects
            const userDetailsArray: any = [];
            for (const userKey of userKeysOnSecurityGroup) {
                // get user details and create user object
                const user = userDetails.users.find((o: any) => {
                    if (o.uid === userKey) {
                        return o;
                    }
                });
                // // console.log("user *********************: ", user);

                const userDetailsObject: any = {
                    selected: true,
                    color: this.fetchColor('user'),
                    background: this.fetchBackground('user'),
                    data: {
                        depth: 1,
                        key: userKey,
                        expandedKey: tree + "-" + org.key + "-" + securityGroupWithPosition.key + "-" + userKey,
                        name: user?.name || userKey,
                        type: 'user'
                    },
                    children: [],
                };

                userDetailsArray.push(userDetailsObject);
            }

            const sgObject: any = {
                selected: true,
                color: this.fetchColor('securityGroup'),
                background: this.fetchBackground('securityGroup'),
                data: {
                    depth: 1,
                    key: securityGroupWithPosition.key,
                    expandedKey: tree + "-" + org.key + "-" + securityGroupWithPosition.key,
                    name: securityGroupWithPosition.name,
                    type: 'securityGroup'
                },
                children: userDetailsArray,
            };
            securityGroupsWithSamePositionObjectArray.push(sgObject);
        }

        const userOnOtherSecurityGroupsWithSamePosition: any = {
            selected: true,
            color: this.fetchColor('userOnOtherSecurityGroupsWithSamePosition'),
            background: this.fetchBackground('userOnOtherSecurityGroupsWithSamePosition'),
            data: {
                depth: 1,
                key: "userOnOtherSecurityGroupsWithSamePosition: " + sg.key,
                expandedKey: tree + "-" + org.key + "-" + sg.key + "userOnOtherSecurityGroupsWithSamePosition",
                name: "Users on Other Security Groups with same Position: " + sg.key,
                type: 'userOnOtherSecurityGroupsWithSamePosition'
            },
            children: securityGroupsWithSamePositionObjectArray,
        };

        return userOnOtherSecurityGroupsWithSamePosition;
    }

    buildObjectForAllSecurityGroupsForPositions(positionsCopy: any, partToStartFromPathSplitString: string, org: any, orgKey: any, tree: string) {
        for (let i = 0; i < positionsCopy.length; i++) {
            const path = positionsCopy[i].path;
            const name = positionsCopy[i].name;

            let pathSplitString = "";

            pathSplitString = path.substring(partToStartFromPathSplitString.length, path.length); //?


            const partData = {
                background: "#007bff",
                color: "white",
                selected: true,
            };
            positionsCopy[i].levelUp = partData;
            positionsCopy[i].path = pathSplitString;
            positionsCopy[i].type = 'positionsPartsRelationship';
            positionsCopy[i].expandedKey = 'tree5-' + 'positionsPartsRelationship-' + pathSplitString;

            // find and attach all sgs to the position
            const securityGroupsForPosition: any = [];
            for (const SGKey of org.orgSecurityGroups.securityGroupsKeys) {
                const sgSearch = org.orgSecurityGroups[SGKey];
                if (sgSearch.orgPosition.partKey === positionsCopy[i].partKey) {
                    securityGroupsForPosition.push(sgSearch);
                }
            }

            positionsCopy[i].securityGroupArray = securityGroupsForPosition
        }

        return positionsCopy;
    }

    buildSecurityGroupsRelationshipsObject(positionsCopy: any, partToStartFromPathSplitString: string, org: any, tree: string) {
        for (let i = 0; i < positionsCopy.length; i++) {
            const path = positionsCopy[i].path;
            const name = positionsCopy[i].name;

            let pathSplitString = "";

            pathSplitString = path.substring(partToStartFromPathSplitString.length, path.length);

            const partData = {
                background: "#007bff",
                color: "white",
                selected: true,
            };

            const inheritedSecurityGroupsForPosition = positionsCopy[i].inheritanceObjects.secondaryPositionSecurityGroups;
            positionsCopy[i].inheritedSecurityGroupsForPosition = inheritedSecurityGroupsForPosition;

            const securityGroupObjectArray2: any = [];
            for (const sg of inheritedSecurityGroupsForPosition) {
                const orgSecurityGroupsSubColFeatureObject = [];

                for (const featureKey in sg.featureDetails) {

                    const feature = sg.featureDetails[featureKey];

                    const featureObject: any = {
                        selected: true,
                        color: 'white',
                        background: 'blue',
                        data: {
                            depth: 2,
                            key: featureKey,
                            expandedKey: tree + "-" + org.key + "-" + "-" + sg.key + "-" + featureKey + "-orgSecurityGroupsSubCol",
                            name: featureKey,
                            type: 'feature'
                        },
                        children: [
                            {
                                selected: true,
                                color: 'white',
                                background: 'blue',
                                data: {
                                    depth: 2,
                                    key: featureKey,
                                    expandedKey: tree + "-" + org.key + "-" + "-" + sg.key + "-" + featureKey + "-featureDetails" + "-orgSecurityGroupsSubCol",
                                    name: feature.permissions,
                                    type: 'featureDetails',
                                },
                                children: [],
                            },
                        ],
                    };
                    orgSecurityGroupsSubColFeatureObject.push(featureObject);
                }


                if (orgSecurityGroupsSubColFeatureObject.length > 0) {
                    const sgObject: any = {
                        selected: true,
                        color: 'white',
                        background: 'green',
                        data: {
                            depth: 2,
                            key: sg.key,
                            expandedKey: tree + "-" + org.key + "-" + "-" + sg.key,
                            name: sg.name,
                            multiUsers: sg.multiUsers,
                            type: 'securityGroup'
                        },
                        children: orgSecurityGroupsSubColFeatureObject,
                    };

                    securityGroupObjectArray2.push(sgObject);
                }

            }

            if (securityGroupObjectArray2.length > 0) {
                const securityGroupsOnPositionObject: any = {
                    selected: true,
                    color: 'black',
                    background: '#e402e4',
                    data: {
                        depth: 2,
                        key: positionsCopy[i].name,
                        expandedKey: tree + "-" + org.key + "-inheritedSecurityGroupsOnPositionObject",
                        name: 'Org Inherited Security Groups on Position: ** ' + positionsCopy[i].name + " **",
                        type: 'inheritedSecurityGroupsOnPositionObject',
                    },
                    children: securityGroupObjectArray2,
                };

                // // console.log("securityGroupsOnPositionObject : ", securityGroupsOnPositionObject);

                const flattenInheritedSecurityGroupsObject = flatten(securityGroupsOnPositionObject);

                // // console.log("flattenInheritedSecurityGroupsObject : ", flattenInheritedSecurityGroupsObject);
                positionsCopy[i].inheritedSecurityGroupsFlat = flattenInheritedSecurityGroupsObject;

                positionsCopy[i].inheritedSecurityGroupsObject = securityGroupsOnPositionObject;
            } else {
                positionsCopy[i].inheritedSecurityGroupsFlat = {};

                positionsCopy[i].inheritedSecurityGroupsObject = {};
            }
        }
    }

    getSecurityGroupsWithSamePosition(org: any, sg: any) {
        const securityGroupsWithSamePositionArray: any = [];
        for (const SGKey of org.orgSecurityGroups.securityGroupsKeys) {
            const sgSearch = org.orgSecurityGroups[SGKey];
            if (sg.orgPosition.key === sgSearch.orgPosition.key && sg.key !== sgSearch.key) {
                securityGroupsWithSamePositionArray.push(sgSearch);
            }
        }
        return securityGroupsWithSamePositionArray;
    }

    appendAllChildPositionsToOrgParts(orgParts: any) {
        let process = true;
        // const firstPart = sg.orgPosition;
        const firstPart = orgParts[0];
        let children = orgParts.filter((o: any) => o.depth === firstPart?.depth + 1 && o.parentPartKey === firstPart?.partKey);

        const positionsWillInheritFromSecurityGroupAbove2: any = [];
        positionsWillInheritFromSecurityGroupAbove2.push(firstPart);
        positionsWillInheritFromSecurityGroupAbove2.push(...children);

        let children2: any = [];
        let childsChildren: any = [];
        while (process) {

            children2 = [];
            for (const child of children) {

                childsChildren = orgParts.filter((o: any) => o.depth === child?.depth + 1 && o.parentPartKey === child?.partKey);
                children2.push(...childsChildren);
                positionsWillInheritFromSecurityGroupAbove2.push(child);

            }

            if (children2.length > 0) {
                // more children to process
                children = children2;
            } else {
                process = false;
            }

        }

        // remove duplicates on positionsWillInheritFromSecurityGroupAbove2
        const positionsWillInheritFromSecurityGroupAboveNoDuplicates2: any = [];
        for (const position of positionsWillInheritFromSecurityGroupAbove2) {
            if (!positionsWillInheritFromSecurityGroupAboveNoDuplicates2.find((o: any) => o.partKey === position.partKey)) {
                positionsWillInheritFromSecurityGroupAboveNoDuplicates2.push(position);
            }
        }

        return positionsWillInheritFromSecurityGroupAboveNoDuplicates2;
    }

    positionsWillInheritFromSecurityGroupBelow(org: any, sg: any) {

        let process = true
        const orgParts = org.orgParts;
        let securityGroupPosition = sg.orgPosition;
        const positionsWillInheritFromSecurityGroupBelow: any = [];
        while (process) {
            const position = orgParts.find((o: any) => o.partKey === securityGroupPosition?.parentPartKey);
            if (position) {
                positionsWillInheritFromSecurityGroupBelow.push(position);
                securityGroupPosition = position;
            } else {
                process = false;
            }

        }

        return positionsWillInheritFromSecurityGroupBelow;
    }

    extractSecurityGroupsForInheritedPositions(positionsCopy: any) {
        const inheritanceObject: any = {};
        const inheritanceObjects: any = {};
        // const childrenSecurityGroups: any = [];
        for (const part of positionsCopy) {

            // place all the securityGroups of the primary part into object
            inheritanceObject.primaryPositionSecurityGroups = part.securityGroupArray;

            // work through every child of part down
            const { positionsWillInheritFromSecurityGroupAbove, childrenSecurityGroups } = this.processAllChildrenAndGetSecurityGroups(part, positionsCopy);

            // place all the sgs of the secondary parts into object
            inheritanceObject.secondaryPositionSecurityGroups = childrenSecurityGroups;


            part.inheritanceObjects = deepCopy(inheritanceObject);
            inheritanceObjects[part.name] = deepCopy(inheritanceObject);
        }
    }

    processAllChildrenAndGetSecurityGroups(orgPosition: any, orgParts: any) {
        let process = true;
        const firstPart = orgPosition;
        let children = orgParts.filter((o: any) => o.depth === firstPart?.depth + 1 && o.parentPartKey === firstPart?.partKey);

        const positionsWillInheritFromSecurityGroupAbove = [];
        positionsWillInheritFromSecurityGroupAbove.push(firstPart);
        positionsWillInheritFromSecurityGroupAbove.push(...children);

        let children2 = [];
        let childsChildren = [];
        const childrenSecurityGroups = [];
        while (process) {

            // // console.log("children 2 : ", children);
            children2 = [];
            for (const child of children) {

                childsChildren = orgParts.filter((o: any) => o.depth === child?.depth + 1 && o.parentPartKey === child?.partKey);
                children2.push(...childsChildren);

                // process the child and get all the sgs of its children
                childrenSecurityGroups.push(...child.securityGroupArray);
                positionsWillInheritFromSecurityGroupAbove.push(child);

            }

            if (children2.length > 0) {
                // more children to process
                children = children2;
            } else {
                process = false;
            }

        }
        return { positionsWillInheritFromSecurityGroupAbove, childrenSecurityGroups };
    }

    extractUserDetailsForPositions(positions: any) {
        const userObjectArray: UserDetails[] = []; // build an array of users for this position

        // process for every position - getting the primary securityGroups and inherited securityGroups and group together by user   
        for (let i = 0; i < positions.length; i++) {

            const positionCopy = deepCopy(positions[i]);

            const primaryPositionSecurityGroupsByUser = [];
            const primaryPositionSecurityGroups = positionCopy.inheritanceObjects.primaryPositionSecurityGroups;
            // work through the sgs of the position and group by user
            for (const sg of primaryPositionSecurityGroups) {
                // group the sgs by user
                let allPrimarySecurityGroupsForUser = [];
                if (sg.assignedTo !== "") {
                    // add sg to array
                    allPrimarySecurityGroupsForUser = primaryPositionSecurityGroups.filter((o: any) => o.assignedTo === sg.assignedTo);
                }
                if (primaryPositionSecurityGroupsByUser.find((o: any) => o.assignedTo === sg.assignedTo && sg.assignedTo !== "") === undefined) {
                    primaryPositionSecurityGroupsByUser.push({ [sg.assignedTo]: allPrimarySecurityGroupsForUser });
                }
            }

            const secondaryPositionsSecurityGroups = positionCopy.inheritanceObjects.secondaryPositionSecurityGroups;
            for (const user of primaryPositionSecurityGroupsByUser) {
                const userKey: any = Object.keys(user)[0]; //?
                if (userKey === '') continue;
                const securityGroups = primaryPositionSecurityGroupsByUser.find((o: any) => Object.keys(o).find((oo: any) => oo === userKey))?.[userKey] || [];
                const featureDetails = this.buildFeatureDetails(securityGroups, secondaryPositionsSecurityGroups, positionCopy);
                const orgUser = this.org.orgUsers[userKey];

                userObjectArray.push({
                    key: userKey,
                    name: orgUser?.username || orgUser.name || '',
                    positions: [positionCopy],
                    primaryPositionSecurityGroupsForUser: [...securityGroups],
                    secondaryPositionsSecurityGroups: [...secondaryPositionsSecurityGroups],
                    positionsObjects: {
                        [positionCopy.key]: {
                            position: [positionCopy],
                            primaryPositionSecurityGroups: [...securityGroups],
                            secondaryPositionsSecurityGroups: [...secondaryPositionsSecurityGroups],
                            featureDetails: featureDetails,
                        }
                    },
                });
            }
        }
        return userObjectArray;
    }

    buildFeatureDetails(primeSecurityGroups: any, inheritedSecurityGroups: any, forPosition: any) {
        // console.log("buildFeatureDetails > primeSecurityGroups : ", primeSecurityGroups);
        // console.log("buildFeatureDetails > inheritedSecurityGroups : ", inheritedSecurityGroups);
        // console.log('----')
        // merge all featureDetails of primeSecurityGroups into one sg object
        const mergedPrimeSecurityGroupsFeatureDetails = primeSecurityGroups.reduce((mergedSecurityGroup: any, sg: any) => {
            // console.log("mergedPrimeSecurityGroupsFeatureDetails > mergedSecurityGroup : ", mergedSecurityGroup);
            // console.log("mergedPrimeSecurityGroupsFeatureDetails > sg : ", sg);

            const securityGroupFeatureDetails = sg.featureDetails;
            const securityGroupFeatureDetailsKeys = Object.keys(securityGroupFeatureDetails);
            if (mergedSecurityGroup.securityGroups === undefined) {
                mergedSecurityGroup.securityGroups = [];
            }
            mergedSecurityGroup.securityGroups = [...mergedSecurityGroup.securityGroups, sg];
            securityGroupFeatureDetailsKeys.forEach((key) => {
                const featureDetail = securityGroupFeatureDetails[key];
                const accPermissions = mergedSecurityGroup.featureDetails[key]?.permissions || [];
                featureDetail.permissions = [...new Set([...accPermissions, ...featureDetail.permissions])];
                mergedSecurityGroup.featureDetails[key] = featureDetail;
            });
            return mergedSecurityGroup;
        }, { featureDetails: {}, securityGroups: [] });



        // merge all featureDetails of inheritedSecurityGroups into one sg object
        const mergedInheritedSecurityGroupsFeatureDetails = inheritedSecurityGroups.reduce((mergedSecurityGroup: any, sg: any) => {
            const securityGroupFeatureDetails = sg.featureDetails;
            const securityGroupFeatureDetailsKeys = Object.keys(securityGroupFeatureDetails);
            if (mergedSecurityGroup.securityGroups === undefined) {
                mergedSecurityGroup.securityGroups = [];
            }
            mergedSecurityGroup.securityGroups = [...mergedSecurityGroup.securityGroups, sg];
            securityGroupFeatureDetailsKeys.forEach((key) => {
                const featureDetail = securityGroupFeatureDetails[key];
                const accPermissions = mergedSecurityGroup.featureDetails[key]?.permissions || [];
                featureDetail.permissions = [...new Set([...accPermissions, ...featureDetail.permissions])];
                mergedSecurityGroup.featureDetails[key] = featureDetail;
            });
            return mergedSecurityGroup;
        }, { featureDetails: {}, securityGroups: [] });

        // find all matching features where the permissions are different
        const mergedFeatureDetailsKeys = Object.keys(mergedPrimeSecurityGroupsFeatureDetails.featureDetails);
        const mergedFeatureDetails = mergedFeatureDetailsKeys.reduce((mergedFeatureDetails: any, key: any) => {
            let message = '';
            const primeFeatureDetail = mergedPrimeSecurityGroupsFeatureDetails.featureDetails[key];
            const inheritedFeatureDetail = mergedInheritedSecurityGroupsFeatureDetails.featureDetails[key] || { permissions: [] };

            // // console.log("primeFeatureDetail : ", primeFeatureDetail);
            // // console.log("inheritedFeatureDetail : ", inheritedFeatureDetail);
            if (JSON.stringify(primeFeatureDetail.permissions.sort()) !== JSON.stringify(inheritedFeatureDetail.permissions.sort())) {
                const mergedFeatureDetailsOfPrimeAndInherited = {
                    // ...primeFeatureDetail,
                    // ...inheritedFeatureDetail,
                    permissions: [...new Set([...primeFeatureDetail.permissions, ...inheritedFeatureDetail.permissions])],
                    name: primeFeatureDetail.name,
                    key: primeFeatureDetail.key
                };
                // find the difference between the two permissions
                const differencePermissions1 = primeFeatureDetail.permissions.filter((permission: any) => !inheritedFeatureDetail.permissions.includes(permission));
                // // console.log("differencePermissions1: ", differencePermissions1);
                const differencePermissions2 = inheritedFeatureDetail.permissions.filter((permission: any) => !primeFeatureDetail.permissions.includes(permission));
                // // console.log("differencePermissions2: ", differencePermissions2);
                const differencePermissions = [...differencePermissions1, ...differencePermissions2];
                // // console.log("differencePermissions: ", differencePermissions);
                if (differencePermissions2.length > 0) {
                    message = `The following permissions are not on the prime securityGroup: ${differencePermissions2.join(", ")}`;
                }

                const securityGroupsWithFeatureForPermission: any = {};
                for (const permission of differencePermissions2) {

                    for (const sg of inheritedSecurityGroups) {
                        
                        for (const key in sg.featureDetails) {
                            const feature = sg.featureDetails[key];
                            const permissions = feature.permissions;
                            if (feature.name === key && permissions.includes(permission)) {
                                
                                if (securityGroupsWithFeatureForPermission[key] === undefined) {
                                    securityGroupsWithFeatureForPermission[key] = { [permission]: [sg.key] };
                                } else {
                                    if (securityGroupsWithFeatureForPermission[key][permission] === undefined) {
                                        securityGroupsWithFeatureForPermission[key][permission] = [sg.key];
                                    } else {
                                        securityGroupsWithFeatureForPermission[key][permission].push(sg.key);
                                    }
                                }
                            }
                        }
                    }
                }


                // merge mergedPrimeSecurityGroupsFeatureDetails and 
                mergedFeatureDetails[key] = {
                    primeFeatureDetail,
                    inheritedFeatureDetail,
                    mergedPermissions: mergedFeatureDetailsOfPrimeAndInherited,
                    differencePermissions: differencePermissions2,
                    message,
                    inheritanceDetails: securityGroupsWithFeatureForPermission
                };
            } else {
                mergedFeatureDetails[key] = {
                    primeFeatureDetail,
                    inheritedFeatureDetail,
                    mergedPermissions: primeFeatureDetail,
                    differencePermissions: [],
                    message,
                    inheritanceDetails: {}
                };
            }
            return mergedFeatureDetails;
        }, {});

        // find where the feature is not on the prime but on inherited
        const mergedFeatureDetailsKeys2 = Object.keys(mergedInheritedSecurityGroupsFeatureDetails.featureDetails);
        const mergedFeatureDetails2: any = mergedFeatureDetailsKeys2.reduce((mergedFeatureDetails2: any, key: any) => {

            let message = '';
            const primeFeatureDetail = mergedPrimeSecurityGroupsFeatureDetails.featureDetails[key];
            const inheritedFeatureDetail = mergedInheritedSecurityGroupsFeatureDetails.featureDetails[key];
            if (!primeFeatureDetail) {
                const mergedFeatureDetailsOfPrimeAndInherited = {
                    permissions: [...new Set([...inheritedFeatureDetail.permissions])],
                    name: inheritedFeatureDetail.name,
                    key: inheritedFeatureDetail.key
                };

                // // console.log("inheritedFeatureDetail : ", inheritedFeatureDetail);
                const securityGroupsWithFeatureNoPermissions: any = {};
                // for(const feature of inheritedFeatureDetail) {
                for (const sg of inheritedSecurityGroups) {
                    for (const key in sg.featureDetails) {
                        const feature = sg.featureDetails[key];
                        const permissions = feature.permissions;
                        if (inheritedFeatureDetail.permissions.length > 0) {
                            // // console.log("inheritedFeatureDetail.permissions : ", inheritedFeatureDetail.permissions);

                            for (const permission of inheritedFeatureDetail.permissions) {
                                if (feature.name === key) {
                                    if (securityGroupsWithFeatureNoPermissions[key] === undefined) {
                                        securityGroupsWithFeatureNoPermissions[key] = { [permission]: [sg.key] };
                                    } else {
                                        if (securityGroupsWithFeatureNoPermissions[key][permission] === undefined) {
                                            securityGroupsWithFeatureNoPermissions[key][permission] = [sg.key];
                                        } else {
                                            securityGroupsWithFeatureNoPermissions[key][permission].push(sg.key);
                                        }
                                    }
                                }
                            }
                        } else {
                            // // console.log("inheritedFeatureDetail.permissions : ", inheritedFeatureDetail.permissions);
                            if (feature.name === key) {
                                // // console.log("feature : ", feature.name, " sg name: ", sg.name, " sg key: ", sg.key, " permissions: ", permissions);
                                if (securityGroupsWithFeatureNoPermissions[key] === undefined) {
                                    securityGroupsWithFeatureNoPermissions[key] = [sg.key];
                                } else {
                                    if (securityGroupsWithFeatureNoPermissions[key] === undefined) {
                                        securityGroupsWithFeatureNoPermissions[key] = [sg.key];
                                    } else {
                                        securityGroupsWithFeatureNoPermissions[key].push(sg.key);
                                    }
                                }
                            }
                        }
                    }
                }


                // // console.log("mergedFeatureDetailsOfPrimeAndInherited ", mergedFeatureDetailsOfPrimeAndInherited);
                message = `The following features are not on the prime sg: ${key}`;
                mergedFeatureDetails2[key] = {
                    primeFeatureDetail: {},
                    inheritedFeatureDetail,
                    mergedPermissions: mergedFeatureDetailsOfPrimeAndInherited,
                    differencePermissions: [],
                    message,
                    inheritanceDetails: securityGroupsWithFeatureNoPermissions
                };
            }
            return mergedFeatureDetails2;
        }, {});

        
        // // console.log("mergedFeatureDetails: ", mergedFeatureDetails);
        // // console.log("mergedFeatureDetails2: ", mergedFeatureDetails2);

        const featureDetails: any = {};
        for (const key in mergedFeatureDetails) {
            // // console.log("key: ", key);
            const featureDetail = mergedFeatureDetails[key];
            featureDetails[key] = featureDetail.mergedPermissions;
            if (featureDetail.message) {
                // // console.log("featureDetail.message: ", featureDetail.message);
                featureDetails[key].inherited = { permissions: [], inheritanceDetails: {} };
                featureDetails[key]["inherited"].permissions = featureDetail.differencePermissions;
                featureDetails[key]["inherited"].inheritanceDetails[key] = featureDetail.inheritanceDetails[key];
            }
        }

        for (const key in mergedFeatureDetails2) {
            // // console.log("key: ", key);
            const featureDetail = mergedFeatureDetails2[key];
            featureDetails[key] = featureDetail.mergedPermissions;
            if (featureDetail.message) {
                // // console.log("featureDetail.message: ", featureDetail.message);
                featureDetails[key].inherited = { permissions: [], inheritanceDetails: {} };
                featureDetails[key]["inherited"].permissions = featureDetail.mergedPermissions.permissions;
                featureDetails[key]["inherited"].inheritanceDetails[key] = featureDetail.inheritanceDetails[key];
            }
        }
        // // console.log("featureDetails: ", featureDetails);
        return featureDetails;
    }

    appendUserDetailsToParts(userObjectArrayCopy: any, positions: any, userDetails: any) {
        const userPartObjectArray: any = [];
        for (let i = 0; i < userObjectArrayCopy.length; i++) {
            const user = userObjectArrayCopy[i];
            // // console.log("user : ", user);
            const userKey = user.key;
            // // console.log("this.orgUsers : ", this.orgUsers);
            // const firebaseUserObject = this.orgUsers.users.find((o: any) => {
            //     if (o.uid === userKey) {
            //         return o;
            //     }
            // });
            // go through the parts on positions and add the user details to the parts
            let positionObject: any = [];
            for (let j = 0; j < user.positions.length; j++) {
                const position = user.positions[j];
                const positionKey = position.key;
                positionObject = (deepCopy(positions)).find((o: any) => {
                    if (o.key === positionKey) {
                        return o;
                    }
                });

                if (userPartObjectArray.find((o: any) => o.key === positionObject.key) === undefined) {

                    userPartObjectArray.push({
                        [positionObject.key]: {},
                        "key": positionObject.key,
                        "userDetailsArray": [user]
                    });
                } else {

                    // get index
                    const index = userPartObjectArray.findIndex((o: any) => o.key === positionObject.key);
                    // see if user already exist on userDetailsArray
                    if (userPartObjectArray[index].userDetailsArray.find((o: any) => o.key === user.key) === undefined) {
                        userPartObjectArray[index].userDetailsArray.push(user);
                    }
                }
            }

        }

        let positionObject: any = [];
        // attach the user details to the parts
        for (let i = 0; i < userPartObjectArray.length; i++) {
            const userPartObject = userPartObjectArray[i];
            const userPartObjectKey = userPartObject.key;
            const userPartObjectUserDetailsArray = userPartObject.userDetailsArray;
            positionObject = (positions).find((o: any) => {
                if (o.key === userPartObjectKey) {
                    return o;
                }
            });
            positionObject.userDetailsArray = userPartObjectUserDetailsArray;
        }

        // console.log("positionObject >>>>>> >>>>>>: ", positionObject);
        return positions;
    }

    buildUserSecurityGroupsRelationshipsObject(positionsCopy: any, partToStartFromPathSplitString: string) {
        for (let i = 0; i < positionsCopy.length; i++) {
            const path = positionsCopy[i].path;
            const name = positionsCopy[i].name;

            let pathSplitString = "";

            pathSplitString = path.substring(partToStartFromPathSplitString.length, path.length);

            const partData = {
                background: "#c2185b",
                color: "white",
                selected: true,
            };

            // const inheritedSecurityGroupsForPosition = positionsCopy[i].inheritanceObjects.secondaryPositionSecurityGroups;
            // positionsCopy[i].inheritedSecurityGroupsForPosition = inheritedSecurityGroupsForPosition;

            // // console.log("positionsCopy[i] : ", positionsCopy[i]);
            const positionUsers = positionsCopy[i].userDetailsArray;
            const allSecurityGroupsForPosition = [...positionsCopy[i].inheritanceObjects.primaryPositionSecurityGroups, ...positionsCopy[i].inheritanceObjects.secondaryPositionSecurityGroups];
            // // console.log("allSecurityGroupsForPosition : ", allSecurityGroupsForPosition);

            const positionUsersObjectArray2: any = [];

            if (positionUsers !== undefined && positionUsers.length > 0) {
                for (const user of positionUsers) {
                    const userFirebaseObject = this.orgUsers.find((o: any) => o.uid === user.key);
                    // // console.log("userFirebaseObject : ", `userFirebaseObject`);

                    // get the keys of the user.positionsObjects
                    const userPositionObjectKeys = Object.keys(user.positionsObjects);
                    // find where the key of the user on user.positionsObjects is same as position on positionsCopy
                    const userPositionKey: any = userPositionObjectKeys.find((o: any) => o === positionsCopy[i].key);
                    // // console.log("userPositionKey : ", userPositionKey);
                    const userPositionObject = user.positionsObjects[userPositionKey];
                    // // console.log("userPositionObject : ", userPositionObject);


                    const orgSecurityGroupsSubColFeatureObject = [];

                    for (const featureKey in userPositionObject.featureDetails) {
                        const feature = userPositionObject.featureDetails[featureKey];
                        const isInherited = feature.inherited ? true : false;

                        const featurePermissions = feature.permissions ? feature.permissions : undefined;
                        const inheritedFeaturePermissions = feature.inherited ? feature.inherited.permissions : undefined;
                        // // console.log("inheritedFeaturePermissions : ", featurePermissions);
                        // // console.log("inheritedFeatureDetail **** : ", inheritedFeaturePermissions);

                        // // console.log("feature ####### :  ", feature);

                        // featureDetail.permissions = [...new Set([...accPermissions, ...featureDetail.permissions])];

                        const completeFeatureInherited = featurePermissions !== undefined &&
                            inheritedFeaturePermissions !== undefined &&
                            JSON.stringify(featurePermissions.sort()) === JSON.stringify(inheritedFeaturePermissions.sort())
                            ? true : false;

                        const permissionsObjectArray: any = [];

                        let securityGroupWithEmptyPermissionsObject: any = {};
                        const securityGroupWithEmptyPermissionsObjectArray: any = [];
                        if (feature.permissions.length > 0) {

                            for (const permission of feature.permissions) {

                                const inheritedPermission = feature.inherited ? feature.inherited.permissions.find((o: any) => o === permission) : undefined;
                                let permissionsObject: any = {};
                                if (isInherited && inheritedPermission) {

                                    const inheritedDetailsForPermission = feature.inherited.inheritanceDetails[featureKey][permission];
                                    // // console.log("inheritedDetailsForPermission : ", inheritedDetailsForPermission);

                                    // find the sgs for the inherited permission using the sg key and looking up the sg in allSecurityGroupsForPosition
                                    const securityGroupsForPermission = inheritedDetailsForPermission.map((o: any) => allSecurityGroupsForPosition.find((sg: any) => sg.key === o));
                                    // // console.log("securityGroupsForPermission : ", permission, " ", securityGroupsForPermission);
                                    const securityGroupsForPermissionObjectArray: any = [];
                                    for (const sg of securityGroupsForPermission) {
                                        if (sg.featureDetails[featureKey].permissions.includes(permission)) {
                                            const securityGroupObject: any = {
                                                selected: true,
                                                color: 'white',
                                                background: 'green',
                                                data: {
                                                    depth: 2,
                                                    key: sg.key,
                                                    expandedKey: "tree5-" + this.org.key + "-" + "-" + "-" + featureKey + "-" + permission + "-permission" + "-usersOnPositionObject",
                                                    name: sg.name + "-" + sg.key,
                                                    multiUsers: sg.multiUsers,
                                                    type: 'securityGroup'
                                                },
                                                children: [],
                                            };

                                            securityGroupsForPermissionObjectArray.push(securityGroupObject);
                                        }

                                    }


                                    permissionsObject =
                                    {
                                        selected: true,
                                        color: 'white',
                                        background: '#bf60f4',
                                        data: {
                                            depth: 2,
                                            key: featureKey + "-" + permission,
                                            expandedKey: "tree5-" + this.org.key + "-" + "-" + "-" + featureKey + "-" + permission + "-permission" + "-usersOnPositionObject",
                                            name: permission,
                                            inherited: true,
                                            inheritedFromSecurityGroup: '',
                                            inheritedFromPosition: '',
                                            type: 'permission',
                                        },
                                        children: securityGroupsForPermissionObjectArray,
                                    };
                                } else {
                                    permissionsObject =
                                    {
                                        selected: true,
                                        color: 'white',
                                        background: '#6060f4',
                                        data: {
                                            depth: 2,
                                            key: featureKey + "-" + permission,
                                            expandedKey: "tree5-" + this.org.key + "-" + "-" + "-" + featureKey + "-" + permission + "-permission" + "-usersOnPositionObject",
                                            name: permission,
                                            inherited: false,
                                            type: 'permission',
                                        },
                                        children: [],
                                    };
                                }

                                permissionsObjectArray.push(permissionsObject);
                            }
                        } else {
                            // if (sg.featureDetails[featureKey].permissions.length === 0) {
                            // // console.log("sg.featureDetails[featureKey].permissions : ", sg.featureDetails[featureKey].permissions);

                            if (feature.inherited) {
                                const inheritedDetailsForFeature = feature.inherited.inheritanceDetails[featureKey];
                                // // console.log("inheritedDetailsForFeature : ", inheritedDetailsForFeature);

                                // find the sgs for the inherited feature using the sg key and looking up the sg in allSecurityGroupsForPosition
                                const securityGroupsForFeature = inheritedDetailsForFeature.map((o: any) => allSecurityGroupsForPosition.find((sg: any) => sg.key === o));
                                for (const sg of securityGroupsForFeature) {
                                    securityGroupWithEmptyPermissionsObject = {
                                        selected: true,
                                        color: 'white',
                                        background: 'green',
                                        data: {
                                            depth: 2,
                                            key: sg.key,
                                            expandedKey: "tree5-" + this.org.key + "-" + "-" + "-" + featureKey + "-permission" + "-usersOnPositionObject",
                                            name: sg.name + "-" + sg.key,
                                            multiUsers: sg.multiUsers,
                                            type: 'securityGroup'
                                        },
                                        children: [],
                                    };

                                    securityGroupWithEmptyPermissionsObjectArray.push(securityGroupWithEmptyPermissionsObject);

                                    // // console.log("securityGroupWithEmptyPermissionsObjectArray : ", securityGroupWithEmptyPermissionsObjectArray);
                                }

                                permissionsObjectArray.push(...securityGroupWithEmptyPermissionsObjectArray);
                            }
                        }

                        let featureObject: any = {};
                        if (completeFeatureInherited) {
                            featureObject = {
                                selected: true,
                                color: 'white',
                                background: '#bf60f4',
                                data: {
                                    depth: 2,
                                    key: featureKey,
                                    expandedKey: "tree5-" + this.org.key + "-" + "-" + "-" + featureKey + "-usersOnPositionObject",
                                    name: featureKey,
                                    inherited: true,
                                    hasPermissions: feature.permissions.length > 0 ? true : false,
                                    type: 'feature'
                                },
                                children: permissionsObjectArray
                            };
                        } else {

                            const inheritedMessage = isInherited ? '      **** INHERITANCE **** ' : '';
                            featureObject = {
                                selected: true,
                                color: 'white',
                                background: 'blue',
                                data: {
                                    depth: 2,
                                    key: featureKey,
                                    expandedKey: "tree5-" + this.org.key + "-" + "-" + "-" + featureKey + "-usersOnPositionObject",
                                    name: featureKey, // + inheritedMessage,
                                    inherited: isInherited ? true : false,
                                    hasPermissions: feature.permissions.length > 0 ? true : false,
                                    type: 'feature'
                                },
                                children: permissionsObjectArray
                            };
                        }
                        orgSecurityGroupsSubColFeatureObject.push(featureObject);
                    }

                    // if (orgSecurityGroupsSubColFeatureObject.length > 0) {
                    const allFeaturesForUserPositionObject: any = {
                        selected: true,
                        color: 'black',
                        background: '#ffa500',
                        data: {
                            depth: 2,
                            key: user.key + "-" + userPositionKey,
                            expandedKey: "tree5-" + this.org.key + "-" + "allFeaturesForUserPositionObject-" + user.key,
                            name: "featureDetails For User " + userFirebaseObject.displayName + " on Position " + userPositionKey,
                            // multiUsers: sg.multiUsers,
                            type: 'allFeaturesForUserPositionObject'
                        },
                        children: orgSecurityGroupsSubColFeatureObject,
                    };

                    // if (orgSecurityGroupsSubColFeatureObject.length > 0) {
                    const usersObject: any = {
                        selected: true,
                        color: 'white',
                        background: '#c2185b',
                        data: {
                            depth: 2,
                            key: user.key,
                            expandedKey: "tree5-" + this.org.key + "-" + "usersOnPositionObject-" + user.key,
                            name: userFirebaseObject.displayName + " - " + user.key,
                            // multiUsers: sg.multiUsers,
                            type: 'usersOnPositionObject'
                        },
                        children: [allFeaturesForUserPositionObject],
                    };
                    positionUsersObjectArray2.push(usersObject);

                    // }

                }
            }

            if (positionUsersObjectArray2.length > 0) {
                const usersOnPositionObject: any = {
                    selected: true,
                    color: 'white',
                    background: '#c2185b',
                    data: {
                        depth: 1,
                        key: positionsCopy[i].name,
                        expandedKey: "tree5-" + this.org.key + "-" + "-usersOnPositionMainObject",
                        name: 'Users on Position: ** ' + positionsCopy[i].name + " **",
                        type: 'usersOnPositionMainObject',
                    },
                    children: positionUsersObjectArray2,
                };

                // // console.log("usersOnPositionObject : ", usersOnPositionObject);

                const flattenUsersOnPositionObject = flatten(usersOnPositionObject);

                positionsCopy[i].positionUsersFlat = flattenUsersOnPositionObject;

                positionsCopy[i].positionUsersObject = usersOnPositionObject;
            } else {
                positionsCopy[i].positionUsersFlat = {};

                positionsCopy[i].positionUsersObject = {};
            }
        }
    }


}

interface InheritanceObjects {
    primaryPositionSecurityGroups: any[];
    secondaryPositionSecurityGroups: SecondaryPositionSecurityGroup[];
}

interface SecondaryPositionSecurityGroup {
    assignedTo: string;
    policies: any[];
    featureDetails: FeatureDetails;
    roles: any[];
    features: string[];
    name: string;
    key: string;
    assigned: boolean;
    orgPosition: OrgPosition;
    multiUsers?: boolean;
}

interface OrgPosition {
    partKey: string;
    id: number;
    key: string;
    path: string;
    name: string;
    depth: number;
    parentRef: string;
    parentPartKey: string;
    children?: any[];
}

interface FeatureDetails {
    [key: string]: {
        name: string;
        permissions: string[];
        key: string;
    }
}

interface LevelUp {
    background: string;
    color: string;
    selected: boolean;
}

export interface UserDetails {
    key: string;
    name: string
    positions: Position[];
    primaryPositionSecurityGroupsForUser: SecurityGroupDetails[];
    secondaryPositionsSecurityGroups: any[];
    positionsObjects: PositionsObjects;
}

export interface Position {
    partKey: string;
    parentPartKey: string;
    depth: number;
    parentRef: string;
    id: number;
    name: string;
    key: string;
    path: string;
    levelUp: LevelUp;
    type: string;
    expandedKey: string;
    securityGroupsArray: SecurityGroupDetails[];
    inheritanceObjects: InheritanceObjects;
    userDetailsArray: UserDetails[];
    parent: string;
    children?: Position[];
    parents?: Position[];
    colour: {
        background: string;
        color: string;
        linkColor: string;
    }

    securityGroupsFlat: Record<string, any>;
    securityGroupsObject: {} | FlatSecurityGroupObject;
}

interface PositionsObjects {
    [key: string]: {
        position: Position[];
        primaryPositionSecurityGroups: SecurityGroupDetails[];
        secondaryPositionsSecurityGroups: any[];
        featureDetails: FeatureDetails;
    };
}

export interface SecurityGroupDetails {
    assignedTo: string;
    policies: any[];
    featureDetails: FeatureDetails;
    roles: any[];
    features: string[];
    name: string;
    multiUsers: boolean;
    key: string;
    assigned: boolean;
    orgPosition: OrgPosition;
}

interface FlatSecurityGroupObject {
    selected: boolean;
    color: string;
    background: string;
    data: {
        depth: number;
        key: string;
        expandedKey: string;
        name: string;
        type: string;
    },
    children: FlatSecurityGroupObject[],
};