import { userUpdate } from '../../redux/user/user.actions';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ThemeIcons, Themes } from '@newgenus/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';

@Injectable()
export class ThemeService {

    public themeIcon: ThemeIcons = ThemeIcons['light-theme'];
    public userTheme: Themes;
    public themeChange$ = new BehaviorSubject<Themes>(Themes['default-theme']);
    public isFocusModeEnabled$ = new BehaviorSubject<boolean>(false);
    public isSmallDisplay$ = new BehaviorSubject<boolean>(false);
    public localSidebarStatus$ = new BehaviorSubject<boolean>(false);
    public showFocusBtn$ = new BehaviorSubject<boolean>(false);

    /**
     * This alone is not the only condition to showing the header.
     * Included is the auth state and the userInstantiated state.
     * @see AppComponent for more information.
     *
     * @memberof ThemeService
     */
    public showHeader$ = new BehaviorSubject<boolean>(true);

    /**
     * Used to determine if the automatic focus mode is enabled or not.
     * @example
     * > User clicks on a button to enable focus mode.
     * > User starts dragging an item in the organization board.
     * > - Focus mode is enabled.
     * > User stops dragging an item in the organization board.
     * > - Focus mode is disabled (returns back to the state it was before).
     */
    public manualFocusState = false;

    public hasUpdatedFocusBtn = false;

    constructor(
        private overlay: OverlayContainer,
        private store: Store
    ) {
        // this.userTheme = localStorage.getItem('theme') as Theme || Theme['default-theme'];
        this.userTheme = localStorage.getItem('theme') as Themes || Themes['light-theme'];
        if (this.userTheme === Themes['light-theme']) {
            this.themeIcon = ThemeIcons['light-theme'];
        } else if (this.userTheme === Themes['dark-theme']) {
            this.themeIcon = ThemeIcons['dark-theme'];
        } //else {
        // this.themeIcon = ThemeIcons['default-theme'];
        // }

        this.themeChange$.next(this.userTheme);
        this.applyTheme(this.userTheme);
    }

    public getThemeIcon(): ThemeIcons {
        return this.themeIcon;
    }

    /**
     * Toggle between the Light and Dark theme and updates the User in the store.
     *
     * @return {*}  {ThemeIcons} The mat-icon which can be used to indicate the theme.
     * @memberof ThemeService
     */
    public toggleTheme(): ThemeIcons {
        // Switch between 2 themes.
        const newTheme = this.userTheme === Themes['light-theme'] ? Themes['dark-theme'] : Themes['light-theme'];
        // Switch between 3 themes.
        // const newTheme = this.userTheme === Theme['default-theme'] ? Theme['light-theme'] : (this.userTheme === Theme['light-theme'] ? Theme['dark-theme'] : Theme['default-theme']);

        if (newTheme === Themes['light-theme']) {
            this.themeIcon = ThemeIcons['light-theme'];
        } else if (newTheme === Themes['dark-theme']) {
            this.themeIcon = ThemeIcons['dark-theme'];
        } // else {
        //   this.themeIcon = ThemeIcon['default-theme'];
        // }

        this.applyTheme(newTheme);

        this.userTheme = newTheme;
        // this.user.theme = newTheme;
        const payload = { theme: newTheme }
        localStorage.setItem('theme', newTheme);

        this.store.dispatch(userUpdate({
            payload: payload
        }));

        this.themeChange$.next(newTheme);

        return this.themeIcon;
    }

    /**
     * Applies the theme to all visible elements on the screen.
     *
     * @private
     * @param {Themes} theme The Theme to apply.
     * @memberof ThemeService
     */
    private applyTheme(theme: Themes) { // TODO: theme-service
        if (this.overlay.getContainerElement().classList.contains("default-theme")) {
            this.overlay.getContainerElement().classList.remove("default-theme");
            document.body.classList.remove("default-theme");
        }
        if (this.overlay.getContainerElement().classList.contains("light-theme")) {
            this.overlay.getContainerElement().classList.remove("light-theme");
            document.body.classList.remove("light-theme");
        }
        if (this.overlay.getContainerElement().classList.contains("dark-theme")) {
            this.overlay.getContainerElement().classList.remove("dark-theme");
            document.body.classList.remove("dark-theme");
        }

        this.overlay.getContainerElement().classList.add(theme);
        document.body.classList.add(theme);
    }

    public manualUpdateOfIsFocusMode(isFocusModeEnabled: boolean): void {
        this.manualFocusState = isFocusModeEnabled;
        this.isFocusModeEnabled$.next(isFocusModeEnabled);
    }

    public automaticEnableFocusState(): void {
        this.showFocusBtn$.next(false);
        this.isFocusModeEnabled$.next(true);
    }

    public automaticReturnToManualFocusState(): void {
        this.isFocusModeEnabled$.next(this.manualFocusState);
        this.showFocusBtn$.next(true);
    }

    public localUpdateOfIsSmallDisplay(isSmall: boolean): void {
        this.isSmallDisplay$.next(isSmall);
    }

    public localUpdateOfSidebarStatus(barStatus: boolean): void {
        this.localSidebarStatus$.next(barStatus);
    }

    public updateShowHeader(showHeader: boolean): void {
        this.showHeader$.next(showHeader);
    }

    public updateShowFocusBtn(showFocusBtn: boolean): void {
        this.hasUpdatedFocusBtn = true;
        this.showFocusBtn$.next(showFocusBtn);
    }

}
