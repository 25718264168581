import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { OrbBoardBaseComponent } from '../../org-board-base-component';
import { ECONode, Organization, OrgUser } from '@newgenus/common';
import { MaterialModule } from '../../../material.module';
import { Position } from '../../services/tree-builder.service';
import { CdkDropList } from '@angular/cdk/drag-drop';
import { CommonModule, TitleCasePipe } from '@angular/common';

@Component({
  selector: 'shared-one-user',
  standalone: true,
  imports: [CommonModule, MaterialModule, TitleCasePipe],
  template: `
    <mat-card class="org-position-card" [ngStyle]="{'opacity': node?.isSelected ? '1' : '0.7'}"
      (click)="(isDragNavDisabled || isNavigatingWithDrag) ? '' : selectSiblingNode.emit(node)">
      <!-- (click)="(isDragNavDisabled || isDragging) ? '' : selectSiblingNode.emit(node)"> -->
  
      <mat-card-header>
        <mat-card-title-group>
          <mat-card-title
            (click)="openTemplate.emit(node); $event.stopPropagation();">{{ (userDetailArray[0].name || '') | titlecase}}</mat-card-title>
          <mat-card-subtitle style="font-size: 0.8rem;">{{ (userDetailArray[0].primaryPositionSecurityGroupsForUser[0].name || '') | titlecase}}</mat-card-subtitle>
          <img mat-card-sm-image
            [src]="'https://ui-avatars.com/api/?name=' + (userDetailArray[0].name || 'John_Doe') + '&background=random' + '&color=random'">
        </mat-card-title-group>
      </mat-card-header>
  
      <!-- <mat-card-content [ngStyle]="{'background-color': node.bc, 'color': node.c }"> -->
      <mat-card-content>
        <ul class="clickable-li"
        cdkDropList 
        [cdkDropListDisabled]="!isEditing"
        (cdkDropListDropped)="templateDrop.emit({ event: $event,  node: node})"  
        [cdkDropListConnectedTo]="dropLists"
        [cdkDropListData]="data"
        >
  
          <li *ngFor="let user of userDetailArray"
            (click)="openTemplate.emit(node); $event.stopPropagation()"
            cdkDrag 
            [cdkDragData]="user"
            [cdkDragDisabled]="isNavigatingWithDrag" 
            (cdkDragEnded)="stateChange.emit(false); dragEnded.emit(node);" 
            (cdkDragStarted)="stateChange.emit(true); dragStarted.emit(node);"
          >
            {{user.name | titlecase}}
  
            <div *ngIf="isEditing" class="drag-handle" cdkDragHandle 
              (mouseenter)="stateChange.emit(true)"
              (mouseleave)="isDroppableItemBeingDragged ? '' : stateChange.emit(false)"
              (click)="$event.stopPropagation();"
              >
              <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                <path
                  d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                </path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
            </div>
  
            <div class="drag-placeholder" *cdkDragPlaceholder>
              <span>{{user.name | titlecase}}</span>
            </div>
          </li>
        </ul>

        <div *ngIf="nodeAsAny?.data?.heldFromAboveUserName">
          <div>
            <span>HFA:</span>
            <div *ngFor="let user of hfaAsUsers" class="user-list-container">
            
              <span class="grouped">
                <img [src]="'https://ui-avatars.com/api/?name=' + user.name + '&background=random' + '&color=random'">
                <span class="clickable-span" (click)="openUser.emit(user); $event.stopPropagation();">{{user.name | titlecase}}</span>
              </span>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    `,
  styleUrls: ['../../organization-board.component.scss']
})
export class OneUserComponent extends OrbBoardBaseComponent {

  @Input()
  public isDragNavDisabled = false;

  // @Input() 
  // public isDragging = false;

  @Input()
  public isNavigatingWithDrag = false;

  @Input()
  public isDroppableItemBeingDragged = false;
  
  @Input()
  public isEditing = false;

  @Input()
  public node!: ECONode<Position>;

  @Input()
  public dropLists!: CdkDropList[];

  @Input()
  public data: any[] = [];

  @Output()
  public selectSiblingNode = new EventEmitter<ECONode<Position>>();

  @Output()
  public openUser = new EventEmitter<OrgUser>();

  @Output()
  public openTemplate = new EventEmitter<any>();

  @Output()
  public stateChange = new EventEmitter<boolean>();

  @Output()
  public dragEnded = new EventEmitter<ECONode<Position>>();

  @Output()
  public dragStarted = new EventEmitter<ECONode<Position>>();

  @Output()
  public templateDrop = new EventEmitter<{ event: any, node?: ECONode<Position> }>();

  @Output()
  public dropList = new EventEmitter<CdkDropList>();

  @ViewChild(CdkDropList)
  public set dropListChanged(value: CdkDropList) {
    if (value) {
      this.dropListElement = value;
      this.dropList.emit(value);
    }
  }

  public dropListElement!: CdkDropList;

  public override ngOnInit(): void {
    // Doesn't need to do anything...
  }

  public get userDetailArray() {
    // return this.node?.data?.userDetailsArray || [];
    return (<any>this.node).data.userDetailsArray;
  }

  public get nodeAsAny() {
    return <any>this.node;
  }

  public get hfaAsUsers() {
    const hfaUidString = this.nodeAsAny?.data?.heldFromAboveUid;
    if (!hfaUidString || this.org === null) {
      return [];
    }

    return hfaUidString
      .split(',')
      .map((uid: string) => (this.org as Organization).orgUsers[uid]);
  }

}
