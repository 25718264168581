import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Organization } from '@newgenus/common';
import { createReducer, on } from '@ngrx/store';
import { OrgActions } from './action-types';

// Entity adapter & initial state
export interface OrgState extends EntityState<Organization> {
    allOrgsLoaded: boolean; // set initial data
}

export const adapter = createEntityAdapter<Organization>({
    // sortComparer: compareParticles,
    selectId: org => org.key,
});

export const initialOrgState = adapter.getInitialState({
    allOrgsLoaded: false,
});

// Reducer functions

export const orgsReducer = createReducer(
    initialOrgState,

    on(OrgActions.allOrgsLoaded, (state, action) => adapter.setAll(action.org, { ...state, allOrgsLoaded: true })),

    on(OrgActions.orgAdded, (state, action) => adapter.setOne(action.payload, { ...state, allOrgsLoaded: true })),

    on(OrgActions.orgRemoved, (state, action) => adapter.removeOne(action.payload.key as string, state)),

    on(OrgActions.orgUpdate, (state, action) => adapter.updateOne({ id: action.payload.key as string, changes: action.payload }, state)),

    on(OrgActions.orgModified, (state, action) => adapter.updateOne({ id: action.payload.key as string, changes: action.payload }, state)),

    on(OrgActions.orgUserModified, (state, action) => adapter.updateOne({ id: action.payload.key as string, changes: action.payload }, state)),

    on(OrgActions.orgTargetModified, (state, action) => adapter.updateOne({ id: action.payload.key as string, changes: action.payload }, state)),

    on(OrgActions.orgUserTargetModified, (state, action) => adapter.updateOne({ id: action.payload.key as string, changes: action.payload }, state)),

    on(OrgActions.orgSettingsModified, (state, action) => adapter.updateOne({ id: action.payload.key as string, changes: action.payload }, state)),
);

export const { selectAll } = adapter.getSelectors();
