import { createAction, props } from '@ngrx/store';
import { Particle } from '@newgenus/common';
import { Update } from '@ngrx/entity';

export const loadAllParticles = createAction('[Particles Resolver] Load All Particles');

export const loadAllParticlesDetails = createAction('[Particles Details Resolver] Load All Particles Details');

export const allParticlesDetailsLoaded = createAction(
    '[Load Particles Details Effect] All particles Details Loaded',
    props<{ payload: Partial<Particle> }>()
);

export const allParticlesLoaded = createAction(
    '[Load Particles Effect] All particles Loaded',
    props<{ particles: Particle[] }>()
);

export const allParticlesSortOrderLoaded = createAction(
    '[Particle Sort Order] All Particle Sort Order Loaded',
    props<{ selectedSort: any }>()
);

export const clearAllParticles = createAction('[Clear All Particles] Clear All Particles', props<{ payload: Particle[] | null }>());

export const particleAdded = createAction('[Particle] added', props<{ payload: Particle }>());

export const particleDraftAdded = createAction('[Particle] draft added', props<{ payload: Particle }>());

export const particleRemoved = createAction('[Particle] removed', props<{ payload: Partial<Particle> }>());

export const particlesRemoved = createAction('[Particles] removed', props<{ particles: { ids: string[] } }>());

export const particleModified = createAction('[Particle] updated', props<{ payload: Partial<Particle> }>());

export const particleUpdated = createAction('[Particle] modified', props<{ payload: Partial<Particle> }>());

export const particleReadStatusUpdate = createAction(
    '[Particle] read status update',
    props<{ payload: Partial<Particle> }>()
);

export const particlesModified = createAction('[Particles] modified', props<{ particles: Update<Particle>[] }>());

export const particleDraftUpdate = createAction('[Particle] Draft Update', props<{ payload: Partial<Particle> }>());

export const particleUpdate = createAction(
    '[Particle updating] updating Particle',
    props<{ payload: Partial<Particle> }>()
);

export const updateDraftParticleAttachments = createAction(
    '[Draft updating] updating Draft Attachments',
    props<{ payload: Partial<Particle> }>()
);

export const particleUpdateLabels = createAction('[Particle] Update Labels', props<{ payload: Partial<Particle> }>());

export const particleSendAndFile = createAction(
    '[Particle] Send and File',
    props<{ payload: Partial<Particle> }>()
);

export const particleReadyToSend = createAction(
    '[Particle] Ready to Send',
    props<{ payload: Partial<Particle> }>()
);

export const particlePend = createAction(
    '[Particle] Pend Particle',
    props<{ payload: Partial<Particle>; pendingTaskSchedule: any }>()
);

export const particlePendAndSend = createAction(
    '[Particle] Pend Particle and Send',
    props<{ payload: Partial<Particle>; pendingTaskSchedule: any }>()
);
