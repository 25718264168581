
<div class="scroll-x">

  <table mat-table (matSortChange)="onChangeSort($event)"
      [dataSource]="dataSource" multiTemplateDataRows
      class="client-abc-table"
      matSort>
      
    <ng-container [matColumnDef]="column" *ngFor="let column of tableColumns">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><span [matTooltip]="tooltips[column]" matTooltipPosition="above">{{column | titlecase}} </span> </th>
      <td mat-cell *matCellDef="let element"><shared-client-abc-table-column [entry]="element" [column]="column"></shared-client-abc-table-column></td>
    </ng-container>

    <!-- <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button aria-label="expand row" (click)="delayedContainerUpdate(); (expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
          <mat-icon *ngIf="expandedElement !== element">open_in_full</mat-icon>
          <mat-icon *ngIf="expandedElement === element">close_fullscreen</mat-icon>
        </button>
      </td>
    </ng-container> -->

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
        <div class="entry-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">

          <div class="entry-container">

            <ng-container *ngIf="!isClientAbcGroup; else embeddedClientAbcTable">
              <ng-container 
                [ngTemplateOutlet]="salesGraph" 
                [ngTemplateOutletContext]="{element:element}">
              </ng-container>
            </ng-container>

            <ng-template #embeddedClientAbcTable>
              <mat-accordion class="my-3">

                <span *ngIf="element.dueForProcess" class="due-for-processing-message">
                  This group is due for processing; Within the next 5-10 minutes the figures will be updated.
                </span>
                <mat-progress-bar *ngIf="element.dueForProcess" mode="indeterminate"></mat-progress-bar>

                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Graph of Sales
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <ng-container 
                    [ngTemplateOutlet]="salesGraph" 
                    [ngTemplateOutletContext]="{element:element}">
                  </ng-container>
                </mat-expansion-panel>

                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Per-Client Client ABC
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  
                  <div class="embedded-client-abc">
                    <mat-progress-bar *ngIf="expandedElement?.salesSummary === undefined" mode="indeterminate"></mat-progress-bar>
                    <shared-client-abc 
                      [dataForCurrentMonth]="dataForCurrentMonth"
                      [dateForData]="dateForData"
                      [dateProfile]="dateProfile"
                      [filterValue]="filterValue"
                      [gatewayConfig]="gatewayConfig"
                      [organization]="organization"
                      [past6MonthsIncludingCurrentMonth]="past6MonthsIncludingCurrentMonth"
                      [selectedRep]="selectedRep"
                      [tableColumns]="embeddedTableColumns"
                      [tableData]="expandedElement?.salesSummary"
                      [tooltips]="tooltips"
                      [settings]="settings"
                      [showFooter]="false"
                    ></shared-client-abc>
                  </div>

                </mat-expansion-panel>

                <mat-expansion-panel *ngIf="!isWidget">
                  <mat-expansion-panel-header class="border-bottom border-secondary">
                    <mat-panel-title>
                      Settings
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="d-flex justify-content-start mt-2">
                    <button mat-flat-button color="primary" class="ml-3" (click)="onEditGroup(expandedElement)">Edit Group</button>
                    <button mat-flat-button color="warn" class="mx-3" (click)="onDeleteGroup(expandedElement)">Delete Group</button>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              
            </ng-template>
          </div>

        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>

    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
        class="entry-row"
        [ngClass]="{'due-for-processing': element.dueForProcess, 'tax-inclusive': element.figuresAre === 'gross', 'tax-exclusive': element.figuresAre === 'nett', 'padding-right': isWidget}"
        [class.example-expanded-row]="expandedElement === element"
        (click)="onExpandToggle(element, expandedElement === element ? false : true); delayedContainerUpdate(); expandedElement = expandedElement === element ? null : element">
    </tr>

    <!-- Row shown when an entry is expanded. -->
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="entry-detail-row" [ngClass]="{'h-0': row !== expandedElement}"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="10">
        <p style="text-align: center;">
          <i>
            
            <ng-container *ngIf="filterValue; else emptyTableMessage">
              No data matching the filter "{{filterValue}}"
            </ng-container>
            
            <ng-template #emptyTableMessage>
              No data available
            </ng-template>
          
          </i>
        </p>
      </td>
    </tr>

    <!-- Footer column -->
    <ng-container *ngIf="showFooter" matColumnDef="footer">
      <td mat-footer-cell *matFooterCellDef colspan="10">
        
        <div class="client-abc-footer" *ngIf="dataSource.data.length !== 0"> 
          <div *ngIf="!isClientAbcGroup && selectedRep !== null">
            <span>
              Monthly target: <strong>R{{footerData.monthTarget | number}}</strong>
            </span>

            <span>
              Weekly target: <strong>R{{footerData.weekTarget | number}}</strong>
            </span>

            <span>
              Daily target: <strong>R{{footerData.dayTarget | number}}</strong>
            </span>
          </div>

          <div>
            <span>
              Sales This Month: <strong>R{{footerData.salesThisMonth | number}}</strong>
            </span>
            
            <span>
              Sales This Week: <strong>R{{footerData.salesThisWeek | number}}</strong>
            </span>
            
            <span>
              Sales today: <strong>R{{footerData.salesToday | number}}</strong>
            </span>
          </div>

          <div>
            <span>
              Sales Previous Month: <strong>R{{footerData.salesPreviousMonth | number}}</strong>
            </span>

            <span>
              Previous Month Difference: <strong>R{{footerData.previousMonthSalesDifference | number}}</strong>
            </span>

            <span>
              Previous Month Difference: <strong [ngClass]="{
                'uptrend': footerData.previousMonthSalesDifferencePercentage > 0,
                'downtrend': footerData.previousMonthSalesDifferencePercentage < 0
              }" >{{footerData.previousMonthSalesDifferencePercentage | number}}%</strong>
            </span>
          </div>

          <div>
            <span>
                Monthly Sales Average: <strong>R{{footerData.monthAverage | number}}</strong>
            </span>

            <span>
                Average Month Sale {{isClientAbcGroup ? 'Across Groups' : 'Per Client'}}: <strong>R{{footerData.clientMonthAverage | number}}</strong>
            </span>

            <span *ngIf="!isClientAbcGroup" title="Table values could be inclusive or exclusive of tax">
              Values are: <strong class="value-indicator" [ngClass]="{'tax-inclusive': settings?.appearance === 'gross', 'tax-exclusive': settings?.appearance === 'nett'}"
              >{{parseGrossOrNett(settings?.appearance)}}</strong>
            </span>
          </div>
        </div>

      </td>
    </ng-container>
    <ng-container *ngIf="showFooter">
      <tr  mat-footer-row *matFooterRowDef="['footer']; sticky:true" class="footer-row"></tr>
    </ng-container>
  </table>

</div>


<mat-paginator [pageSizeOptions]="[10, 25, 100]" [showFirstLastButtons]="true" aria-label="Select page of clients"></mat-paginator>

<ng-template #salesGraph let-element='element'>
  <section id="client-abc-graph-container" (mouseup)="onContainerResize($event)" (touchend)="onContainerResize($event)">
    <shared-client-abc-graph
      *ngIf="element === expandedElement"
      [data]="expandedElement"
      [past6Months]="past6MonthsIncludingCurrentMonth"
      [parentProps]="containerProps"
    ></shared-client-abc-graph>
  </section>
</ng-template>