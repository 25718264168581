/* eslint-disable no-restricted-syntax */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-input-rename */
import { Organization, deepCopy, parseEmbeddedTimestampsToFirebaseDate, toOrdinalString, ComplexGraphData, ConsolidatedComplexGraphData, isSalesTransactionInvoice, isSalesTransactionSalesOrder, NewgenusSalesTransaction, NewgenusSalesTransactionInvoice, NewgenusSalesTransactionSalesOrder, NewgenusSalesTransactionType, GraphStatus, DateSettings, IQConfiguration, DaySummaryDatum, FirebaseDaySummary, parseEmbeddedTimestampsToJavascriptDate, Themes, getCompanyMonthStartAndEndByDateSettings, toDate, TargetSnapshot, isObjectEmpty, RepDoc, isObjectNotEmpty } from '@newgenus/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import moment from 'moment';
import * as d3 from 'd3';

@Component({
    selector: 'shared-7r-graph-day',
    styleUrls: ['../graph-base.component.scss'],
    template: `
        <div *ngIf="showDateRange" style="display: flex; flex-wrap: wrap; justify-content: center;">
            <span class="graph-date" title="Graph Date Range" [ngClass]="{'graph-date-expanded': isExpanded}"
                >{{ (dateForData | date : 'EEEE') + '' + (fromDate | date : ' H:mm') + ' - ' + (toDate | date : 'H:mm,') + ' ' + (dateForData | date : 'MMMM')}} {{toOrdinalString(dateForData?.getDate())}}
            </span>
        </div>
        <div class="graph-parent-container">
          <div>

            <section class="graph-wrapper">
                <button mat-icon-button title="Toggle Expand Mode" (click)="onExpandToggle()" class="expand-shrink-btn"><mat-icon>{{isExpanded ? 'unfold_less' : 'unfold_more'}}</mat-icon></button>

              <shared-7r-graph-base
                title="Daily 7R Graph"
                [consolidateData]="consolidateData"
                [data]="graphData"
                [expanded]="isExpanded"
                [graphMargin]="margin"
                [graphType]="'daily'"
                [height]="height"
                [parentProps]="parentProperties"
                [repNames]="repNames"
                [secondaryData]="graphDataSalesOrders"
                [target]="target"
                [tertiaryData]="graphDataInvoices"
                [theme]="theme"
                [width]="width"
                [xExtent]="xExtent"
                [xFormat]="xFormat"
                [xTicks]="xTicks"
                [yExtent]="yExtent"
                [yScale]="yScale"
                [fromDate]="fromDate"
                [toDate]="toDate"
                (graphStatus)="onGraphStatusChange($event)"
                (clickEntry)="onClickEntry($event)"

              ></shared-7r-graph-base>

              
              <div class="text-center" *ngIf="showDatePicker">
                <button mat-icon-button (click)="previousDate()" class="nav-btn" color="primary" aria-label="Button which shows the previous day's data." title="Button which shows the previous day's data.">
                    <mat-icon>chevron_left</mat-icon>
                </button>

                <mat-form-field appearance="outline">
                  <mat-label>Choose a date</mat-label>
                  <input matInput [matDatepicker]="picker" disabled [(ngModel)]="dateForData" (dateChange)="onDateChange()">
                  <mat-hint>MM/DD/YYYY</mat-hint>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker disabled="false"></mat-datepicker>
                </mat-form-field>
                
                <button mat-icon-button (click)="nextDate()" class="nav-btn" color="primary" aria-label="Button which shows the previous day's data." title="Button which shows the previous day's data.">
                    <mat-icon>chevron_right</mat-icon>
                </button>
              </div>
            </section>

            <div style="display: flex; flex-wrap: wrap; justify-content: center;" *ngIf="showConsolidateHours">
                <div style="display: flex; flex: 1 auto;">
                    <mat-checkbox [(ngModel)]="consolidateData" (change)="onChangeShowConsolidatedHours()"
                        >Show Consolidated Hours<mat-icon
                            matTooltip="When checked, sales recorded outside of working hours will appear on the start of the next working day."
                        >info</mat-icon
                    ></mat-checkbox>
                </div>
            </div>
          </div>

            <div *ngIf="showEmbeddedEntryOnClick && selectedEntries.length > 0" style="padding-right: 1rem;">

              <ul *ngFor="let entry of selectedEntries" class="selected-entry-details" [ngClass]="{'selected-primary': entry.line === 'primary', 'selected-secondary': entry.line === 'secondary', 'selected-tertiary': entry.line === 'tertiary'}">
                <li>Rep: <strong [innerHtml]="toRepName(entry.repKey)"></strong></li>
                <li>Transaction: <strong>R{{entry.value | number}}</strong></li>
                <li>Accumulated Value: <strong>R{{entry.valueCompounded | number}}</strong></li>
                <li>Date: <strong>{{entry.date | date: 'EEEE, MMMM d, y, h:mm a'}}</strong></li>
                <li>Rep No.: <strong>{{entry.repKey}}</strong></li>
                <!-- <li>data: <strong>{{entry.data?.length}}</strong></li> -->
              </ul>
            </div>

          <div class="save-loader" *ngIf="showSpinner">
              <mat-spinner diameter="51"></mat-spinner>
          </div>
        </div>
    `
})
export class Shared7rDayComponent implements OnDestroy, OnChanges {

    @Input()
    public showDateRange = true;

    @Input()
    public showConsolidateHours: undefined | boolean = true;

    @Input()
    public showDatePicker = true;

    @Input('date')
    public dateForData: Date | null = null;

    @Input('repKeys')
    public repKeys!: BehaviorSubject<string[]>;

    @Input('filtersByCode')
    public filtersByCode: null | Record<string, boolean> = null;

    @Input()
    public showCompanyTargets: boolean | null = false;

    @Input()
    public width = 470;

    @Input()
    public height = 440;

    @Input('parentProps')
    public parentProperties = { height: 0, width: 0 };

    @Input('showEntryOnClick')
    public showEmbeddedEntryOnClick = false;

    @Input()
    public mayViewCompanyStats: boolean | null = false;

    @Input()
    public mayViewRepStats: boolean | null = false;

    @Input()
    public selectedOrg: null | Organization = null;

    @Input()
    public dateSettings: null | DateSettings = null;

    @Input()
    public selectedConfig: null | Partial<IQConfiguration> = null;

    /**
     * @deprecated - Not used since the summaries have these in them.
     */
    @Input()
    public openSalesOrders: NewgenusSalesTransactionSalesOrder[] | null = [];

    @Input()
    public data: NewgenusSalesTransaction[] | null = [];

    @Input()
    public summaryData: FirebaseDaySummary[] | null = [];

    @Input()
    public signedInUsersRepMapping: RepDoc | undefined;

    @Input()
    public theme: Themes = Themes['default-theme'];

    @Output('clickEntry')
    public onClickedEntry: EventEmitter<NewgenusSalesTransaction[]> = new EventEmitter<NewgenusSalesTransaction[]>();

    public margin = { top: 30, right: 20, bottom: 50, left: 70 };
    public target = 0;
    public repNames: { [key: number]: string } = {};

    public graphData: Array<NewgenusSalesTransaction> = [];
    public graphDataSalesOrders: Array<ComplexGraphData> = [];
    public graphDataInvoices: Array<ComplexGraphData> = [];
    private dayData: Array<NewgenusSalesTransaction> = [];

    public xTicks = 10;
    public xFormat = '%I %p';
    public xScale!: d3.ScaleTime<number, number, never>;
    public yScale!: d3.ScaleLinear<number, number, never>;
    // public selectedEntries: NewgenusSalesTransaction[] = [];
    public selectedEntries: any[] = [];
    public isExpanded = false;
    private hasSubscribedToRepKeys = false;;

    public yExtent: [number, number] = [0, 0];
    public xExtent!: [Date, Date];
    private workingDayStart!: Date;
    private workingDayEnd!: Date;

    /**
     * This flag indicates if sales made outside of the working hours are grouped and shown in the graph.
     * For example, a working day of 8-17 with consolidateData set to true, sales made at 7am will show from 8am. Likewise, sales made after 17 will show on the next day, from 8am.
     *
     * @memberof LineGraphComponent
     */
    @Input()
    public consolidateData = false;

    public showSpinner = true;

    private dateChange$ = new BehaviorSubject<Date>(new Date());
    private destroy$ = new Subject<void>();
    private workingDayEndYesterday!: Date;

    @Output()
    public fromDateChange = new EventEmitter<Date>();
    public fromDate: Date = new Date();

    @Output()
    public toDateChange = new EventEmitter<Date>();
    public toDate: Date = new Date();

    // Used in the graph to show the total sales orders, invoices, etc. Often just deconstructed to make a new object with the correct fields.
    private readonly zeroBasedComplexGraphData = {
        creditNoteGross: 0,
        creditNoteNett: 0,
        creditNoteTax: 0,
        invoicedCompounded: 0,
        invoicedGross: 0,
        invoicedNett: 0,
        invoicedTax: 0,
        nettOutstanding: 0,
        salesOrderGross: 0,
        salesOrderNett: 0,
        salesOrdersCompounded: 0,
        salesOrderTax: 0,
        value: 0,
        valueCompounded: 0,
    } as ComplexGraphData;


    constructor(public store: Store) {

    }

    private setTargetForGraph(repKeys: string[]) {

        // Use target from summary data.
        if (this.summaryData && this.summaryData.length > 0) {

            const targetsToExtract: Record<string, TargetSnapshot[]> = {};

            // If the user has permission to view company stats, show company targets.
            if (this.mayViewCompanyStats && this.showCompanyTargets && this.selectedConfig?.key) {
                // Find the selected reps summaries, and extract their targets.
                if (repKeys && repKeys?.length > 0) {
                    for (const repKey of repKeys) {
                        const repSummaryTarget = this.summaryData.find(summary => summary.summaryIsForKey === repKey);
                        if (repSummaryTarget) targetsToExtract[repKey] = repSummaryTarget.targets;
                    }
                }
                // Find the org summary, and extract the targets.
                else {
                    const orgSummaryTarget = this.summaryData.find(summary => summary.summaryIsForKey === this.selectedConfig?.orgKey);
                    if (orgSummaryTarget) targetsToExtract[this.selectedConfig.orgKey as string] = orgSummaryTarget.targets;
                }

            } else if (this.mayViewRepStats && !this.showCompanyTargets && this.selectedConfig?.key) {
                // Find the selected reps summaries, and extract their targets.
                if (repKeys && repKeys?.length > 0) {
                    for (const repKey of repKeys) {
                        const repSummaryTarget = this.summaryData.find(summary => summary.summaryIsForKey === repKey);
                        if (repSummaryTarget) targetsToExtract[repKey] = repSummaryTarget.targets;
                    }
                }
                // Find the signed in user's summary, and extract the targets.
                else if (this.signedInUsersRepMapping) {
                    const repSummaryTarget = this.summaryData.find(summary => summary.summaryIsForKey === this.signedInUsersRepMapping?.repNum);
                    if (repSummaryTarget) targetsToExtract[this.signedInUsersRepMapping.repNum] = repSummaryTarget.targets;
                }

            }

            if (isObjectEmpty(targetsToExtract)) {
                // 0 = don't show target line.
                this.target = 0;
            } else {
                // Extract the targets from the summaries.
                const extractedTargets = Object.entries(targetsToExtract).map(([key, value]) => {
                    // Select most recent target from the array.
                    if (value && value.length > 0) {
                        const mostRecentTarget = value.reduce((a, b) => toDate(a.date) >= toDate(b.date) ? a : b);
                        return mostRecentTarget?.dayTarget || 0;
                    } else return 0;
                });

                // Sum the targets.
                this.target = extractedTargets.reduce((a, b) => a + b, 0);
            }
        }

        // Use targets from org.
        if (!(this.summaryData && this.summaryData.length > 0) || this.target <= 0) {


            // If the user has permission to view company stats, show company targets.
            if (this.mayViewCompanyStats && this.selectedOrg !== undefined && this.showCompanyTargets && this.selectedConfig?.key) {

                // If the user has selected reps, show a sum of targets for the selected reps.
                if (repKeys?.length > 0) {
                    this.target = 0;

                    if (this.selectedOrg)
                        for (const userKey of (this.selectedOrg?.orgUsers?.userKeys || [])) {
                            const orgUser = this.selectedOrg.orgUsers[userKey];

                            if (orgUser?.targets && orgUser?.statistics
                                && orgUser.targets[this.selectedConfig.key]
                                && orgUser?.statistics[this.selectedConfig.key]
                                && repKeys.includes(orgUser.statistics[this.selectedConfig.key]?.repNo)) {

                                this.target += orgUser?.targets[this.selectedConfig.key].dailyTarget;
                            }
                        }
                }
                // If no reps are selected, show the company stats if they are available.
                else if (this.selectedConfig?.key && this.selectedOrg?.targets && this.selectedOrg.targets[this.selectedConfig.key]) {
                    this.target = this.selectedOrg.targets[this.selectedConfig.key].dailyTarget;
                } else {
                    // console.debug('No target set');
                    this.target = 0;
                }
            } else if (this.selectedOrg !== undefined && this.mayViewRepStats && !this.showCompanyTargets) {
                this.target = 0;
                // If the user has permission to view rep stats, show rep targets.
                if (this.selectedOrg)
                    for (const userKey of this.selectedOrg.orgUsers.userKeys) {
                        const orgUser = this.selectedOrg.orgUsers[userKey];
                        if (this.selectedConfig?.key && orgUser?.targets && orgUser?.targets[this.selectedConfig.key] && (repKeys?.length === 0 || repKeys.includes(orgUser.statistics[this.selectedConfig.key]?.repNo))) {
                            this.target += orgUser?.targets[this.selectedConfig.key].dailyTarget;
                        }
                    }

            } else {
                // Otherwise, hide targets.
                this.target = 0;
            }
        }

        // If target is a negative number, set it to 0.
        if (this.target < 0) this.target = 0;
    }

    public previousDate() {
        this.dateForData = moment(this.dateForData).subtract(1, 'day').toDate();
        this.onDateChange();
    }

    public nextDate() {
        this.dateForData = moment(this.dateForData).add(1, 'day').toDate();
        this.onDateChange();
    }

    public onGraphStatusChange(status: GraphStatus) {
        // console.log('onGraphStatusChange > status: ', status);
    }

    public onClickEntry(entries: any[]) {
        if (this.showEmbeddedEntryOnClick) {
            this.selectedEntries = entries;
        }

        this.onClickedEntry.emit(entries);
    }

    public onExpandToggle(): void {
        this.isExpanded = !this.isExpanded;

        this.updateYExtent();
    }

    public onChangeShowConsolidatedHours(): void {
        this.updateFromAndToDate();
        // this.prepareGraphData(this.dayData, this.openSalesOrders || []);
        this.processNewSummaryData();
    }

    public onDateChange() {
        this.updateFromAndToDate();
        this.clearCurrentDataAndSelectedEntries();
        if (this.dateForData) this.dateChange$.next(this.dateForData);
    }

    private clearCurrentDataAndSelectedEntries() {
        this.dayData = [];
        this.selectedEntries = [];
        this.onClickedEntry.emit([]);

    }

    private prepareEmptyGraph() {
        this.showSpinner = true;

        // set scale domains
        this.yExtent = d3.extent([0, 100000]) as [number, number]; // Set default to 0-100k.
        this.setTargetForGraph(this.repKeys.value); // Then set targets if the data is available.
        let xExtent = d3.extent([this.workingDayStart, this.workingDayEnd]);
        if (!xExtent[0] || !xExtent[1]) {
            xExtent = [this.workingDayStart, this.workingDayEnd];
        }

        if (this.consolidateData) {
            this.xExtent = [this.workingDayStart, this.workingDayEnd];
        } else {
            this.xExtent = xExtent;
        }

        // Calculate xTicks.
        this.xTicks = 10;

        this.graphData = [];
        this.dayData = [];

        this.showSpinner = false;
    }

    private prepareGraphData(data: Array<NewgenusSalesTransaction>, openSalesOrders: NewgenusSalesTransactionSalesOrder[]) {
        // console.clear();
        // console.log('\n');
        this.showSpinner = true;

        // Sort the data based on date objects.
        data.sort((a, b) => { return (<any>a).date - (<any>b).date });

        let consolidatedFromEndOfYesterdayToStartOfToday: Array<NewgenusSalesTransaction> = [];
        const workingDayStart = this.workingDayStart.getTime();
        const workingDayEnd = this.workingDayEnd.getTime();
        const workingDayEndYesterday = this.workingDayEndYesterday.getTime();

        if (this.consolidateData) {
            // Consolidated = sum of all sales between yesterday's working day end and today's working day start.
            consolidatedFromEndOfYesterdayToStartOfToday = data.filter(c => c.date.getTime() > workingDayEndYesterday && c.date.getTime() <= workingDayStart);

            // Data = sum of all sales between today's working day start and today's working day end.
            data = data.filter(c => c.date.getTime() > workingDayStart && c.date.getTime() < workingDayEnd);
        } else {
            // Filter dates to show between mid-Night 00:00 and mid-Night 23:59.
            data = data.filter(x => x.date.getTime() >= workingDayStart && x.date.getTime() <= workingDayEnd);
        }
        // console.log('prepareGraphData > consolidatedFromEndOfYesterdayToStartOfToday: ', consolidatedFromEndOfYesterdayToStartOfToday);
        // console.log('prepareGraphData > consolidatedFromEndOfYesterdayToStartOfToday invoices: ', consolidatedFromEndOfYesterdayToStartOfToday.filter(isSalesTransactionInvoice));

        // const invoiceNettConsolidated = consolidatedFromEndOfYesterdayToStartOfToday.filter(isSalesTransactionInvoice).reduce((a, b) => {
        //   if (b.invoiceType == 'I') {
        //     return a + b.nett;
        //   } else {
        //     return a - b.nett;
        //   }
        // }, 0);
        // console.log('prepareGraphData > invoiceNettConsolidated: ', invoiceNettConsolidated);


        // Set the valueCompounded property of each entry.
        data = this.compoundDataValuesAndDifferentLines(data, openSalesOrders, consolidatedFromEndOfYesterdayToStartOfToday);
        // console.log('data:', data);

        // console.log('Data compounded:', data)
        if (data?.length > 0) {
            // The graphData is already sorted, add the consolidated data to the top of the array.
            // if (consolidated?.length > 0) {
            //   // const consolidatedEntry: GraphDataConsolidated = {
            //   const consolidatedEntry: any = {
            //     date: moment(workingDayStart).add(10, 'millisecond').toDate(), // Adding 10 milliseconds will render the consolidated entry after the zero based entry.
            //     data: consolidated,
            //     key: '',
            //     value: 0,
            //     valueCompounded: consolidated[consolidated.length - 1].valueCompounded, // The last entry will have the accurate valueCompounded.
            //     repKey: 0,
            //     gross: 0,
            //     nett: 0,
            //     sales: 0,
            //   };
            //   data.unshift(consolidatedEntry);
            // }

            // The zeroBasedEntry allows the first line to plot from 0 to the destined value.
            const zeroBasedEntry: any = {
                ...this.zeroBasedComplexGraphData,
                date: data[0].date,
                key: 'zero-based-entry',
                value: 0,
                repKey: 0,
                gross: 0,
                nett: 0,
                sales: 0,
            };
            data.unshift(zeroBasedEntry);
            this.graphDataSalesOrders.unshift(zeroBasedEntry);
            this.graphDataInvoices.unshift(zeroBasedEntry);
        }

        // set scale domains
        this.updateYExtent(data);

        let xExtent = d3.extent(data, d => new Date(d.date));
        if (!xExtent[0] || !xExtent[1]) {
            xExtent = [this.workingDayStart, this.workingDayEnd];
        }

        if (this.consolidateData) {
            this.xExtent = [this.workingDayStart, this.workingDayEnd];
        } else {
            this.xExtent = xExtent;
        }
        // Add a small amount of time to each end, granting a small margin on the edge of the graph.
        const xExtentMargin5Minutes: [Date, Date] = [moment(this.xExtent[0]).subtract(5, 'minutes').toDate(), moment(this.xExtent[1]).add(5, 'minutes').toDate()]
        this.xExtent = xExtentMargin5Minutes;

        // Calculate xTicks.
        this.xTicks = 10;
        const diff = moment(this.xExtent[1]).diff(this.xExtent[0], 'hours', true).toFixed(0);
        this.xTicks = +diff;

        this.graphData = [];
        this.graphData = data;

        this.showSpinner = false;
    };

    private compoundDataValuesAndDifferentLines(salesOrdersAndInvoices: Array<NewgenusSalesTransaction>, openSalesOrdersWithPossibleDuplicates: NewgenusSalesTransactionSalesOrder[], consolidatedData: NewgenusSalesTransaction[]): Array<ComplexGraphData> {
        const isDateSelectedTheCurrentDateToday = moment(this.dateForData).isSame(moment(), 'day') && moment().isBefore(moment(this.workingDayEnd));
        let results: ComplexGraphData[] = [];

        // Remove superseded data.
        const supersededRemoved = salesOrdersAndInvoices.filter(z => !z.isSuperseded); // Remove superseded data.

        // Remove duplicates from sales orders by "SAL number"/referenceKey.
        const salesOrdersWithPossibleDuplicates = supersededRemoved.filter(isSalesTransactionSalesOrder);
        // Grouped all sales orders that matter, dropping sales orders that are duplicated. 
        const distinctSalesOrders: NewgenusSalesTransactionSalesOrder[] = [];
        for (let i = 0; i < salesOrdersWithPossibleDuplicates.length; i++) {
            const salesOrder = salesOrdersWithPossibleDuplicates[i];
            if (distinctSalesOrders.findIndex(so => so.referenceKey == salesOrder.referenceKey) == -1) {
                distinctSalesOrders.push(salesOrder);
            }
        }

        // Get all invoices from the sales-transactions.
        const invoicesWithPossibleDuplicates = supersededRemoved.filter(isSalesTransactionInvoice).filter(i => (i.invoiceType == 'I' || i.invoiceType == 'C')).sort((a, b) => a.date > b.date ? 1 : -1);
        let invoices: NewgenusSalesTransactionInvoice[] = [];
        for (let i = 0; i < invoicesWithPossibleDuplicates.length; i++) {
            const invoice = invoicesWithPossibleDuplicates[i];
            if (invoices.findIndex(inv => inv.key == invoice.key) == -1) {
                invoices.push(invoice);
            }
        }

        // Update the 'reference' key of credit notes to match the sales order (they match the invoice by default).
        invoices = invoices.map(crnInv => {
            // let result = crnInv;
            if (crnInv.invoiceType == 'C') {
                const salesOrder = invoices.find(inv => crnInv.referenceKey == inv.document);
                if (salesOrder) {
                    crnInv = parseEmbeddedTimestampsToFirebaseDate(deepCopy(crnInv));
                    crnInv.date = new Date(crnInv.date);
                    crnInv.referenceKey = salesOrder.referenceKey;
                }
            }
            return crnInv;
        });

        // const invoiceNett = invoices.reduce((acc, inv) => {
        //     if (inv.invoiceType == 'I') return acc + inv.nett;
        //     else return acc - inv.nett;
        // }, 0);

        // console.log('distinctSalesOrders.length:', distinctSalesOrders.length);
        // console.log('invoices.length:', invoices.length);
        // console.log('\n', new Date().toISOString());
        // console.log('invoiceNett', invoiceNett.toFixed(2));

        // const salesOrderNett = distinctSalesOrders.reduce((acc, so) => acc + so.nett, 0);
        // console.log('salesOrderNett', salesOrderNett.toFixed(2));


        if (!isDateSelectedTheCurrentDateToday) {
            results = this.compoundForPastDate(distinctSalesOrders, invoices);
        } else {
            const dateForData = moment(this.dateForData);

            // Remove duplicates from OPEN sales orders by "SAL number"/referenceKey.
            const distinctOpenSalesOrders: NewgenusSalesTransactionSalesOrder[] = [];
            const addedSalesOrders = [];
            for (let i = 0; i < openSalesOrdersWithPossibleDuplicates.length; i++) {
                const saleOrder = openSalesOrdersWithPossibleDuplicates[i];
                if (
                    // Check that the sales order is not already in the distinctOpenSalesOrders array.
                    distinctOpenSalesOrders.findIndex(so => so.referenceKey == saleOrder.referenceKey) == -1
                    // Check that if it's NOT listed, that it has not been revised and listed with a different(updated) referenceKey.
                    && distinctOpenSalesOrders.findIndex(so => so.document == saleOrder.referenceKey) == -1

                ) {
                    distinctOpenSalesOrders.push(saleOrder);
                }

                // Append open sales orders that haven't reflected in salesOrders (sales-transactions) yet.
                if (distinctSalesOrders.findIndex(so => so.referenceKey == saleOrder.referenceKey) == -1 && moment(saleOrder.date).isSame(dateForData, 'day')) {
                    addedSalesOrders.push(saleOrder);
                }
            }
            // const openSalesOrdersNett = distinctOpenSalesOrders.reduce((acc, so) => acc + so.nett, 0);
            // console.log('openSalesOrdersNett', openSalesOrdersNett.toFixed(2));
            // console.log('graphHead should be: ', (invoiceNett + openSalesOrdersNett).toFixed(2));
            distinctSalesOrders.push(...addedSalesOrders);


            // Use the open sales order if it's still open, this is a fix to curb IQ having an error they haven't fixed yet. The error causes a sales order to have a super high value, it then manually gets fixed but our data is then corrupted, the open sales orders would be the now uncorrupted and recently fixed version of the data.
            distinctOpenSalesOrders.forEach(salesOrder => {
                const index = distinctSalesOrders.findIndex(so => so.referenceKey == salesOrder.referenceKey);
                if (index != -1) {
                    distinctSalesOrders[index] = salesOrder;
                }
            });

            // console.log('addedSalesOrders:', addedSalesOrders);
            // console.log('distinctOpenSalesOrders.length:', distinctOpenSalesOrders.length);

            // Filter the open sales orders that are carried over from the previous day(s).
            const openSalesOrdersCarriedOver: NewgenusSalesTransactionSalesOrder[] = distinctOpenSalesOrders.filter(salesOrder => {
                if (
                    // We only care about sales orders that are not superseded.
                    !salesOrder.isSuperseded
                    // Filter that it's not the same date as the dateForData.
                    && !moment(salesOrder.date).isSame(dateForData, 'day')
                    // Check that the sales order is not already in the distinctOpenSalesOrders array.
                    && distinctSalesOrders.findIndex(so => so.referenceKey == salesOrder.referenceKey) == -1
                ) return true;

                return false;
            });

            const invoicesMatchedToCarriedOverSalesOrders = invoices.filter(x => x.invoiceType != 'C' && openSalesOrdersCarriedOver.some(y => y.referenceKey == x.referenceKey));

            const invoicedTodayForCarriedOverSalesOrders = invoicesMatchedToCarriedOverSalesOrders
                .map(invoice => {

                    const cn = invoice.invoiceType == 'C' ? {
                        creditNoteTax: invoice.tax,
                        creditNoteNett: invoice.nett,
                        creditNoteGross: invoice.gross,
                    } : { creditNoteTax: 0, creditNoteNett: 0, creditNoteGross: 0 };

                    const inv = invoice.invoiceType == 'I' ? {
                        invoicedGross: invoice.gross,
                        invoicedNett: invoice.nett,
                        invoicedTax: invoice.tax,
                    } : { invoicedGross: 0, invoicedNett: 0, invoicedTax: 0 };


                    return {
                        ...this.zeroBasedComplexGraphData,
                        ...invoice,
                        isRevisedSalesOrder: false,
                        ...cn,
                        ...inv,
                    };
                });

            const invoicesNotInSalesOrders = invoices
                .filter(inv => !distinctSalesOrders.some(salesOrder => salesOrder.referenceKey == inv.referenceKey)
                    // .filter(inv => !invoicesOnlyInSalesOrders.some(inv => inv.referenceKey == inv.referenceKey)
                    && !invoicedTodayForCarriedOverSalesOrders.some(soCarriedOver => soCarriedOver.key == inv.key))
                .map(invoice => {
                    const cn = invoice.invoiceType == 'C' ? {
                        creditNoteTax: invoice.tax,
                        creditNoteNett: invoice.nett,
                        creditNoteGross: invoice.gross,
                    } : { creditNoteTax: 0, creditNoteNett: 0, creditNoteGross: 0 };

                    const inv = invoice.invoiceType == 'I' ? {
                        invoicedGross: invoice.gross,
                        invoicedNett: invoice.nett,
                        invoicedTax: invoice.tax,
                    } : { invoicedGross: 0, invoicedNett: 0, invoicedTax: 0 };


                    return {
                        // Don't adjust the order if you are not sure what you are doing.
                        ...this.zeroBasedComplexGraphData,
                        ...invoice,
                        ...cn,
                        ...inv,
                    };
                });

            const sOCarriedOverInvoiced = openSalesOrdersCarriedOver.map(salesOrder => {
                const invoiced = invoices.filter(x => x.referenceKey == salesOrder.referenceKey);

                const cn = invoiced.filter(i => i.invoiceType == 'C')
                    .reduce((a, b) => {
                        return {
                            creditNoteTax: a.creditNoteTax + b.tax,
                            creditNoteNett: a.creditNoteNett + b.nett,
                            creditNoteGross: a.creditNoteGross + b.gross,
                        };
                    }, { creditNoteTax: 0, creditNoteNett: 0, creditNoteGross: 0 });

                const inv = invoiced.filter(i => i.invoiceType == 'I')
                    .reduce((a, b) => {
                        return {
                            invoicedTax: a.invoicedTax + b.tax,
                            invoicedNett: a.invoicedNett + b.nett,
                            invoicedGross: a.invoicedGross + b.gross,
                        };
                    }, { invoicedGross: 0, invoicedNett: 0, invoicedTax: 0 });

                return {
                    ...this.zeroBasedComplexGraphData,
                    ...salesOrder,
                    invoiceType: '',
                    ...inv,
                    ...cn,
                    nettOutstanding: salesOrder.nett - inv.invoicedNett - cn.creditNoteNett,
                };
            });

            // Reduce sOCarriedOverInvoiced to a single object.
            const sOCarriedOverInvoicedReduced: ConsolidatedComplexGraphData = sOCarriedOverInvoiced.concat(invoicedTodayForCarriedOverSalesOrders).reduce((a, b) => {
                return {
                    ...a,
                    invoicedGross: a.invoicedGross + b.invoicedGross,
                    invoicedNett: a.invoicedNett + b.invoicedNett,
                    invoicedTax: a.invoicedTax + b.invoicedTax,
                    nettOutstanding: a.nettOutstanding + b.nettOutstanding,
                    salNumbers: a.salNumbers.concat(b.referenceKey),
                    nett: a.nett + b.nett,
                };
            }, { ...this.zeroBasedComplexGraphData, nett: 0, date: new Date(), salNumbers: Array<string>(), key: 'carried-over', gross: 0, tax: 0, type: NewgenusSalesTransactionType['carried-over'], userKey: '', referenceKey: '', isSuperseded: false, document: '' });
            sOCarriedOverInvoicedReduced.date = moment(this.workingDayStart).add(2, 'seconds').toDate();

            const sOInvoiced: ComplexGraphData[] = distinctSalesOrders.map(salesOrder => {
                const invoiced = invoices.filter(x => x.referenceKey == salesOrder.referenceKey);

                const cn = invoiced.filter(i => i.invoiceType == 'C')
                    .reduce((a, b) => {
                        return {
                            creditNoteTax: a.creditNoteTax + b.tax,
                            creditNoteNett: a.creditNoteNett + b.nett,
                            creditNoteGross: a.creditNoteGross + b.gross,
                        };
                    }, { creditNoteTax: 0, creditNoteNett: 0, creditNoteGross: 0 });

                const inv = invoiced.filter(i => i.invoiceType == 'I')
                    .reduce((a, b) => {
                        return {
                            invoicedTax: a.invoicedTax + b.tax,
                            invoicedNett: a.invoicedNett + b.nett,
                            invoicedGross: a.invoicedGross + b.gross,
                        };
                    }, { invoicedGross: 0, invoicedNett: 0, invoicedTax: 0 });

                // Include the open sale orders only if the the date is within the working day of today.
                const isAnOpenSalesOrder = distinctOpenSalesOrders.some(x => x.referenceKey == salesOrder.referenceKey);

                const body = {
                    ...this.zeroBasedComplexGraphData,
                    ...salesOrder,
                    nettOutstanding: 0,
                    ...cn,
                    ...inv,
                    salesOrderNett: inv.invoicedNett,
                    salesOrderGross: inv.invoicedGross,
                    salesOrderTax: inv.invoicedTax,
                    nett: 0,
                    gross: 0,
                    tax: 0,
                };

                if (isAnOpenSalesOrder) {
                    body.nett = salesOrder.nett;
                    body.gross = salesOrder.gross;
                    body.tax = salesOrder.tax;
                    body.salesOrderNett = salesOrder.nett;
                    body.salesOrderGross = salesOrder.gross;
                    body.salesOrderTax = salesOrder.tax;
                    body.nettOutstanding = salesOrder.nett;
                }

                return body;
            });

            // const duplicateSalesOrders = distinctSalesOrders.filter(salesOrder => distinctOpenSalesOrders.some(x => x.referenceKey == salesOrder.referenceKey));
            // const duplicateOpenSalesOrders = distinctOpenSalesOrders.filter(salesOrder => distinctSalesOrders.some(x => x.referenceKey == salesOrder.referenceKey));

            // console.log('duplicateSalesOrders:', duplicateSalesOrders)
            // console.log('duplicateOpenSalesOrders:', duplicateOpenSalesOrders)
            // console.log('duplicateSalesOrders nett:', duplicateSalesOrders.reduce((a, b) => a + b.nett, 0));
            // console.log('duplicateOpenSalesOrders nett:', duplicateOpenSalesOrders.reduce((a, b) => a + b.nett, 0));


            // Compound all the data together, to create a single array of data with all the data we need.
            const combined = sOInvoiced
                .concat(sOCarriedOverInvoicedReduced, invoicesNotInSalesOrders)
                // Sort the data by date.
                .sort((a, b) => a.date.getTime() - b.date.getTime());
            this.compoundInvoiceLine(invoices);

            const invoicesMatchedBySalesOrders = invoices.filter(inv => distinctSalesOrders.some(salesOrder => salesOrder.referenceKey == inv.referenceKey));
            this.compoundSalesOrderLine(sOInvoiced, invoicesMatchedBySalesOrders, invoicesMatchedToCarriedOverSalesOrders);

            results = combined
                // Compound the data.
                .map((datum, i) => {

                    if (i == 0) {
                        // The first entry is the "carried over" entry.
                        datum.salesOrdersCompounded = datum.nettOutstanding;
                        datum.invoicedCompounded = datum.invoicedNett - datum.creditNoteNett;
                        datum.valueCompounded = datum.nett - datum.creditNoteNett;
                    } else {
                        const previousEntry = combined[i - 1];

                        // "Yellow line" (Invoices only)
                        if (datum.type == NewgenusSalesTransactionType.invoice) {
                            datum.salesOrdersCompounded = 0; // Invoices don't have data on sales orders, setting this to 0 will allow no changes there.
                            datum.invoicedCompounded = datum.invoicedNett - datum.creditNoteNett; // Add to invoice compound.
                            datum.valueCompounded = datum.invoicedNett - datum.creditNoteNett; // Add to invoice compound.
                        }

                        // "Red line" (SOs only)
                        else if (datum.type == NewgenusSalesTransactionType.salesOrder) {
                            datum.valueCompounded = datum.nettOutstanding + datum.invoicedNett - datum.creditNoteNett;
                            datum.invoicedCompounded = datum.invoicedNett - datum.creditNoteNett; // SalesOrders have information of invoices placed on them, append this to the invoicedCompound.
                            datum.salesOrdersCompounded = datum.nettOutstanding; // Add to sales order compound.
                        }

                        datum.value = datum.valueCompounded;

                        // "Blue line" (SOs + Invoices, or otherwise said, "total")
                        datum.invoicedCompounded += previousEntry.invoicedCompounded; // "Blue line"
                        datum.salesOrdersCompounded += previousEntry.salesOrdersCompounded; // "Red line"
                        datum.valueCompounded += previousEntry.valueCompounded; // "Blue line"
                    }

                    datum.repKey = +(datum.userKey + '');

                    return datum;
                });
        }


        // console.log('results:', results);
        return results;
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    private compoundForPastDate(distinctSalesOrders: NewgenusSalesTransactionSalesOrder[], invoices: NewgenusSalesTransactionInvoice[]) {
        const dateForData = moment(this.dateForData);

        const invoicesNotMatchedToSalesOrders = invoices.filter(x => !distinctSalesOrders.some(y => y.referenceKey == x.referenceKey));
        const openSalesOrdersCarriedOver = distinctSalesOrders.filter(so => {
            return moment(so.date).isBefore(dateForData, 'day') // && invoices.some(inv => inv.referenceKey == so.referenceKey && moment(inv.date).isSame(dateForData, 'day');
        });
        const invoicesMatchedToCarriedOverSalesOrders = invoices.filter(x => x.invoiceType != 'C' && openSalesOrdersCarriedOver.some(y => y.referenceKey == x.referenceKey));
        const invoicedTodayForCarriedOverSalesOrders = invoicesMatchedToCarriedOverSalesOrders
            .map(invoice => {

                const cn = invoice.invoiceType == 'C' ? {
                    creditNoteTax: invoice.tax,
                    creditNoteNett: invoice.nett,
                    creditNoteGross: invoice.gross,
                } : { creditNoteTax: 0, creditNoteNett: 0, creditNoteGross: 0 };

                const inv = invoice.invoiceType == 'I' ? {
                    invoicedGross: invoice.gross,
                    invoicedNett: invoice.nett,
                    invoicedTax: invoice.tax,
                } : { invoicedGross: 0, invoicedNett: 0, invoicedTax: 0 };


                return {
                    ...this.zeroBasedComplexGraphData,
                    ...invoice,
                    isRevisedSalesOrder: false,
                    ...cn,
                    ...inv,
                };
            });

        const invoicesNotInSalesOrders = invoices
            .filter(inv => !distinctSalesOrders.some(salesOrder => salesOrder.referenceKey == inv.referenceKey)
                // .filter(inv => !invoicesOnlyInSalesOrders.some(inv => inv.referenceKey == inv.referenceKey)
                && !invoicedTodayForCarriedOverSalesOrders.some(soCarriedOver => soCarriedOver.key == inv.key))
            .map(invoice => {
                const cn = invoice.invoiceType == 'C' ? {
                    creditNoteTax: invoice.tax,
                    creditNoteNett: invoice.nett,
                    creditNoteGross: invoice.gross,
                } : { creditNoteTax: 0, creditNoteNett: 0, creditNoteGross: 0 };

                const inv = invoice.invoiceType == 'I' ? {
                    invoicedGross: invoice.gross,
                    invoicedNett: invoice.nett,
                    invoicedTax: invoice.tax,
                } : { invoicedGross: 0, invoicedNett: 0, invoicedTax: 0 };


                return {
                    // Don't adjust the order if you are not sure what you are doing.
                    ...this.zeroBasedComplexGraphData,
                    ...invoice,
                    ...cn,
                    ...inv,
                };
            });

        const sOCarriedOverInvoiced = openSalesOrdersCarriedOver.map(salesOrder => {
            const invoiced = invoices
                .filter(x => x.referenceKey == salesOrder.referenceKey)
                .reduce((a, b) => {

                    return {
                        gross: a.gross + b.gross,
                        nett: a.nett + b.nett,
                        tax: a.tax + b.tax
                    };
                }, { gross: 0, nett: 0, tax: 0 });

            return {
                ...this.zeroBasedComplexGraphData,
                ...salesOrder,
                invoiceType: '',
                invoicedGross: invoiced.gross,
                invoicedNett: invoiced.nett,
                invoicedTax: invoiced.tax,
                nettOutstanding: salesOrder.nett - invoiced.nett,
                salesOrdersCompounded: 0,
                invoicedCompounded: 0,
                valueCompounded: 0,
            };
        });

        // Reduce sOCarriedOverInvoiced to a single object.
        const sOCarriedOverInvoicedReduced: ConsolidatedComplexGraphData = sOCarriedOverInvoiced.concat(invoicedTodayForCarriedOverSalesOrders).reduce((a, b) => {
            return {
                ...a,
                salesOrderNett: a.salesOrderNett + b.salesOrderNett,
                salesOrderGross: a.salesOrderGross + b.salesOrderGross,
                salesOrderTax: a.salesOrderTax + b.salesOrderTax,
                invoicedGross: a.invoicedGross + b.invoicedGross,
                invoicedNett: a.invoicedNett + b.invoicedNett,
                invoicedTax: a.invoicedTax + b.invoicedTax,
                nettOutstanding: a.nettOutstanding + b.nettOutstanding,
                salNumbers: a.salNumbers.concat(b.referenceKey),
                nett: a.nett + b.nett,
            };
        }, { ...this.zeroBasedComplexGraphData, nett: 0, date: new Date(), salNumbers: Array<string>(), key: 'carried-over', gross: 0, tax: 0, type: NewgenusSalesTransactionType['carried-over'], userKey: '', referenceKey: '', isSuperseded: false, document: '' });
        sOCarriedOverInvoicedReduced.date = moment(this.workingDayStart).add(2, 'seconds').toDate();


        const sOInvoiced: ComplexGraphData[] = distinctSalesOrders.map(salesOrder => {
            const invoiced = invoices.filter(x => x.referenceKey == salesOrder.referenceKey);

            const cn = invoiced.filter(i => i.invoiceType == 'C')
                .reduce((a, b) => {
                    return {
                        creditNoteTax: a.creditNoteTax + b.tax,
                        creditNoteNett: a.creditNoteNett + b.nett,
                        creditNoteGross: a.creditNoteGross + b.gross,
                    };
                }, { creditNoteTax: 0, creditNoteNett: 0, creditNoteGross: 0 });

            const inv = invoiced.filter(i => i.invoiceType == 'I')
                .reduce((a, b) => {
                    return {
                        invoicedTax: a.invoicedTax + b.tax,
                        invoicedNett: a.invoicedNett + b.nett,
                        invoicedGross: a.invoicedGross + b.gross,
                    };
                }, { invoicedGross: 0, invoicedNett: 0, invoicedTax: 0 });

            // Include the open sale orders only if the the date is within the working day of today.
            const body = {
                ...this.zeroBasedComplexGraphData,
                ...salesOrder,
                nettOutstanding: 0,
                ...cn,
                ...inv,
                // salesOrderNett: salesOrder.nett > 0 ? salesOrder.nett : inv.invoicedNett, // TODO test if ok and should not rather stay at 0.
                // salesOrderGross: salesOrder.gross > 0 ? salesOrder.gross : inv.invoicedGross,
                // salesOrderTax: salesOrder.tax > 0 ? salesOrder.tax : inv.invoicedTax,
                salesOrderNett: inv.invoicedNett, // TODO test if ok and should not rather stay at salesOrder.X
                salesOrderGross: inv.invoicedGross,
                salesOrderTax: inv.invoicedTax,
                nett: 0,
                gross: 0,
                tax: 0,
            };

            return body;
        });

        // Compound all the data together, to create a single array of data with all the data we need.
        const combined = sOInvoiced
            .concat(sOCarriedOverInvoicedReduced, invoicesNotInSalesOrders)
            // Sort the data by date.
            .sort((a, b) => a.date.getTime() - b.date.getTime());
        this.compoundInvoiceLine(invoices);
        const invoicesMatchedBySalesOrders = invoices.filter(inv => distinctSalesOrders.some(salesOrder => salesOrder.referenceKey == inv.referenceKey));
        this.compoundSalesOrderLine(sOInvoiced, invoicesMatchedBySalesOrders, invoicesNotMatchedToSalesOrders);

        const results = combined
            // Compound the data.
            .map((datum, i) => {

                if (i == 0) {
                    // The first entry is the "carried over" entry.
                    datum.salesOrdersCompounded = datum.nett;
                    datum.invoicedCompounded = datum.invoicedNett - datum.creditNoteNett;
                    datum.valueCompounded = datum.nett - datum.creditNoteNett;
                } else {
                    const previousEntry = combined[i - 1];

                    // "Black line" (Invoices only)
                    if (datum.type == NewgenusSalesTransactionType.invoice) {
                        datum.salesOrdersCompounded = 0;

                        if ((<any>datum).invoiceType == 'I') {
                            datum.invoicedCompounded = datum.invoicedNett - datum.creditNoteNett; // Add to invoice compound.
                            datum.valueCompounded = datum.invoicedNett - datum.creditNoteNett; // Add to invoice compound.

                        } else if ((<any>datum).invoiceType == 'C') {
                            datum.invoicedCompounded = datum.invoicedNett - datum.creditNoteNett; // Add to invoice compound.
                            datum.valueCompounded = datum.invoicedNett - datum.creditNoteNett; // Add to invoice compound.

                        } else {
                            console.warn('Unknown invoice type', (<any>datum).invoiceType, datum);
                        }

                    }

                    // "Red line" (SOs only)
                    else if (datum.type == NewgenusSalesTransactionType.salesOrder) {
                        datum.valueCompounded = datum.nettOutstanding + datum.invoicedNett - datum.creditNoteNett;
                        datum.invoicedCompounded = datum.invoicedNett - datum.creditNoteNett;
                        datum.salesOrdersCompounded = datum.nettOutstanding; // Add to sales order compound.
                    }

                    datum.value = datum.valueCompounded;

                    // "Blue line" (SOs + Invoices, or otherwise said, "total")
                    datum.valueCompounded += previousEntry.valueCompounded; // "Blue line"
                    datum.invoicedCompounded += previousEntry.invoicedCompounded;// "Black line"
                    datum.salesOrdersCompounded += previousEntry.salesOrdersCompounded; // "Red line"
                }

                datum.repKey = +(datum.userKey + '')

                return datum;
            });
        return results;
    }

    private compoundSalesOrderLine(sOInvoiced: ComplexGraphData[], invoices: NewgenusSalesTransactionInvoice[], invoicesMatchedToCarriedOverSalesOrders: NewgenusSalesTransactionInvoice[]) {
        const soCompound = deepCopy(sOInvoiced.concat(invoices.filter(inv => {
            return !invoicesMatchedToCarriedOverSalesOrders.some(x => x.referenceKey == inv.referenceKey);
        }) as any[]))
            .map(x => {
                x.date = new Date(x.date);
                const newDataBody = {
                    ...this.zeroBasedComplexGraphData,
                    ...x,
                };

                if (x.type == NewgenusSalesTransactionType.salesOrder) {
                    newDataBody.salesOrderNett = x.salesOrderNett || 0;
                    newDataBody.salesOrderGross = x.salesOrderGross || 0;
                    newDataBody.salesOrderTax = x.salesOrderTax || 0;

                    newDataBody.salesOrdersCompounded = newDataBody.salesOrderNett;
                } else if (x.type == NewgenusSalesTransactionType.invoice) {
                    if ((x as any).invoiceType == 'I') {
                        newDataBody.invoicedNett = x.nett || 0;
                        newDataBody.invoicedGross = x.gross || 0;
                        newDataBody.invoicedTax = x.tax || 0;
                    } else {
                        newDataBody.creditNoteNett = x.nett || 0;
                        newDataBody.creditNoteGross = x.gross || 0;
                        newDataBody.creditNoteTax = x.tax || 0;
                    }

                    newDataBody.salesOrdersCompounded = -Math.abs(newDataBody.invoicedNett - newDataBody.creditNoteNett);
                }

                newDataBody.value = newDataBody.salesOrdersCompounded;
                newDataBody.repKey = +(newDataBody.userKey + '');

                return newDataBody;
            })
            .sort((a, b) => a.date.getTime() - b.date.getTime());

        this.graphDataSalesOrders = soCompound.map((so, i) => {
            if (i > 0) so.salesOrdersCompounded += soCompound[i - 1].salesOrdersCompounded;
            if (i == soCompound.length - 1) so.salesOrdersCompounded = Number(so.salesOrdersCompounded.toFixed(2));
            so.valueCompounded = so.salesOrdersCompounded;
            return so;
        });
    }

    private compoundInvoiceLine(invoices: NewgenusSalesTransactionInvoice[]) {
        const invCompound = deepCopy(invoices)
            .map(inv => {
                inv.date = new Date(inv.date); // Convert the date (string) to a date object.
                const newDataBody = {
                    ...this.zeroBasedComplexGraphData,
                    ...inv,
                };

                if (inv.invoiceType == 'I') {
                    newDataBody.invoicedNett = inv.nett || 0;
                    newDataBody.invoicedGross = inv.gross || 0;
                    newDataBody.invoicedTax = inv.tax || 0;
                } else {
                    newDataBody.creditNoteNett = inv.nett || 0;
                    newDataBody.creditNoteGross = inv.gross || 0;
                    newDataBody.creditNoteTax = inv.tax || 0;
                }

                newDataBody.invoicedCompounded = newDataBody.invoicedNett - newDataBody.creditNoteNett;
                newDataBody.value = newDataBody.invoicedCompounded;
                newDataBody.repKey = +(newDataBody.userKey + '');

                return newDataBody;
            });

        this.graphDataInvoices = invCompound.map((inv, i) => {
            if (i > 0) inv.invoicedCompounded += invCompound[i - 1].invoicedCompounded;
            if (i == invCompound.length - 1) inv.invoicedCompounded = Number(inv.invoicedCompounded.toFixed(2));
            inv.valueCompounded = inv.invoicedCompounded;
            return inv;
        });
    }

    private updateYExtent(data?: NewgenusSalesTransaction[]): void {

        const primaryExtent = d3.extent((data || this.graphData), (d: NewgenusSalesTransaction) => d.valueCompounded) as [number, number];
        let yExtendMargin10Percent: [number, number] = [0, 100000];

        if (this.isExpanded) {
            const salesOrdersYExtent = d3.extent(this.graphDataSalesOrders, (d: NewgenusSalesTransaction) => d.valueCompounded) as [number, number];
            const invoicesYExtent = d3.extent(this.graphDataInvoices, (d: NewgenusSalesTransaction) => d.valueCompounded) as [number, number];

            const orZero = (value: number) => value !== undefined && !isNaN(value) ? value : 0;
            const yMin = Math.min(orZero(primaryExtent[0]), orZero(salesOrdersYExtent[0]), orZero(invoicesYExtent[0]));
            const yMax = Math.max(orZero(primaryExtent[1]), orZero(salesOrdersYExtent[1]), orZero(invoicesYExtent[1]), orZero(this.target));

            // yExtendMargin10Percent = [yMin / 0.9, yMax * 1.1];
            yExtendMargin10Percent = [
                yMin > 0 ? yMin / 1.1 : yMin * 0.9,
                yMax > 0 ? yMax * 1.1 : yMax / 0.9,
            ];
        } else {
            // yExtendMargin10Percent = [primaryExtent[0] / 0.9, primaryExtent[1] * 1.1];
            yExtendMargin10Percent = [
                primaryExtent[0] > 0 ? primaryExtent[0] / 1.1 : primaryExtent[0] * 0.9,
                primaryExtent[1] > 0 ? primaryExtent[1] * 1.1 : primaryExtent[1] / 0.9,
            ];
        }

        this.yExtent = yExtendMargin10Percent;
    }

    private updateFromAndToDate(): void {
        // Avoid processing if dateForData or dateSettings is not defined.
        if (!this.dateForData || !this.dateSettings) return;

        const result = getCompanyMonthStartAndEndByDateSettings(this.dateForData, this.dateSettings);

        if (!this.consolidateData) {
            this.fromDate = result.monthStart.startOf('day').toDate();
            this.toDate = result.monthEnd.endOf('day').toDate();
        } else {
            this.fromDate = result.monthStart.local().toDate();
            this.toDate = result.monthEnd.local().toDate();
        }

        // fromDate is the beginning of the month at the start of the working day (hour) - adjust it to the current date.
        this.fromDate = moment(this.fromDate).set('date', moment(this.dateForData).get('date')).toDate();
        // toDate is the end of the month at the end of the working day (hour) - adjust it to the current date.
        this.toDate = moment(this.toDate).set('date', moment(this.dateForData).get('date')).toDate();

        this.workingDayStart = moment(this.dateForData).startOf('d').set('hour', this.fromDate?.getHours() || 0).toDate(); // Bind to timezone of from/to dates.
        this.workingDayEnd = moment(this.dateForData).startOf('d').set('hour', this.toDate?.getHours() || 23).toDate(); // Bind to timezone of from/to dates.
        this.workingDayEndYesterday = moment(this.dateForData).startOf('d').subtract(1, 'day').set('hour', this.dateSettings?.hourOfDayEnd || 23).toDate();

        if (!this.consolidateData) {
            this.workingDayStart = moment(this.workingDayStart).startOf('d').toDate();
            this.workingDayEnd = moment(this.workingDayEnd).endOf('d').toDate();
        }

        this.fromDateChange.emit(this.fromDate);
        this.toDateChange.emit(this.toDate);
    }

    public toOrdinalString(str: number | undefined): string {
        return toOrdinalString(str);
    }

    public toRepName(repKey: string | number): string {
        return this.repNames[+repKey] ? this.repNames[+repKey] : '<i>Unavailable</i>';
    }

    public ngOnChanges(changes: SimpleChanges): void {
        // console.log('daily7r > changes[parentProperties]:', changes['parentProperties']);
        // console.log('daily7r > changes[parentProps]:', changes['parentProps']);

        if (changes['dateForData'] && !changes['dateForData'].isFirstChange() && this.selectedOrg) {
            this.onDateChange();
        }

        if (changes['selectedOrg']) {
            setTimeout(() => {
                // Set the dateSettings if they are not set.
                if (!this.dateSettings && this.selectedOrg) {
                    this.dateSettings = this.selectedOrg.settings;
                }

                if (this.dateSettings) {
                    // Capture consolidateData setting.
                    this.consolidateData = this.dateSettings.consolidateHours;
                }

                // Set the date range for the data and graph - does emit update on dateChanges$.
                this.updateFromAndToDate();
            });
        }

        if (changes['dateSettings']) {
            setTimeout(() => { this.onDateChange(); });
        }

        if (changes['selectedConfig']) {
            setTimeout(() => {
                // Reset local repNames.
                this.repNames = {};

                // Add repNames to local repNames.
                Object.values(this.selectedConfig?.userMapping || {}).forEach(rep => this.repNames[+rep.key] = rep.repName);
            });
        }

        if (changes['summaryData'] || changes['openSalesOrders'] || changes['filtersByCode']) {
            //  console.log('summaryData changed:', changes['summaryData']?.currentValue);
            setTimeout(() => {
                this.processNewSummaryData();
            });
        }
        if (changes['repKeys']) {
            setTimeout(() => {
                this.subscribeToRepKeys();
            });
        }

    }

    private subscribeToRepKeys(): void {
        if (this.hasSubscribedToRepKeys) return;
        this.hasSubscribedToRepKeys = true;

        const currentRepKeysSub = this.repKeys.pipe(
            takeUntil(this.destroy$))
            .subscribe((repKeys) => {
                this.processNewSummaryData();
            });
    }

    private processNewData() {
        // If null is returned, clear the graph.
        if (!this.data || this.data?.length === 0
            && !this.openSalesOrders || this.openSalesOrders?.length === 0) {
            this.dayData = []
            this.target = 0;
            this.prepareEmptyGraph();
            return;
        }

        const data = this.data, openSalesOrders = this.openSalesOrders;
        const repKeys = this.repKeys.value;
        this.setTargetForGraph(repKeys);

        if ((data || openSalesOrders) && repKeys) {
            this.dayData = [];

            // Filter this data to only today and yesterday's date to reduce overhead processing...
            const currentDate = moment(this.dateForData).endOf('d').toDate();
            const yesterdayOfCurrentDate = moment(currentDate).subtract(1, 'day').startOf('d').toDate();
            let newData: NewgenusSalesTransaction[] = data?.filter((c) => moment(c.date).isBetween(yesterdayOfCurrentDate, currentDate)) || [];
            const areAllRepsSelected = (Object.keys(this.selectedConfig?.userMapping || {}).length === repKeys.length);

            // If the user has selected reps, and the number of selected reps is equal to the number of reps in the data, then we can assume that no filtering is required.
            if ((repKeys.length === 0 || areAllRepsSelected) && (this.filtersByCode == null || Object.values(this.filtersByCode).every(x => x === true))) {
                // console.log('No filtering required');
            } else {
                // Filter out data that is not in the selected reps, if any.
                newData = newData.filter((c) => {
                    // let accepted = true;

                    // // For the filter to apply, it must be non-null and at least one of the values must be false.
                    // if (this.filtersByCode && Object.values(this.filtersByCode).some(f => f === false)) {
                    //   if (c.collection === 'salesOrder') {
                    //     accepted = this.filtersByCode['salesOrder-open'];
                    //   } else if (c.collection === 'debtTran') {
                    //     accepted = this.filtersByCode[`debtTran-${c.type}`];
                    //   }
                    // }

                    // if (!accepted) {
                    //   return false;
                    // }

                    // Filter out data that is not in the selected reps, if any.
                    if (repKeys?.length > 0)
                        return repKeys.includes((c.userKey));
                    // If no reps are selected, show all available data.
                    else return true;
                });
            }

            // Filter out sales orders that don't match the selected reps.
            let newSalesOrders = [];
            if (repKeys.length === 0 || areAllRepsSelected) {
                newSalesOrders = openSalesOrders || [];
            } else {
                newSalesOrders = openSalesOrders?.filter((c) => { return repKeys.includes(c.userKey) }) || [];
            }

            this.dayData = [...newData];
            this.openSalesOrders = newSalesOrders;
            // console.log('\n' + new Date().toISOString() + '\nCalling prepare graphdata')
            this.prepareGraphData(this.dayData, this.openSalesOrders);

        } else {
            console.debug('not doing anything...')
        }
    }

    private processNewSummaryData() {
        // If null is returned, clear the graph.
        if (!this.summaryData || this.summaryData?.length === 0) {
            this.dayData = []
            this.target = 0;
            this.prepareEmptyGraph();
            return;
        }
        // console.log('processNewSummaryData > summaryData:', this.summaryData)

        const parsedSummaries = this.summaryData.map(d => parseEmbeddedTimestampsToJavascriptDate(deepCopy(d)));
        // console.log('processNewSummaryData > parsedSummaries:', parsedSummaries)
        let data = parsedSummaries.flatMap(d => d.fullSalesToday.concat(d.fullSalesYesterday)).map(tx => {
            // Deep copy to break NGRX readonly nonesense.
            const parsedCopy = parseEmbeddedTimestampsToJavascriptDate(deepCopy(tx))
            // Set the repKey to the userKey for the existing infrastructure to work.
            parsedCopy.repKey = parsedCopy.userKey;
            return parsedCopy;
        });//.filter(c => c.referenceKey)

        const openSalesOrders = this.openSalesOrders;
        const repKeys = this.repKeys.value;
        this.setTargetForGraph(repKeys);
        // console.log('processNewSummaryData > openSalesOrders:', openSalesOrders)


        if ((data || openSalesOrders) && repKeys) {
            this.dayData = [];
            const areAllRepsSelected = (Object.keys(this.selectedConfig?.userMapping || {}).length === repKeys.length);

            // If the user has selected reps, and the number of selected reps is equal to the number of reps in the data, then we can assume that no filtering is required.
            if ((repKeys.length === 0 || areAllRepsSelected) && (this.filtersByCode === null || Object.values(this.filtersByCode).every(x => x === true))) {
                //  console.log('No filtering required');
            } else {
                // Filter out data that is not in the selected reps, if any.
                data = data.filter((c) => {
                    let accepted = true;

                    // For the filter to apply, it must be non-null and at least one of the values must be false.
                    if (this.filtersByCode && Object.values(this.filtersByCode).some(f => f === false)) {
                        // TODO: currently it always allows sales orders, but this should be configurable.
                        if (c.transactionType !== 'SO') {
                            accepted = this.filtersByCode[`debtTran-${c.transactionType}`];
                        } else if (c.type === 'open_so') {
                            accepted = this.filtersByCode['salesOrder-open'];
                        } else if (c.type === 'sales_o') {
                            accepted = this.filtersByCode['salesOrder-closed'];
                        }
                    }

                    if (!accepted) return false;

                    // Filter out data that is not in the selected reps, if any.
                    if (repKeys?.length > 0 && c.userKey !== undefined)
                        return repKeys.includes(c.userKey + '');

                    // If no reps are selected, show all available data.
                    else return true;
                });
                // console.log('processNewSummaryData > (filtered) data:', data)
            }

            // this.dayData = [...newData];
            // this.openSalesOrders = newSalesOrders;

            this.prepareSummaryGraphData(data);
        } else {
            console.debug('not doing anything...')
        }
    }

    private prepareSummaryGraphData(data: Array<DaySummaryDatum>) {
        this.showSpinner = true;

        // Sort the data based on date objects.
        data.sort((a, b) => { return (<any>a).date - (<any>b).date });

        let consolidatedFromEndOfYesterdayToStartOfToday: Array<DaySummaryDatum> = [];
        const workingDayStart = this.workingDayStart.getTime();
        const workingDayEnd = this.workingDayEnd.getTime();
        const workingDayEndYesterday = this.workingDayEndYesterday.getTime();

        // console.log('workingDayStart:', moment(workingDayStart).format('YYYY-MM-DD HH:mm:ss'));
        // console.log('workingDayEnd:', moment(workingDayEnd).format('YYYY-MM-DD HH:mm:ss'));
        // console.log('workingDayEndYesterday:', moment(workingDayEndYesterday).format('YYYY-MM-DD HH:mm:ss'));


        if (this.consolidateData) {
            // Consolidated = sum of all sales between yesterday's working day end and today's working day start.
            consolidatedFromEndOfYesterdayToStartOfToday = data.filter(tx => {

                // If the date is between yesterday's working day end and today's working day start, then include it in the consolidated data.
                if (tx.date.getTime() > workingDayEndYesterday && tx.date.getTime() < workingDayStart) {
                    return true;
                }

                // If the transaction date was before today's start and it was a sales order that has been invoiced, then include it in the consolidated data.
                // It is important to note that open sales orders are not included in the consolidated data.
                if (tx.date.getTime() < workingDayStart && tx.type === 'sales_o') {
                    const invoicedDuringWorkingDay = data.find(inv => inv.type === 'invoice' && inv.referenceKey === tx.referenceKey && inv.date.getTime() >= workingDayStart);
                    if (invoicedDuringWorkingDay) {
                        return true;
                    }
                }

                return false;
            });

            // Data = sum of all sales between today's working day start and today's working day end.
            data = data.filter(c => c.date.getTime() > workingDayStart && c.date.getTime() < workingDayEnd);
        } else {
            // Filter dates to show between mid-Night 00:00 and mid-Night 23:59.
            data = data.filter(x => x.date.getTime() >= workingDayStart && x.date.getTime() <= workingDayEnd);
        }
        // Set the valueCompounded property of each entry.
        data = this.compoundSummaryData(data, consolidatedFromEndOfYesterdayToStartOfToday) as any;
        //  console.log('data:', data);

        // console.log('Data compounded:', data)
        if (data?.length > 0) {
            // The graphData is already sorted, add the consolidated data to the top of the array.
            // if (consolidated?.length > 0) {
            //   // const consolidatedEntry: GraphDataConsolidated = {
            //   const consolidatedEntry: any = {
            //     date: moment(workingDayStart).add(10, 'millisecond').toDate(), // Adding 10 milliseconds will render the consolidated entry after the zero based entry.
            //     data: consolidated,
            //     key: '',
            //     value: 0,
            //     valueCompounded: consolidated[consolidated.length - 1].valueCompounded, // The last entry will have the accurate valueCompounded.
            //     repKey: 0,
            //     gross: 0,
            //     nett: 0,
            //     sales: 0,
            //   };
            //   data.unshift(consolidatedEntry);
            // }

            // The zeroBasedEntry allows the first line to plot from 0 to the destined value.
            const zeroBasedEntry: any = {
                ...this.zeroBasedComplexGraphData,
                date: data[0].date,
                key: 'zero-based-entry',
                value: 0,
                repKey: 0,
                gross: 0,
                nett: 0,
                sales: 0,
            };
            data.unshift(zeroBasedEntry);
            this.graphDataSalesOrders.unshift(zeroBasedEntry);
            this.graphDataInvoices.unshift(zeroBasedEntry);
        }

        // set scale domains
        this.updateYExtent(data as any);

        let xExtent = d3.extent(data, d => new Date(d.date));
        if (!xExtent[0] || !xExtent[1]) {
            xExtent = [this.workingDayStart, this.workingDayEnd];
        }

        // if (this.consolidateData) {
        this.xExtent = [this.workingDayStart, this.workingDayEnd];
        // } else {
        //     this.xExtent = xExtent;
        // }
        // Add a small amount of time to each end, granting a small margin on the edge of the graph.
        const xExtentMargin5Minutes: [Date, Date] = [moment(this.xExtent[0]).subtract(5, 'minutes').toDate(), moment(this.xExtent[1]).add(5, 'minutes').toDate()]
        this.xExtent = xExtentMargin5Minutes;

        // Calculate xTicks.
        this.xTicks = 10;
        const diff = moment(this.xExtent[1]).diff(this.xExtent[0], 'hours', true).toFixed(0);
        this.xTicks = +diff;

        this.graphData = [];
        this.graphData = data as any;

        this.showSpinner = false;
    };

    private compoundSummaryData(data: DaySummaryDatum[], consolidatedFromEndOfYesterdayToStartOfToday: DaySummaryDatum[]) {
        // console.groupCollapsed('compoundSummaryData');

        // const isDateSelectedTheCurrentDateToday = moment(this.dateForData).isSame(moment(), 'day') && moment().isBefore(moment(this.workingDayEnd));
        const salesOrdersFromData: DaySummaryDatum[] = data.filter(c => c.type === 'sales_o' || c.type === 'open_so');

        // const duplicatesInConsolidatedData = consolidatedFromEndOfYesterdayToStartOfToday.filter((item, index, self) =>
        //     index !== self.findIndex((t) => (
        //         t.referenceKey === item.referenceKey && t.type === item.type 
        //     ))
        // );
        // console.log('duplicatesInConsolidatedData:', duplicatesInConsolidatedData);

        data = data.filter(c => c.transactionType !== '--');

        const carriedOver: DaySummaryDatum | null = consolidatedFromEndOfYesterdayToStartOfToday[0] || null;
        if (consolidatedFromEndOfYesterdayToStartOfToday.length > 0) {
            consolidatedFromEndOfYesterdayToStartOfToday.sort((a, b) => { return (<any>a).date - (<any>b).date });
            if (carriedOver.repKey !== undefined) carriedOver.repKeys = [carriedOver.repKey];
            carriedOver.referenceKeys = [carriedOver.referenceKey];
            carriedOver.salesOrderCompounded = 0;
            carriedOver.invoicedCompounded = 0;
            const salesOrdersCarriedOver: DaySummaryDatum[] = [];
            const invoicesCarriedOver: DaySummaryDatum[] = [];

            // console.log('consolidatedFromEndOfYesterdayToStartOfToday:', consolidatedFromEndOfYesterdayToStartOfToday);
            consolidatedFromEndOfYesterdayToStartOfToday.forEach((datum, i) => {
                if (i > 0 && carriedOver) {
                    // add reference key and rep key if not already added.
                    if (carriedOver?.referenceKeys?.indexOf(datum.referenceKey) === -1) carriedOver.referenceKeys.push(datum.referenceKey);
                    if (datum.repKey && carriedOver?.repKeys?.indexOf(datum.repKey) === -1) carriedOver.repKeys.push(datum.repKey);


                    // if (datum.invoiceType === 'I') {
                    //     invoicesCarriedOver.push(datum);
                    //     carriedOver.value += datum.invoicedNett;
                    //     carriedOver.invoicedCompounded = carriedOver.invoicedCompounded + datum.invoicedNett;
                    //     carriedOver.invoicedGross += datum.invoicedGross;
                    //     carriedOver.invoicedNett += datum.invoicedNett;
                    //     carriedOver.invoicedTax += datum.invoicedTax;

                    // } else if (datum.invoiceType === 'C') {
                    //     invoicesCarriedOver.push(datum);
                    //     carriedOver.value += datum.invoicedNett - datum.creditNoteNett;
                    //     carriedOver.invoicedCompounded = carriedOver.invoicedCompounded + datum.invoicedNett - datum.creditNoteNett;

                    //     carriedOver.creditNoteGross += datum.creditNoteGross;
                    //     carriedOver.creditNoteNett += datum.creditNoteNett;
                    //     carriedOver.creditNoteTax += datum.creditNoteTax;

                    // } else 
                    if (datum.invoiceType === 'S') {
                        salesOrdersCarriedOver.push(datum);
                        carriedOver.value = carriedOver.value + datum.salesOrderNett;
                        carriedOver.salesOrderCompounded = carriedOver.salesOrderCompounded + datum.salesOrderNett;

                        carriedOver.salesOrderGross += datum.salesOrderGross;
                        carriedOver.salesOrderNett += datum.salesOrderNett;
                        carriedOver.salesOrderTax += datum.salesOrderTax;

                        // carriedOver.invoicedCompounded = carriedOver.invoicedCompounded + datum.invoicedNett;
                        // carriedOver.invoicedGross += datum.invoicedGross;
                        // carriedOver.invoicedNett += datum.invoicedNett;
                        // carriedOver.invoicedTax += datum.invoicedTax;
                    } else {
                        console.warn('Unknown invoice type', datum.invoiceType, datum);
                    }

                }
            });
            // console.log('1st > carriedOver:', carriedOver);
            // carriedOver.valueCompounded = carriedOver.salesOrderCompounded + carriedOver.invoicedCompounded;
            // carriedOver.value = carriedOver.valueCompounded;
            // carriedOver.valueCompounded = (+carriedOver.salesOrderCompounded.toFixed(2) || 0.00) + (+carriedOver.invoicedCompounded.toFixed(2) || 0.00);
            carriedOver.valueCompounded = +carriedOver.salesOrderCompounded.toFixed(2) || 0.00;
            carriedOver.value = carriedOver.valueCompounded;

            // Set these references to the carriedOver object for onClick and tooltip usage.
            (carriedOver as any).salesOrderDetails = salesOrdersCarriedOver;
            (carriedOver as any).invoiceDetails = invoicesCarriedOver;

            carriedOver.repKey = undefined;
            carriedOver.transactionType = 'SO';
            carriedOver.type = 'sales_o';
            carriedOver.key = 'carried-over-entry';
            carriedOver.referenceKey = 'carried-over-entry';
            carriedOver.date = moment(this.workingDayStart).add(10, 'millisecond').toDate();
            data.unshift(carriedOver);
        }
        // console.log('carriedOver:', carriedOver);
        const areClosedSalesOrdersIncluded = this.filtersByCode !== null && isObjectNotEmpty(this.filtersByCode) ? this.filtersByCode['salesOrder-closed'] === true : true;
        data.sort((a, b) => { return (<any>a).date - (<any>b).date })

        const salesOrders2: DaySummaryDatum[] = [];
        const invoices2: DaySummaryDatum[] = [];
        const unrecognized: DaySummaryDatum[] = [];
        const carriedOverInvoiced: DaySummaryDatum[] = [];
        const complexGraphData: ComplexGraphData[] = data.map((datum, i) => {

            if (i > 0) {
                if (datum.type === 'invoice' || datum.type === 'debtTran') {

                    let hasSalesOrderForInvoice = salesOrdersFromData.some(so => so.referenceKey === datum.referenceKey);
                    if (!hasSalesOrderForInvoice) {
                        const wasInvoicedForCarriedOverSalesOrder = consolidatedFromEndOfYesterdayToStartOfToday.find(x => x.referenceKey === datum.referenceKey);
                        if (wasInvoicedForCarriedOverSalesOrder) {
                            hasSalesOrderForInvoice = true;
                            carriedOverInvoiced.push(datum);
                            datum.value = datum.invoicedNett - datum.creditNoteNett;
                            datum.invoicedCompounded = data[i - 1].invoicedCompounded + datum.value;
                        } else {
                            datum.value = datum.invoicedNett - datum.creditNoteNett;
                            datum.invoicedCompounded = data[i - 1].invoicedCompounded + datum.value;
                        }

                    } else {
                        datum.value = datum.invoicedNett - datum.creditNoteNett;
                        datum.invoicedCompounded = data[i - 1].invoicedCompounded + datum.value;
                    }

                    if (datum.invoiceType !== 'C' && areClosedSalesOrdersIncluded && hasSalesOrderForInvoice) {
                        datum.salesOrderCompounded = data[i - 1].salesOrderCompounded - Math.abs(datum.value);
                    } else {
                        datum.salesOrderCompounded = data[i - 1].salesOrderCompounded;
                    }

                } else if (datum.invoiceType === 'S') {
                    datum.value = datum.salesOrderNett;
                    datum.salesOrderCompounded = data[i - 1].salesOrderCompounded + datum.value;
                    datum.invoicedCompounded = data[i - 1].invoicedCompounded;

                    const salesOrderInCarriedOver = consolidatedFromEndOfYesterdayToStartOfToday.find(x => x.referenceKey === datum.referenceKey);
                    if (salesOrderInCarriedOver) {
                        datum.value = 0;
                        datum.salesOrderCompounded = data[i - 1].salesOrderCompounded;
                        datum.invoicedCompounded = data[i - 1].invoicedCompounded;
                    }

                } else {
                    console.warn('Unknown invoice type', datum.invoiceType, datum);
                    datum.value = 0;
                    datum.invoicedCompounded = data[i - 1].invoicedCompounded;
                    datum.salesOrderCompounded = data[i - 1].salesOrderCompounded;
                }

                datum.valueCompounded = datum.invoicedCompounded + datum.salesOrderCompounded;
            } else { // TODO: this section can be removed with a bit of cleaner-thinking.
                // console.log('first entry', datum)
                if (datum.key === 'carried-over-entry') {
                    // Carried over entry is already processed, so we handle it differently.
                } else {
                    if (datum.type === 'invoice' || datum.type === 'debtTran') {
                        let hasSalesOrderForInvoice = salesOrdersFromData.some(so => so.referenceKey === datum.referenceKey);
                        if (!hasSalesOrderForInvoice) {
                            const wasInvoicedForCarriedOverSalesOrder = consolidatedFromEndOfYesterdayToStartOfToday.some(x => x.referenceKey === datum.referenceKey);
                            if (wasInvoicedForCarriedOverSalesOrder) {
                                hasSalesOrderForInvoice = true;
                                carriedOverInvoiced.push(datum);
                                datum.value = datum.invoicedNett - datum.creditNoteNett;
                                datum.invoicedCompounded += datum.value;
                            } else {
                                datum.value = datum.invoicedNett - datum.creditNoteNett;
                                datum.invoicedCompounded += datum.value;
                            }

                        } else {
                            datum.value = datum.invoicedNett - datum.creditNoteNett;
                            datum.invoicedCompounded += datum.value;
                        }

                        if (datum.invoiceType !== 'C' && areClosedSalesOrdersIncluded && hasSalesOrderForInvoice) {
                            datum.salesOrderCompounded = 0 - Math.abs(datum.value);
                        } else {
                            datum.salesOrderCompounded = 0;
                        }

                    } else if (datum.invoiceType === 'C') {
                        datum.value = datum.invoicedNett - datum.creditNoteNett;
                        datum.invoicedCompounded = 0 - datum.value;
                        datum.salesOrderCompounded = 0;

                    } else if (datum.invoiceType === 'S') {
                        datum.value = datum.salesOrderNett;
                        datum.salesOrderCompounded = 0 + datum.value;
                        datum.invoicedCompounded = 0;
                    } else {
                        console.warn('Unknown invoice type', datum.invoiceType, datum);
                        datum.value = 0;
                        datum.invoicedCompounded = 0;
                        datum.salesOrderCompounded = 0;
                    }

                    datum.valueCompounded = datum.invoicedCompounded + datum.salesOrderCompounded;
                }
            }

            datum.value = +datum.value.toFixed(2);
            datum.valueCompounded = +datum.valueCompounded.toFixed(2);
            datum.salesOrderCompounded = +datum.salesOrderCompounded.toFixed(2);
            datum.invoicedCompounded = +datum.invoicedCompounded.toFixed(2);

            return datum as any;
        });
        // const salesOrders: DaySummaryDatum[] = [];
        // const invoices: DaySummaryDatum[] = [];
        // console.log('carriedOverInvoiced:', carriedOverInvoiced)
        // console.log('carriedOverInvoiced invoice nett:', carriedOverInvoiced.filter(x => x.type === 'invoice').reduce((a, b) => a + b.invoicedNett, 0))
        data.forEach(c => {
            if (c.type.startsWith('open')) {
                // do nothing...
            } else if (c.type === 'sales_o') {
                // salesOrders.push(deepCopy(c));
            } else if (c.type === 'invoice') {
                // invoices.push(deepCopy(c));
            } else {
                unrecognized.push(deepCopy(c));
            }
            salesOrders2.push(deepCopy(c));
            invoices2.push(deepCopy(c));
        });
        // salesOrders.forEach(tx => tx.valueCompounded = tx.salesOrderCompounded);
        // invoices.forEach(tx => tx.valueCompounded = tx.invoicedCompounded);

        salesOrders2.forEach(tx => {
            tx.valueCompounded = tx.salesOrderCompounded;
            if (tx.invoiceType !== 'S') {
                tx.value = 0;
                tx.userKey = 0;
            }
        });
        invoices2.forEach((tx, i) => {
            tx.valueCompounded = tx.invoicedCompounded;
            if (tx.invoiceType !== 'I' && tx.invoiceType !== 'C') {
                tx.value = 0;
                tx.userKey = 0;
            }
        });

        this.graphDataInvoices = invoices2 as any;
        this.graphDataSalesOrders = salesOrders2 as any;
        this.graphData = complexGraphData as any;

        // console.groupEnd();
        return complexGraphData;
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

}
