import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, CanLoad, Route, RouterState, ActivatedRoute } from '@angular/router';
import { AngularFirestore, QuerySnapshot } from '@angular/fire/compat/firestore';
import { AuthService } from './shared/services/auth.service';
import { take, map, switchMap, first } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { User, UserOrgDetails } from '@newgenus/common';
import { Observable, from, of } from 'rxjs';
import { Injectable } from '@angular/core';

type Purpose = 'CanActivate' | 'CanActivateChild' | 'CanLoad';

interface UserAuth {
  url: string;
  user: User;
  purpose: Purpose;
  authenticated: boolean | null;
  authorized: boolean | null;
  redirect: string | null;
  meta?: any;
}

/**
 * Protect routes that should only be viewed by:
 * 1 - authenticated and initilized (via user service) and
 * 2 - authorized (via feature service)
 * users.
 */
@Injectable()
export class PermissionGuard implements CanActivate, CanActivateChild, CanLoad {
  state: RouterState;
  snapshot: RouterStateSnapshot;
  root: ActivatedRouteSnapshot;
  child: any;
  url: any;

  constructor(
    // private featureService: FeatureService,
    private authService: AuthService,
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    private router: Router,
    private route: ActivatedRoute,
    // private state: RouterStateSnapshot
  ) {
    this.state = router.routerState;
    this.url = router.url;
    this.snapshot = this.state.snapshot;
    this.root = this.snapshot.root;
    this.child = this.root.firstChild;
    // console.log("this.snapshot : ", this.snapshot);
    const dbRoutes = this.afs.collection('routes', ref => {
      return ref
        .where('route', '==', this.snapshot.url)
    }).stateChanges()

    dbRoutes.subscribe(
      (data) => {
        // console.log("data: ", data);
      }
    )

    this.route.data.subscribe((data: any) => {
      console.log("this.route.data : ", data);
    });
  }

  // @logMethod
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    console.log("route : ", route);
    console.log("state : ", state.root);

    const componentName = route.data['componentName'];
    console.log("componentName : ", componentName);

    return this.check(state.url, 'CanActivate').pipe(map(userAuth => {
      // console.log("userAuth result : ", userAuth);
      if (!userAuth.authenticated) {
        this.router.navigate(['/login']);
        return false;
      }
      else
        if (!userAuth.authorized) {
          this.router.navigate([userAuth.redirect]);
          return false;
        }
        else {
          // console.log("url: ", state.url);
          return true;
        }

    }));
  }

  // @logMethod
  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.check(state.url, 'CanActivateChild');
  }

  // @logMethod
  public canLoad(route: Route): Observable<boolean> {
    return this.check(route.path || '', 'CanLoad');
  }

  // private functions ---------------------------------------------------------

  private check(url: string, purpose: Purpose): Observable<any> {
    return this.authService.user$.pipe(
      take(1),
      switchMap((user) => {
        if (!user) { return of(null); } // No user = no access...

        return this.checkAuthenticated({
          authenticated: null,
          authorized: null,
          purpose,
          redirect: null,
          url,
          user
        })
      }),
      map(userAuth => {
        if (userAuth !== null) this.authService.user = userAuth.user;
        return userAuth;
      }),
      switchMap((userAuth) => {
        if (userAuth === null) return of(null);
        else return this.checkAuthorized(userAuth)
      }),
      map((userAuth) => {
        console.log("userAuth : ", userAuth);
        if (userAuth === null) return null;

        if (userAuth.redirect) {
          if (!userAuth.authenticated) {
            this.authService.redirectUrl = url;
          }
          if (!userAuth.authorized) {
            this.authService.redirectUrl = url;
          }
        }
        // console.log("this.authService.redirectUrl: ", this.authService.redirectUrl);
        return userAuth; // userAuth.authenticated && userAuth.authorized;; //  && userAuth.authorized;
      })
    );
  }

  private checkAuthenticated(userAuth: UserAuth): Observable<UserAuth> {
    if (userAuth.user === null) {
      // console.log("step 1!");
      userAuth.authenticated = false;
      userAuth.redirect = userAuth.redirect || '/login';
      return of(userAuth);
    } else {
      // console.log("step 2!", userAuth);
      userAuth.authenticated = true;
      return of(userAuth);
    }
  }

  private checkAuthorized(userAuth: UserAuth): Observable<UserAuth> {
    // console.log("checkAuthorized > reaches here!", userAuth);
    const fetchRoutes = this.afs.collection('routes', ref => {
      return ref
        .where('route', '==', userAuth.url)
    }).get();

    let meta = {} as any;
    const waitMockPromise = new Promise<any>((resolve) => {
      // this.afAuth.authState.pipe(first()).toPromise();
      const fetching = fetchRoutes.pipe(take(1)).toPromise() as Promise<QuerySnapshot<User>>;

      // console.log("checkAuthorized > start waiting for fetching " + new Date().toString());
      fetching.then((data) => {
        console.log("stop waiting for fetching: ", data.docs);
        let routeFeatureExists;
        // setup features meta
        for (let i = 0; i < data.docs.length; i++) {
          const doc: any = data.docs[i];
          console.log("doc: ", doc.data());
          meta["features"] = doc.data().features;
          meta = { ...meta, ...doc.data() };
          const route: any = doc.data();
          // console.log("checkAuthorized > data of the route route: ", route);
          // console.log("checkAuthorized > userAuth > user > organizations > selected: ", userAuth.user.organizations.selected);
          // console.log("checkAuthorized > userAuth.user.organizations[userAuth.user.organizations.selected]: ", userAuth.user.organizations[userAuth.user.organizations.selected]);

          const featureArray = userAuth.user.organizations && userAuth.user.organizations[userAuth.user.organizations.selected].features || [];
          // console.log("checkAuthorized > featureArray : ", featureArray);
          const found = featureArray.find(item => item === route.routeFeature);
          // console.log("checkAuthorized > found : ", found);
          // userAuth.user.organizations[userAuth.user.organizations.selected]
          if (found !== undefined) {
            routeFeatureExists = true;
          } else {
            routeFeatureExists = false;
          }
        }
        // setup permissions meta
        console.log("userAuth.user.organizations : ", userAuth.user.organizations);
        const org: UserOrgDetails = userAuth.user.organizations ? userAuth.user.organizations[userAuth.user.organizations.selected] : {} as UserOrgDetails;
        console.log("checkAuthorized > org : ", org);

        const permissions = [];
        for (const permission of (org.permissions || [])) {
          console.log("permission : ", permission);
          permissions.push(permission);
        }

        console.log("this.router : ", this.router);
        console.log("this.root : ", this.root);
        console.log('this', this.constructor.name);
        console.log("this.route : ", this.route);

        meta["permissions"] = permissions;

        if (userAuth.authenticated !== true) {
          userAuth.authorized = false;
          userAuth.redirect = userAuth.redirect || '/workspace';
          // return of(userAuth);
          resolve(userAuth);
          // } else if (this.featureService.hasRoute(userAuth.user, '', userAuth.url)) {
        } else if (routeFeatureExists) {
          // console.log("checkAuthorized > user authorized as true for feature & route!");
          userAuth.authorized = true;
          userAuth["meta"] = meta;
          // return of(userAuth);
          resolve(userAuth);
        } else {
          userAuth.authorized = false;
          userAuth.redirect = userAuth.redirect || '/workspace';
          resolve(userAuth);
          // return of(userAuth);
        }
      }
      )
    });

    // const test = from(waitMockPromise).subscribe(val => {
    // console.log("val: ", val);
    // });

    return from(waitMockPromise);

    // fetchRoutes.subscribe(  (data) => {
    //   console.log("data: ", data);
    //   if (userAuth.authenticated !== true) {
    //     userAuth.authorized = false;
    //     userAuth.redirect = userAuth.redirect || '/workspace';
    //     return of(userAuth);
    //   } else if (this.featureService.hasRoute(userAuth.user, '', userAuth.url)) {
    //     userAuth.authorized = true;
    //     return of(userAuth);
    //   } else {
    //     userAuth.authorized = false;
    //     userAuth.redirect = userAuth.redirect || '/workspace';
    //     return of(userAuth);
    //   }
    // }
    // )
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
// function logMethod(target: any, propertyName: string, descriptor: TypedPropertyDescriptor<Function>) {
//   const method = descriptor.value;
//   if (!method) { return; }

//   descriptor.value = function () {
//     // eslint-disable-next-line prefer-rest-params
//     const ret = method.apply(this, arguments);
//     return ret;
//   };
// }
