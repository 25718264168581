import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { WorkspaceActions } from '../workspace/action-types';
import { createReducer, on } from '@ngrx/store';
import { Workspace } from '@newgenus/common';

// Entity adapter & initial state
export interface WorkspaceState extends EntityState<Workspace> {
    allWorkspaceLoaded: boolean; // set initial data
}

export const adapter = createEntityAdapter<Workspace>({});

export const initialWorkspaceState = adapter.getInitialState({});

// Reducer functions

export const workspaceReducer = createReducer(
    initialWorkspaceState,

    on(WorkspaceActions.allWorkspaceLoaded, (state, action) => {
        return adapter.setAll(action.workspace, {
            ...state,
            allWorkspaceLoaded: true,
        });
    }),

    on(WorkspaceActions.workspaceAdded, (state, action) => {
        return adapter.setOne(action.payload, {
            ...state,
            allWorkspaceLoaded: true,
        });
    }),

    on(WorkspaceActions.workspaceModified, (state, action) => {
        return adapter.updateOne({ id: action.payload.id as string, changes: action.payload }, state);
    }),

    on(WorkspaceActions.workspaceUpdated, (state, action) => {
        return adapter.updateOne({ id: action.payload.id as string, changes: action.payload }, state);
    })
);

export const { selectAll } = adapter.getSelectors();
