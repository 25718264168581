/**
 * Error message data
 */
export interface ErrorMessage {
  message: string;
  urgency: ErrorUrgency;
}

/**
 * Error urgency setting
 */
export enum ErrorUrgency {
  Insignificant,
  Minor, // Shows alert
  Warning, // Shows alert
  Error, // Shows modal
  Crucial, // Shows modal
}

