import { ParticlesSortOrderState } from '../reducers/particle.sortOrder.reducers';
import * as fromParticlesSortOrder from '../reducers/particle.sortOrder.reducers';
import { toFirebaseTimestamp, setParticleDateType} from '@newgenus/common';
import { WorkersService } from '../../shared/services/workers.service';
import { Particle, BasketSortingPreference } from '@newgenus/common';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ParticlesState } from '../reducers/particle.reducers';
import * as fromParticles from '../reducers/particle.reducers';
import { selectUsersState } from '../user/user.selectors';
import { Timestamp } from 'firebase/firestore';
import { Injector } from '@angular/core';
import { Moment } from 'moment';

// <!> Play nice and use only services containing pure functions
const injector = Injector.create({ providers: [{ provide: WorkersService, deps: [] }] });
const workersService: WorkersService = injector.get(WorkersService);

export const selectParticlesState =
    createFeatureSelector<ParticlesState>("particles");

export const selectParticlesSortOrderState =
    createFeatureSelector<ParticlesSortOrderState>("selectedSort");


export const selectAllParticles = createSelector(
    selectParticlesState,
    fromParticles.selectAll
);

export const selectAllInBasketParticles = createSelector(
    selectAllParticles,
    (particles) => {
        return particles.filter((particle) => particle.labelIds.includes('INBASKET'));
    });

export const selectAllPendingBasketParticles = createSelector(
    selectAllParticles,
    (particles) => {
        return particles.filter((particle) => particle.labelIds.includes('PENDING'));
    });

export const selectAllOutBasketParticles = createSelector(
    selectAllParticles,
    (particles) => {
        return particles.filter((particle) => particle.labelIds.includes('DONE'));
    });

export const selectAllFiledBasketParticles = createSelector(
    selectAllParticles,
    (particles) => {
        return particles.filter((particle) => particle.labelIds.includes('DONE'));
    });

export const selectAllFoldersBasketParticles = createSelector(
    selectAllParticles,
    (particles) => {
        return particles.filter((particle) => particle.folders !== undefined);
    });


export const selectAllParticlesSortOrder = createSelector(
    selectParticlesSortOrderState,
    fromParticlesSortOrder.selectAll
);

export const selectParticle = (id: string) => {
    return createSelector(
        selectAllParticles,
        particles => particles.filter(particle => particle.particleKey === id));
};

export const particleSortOrder = createSelector(
    selectUsersState,
    (users) => {
        return users.entities[users.ids[0]]?.preferences ||
            {
                'inbasket': { typeOfSort: 'date', dateSorting: 'dsc', particleLabelsOrder: ['EMAIL'] },
                'outbasket': { typeOfSort: 'date', dateSorting: 'dsc', particleLabelsOrder: ['EMAIL'] },
                'pending': { typeOfSort: 'date', dateSorting: 'dsc', particleLabelsOrder: ['EMAIL'] },
                'filedbasket': { typeOfSort: 'date', dateSorting: 'dsc', particleLabelsOrder: ['EMAIL'] },
                // 'foldersbasket': { typeOfSort: 'date', dateSorting: 'dsc', particleLabelsOrder: ['EMAIL'] },
                'folders': { foldersbasket: { typeOfSort: 'date', dateSorting: 'dsc', particleLabelsOrder: ['EMAIL'] } },
                'typeOfSort': 'date', dateSorting: 'dsc', particleLabelsOrder: ['EMAIL']
            } as BasketSortingPreference
    }
);

export const selectInbasketParticles = createSelector(
    selectAllInBasketParticles,
    particleSortOrder,
    async (particles, userPreference) => {
        const basket = 'INBASKET';
        const typeOfSort = userPreference.inbasket ? userPreference.inbasket.typeOfSort : userPreference.typeOfSort;
        // const inbasketParticles = particles.filter((particle) => particle.labelIds.includes('INBASKET'));
        const sortedParticles: any = await workersService.processSort(particles, userPreference, typeOfSort, basket);
        return sortedParticles; // particleDateOnlySort(particles, userPreference, basket);
        // switch (typeOfSort) {
        //     case "date":
        //         const particleCopy: any = await workersService.processSort(particles, userPreference, typeOfSort, basket);
        //         return particleCopy; // particleDateOnlySort(particles, userPreference, basket);
        //     case "type":
        //         return particleTypeOnlySort(particles, userPreference, basket);
        //     case "type_date":
        //         return particleTypeDateSort(particles, userPreference, basket);
        //     case "date_type":
        //         return particleDateTypeSort(particles, userPreference, basket);
        // }

        // let newParticles = [];
        // if (particles.length > 0) {
        //     newParticles = setParticleDateType(particles);
        //     newParticles.forEach(particleCopy => {
        //         particleCopy.creationDate = toFirebaseTimestamp(particleCopy.creationDate);
        //     });
        // }
        // // Default
        // return (particles.filter((particle) => particle.labelIds.includes('INBASKET'))).sort(
        //     (a, b) => compareParticles(a, b, userPreference))
    });

// export const selectInbasketParticlesWithoutSort = createSelector(
//     selectAllParticles,
//     (particles) => {
//         return particles.filter((particle) => particle.labelIds.includes('INBASKET'));
//     });

export const selectPendingBasketParticles = createSelector(
    selectAllPendingBasketParticles,
    particleSortOrder,
    async (particles, userPreference) => {
        const basket = 'PENDINGBASKET';
        const typeOfSort = userPreference.pending ? userPreference.pending.typeOfSort : userPreference.typeOfSort;
        const pendingParticles = particles.filter((particle) => particle.labelIds.includes('PENDING'));
        const particleCopy: any = await workersService.processSort(pendingParticles, userPreference, typeOfSort, basket);
        return particleCopy; // particleDateOnlySort(particles, userPreference, basket);
        // switch (typeOfSort) {
        //     case "date":
        //         // return particleDateOnlySort(particles, userPreference, basket);
        //     case "type":
        //         return particleTypeOnlySort(particles, userPreference, basket);
        //     case "type_date":
        //         return particleTypeDateSort(particles, userPreference, basket);
        //     case "date_type":
        //         return particleDateTypeSort(particles, userPreference, basket);

        // }

        // let newParticles = [];
        // if (particles.length > 0) {
        //     newParticles = setParticleDateType(particles);
        //     newParticles.forEach(particleCopy => {
        //         particleCopy.creationDate = toFirebaseTimestamp(particleCopy.creationDate);
        //     });
        // }
        // return (particles.filter((particle) => particle.labelIds.includes('PENDING'))).sort(
        //     (a, b) => compareParticles(a, b, userPreference))
    });

export const selectOutBasketParticles = createSelector(
    selectAllOutBasketParticles,
    particleSortOrder,
    async (particles, userPreference) => {
        const basket = 'OUTBASKET';
        const typeOfSort = userPreference.outbasket ? userPreference.outbasket.typeOfSort : userPreference.typeOfSort;
        const outbasketParticles = particles.filter((particle) => particle.labelIds.includes('DONE'));
        const particleCopy: any = await workersService.processSort(outbasketParticles, userPreference, typeOfSort, basket);
        return particleCopy; // particleDateOnlySort(particles, userPreference, basket);
        // switch (typeOfSort) {
        //     case "date":
        //         // return particleDateOnlySort(particles, userPreference, basket);
        //     case "type":
        //         return particleTypeOnlySort(particles, userPreference, basket);
        //     case "type_date":
        //         return particleTypeDateSort(particles, userPreference, basket);
        //     case "date_type":
        //         return particleDateTypeSort(particles, userPreference, basket);

        // }

        // let newParticles = [];
        // if (particles.length > 0) {
        //     newParticles = setParticleDateType(particles);
        //     newParticles.forEach(particleCopy => {
        //         particleCopy.creationDate = toFirebaseTimestamp(particleCopy.creationDate);
        //     });
        // }
        // return (particles.filter((particle) => particle.labelIds.includes('DONE'))).sort(
        //     (a, b) => compareParticles(a, b, userPreference))
    });

// export const selectOutBasketParticlesWithoutSort = createSelector(
//     selectAllParticles,
//     (particles) => {
//         return particles.filter((particle) => particle.labelIds.includes('DONE'));
//     });

export const selectFiledParticles = createSelector(
    selectAllFiledBasketParticles,
    particleSortOrder,
    async (particles, userPreference) => {
        const basket = 'FILEDBASKET';
        const typeOfSort = userPreference.filedbasket ? userPreference.filedbasket.typeOfSort : userPreference.typeOfSort;
        const filedbasketParticles = particles.filter((particle) => particle.labelIds.includes('DONE'));
        const particleCopy: any = await workersService.processSort(filedbasketParticles, userPreference, typeOfSort, basket);
        return particleCopy; // particleDateOnlySort(particles, use
        // switch (typeOfSort) {
        //     case "date":
        //         // return particleDateOnlySort(particles, userPreference, basket);
        //     case "type":
        //         return particleTypeOnlySort(particles, userPreference, basket);
        //     case "type_date":
        //         return particleTypeDateSort(particles, userPreference, basket);
        //     case "date_type":
        //         return particleDateTypeSort(particles, userPreference, basket);

        // }

        // let newParticles = [];
        // if (particles.length > 0) {
        //     newParticles = setParticleDateType(particles);
        //     newParticles.forEach(particleCopy => {
        //         particleCopy.creationDate = toFirebaseTimestamp(particleCopy.creationDate);
        //     });
        // }
        // return (particles.filter((particle) => particle.labelIds.includes('DONE'))).sort(
        //     (a, b) => compareParticles(a, b, userPreference))
    });

export const selectFolderParticles = (folder: string) => {
    return createSelector(
        selectAllFoldersBasketParticles,
        particleSortOrder,
        async (particles, userPreference) => {
            let particleResults: Array<Particle> = [];
            // switch (userPreference.typeOfSort) {
            //     case "date":
            //         particleResults = particleDateOnlySort(particles, userPreference);
            //     case "type":
            //         particleResults = particleTypeOnlySort(particles, userPreference);
            //     case "type_date":
            //         particleResults = particleTypeDateSort(particles, userPreference);
            //     case "date_type":
            //         particleResults = particleDateTypeSort(particles, userPreference);
            // }

            if (particleResults.length === 0) particleResults = particles;
            const byFolderName = (particle: Particle) => {
                let isInFolder = false;
                if (particle.folders)
                    Object.values(particle.folders).forEach((f) => {
                        if (f.toLowerCase() === folder) isInFolder = true;
                    });

                return isInFolder;
            };
            let newParticles = [];
            if (particleResults.length > 0) {
                newParticles = setParticleDateType(particleResults);
                newParticles.forEach((particleCopy: { creationDate: string | JSON | Timestamp | Date | Moment; }) => {
                    particleCopy.creationDate = toFirebaseTimestamp(particleCopy.creationDate);
                });
            }
            const basket = 'FOLDERBASKET';
            const typeOfSort = userPreference.folders && userPreference.folders[folder] ? userPreference.folders[folder].typeOfSort : userPreference.typeOfSort;
            const filedbasketParticles = particleResults.filter(byFolderName);
            const particlesSelected: any = await workersService.processSort(filedbasketParticles, userPreference, typeOfSort, basket, folder);
            return particlesSelected; // particleDateOnlySort(particles, use
            // return particleResults.filter(byFolderName).sort((a, b) => compareParticles(a, b, userPreference))
        });
}


export const areParticlesLoaded = createSelector(
    selectParticlesState,
    (state) => {
        return state.allParticlesLoaded
    }
);
