import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { SearchActions } from '../search/action-types';
import { createReducer, on } from '@ngrx/store';
import { Search } from '@newgenus/common';

// Entity adapter & initial state
export interface SearchState extends EntityState<Search> {
    allSearchLoaded: boolean; // set initial data
}

export const adapter = createEntityAdapter<Search>({
    selectId: particle => particle.emailId,
});

export const initialWorkspaceState = adapter.getInitialState({
    allSearchLoaded: false, // This was false before and prevented empty workspace baskets from loading..But seems to work fine as true?
});

// Reducer functions

export const searchReducer = createReducer(
    initialWorkspaceState,

    on(SearchActions.allSearchLoaded, (state, action) => {
        return adapter.setAll(action.payload, {
            ...state,
            allSearchLoaded: true,
        });
    }),

    on(SearchActions.addManySearchLoaded, (state, action) => {
        return adapter.addMany(action.payload, {
            ...state,
            allSearchLoaded: true,
        });
    }),

    on(SearchActions.clearAllSearch, (state, action) => {
        return adapter.removeAll({ ...state, selectId: null });
    }),

    on(SearchActions.searchAdded, (state, action) => {
        return adapter.setOne(action.payload, {
            ...state,
            allSearchLoaded: true,
        });
    }),

    on(SearchActions.searchModified, (state, action) => {
        return adapter.updateOne({ id: action.payload.id as string, changes: action.payload }, state);
    }),

    on(SearchActions.searchUpdated, (state, action) => {
        // delete action.payload.content;
        return adapter.updateOne({ id: action.payload.id as string, changes: action.payload }, state);
    })
);

export const { selectAll } = adapter.getSelectors();
