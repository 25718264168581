import { ErrorMessage, ErrorUrgency } from '@newgenus/common';
import { AlertType } from '../components/alerts/alert.model';
import { AlertService } from './alert.service';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ErrorService {
  private error = new Subject<ErrorMessage>();
  error$ = this.error.asObservable();

  constructor(
    private alertService: AlertService,
  ) { }

  /**
   * Adds a new message to the message stream, so the modal component will display it
   */
  throwError(message: string, urgency: ErrorUrgency = ErrorUrgency.Error) {
    this.error.next({
      message: message,
      urgency: urgency,
    });

    switch (urgency) {
      case ErrorUrgency.Crucial:
        // this.modalService.openSimpleText('general.error.text', message, {
        //   disableClose: true
        // });
        break;
      case ErrorUrgency.Error:
        this.alertService.addAlert(message, AlertType.Danger);
        break;
      default:
        this.alertService.addAlert(message, AlertType.Warning);
        break;
    }
  }
}
