/* eslint-disable no-restricted-syntax */

import { Feature, MenuConfiguration, MenuItem, NewgenusRoute, Permission, User, hasAnyPermissions, hasPermission, isDeveloper, isFeatureAvailableForUser } from "@newgenus/common";
import { areRoutesLoaded, selectRoutesByFeatureKeysOrSystemRoute } from "../../redux/routes/routes.selectors";
import { BehaviorSubject, Observable, Subject, debounceTime, from, map, switchMap, take, tap } from "rxjs";
import { ActivatedRouteSnapshot, Router, RoutesRecognized } from "@angular/router";
import { allRoutesLoaded } from "../../redux/routes/routes.actions";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { StoreUser } from "../../workspace/user.abstract";
import { MenuConfigurations } from "../utils/constants";
import { Store, select } from "@ngrx/store";
import { AuthService } from "./auth.service";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class SecurityService extends StoreUser {
    public userAuth: any;
    public permissionCheck$ = new Subject<void>();
    public permissionBehaviorSub$ = new BehaviorSubject<any>(null);
    private currentRecognizedRoute!: RoutesRecognized;

    constructor(
        public auth: AuthService,
        public override store: Store,
        private db: AngularFirestore,
        private router: Router
    ) {
        super(store);
        this.initStoreUser();

        this.router.events.subscribe((event) => {
            if (event instanceof RoutesRecognized) {
                this.currentRecognizedRoute = event;
            }
        });

        this.user$
            .pipe(
                tap((users) => {
                    if (users && users[0]) {
                        this.permissionCheck$.next();
                        this.permissionBehaviorSub$.next(users[0].uid);
                    }
                }),
                debounceTime(500),
                map((users) => (users && users[0] ? users[0] : null)),
                switchMap((user: User | null) => {
                    if (this.auth.isLoggedIn && user?.organizations) {
                        return this.checkRouteAuthorization(this.router.url);
                    } else {
                        return from([false]);
                    }
                })
            )
            .subscribe((isAuthorizedOnCurrentRoute) => {
                if (this.auth.isLoggedIn && isAuthorizedOnCurrentRoute === false) {
                    this.redirectToNearbyAuthorizedRoute();
                } else {
                    // Do nothing, the Auth Guard has got this...
                }
            });
    }

    /**
     * Check if a user has access to a given feature and permission name.
     *
     * @param {string} featureName The name of the feature to check.
     * @param {string} permissionName The name of the permission to check.
     * @return {boolean} True if the user has access to the feature and permission, false otherwise.
     * @memberof SecurityService
     */
    public checkPermission(featureName: Feature, permissionName: Permission) {
        return hasPermission(this.user, featureName, permissionName)

        // // Short circuit to false if no user or organization
        // if (!this.user?.organizations) return false;

        // // Reference current selected org.
        // const org = this.user.organizations[this.user.organizations.selected];

        // // Short circuit to false if no details.
        // if (!org?.features || !org?.featureDetails) {
        //     return false;
        // }

        // // Find feature with feature name.
        // const featureFound = org.features.includes(featureName);

        // // Find permission.
        // const permissionFound = featureFound
        //     ? (org.featureDetails[featureName]).permissions.includes(permissionName)
        //     : false;

        // // Check if feature has permission.
        // const hasAccess = featureFound && permissionFound;

        // return hasAccess;
    }

    /**
     * Check if the user is a developer.
     *
     * @return {boolean} True if the user is a developer, false otherwise.
     */
    public isDeveloper() {
        return isDeveloper(this.user);
    }

    /**
     * Monitor a user's permissions for a given feature and permission name.
     * This will return an observable that will emit a boolean value when the user or their permissions change.
     *
     * @param {string} featureName The name of the feature to check.
     * @param {string} permissionName The name of the permission to check.
     * @return {Observable<boolean>} An observable that will emit a boolean value when the user or their permissions change.
     * @memberof SecurityService
     */
    public monitorPermission(featureName: Feature, permissionName: Permission): Observable<boolean> {
        return new Observable((observer) => {

            this.permissionBehaviorSub$.subscribe((uid) => {
                if (uid) {
                    const hasAccess = this.checkPermission(featureName, permissionName);
                    observer.next(hasAccess);
                }
            });

            this.permissionCheck$.subscribe(() => {
                const hasAccess = this.checkPermission(featureName, permissionName);
                observer.next(hasAccess);
            });

        });
    }

    /**
     * Check if a user has access to a given feature and any of the given permissions.
     * This will return an observable that will emit a boolean value when the user or their permissions change.
     *
     * @param {Feature} featureName The name of the feature to check.
     * @param {...Permission[]} permissions The names of the permissions to check.
     * @return {*}  {Observable<boolean>} An observable that will emit a boolean value when the user or their permissions change.
     * @memberof SecurityService
     */
    public monitorAnyPermissions(featureName: Feature, ...permissions: Permission[]): Observable<boolean> {
        return new Observable((observer) => {

            this.permissionBehaviorSub$.subscribe((uid) => {
                if (uid) {
                    const hasAccess = hasAnyPermissions(this.user, featureName, ...permissions);
                    observer.next(hasAccess);
                }
            });

            this.permissionCheck$.subscribe(() => {
                const hasAccess = hasAnyPermissions(this.user, featureName, ...permissions);
                observer.next(hasAccess);
            });

        });
    }

    /**
     * Check if a user has access to a given feature.
     *
     * @param {string} feature The name of the feature to check.
     * @return {boolean} True if the user has access to the feature, false otherwise.
     * @memberof SecurityService
     */
    public isFeatureAvailableForUser(feature: Feature) {
        return isFeatureAvailableForUser(this.user, feature);

        // const featureArray = this.user.organizations ? this.user.organizations[this.user.organizations.selected].features : [];
        // const lowercased = featureArray.map(name => name.toLowerCase());
        // return lowercased.includes(feature.toLowerCase());
    }

    public monitorFeature(feature: Feature): Observable<boolean> {
        return new Observable((observer) => {

            this.permissionBehaviorSub$.subscribe((uid) => {
                if (uid) {
                    const hasAccess = this.isFeatureAvailableForUser(feature);
                    observer.next(hasAccess);
                }
            });

            // this.permissionCheck$.subscribe(() => {
            //     const hasAccess = this.isFeatureAvailableForUser(feature);
            //     observer.next(hasAccess);
            // });

        });
    }

    /**
     * Check if a user has any organization.
     *
     * @return {boolean} True if the user has access to an organization, false otherwise. 
     * @memberof SecurityService
     */
    public isOrgAvailableForUser() {
        return this.user.organizations !== undefined && this.user.organizations !== null;
    }

    private extractEmbeddedChildData(children: ActivatedRouteSnapshot[]): Array<EmbeddedChildRoutes> {
        return children.map((c) => {
            return {
                route: c.routeConfig?.path || '',
                data: c.data,
                children: this.extractEmbeddedChildData(c.children),
            };
        });
    }

    public checkRouteAuthorization(path: string, loopCount = 0): Observable<boolean | null> {
        return from(
            new Promise<boolean | null>((resolve) => {
                // If the route has changed, terminate looping and resolve as null.
                if (this.router.url !== path) {
                    resolve(null);
                    return;
                }

                // Everyone can go home. No, seriously, everyone, can go home. Please go home.
                if (path === '/app/home') {
                    resolve(true);
                    return;
                }

                // Check if the route that is being navigated requires authorization or not.
                if (this.currentRecognizedRoute && this.currentRecognizedRoute.state.url === path) {
                    // Look for data on the current active route segment (this checks the base route, consider '/app/home', this would be just '/').
                    if (Object.keys(this.currentRecognizedRoute.state.root?.data || {}).length > 0) {
                        const routeData = this.currentRecognizedRoute.state.root.data;
                        if (routeData['isAuthorizedOnCurrentRoute']) {
                            resolve(true);
                            return;
                        }
                    } else {
                        // Look for data on the embedded child routes (this for example, on '/app/home' will check both 'app' and 'home' routes as they are children of '/').
                        const routeData = this.extractEmbeddedChildData(
                            this.currentRecognizedRoute.state.root.children
                        );
                        const isUnauthorizedAccessAllowed = this.recursiveCheckChildData(
                            routeData,
                            'isUnauthorizedAccessAllowed'
                        );

                        if (isUnauthorizedAccessAllowed) {
                            console.debug(`Route ${path} allows unauthorized access.`);
                            resolve(true);
                            return;
                        }
                    }
                }

                let routeFeatureExists = false;

                if (this.auth.isLoggedIn) {
                    // If the user's organization field is not set, this means the auth firestore user has not loaded in yet and it will shortly.
                    // Do a loop, but only 5 times.
                    if (!this.user.organizations) {
                        if (loopCount < 5) {
                            // Loop the operation after 800ms.
                            setTimeout(() => {
                                return this.checkRouteAuthorization(this.router.url, loopCount + 1);
                            }, 800);
                        } else {
                            // Either their sus, or their internet is out. Either way, they're signing out.
                            alert('Please check your internet connection and try again.');
                            this.auth.signOut();
                        }
                    }

                    const userFeatureKeyArray = this.user.organizations ? this.user.organizations[this.user.organizations.selected].features as string[] : [];

                    // TODO - this is a hack to get around the fact that the route is not loaded yet.
                    // This should be fixed by using RXJS to wait for the route to be loaded.
                    return this.fetchRoutesByFeatureKeysIncludingSystemRoutes(userFeatureKeyArray)
                        .pipe(take(1))
                        .toPromise()
                        .then((routes) => {
                            const routeFound = routes ? routes.find((r) => r.route === path) : undefined;

                            // Developers can access all routes.
                            if (this.user.isDeveloper || routeFound) {
                                routeFeatureExists = true;

                                if (this.user.isDeveloper && routeFound === undefined)
                                    console.warn(`Developer user <${this.user?.email}> is missing feature <${routeFound}> for route <${path}>.`);
                                // console.warn(`Developer user <${this.user?.email}> is missing feature <${routeFound?.routeFeature}> for route <${path}>.`);
                            } else {
                                routeFeatureExists = false;
                            }

                            resolve(routeFeatureExists);
                        })
                        .catch((err) => {
                            console.error('checkRouteAuthorization > err: ', err);
                            alert('Unexpected error occurred. Please try again.');
                            this.auth.signOut();
                        });
                } else {
                    resolve(false);
                    return;
                }
            })
        );
    }

    private recursiveCheckChildData(routeData: EmbeddedChildRoutes[], field: string): any {
        for (let i = 0; i < routeData.length; i++) {
            const route = routeData[i];
            if (Object.keys(route?.data || {}).length > 0 && route.data[field] !== undefined) {
                return route.data[field];
            } else {
                if (route.children.length > 0) {
                    return this.recursiveCheckChildData(route.children, field);
                }
            }
        }
    }

    public async generateSubMenuFromUserAccess() {
        return new Promise<MenuConfiguration | null>((resolve) => {
            if (!this.user) {
                resolve(null);
                return null;
            }

            let userMenuConfigurations: MenuConfiguration = {
                HOME: {
                    header: MenuConfigurations['HOME'].header,
                    showFolders: MenuConfigurations['HOME'].showFolders,
                    topTierMenuItems: [],
                    middleTierMenuItems: [],
                },
            };
            if (this.user.isDeveloper) {
                // Developers can do what they want in this world.
                userMenuConfigurations = MenuConfigurations;
                console.warn('Developer mode enabled. All menu items are available.');
            } else {
                const featureArray = this.user.organizations ? this.user.organizations[this.user.organizations.selected].features as string[] : [];

                this.fetchRoutesByFeatureKeysIncludingSystemRoutes(featureArray)
                    // .pipe(first(), delay(300))
                    .pipe(take(1), debounceTime(300))
                    .toPromise()
                    .then((routes) => {
                        for (const [subDirectoryKey, subMenu] of Object.entries(MenuConfigurations)) {
                            // Match TopTierMenuItems to featureKey.
                            const topTierMenuItems = subMenu.topTierMenuItems.filter((item) => {
                                return routes?.some(
                                    (r) =>
                                        r.route.toLowerCase() ===
                                        `/app/${item.section.toLowerCase()}/${item.path.toLowerCase()}`
                                );
                            });

                            // Match MiddleTierMenuItems to featureKey.
                            const middleTierMenuItems = subMenu.middleTierMenuItems.filter((item) => {
                                return routes?.some(
                                    (r) =>
                                        r.route.toLowerCase() ===
                                        `/app/${item.section.toLowerCase()}/${item.path.toLowerCase()}`
                                );
                            });

                            // Merge topTierMenuItems and middleTierMenuItems into existing userMenuConfigurations.
                            if (topTierMenuItems.length > 0 || middleTierMenuItems.length > 0) {
                                if (userMenuConfigurations[subDirectoryKey]) {
                                    topTierMenuItems
                                        .filter(
                                            (x) =>
                                                userMenuConfigurations[subDirectoryKey].topTierMenuItems.find(
                                                    (y) => y.id === x.id
                                                ) === undefined
                                        )
                                        .forEach((x) =>
                                            userMenuConfigurations[subDirectoryKey].topTierMenuItems.push(x)
                                        );
                                    middleTierMenuItems
                                        .filter(
                                            (x) =>
                                                userMenuConfigurations[subDirectoryKey].middleTierMenuItems.find(
                                                    (y) => y.id === x.id
                                                ) === undefined
                                        )
                                        .forEach((x) =>
                                            userMenuConfigurations[subDirectoryKey].middleTierMenuItems.push(x)
                                        );
                                } else {
                                    // Add one of each sub directory to the home directory.
                                    userMenuConfigurations['HOME'].topTierMenuItems.push(
                                        topTierMenuItems.slice(0, 1).map((m) => {
                                            m = { ...m };
                                            m.name = subMenu.header;
                                            m.showCount = false;
                                            m.icon = this.toHomeIcon(m);
                                            m.svg = undefined;
                                            return m;
                                        })[0]
                                    );

                                    userMenuConfigurations[subDirectoryKey] = {
                                        header: subMenu.header,
                                        showFolders: subMenu.showFolders,
                                        topTierMenuItems,
                                        middleTierMenuItems,
                                    };
                                }
                            }
                        }

                        // Sort the HOME top tier menu items.
                        userMenuConfigurations['HOME'].topTierMenuItems = userMenuConfigurations[
                            'HOME'
                        ].topTierMenuItems.sort((a, b) => a.order - b.order);
                    });
            }

            resolve(userMenuConfigurations);
            return;
        });
    }

    private toHomeIcon(m: MenuItem): string {
        switch (m.section) {
            case 'workspace':
                return 'account_balance_wallet';
            case 'management-tools':
                return 'engineering';
            case 'organization':
                return 'corporate_fare';
            default:
                return 'dashboard';
        }
    }

    private fetchRoutesByFeatureKeysIncludingSystemRoutes(featureKeys: string[]): Observable<NewgenusRoute[]> {
        return this.store.pipe(select(areRoutesLoaded)).pipe(
            take(1),
            switchMap((routesAreLoaded) => {
                if (routesAreLoaded) {
                    return (
                        this.store
                            // Fetch all matching routes.
                            .pipe(select(selectRoutesByFeatureKeysOrSystemRoute(featureKeys)))
                            .pipe(map((resp) => ({ routes: resp, from: 'ngrx' })))
                    );
                } else {
                    return this.db
                        .collection<NewgenusRoute>('routes')
                        .get()
                        .pipe(
                            map((routes) => {
                                // Parse from Firestore Documents.
                                const allRoutes = routes.docs.map((doc) => doc.data());

                                // Dispatch the fetched routes to the store.
                                this.store.dispatch(allRoutesLoaded({ routes: allRoutes }));

                                // Filter matching routes.
                                const matchingRoutes = allRoutes.filter((routeDoc) => {
                                    return (
                                        featureKeys.includes(routeDoc.routeFeature) ||
                                        routeDoc.features.every((f) => featureKeys.includes(f)) ||
                                        (routeDoc.systemRoute && routeDoc.features.some((rFeatureKey) => featureKeys.includes(rFeatureKey)))
                                    );
                                });

                                // Return the matching routes.
                                return { routes: matchingRoutes, from: 'firestore' };
                            })
                        );
                }
            }),
            map((results) => results.routes)
        );
    }

    private redirectToNearbyAuthorizedRoute(): void {
        this.generateSubMenuFromUserAccess().then((newMenu) => {
            const url = this.router.url;
            let moduleFragment = url.substring(url.indexOf('/', 1) + 1, url.indexOf('/', url.indexOf('/', 1) + 1));
            moduleFragment = moduleFragment.replace('/', '').replace('/', '');

            moduleFragment = moduleFragment.toUpperCase();

            if (newMenu && moduleFragment) {
                let path = '/app/home';

                if (newMenu[moduleFragment]?.topTierMenuItems?.length > 0) {
                    const topMenuItem = newMenu[moduleFragment].topTierMenuItems[0];
                    path =
                        `app/${topMenuItem.section}` + (topMenuItem.path ? `/${topMenuItem.path.toLowerCase()}` : '');
                } else if (newMenu[moduleFragment]?.middleTierMenuItems?.length > 0) {
                    const middleMenuItem = newMenu[moduleFragment].middleTierMenuItems[0];
                    path =
                        `app/${middleMenuItem.section}` +
                        (middleMenuItem.path ? `/${middleMenuItem.path.toLowerCase()}` : '');
                }

                this.router.navigate([path]);
            } else {
                // Do nothing...
                // This is because angular routing will handle the redirect.
            }
        });
    }
}

interface EmbeddedChildRoutes {
    route: string;
    data: Record<string, any>;
    children: Array<EmbeddedChildRoutes>;
}
