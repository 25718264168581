import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { OrbBoardBaseComponent } from '../org-board-base-component';
import { CommonModule, NgStyle } from '@angular/common';
import { MaterialModule } from '../../material.module';
import { Position } from '../services/tree-builder.service';
import { FormsModule } from '@angular/forms';
import { ECONode } from '@newgenus/common';
import { OrgBoardService } from '../services/org-board-service';

@Component({
    selector: 'shared-sidebar-position-detail',
    standalone: true,
    imports: [CommonModule, MaterialModule, NgStyle, FormsModule],
    template: `
    <!-- Position Details -->
    <mat-card class="my-2">
        <!-- Position title. -->
        <mat-card-header>
        <mat-card-title>Position Details</mat-card-title>
        <mat-card-subtitle>{{node?.data?.name || '-None-'}}</mat-card-subtitle>
        </mat-card-header>

        <!-- Position Detail. -->
        <mat-card-content class="pt-2">
        <div class="description-list-container">
            <dl>
                <ng-container *ngIf="isDev">
                    <dt>PartKey:</dt>
                    <dd>{{node?.data?.partKey || '-None-'}}</dd>
                    <dt>Parent:</dt>
                    <dd>{{node?.data?.parent || '-None-'}}</dd>
                    <dt>Depth:</dt>
                    <dd>{{node?.data?.depth || '-None-'}}</dd>
                    <dt>Key:</dt>
                    <dd>{{node?.data?.key || '-None-'}}</dd>
                    <dt>ID:</dt>
                    <dd>{{node?.data?.id || '-None-'}}</dd>
                    <dt>Path:</dt>
                    <dd>{{node?.data?.path || '-None-'}}</dd>
                </ng-container>
            
            <dt>Colours:</dt>
            <dd class="flex-between">Base: {{node?.data?.colour?.color || '-Unset-'}} <input type="color" (ngModelChange)="obService.onColourChange.next(node)" [(ngModel)]="nodeColour.color"/> </dd>
            <dd class="flex-between">Background: {{node?.data?.colour?.background || '-Unset-'}} <input type="color" (ngModelChange)="obService.onColourChange.next(node)" [(ngModel)]="nodeColour.background" /> </dd>
            <dd class="flex-between">Links: {{node?.data?.colour?.linkColor || '-Unset-'}} <input type="color" (ngModelChange)="obService.onColourChange.next(node)" [(ngModel)]="nodeColour.linkColor"/> </dd>
            </dl>
        </div>
        </mat-card-content>

        <!-- Position Actions -->
        <mat-card-actions align="end" class="button-margin">
        <!-- <button mat-button color="primary">BUTTON</button> -->
        <button mat-stroked-button color="primary" (click)="dialogService.openSecurityGroupsDialog(node)"><mat-icon>add_circle</mat-icon>Assign Hat</button>
        <button mat-raised-button color="primary" (click)="dialogService.openNewHatDialog(node)"><mat-icon>add</mat-icon>New Hat</button>
        <!-- <button mat-stroked-button color="primary">BUTTON</button> -->
        <!-- <button mat-flat-button color="warn">BUTTON</button> -->
        </mat-card-actions>
    </mat-card>
    `,
    styleUrls: ['../organization-board.component.scss']
})
export class SidebarPositionDetailComponent extends OrbBoardBaseComponent {

    @Input()
    public isDev = false;

    @Input() // TODO: this should come from the Org doc, and be linked to a UID. Only the matching UID should be allowed to edit?
    public isEditing = false;

    @Input()
    public node!: ECONode<Position>;

    @Output()
    public changeNodeColour = new EventEmitter<ECONode<Position>>();

    public obService = inject(OrgBoardService);

    public override ngOnInit(): void {
        // Doesn't need to do anything.
    }

    public get nodeColour() {

        if (!this.node || !this.node.data) {
            return {} as any;
        }

        if (!this.node.data || !this.node.data.colour) {
            this.node.data.colour = { color: '#000000', background: '#FFFFFF', linkColor: '#000000' };
        }

        return this.node.data?.colour;
    }

}
