import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserDetails } from '../services/tree-builder.service';
import { Component, Inject, OnInit } from '@angular/core';
import { Organization, OrgUser } from '@newgenus/common';
import { MaterialModule } from '../../material.module';
import { NormalizePipe } from '../format-pipe';

@Component({
  selector: 'shared-org-user-dialog',
  standalone: true,
  imports: [MaterialModule, NormalizePipe],
  templateUrl: './org-user-details-dialog.html',
  styleUrls: [
    './org-dialog.scss',
    '../organization-board.component.scss'
  ]
})
export class OrgUserDetailsDialogComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<OrgUserDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user: UserDetails, node: any, organization: Organization }
  ) {

  }

  public ngOnInit(): void {
    console.log('OrgUserDetailsDialogComponent > data:', this.data);
  }

  public get orgUserDetails(): OrgUser {
    return this.data.organization.orgUsers[this.data.user.key];
  }

}
