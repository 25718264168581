import { Component, ChangeDetectionStrategy, OnChanges, Input, SimpleChanges } from "@angular/core";
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'shared-client-abc-table-column',
  styleUrls: ['../client-abc.component.scss'],
  template: `<span [@valueAnimation]="value"
    >{{isNumber ? 'R' + (value | number) : entry[column]}}</span>
    <!-- <mat-icon
      [class.uptrend]="isUptrend === true"
      [class.downtrend]="isUptrend === false"
    >{{icon}}</mat-icon> -->
    `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('valueAnimation', [
      transition(':increment', [
        style({ color: '#4a4a4aa3', 'background-color': '#8a8a8aa3' }),
        animate('0.4s ease-out', style('*'))
      ]
      ),
      transition(':decrement', [
        style({ color: '#4a4a4aa3', 'background-color': '#8a8a8aa3' }),
        animate('0.4s ease-out', style('*'))
      ]
      )
    ])
  ]

})
export class SharedInlineClientAbcColumnComponent implements OnChanges {

  @Input()
  public column = '';

  @Input()
  public entry!: any;

  public value = 0;
  public isNumber = false;
  // public icon: string | undefined;
  // public isUptrend = false;
  private columnsToTrend = ['current month', 'current month -1', 'current month -2', 'current month -3', 'current month -4', 'current month -5', 'current month -6']

  public ngOnChanges(changes: SimpleChanges): void {

    if (changes['entry'].firstChange && (['sales average'].concat(this.columnsToTrend).includes(this.column))) {
      this.isNumber = true;
    }

    if (changes['entry'] && (this.column === 'sales average' || this.columnsToTrend.includes(this.column))) {
      // this.setTrendIndicator();
      if (this.isNumber) {
        this.value = this.entry[this.column];
      }
    }
  }

  // private setTrendIndicator(): void {

  //   if (this.column === 'current month') {
  //     //Todo: date cycle specific.
  //     const daySalesAverage = Number(this.entry[this.column]) / moment().date();
  //     const averageDayTarget = Number(this.entry['sales average']) / moment().daysInMonth();

  //     if (daySalesAverage > averageDayTarget) {
  //       this.icon = 'expand_less';
  //       this.isUptrend = true;
  //     } else {
  //       this.icon = 'expand_more';
  //       this.isUptrend = false;
  //     }

  //   } else {
  //     if (Number(this.entry[this.column]) > Number(this.entry['sales average'])) {
  //       this.icon = 'expand_less';
  //       this.isUptrend = true;
  //     } else if (Number(this.entry[this.column]) < Number(this.entry['sales average'])) {
  //       this.icon = 'expand_more';
  //       this.isUptrend = false;
  //     }
  //   }

  // }
}