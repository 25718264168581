import { interval, Subscription, Subject, debounceTime, distinctUntilChanged, takeUntil, takeWhile } from 'rxjs';
import { NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { trigger, animate, style, transition } from '@angular/animations';
import { MatDrawer, MatDrawerContainer } from '@angular/material/sidenav';
import { RightSideBarService } from '@newgenus/angular-shared-components';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ParticleService } from './shared/services/particle.service';
import { SecurityService } from './shared/services/security.service';
import { MatSelectionListChange } from '@angular/material/list';
import { ThemeService } from './shared/services/theme.service';
import { AuthService } from './shared/services/auth.service';
import { StoreUser } from './workspace/user.abstract';
import { MenuHome } from './shared/utils/constants';
import { SwUpdate } from '@angular/service-worker';
import { MenuItem } from '@newgenus/common';
import { Store } from '@ngrx/store';

interface AppState {
  message: string;
}

@Component({
  selector: 'newgenus-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    // Animate Y axis for the height, and change opacity. From 0 to 100% height.
    trigger('smoothHeight', [
      transition(':leave', [
        style({ height: '*' }),
        animate('0.4s ease-out', style({ height: 0 }))
      ]),
      transition(':enter', [
        style({ height: '0' }),
        animate('0.4s ease-out', style({ height: '*' }))
      ]),
    ]),
    // Animate opacity with a delay. From 0 to 100% opacity. :leave has no delay, :enter has a 0.4s delay.
    trigger('delayedOpacity', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.4s ease-out', style({ opacity: 0 }))
      ]),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.4s 0.4s ease-out', style({ opacity: 1 }))
      ]),
    ]),
  ]
})
export class AppComponent extends StoreUser implements OnDestroy, OnInit {

  // public message$: Observable<string>;

  public settings!: any[];
  public hats!: any[];

  public navigationOptions: MenuItem[] = [];
  public subNavSidebarState!: string;
  private msTracker = 0;
  private readonly homeMenu = [MenuHome];
  public selectedOptions: any = this.homeMenu.slice();

  private isFocusMode = false;
  private shouldRefresh = false;
  public hasBackDrop = true;
  public isSmallDisplay = false;
  public loadingRouteConfig = false;
  public showFocusBtn = false;
  public showHeader = true;

  @ViewChild("innerMenuContainer")
  private innerMenuContainer!: MatDrawerContainer;

  @ViewChild('innerMenu', { static: true })
  public innerMenuDrawer!: MatDrawer;

  @ViewChild('drawerSecondary', { static: true })
  public drawerSecondary!: MatDrawer;

  private marginSubscription!: Subscription | null;
  private destroy$ = new Subject<void>();

  constructor(
    private particleService: ParticleService,
    private router: Router,
    private security: SecurityService,
    private themeService: ThemeService,
    protected override store: Store<AppState>,
    public auth: AuthService,
    public sidebarService: RightSideBarService,
    public updates: SwUpdate,
  ) {
    super(store);
    this.initStoreUser(this.destroy$);
    // if (environment.emulator_config.useAuthEmulator) {
    //   this.afStorage.storage.useEmulator('localhost', 9199);
    // }

    // Subscribe to the versionUpdates observable to update the application when a new version is available (service worker / PWA).
    this.updates.versionUpdates.subscribe(event => {
      console.log('SwUpdate.versionUpdates > event:', event);

      if (event.type === 'VERSION_READY') {
        updates.activateUpdate().then(() => {
          if (window.confirm('An update is available. Please refresh your browser.')) location.reload()
        });
      }
    });

  }

  public ngOnInit(): void {

    // This is merely a workaround to adjust the margin of the inner menu container when the sidebar has loaded up.
    this.particleService.sideBarInstantiated$.asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        setTimeout(() => {
          this.subscribeToUpdateMargin();
        });
      });

    // Subscribe to the user$ observable to update the inner menu container state.
    this.user$
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        if (!user) {
          this.innerMenuContainer?.close();
          this.shouldRefresh = true;
        } else if (user !== null && user[0] !== null && this.shouldRefresh) {
          this.innerMenuContainer?.open();
          this.shouldRefresh = false;
        }

        if (user) {
          setTimeout(() => this.subscribeToUpdateMargin());
        }
      });

    /** 
     * Subscribe to the user$ observable to update the navigation options based on the user's permission and auth state.
     * This is a debounced subscription to prevent the menu from being generated too often.
     */
    this.user$.pipe(
      debounceTime(1000),
      takeUntil(this.destroy$)
    ).subscribe(async user => {
      if (user && user[0]) {

        const menu = await this.security.generateSubMenuFromUserAccess();
        if (menu) {
          // Set the focus mode to true if the user has access to the focus mode (collapses the sidebar entirely).
          if (!this.themeService.hasUpdatedFocusBtn) {
            this.themeService.updateShowFocusBtn(true);
          }

          // Set the navigation options to the home menu, top tier menu items, and middle tier menu items.
          this.navigationOptions = this.homeMenu.concat(menu['HOME'].topTierMenuItems.concat(menu['HOME'].middleTierMenuItems));

          // Set the default selection to the first item in the menu.
          this.updateMainNavSelection(this.router.url);
        }
      } else {
        this.navigationOptions = this.homeMenu;
        this.themeService.updateShowFocusBtn(false);
        this.themeService.hasUpdatedFocusBtn = false;
      }
    });

    this.themeService.showFocusBtn$.asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(showFocusBtn => {
        this.showFocusBtn = showFocusBtn;
      });

    // Subscribe to the localSidebarStatus$ observable to update the sub navigation sidebar state.
    this.themeService.localSidebarStatus$.asObservable()
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(sidebarStatus => {
        // Wrapped in settimeout to help the ngAfterViewChecked lifecycle hook.
        setTimeout(() => {
          this.subNavSidebarState = (sidebarStatus) ? "collapsed" : "expanded";
          if (!this.isSmallDisplay) setTimeout(() => this.subscribeToUpdateMargin());
        });
      });

    // Subscribe to the isSmallDisplay$ observable to update the margin of the inner menu container.
    this.themeService.isSmallDisplay$.asObservable()
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe(isSmallDisplay => {
        // Wrapped in settimeout to help the ngAfterViewChecked lifecycle hook.
        setTimeout(() => {
          this.isSmallDisplay = isSmallDisplay;
          this.subscribeToUpdateMargin();
        });
      });

    this.themeService.isFocusModeEnabled$.asObservable()
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe(isFocusMode => {
        this.isFocusMode = isFocusMode;
        if (isFocusMode) {
          this.innerMenuDrawer.close();
        } else {
          this.innerMenuDrawer.open();
        }
      });

    // Subscribe to the showHeader$ observable to update the header state.
    this.themeService.showHeader$.asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(showHeader => {
        this.showHeader = showHeader;
      });

    // Subscribe to router events to update the main navigation selection.
    this.router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {

        if (event instanceof NavigationStart) {
          this.updateMainNavSelection(event.url);
        } else if (event instanceof RouteConfigLoadStart) {
          this.loadingRouteConfig = true;
          // this.flashMessage.show('Loading...')
        } else if (event instanceof RouteConfigLoadEnd) {
          this.loadingRouteConfig = false;
        }

      });

    this.sidebarService.open$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.drawerSecondary.open();
      });

    this.sidebarService.close$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.drawerSecondary.close();
      });
  }

  /**
   * Update the currently selected main navigation item based on the URL.
   *
   * @param {string} newUrl The new URL to check against.
   */
  private updateMainNavSelection(newUrl: string) {
    let moduleFragment = '';

    // If the URL contains '/app/', then the module fragment is the string between the first and second slashes after '/app/'.
    if (newUrl.includes('/app/')) {
      const moduleFragmentStart = newUrl.indexOf('/app/') + 5;
      const moduleFragmentEndIndex = newUrl.indexOf('/', moduleFragmentStart);
      // Setting moduleFragmentEnd to undefined will cause the substring to go to the end of the string, whereas -1 will cause it to invert.
      const moduleFragmentEnd = moduleFragmentEndIndex === -1 ? undefined : newUrl.indexOf('/', moduleFragmentStart);
      moduleFragment = newUrl.substring(moduleFragmentStart, moduleFragmentEnd);
    } else {
      // Usually this won't match to anything that's relevant, but it's here just in case.
      moduleFragment = newUrl.substring(newUrl.indexOf('/', 1) + 1, newUrl.indexOf('/', newUrl.indexOf('/', 1) + 1));
    }

    // Remove the slashes from the module fragment.
    moduleFragment = moduleFragment.replace('/', '').replace('/', '');

    // Update the main navigation menu to show the selected module.
    this.selectedOptions = [this.navigationOptions.find((option: MenuItem) => option.id === moduleFragment)];
  }

  /**
   * Subscribe to the interval that updates the margin of the inner menu container.
   *
   * @private
   * @memberof AppComponent
   */
  private subscribeToUpdateMargin(): void {
    if (!this.marginSubscription) {
      this.marginSubscription = interval(15)
        .pipe(takeWhile((i) => i <= 650))
        .subscribe(() => {
          this.msTracker += 30;
          this.innerMenuContainer.updateContentMargins();
          if (this.msTracker >= 650) {
            this.unsubscribeFromUpdateMargin();
          }
        });
    } else {
      // Reset tracker, user is obviously unsure if they want the menu open or closed. You won't bug me today, Satan.
      this.msTracker = 0;
    }
  }

  /**
   * Unsubscribe from the interval that updates the margin of the inner menu container.
   *
   * @private
   * @memberof AppComponent
   */
  private unsubscribeFromUpdateMargin(): void {
    if (this.marginSubscription) {
      this.marginSubscription.unsubscribe();
      this.msTracker = 0;
      setTimeout(() => { this.marginSubscription = null; });
    }
  }

  /**
   * Callback for when the user selects a main navigation item.
   * Navigate to the selected item's path via the router.
   *
   * @param {MatSelectionListChange} event The event emitted by the mat-selection-list.
   * @memberof AppComponent
   */
  public onMainNavSelect(event: MatSelectionListChange): void {
    const item = event.options[0].value;
    const path = `app/${item.section}` + (item.path ? `/${item.path}` : '');
    this.router.navigate([path]);
  }

  /**
   * Enable or disable focus mode, which collapses the sidebar entirely.
   *
   * @memberof AppComponent
   */
  public toggleFocusMode(): void {
    this.isFocusMode = !this.isFocusMode;
    this.themeService.manualUpdateOfIsFocusMode(this.isFocusMode);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

}

// if (typeof Worker !== 'undefined') {
//   // Create a new
//   const worker = new Worker('./workers/hello.worker', { type: 'module' });
//   worker.onmessage = ({ data }) => {
//     console.log(`page got message: ${data}`);
//   };
//   worker.postMessage('hello');
// } else {
//   // Web Workers are not supported in this environment.
//   console.error("Web Workers are not supported in this environment.");
//   // You should add a fallback so that your program still executes correctly.
// }