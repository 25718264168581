<h2 mat-dialog-title class="m-0">Policies

    <!-- Close button. -->
    <button mat-icon-button mat-dialog-close class="close-button">
        <mat-icon>close</mat-icon>
    </button>
</h2>

<mat-dialog-content class="h-100-70 w-100">

    <mat-tab-group class="w-100" [(selectedIndex)]="tabIndex">
        <!-- New Policies -->
        <mat-tab label="New Policy">

            <div class="view-container m-3">
                <div class="view-header">
                    <span class="viewer-title-spacer">Create new Policy</span>
                    <button mat-raised-button (click)="savePolicy()" color="accent" [disabled]="form.invalid"><mat-icon>save</mat-icon>Save</button>
                </div>
            
                <div class="view-content">
                    <form [formGroup]="form" class="w-100">

                        <div class="flex-row flex-wrap">
                            <mat-form-field appearance="outline">
                                <mat-label>Policy Name</mat-label>
                                <input matInput type="text" formControlName="name" />
                                <mat-hint>By changing the policy name, a revision will be published.</mat-hint>
                            </mat-form-field>
                        </div>
        

                        <!-- TODO: Tiny MCE Editor -->
                        <div class="flex-row flex-wrap">
                            <mat-form-field appearance="outline">
                                <mat-label>Policy Writing</mat-label>
                                <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="6"
                                    formControlName="policyWriting"></textarea>
                                <mat-hint>By changing the policy writing, a revision will be published.</mat-hint>
                            </mat-form-field>
                        </div>

                        <div class="flex-row flex-wrap">
                            <p>TODO: Audience and who this policy affects</p>
                        </div>
                    </form>
                </div>
            </div>

        </mat-tab>


        <!-- Existing Policies -->
        <mat-tab label="Existing Policies">

            <div class="view-container m-3">
                <div class="view-header">
                    <span class="viewer-title-spacer mb-3">Company Policies</span>

                    <mat-form-field class="mw-300" appearance="outline">
                        <mat-label>Search</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Search..." />
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                </div>
                <div class="view-content">
                    <mat-table [dataSource]="dataSource" class="mat-elevation-z5 w-100">

                        <ng-container [matColumnDef]="column.columnDef" *ngFor="let column of columnsWithoutActions">
                            <mat-header-cell *matHeaderCellDef>{{column.header}}</mat-header-cell>
                            <mat-cell *matCellDef="let row">{{column.cell(row)}}</mat-cell>
                        </ng-container>
        
                        <ng-container matColumnDef="actions">
                            <mat-header-cell *matHeaderCellDef class="flex-right mw-5-5rem">Actions </mat-header-cell>
                            <mat-cell *matCellDef="let row" class="flex-right mw-5-5rem">
                                <button mat-icon-button aria-label="Open actions" [matMenuTriggerFor]="menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
        
                                <mat-menu #menu="matMenu">
                                    <button *ngFor="let opt of columnActions" mat-menu-item [attr.color]="opt.color" [disabled]="opt.disabled"
                                        (click)="opt.action(row)">{{opt.display}}</button>
                                </mat-menu>
                            </mat-cell>
                        </ng-container>
        
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row matRipple *matRowDef="let row; columns: displayedColumns;" (dblclick)="editPolicy(row)"></mat-row>
                    </mat-table>
                </div>
            </div>


        </mat-tab>
    </mat-tab-group>




</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>