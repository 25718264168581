import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { UserActions } from '../user/action-types';
import { createReducer, on } from '@ngrx/store';
import { User } from '@newgenus/common';

// Entity adapter & initial state
export interface UserState extends EntityState<User> {
    allUsersLoaded: boolean; // set initial data
    particleSortOrder: any;
}

export const adapter = createEntityAdapter<User>({
    // sortComparer: compareParticles,
    selectId: user => user.uid,
});

export const initialUserState = adapter.getInitialState({
    allUsersLoaded: false,
    particleSortOrder: {
        name: 'creationDate',
        direction: 'asc',
    },
});

// Reducer functions

export const userReducer = createReducer(
    initialUserState,

    on(UserActions.allUsersLoaded, (state, action) => {
        return adapter.setAll(action.user, { ...state, allUsersLoaded: true });
    }),

    on(UserActions.usersAdded, (state, action) => {
        return adapter.setOne(action.payload, { ...state, allUsersLoaded: true });
    }),

    on(UserActions.userRemoved, (state, action) => adapter.removeOne(action.payload.uid as string, state)),

    on(UserActions.userModified, (state, action) => {
        // delete action.payload.content;
        return adapter.updateOne({ id: action.payload.uid as string, changes: action.payload }, state);
    }),

    on(UserActions.updateUserParticleSort, (state, action) => {
        // delete action.payload.content;
        return adapter.updateOne({ id: action.payload.uid as string, changes: action.payload }, state);
    }),

    on(UserActions.userUpdateSortOrder, (state, action) => {
        return adapter.updateOne({ id: action.payload.uid as string, changes: action.payload }, state);
    })
);

export const { selectAll } = adapter.getSelectors();
