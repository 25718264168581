import { FirestoreChunk, IqConfigurationStripped, IqStrippedDebtor } from "./fe-models/app.models";
import { DateSettings } from "./fe-models/organization-models";
import { QuerySnapshot } from "@angular/fire/compat/firestore";
import { Timestamp } from "firebase/firestore";
import { ClientAbcTransactionTypes, User } from "./fe-models/user";
import { Observable } from "rxjs";

export interface DataEntry {
    name: string;
    position: number;
    weight: number;
    symbol: string;
}

export interface RepSales extends Record<string, any> {
    key: string;
    name: string;
    sales: string;
    credits: string;
    nett: string;
    grossProfit: string;
    gp: string;
    actions: string;
    // [monthKey: string]: IQSaleSummary ;
}

export interface DebtTranDatum {
    account: string;
    amount: number;
    created: Date;
    credit: number;
    creditTax: number;
    date: Date;
    dc: string;
    debit: number;
    debitTax: number;
    linkAccount: string;
    number: number;
    profit: number;
    reference: string;
    rep: number;
    type: string;
    user: number;
    collection: 'debtTran';
}

export interface SalesOrderDatum {
    accnum: string;
    amounttendered: number;
    areacode: string;
    cadrounding: number;
    cashier: number;
    cashiernm: string;
    change: number;
    changed: boolean;
    changeddate: any;
    charge: number;
    created: any;
    credappr: number;
    credapprname: string;
    currency: string;
    currencyrate: number;
    date: any;
    deposit: number;
    depositpaid: number;
    discountam: number;
    discountp: number;
    discounttype: number;
    doctype: string;
    document: string;
    hours: number;
    inclusive: boolean;
    internalorder: string;
    invdate: any;
    ispos: boolean;
    labour: number;
    linkaccount: string;
    name: string;
    number: number;
    orddate: any;
    ordernoint: string;
    pickdate: any;
    picker: number;
    pickname: string;
    pmntmethod: string;
    product: string;
    qtotal: number;
    rep: number;
    roundingdiscount: number;
    status: number;
    statusdes: string;
    taxno: string;
    terms: number;
    till: number;
    total: number;
    totalitems: number;
    totalvat: number;
    workshop_inv: boolean;
    collection: 'salesOrder';
}

export interface IQRepMonthSummary {
    [repKey: string]: {
        [monthKey: string]: IQSaleSummary
    }
}

export interface IQSaleSummary extends Record<string, any> {
    ['debtTran-count']: number;
    ['debtTran-debits']: number;
    ['debtTran-credits']: number;
    ['debtTran-profit']: number;
    ['debtTran-debitTax']: number;
    ['debtTran-creditTax']: number;

    ['salesOrder-count']: number;
    ['salesOrder-gross']: number;
    ['salesOrder-nett']: number;
    ['salesOrder-tax']: number;

    ['total-count']: number;
    ['total-debits']: number;
    ['total-credits']: number;
    ['total-profit']: number;
    ['total-debitTax']: number;
    ['total-creditTax']: number;

    codeTypeSummary: {
        ['debtTran-BC']: number;
        ['debtTran-CN']: number;
        ['debtTran-DS']: number;
        ['debtTran-IN']: number;
        ['debtTran-IT']: number;
        ['debtTran-JC']: number;
        ['debtTran-JD']: number;
        ['debtTran-PM']: number;
        ['debtTran-RE']: number;
        ['debtTran-RF']: number;
        // ['salesOrder-open-nett']: number;
        ['salesOrder-open']: number;
    } & Record<string, number>,

    codeTypeTax: {
        ['debtTran-BC']: number;
        ['debtTran-CN']: number;
        ['debtTran-DS']: number;
        ['debtTran-IN']: number;
        ['debtTran-IT']: number;
        ['debtTran-JC']: number;
        ['debtTran-JD']: number;
        ['debtTran-PM']: number;
        ['debtTran-RE']: number;
        ['debtTran-RF']: number;
        // ['salesOrder-open-nett']: number;
        ['salesOrder-open']: number;
    } & Record<string, number>,
};

export const enum SettingsAppearance {
    'gross&tax' = 'gross&tax',
    'nett&tax' = 'nett&tax',
    'nett' = 'nett',
    'gross' = 'gross',
}

export const enum SettingsType {
    'clientAbc' = 'clientAbc',
    'summaryByCode' = 'summaryByCode',
}

/**
 * The Newgenus Sales Transaction interface.
 * This interface is used to represent a sales transaction in the Newgenus Sales Transaction collection.
 *
 * @export
 * @interface NewgenusSalesTransaction
 */
export interface NewgenusSalesTransaction extends GraphDataBase {
    /**
     * The date when the transaction happened (IQ: "created" date).
     */
    date: Date;
    // date: firebase.firestore.Timestamp;

    /**
     * The transaction nett amount (excluding tax).
     */
    nett: number;

    /**
     * The transaction tax amount.
     */
    tax: number;

    /**
     * The transaction gross amount (including tax).
     */
    gross: number;

    /**
     * The key of the user who's responseible for this record (IQ: "repNo").
     */
    userKey: string;

    /**
     * Another ID field for the document that holds this transaction.
     */
    document: string;

    /**
     * The ID/Reference of the inflowing transaction being recorded (IQ: "SAL" number - the unique number for each sales order).
     */
    referenceKey: string;

    /**
     * Auto generated key for the transaction.
     */
    key: string;

    /**
     * A field indicating the type of the original source transaction (IQ: this would be "SalesOrder" or "Invoice").
     */
    type: NewgenusSalesTransactionType;

    /**
     * A field indicating if this transaction is no longer valid ("true") as a new transaction has superseded it.
     */
    isSuperseded: boolean;

}

export const enum IqInvoiceType {
    'I' = 'I',  // Invoice
    'G' = 'G',  // GRV
    'C' = 'C'   //Credit Note
}

export interface NewgenusSalesTransactionInvoice extends NewgenusSalesTransaction {
    invoiceType: IqInvoiceType;
}

export interface NewgenusSalesTransactionSalesOrder extends NewgenusSalesTransaction {
    isRevisedSalesOrder: boolean;
}

export interface ComplexGraphData extends NewgenusSalesTransaction, GraphDataBase {
    creditNoteGross: number;
    creditNoteNett: number;
    creditNoteTax: number;
    invoicedCompounded: number;
    invoicedGross: number;
    invoicedNett: number;
    invoicedTax: number;
    nettOutstanding: number;
    salesOrderGross: number;
    salesOrderNett: number;
    salesOrdersCompounded: number;
    salesOrderTax: number;
    value: number;
    valueCompounded: number;
    repKey?: number;
    repKeys?: number[];
}

export interface GraphDataBase {
    valueCompounded: number;
    date: Date;
}

export interface ConsolidatedComplexGraphData extends ComplexGraphData {
    salNumbers: string[];
}

export function isSalesTransactionInvoice(transaction: NewgenusSalesTransaction): transaction is NewgenusSalesTransactionInvoice {
    return transaction.type === NewgenusSalesTransactionType.invoice;
}

export function isSalesTransactionSalesOrder(transaction: NewgenusSalesTransaction): transaction is NewgenusSalesTransactionSalesOrder {
    return transaction.type === NewgenusSalesTransactionType.salesOrder;
}

/**
 * The Newgenus Sales Transaction Type enum.
 * This enum is used to represent the type of the original source transaction (IQ: this would be "SalesOrder" or "Invoice").
 *
 * @export
 * @enum {string}
 */
export const enum NewgenusSalesTransactionType {
    'invoice' = 'invoice',
    'salesOrder' = 'salesOrder',
    'carried-over' = 'carried-over', // For consolidation only...
}

export interface ClientAbcTableDatumShared {
    "current month -1": number;
    "current month -2": number;
    "current month -3": number;
    "current month -4": number;
    "current month -5": number;
    "current month": number;
    "sales average": number;
    "rank": number;
}

export interface ClientAbcTableDatum extends ClientAbcTableDatumShared {
    account: string;
    customer: string;
}

export interface ClientAbcGroupTableDatum extends ClientAbcTableDatumShared {
    'group name': string;
    salesSummary: Array<ClientAbcTableDatum> | undefined;
    groupKey: string;
    dueForProcess: boolean;
    figuresAre: SettingsAppearance;
    summaryLocation?: string;
}

export interface ClientAbcDocument {
    dateCycleKey: string;
    key: string;
    lastUpdated: Timestamp;
    lastUpdatedTop25Clients: Timestamp | undefined;
    top25Clients: ClientAbcSalesSummary | null
    monthEnd: number;
    monthStart: number;
    repNo: string;
    salesSummary: ClientAbcSalesSummary | null;
    salesSummaryLocation: string;
    year: number;
    yearEnd: Timestamp;
    yearStart: Timestamp;
}

export interface ClientAbcSalesSummary {
    [branch: string]: { [monthKey: string]: ClientAbcMonthSummary };
}

export interface ClientAbcMonthSummary {
    account: string;
    branch: string;
    client: string;
    code_bc_tax: number;
    code_bc: number;
    code_cn_tax: number;
    code_cn: number;
    code_ds_tax: number;
    code_ds: number;
    code_in_tax: number;
    code_in: number;
    code_jc_tax: number;
    code_jc: number;
    code_jd_tax: number;
    code_jd: number;
    code_pm_tax: number;
    code_pm: number;
    code_re_tax: number;
    code_re: number;
    code_rf_tax: number;
    code_rf: number;
    creditgross: number;
    credittax: number;
    debitgross: number;
    debittax: number;
    gross_sales: number;
    linkaccount: string;
    name: string;
    nett_sales: number;
    rep: number;
    yearmonthkey: string;
}

export interface ClientAbcGroupSummaries {
    nett: ClientAbcTableDatumShared & { 'group name': string; };
    gross: ClientAbcTableDatumShared & { 'group name': string; };

    code_bc_tax: ClientAbcTableDatumShared & { 'group name': string; };
    code_bc: ClientAbcTableDatumShared & { 'group name': string; };
    code_cn_tax: ClientAbcTableDatumShared & { 'group name': string; };
    code_cn: ClientAbcTableDatumShared & { 'group name': string; };
    code_ds_tax: ClientAbcTableDatumShared & { 'group name': string; };
    code_ds: ClientAbcTableDatumShared & { 'group name': string; };
    code_in_tax: ClientAbcTableDatumShared & { 'group name': string; };
    code_in: ClientAbcTableDatumShared & { 'group name': string; };
    code_jc_tax: ClientAbcTableDatumShared & { 'group name': string; };
    code_jc: ClientAbcTableDatumShared & { 'group name': string; };
    code_jd_tax: ClientAbcTableDatumShared & { 'group name': string; };
    code_jd: ClientAbcTableDatumShared & { 'group name': string; };
    code_pm_tax: ClientAbcTableDatumShared & { 'group name': string; };
    code_pm: ClientAbcTableDatumShared & { 'group name': string; };
    code_re_tax: ClientAbcTableDatumShared & { 'group name': string; };
    code_re: ClientAbcTableDatumShared & { 'group name': string; };
    code_rf_tax: ClientAbcTableDatumShared & { 'group name': string; };
    code_rf: ClientAbcTableDatumShared & { 'group name': string; };
}


export const enum ClientAbcDynamicGroups {
    'Main Account' = 'Main Account',
    'No grouping' = 'No grouping'
}

export interface ClientAbcGroup {

    /**
     * The accounts that belong to the group.
     */
    accounts: string[];

    /**
     * The user who created the group.
     */
    createdBy: string;

    /**
     * The date when the group was created.
     */
    dateCreated: Timestamp;

    /**
     * The date when the group was last updated by any user or the system.
     */
    lastUpdated: Timestamp;

    /**
     * The detailed summary of each account in the group.
     */
    accountSalesSummary: ClientAbcSalesSummary | null;

    /**
     * Where the group summary is stored in the cloud storage.
     */
    accountSalesSummaryLocation: string;

    /**
     * The date when the group was last processed by the backend.
     */
    lastProcessedOn: Timestamp;

    /**
     * Unique key for the group.
     */
    key: string;

    /**
     * The org key for the group.
     */
    orgKey: string;

    /**
     * The rep name of who the group is for.
     * @optional
     */
    repName?: string;

    /**
     * The rep number of who the group is for.
     */
    repNo?: string;

    /**
     * The name of the group.
     */
    title: string;

    /**
     * The key of the user who the group is for.
     */
    userKey: string;

    settings: {
        transactionTypes: ClientAbcTransactionTypes[],
        dynamicGrouping: ClientAbcDynamicGroups,
        appearance: SettingsAppearance,
    }

    configKey: string;
    dateCycleKey: string;
    year: number;
    dueForProcess: boolean;

    /**
     * Top level summaries for the group, by nett, gross, and transaction type.
     */
    groupSummaries: GroupSummaryValues | null
}


export type GroupSummaryValues = {
    [tranType: string]: ClientAbcGroupTableDatum;
    nett: ClientAbcGroupTableDatum;
    gross: ClientAbcGroupTableDatum;
  };

export interface ClientAbcGroupDialogData {
    user: User;
    debtorsObs: Observable<QuerySnapshot<FirestoreChunk<IqStrippedDebtor>>>;
    currentIntegration: Partial<IqConfigurationStripped>;
    currentDateCycle: DateSettings;
    groupToEdit?: ClientAbcGroup;
    existingGroups?: ClientAbcGroup[];
}

export interface YearMonthDateKeyStats {
    [yearmonthkey: string]: {
        [typeKey: string]: {
            // threeMonthAverage: number;
            // weekAverage: number;
            // dayAverage: number;
            turnover: number;

            count: number;
            credit: number;
            creditTax: number;
            debit: number;
            debitTax: number;
            profit: number;
        };
    };
}

export const enum GraphStatus {
    INIT = 'INIT',
    DRAWN = 'DRAWN',
    UPDATING = 'UPDATING',
    LOADED = 'LOADED',
    ERROR = 'ERROR',
}

export interface GraphData extends GraphDataBase {
    /**
     * @deprecated - use explicit fields instead.
     */
    value: number; // Nett

    /**
     * Nett = total, VAT excluded.
     * 
     * - For DebtTran, this is less credits and can be written as so:  
     *      `Nett Sales = debits - debitTax - credits + creditTax.`  
     * - For SalesOrders, this is `total x currencyrate - totalvat * currencyrate`.
     */
    nett: number;

    /**
     * Gross = total, VAT included.
     * 
     * - For DebtTran, this is `debit`.  
     * - For SalesOrders, this is `total x currencyrate`.
     */
    gross: number;

    /**
     * - For DebtTran, this is `debit - debitTax`.
     * - Not available for SalesOrders.
     */
    sales?: number;

    /**
     * Currently not used anywhere.
     * 
     * - For DebtTran:
     * Credits Notes = credits - creditTax.
     * - Not available for SalesOrders.
     */
    credits?: number;

    date: Date;
    key: string;
    valueCompounded: number;
    repKey: number;
    repKeys?: number[];
    id?: string;
}

export interface GraphDataConsolidated extends GraphData {
    data: Array<GraphData>;
}

export const enum GraphLine {
    'primary' = 'primary',

    /**
     * For Daily graph, this would be the sales orders.
     */
    'secondary' = 'secondary',

    /**
     * For Daily graph, this would be the invoices.
     */
    'tertiary' = 'tertiary',
}
