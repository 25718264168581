import { SelectFolderOrderDisplayModalComponentComponent } from './shared/modals/select-folder-order-display-modal-component/select-folder-order-display-modal-component.component';
import { AngularFireAnalyticsModule, DEBUG_MODE, ScreenTrackingService, UserTrackingService } from '@angular/fire/compat/analytics';
import { ManageFolderModalContentComponent } from './shared/components/manage-folder-modal-content/manage-folder-modal-content';
import { AngularFirestoreModule, USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { NavigationActionTiming, RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { AngularFireAuth, USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { FileUploadComponent } from './shared/components/file-upload/file-upload.component';
import { CustomSortComponent } from './shared/components/custom-sort/custom-sort.component';
import { GlobalErrorHandlerService } from './shared/services/global-error-handler.service';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { particlesSortOrderReducer } from './redux/reducers/particle.sortOrder.reducers';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import { USE_EMULATOR as USE_DATABASE_EMULATOR } from '@angular/fire/compat/database';
import { USE_EMULATOR as USE_STORAGE_EMULATOR } from '@angular/fire/compat/storage';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { SETTINGS as FIRESTORE_SETTINGS } from '@angular/fire/compat/firestore';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DynamicFormModule } from './shared/dynamic-forms/dynamic-form.module';
import { LoggingInComponent } from './auth/logging-in/logging-in.component';
import { QuestionService } from './shared/dynamic-forms/question.service';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { RightSideBarService } from '@newgenus/angular-shared-components';
import { ParticleHelper } from './shared/utils/helpers/particle-helper';
import { SAVER, getSaver } from './shared/utils/helpers/saver.provider';
import { HeaderComponent } from './components/header/header.component';
import { SplashComponent } from './components/splash/splash.component';
import { workspaceReducer } from './redux/reducers/workspace.reducers';
import { particlesReducer } from './redux/reducers/particle.reducers';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ParticleService } from './shared/services/particle.service';
import { NetworkService } from './shared/services/network.service';
import { EmailHelper } from './shared/utils/helpers/email-helper';
import { CypressTestData } from './shared/data/cypress-test-data';
import { orgsReducer } from './redux/organizations/org.reducers';
import { searchReducer } from './redux/reducers/search.reducers';
import { routesReducer } from './redux/reducers/routes.reducers';
import { MatExpansionModule } from '@angular/material/expansion';
import { LogoutComponent } from './auth/logout/logout.component';
import { APP_INITIALIZER, enableProdMode } from '@angular/core';
import { ErrorService } from './shared/services/error.service';
import { DraftService } from './shared/services/draft.service';
import { ThemeService } from './shared/services/theme.service';
import { AlertService } from './shared/services/alert.service';
import { DateFunctions } from './shared/utils/date-functions';
import { LoginComponent } from './auth/login/login.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { userReducer } from './redux/reducers/user.reducers';
import { AuthService } from './shared/services/auth.service';
import { InitService } from './shared/services/init.service';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { PermissionGuard } from './permission-guard.service';
import { MatSelectModule } from '@angular/material/select';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MatDialogModule } from '@angular/material/dialog';
import { ApiService } from './shared/services/api.service';
import { MatButtonModule } from '@angular/material/button';
import { environment } from '../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { metaReducers, reducers } from './redux/reducers';
import { AngularFireModule } from '@angular/fire/compat';
import { PERSISTENCE } from '@angular/fire/compat/auth';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppRoutingModule } from './app-routing.module';
import { PipeModule } from './shared/pipes/pipe.module';
import { HttpClientModule } from '@angular/common/http';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { AuthGuard } from './auth-guard.service';
import { AppComponent } from './app.component';
import { EffectsModule } from '@ngrx/effects';
import { FormsModule } from '@angular/forms'
import { StoreModule } from '@ngrx/store';
import { LoggerModule } from 'ngx-logger';
import { MomentModule } from 'ngx-moment';
import 'firebase/storage';

// Resolves/bypasses https://github.com/angular/angularfire/issues/2656
export function initializeApp(afAuth: AngularFireAuth): () => Promise<null> {
  return () => {
    return new Promise<null>((resolve) => {

      DateFunctions.setupMomentForSouthAfricaOfYear2023();

      if (!environment.emulator_config.useAuthEmulator) {
        return resolve(null);
      } else {

        afAuth.useEmulator(`http://${environment.isDockerized ? 'emulator' : 'localhost'}:9099/`).then(() => {
          resolve(null);
        });
      }
    });
  };
}

if (environment.production) {
  enableProdMode();
}

@NgModule({
  declarations: [
    AppComponent,
    CustomSortComponent,
    FileUploadComponent,
    HeaderComponent,
    SplashComponent,
    LoginComponent,
    ForgotPasswordComponent,
    LogoutComponent,
    LoggingInComponent,
    ManageFolderModalContentComponent,
    SelectFolderOrderDisplayModalComponentComponent,
    SidebarComponent,
  ],
  imports: [
    EffectsModule.forRoot([]),
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    DragDropModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatSelectModule,
    MatToolbarModule,
    OverlayModule,
    PipeModule,
    ReactiveFormsModule,
    DynamicFormModule,
    // TranslateModule.forRoot(),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        // strictActionSerializability: true,
        // strictStateSerializability:true
      }
    }),
    StoreModule.forFeature('users', userReducer),
    StoreModule.forFeature('particles', particlesReducer,),
    StoreModule.forFeature('selectedSort', particlesSortOrderReducer),
    StoreModule.forFeature('workspace', workspaceReducer),
    StoreModule.forFeature('search', searchReducer),
    StoreModule.forFeature('orgs', orgsReducer),
    StoreModule.forFeature('routes', routesReducer),
    StoreDevtoolsModule.instrument({
      maxAge: 20 // number of states to retain
    }),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      navigationActionTiming: NavigationActionTiming.PostActivation,
      routerState: RouterState.Minimal
    }),
    LoggerModule.forRoot(
      {
        serverLoggingUrl: environment.logServerUrl,
        level: environment.logLevel,
        serverLogLevel: environment.logServerLevel
      }
    ),
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        'm': 59
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.useServiceWorker,
      // Register the ServiceWorker as soon as the application is stable
      // or after 10 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:10000'

      // Register the ServiceWorker after 10 seconds.
      // registrationStrategy: 'registerWithDelay:10000'
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(),
    AngularFireAnalyticsModule

  ],
  providers: [
    AlertService,
    ApiService,
    AuthGuard,
    PermissionGuard,
    AuthService,
    CypressTestData,
    DraftService,
    DragDropModule,
    EmailHelper,
    ErrorService,
    ThemeService,
    NetworkService,
    ParticleHelper,
    ParticleService,
    QuestionService,
    // TranslateService,
    RightSideBarService,
    { provide: SAVER, useFactory: getSaver },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AngularFireAuth],
      useFactory: initializeApp
    },
    { provide: USE_AUTH_EMULATOR, useValue: environment.emulator_config.useAuthEmulator ? [environment.isDockerized ? 'emulator' : 'localhost', 9099] : undefined },
    { provide: USE_DATABASE_EMULATOR, useValue: environment.useEmulators ? [environment.isDockerized ? 'emulator' : 'localhost', 9000] : undefined },
    { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? [environment.isDockerized ? 'emulator' : 'localhost', 8080] : undefined },
    { provide: USE_STORAGE_EMULATOR, useValue: environment.useEmulators ? [environment.isDockerized ? 'emulator' : 'localhost', 9199] : undefined },
    { provide: FIRESTORE_SETTINGS, useValue: (window as any).Cypress ? { experimentalForceLongPolling: true } : undefined },
    { provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? [environment.isDockerized ? 'emulator' : 'localhost', 5001] : undefined },
    { provide: PERSISTENCE, useValue: 'local' },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    { provide: DEBUG_MODE, useValue: environment.env === 'develop' },
    ScreenTrackingService,
    UserTrackingService,
  ],
  bootstrap: [AppComponent],
  // #region -- This is for dynamically added components that are added using ViewContainerRef.createComponent(). 
  // Adding them to entryComponents tells the offline template compiler to compile them and create factories for them.
  // The components registered in route configurations are added automatically to entryComponents as well because router-outlet also uses ViewContainerRef.createComponent() to add routed components to the DOM.
  // Offline template compiler (OTC) only builds components that are actually used. If components aren't used in templates directly the OTC can't know whether they need to be compiled. With entryComponents you can tell the OTC to also compile this components so they are available at runtime.
  // #endregion -- 
  // entryComponents: [
  //   CustomSortComponent,
  //   FileUploadComponent,
  //   ManageFolderModalContentComponent,
  //   SelectFolderOrderDisplayModalComponentComponent,
  // ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

export function initFunction(config: InitService) {
  return () => config.init();
}

