import { DynamicTableColumn, Organization, OrgPolicy, TableAction } from '@newgenus/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Component, inject, Inject, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MaterialModule } from '../../../material.module';
import { NgFor, TitleCasePipe } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { NormalizePipe } from '../../format-pipe';

@Component({
  selector: 'shared-org-policy-dialog',
  standalone: true,
  imports: [MaterialModule, NgFor, TitleCasePipe, NormalizePipe, ReactiveFormsModule, MatMenuModule, MatButtonModule, MatIconModule],
  templateUrl: './org-policy-dialog.html',
  styleUrls: [
    './org-policy-dialog.scss',
    '../org-dialog.scss',
    '../../organization-board.component.scss'
  ],
})
export class OrgPolicyDialogComponent implements OnInit {

  public form = new FormGroup({
    createdDate: new FormControl<Date | null>(new Date),
    key: new FormControl<string | null>(null),
    lastModifiedBy: new FormControl<string | null>(null),
    lastModifiedDate: new FormControl<Date | null>(new Date()),
    name: new FormControl<string | null>(null, Validators.compose([Validators.required, Validators.minLength(3)])),
    policyWriting: new FormControl<string | null>(null, Validators.compose([Validators.required, Validators.minLength(20)])),
    revisions: new FormControl<any | null>(null),
    version: new FormControl<number | null>(1),
  });

  public columns: DynamicTableColumn<OrgPolicy>[] = [
    {
      columnDef: 'name',
      header: 'Name',
      cell: (element: OrgPolicy) => element.name || '',
    },
    {
      columnDef: 'lastModifiedDate',
      header: 'Last Updated',
      cell: (element: OrgPolicy) => element.lastModifiedDate?.toDateString() || '',
    },
    {
      columnDef: 'policyWriting',
      header: 'Policy',
      cell: (element: OrgPolicy) => element.policyWriting || '',
    },
    // {
    //   columnDef: 'revisions',
    //   header: 'Revisions',
    //   cell: (element: OrgPolicy) => element.revisions?.map(r => r.date.toDateString()).join(', ')  || '',
    // },
    {
      columnDef: 'version',
      header: 'Version',
      cell: (element: OrgPolicy) => element.version?.toString() || '',
    },
    {
      columnDef: 'actions',
      header: '',
      cell: (row) => '',
    },
  ];
  public columnActions: TableAction<OrgPolicy>[] = [
    {
      display: 'Update',
      action: (row) => this.editPolicy(row),
      color: 'primary',
      disabled: false
    },
  ];

  public dataSource = new MatTableDataSource<OrgPolicy>([]);
  public displayedColumns = this.columns.map(c => c.columnDef);
  public tabIndex = 0;

  public snackbar = inject(MatSnackBar);
  private db = inject(AngularFirestore);

  constructor(
    public dialogRef: MatDialogRef<OrgPolicyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      organization: Organization,
    }
  ) { }

  public ngOnInit(): void {
    const keys: string[] = this.data.organization?.orgPolicies?.policiesKeys || [];
    const newTableData: OrgPolicy[] = [];
    if (keys) keys.forEach(key => newTableData.push(this.data.organization.orgPolicies[key]));

    this.dataSource.data = newTableData;
  }

  public savePolicy() {
    console.log('Save policy: ', this.form.value);
    const updateBody = this.form.value;
    const policyKey = updateBody.key || this.db.createId();
    this.db
      .collection('organizations')
      .doc(this.data.organization.key)
      .collection('policies')
      .doc(policyKey)
      .set(updateBody, { merge: true })
      .then(() => {
        this.snackbar.open(`Version ${this.form.value.version} of policy saved`, 'Close', { duration: 2000 });
        this.form.reset();
      })
      .catch((error) => {
        console.error('Error saving policy: ', error);
        this.snackbar.open('Error saving policy', 'Close', { duration: 2000 });
      });

  }

  public get columnsWithoutActions(): DynamicTableColumn[] {
    return this.columns.filter(c => c.columnDef !== 'actions');
  }

  public editPolicy(row: OrgPolicy): any {
    console.log('Edit policy: ', row);

    // Set the form values.
    this.form.reset();
    this.form.patchValue(row);
    this.tabIndex = 0;

    // Ensure the version is incremented.
    this.form.controls['version'].setValue(row.version !== null && row.version !== undefined ? row.version + 1 : 2);
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value || '';
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
