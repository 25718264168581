import { FileMetadata, ParticleNote, ParticleTaskMap } from "../interfaces/fe-models/particle";
import { ClientOption } from "../interfaces/fe-models/app.models";
import { ParticleStatus } from "../interfaces/fe-models/enums";
import { SendFrom } from "../interfaces/fe-models/user";
import { Timestamp } from "firebase/firestore";

export class Particle {
    public toBlankDraft(userId: string, email: string, mimetype: string, content = ''): void {
        this.app = '';
        this.attachments = [];
        this.bcc = [];
        this.cc = [];
        this.clientEmailAddress = email;
        this.content = content;
        this.creationDate = Timestamp.now();
        this.draftId = '';
        this.error = null;
        this.folders = {};
        this.from = email;
        this.id = '';
        this.internalDate = Timestamp.now();
        this.labelIds = ['DRAFT', 'INBASKET', 'EMAIL'];
        this.mimeType = mimetype;
        this.order = 0;
        this.particleKey = '';
        this.particleType = 'draft';
        this.position = 0;
        this.readStatus = true;
        this.snippet = '';
        this.subject = '';
        this.temp = false;
        this.threadId = '';
        this.to = [];
        this.uid = userId;
    }

    public toBlankSystem(userId: string): void {
        this.app = '';
        this.creationDate = Timestamp.now();
        this.folders = {};
        this.id = '';
        this.internalDate = Timestamp.now();
        this.error = null;
        this.labelIds = ['INBASKET'];
        this.order = 0;
        this.particleKey = '';
        this.particleType = 'system';
        this.position = 0;
        this.readStatus = true;
        this.snippet = '';
        this.subject = '';
        this.uid = userId;
        this.temp = false;
    }

    public toDraftReply(
        userId: string,
        sendFrom: SendFrom,
        mimetype: string,
        content: string,
        particleDetails: Particle,
        clientOptions: Array<ClientOption>
    ): void {
        this.toBlankDraft(userId, sendFrom.emailKey, mimetype, content);
        this.particleType = particleDetails.particleType;
        this.subject = 'RE: ' + particleDetails.subject;
        this.threadId = particleDetails.threadId;

        const fromAddress = this.determineFromAddress(sendFrom, clientOptions, particleDetails, true);
        this.from = fromAddress.emailKey;
        this.client = fromAddress.client;
        this.to = [particleDetails.from];

        this.forwardFromMessageId = particleDetails.emailId as string;
        // Copy inline images across.
        this.attachmentsMeta = this.attachmentsMeta ?? {};
        Object.entries(particleDetails.attachmentsMeta || {})
            .filter((x) => x[1].isInline)
            .forEach((c) => {
                this.attachmentsMeta[c[0]] = c[1];
                this.attachments.push(c[0])
            });
    }

    public toDraftReplyAll(
        userId: string,
        sendFrom: SendFrom,
        mimetype: string,
        content: string,
        particleDetails: Particle,
        clientOptions: Array<ClientOption>
    ): void {
        this.toBlankDraft(userId, sendFrom.emailKey, mimetype, content);
        this.particleType = particleDetails.particleType;
        this.subject = 'RE: ' + particleDetails.subject;
        this.threadId = particleDetails.threadId;

        const fromAddress = this.determineFromAddress(sendFrom, clientOptions, particleDetails, true);

        this.from = fromAddress.emailKey;
        this.client = fromAddress.client;
        this.to = [
            `${particleDetails.from}`,
            ...(particleDetails.to || []).filter((c) => c.toLowerCase() !== fromAddress.emailKey),
        ];
        this.cc = particleDetails.cc?.filter((c) => c.toLowerCase() !== fromAddress.emailKey);
        this.bcc = particleDetails.bcc?.filter((c) => c.toLowerCase() !== fromAddress.emailKey);

        this.forwardFromMessageId = particleDetails.emailId as string;
        // Copy inline images across.
        this.attachmentsMeta = this.attachmentsMeta ?? {};
        Object.entries(particleDetails.attachmentsMeta || {})
            .filter((x) => x[1].isInline)
            .forEach((c) => {
                this.attachmentsMeta[c[0]] = c[1];
                this.attachments.push(c[0])
            });
    }

    public toDraftForward(
        userId: string,
        sendFrom: SendFrom,
        mimetype: string,
        content: string,
        particleDetails: Particle,
        filesMap: any
    ): void {
        this.toBlankDraft(userId, sendFrom.emailKey, mimetype, content);
        this.particleType = particleDetails.particleType;
        this.subject = 'FW: ' + particleDetails.subject;
        this.threadId = particleDetails.threadId;
        this.forwardFromMessageId = particleDetails.emailId as string;

        this.attachmentsMeta = { ...particleDetails.attachmentsMeta, ...filesMap };

        // Append only external file names to attachments.
        this.attachments = Object.entries(this.attachmentsMeta)
            .filter((x) => !x[1].isInline)
            .map((c) => c[0]);
        // console.log('toDraftForward > this.attachments', this.attachments);

        // const files = [];
        // if (filesMap) {
        //     const keys = Object.keys(Object.entries(filesMap).filter);
        //     for (const key of keys) {
        //         files.push(key);
        //     }
        //     this.attachments = files;
        // }
    }

    public determineFromAddress(
        sendFrom: SendFrom,
        clientOptions: ClientOption[],
        particleDetails: Particle,
        forceDontUseDefault = false
    ): SendFrom {
        const toMatches: Array<ClientOption> = [],
            ccMatches: Array<ClientOption> = [],
            bccMatches: Array<ClientOption> = [];
        let fromAddress = sendFrom;

        clientOptions.forEach((co) => {
            particleDetails.to?.forEach((to) => (to.includes(co.emailKey) ? toMatches.push(co) : undefined));
            particleDetails.cc?.forEach((cc) => (cc.includes(co.emailKey) ? toMatches.push(co) : undefined));
            particleDetails.bcc?.forEach((bcc) => (bcc.includes(co.emailKey) ? toMatches.push(co) : undefined));
        });

        // Set to remove potentially duplicate emails (aliases, etc).
        const recipientArr = [...new Set(toMatches.concat(ccMatches).concat(bccMatches))];

        const defaultEmailEntry = recipientArr.find((x) => x.emailKey === sendFrom.emailKey);
        // The default sendFrom is in the recipient list, use it.
        if (!forceDontUseDefault && defaultEmailEntry) fromAddress = defaultEmailEntry;
        // This email wasn't sent to the default sendFrom address. Use the first match in the RecipientArr.
        else if (recipientArr.length > 0) {
            // Based on the the catenation order, toMatches are evaluated first, then ccMatches, finally bccMatches.
            fromAddress = recipientArr[0];
        } else {
            // fromAddress is set to the default sendFrom.
        }

        return fromAddress;
    }

    public toFirestoreDocument(): any {
        const doc: any = {
            app: this.app,
            attachments: this.attachments,
            attachmentsMeta: this.attachmentsMeta,
            bcc: this.bcc,
            cc: this.cc,
            client: this.client,
            content: this.content,
            creationDate: this.creationDate,
            draftId: this.draftId,
            folders: this.folders,
            forwardFromMessageId: this.forwardFromMessageId,
            from: this.from,
            id: this.id,
            internalDate: this.internalDate,
            labelIds: this.labelIds,
            mimeType: this.mimeType,
            order: this.order,
            particleKey: this.particleKey,
            particleType: this.particleType,
            position: this.position,
            readStatus: this.readStatus,
            snippet: this.snippet,
            subject: this.subject,
            temp: false,
            threadId: this.threadId,
            to: this.to,
            uid: this.uid,
        };

        if (this.description) doc.description = this.description;
        if (this.taskCounter) doc.taskCounter = this.taskCounter;
        if (this.notes) doc.notes = this.notes;
        if (this.alerting) doc.alerting = this.alerting;
        if (this.emailId) doc.emailId = this.emailId;
        if (this.checklistKey) doc.checklistKey = this.checklistKey;
        if (this.checklistItemKey) doc.checklistItemKey = this.checklistItemKey;
        if (this.cycleDescription) doc.cycleDescription = this.cycleDescription;
        if (this.isComplete) doc.isComplete = this.isComplete;
        if (this.completionDateMissed) doc.completionDateMissed = this.completionDateMissed;
        if (this.dateCompleted) doc.dateCompleted = this.dateCompleted;
        if (this.header) doc.header = this.header;
        if (this.emailAlias) doc.emailAlias = this.emailAlias;
        if (this.tasks) doc.tasks = this.tasks;

        return doc;
    }

    alerting?: boolean;
    app!: string;
    attachments!: string[];
    attachmentsMeta!: Record<string, FileMetadata>;
    bcc!: string[];
    cc!: string[];
    checklistItemKey?: string;
    checklistKey?: string;
    client?: string;
    clientEmailAddress!: string;
    completionDateMissed?: boolean;
    content!: string;
    creationDate!: Timestamp;
    cycleDescription?: string;
    dateCompleted?: Timestamp;
    description?: string;
    draftId!: string;
    emailAlias?: string;
    emailId?: string;
    lockFromAddress?: boolean;
    error: any;
    filedDate?: Timestamp;
    folders!: { [key: string]: string; };
    forwardFromMessageId!: string;
    from!: string;
    header?: string;
    id!: string;
    internalDate!: Timestamp;
    isComplete?: boolean;
    labelIds!: string[];
    load?: boolean;
    mimeType!: string;
    notes?: Record<string, ParticleNote>;
    order!: number;
    particleDate!: Timestamp;
    particleKey!: string;
    particleType!: string;
    position!: number;
    readStatus!: boolean;
    snippet!: string;
    subject!: string;
    status?: ParticleStatus;
    sendMeta?: {
        retryOn?: Timestamp,
        sentDate?: Timestamp,
        lastTriedOn?: Timestamp,
        errorDocKey?: string,
    };
    taskCounter?: string;
    tasks!: ParticleTaskMap;
    temp!: boolean;
    threadId!: string;
    to!: string[];
    uid!: string;

    // API Variables
    existsInNewgenus?: boolean; // from search api
    importedTimestamp?: Timestamp; // from search api


}