/*This code go from an article written some time ago (nov 2006) by Emilio Cortegoso Lobato:
 https://www.codeproject.com/Articles/16192/Graphic-JavaScript-Tree-with-Layout

Please, don't remove the comment
 */

// Walker's layout algorithm
// One of the classic treeview JavaScript components is Geir Landrö's DTree: outstanding and simple.
// Some time ago, an article appeared here at CodeProject that presents a horizontal JavaScript tree,
// based upon DTree, which uses HTML tables to render the tree in a horizontal manner. 

/*  The best way to get an idea of what could be accomplished by using this component is to download the sources 
    and play with the sample included. The API reference below could give you a more precise idea of what could be done. 
    But, as a briefing, features include:

    Nodes could be of any size, color. (With some code tweaking, it's easy to adapt them as desired).
    Nodes could have a title and a hidden associated metadata.
    Selection modes are: Multiple selection, Single selection and No selection.
    Node links could be straight lines (Manhattan) or Bézier curves.
    The tree layout could be oriented top to bottom, right to left or reversed. (Thanks to Walker's algorithm, not to me...!)
    Nodes could have an associated hyperlink.
    Subtrees could be collapsed and expanded as desired.
    Node searches could be done based on both title and metadata.
*/

// The Walker's algorithm goal over its predecessors was to provide a solution to fully satisfy the third rule, 
// by means of spacing out evenly smaller subtrees in the interior of adjacent larger subtrees.

/*  The Walker's algorithm is a simple algorithm that is based on the idea of a horizontal tree.
    The main concepts of the algorithm are:

    Treating each subtree as a rigid unit, moving a node implies moving all of its descendants.
    Doing so by using a preliminary coordinate and a modifier for each node. Moving a node implies to 
    increase/decrease its preliminary coordinate and its modifier, 
    but only the modifier will affect the final position of the node's descendants. 
    So moving a subtree means alter the subtree's root modifier. 
    The node's final position is computed summing up the node preliminary coordinate plus all its ancestor's modifiers.
    The algorithm works in two passes. The first pass (firstWalk) is a postorder traversal. 
    The different subtrees are processed recursively from bottom to top and left to right 
    positioning the rigid units that form each subtree until no one touch each other. 
    As the traversal goes, smaller subtrees are combined forming larger subtrees until we reach the root. 
    During the process, the apportion routine space out evenly the inner smaller subtrees that could float 
    between two adjacent larger subtrees (to satisfy the symmetry of the 3rd rule). 
    The second pass (secondWalk) is a preorder traversal which calculates the final node position by 
    adding all the ancestors modifiers to the preliminary coordinate of each node.

    Gao Chaowei (see References) proposed an optimization to the firstWalk routine which consists 
    in an incremental version of the Walker's algorithm. 
    This version avoids to recalculate the node preliminary coordinate and modifier when changes made 
    to the tree structure don't imply modifications to their values. 
    This optimization is not yet implemented in this component.

    The algorithm also adjust the calculations if the layout is to be made in a different direction 
    (i.e. bottom to top). Different uses may require different views. In occident, 
    traditionally a top disposition means power, like in organizational hierarchies; 
    a bottom layout means evolution or growth, like in biology; 
    whereas left and right layouts could mean time evolution. (But this is a particular consideration).

    Just a word to mention that, an uniform layout algorithm like this one is adequate for relative small trees. 
    Trees with a large number of nodes may require other layout and visualization techniques, 
    like the ability to zoom, pan and focus, collapse some but not all the children of a node, 
    adding interactive searching. There are a number of other solutions to achieve the same objectives including, 
    but not limited to, hyperbolic trees, treemaps, Degree of interest calculations, ... 
    The curious reader could find a lot of information on the net.

    https://github.com/mahasak/ECOTree/blob/master/ECOTree.js
*/


export class ECONode<T> {
  id;
  pid: null;
  dsc: null;
  w;
  h;
  c: null;
  bc: null;
  linkColor: null;
  data: T | null;
  // data: { user: { userName: string; } | undefined; uid: string; } | null;

  siblingIndex = 0;
  dbIndex = 0;

  XPosition = 0;
  YPosition = 0;
  prelim = 0;
  modifier = 0;
  leftNeighbor: any = null;
  rightNeighbor: any = null;
  nodeParent: ECONode<T> | null = null;
  nodeChildren: ECONode<T>[] = [];

  isCollapsed = false;
  canCollapse = false;

  isSelected = false;

  constructor(id: number, pid: null, dsc: null, w: number, h: number, c: null, bc: null, lc: null, meta: null) {
    this.id = id;
    this.pid = pid;
    this.dsc = dsc;
    this.w = w;
    this.h = h;
    this.c = c;
    this.bc = bc;
    this.linkColor = lc;
    this.data = meta;

    this.siblingIndex = 0;
    this.dbIndex = 0;

    this.XPosition = 0;
    this.YPosition = 0;
    this.prelim = 0;
    this.modifier = 0;
    this.leftNeighbor = null;
    this.rightNeighbor = null;
    this.nodeParent = null;
    this.nodeChildren = [];

    this.isCollapsed = false;
    this.canCollapse = false;

    this.isSelected = false;
  }

  _getLevel(): any {
    if (this.nodeParent?.id == -1) {
      return 0;
    } else return this.nodeParent?._getLevel() + 1;
  }

  _isAncestorCollapsed(): any {
    if (this.nodeParent?.isCollapsed) {
      return true;
    } else {
      if (this.nodeParent?.id == -1) {
        return false;
      } else {
        return this.nodeParent?._isAncestorCollapsed();
      }
    }
  }

  _setAncestorsExpanded(): any {
    if (this.nodeParent?.id == -1) {
      return;
    } else {
      if (this.nodeParent?.isCollapsed) this.nodeParent.isCollapsed = false;
      return this.nodeParent?._setAncestorsExpanded();
    }
  }

  _getChildrenCount() {
    if (this.isCollapsed) return 0;
    if (this.nodeChildren == null) return 0;
    else return this.nodeChildren.length;
  }

  _getLeftSibling() {
    if (
      this.leftNeighbor != null &&
      this.leftNeighbor.nodeParent == this.nodeParent
    )
      return this.leftNeighbor;
    else return null;
  }

  _getRightSibling() {
    if (
      this.rightNeighbor != null &&
      this.rightNeighbor.nodeParent == this.nodeParent
    )
      return this.rightNeighbor;
    else return null;
  }

  _getChildAt(i: number) {
    return this.nodeChildren[i];
  }

  _getChildrenCenter(tree: { _getNodeSize: (arg0: ECONode<any>) => number; }): number {
    const node = this._getFirstChild();
    const node1 = this._getLastChild();
    return (
      node.prelim + (node1.prelim - node.prelim + tree._getNodeSize(node1)) / 2
    );
  }

  _getFirstChild() {
    return this._getChildAt(0);
  }

  _getLastChild() {
    return this._getChildAt(this._getChildrenCount() - 1);
  }

  _drawChildrenLinks(tree: { config: { iRootOrientation: EcoOrientation; iNodeJustification: any; iLevelSeparation: number; linkType: any; }; }) {
    const s = [];
    let xa = 0,
      ya = 0,
      xb = 0,
      yb = 0,
      xc = 0,
      yc = 0,
      xd = 0,
      yd = 0;
    let node1 = null;

    switch (tree.config.iRootOrientation) {
      case EcoOrientation.RO_TOP:
        xa = this.XPosition + this.w / 2;
        ya = this.YPosition + this.h;
        break;

      case EcoOrientation.RO_BOTTOM:
        xa = this.XPosition + this.w / 2;
        ya = this.YPosition;
        break;

      case EcoOrientation.RO_RIGHT:
        xa = this.XPosition;
        ya = this.YPosition + this.h / 2;
        break;

      case EcoOrientation.RO_LEFT:
        xa = this.XPosition + this.w;
        ya = this.YPosition + this.h / 2;
        break;
    }
    const nodesSorted = this.nodeChildren.sort((a, b) =>
      a.isSelected && !b.isSelected ? 1 : -1
    );
    for (let k = 0; k < nodesSorted.length; k++) {
      node1 = nodesSorted[k];

      switch (tree.config.iRootOrientation) {
        case EcoOrientation.RO_TOP:
          xd = xc = node1.XPosition + node1.w / 2;
          yd = node1.YPosition;
          xb = xa;
          switch (tree.config.iNodeJustification) {
            case EcoAligment.NJ_TOP:
              yb = yc = yd - tree.config.iLevelSeparation / 2;
              break;
            case EcoAligment.NJ_BOTTOM:
              yb = yc = ya + tree.config.iLevelSeparation / 2;
              break;
            case EcoAligment.NJ_CENTER:
              yb = yc = ya + (yd - ya) / 2;
              break;
          }
          break;

        case EcoOrientation.RO_BOTTOM:
          xd = xc = node1.XPosition + node1.w / 2;
          yd = node1.YPosition + node1.h;
          xb = xa;
          switch (tree.config.iNodeJustification) {
            case EcoAligment.NJ_TOP:
              yb = yc = yd + tree.config.iLevelSeparation / 2;
              break;
            case EcoAligment.NJ_BOTTOM:
              yb = yc = ya - tree.config.iLevelSeparation / 2;
              break;
            case EcoAligment.NJ_CENTER:
              yb = yc = yd + (ya - yd) / 2;
              break;
          }
          break;

        case EcoOrientation.RO_RIGHT:
          xd = node1.XPosition + node1.w;
          yd = yc = node1.YPosition + node1.h / 2;
          yb = ya;
          switch (tree.config.iNodeJustification) {
            case EcoAligment.NJ_TOP:
              xb = xc = xd + tree.config.iLevelSeparation / 2;
              break;
            case EcoAligment.NJ_BOTTOM:
              xb = xc = xa - tree.config.iLevelSeparation / 2;
              break;
            case EcoAligment.NJ_CENTER:
              xb = xc = xd + (xa - xd) / 2;
              break;
          }
          break;

        case EcoOrientation.RO_LEFT:
          xd = node1.XPosition;
          yd = yc = node1.YPosition + node1.h / 2;
          yb = ya;
          switch (tree.config.iNodeJustification) {
            case EcoAligment.NJ_TOP:
              xb = xc = xd - tree.config.iLevelSeparation / 2;
              break;
            case EcoAligment.NJ_BOTTOM:
              xb = xc = xa + tree.config.iLevelSeparation / 2;
              break;
            case EcoAligment.NJ_CENTER:
              xb = xc = xa + (xd - xa) / 2;
              break;
          }
          break;
      }

      switch (tree.config.linkType) {
        case 'M':
          s.push(
            'M' +
            xa +
            ' ' +
            ya +
            ' L' +
            xb +
            ' ' +
            yb +
            ' L' +
            xc +
            ' ' +
            yc +
            ' L' +
            xd +
            ' ' +
            yd
          );
          break;

        case 'B':
          s.push(
            'M' +
            xa +
            ' ' +
            ya +
            ' C' +
            xb +
            ' ' +
            yb +
            ' ' +
            xc +
            ' ' +
            yc +
            ' ' +
            xd +
            ' ' +
            yd
          );
          break;
        case 'L':
          if (tree.config.iRootOrientation == EcoOrientation.RO_BOTTOM) {
            s.push('M' + xa + ' ' + ya + ' L' + xd + ' ' + yd);
          } else {
            s.push('M' + xa + ' ' + ya + ' L' + xd + ' ' + yd);
          }
          break;
      }
    }
    return s;
  }
}

export class ECOTree {
  config = {
    iMaxDepth: 100,
    iLevelSeparation: 40,
    iSiblingSeparation: 40,
    iSubtreeSeparation: 80,
    iRootOrientation: EcoOrientation.RO_TOP,
    iNodeJustification: EcoAligment.NJ_TOP,
    topXAdjustment: 0,
    topYAdjustment: 0,
    linkType: 'B',
    nodeColor: '#333',
    nodeBorderColor: 'white',
    nodeSelColor: '#FFFFCC',
    useTarget: true,
    searchMode: EcoSearch.SM_DSC,
    selectMode: EcoSelect.SL_MULTIPLE,
    defaultNodeWidth: 300, // 240,
    defaultNodeHeight: 140,
  };
  version = '1.1';
  canvasoffsetTop = 0;
  canvasoffsetLeft = 0;

  maxLevelHeight: any[] = [];
  maxLevelWidth: any[] = [];
  previousLevelNode: any[] = [];

  rootYOffset = 0;
  rootXOffset = 0;

  nDatabaseNodes: any[] = [];
  mapIDs: any = {};

  root;
  iSelectedNode = -1;
  iLastSearch = 0;

  width = 0;
  height = 0;
  padding = 0;

  constructor() {
    this.config;

    this.version = '1.1';
    this.canvasoffsetTop = 0;
    this.canvasoffsetLeft = 0;

    this.maxLevelHeight = [];
    this.maxLevelWidth = [];
    this.previousLevelNode = [];

    this.rootYOffset = 0;
    this.rootXOffset = 0;
    this.padding = 10;

    this.nDatabaseNodes = [];
    this.mapIDs = {};

    this.root = new ECONode(-1, null, null, 2, 2, null, null, null, null);
    this.iSelectedNode = -1;
    this.iLastSearch = 0;
  }

  /*  _canvasNodeClickHandler(tree, target, nodeid) {
    if (target != nodeid) return;
    tree.selectNode(nodeid, true);
  }
*/
  //Layout algorithm
  _firstWalk<T>(tree: this, node: ECONode<T>, level: number) {
    let leftSibling = null;

    node.XPosition = 0;
    node.YPosition = 0;
    node.prelim = 0;
    node.modifier = 0;
    node.leftNeighbor = null;
    node.rightNeighbor = null;
    tree._setLevelHeight(node, level);
    tree._setLevelWidth(node, level);
    tree._setNeighbors(node, level);
    if (node._getChildrenCount() == 0 || level == tree.config.iMaxDepth) {
      leftSibling = node._getLeftSibling();
      if (leftSibling != null)
        node.prelim =
          leftSibling.prelim +
          tree._getNodeSize(leftSibling) +
          tree.config.iSiblingSeparation;
      else node.prelim = 0;
    } else {
      const n = node._getChildrenCount();
      for (let i = 0; i < n; i++) {
        const iChild = node._getChildAt(i);
        this._firstWalk(tree, iChild, level + 1);
      }

      let midPoint = node._getChildrenCenter(tree);
      midPoint -= tree._getNodeSize(node) / 2;
      leftSibling = node._getLeftSibling();
      if (leftSibling != null) {
        node.prelim =
          leftSibling.prelim +
          tree._getNodeSize(leftSibling) +
          tree.config.iSiblingSeparation;
        node.modifier = node.prelim - midPoint;
        this._apportion(tree, node, level);
      } else {
        node.prelim = midPoint;
      }
    }
  }

  _apportion(tree: { config: { iMaxDepth: number; iSubtreeSeparation: any; }; _getNodeSize: (arg0: any) => any; _getLeftmost: (arg0: any, arg1: number, arg2: number) => any; }, node: { _getFirstChild: () => any; }, level: number) {
    let firstChild = node._getFirstChild();
    let firstChildLeftNeighbor = firstChild.leftNeighbor;
    let j = 1;
    for (
      let k = tree.config.iMaxDepth - level;
      firstChild != null && firstChildLeftNeighbor != null && j <= k;

    ) {
      let modifierSumRight = 0;
      let modifierSumLeft = 0;
      let rightAncestor = firstChild;
      let leftAncestor = firstChildLeftNeighbor;
      for (let l = 0; l < j; l++) {
        rightAncestor = rightAncestor.nodeParent;
        leftAncestor = leftAncestor.nodeParent;
        modifierSumRight += rightAncestor.modifier;
        modifierSumLeft += leftAncestor.modifier;
      }

      let totalGap =
        firstChildLeftNeighbor.prelim +
        modifierSumLeft +
        tree._getNodeSize(firstChildLeftNeighbor) +
        tree.config.iSubtreeSeparation -
        (firstChild.prelim + modifierSumRight);
      if (totalGap > 0) {
        let subtreeAux: any = node;
        let numSubtrees = 0;
        for (
          ;
          subtreeAux != null && subtreeAux != leftAncestor;
          subtreeAux = subtreeAux._getLeftSibling()
        )
          numSubtrees++;

        if (subtreeAux != null) {
          let subtreeMoveAux: any = node;
          const singleGap = totalGap / numSubtrees;
          for (
            ;
            subtreeMoveAux != leftAncestor;
            subtreeMoveAux = subtreeMoveAux._getLeftSibling()
          ) {
            subtreeMoveAux.prelim += totalGap;
            subtreeMoveAux.modifier += totalGap;
            totalGap -= singleGap;
          }
        }
      }
      j++;
      if (firstChild._getChildrenCount() == 0)
        firstChild = tree._getLeftmost(node, 0, j);
      else firstChild = firstChild._getFirstChild();
      if (firstChild != null) firstChildLeftNeighbor = firstChild.leftNeighbor;
    }
  }

  _secondWalk<T>(tree: this, node: ECONode<T>, level: number, X: number, Y: number) {
    if (level <= tree.config.iMaxDepth) {
      const xTmp = tree.rootXOffset + node.prelim + X;
      const yTmp = tree.rootYOffset + Y;
      let maxsizeTmp = 0;
      let nodesizeTmp = 0;
      let flag = false;

      switch (tree.config.iRootOrientation) {
        case EcoOrientation.RO_TOP:
        case EcoOrientation.RO_BOTTOM:
          maxsizeTmp = tree.maxLevelHeight[level];
          nodesizeTmp = node.h;
          break;

        case EcoOrientation.RO_RIGHT:
        case EcoOrientation.RO_LEFT:
          maxsizeTmp = tree.maxLevelWidth[level];
          flag = true;
          nodesizeTmp = node.w;
          break;
      }
      switch (tree.config.iNodeJustification) {
        case EcoAligment.NJ_TOP:
          node.XPosition = xTmp;
          node.YPosition = yTmp;
          break;

        case EcoAligment.NJ_CENTER:
          node.XPosition = xTmp;
          node.YPosition = yTmp + (maxsizeTmp - nodesizeTmp) / 2;
          break;

        case EcoAligment.NJ_BOTTOM:
          node.XPosition = xTmp;
          node.YPosition = yTmp + maxsizeTmp - nodesizeTmp;
          break;
      }
      if (flag) {
        const swapTmp = node.XPosition;
        node.XPosition = node.YPosition;
        node.YPosition = swapTmp;
      }
      switch (tree.config.iRootOrientation) {
        case EcoOrientation.RO_BOTTOM:
          node.YPosition = -node.YPosition - nodesizeTmp;
          break;

        case EcoOrientation.RO_RIGHT:
          node.XPosition = -node.XPosition - nodesizeTmp;
          break;
      }
      if (node._getChildrenCount() != 0)
        this._secondWalk(
          tree,
          node._getFirstChild(),
          level + 1,
          X + node.modifier,
          Y + maxsizeTmp + tree.config.iLevelSeparation
        );
      const rightSibling = node._getRightSibling();
      if (rightSibling != null)
        this._secondWalk(tree, rightSibling, level, X, Y);
    }
  }

  _positionTree() {
    this.maxLevelHeight = [];
    this.maxLevelWidth = [];
    this.previousLevelNode = [];
    this._firstWalk(this, this.root, 0);

    switch (this.config.iRootOrientation) {
      case EcoOrientation.RO_TOP:
      case EcoOrientation.RO_LEFT:
        this.rootXOffset = this.config.topXAdjustment + this.root.XPosition;
        this.rootYOffset = this.config.topYAdjustment + this.root.YPosition;
        break;

      case EcoOrientation.RO_BOTTOM:
      case EcoOrientation.RO_RIGHT:
        this.rootXOffset = this.config.topXAdjustment + this.root.XPosition;
        this.rootYOffset = this.config.topYAdjustment + this.root.YPosition;
    }

    this._secondWalk(this, this.root, 0, 0, 0);
  }

  _setLevelHeight(node: { h: number; }, level: number) {
    if (this.maxLevelHeight[level] == null) this.maxLevelHeight[level] = 0;
    if (this.maxLevelHeight[level] < node.h)
      this.maxLevelHeight[level] = node.h;
  }

  _setLevelWidth(node: { w: number; }, level: number) {
    if (this.maxLevelWidth[level] == null) this.maxLevelWidth[level] = 0;
    if (this.maxLevelWidth[level] < node.w) this.maxLevelWidth[level] = node.w;
  }

  _setNeighbors(node: { leftNeighbor: { rightNeighbor: any; } | null; }, level: number) {
    node.leftNeighbor = this.previousLevelNode[level];
    if (node.leftNeighbor != null) node.leftNeighbor.rightNeighbor = node;
    this.previousLevelNode[level] = node;
  }

  _getNodeSize(node: { w: any; h: any; }) {
    switch (this.config.iRootOrientation) {
      case EcoOrientation.RO_TOP:
      case EcoOrientation.RO_BOTTOM:
        return node.w;

      case EcoOrientation.RO_RIGHT:
      case EcoOrientation.RO_LEFT:
        return node.h;
    }
    return 0;
  }

  _getLeftmost(node: { _getChildrenCount: () => number; _getChildAt: (arg0: number) => any; }, level: number, maxlevel: number): any {
    if (level >= maxlevel) return node;
    if (node._getChildrenCount() == 0) return null;

    const n = node._getChildrenCount();
    for (let i = 0; i < n; i++) {
      const iChild = node._getChildAt(i);
      const leftmostDescendant = this._getLeftmost(iChild, level + 1, maxlevel);
      if (leftmostDescendant != null) return leftmostDescendant;
    }

    return null;
  }

  _selectNodeInt(dbindex: number, flagToggle: boolean) {
    if (this.config.selectMode == EcoSelect.SL_SINGLE) {
      if (this.iSelectedNode != dbindex && this.iSelectedNode != -1) {
        this.nDatabaseNodes[this.iSelectedNode].isSelected = false;
      }
      this.iSelectedNode =
        this.nDatabaseNodes[dbindex].isSelected && flagToggle ? -1 : dbindex;
    }
    this.nDatabaseNodes[dbindex].isSelected = flagToggle
      ? !this.nDatabaseNodes[dbindex].isSelected
      : true;
  }

  _getNodeInt(dbindex: number, flagToggle: boolean) {
    return this.nDatabaseNodes[dbindex];
  }

  _collapseAllInt(flag: boolean) {
    let node = null;
    for (let n = 0; n < this.nDatabaseNodes.length; n++) {
      node = this.nDatabaseNodes[n];
      if (node.canCollapse) node.isCollapsed = flag;
    }
    this.UpdateTree();
  }

  _selectAllInt(flag: boolean) {
    let node = null;
    for (let k = 0; k < this.nDatabaseNodes.length; k++) {
      node = this.nDatabaseNodes[k];
      node.isSelected = flag;
    }
    this.iSelectedNode = -1;
    this.UpdateTree();
  }

  // ECOTree API begins here...

  public UpdateTree() {
    // console.log('EcoNode > UpdateTree called with config:', JSON.stringify(this.config, null, '\t'));

    this._positionTree();
    this.width =
      this.config.iRootOrientation == EcoOrientation.RO_RIGHT
        ? Math.max(...this.nDatabaseNodes.map(x => -x.XPosition + x.w))
        : Math.max(...this.nDatabaseNodes.map(x => x.XPosition + x.w));
    this.height =
      this.config.iRootOrientation == EcoOrientation.RO_BOTTOM
        ? Math.max(...this.nDatabaseNodes.map(x => -x.YPosition + x.h))
        : Math.max(...this.nDatabaseNodes.map(x => x.YPosition + x.h));

    if (this.config.iRootOrientation == EcoOrientation.RO_BOTTOM) {
      this.nDatabaseNodes.forEach(x => {
        x.YPosition = x.YPosition + this.height;
      });
    }
    if (this.config.iRootOrientation == EcoOrientation.RO_RIGHT) {
      this.nDatabaseNodes.forEach(x => {
        x.XPosition = x.XPosition + this.width;
      });
    }
  }

  public add(id: string | number, pid: number, dsc: any, w: any, h: number, c: any, bc: any, lc: any, meta: any, selected = false) {
    const nw = w || this.config.defaultNodeWidth; //Width, height, colors, target and metadata defaults...
    const nh = h || this.config.defaultNodeHeight;
    const color = c || this.config.nodeColor;
    const border = bc || this.config.nodeBorderColor;
    const metadata = typeof meta != 'undefined' ? meta : '';

    let pnode = null; //Search for parent node in database
    if (pid == -1) {
      pnode = this.root;
    } else {
      for (let k = 0; k < this.nDatabaseNodes.length; k++) {
        if (this.nDatabaseNodes[k].id == pid) {
          pnode = this.nDatabaseNodes[k];
          break;
        }
      }
    }

    const node = new ECONode(id as any, pid as any, dsc, nw, nh, color, border, lc, metadata);
    node.isSelected = selected; //New node creation...
    node.nodeParent = pnode; //Set it's parent
    pnode.canCollapse = true; //It's obvious that now the parent can collapse
    const i = this.nDatabaseNodes.length; //Save it in database
    node.dbIndex = this.mapIDs[id] = i;
    this.nDatabaseNodes[i] = node;
    h = pnode.nodeChildren.length; //Add it as child of it's parent
    node.siblingIndex = h;
    pnode.nodeChildren[h] = node;
    // console.log("this.nDatabaseNodes 1 : ", JSON.stringify(this.nDatabaseNodes, null, "\t"));
    // console.log("this.nDatabaseNodes 1 : ", this.nDatabaseNodes);
  }

  public searchNodes(str: string) {
    let node = null;
    const m = this.config.searchMode;
    const sm = this.config.selectMode == EcoSelect.SL_SINGLE;

    if (typeof str == 'undefined') return;
    if (str == '') return;

    let found = false;
    let n = sm ? this.iLastSearch : 0;
    if (n == this.nDatabaseNodes.length) n = this.iLastSearch = 0;

    str = str.toLocaleUpperCase();

    for (; n < this.nDatabaseNodes.length; n++) {
      node = this.nDatabaseNodes[n];
      if (
        node.dsc.toLocaleUpperCase().indexOf(str) != -1 &&
        (m == EcoSearch.SM_DSC || m == EcoSearch.SM_BOTH)
      ) {
        node._setAncestorsExpanded();
        this._selectNodeInt(node.dbIndex, false);
        found = true;
      }
      if (
        node.meta.toLocaleUpperCase().indexOf(str) != -1 &&
        (m == EcoSearch.SM_META || m == EcoSearch.SM_BOTH)
      ) {
        node._setAncestorsExpanded();
        this._selectNodeInt(node.dbIndex, false);
        found = true;
      }
      if (sm && found) {
        this.iLastSearch = n + 1;
        break;
      }
    }
    this.UpdateTree();
  }

  public selectAll() {
    if (this.config.selectMode != EcoSelect.SL_MULTIPLE) return;
    this._selectAllInt(true);
  }

  public unselectAll() {
    this._selectAllInt(false);
  }

  public collapseAll() {
    this._collapseAllInt(true);
  }

  public expandAll() {
    this._collapseAllInt(false);
  }

  public collapseNode(nodeid: string | number, upd: any) {
    const dbindex = this.mapIDs[nodeid];
    this.nDatabaseNodes[dbindex].isCollapsed =
      !this.nDatabaseNodes[dbindex].isCollapsed;
    if (upd) this.UpdateTree();
  }

  public selectNode(nodeid: string | number, upd: any) {
    this._selectNodeInt(this.mapIDs[nodeid], true);
    if (upd) this.UpdateTree();
  }

  public getNode(nodeid: string | number, upd: boolean) {
    const node = this._getNodeInt(this.mapIDs[nodeid], true);
    return (node);
    // if (upd) this.UpdateTree();
  }

  public setNodeTitle(nodeid: string | number, title: any, upd: any) {
    const dbindex = this.mapIDs[nodeid];
    this.nDatabaseNodes[dbindex].dsc = title;
    if (upd) this.UpdateTree();
  }

  public setNodeMetadata(nodeid: string | number, meta: any, upd: any) {
    const dbindex = this.mapIDs[nodeid];
    this.nDatabaseNodes[dbindex].meta = meta;
    if (upd) this.UpdateTree();
  }

  /*
  setNodeTarget(nodeid, target, upd) {
    const dbindex = this.mapIDs[nodeid];
    this.nDatabaseNodes[dbindex].target = target;
    if (upd) this.UpdateTree();
  }
*/
  public setNodeColors(nodeid: string | number, color: any, border: any, upd: any) {
    const dbindex = this.mapIDs[nodeid];
    if (color) this.nDatabaseNodes[dbindex].c = color;
    if (border) this.nDatabaseNodes[dbindex].bc = border;
    if (upd) this.UpdateTree();
  }

  public setNodeData(nodeid: string | number, key: string, value: string, upd: boolean) {
    const dbindex = this.mapIDs[nodeid];
    // console.log("xxx - setNodeData - key: ", key, " value : ", value);
    if (key) this.nDatabaseNodes[dbindex].data[key] = value;
    if (upd) this.UpdateTree();

  }

  public getSelectedNodes() {
    let node = null;
    const selection = [];
    let selnode = null;

    for (let n = 0; n < this.nDatabaseNodes.length; n++) {
      node = this.nDatabaseNodes[n];
      if (node.isSelected) {
        selnode = {
          id: node.id,
          dsc: node.dsc,
          meta: node.meta,
        };
        selection[selection.length] = selnode;
      }
    }
    return selection;
  }
}

export interface IECONode {
  data: any;
  linkColor?: string;
  background?: string;
  color?: string;
  width?: number;
  height?: number;
  children?: IECONode[];
  selected?: boolean;
}

export enum EcoOrientation {
  RO_TOP,
  RO_BOTTOM,
  RO_RIGHT,
  RO_LEFT,
}

export enum EcoAligment {
  NJ_TOP,
  NJ_CENTER,
  NJ_BOTTOM,
}

export enum EcoFill {
  NF_GRADIENT,
  NF_FLAT,
}

export enum EcoColorize {
  CS_NODE,
  CS_LEVEL,
}

//Search method: Title, metadata or both
export enum EcoSearch {
  SM_DSC,
  SM_META,
  SM_BOTH,
}

//Selection mode: single, multiple, no selection
export enum EcoSelect {
  SL_MULTIPLE,
  SL_SINGLE,
  SL_NONE,
}