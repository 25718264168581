import { SearchableSelectComponent } from '../../searchable-select/searchable-select.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DisplayValue, ECONode, Organization } from '@newgenus/common';
import { Component, inject, Inject, OnInit } from '@angular/core';
import { NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MaterialModule } from '../../material.module';
import { Position } from '../services/tree-builder.service';

@Component({
  selector: 'shared-org-assign-user-dialog',
  standalone: true,
  imports: [MaterialModule, SearchableSelectComponent, TitleCasePipe, NgFor, NgIf],
  template: `
  <h2 mat-dialog-title>Users for {{data.node.data?.name}}

    <!-- Close button. -->
    <button mat-icon-button mat-dialog-close class="close-button">
      <mat-icon>close</mat-icon>
    </button>
  </h2>

  <mat-dialog-content class="h-100-70 w-100">
    
    <div class="w-100 px-3">
        <h3 class="pl-2 mb-2 p-2">Search and select users</h3>
        <shared-searchable-select 
          [classes]="['w-100']"
          [label]="'Search for users'"
          [data]="searchableData" 
          (selected)="onSelectUsers($event)"
          [multiple]="true"
        ></shared-searchable-select>
    </div>
  
    <div class="view-container m-3 w-100">
      <div class="view-header">
          <span class="viewer-title-spacer">User changes</span>
      </div>

      <div class="view-content">
        <div class="info-quadrant">
          <section class="flex-row">
            <div class="bordered mb-3 w-100 p-3">
              <h3>Users for {{data.node.data?.name}}</h3>
              <div *ngFor="let user of usersOnHat" class="user-list-container">
                
                <span class="grouped">
                  <img [src]="'https://ui-avatars.com/api/?name=' + user.display + '&background=random' + '&color=random'">
                  <span>{{user.display | titlecase}}</span>
                </span>

                <button mat-icon-button color="warn" (click)="onRemoveUserFromUsersOnHat(user.value)" >
                  <mat-icon>remove_circle</mat-icon>
                </button>

              </div>
            </div>
          </section>

          <section class="flex-row">
            <div class="bordered mb-3 mx-3 w-100 p-3">
              <h3>Users to assign</h3>
              <div *ngFor="let user of usersToAssign" class="user-list-container">
                
                <span class="grouped">
                  <img [src]="'https://ui-avatars.com/api/?name=' + user.display + '&background=random' + '&color=random'">
                  <span>{{user.display | titlecase}}</span>
                </span>

                <button mat-icon-button color="warn" (click)="onRemoveUserFromUsersToAssign(user.value)" >
                  <mat-icon>remove_circle</mat-icon>
                </button>

              </div>
            </div>
          </section>

          <section class="flex-row">
            <div class="bordered mb-3 w-100 p-3">
              <h3>Users to remove</h3>
              <div *ngFor="let user of usersToRemove" class="user-list-container">
                
                <span class="grouped">
                  <img [src]="'https://ui-avatars.com/api/?name=' + user.display + '&background=random' + '&color=random'">
                  <span>{{user.display | titlecase}}</span>
                </span>

                <button mat-icon-button color="primary" (click)="onRestoreUser(user.value)" >
                  <mat-icon>restore</mat-icon>
                </button>

              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
        

    <div class="view-container m-3 w-100">
      <div class="view-header">
          <span class="viewer-title-spacer">Final users on {{data.node.data?.name}}</span>
      </div>
      <div class="view-content">
        <div *ngFor="let user of finalUsersOnHat" class="user-list-container">
          <span class="grouped">
            <img [src]="'https://ui-avatars.com/api/?name=' + user.display + '&background=random' + '&color=random'">
            <span>{{user.display | titlecase}}</span>
          </span>
        </div>

        <div *ngIf="!finalUsersOnHat">
          <p>No users assigned to this hat.</p>
        </div>
      </div>
    </div>
  
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close color="warn">Close</button>
    <button mat-stroked-button color="primary"><mat-icon>save</mat-icon> Save</button>
  </mat-dialog-actions>
`,
  styleUrls: [
    './org-dialog.scss',
    '../organization-board.component.scss'
  ],
})
export class OrgAssignUserDialogComponent implements OnInit {

  public selectedUserKeys: string | string[] = [];
  public searchableData: DisplayValue[] = [];

  public usersOnHat: DisplayValue[] = [];
  public usersToAssign: DisplayValue[] = [];
  public usersToRemove: DisplayValue[] = [];
  public finalUsersOnHat: DisplayValue[] = [];

  public snackbar = inject(MatSnackBar);

  constructor(
    public dialogRef: MatDialogRef<OrgAssignUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { node: ECONode<Position>, organization: Organization }
  ) { }


  public ngOnInit(): void {
    console.log('OrgAssignUserDialogComponent > ngOnInit > this.data: ', this.data);

    // Set searchable data to the org users.
    const userKeys = this.data.organization.orgUsers.userKeys;
    console.log('OrgAssignUserDialogComponent > ngOnInit > userKeys: ', userKeys);
    this.searchableData = userKeys.map(key => ({
      display: this.data.organization.orgUsers[key].name,
      value: key
    }));

    this.usersOnHat = (this.data?.node?.data?.userDetailsArray || [])
      .map(user => ({ display: user.name, value: user.key }));
  }

  public onSelectUsers(selectedUserKeys: string[]) {
    console.log('onSelectUsers > $event: ', selectedUserKeys);
    this.selectedUserKeys = selectedUserKeys;

    this.usersToAssign = this.selectedUserKeys.map(key => ({
      display: this.data.organization.orgUsers[key].name,
      value: key
    }));

    this.updateFinalResult();
  }

  /**
   * Removes user from the usersOnHat, and adds an entry to the usersToRemove array.
   */
  public onRemoveUserFromUsersOnHat(userKey: string) {
    console.log('onRemoveUserFromUsersOnHat > userKey: ', userKey);
    const userRemoved = this.usersOnHat.find(user => user.value === userKey) as DisplayValue;
    this.usersOnHat = this.usersOnHat.filter(user => user.value !== userKey);
    this.usersToRemove.push(userRemoved);

    this.updateFinalResult();
  }


  /**
   * Removes user from the usersToAssign array.
   */
  public onRemoveUserFromUsersToAssign(userKey: string) {
    console.log('onRemoveUserFromUsersToAssign > userKey: ', userKey);
    this.usersToAssign = this.usersToAssign.filter(user => user.value !== userKey);

    this.updateFinalResult();
  }

  /**
   * Removes a user from the usersToRemove array, and adds them back to the usersOnHat array.
   */
  public onRestoreUser(userKey: string) {
    console.log('onRestoreUser > userKey: ', userKey);
    const userRestored = this.usersToRemove.find(user => user.value === userKey) as DisplayValue;
    this.usersToRemove = this.usersToRemove.filter(user => user.value !== userKey);
    this.usersOnHat.push(userRestored);

    this.updateFinalResult();
  }

  private updateFinalResult(): void {
    this.finalUsersOnHat = this.usersOnHat.concat(this.usersToAssign);
  }

}
