import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'normalize', standalone: true })
export class NormalizePipe implements PipeTransform {
    transform(value: string): any {
        // Normalize the input value.
        // Input examples would be:
        // Management-tools-dashboard
        // Workspace
        // Test-feature-3
        // And other similar values.

        // Normalize the value, E.G:
        // Management Tools Dashboard
        // Workspace
        // Test Feature 3
        // And other similar values
        const normalizedValue = value
            .split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

        // Return the normalized value.
        return normalizedValue;
    }
}