import { QuestionBase } from './question-base';

export class DatePickerQuestion extends QuestionBase<string> {
  override controlType = 'datepicker';
  override options: { key: string, value: string }[] = [];

  constructor(options: any = {}) {
    super(options);
    this.options = options['options'] || [];
  }
}

