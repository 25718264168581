import { OrbBoardBaseComponent } from '../org-board-base-component';
import { Position, UserDetails } from '../services/tree-builder.service';
import { CommonModule, NgStyle } from '@angular/common';
import { MaterialModule } from '../../material.module';
import { Component, Input } from '@angular/core';
import { NormalizePipe } from '../format-pipe';
import { ECONode } from '@newgenus/common';

@Component({
  selector: 'shared-sidebar-user',
  standalone: true,
  imports: [CommonModule, MaterialModule, NgStyle, NormalizePipe],
  template: `
  <!-- User details. -->
  <mat-card class="my-2">
    <!-- User name and profile picture. -->
    <mat-card-header>
      <div mat-card-avatar class="example-header-image"
        [ngStyle]="{'background-image': 'url(https://ui-avatars.com/api/?name=' + encodeURL(user?.name || 'Not Acquired') + '&background=random' + '&color=random&size=40)'}">
      </div>
      <mat-card-title>{{(user?.name || '') | titlecase}}</mat-card-title>
      <mat-card-subtitle>{{positionTitles}}</mat-card-subtitle>
    </mat-card-header>

    <!-- User detail. -->
    <mat-card-content>
      <p>
        TODO: User details - What to put here and how to display it?
      </p>
      <!-- <p>
        UID: {{userDetails?.uid || 'TODO'}}
      </p> -->
      <!-- <span>Hats:</span>
      <div class="description-list-container">
        <dl *ngFor="let hat of user?.orgUser?.hats || []">
          <dt>{{user.orgUser.hatsDetails[hat].name}}</dt>
          <dd>- <span *ngFor="let feat of user.orgUser.hatsDetails[hat].features; last as isLast">{{feat + (isLast ?
              '' : ', ')}}</span></dd>
        </dl>
      </div> -->
    </mat-card-content>

    <!-- User actions. -->
    <mat-card-actions align="end" class="button-margin">
      <button mat-stroked-button color="accent"><mat-icon>chat</mat-icon> Chat</button>
      <!-- TODO: can be a split button in the future, where it offers all the particle types the work-space has (order, dispatch, email, etc.). Additionally, it will remember what the user uses the most and offer that first. -->
      <button mat-stroked-button color="accent"><mat-icon>mail</mat-icon> Contact</button>
        <button mat-raised-button color="accent" (click)="dialogService.openOrgUserDetailsDialog(node, user)"><mat-icon>person_search</mat-icon> Open User</button>
      <!-- <button mat-flat-button color="warn">BUTTON</button> -->
    </mat-card-actions>
  </mat-card>

  <!-- Hat Details. -->
  <mat-card>
    <!-- Hat title. -->
    <mat-card-header>
      <mat-card-title>Hats Worn by {{(user?.name || '') | titlecase}}</mat-card-title>
      <!-- <mat-card-subtitle>{{user?.hat?.name || 'TODO'}}</mat-card-subtitle> -->
    </mat-card-header>

    <!-- Hat Detail. -->
    <mat-card-content>

    
      <div *ngFor="let pos of positions" class="inset-details">
        <h3>{{pos.name}}</h3>

        <div *ngFor="let sg of pos.securityGroupsArray" class="border-top-1">
          <div class="w-100 mb-2">Security Group: <strong>{{sg.name | normalize}}</strong></div>

          <span>Features:</span>
          <div class="description-list-container">
            <dl *ngFor="let feature of sg?.features || []">
              <dt>{{feature | normalize}}</dt>
              <dd *ngFor="let permission of sg.featureDetails[feature].permissions">- {{permission | normalize}}</dd>
            </dl>
          </div>

          <!-- <span class="mt-2">Policies:</span>
          <div class="description-list-container">
            <dl *ngFor="let policy of sg?.policies || []">
              <dt>{{policy}}</dt>
              <dd *ngFor="let detail of sg.policyDetails[policy]">- {{detail}}</dd>
            </dl>
          </div> -->
        </div>
      </div>

    </mat-card-content>

    <!-- Hat Actions -->
    <mat-card-actions align="end" class="button-margin">
        <!-- If no hat assigned. -->
        <button mat-stroked-button color="primary" (click)="dialogService.openSecurityGroupsDialog(node)"><mat-icon>add_circle</mat-icon>Assign Hat</button>
        <!-- <button mat-raised-button color="primary"><mat-icon>add</mat-icon>New Hat</button> -->

        <!-- If existing hat assigned. -->
        <button mat-raised-button color="accent" (click)="dialogService.openHat(node, user)"><mat-icon>open_in_new_off</mat-icon> Open Hat</button>
    </mat-card-actions>
  </mat-card>
    `,
  styleUrls: ['../organization-board.component.scss']
})
export class SidebarUserComponent extends OrbBoardBaseComponent {

  @Input()
  public isDev = false;

  @Input() // TODO: this should come from the Org doc, and be linked to a UID. Only the matching UID should be allowed to edit?
  public isEditing = false;

  @Input()
  public node!: ECONode<Position>;

  @Input()
  public user!: UserDetails;

  public override ngOnInit(): void {
    // Doesn't need to do anything.
    console.log('SidebarUserComponent > user: ', this.user);
  }

  public encodeURL(url: string) {
    return encodeURIComponent(url);
  }

  public get positions(): Position[] {
    return this.user?.positions || [];
  }
  public get positionTitles() {
    return this.positions?.map(p => p.name)?.join(', ') || '';
  }

}
