import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { ParticleActions } from '../particles/action-types';
import { createReducer, on } from '@ngrx/store';

// Entity adapter & initial state
export interface ParticlesSortOrderState extends EntityState<any> {
    selectedSort: any; // set initial data
}

export const adapter = createEntityAdapter<any>({
    selectId: order => order.name,
});

export const initialParticlesSortOrderState = adapter.getInitialState({
    selectedSort: {
        name: 'byName',
        order: 'asc',
    },
});

// Reducer functions

export const particlesSortOrderReducer = createReducer(
    initialParticlesSortOrderState,

    on(ParticleActions.allParticlesSortOrderLoaded, (state, action) => {
        return adapter.setAll(action.selectedSort, { ...state, allParticlesLoaded: true });
    })
);

export const { selectAll } = adapter.getSelectors();
