import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ParticleService } from '../../services/particle.service';
import { Component, OnDestroy, Inject } from '@angular/core';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Folder } from '@newgenus/common';
import { takeUntil, Subject } from 'rxjs';


@Component({
  selector: 'newgenus-select-folder-order-display-modal-component',
  templateUrl: './select-folder-order-display-modal-component.component.html',
  styleUrls: ['./select-folder-order-display-modal-component.component.css']
})
export class SelectFolderOrderDisplayModalComponentComponent implements OnDestroy {

  //-- subscription -- //
  private folderSubscription: any;
  private destroy$ = new Subject<void>();
  public modal: any;
  //-- model -- //
  customFolders: Folder[] = [];

  constructor(//public modal: NgbActiveModal,
    private particleService: ParticleService,
    public dialogRef: MatDialogRef<SelectFolderOrderDisplayModalComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) {

    this.folderSubscription = this.particleService.userFolders$ // TODO: duplicate code between baskets, cleanup.
      .pipe(takeUntil(this.destroy$))
      .subscribe(folders => {
        if (folders?.length > 0) {
          this.customFolders = this.particleService.sortFolders(folders);
        }
      });
  }

  // -- DOM --

  submit(): void {
    this.dialogRef.close(this.customFolders);
  }

  onDrop(event: any) {
    // CdkDragDrop<string>
    const folders: any = this.customFolders;
    moveItemInArray(folders, event.previousIndex, event.currentIndex);

    const orderedFolders = this.updateFoldersPosition(folders);

    for (let i = 0; i < orderedFolders.length; i++) {

      this.particleService.updateCustomFolderDisplayPosition(orderedFolders[i]);
    }
  }

  updateFoldersPosition(folders: any) {

    const sortedFolders = [];

    for (let i = 0; i < folders.length; i++) {
      const folder = folders[i];

      folder.position = i + 1;
      sortedFolders.push(folder);
    }

    return sortedFolders;
  }
  // -- DOM ends --

  public ngOnDestroy(): void {
    this.folderSubscription.unsubscribe();
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

}
