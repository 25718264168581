
<mat-form-field [appearance]="appearance" [ngClass]="classes">

  <!-- Label -->
  <mat-label *ngIf="label">{{label}}</mat-label>

  <!-- Select -->
  <mat-select [formControl]="control" [multiple]="multiple" (selectionChange)="onSelectChange($event)" [disabled]="disabled" [panelClass]="{'overflow-clip': true}">

    <!-- Search input -->
    <mat-form-field [appearance]="appearance" [ngClass]="classes">
      <input matInput #searchInput autocomplete="dropdown-search" [(ngModel)]="searchTerm" (keyup)="onSearchChange(searchInput.value)" [placeholder]="placeholder">
    </mat-form-field>

    <!-- Multi-select label -->
    <mat-select-trigger *ngIf="multiple">
      {{control.value?.[0] || ''}}
      <span *ngIf="(control.value?.length || 0) > 1" class="additional-selection">
        (+{{(control.value?.length || 0) - 1}} {{control.value?.length === 2 ? 'other' : 'others'}})
      </span>
    </mat-select-trigger>

    <!-- Selection Options -->
    <div style="height: 75%; overflow: auto;" class="hide-scrollbar">
      <mat-option *ngFor="let item of filteredData" [value]="item.value">
        {{item.display}}
      </mat-option>
    </div>

  </mat-select>

</mat-form-field>
