import { ApiHealthIndicator, BasicState, DraftUpdate } from './enums';
import { OrganizationSettings } from './organization-models';
import { DocumentReference } from '@angular/fire/firestore';
import { Particle } from '../../classes/particle';
import { Timestamp } from "firebase/firestore";
import { TimeValue } from './checklist.model';

/* GENERAL ======================================== */

export interface BaseEntity {
  id: number;
  createdOn?: string;
  updatedOn?: string;
  updatedBy?: string;
}

export interface OptionalBaseEntity {
  id?: number;
  createdOn?: string;
  updatedOn?: string;
  updatedBy?: string;
}

export interface AuthenticationResponse {
  success: boolean;
  token?: string;
}

export interface TokenValidationResponse {
  valid: boolean;
}

export interface Locale extends BaseEntity {
  active: number;
  code: string;
  charCode2: string;
  charCode5: string;
  translations: Translation[];
}

/**
 * Error message data
 */
export interface ErrorMessage {
  message: string;
  urgency: ErrorUrgency;
}

/**
 * Error urgency setting
 */
export enum ErrorUrgency {
  Insignificant,
  Minor, // Shows alert
  Warning, // Shows alert
  Error, // Shows modal
  Crucial, // Shows modal
}

export interface DateParts {
  year: number;
  month: number;
  day: number;
}

export interface Translation extends OptionalBaseEntity {
  parentTable?: string;
  parentId?: number;
  locale_id: number;
  name?: string;
  shortDesc?: string;
  longDesc?: string;
  street1?: string;
  street2?: string;
  street3?: string;
  city?: string;
}

export interface ControlSettings {
  component?: string;
  isChild?: boolean;
  subTitle?: string;
  link?: string;
  title?: string;
  formDirty?: boolean;
  formValid?: boolean;
  // formState?: FormState;
  showSaveButton: boolean;
  showBackButton: boolean;
  // NB: Class styling need to be placed on the component where this interface will be used.
  headerButtons?: Array<InjectedElementInterface>;
  footerButtons?: Array<InjectedElementInterface>;
}

export interface InjectedElementInterface {
  class: string;
  icon: string;
  label: string;
  enabled: boolean;
  hidden: boolean;
  id: string | number;
  actionDefinition: ActionEventEnum;
  callback: ($event?: any) => void;
  hideCompareFunction?: ($event: any) => boolean;
}

export interface SortInterface {
  active: boolean;
  fieldId: string;
  enabled: boolean;
  sortName: string;
  sortDirection?: 'asc' | 'desc';
}

export interface EmailRequestBody {
  id: number;
  eMail: string;
  email?: string; // <-- HACK: The email sever should only take one 'email'. The hi/lo 'M' should be standardized.
  lang: string;
}

export interface EmailTemplateItems {
  id: number;
  templateItemType: string;
  locale_id: number;
  content: string;
}

export interface TaxRate extends BaseEntity {
  property_id: number;
  value: number;
  active: number;
  deleted: number;
}

/* FIREBASE FIRE-STORE ======================================== */


export interface UserProviderData {
  displayName: string;
  email: string;
  phoneNumber: string;
  photoURL: string;
  providerId: string;
  uid: string;
}

export enum ValidationStatus {
  Valid = 'VALID',
  Invalid = 'INVALID',
  Pending = 'PENDING',
  Disabled = 'DISABLED'
}

export enum ActionEventEnum {
  VIEW = 1,
  ANONYMISE = 2,
  CHAT = 3,
  CREATE = 4,
  DELETE = 5,
  SHOW_HISTORY = 6,
  SHOW_GUEST = 7,
  SHOW_IN_ROOMPLAN = 8,
  CHECKIN_ALL = 9,
  CHECKIN_LEAD = 10,
  CHECKOUT_ALL = 11,
  CHECKOUT_LEAD = 12,
  VIEW_BOOKING = 13,
  CLOSE = 14,
  COMPLETE = 15,
  INCOMPLETE = 16,
  EDIT = 17,
  DUPLICATE = 18,
  SHOW_ALL_INVOICES_BOOKING = 19,
  SHOW_ALL_INVOICES_GUEST = 20,
  UNLOCK_INVOICE = 21
}

export interface PopoverEntry {
  title: string;
  content?: string;
  extraContent?: string;
  noteKey?: string;
}

export interface ClientOption extends DisplayValueOptional { client: string, emailKey: string }
export interface DisplayValueOptional { display?: string, value?: any }
export interface DisplayValue { display: string, value: any }
export interface DisplayValueTyped<T> { display: string, value: T }
export interface DisplayValueDisable extends DisplayValue { disabled: boolean }
export interface DisplayAction { display: string, action: (...args: any[]) => any }
export interface DisplayActionIcon extends DisplayAction { disabled?: boolean, icon: string, colour?: MaterialColourScheme }

export type MaterialColourScheme = 'primary' | 'accent' | 'warn' | 'basic' | 'success' | 'info' | 'warning' | 'danger' | 'light' | 'dark' | 'medium' | 'transparent' | 'none';

export interface DynamicDropDownMenu extends DisplayValue {
  family: DynamicDropDownMenu[];
  classes: string[];
  familyKey?: string;
  disabled?: boolean;
}

export interface SearchFilters {
  gmail: string[];
  outlook: string[];
  workspace: string[];
}

export interface EmailSearchMeta {
  email: string;
  pageLimit: number;
  nextPageToken: string;
  returnedCount: number;
  estimatedCount: number;
}

export interface SearchMeta {
  searchString?: string;
  searchType?: string;
  gmail: ClientSearchMeta;
  outlook: ClientSearchMeta;
  lastCounter?: number;
  filters?: any;
}

export interface ClientSearchMeta {
  emails: EmailSearchMeta[];
  totalReturnedCount: number;
  totalEstimatedCount: number;
  // after?: string;
  // before?: string;
}

export interface PendParticleResponse {
  particle: Particle;
  // model: NgbDateStruct;
  model: { year: number, month: number, day: number };
  time: TimeValue;
  noteContent?: string;
}

type Modify<T, R> = Omit<T, keyof R> & R;
interface DraftChanges {
  type: DraftUpdate;
  shouldSend?: boolean
}

export interface DraftChangePend extends DraftChanges {
  type: DraftUpdate.PEND | DraftUpdate.SEND_PEND
  pendArgs: PendParticleResponseContentBlocks;
}
export type PendParticleResponseContentBlocks = Modify<PendParticleResponse, {
  particle: ParticleContentBlocks;
}>

export interface DraftChangeParticle extends DraftChanges {
  type: DraftUpdate.AUTO_SAVE | DraftUpdate.SEND_FILE
  particle: ParticleContentBlocks;
}

export type ParticleContentBlocks = Modify<Particle, {
  content: string;
}>

export enum ButtonSpinnerState {
  'success' = 'success',
  'ready' = 'ready',
  'busy' = 'busy',
  'error' = 'error'
}

export interface TimezoneDifference {
  messageLong: string;
  messageShort: string;
  messageNone: string;
  minutes: number;
  hours: number;
  isAhead: boolean;
  aheadOrBehind: string;
  hasDifference: boolean;
  offset: number;
}


export interface IqConfigurationStripped {
  apiProfile: ApiProfile;
  apiStatus: ApiHealthIndicator;
  frequency: number;
  key: string;
  nickName: string;
  orgKey: string;
  settings: OrganizationSettings;
  plan: string;
  status: BasicState;
  userKey: string;
  type: string;

  /**
   * These are RepNumbers to ignore when processing.
   */
  repNumbersToIgnoreInMapping: string[];

  /**
   * All the users who may read this document and/or its sub-collections.
   * Maintained by DB Triggers to updates on this document's `orgUsers`, `userMapping` and `assignedUsers`.
   * Inclusive of the UserKey on this document.
   */
  allAssociatedUsers: Array<string>;

  /**
   * Users directly assigned to be able to read this document and/or its sub-collections.
   * This is maintained manually by developers/admins.
   */
  assignedUsers: Array<string>;

  /**
   * A copy of the UIDs of users from the orgKey - may be empty.
   * This is maintained by DB Triggers on the /organizations/{orgKey}/users collection.
   */
  orgUsers: Array<string>;

  /**
   * Mappings of user UIDs to rep numbers.
   * This is maintained by DB Triggers on the /gateway/.../{config}/reps collection.
   * 
   * @Exmaple
   * ``` {
   *  201: {
   *      userKey: 'userKey',
   *      repNumber: 201
   *   }
   * }```
   */
  userMapping: Record<string, RepDoc>;
}

export interface IqStrippedDebtor {
  modified: Date;
  account: string;
  linkaccount: string;
  name: string;
  alternativeName: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  postcode: string;
  country: string;
  deliver1: string;
  deliver2: string;
  deliver3: string;
  deliver4: string;
  deliveryPostcode: string;
  reps?: string[];
}

// Some fields are still stripped.
export interface IQConfiguration extends IqConfigurationStripped {
  lastException: {
    dateOfLastException: Date;
    exceptionJSON: string;
    message: string;
    name: string;
    stack: string;
  };
  updateRepsAt: Timestamp;
  lastCompletedOn: Timestamp;
  lastPerformedAt: Timestamp;
  dateCreated: Timestamp;
  performAt: Timestamp;
  previousIteration: PreviousIteration;
  processingStartedOn: Timestamp;
}

export interface RepDoc {
  key: string; // Copy of repNum.
  orgUserKey?: null | string;
  orgUserName?: null | string;
  orgUserRef?: null | string | DocumentReference;
  repName: string;
  repNum: string; // Copy of key.
  // statsRef: string | DocumentReference;
}

export interface ApiProfile {
  companyCode: string;
  host: string;
  port: string;
  protocol: string;
  userNumber: number;
  userPsw: string;
}

interface PreviousIteration {
  debtTransactionDate: Timestamp;
  debtTransactionNumber: number;
  debtorsLastProcessedOn: Timestamp;
  invoiceDate: Timestamp;
  invoiceNumber: number;
  salesOrderDate: Timestamp;
  salesOrderNumber: number;

  monthlySummariesLastProcessedOn: Timestamp;
  weeklySummariesLastProcessedOn: Timestamp;
  dailySummariesLastProcessedOn: Timestamp;
}

export interface FirestoreChunk<T> {
  data: Array<T>;
  size: number;
  dateFrom: Timestamp;
  dateTo: Timestamp;
  processDate: Timestamp;
  idFrom: string;
  idTo: string;
  key: string;
  filterParameters?: Array<string>;
}

export interface ParsedChunk<T> {
  data: Array<T>;
  size: number;
  dateFrom: Date;
  dateTo: Date;
  processDate: Date;
  idFrom: string;
  idTo: string;
  key: string;
  filterParameters?: Array<string>;
}

export interface SqlDateKeyStats {
  [sqlDate: string]: {
    [typeKey: string]: {
      turnover: number;
      threeMonthAverage: number;
      weekAverage: number;
      dayAverage: number;

      count: number;
      credit: number;
      creditTax: number;
      debit: number;
      debitTax: number;
      profit: number;
    };
  };
}

export interface MenuConfiguration {
  [key: string]: {
    header: string;
    showFolders: boolean;
    topTierMenuItems: Array<MenuItem>;
    middleTierMenuItems: Array<MenuItem>;
  }
}

export interface MenuItem {
  order: number;
  count: number;
  id: string;
  section: string;
  showCount: boolean;
  isHighlighted: boolean;
  children?: MenuItem[];

  /**
   * The name of the menu item - this is what is displayed in the UI.
   */
  name: string;

  /**
   * The string path used for the navigation.
   */
  path: string;

  /**
   * An icon name from the Material Icon font.
   */
  icon?: string;

  /**
   * The name of an image in the assets directory to use as the icon.
   */
  svg?: string;
}

export interface CurrentConfig {
  header: string,
  showFolders: boolean,
  topTierMenuItems: MenuItem[],
  middleTierMenuItems: MenuItem[],
}


export interface YearCycle {
  key: string;
  value: {
    month: string;
    monthEnd: {
      custom?: boolean;
      NextBusinessDateEnd: string;
      PrevBusinessDateEnd: string;
      dayEnd: string;
      monthEndDate: Timestamp;
      monthEndNextBusinessDate: Timestamp;
      monthEndPrevBusinessDate: Timestamp;
    };
    monthStart: {
      custom?: boolean;
      NextBusinessDateStart: string;
      PrevBusinessDateStart: string;
      dayStart: string;
      monthStartDate: Timestamp;
      monthStartNextBusinessDate: Timestamp;
      monthStartPrevBusinessDate: Timestamp;
    };
    year: string;
    parameters: {
      MonthEnd: number;
      MonthStart: number;
      YearEnd: Timestamp;
      YearStart: Timestamp;
    };
  };
}

export interface Folder {
  folderKey: string;
  id: string;
  uid: string;
  name: string;
  count: number;
  icon: string;
  particles: [];
  section: string;
  position: 0;
}

export interface NewgenusRoute {
  systemRoute: boolean;
  key: string,
  parentFeatures?: string[],
  features: string[],
  route: string,
  routeFeature: string,

}

export interface Search {
  id: string,
  emailId: string

}


/**
 * @memberof DateCycles
 */
export interface YearMonthCyclesParameters {
  yearStart: moment.Moment;
  yearEnd: moment.Moment;
  monthStart: number;
  monthEnd: number;
  cycleType: string;
  cycleYear: string;
  businessDaySelection: string;
  // nextBusinessDay: boolean;
  // prevBusinessDay: boolean;
}