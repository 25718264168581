import { createAction, props } from '@ngrx/store';
import { Search } from '@newgenus/common';

export const loadSearch = createAction('[Search Resolver] Load Search Document');

export const allSearchLoaded = createAction('[Load Search Effect] All Search Loaded', props<{ payload: Search[] }>());

export const addManySearchLoaded = createAction('[Add Many Search] Add Many Search', props<{ payload: Search[] }>());

export const clearAllSearch = createAction('[Clear All Search] Clear All Search', props<{ payload: Search[] | null }>());

export const searchAdded = createAction('[Search] added', props<{ payload: Search }>());

export const searchRemoved = createAction('[Search] removed', props<{ payload: Partial<Search> }>());

export const searchModified = createAction('[Search] modified', props<{ payload: Partial<Search> }>());

export const searchUpdate = createAction('[Search updating] updating Search', props<{ payload: Partial<Search> }>());

export const searchUpdated = createAction('[Search] modified', props<{ payload: Partial<Search> }>());
