<h2 mat-dialog-title>Select Preferred Order of Folders</h2>

<div mat-dialog-content>
    <div class="card-body canscroll" cdkDropList #customFolder="cdkDropList" 
         [cdkDropListData]="[customFolders]"
        (cdkDropListDropped)="onDrop($event)">

        <div *ngFor="let customFolder of customFolders; let i = index" [attr.data-cy]="'folder-order-' + (i + 1)" class="customFolder-list column" cdkDrag>
            <div class="particle-box card-header">
                {{customFolder.name}}
            </div>
        </div>

    </div>
</div>

<div mat-dialog-actions class="d-flex">
  <div class="flex-spacer"></div>
  <button mat-raised-button [mat-dialog-close]="undefined" data-cy="dialog-confirm" color="primary" cdkFocusInitial>Done</button>
</div>

