<div class="reset-wrapper">
  
  <div id="forgot-psw-formContent">
  
      <div class="forgot-psw-form">
        <form [formGroup]="form" (ngSubmit)="submit()" class="pb-3">
          <!-- Email input field -->
          <input formControlName="email" class="mb-3" [ngClass]="{'no-action': isOperationRunning}" [disabled]="isOperationRunning" name="email" data-cy="email" type="text" placeholder="Email" />
        
          <!-- Submit button -->
          <button mat-raised-button color="primary" [disabled]="isOperationRunning || form.invalid" data-cy="submit" type="submit" (click)="submit()">Reset Password</button>
        </form>
      </div>

      <div class="reset-error-msg" *ngIf="errorInReset">
          <span>Something went wrong! Please try again later.</span>
      </div>
      <div class="reset-error-msg" *ngIf="unrecognizedEmail">
          <span>Email address not recognized.</span>
      </div>

    <div id="formFooter">
      <br />
      <span><a class="underlineHover" routerLink="/login">Back to login</a></span>

    </div>

</div>
</div>





