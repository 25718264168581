
<div [formGroup]="form" style="display:inherit;">
  
  <div class="col-1">
  <label *ngIf="question.controlType !== 'datepicker'"[attr.for]="question.key" class="form-label">{{question.label}}</label>
  </div> 
  <div [ngSwitch]="question.controlType" class="col-4" *ngIf="embeddedFormControl !== undefined">
  
    <input *ngSwitchCase="'textbox'" [formControlName]="question.key"
            [id]="question.key" [type]="question.type" class="form-input" 
            [ngClass]="{
            'is-error':!isValid,
            'is-success':isValid}">

    <select [id]="question.key" *ngSwitchCase="'dropdown'" [formControlName]="question.key" class="form-select">
      <option *ngFor="let opt of question.options" [value]="opt.key">{{opt.value}}</option>
    </select>    

  
    <label class="form-switch" *ngSwitchCase="'switch'">
      <input type="checkbox" [formControlName]="question.key">
      <i class="form-icon"></i> {{question.text}}
    </label>
    
    <mat-form-field color="accent" *ngSwitchCase="'datepicker'" class="example-form-field" appearance="fill">
      <mat-label>{{question.label}}</mat-label>
      <mat-date-range-input [formGroup]="embeddedFormControl" [rangePicker]="marPicker" (onChange)="onDateChange($event)">
          <input matStartDate placeholder="Start date"  [formControlName]="'start'">
          <input matEndDate placeholder="End date" [formControlName]="'end'">
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="marPicker"></mat-datepicker-toggle>
      <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
      <mat-date-range-picker #marPicker></mat-date-range-picker>
    </mat-form-field>

  <p style="color:red" *ngIf="!isValid && embeddedFormControl.enabled"  >{{question.label}} is required</p>
  </div>  

</div>

<!-- <div [formGroup]="form" class="form-group" >
  
  <div class="col-1">
  <label *ngIf="question.controlType !== 'datepicker'"[attr.for]="question.key" class="form-label">{{question.label}}</label>
  </div> 
  <div [ngSwitch]="question.controlType" class="col-4">
  
    <input *ngSwitchCase="'textbox'" [formControlName]="question.key"
            [id]="question.key" [type]="question.type" class="form-input" 
            [ngClass]="{
            'is-error':!isValid,
            'is-success':isValid}">

    <select [id]="question.key" *ngSwitchCase="'dropdown'" [formControlName]="question.key" class="form-select">
      <option *ngFor="let opt of question.options" [value]="opt.key">{{opt.value}}</option>
    </select>    

  
    <label class="form-switch" *ngSwitchCase="'switch'">
      <input type="checkbox" [formControlName]="question.key">
      <i class="form-icon"></i> {{question.text}}
    </label>
    
    <mat-form-field *ngSwitchCase="'datepicker'" class="example-form-field" appearance="fill">
      <mat-label>{{question.label}}</mat-label>
      <mat-date-range-input [formGroup]="form.controls[question.key]" [rangePicker]="marPicker" >
          <input matStartDate placeholder="Start date"  [formControlName]="'start'">
          <input matEndDate placeholder="End date" [formControlName]="'end'">
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="marPicker"></mat-datepicker-toggle>
      <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
      <mat-date-range-picker #marPicker></mat-date-range-picker>
    </mat-form-field>

  <p style="color:red" *ngIf="!isValid"  >{{question.label}} is required</p>
  </div>  

</div> -->

