import { Particle } from "../classes/particle";


export function compareParticles(c1: Particle, c2: Particle, order: any): number {
    // TODO: This should be based on ParticleDate - Keagan.
    const compare = c2.creationDate.toDate().getTime() - c1.creationDate.toDate().getTime();
    switch (order.direction) {
        case 'asc':
            if (compare > 0) {
                return -1;
            } else if (compare < 0) {
                return 1;
            } else return 0;

        case 'dsc':
            if (compare > 0) {
                return 1;
            } else if (compare < 0) {
                return -1;
            } else return 0;
    }

    return 0;
}

export function sortParticlesOnAscDate(c1: Particle, c2: Particle): number {
    const compare = c2.creationDate.toDate().getTime() - c1.creationDate.toDate().getTime();

    if (compare > 0) {
        return -1;
    } else if (compare < 0) {
        return 1;
    } else return 0;
}

export function sortParticlesOnDscDate(c1: Particle, c2: Particle): number {
    const compare = c2.creationDate.toDate().getTime() - c1.creationDate.toDate().getTime();

    if (compare > 0) {
        return 1;
    } else if (compare < 0) {
        return -1;
    } else return 0;
}

export function sortParticlesOnAscType(c1: Particle, c2: Particle): number {
    const compare = c2.creationDate.toDate().getTime() - c1.creationDate.toDate().getTime();

    if (compare > 0) {
        return -1;
    } else if (compare < 0) {
        return 1;
    } else return 0;
}

export function sortParticlesOnTypeDscDate(c1: Particle, c2: Particle): number {
    const compare = c2.creationDate.toDate().getTime() - c1.creationDate.toDate().getTime();

    if (compare > 0) {
        return 1;
    } else if (compare < 0) {
        return -1;
    } else return 0;
}