import { cloneObject } from '@newgenus/common';

const defaultAlertOptions: AlertOptions = {
  lifetime: 6000,
  dismissible: false,
  icon: undefined
};

/**
 * AlertType
 *
 * Different types according to Bootstrap Alerts
 * @see: https://getbootstrap.com/docs/4.0/components/alerts/
 */
export enum AlertType {
  Success,
  Info,
  Warning,
  Danger
}

/**
 * Alert
 *
 * A simple alert for use with error messages etc
 * @see: https://getbootstrap.com/docs/4.0/components/alerts/
 */
export class Alert {
  static count = 0;

  id: number;

  constructor(
    public msg: string,
    public type: AlertType = AlertType.Info,
    public options: AlertOptions = {}
  ) {
    this.id = ++Alert.count;
    // set default icon
    if (!options.icon) {
      switch (type) {
        case AlertType.Success:
          options.icon = '👍';
          break;
      }
    }
    this.options = Object.assign(cloneObject(defaultAlertOptions), options);
  }
}

export interface AlertOptions {
  lifetime?: number;
  dismissible?: boolean;
  icon?: string;
}
