import { AngularFirestore } from '@angular/fire/compat/firestore';
import { inject, Injectable } from '@angular/core';


/**
 * TODO: rename this class to something better. The service is not only for hatting.
 */
@Injectable({
    providedIn: 'root'
})
export class OrgHattingService {

    private db = inject(AngularFirestore)

    public async onHatTodo() {

        // return this.mockRequest();

        // this.db.collection('organizations')
        //     .doc('xDN2IJuFIlu7fidUgP4p')
        //     .collection('securityGroups')

    }

    private mockRequest() {
        return new Promise((resolve, reject) => {

            // Random timeout between 1-2.5 seconds that returns randomly truel/false.
            const timeout = Math.floor(Math.random() * 1500) + 1000;

            setTimeout(() => {
                const randomSuccess = Math.random() > 0.5;
                resolve(randomSuccess);
            }, timeout);
        });
    }
}