import { inject, Inject, Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ECONode, ECOTree, Organization } from '@newgenus/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class OrgBoardService implements OnDestroy {

    /**
     * Subscribed to from the Organization Board Component.
     * Emitting on this subject will update the linking lines on the organization board.
     */
    public updateNodeLinking = new Subject<void>();

    /**
     * Emits when a colour change is made to a node.
     */
    public onColourChange = new Subject<any>();

    /**
     * VITAL to keep this in sync with the Organization Board Component.
     */
    public organization!: Organization;

    /**
     * The previous node updated with colours.
     * Used to prevent asking the user multiple times if they want to update all children.
     * @memberof OrgBoardService
     */
    private previousColorUpdateNode: ECONode<any> | undefined;

    /**
     * Used to determine if all children should be updated with the new colours.
     * Extracted to it's own variable to prevent asking the user multiple times if they want to update all children.
     */
    private updateAllChildren = false;

    private destroy$ = new Subject<void>();

    private snackBar = inject(MatSnackBar);

    constructor(private http: HttpClient, private db: AngularFirestore) {
        this.subscribeToColourChanges()
    }

    public inviteUsersToOrg(organizationId: string, emails: string[]) {
        console.log('OrgBoardService > inviteUsersToOrg > organizationId: ', organizationId);
        console.log('OrgBoardService > inviteUsersToOrg > emails: ', emails);
        // return this.http.post(`${environment.apiBaseUrl}/organization/${organizationId}/invite-users`, emails);
    }

    private subscribeToColourChanges() {
        this.onColourChange
            .pipe(
                debounceTime(300),
                takeUntil(this.destroy$)
            )
            .subscribe((node) => {
                console.log('onColourChange > node:', node);
                const originalBc = node.bc + '';
                const originalC = node.c + '';
                const originalLinkColor = node.linkColor + '';

                // If all the original colours are the same, return.
                if (originalBc === node.data.colour.background
                    && originalC === node.data.colour.color
                    && originalLinkColor === node.data.colour.linkColor)
                    return;

                node.bc = node.data.colour.background;
                node.c = node.data.colour.color;
                node.linkColor = node.data.colour.linkColor;

                // Ask only once if the user wants to update all children.
                if (!this.previousColorUpdateNode || this.previousColorUpdateNode.id !== node.id) {
                    this.updateAllChildren = confirm('Would you like to update all positions beneath this position as well?');
                }
                this.previousColorUpdateNode = node;

                if (this.updateAllChildren) {
                    function recursivelyUpdateChildren(nodeR: any) {
                        nodeR.nodeChildren.forEach((child: any) => {
                            child.bc = nodeR.bc;
                            child.c = nodeR.c;
                            child.linkColor = nodeR.linkColor;

                            if (child.nodeChildren && child.nodeChildren.length > 0) recursivelyUpdateChildren(child);
                        });
                    }

                    recursivelyUpdateChildren(node);
                } else {
                    node.nodeChildren.forEach((child: any) => {
                        if (child.bc === originalBc) child.bc = node.bc;
                        if (child.c === originalC) child.c = node.c;
                        if (child.linkColor === originalLinkColor) child.linkColor = node.linkColor;
                    });
                }

                if (node.data) {
                    this.updateOrgPart(node.data.partKey, { colour: node.data.colour });
                }

                this.updateNodeLinking.next();
            });
    }

    private updateOrgPart(partKey: string, updateBody: Record<string, any>) {
        const orgKey = this.organization?.key;
        if (!orgKey) return;

        this.db.collection('organizations')
            .doc(orgKey)
            .collection('parts')
            .doc(partKey)
            .update(updateBody)
            .then(() => {
                this.snackBar.open('Part updated successfully.', 'Close', { duration: 3000 });
            })
            .catch((error) => {
                console.error('updateOrgPart > error:', error);
                this.snackBar.open('Failed to update part.', 'Close', { duration: 3000 });
            });
    }

    public ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}