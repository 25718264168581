import { Timestamp } from "firebase/firestore";
import { BasicCrud } from '../general-models';
import { Widget } from './dashboard.models';
import { YearCycle } from './app.models';

export interface OrganizationMeta {
    orgParts: OrgPart[];
}

export interface Organization {
    dashboards?: OrgDashboard
    description: string;
    editors: string[];
    key: string;
    modifiedTimeStamp: Timestamp;
    name: string;
    orgDateCycles: OrgDateCycle[];
    orgFeatures: { featuresKeys: string[] } & { [key: string]: OrgFeature };
    orgHats: { hatsKeys: string[] } & { [key: string]: OrgHat };
    orgSecurityGroups: { securityGroupsKeys: string[] } & { [key: string]: OrgSecurityGroup };
    orgParts: OrgPart[];
    orgPolicies: { policiesKeys: string[] } & { [key: string]: OrgPolicy };
    orgUsers: { userKeys: string[] } & { [key: string]: OrgUser };
    owners: string[];
    plan?: string;
    settings: OrganizationSettings;
    users: string[];
    targets: {
        [key: string]: TargetGroup;
    }
    apis?: Array<{
        apiDocKey: string,
        name: string,
        type: string
    }>
}

/**
 * The order and orientation of org parts determines the hierarchy of the organization, including inheritance of features and permissions that have security groups whose access is inheritable (@see OrgSecurityGroup).
 */
export interface OrgPart {
    depth: number;
    id: number;
    key: string;
    name: string;
    parent: string;
    partKey: string;
    path: string;
    parentPartKey: string;
    parentRef: string;
}

export interface OrgFeature {
    name: string;
    enabled: boolean;
    featureGroups: string[];
    planGroups: string[];
    permissions: string[];
    key: string;
    systemFeature: boolean;
}

export interface OrganizationSettings {
    consolidateHours: boolean,
    dayOfMonthEnd: number,
    dayOfMonthStart: number,
    dayOfWeekEnd: number,
    dayOfWeekStart: number,
    hourOfDayEnd: number,
    hourOfDayStart: number,
    monthWorkingDays: number,
    weekWorkingDays: number,
    workingHours: number
}

export interface OrgUser {
    api: Record<string, TargetGroup>;
    dashboards: OrgDashboard;
    featureDetails: OrgUserFeatureDetails;
    features: string[];
    hats: string[];
    hatsDetails: OrgUserSecurityGroupDetails;
    key: string;
    name: string; // Duplicates?? vv
    roles: string[];
    selectedGatewayKey: string;
    statistics: { [configKey: string]: { configName: string, configRef: string, configType: string, repNo: string }; }
    targets: { [configKey: string]: { dailyTarget: number, monthlyTarget: number, weeklyTarget: number } }
    uid: string;
    userName: string; // ^^ Duplicates?? 
}

export interface OrgDashboard {
    [key: string]: {
        dashboardKey: string;
        description: string;
        name: string;
        widgets: Widget<any>[];
    }
}

/**
 * @deprecated no longer in use this interface is.
 */
interface OrgDashboardWidget {
    col: number;
    description: string;
    name: string;
    rows: number;
    settings: any; // TODO...
    type: string;
}

interface OrgUserSecurityGroupDetails {
    [key: string]: OrgSecurityGroup;
}

interface OrgUserFeatureDetails {
    [key: string]: {
        permissions: string[];
    }
}

interface OrgSecurityGroupFeatureDetails {
    [key: string]: {
        key: string;
        name: string;
        permissions: string[];
        enabled: boolean;
        featureGroups: string[];
        planGroups: string[];
    };
}

/**
 * Security groups have features and permissions assigned to them.
 * Security groups can be assigned to users or hats.
 * Users/Hats can have multiple security groups assigned to them.
 * Security groups can or cannot have their access inherited by parent OrgParts (@see OrgPart).
 */
export interface OrgSecurityGroup {
    /**
     * Flag to indicate if the security group is assigned to a user.
     */
    assigned: boolean;

    /**
     * The user key that the security group is assigned to.
     */
    assignedTo: string;
    
    featureDetails: OrgSecurityGroupFeatureDetails;
    features: string[];
    key: string;
    name: string;
    
    orgPosition: OrgPart; // TODO: this should be an array.
    policies: string[]; // TODO: Remove this
    roles?: string[]; // TODO: Remove this
    multiUsers?: boolean; // TODO: Remove this - they're all technically multi-users
}

/**
 * Org hats have security groups assigned to them.
 * These security groups have features and permissions assigned to them.
 * Users are assigned to hats, this is what gives them access to the features and permissions (In some RARE cases, security groups with are assigned directly to users).
 */
export interface OrgHat {
    /**
     * The name of the hat.
     */
    name: string;

    /**
     * Keys of the users who are wearing this hat.
     */
    userKeys: string[];

    /**
     * Policies associated with this hat.
     */
    policyKeys: Record<string, string>;

    /**
     * TODO this isn't anything final, it's a place-marker for now.
     * @deprecated - Not finished designing yet...
     */
    checklist?: Array<{
        name: string;
        items: Array<any>
    }>;

    /**
     * Security groups associated with this hat.
     */
    securityGroups: OrgSecurityGroup[];

    /**
     * Key of the hat.
     */
    key: string;

    /**
     * Description of the hat.
     */
    description: string;

    /**
     * The date the hat was created.
     */
    createdDate: Date;

    /**
     * The date the hat was last modified.
     */
    lastUpdatedDate: Date;

    /**
     * If the hat can be worn by multiple users.
     */
    isMultiUsers: boolean;

    /**
     * If the hat is soft deleted.
     */
    isSoftDeleted: boolean;

    /**
     * Org Parts (Org Positions) associated with this hat.
     * A hat can appear on multiple parts of the organization, this does not affect the hierarchy of feature, permissions and inheritance (@see OrgPart).
     */
    orgPosition: OrgPart[];
}



export interface OrgTarget {
    targets: Record<string, TargetGroup>
    type: TargetType;
    key: string;
}

export interface TargetGroup {
    dailyTarget: number;
    monthlyTarget: number;
    weeklyTarget: number;
}

export interface OrgDateCycle extends BasicCrud {

    businessDaySelection: string;
    default: boolean;
    key: string;
    profileName: string;
    softDeleted: boolean;
    type: string;
    year: string;
    yearCycle: Array<YearCycle>;

    /**
     * A flag to indicate if this date cycle should be marked for review by a user on the frontend.
     * This is required if there are unusual changes to the date cycle, such as a change in the month start / end dates where business days are involved.
     */
    reviewRequired?: boolean;

    /**
     * Version hash to indicate what version of the date cycle this is.
     * This is used to determine if the date cycle has been updated and if a banner/indicator must be shown that the data is updating.
     */
    hash: string;

    /**
     * The updated version of the date cycle that has not yet been published.
     * This is used as a proxy update until the Client ABC has processed the new updated date cycle.
     * Once that is done, the proxy version will be set as the new version.
     */
    proxyUpdate?: OrgDateCycle;

    /**
     * An array containing relationships between date cycles.
     */
    linkDateCycles: Array<{
        key: string;
        name?: string;
        year: string;
    }>;

    /**
     * The family key is used to identify the linage of the date cycle.
     * When generating new date cycles, the family key is used to determine the origin relationship between date cycles.
     * This is also used in ClientABC for query purposes.
     */
    familyKey: string;

    parameters: {
        monthEnd: number;
        monthStart: number;
        yearEnd: Timestamp;
        yearStart: Timestamp;
    };

    regenStrategy: {
        /**
         * A flag indicating that the dateCycle has regenerated.
         * if true regardless of the dateOfRegen, the dateCycle is marked as regenerated and will be ignored in the next regeneration cycle (cron-job task/cycle).
         * if false and the dateOfRegen is near, the dateCycle has not regenerated and will be regenerated in the next regeneration cycle (cron-job task/cycle).
         * Default is false.
         */
        hasRegenerated: boolean;
        /**
         * Default is 'oncePerYear' if not provided.
         * 
         * oncePerYear: The dateCycle is generated once per year, normally at the end of the year but this should be when the "year end" is specified on the dateCycle.
         * multiplePerYear: The dateCycle is generated multiple times per year, normally at the end of the month/quarter but this should be when the "year end" is specified on the dateCycle. Currently, this is not supported.
         * onceOff: The dateCycle is generated once and never again. This is used for dateCycles that are not meant to regenerate. Currently, this is not supported.
         */
        frequency: 'oncePerYear' | 'multiplePerYear' | 'onceOff';
        /**
         * Default is 'atEndOfCycle' if not provided.
         * 
         * fixedDate: The dateCycle is generated on a fixed date.
         * atEndOfCycle: The dateCycle is generated at the end of the cycle.
         * none: The dateCycle is not generated.
         */
        strategy: 'fixedDate' | 'atEndOfCycle' | 'none';
        /**
         * The date the dateCycle is due to regenerate.
         * The default value should be the year end date.
         * 
         * Regeneration occurs 1 month before the dateOfRegen, as of now with the current cronJob. - 19-2-2024
         */
        dateOfRegen: Timestamp;
    };
}

export enum TargetType {
    'COMPANY_TARGETS' = 'COMPANY_TARGETS',
    'USER_TARGETS' = 'USER_TARGETS'
}

export interface DateSettings extends OrganizationSettings {
    dateProfileKey?: string;
    monthsOfYear?: Array<YearCycle>;
    cycle?: OrgDateCycle;
}

export interface OrgPolicy {
    key: string;
    lastModifiedBy: string;
    lastModifiedDate: Date;
    name: string;
    policyWriting: string;
    revisions: Array<{
        date: Date;
        name: string;
        policyWriting: string;
        version: number;
        writtenBy: string;
    }>;
    version: number;
}