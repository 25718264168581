import { ClientAbcGroup, ClientAbcGroupDialogData, ClientAbcTransactionTypes, DefaultClientAbcSettings, DisplayValue, DisplayValueTyped, IqStrippedDebtor, RepDoc, deepCopy, hasPermission, toFirebaseTimestamp } from '@newgenus/common';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { MatStepper } from '@angular/material/stepper';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'shared-client-abc-group-dialog',
  templateUrl: './client-abc-group-dialog.component.html',
  styleUrls: ['../dialog.styles.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ClientAbcGroupDialogComponent implements OnInit, AfterViewInit {

  //#region Variables
  // Variables for this component that are within scope of "this" component.

  @ViewChild('stepper') stepper!: MatStepper;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  public existingGroups: DisplayValue[] = [];

  public debtorOptions: Array<GroupedEntries<IqStrippedDebtor>> = [];

  public clientAbcTransactionOptions: DisplayValueTyped<ClientAbcTransactionTypes>[] = [
    { display: 'Bank Charges', value: 'BC' },
    { display: 'Credit Notes', value: 'CN' },
    { display: 'Discounts', value: 'DS' },
    { display: 'Invoices', value: 'IN' },
    { display: 'Journal Credits', value: 'JC' },
    { display: 'Journal Debits', value: 'JD' },
    { display: 'Payments', value: 'PM' },
    { display: 'Rebates', value: 'RE' },
    { display: 'Refunds', value: 'RF' },
  ];

  public reviewModel = {
    key: '',
    title: '',
    accounts: '',
  }

  public form = new FormGroup({
    key: new FormControl<string | null>(null),
    title: new FormControl('', [Validators.required]),
    accounts: new FormControl(new Array<string>(), [Validators.required]),
    userKey: new FormControl(this.data.user.uid),
    orgKey: new FormControl(this.data.user.organizations?.selected),
    createdBy: new FormControl(this.data.user.firstName + ' ' + this.data.user.lastName),
    dateCreated: new FormControl(new Date()),
    lastUpdated: new FormControl(new Date()),
    lastProcessedOn: new FormControl<Date>(new Date('1899-12-30T00:00:00.000Z')),
    repNo: new FormControl<string | null>(null),
    repName: new FormControl<string | null>(null),
    configKey: new FormControl<string>(''),
    dateCycleKey: new FormControl<string>(''),
    settings: new FormGroup({
      transactionTypes: new FormControl(new Array(...DefaultClientAbcSettings.transactionTypes)),
      dynamicGrouping: new FormControl(DefaultClientAbcSettings.dynamicGrouping),
      appearance: new FormControl(DefaultClientAbcSettings.appearance),
    })
  });

  public newGroupName = '';
  public isExistingGroupSelected = false;
  public displayedColumns: string[] = ['select', 'groupKey', 'groupName'];
  public columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  public expandedElement: GroupedEntries<IqStrippedDebtor> | null = null;
  public dataSource = new MatTableDataSource<GroupedEntries<IqStrippedDebtor>>();
  public tableSelection = new SelectionModel<GroupedEntries<IqStrippedDebtor>>(true, []);
  public selectedDebtors: GroupedEntries<IqStrippedDebtor>[] = [];
  private signedInUsersRepMapping: RepDoc | undefined;
  public isLoading = false;

  //#endregion

  constructor(
    public dialogRef: MatDialogRef<ClientAbcGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ClientAbcGroupDialogData) {
  }

  //#region Instantiation
  // Setup\fetches\initialization.

  public ngOnInit(): void {
    // console.log('ClientAbcGroupDialogComponent > ngOnInit > data:', this.data);

    if (this.data.existingGroups) this.existingGroups = this.data.existingGroups.map(g => { return { display: g.title, value: g.key } });

    this.debtorOptions = [];
    const ungroupedDebtors: IqStrippedDebtor[] = [];

    this.isLoading = true;
    const mayViewAllDebtors = hasPermission(this.data.user, 'client-abc', 'create-company-custom-groups');

    // Find the rep mapping for the signed in user if it exists.
    this.signedInUsersRepMapping = Object.values(this.data.currentIntegration?.userMapping || {})
      .find((mapping) => mapping.orgUserKey === this.data.user.uid);

    this.data.debtorsObs.subscribe(debtors => {

      debtors.docs.forEach(doc => {
        const debtorChunk = doc.data();
        ungroupedDebtors.push(...debtorChunk['data']);
      });

      for (let i = 0; i < ungroupedDebtors.length; i++) {

        // Check that the current user has permission to view the debtor.
        // If they are listed on the debtors rep, then yes. Or, if they have mayViewAllDebtors, then yes.
        // Otherwise, skip the debtor.
        if (!mayViewAllDebtors && (!this.signedInUsersRepMapping || !ungroupedDebtors[i].reps?.includes(this.signedInUsersRepMapping.repNum))) continue;

        const dbt = ungroupedDebtors[i];
        const existing = this.debtorOptions.find(g => g.groupKey === dbt.account || g.groupKey === dbt.linkaccount);
        if (existing) {
          if (!dbt.linkaccount) {
            existing.groupKey = dbt.account;
            existing.groupName = dbt.name;
          }
          existing.data.push(dbt);
        } else {
          this.debtorOptions.push({
            groupName: !dbt.linkaccount ? dbt.name : `[[${dbt.name}]]`,
            groupKey: dbt.linkaccount ? dbt.linkaccount : dbt.account,
            data: [dbt]
          });
        }
      }
      this.debtorOptions.sort((a, b) => a.groupName.localeCompare(b.groupName));

      this.dataSource.data = this.debtorOptions;
    }, (err) => {
      console.error('ClientAbcGroupDialogComponent > ngOnInit > err:', err);
    }, () => {

      if (this.data.groupToEdit) {
        this.onKeyGroupName(this.data.groupToEdit.title);
      }

      this.isLoading = false;
    });

    // console.log('data.user.uid:', this.data.user.uid);
    // console.log('data.currentIntegration:', this.data.currentIntegration);
    // console.log('signedInUsersRepMapping:', this.signedInUsersRepMapping);

    if (this.signedInUsersRepMapping) {
      this.form.controls.repNo.setValue(this.signedInUsersRepMapping.repNum);
      this.form.controls.repName.setValue(this.signedInUsersRepMapping.repName);
    }

    if (this.data.currentIntegration && this.data.currentIntegration.key) {
      this.form.controls.configKey.setValue(this.data.currentIntegration.key);
    }

    if (this.data.currentDateCycle && this.data.currentDateCycle.dateProfileKey) {
      this.form.controls.dateCycleKey.setValue(this.data.currentDateCycle.dateProfileKey);
    }
  }

  public ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (group: GroupedEntries<IqStrippedDebtor>, filter: string) => {
      // Match the parent group properties.
      const matchedInParent = group.groupName.trim().toLowerCase().includes(filter.trim().toLowerCase());

      // Match the child properties by stringifying the data array and checking if it includes the filter.
      // const matchedInChild = group.data.map(d => JSON.stringify(d)).join('').toLowerCase().includes(filter.trim().toLowerCase());
      const matchedInChild = group.data.some(d => JSON.stringify(d).toLowerCase().includes(filter.trim().toLowerCase()))

      return matchedInParent || matchedInChild;
    };
  }

  //#endregion
  //#region User Interactions
  // Callbacks from html, anything the user can "interact" with from the view.

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  public toggleAllRows(): void {
    this.selectedDebtors = [];
    const isAllDataSelected = this.isAllOnTableSelected();
    // console.log('toggleAllRows > isAllDataSelected:', isAllDataSelected);
    // console.log('toggleAllRows > tableSelection.hasValue():', this.tableSelection.hasValue());

    // If there is a filter applied, select/deselect all the rows in the filtered list.
    if (this.dataSource.filter) {
      const isFilteredDataSelected = this.dataSource.filteredData.every(row => this.tableSelection.isSelected(row));
      if (isFilteredDataSelected) {
        this.dataSource.filteredData.forEach(row => this.tableSelection.deselect(row));
      } else {
        this.dataSource.filteredData.forEach(row => this.tableSelection.select(row));
      }
      this.selectedDebtors = [...this.tableSelection.selected];
    } else if (isAllDataSelected) {
      this.tableSelection.clear();
    } else {
      this.tableSelection.clear();
      this.tableSelection.select(...this.dataSource.data);
      this.selectedDebtors = [...this.tableSelection.selected];
    }

    this.updateFormAccounts();
  }

  /** The label for the checkbox on the passed row */
  public checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllOnTableSelected() ? 'Deselect' : 'Select'} all`;
    }
    return `${this.tableSelection.isSelected(row) ? 'Deselect' : 'Select'} row ${row.position + 1}`;
  }

  public isRowSelected(row: any) {
    return this.tableSelection.isSelected(row);
  }

  public onToggleTableRow(tableRow: GroupedEntries<IqStrippedDebtor>): void {
    setTimeout(() => {

      this.tableSelection.toggle(tableRow);

      if (this.tableSelection.isSelected(tableRow) === false) {
        // Deselect all the rows in the sublist.
        this.selectedDebtors = this.selectedDebtors.filter(d => d.groupKey !== tableRow.groupKey);
      } else {
        // If the debtor is  not already in the selected list:
        const debtor = this.selectedDebtors.find(s => s.groupKey === tableRow.groupKey);
        if (!debtor) this.selectedDebtors.push(tableRow); // Add it.
        else debtor.data = tableRow.data; // Otherwise, update the data.
      }

      this.updateFormAccounts();
    });
  }

  public onListItemSelectionChange(isSelected: boolean, debtor: IqStrippedDebtor, expandedRow: GroupedEntries<IqStrippedDebtor>): void {
    const selectedDebtor = this.selectedDebtors.find(s => s.groupKey === expandedRow.groupKey);

    // Remove from the selected list.
    if (isSelected === false && selectedDebtor) {
      // Remove the debtor from the selectedDebtor.data array.
      selectedDebtor.data = selectedDebtor.data.filter(d => d.account !== debtor.account);

      if (selectedDebtor.data.length === 0) {
        // Remove the group from the selectedDebtors array.
        this.selectedDebtors = this.selectedDebtors.filter(s => s.groupKey !== selectedDebtor.groupKey);
        // Deselect the group from the table as well.
        this.tableSelection.deselect(expandedRow);
      }
    }
    // Add to the selected list.
    else if (isSelected === true) {

      // If the debtor isn't already in the selected list, add it.
      if (!selectedDebtor) {
        const copy = deepCopy(expandedRow);
        copy.data = [debtor];
        this.selectedDebtors.push(copy);
      } else {
        // Otherwise, add the debtor to the selectedDebtor.data array if it's not already there.
        if (!selectedDebtor.data.find(d => d.account === debtor.account)) {
          selectedDebtor.data.push(debtor);
        }
      }

      // If all the debtors in the group are selected, select the group.
      if (selectedDebtor && selectedDebtor.data.length === expandedRow.data.length) {
        this.tableSelection.select(expandedRow);
      }
    }

    this.updateFormAccounts();
  }

  public onSelectGroupOption(opt: MatAutocompleteSelectedEvent): void {
    this.onKeyGroupName(opt.option.value); // opt.option.value is the display value.
  }

  public onKeyGroupName(input: any) {
    if (!input) return;
    const term = (input?.target?.value || input).toLocaleLowerCase();

    const group = this.data.existingGroups?.find(g => g.title?.toLocaleLowerCase() === term);

    if (group) {
      const lastProcessDate = group.lastProcessedOn ? toFirebaseTimestamp(group.lastProcessedOn).toDate() : new Date('1899-12-30T00:00:00.000Z');
      this.newGroupName = group.title;
      this.isExistingGroupSelected = true;
      this.form.controls['key'].setValue(group.key);
      this.form.controls['title'].setValue(group.title);
      this.form.controls['accounts'].setValue(group.accounts);
      this.form.controls['lastProcessedOn'].setValue(lastProcessDate);
      this.form.controls['settings'].controls['transactionTypes'].setValue(group.settings?.transactionTypes || DefaultClientAbcSettings.transactionTypes);
      this.form.controls['settings'].controls['dynamicGrouping'].setValue(group.settings?.dynamicGrouping || DefaultClientAbcSettings.dynamicGrouping);
      this.form.controls['settings'].controls['appearance'].setValue(group.settings?.appearance || DefaultClientAbcSettings.appearance);

      // Pre-select all the debtors from the group.
      const accountsLoaded: string[] = [];
      for (let i = 0; i < group.accounts.length; i++) {
        const account = group.accounts[i];

        // Skip if already loaded.
        if (accountsLoaded.includes(account)) continue;

        // Find main account.
        const mainDebtorAccount = this.debtorOptions.find(debtor => debtor.groupKey === account || debtor.data?.find(linkAccount => linkAccount.account === account));
        if (mainDebtorAccount) {
          // Find if the debtor has already been selected.
          const selectedDebtor = this.selectedDebtors.find(s => s.groupKey === mainDebtorAccount.groupKey);
          if (selectedDebtor) { // Load the accounts of the debtor that have been selected in the table.
            console.log('Should never run...')
            const accountsOfDebtorSelected = mainDebtorAccount.data.filter(d => group.accounts.includes(d.account));
            if (accountsOfDebtorSelected) selectedDebtor.data = [...accountsOfDebtorSelected];
            else selectedDebtor.data = [];
            accountsLoaded.push(...selectedDebtor.data.map(d => d.account));
          }
          else { // Create selection from main account.
            const copy = deepCopy(mainDebtorAccount);
            copy.data = copy.data.filter(d => group.accounts.includes(d.account));
            this.tableSelection.select(copy);
            this.selectedDebtors.push(copy);
            accountsLoaded.push(...copy.data.map(d => d.account));
          }
        }
      }
      // console.log('tableSelection.selected count:', this.tableSelection.selected.length);
      // console.log('selectedDebtors count:', this.selectedDebtors.length);
      // console.log('dataSource.data count:', this.dataSource.data.length);

      // console.log('tableSelection.selected flatmap count:', this.tableSelection.selected.flatMap(s => s.data).length);
      // console.log('selectedDebtors flatmap count:', this.selectedDebtors.flatMap(s => s.data).length);
      // console.log('group.accounts count:', group.accounts.length);
      // console.log('isAllOnTableSelected:', this.isAllOnTableSelected());
      // console.log('tableSelection.hasValue():', this.tableSelection.hasValue());

    } else {
      this.form.controls['key'].setValue(null); // null indicates to save.
      this.form.controls['accounts'].setValue([]);
      this.selectedDebtors = [];
      this.isExistingGroupSelected = false;
      this.newGroupName = input?.target?.value || input;
      this.form.controls['lastProcessedOn'].setValue(new Date('1899-12-30T00:00:00.000Z'));
      this.form.controls['settings'].controls['transactionTypes'].setValue(DefaultClientAbcSettings.transactionTypes);
      this.form.controls['settings'].controls['dynamicGrouping'].setValue(DefaultClientAbcSettings.dynamicGrouping);
      this.form.controls['settings'].controls['appearance'].setValue(DefaultClientAbcSettings.appearance);
    }
  }

  public renameGroup(): void {
    const currentGroup = this.existingGroups?.find(g => g.value === this.form.controls['key'].value);
    if (!currentGroup) return;
    else currentGroup.display = this.newGroupName;
    this.form.controls['title'].setValue(this.newGroupName);
  }

  public clearGroupSelection(): void {
    this.form.controls['title'].setValue('');
    this.form.controls['key'].setValue(null);
    this.form.controls['accounts'].setValue([]);
    this.selectedDebtors = [];
    this.isExistingGroupSelected = false;
    this.newGroupName = '';
  }

  //#endregion
  //#region Events and Domain Functions
  // $watches/$on events, functions which don't fall into any of the other regions,
  // including any function that has to do domain functionality.

  public onStep(): void {
    // console.log('ClientAbcGroupDialogComponent > onStep > stepper:', this.stepper);
  }

  public submit(): Partial<ClientAbcGroup> {
    // console.log('ClientAbcGroupDialogComponent > submit > form:', this.form);

    return this.form.value as unknown as Partial<ClientAbcGroup>;
  }


  //#endregion
  //#region Data Access & Subscriptions
  // API interactions, NGRX selects, etc.

  //#endregion
  //#region Setters, Updaters and Preloaders
  // Setter functions, and methods called from the Instantiation region.

  private updateFormAccounts(): void {
    this.form.controls.accounts.setValue(this.selectedDebtors.reduce((acc, curr) => {
      acc.push(...curr.data.map(d => d.account));
      return acc;
    }, new Array<string>()));
  }

  //#endregion
  //#region Getters and Filters
  // Getter functions and filter methods/functions.

  public getSelectedAccountsCount() {
    return this.selectedDebtors.reduce((acc, curr) => acc + curr.data.length, 0);
  }

  //#endregion
  //#region Change Detection
  // Deep config comparisons, change log generation, hasChangesFn, etc.

  //#endregion    
  //#region Parsing and Validation
  // Transformation/mapping methods
  // Methods returning true/false: isCurrent, areFeedsEqual, shouldProcess, etc.


  /** Whether the number of selected elements matches the total number of rows. */
  public isAllOnTableSelected() {
    const numSelected = this.tableSelection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  public isSubListIntermediate(subList: GroupedEntries<IqStrippedDebtor>) {
    // If the group is not selected, return false.
    const groupSelected = this.selectedDebtors.find(s => s.groupKey === subList.groupKey);
    if (!groupSelected) return false;

    let numSelected = 0;
    subList.data.forEach(d => {
      if (groupSelected.data.find(s => d.account === s.account)) numSelected++;
    });

    const numRows = subList.data.length;
    return numSelected > 0 && numSelected < numRows;
  }

  public isListItemSelected(debtor: IqStrippedDebtor, sublist: GroupedEntries<IqStrippedDebtor>): boolean {
    const selectedSublist = this.tableSelection.selected.find(s => s.groupKey === sublist.groupKey);
    if (!selectedSublist) return false;
    return selectedSublist.data.find(d => d.account === debtor.account) !== undefined;
  }

  //#endregion
  //#region Response Handlers
  // API/NGRX callback handlers.

  //#endregion
  //#region Navigation
  // Confirmation close dialogs, router guards, etc.

  //#endregion
  //#region Post Instantiation
  // Post-init fetch requests, used in such as landing page, after view init, etc.

  //#endregion

}

interface GroupedEntries<T> {
  groupName: string;
  groupKey: string;
  data: T[];
}