import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { RoutesActions } from '../routes/action-types';
import { NewgenusRoute } from '@newgenus/common';
import { createReducer, on } from '@ngrx/store';

// Entity adapter & initial state
export interface RoutesState extends EntityState<NewgenusRoute> {
    allRoutesLoaded: boolean; 
}

export const adapter = createEntityAdapter<NewgenusRoute>({
    // sortComparer: compareParticles,
    selectId: route => route.key,
});

export const initialRoutesState = adapter.getInitialState({
    allRoutesLoaded: false,
    // particleSortOrder: {
    //     name: 'creationDate',
    //     direction: 'asc',
    // },
});

// Reducer functions

export const routesReducer = createReducer(
    initialRoutesState,

    on(RoutesActions.allRoutesLoaded, (state, action) => {
        return adapter.setAll(action.routes, { ...state, allRoutesLoaded: true });
    }),

    on(RoutesActions.routeAdded, (state, action) => {
        return adapter.setOne(action.payload, { ...state, allRoutesLoaded: true });
    }),

    on(RoutesActions.routeRemoved, (state, action) => adapter.removeOne(action.payload.key as string, state)),

    on(RoutesActions.routeModified, (state, action) => {
        // delete action.payload.content;
        return adapter.updateOne({ id: action.payload.key as string, changes: action.payload }, state);
    }),

);

export const { selectAll } = adapter.getSelectors();
