import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { LoggingInComponent } from './auth/logging-in/logging-in.component';
import { SplashComponent } from './components/splash/splash.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { LoginComponent } from './auth/login/login.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth-guard.service';
import { NgModule } from '@angular/core';

const routes: Routes = [

    { path: 'login', component: LoginComponent },

    { path: 'forgot-password', component: ForgotPasswordComponent },

    { path: 'logout', component: LogoutComponent },

    { path: 'logging-in', component: LoggingInComponent },

    { path: 'splash', component: SplashComponent },

    {
        path: 'styleguide',
        loadChildren: () => import('./dev/styleguide/styleguide.module').then(m => m.StyleGuideModule),
        canActivate: [AuthGuard],
    },

    // {
    //     path: 'dashboard',
    //     loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    //     canActivate: [AuthGuard],
    // },

    {
        path: 'app',
        loadChildren: () => import('./newgenus.module').then(m => m.NewgenusModule),
    },

    {
        path: '**',
        redirectTo: 'app',
    },

    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'app',
    },

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
