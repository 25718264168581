/* eslint-disable @typescript-eslint/member-ordering */
import { AddWidgetDialogInputData, DisplayAction, DisplayActionIcon, InstantiatedWidget, IqConfigurationStripped, Organization, User, Widget, WidgetSettingsDialogInputData, deepCompare, deepCopy } from '@newgenus/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { WidgetSettingsDialogComponent } from '../dialogs/widget-settings-dialog/widget-settings-dialog.component';
import { AddWidgetDialogComponent } from '../dialogs/add-widget-dialog/add-widget-dialog.component';
import { CdkDropList, CdkDragEnter, moveItemInArray, CdkDragDrop } from "@angular/cdk/drag-drop";
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { ngResizeObserverProviders } from "ng-resize-observer";
import { ResizeObserver } from '@juggle/resize-observer';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import moment from 'moment';


@Component({
    selector: 'shared-dashboard-grid',
    templateUrl: './dashboard-grid.component.html',
    styleUrls: ['./dashboard-grid.component.scss'],
    providers: [...ngResizeObserverProviders]
})
export class DashboardGridComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    // public isHandset$!: Observable<any>;

    //#region Variables
    // Variables for this component that are within scope of "this" component.

    @ViewChildren(CdkDropList)
    public dropsQuery!: QueryList<CdkDropList>;

    @ViewChild('content')
    private elementView!: ElementRef;

    @Input() integrations: IqConfigurationStripped[] | undefined;
    @Input() organization: Organization | undefined;
    @Input() user: User | undefined;
    @Input() dashboardKey = '';
    @Input() widgets: InstantiatedWidget<any, any>[] = [];
    @Output() addNewWidget = new EventEmitter<Widget<any>>();
    @Output() updateWidgetsOrder = new EventEmitter<InstantiatedWidget<any, any>[]>();
    @Output() updateWidget = new EventEmitter<InstantiatedWidget<any, any>>();
    @Output() deleteWidget = new EventEmitter<InstantiatedWidget<any, any>>();

    /**
     * Based on the dashboardKey, it will just be formatted first.
     */
    public dashboardName = '';
    public drops: CdkDropList[] = [];

    /**
     * Menu options for widgets.
     * This is dynamically generated on the fly for each widget.
     */
    public menuOptions: CustomMenuOptions<{ selected: boolean }>[] = [];


    public originalWidgets = [
        { title: "Company 7R Graph", cols: 4, rows: 2, color: "#4a4a4a", component: "company-7r-graph", expand: false },
        { title: "User 7R Graph", cols: 4, rows: 4, color: "#4a4a4a", component: "user-7r-graph", expand: false },
        { title: "Client-ABC", cols: 4, rows: 2, color: "#4a4a4a", component: "client-abc", expand: false },
    ];

    public selectedWidget: Widget<any> | null = null;
    private previousWidgets: Widget<any>[] | null = null;

    public isDragging = false;
    public busyProcessing = false;

    // Grid resize variables.
    private pageWidth = 0;
    // private width$ = new BehaviorSubject<any>(0);
    private observer!: ResizeObserver;
    private activeMediaQuery = '';
    private watcher!: Subscription;
    public breakpoint = 2;
    private contentHeight = 0;
    private gridByBreakpoint: Record<string, number> = {
        xl: 8,
        lg: 6,
        md: 4,
        sm: 2,
        xs: 2
    }

    // Widget options.
    public allColumnWidths = [2, 4, 6, 8];
    public allRowsWidths = [2, 4, 6, 8];

    /**
     * Used to update the titles of the widgets for the 7r graphs.
     *
     * @type {Date} - The date to use for the title.
     * @memberof DashboardGridComponent
     */
    public fromDate?: Date;

    /**
     * Used to update the titles of the widgets for the 7r graphs.
     *
     * @type {Date} - The date to use for the title.
     * @memberof DashboardGridComponent
     */
    public toDate?: Date;

    public form = this.fb.group({
        cols: new FormControl('', Validators.required),
        row: new FormControl('', Validators.required),
    });
    public draggingEntry: ElementRef<HTMLElement> | undefined;

    /**
     * A boolean to force the re-calculation of widget's dimensions and update their scales.
     * Mainly used for the 7r graphs.
     */
    public refreshScale = false;

    //#endregion

    constructor(public mediaObserver: MediaObserver,
        private host: ElementRef,
        private dialog: MatDialog,
        private analytics: AngularFireAnalytics,
        private fb: FormBuilder) { }


    //#region Instantiation
    // Setup\fetches\initialization.

    public ngOnInit(): void {

        // TODO fine tune this to monitor grid items.
        this.observer = new ResizeObserver(entries => {

            entries.forEach(() => {
                if (this.elementView) this.contentHeight = this.elementView.nativeElement.offsetHeight;
            });

            this.pageWidth = entries[0].contentRect.width;
            // this.zone.run(() => {
            //     console.log('ResizeObserver', entries[0].contentRect.width);
            //     this.width$.next(entries[0].contentRect.width);
            // });
        });

        this.observer.observe(this.host.nativeElement);

        this.watcher = this.mediaObserver.asObservable().subscribe((change: MediaChange[]) => {
            this.activeMediaQuery = change?.length > 0 ? `'${change[0].mqAlias}' = (${change[0].mediaQuery})` : '';
            setTimeout(() => {
                if (change?.length > 0) {
                    const alias = change[0].mqAlias;
                    this.breakpoint = this.gridByBreakpoint[alias];
                }
            });

        });
    }

    //#endregion
    //#region User Interactions
    // Callbacks from html, anything the user can "interact" with from the view.

    public drop($event: CdkDragDrop<any>) {
        if (this.previousWidgets !== undefined && !deepCompare(this.previousWidgets, this.widgets)) {
            // this.previousWidgets = [...this.widgets]; // deepCopy<any>(this.widgets);
            this.previousWidgets = deepCopy<any>(this.widgets);
            this.updateWidgetsOrder.emit(this.widgets);
            // moveItemInArray(this.widgets, $event.item.data, $event.container.data);
        }
    }

    public dragStarted(): void {
        this.isDragging = true;
    }

    public dragEnded(): void {
        this.isDragging = false;
    }

    public openSettingsDialogForWidget(widget: InstantiatedWidget<any, any>) {
        const dialogRef = this.dialog.open(WidgetSettingsDialogComponent, {
            data: {
                allColumnWidths: this.allColumnWidths,
                allRowsWidths: this.allRowsWidths,
                filterBySalesCodes: [],
                integrations: this.integrations,
                organization: this.organization,
                currentUser: this.user as User,
                widget: widget,
            } as WidgetSettingsDialogInputData,
            width: '500px',
            height: '500px',
        });

        dialogRef.afterClosed().subscribe(newValues => {
            if (newValues) {

                // Update local widget, emit new settings on the settingSub.
                if (newValues.settings) {
                    const previousRepKeys = widget.settings.repKeys;
                    widget.settings = newValues.settings;
                    widget.settingsSub.next(newValues.settings);

                    if (widget.data.selectedRep && previousRepKeys !== widget.settings.repKeys) {

                        // If the widget is a clientAbc widget, then the repKeys is a string, not an array.
                        if (widget.type === 'clientAbc') {
                            widget.data.selectedRep.next(newValues.settings.repKeys[0]);
                        } else {
                            widget.data.selectedRep.next(newValues.settings.repKeys);
                        }

                    }

                    if (widget.data?.groupKeys && newValues.settings.groupKeys) {
                        widget.data.groupKeys.next(newValues.settings.groupKeys);
                    }
                }
                if (newValues.title) widget.title = newValues.title;
                if (newValues.auth) widget.auth = newValues.auth;

                this.updateWidget.emit(newValues);
            }
        });
    }

    public openAddWidgetDialog(): void {
        const ref = this.dialog.open<AddWidgetDialogComponent, AddWidgetDialogInputData>(AddWidgetDialogComponent, {
            // width: '800px',
            // height: '700px',
            data: {
                allColumnWidths: this.allColumnWidths,
                allRowsWidths: this.allRowsWidths,
                filterBySalesCodes: [],
                integrations: this.integrations,
                organization: this.organization,
                currentUser: this.user as User,
            }
        });

        ref.afterClosed().subscribe(result => {
            if (result) {
                this.addNewWidget.emit(result);
            }
        });
    }

    public onDeleteWidget(widget: InstantiatedWidget<any, any>) {
        if (confirm('Are you sure you want to delete this widget?')) this.deleteWidget.emit(widget);
    }

    public expand(index: number) {
        // this.widgets[index].expand = true;
    }

    public collapse(index: number) {
        // this.widgets[index].expand = false;
    }

    public onClientAbcGroupExpand(event: { isExpanded: boolean; row: any; }, widget: InstantiatedWidget<any, any>) {
        if (widget.type === 'clientAbcGroupList') {
            widget.data.expandFetchDataSubject.next(event);
        }
    }

    // public onClientAbcGroupEdit($event: ClientAbcGroupTableDatum) {
    //     throw new Error('Method not implemented.');
    // }

    // public onClientAbcGroupDelete($event: ClientAbcGroupTableDatum) {
    //     throw new Error('Method not implemented.');
    // }


    //#endregion
    //#region Events and Domain Functions
    // $watches/$on events, functions which don't fall into any of the other regions,
    // including any function that has to do domain functionality.

    public entered($event: CdkDragEnter): void {
        this.previousWidgets = deepCopy(this.widgets);
        moveItemInArray(this.widgets, $event.item.data, $event.container.data);
    }

    private onColChange(widget: InstantiatedWidget<any, any>, cols: number): void {
        widget.settings.columns = cols;
        this.updateWidget.emit(widget);
    }

    private onRowChange(widget: InstantiatedWidget<any, any>, row: number): void {
        widget.settings.rows = row;
        this.updateWidget.emit(widget);
    }

    public updateMenuOptions(widget: InstantiatedWidget<any, any>) {

        // Column and row options are present for all widgets.
        this.menuOptions = [
            {
                display: 'Columns',
                icon: 'swap_horiz',
                subMenuItems: this.allColumnWidths.map((col) => {
                    return {
                        selected: widget.settings.columns === col,
                        display: `${col} Columns`,
                        action: this.onColChange.bind(this, widget, col)
                    }
                }),
                action: () => {
                    // Do nothing...
                }
            },
            {
                display: 'Rows',
                icon: 'height',
                subMenuItems: this.allRowsWidths.map((row) => {
                    return {
                        selected: widget.settings.rows === row,
                        display: `${row} Row`,
                        action: this.onRowChange.bind(this, widget, row)
                    }
                }),
                action: () => {
                    // Do nothing...
                }
            },
        ];

        const settingsOpt: CustomMenuOptions<{ selected: boolean; }> = {
            display: 'Settings',
            icon: 'settings',
            action: this.openSettingsDialogForWidget.bind(this, widget)
        };

        const deleteOpt: CustomMenuOptions<{ selected: boolean; }> = {
            display: 'Delete Widget',
            colour: 'warn',
            icon: 'delete_forever',
            action: this.onDeleteWidget.bind(this, widget)
        };

        switch (widget.type) {
            case 'daily7r':
            case 'monthly7r':
                this.menuOptions.push(
                    settingsOpt,
                    deleteOpt
                );
                break;
            case 'clientAbc':
            case 'clientAbcGroup':
            case 'clientAbcGroupList':
                this.menuOptions.push(
                    settingsOpt,
                    deleteOpt
                );
                break;
            case 'clientAbcGraph':
                settingsOpt.disabled = true;

                this.menuOptions.push(
                    settingsOpt,
                    deleteOpt
                );
                break;
            default:
                console.error('IntermediateComponent.updateMenuOptions > type not recognized', widget.type);
                alert('Action failed - please try again.');
                break;
        }
    }

    public onFromOrToDateChange(widget: InstantiatedWidget<any, any>): void {
        if (this.fromDate && this.toDate) {
            setTimeout(() => {
                this.updateWidgetSubtitle(widget, this.pageWidth);
            });
        }
    }

    //#endregion
    //#region Data Access & Subscriptions
    // API interactions, NGRX selects, etc.

    public onWidgetResize(event: { width: number, height: number }, widget: InstantiatedWidget<any, any>) {
        // console.log('onWidgetResize', event, widget);

        setTimeout(() => {
            this.updateWidgetSubtitle(widget, event.width);
        });

        // console.log('onWidgetResize', event, widget);
        this.refreshScale = !this.refreshScale;
        console.log('onWidgetResize > refreshScale',this.refreshScale);
    }

    //#endregion
    //#region Setters, Updaters and Preloaders
    // Setter functions, and methods called from the Instantiation region.

    private updateWidgetSubtitle(widget: InstantiatedWidget<any, any>, containerWidth: number) {
        // Breakpoints reference from CSS:
        const phone = 320;
        const tabletSm = 534;
        const tablet = 768;
        const desktop = 1024;

        if (widget.type === 'daily7r') {

            // If widget's container width like a phone, only show the time.
            if (containerWidth <= phone)
                widget.subtitle = `${moment(this.fromDate).format('H:mm')} - ${moment(this.toDate).format('H:mm')}`
            // If widget's container width like a small tablet, show the time and short date.
            else if (containerWidth <= tabletSm)
                widget.subtitle = `${moment(this.fromDate).format('H:mm')} - ${moment(this.toDate).format('H:mm,')} ${moment(this.toDate).format('MMM')} ${moment(this.fromDate).format('Do')}`
            // If widget's container width like a tablet, show the time and full date.
            else if (containerWidth <= tablet)
                widget.subtitle = `${moment(this.fromDate).format('dddd')} ${moment(this.fromDate).format('H:mm')} - ${moment(this.toDate).format('H:mm,')} ${moment(this.toDate).format('MMM')} ${moment(this.fromDate).format('Do')}`
            // If widget's container width like a desktop or bigger, show the time and full date.
            else if (containerWidth >= desktop)
                widget.subtitle = `${moment(this.fromDate).format('dddd')} ${moment(this.fromDate).format('H:mm')} - ${moment(this.toDate).format('H:mm,')} ${moment(this.toDate).format('MMM')} ${moment(this.fromDate).format('Do')}`

        } else if (widget.type === 'monthly7r') {

            // If the widget's container width like a phone, only show the shortest month range.
            if (containerWidth <= phone)
                widget.subtitle = `${moment(this.fromDate).format('MM/DD')} - ${moment(this.toDate).format('MM/DD')} `
            // If widget's container width like a small tablet, show smaller month range.
            else if (containerWidth <= tabletSm)
                widget.subtitle = `${moment(this.fromDate).format('MMM')} ${moment(this.fromDate).format('Do')} - ${moment(this.toDate).format('MMM')} ${moment(this.toDate).format('Do')}`
            // If widget's container width like a tablet, show full month range.
            else if (containerWidth <= tablet)
                widget.subtitle = `${moment(this.fromDate).format('MMMM')} ${moment(this.fromDate).format('Do')} - ${moment(this.toDate).format('MMMM')} ${moment(this.toDate).format('Do')}`
            // If widget's container width like a desktop or bigger, show full month range.
            else if (containerWidth >= desktop)
                widget.subtitle = `${moment(this.fromDate).format('MMMM')} ${moment(this.fromDate).format('Do')} - ${moment(this.toDate).format('MMMM')} ${moment(this.toDate).format('Do')}`

        } else if (widget.type === 'clientAbc') {
            if (this.user && widget.settings?.repKeys && this.user.organizations?.selected) {
                const selectedIntegration = this.user.organizations[this.user.organizations.selected].selectedGatewayKey;

                if (selectedIntegration) {
                    const integrationReps = Object.values(this.integrations?.find(i => i.key === selectedIntegration)?.userMapping || {});
                    const repDoc = integrationReps.find(r => r.key === widget.settings.repKeys[0]);
                    if (repDoc) {
                        widget.subtitle = repDoc.repName;
                    }
                }

            }
        }
    }

    //#endregion
    //#region Getters and Filters
    // Getter functions and filter methods/functions.

    //#endregion
    //#region Change Detection
    // Deep config comparisons, change log generation, hasChangesFn, etc.

    public ngOnChanges(changes: SimpleChanges): void {

        if (changes['dashboardKey'] && changes['dashboardKey'].currentValue) {
            // Replace dash with space, convert to title case.
            this.dashboardName = (changes['dashboardKey'].currentValue + '').replaceAll('-', ' ');
        }

    }

    //#endregion    
    //#region Parsing and Validation
    // Transformation/mapping methods
    // Methods returning true/false: isCurrent, areFeedsEqual, shouldProcess, etc.

    //#endregion
    //#region Response Handlers
    // API/NGRX callback handlers.

    //#endregion
    //#region Navigation
    // Confirmation close dialogs, router guards, etc.

    public ngOnDestroy(): void {
        this.watcher.unsubscribe();
        this.observer.unobserve(this.host.nativeElement);
    }


    //#endregion
    //#region Post Instantiation
    // Post-init fetch requests, used in such as landing page, after view init, etc.

    public ngAfterViewInit(): void {
        this.dropsQuery.changes.subscribe(() => {
            setTimeout(() => {
                const newArr = this.dropsQuery.toArray();
                this.drops = newArr || [];
            });
        });

        setTimeout(() => {
            const newArr = this.dropsQuery.toArray();
            this.drops = newArr || [];
        });

        if (this.elementView) this.contentHeight = this.elementView.nativeElement.offsetHeight;

    }

    //#endregion

}

interface CustomMenuOptions<T> extends DisplayActionIcon {
    subMenuItems?: (DisplayAction & T)[];
}