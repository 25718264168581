<h2 mat-dialog-title>{{hatName || 'Org Hat'}}

    <!-- Close button. -->
    <button mat-icon-button mat-dialog-close class="close-button">
        <mat-icon>close</mat-icon>
    </button>
</h2>

<mat-dialog-content>
    <mat-accordion class="example-headers-align w-100" multi>
        
        <!-- Users on hat -->
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>Users Wearing Hat</mat-panel-title>
                <mat-panel-description><mat-icon class="float-right">account_circle</mat-icon></mat-panel-description>
            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>

                <div class="view-container m-3">

                    <div class="view-header">
                        <span class="viewer-title-spacer">Users with this hat</span>
                    </div>

                    <div class="view-content">
                        <div *ngFor="let user of userDetailArray" class="user-list-container">

                            <span class="grouped">
                                <img [src]="'https://ui-avatars.com/api/?name=' + user.name + '&background=random' + '&color=random'">
                                <span>{{user.name | titlecase}}</span>
                            </span>
                        </div>
                    </div>
                </div>

            </ng-template>

        </mat-expansion-panel>

        <!-- Security Groups -->
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>Security Groups</mat-panel-title>
                <mat-panel-description><mat-icon class="float-right">lock</mat-icon></mat-panel-description>
            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>

                <div class="view-container m-3">

                    <div class="view-header">
                        <span class="viewer-title-spacer">Security Groups on Hat</span>
                    </div>
                
                    <div class="view-content">
                        <div class="bordered" *ngFor="let group of securityGroups">
                            <div class="title">Security Group</div>
                            <div class="p-2">
                                <strong>{{group.name}}</strong>
                                <p>Features</p>
                                <ul class="fs-0-9">
                                    <li *ngFor="let feature of group.features" class="clickable-li"
                                        (click)="onClickFeature(group, feature)">{{feature | normalize}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                
                </div>

            </ng-template>

        </mat-expansion-panel>
    </mat-accordion>

</mat-dialog-content>

<!-- <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
  </mat-dialog-actions> -->