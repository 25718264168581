import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Particle, User, firestoreTimestampNow } from '@newgenus/common';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { switchMap, Observable, filter, map } from 'rxjs';
import { Injectable, NgZone } from '@angular/core';
import { Themes, Plan } from '@newgenus/common';
import { ApiService } from './api.service';
import firebase from 'firebase/compat/app';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    public user$: Observable<User>;
    public user: any;
    private userToken: string | null | undefined;
    public redirectUrl!: string;
    public isLoggedIn!: boolean;
    public setupDone!: boolean;
    private oneInFlight = 0;
    private oauthedUser!: firebase.User;
    public isAdmin!: boolean;
    private registration!: boolean;

    constructor(
        public afAuth: AngularFireAuth,
        private afs: AngularFirestore,
        private apiService: ApiService,
        public ngZone: NgZone, // private particleService: ParticleService,
        private router: Router,
        private analytics: AngularFireAnalytics
    ) {
        // Get auth data, then get firestore user document || null
        this.user$ = this.afAuth.authState.pipe(
            filter((user) => user !== null && user !== undefined),
            map((user: firebase.User | null) => user as firebase.User), // if the filter above was recognized, this map wouldn't be neeed.
            switchMap(user => {
                this.user = user;
                this.isLoggedIn = true;

                return afs.doc<User>(`users/${user.uid}`).valueChanges()
            }),
            filter((user) => user !== null && user !== undefined),
            map((user: User | undefined) => user as User), // if the filter above was recognized, this map wouldn't be neeed.
        );

        this.afAuth.onAuthStateChanged((user) => {
            if (user) {
                firebase
                    .auth()
                    .currentUser?.getIdToken()
                    .then((idToken) => {
                        this.userToken = idToken;
                    });
            }
        });

    }

    public isInstantiated() {
        return new Promise<void>((resolve, reject) => {
            let isInstantiated = false;

            const subscription = this.user$.subscribe((user) => {
                if (user && user.preferences) {
                    isInstantiated = true;
                    resolve();
                } else if (user) {
                    // console.log('auth service > isInstantiated > not fully fletched user:', user);
                }
            });

            setTimeout(() => {
                if (!isInstantiated) {
                    subscription.unsubscribe();
                    reject();
                }
            }, 10000);
        });
    }

    async getUserToken() {
        if (this.userToken !== null && this.userToken !== undefined) {
            return this.userToken;
        } else {
            return await firebase
                .auth()
                .currentUser?.getIdToken()
                .then((idToken) => {
                    this.userToken = idToken;
                    return idToken;
                });
        }
    }

    // #region -------- Login/Sign-up to firebase   -----------------------------------

    public googleLogin(): Promise<void> {
        const provider = new firebase.auth.GoogleAuthProvider();
        return this.oAuthLogin(provider, 'google');
    }

    public microsoftLogin(): Promise<void> {
        const provider = new firebase.auth.OAuthProvider('microsoft.com');
        return this.oAuthLogin(provider, 'microsoft');
    }

    private oAuthLogin(provider: any, oauthProvider: string) {
        return this.afAuth.signInWithPopup(provider)
            .then(async (result: any) => {
                const idTokenResult: any = await firebase.auth().currentUser?.getIdTokenResult()
                    .catch((error) => {
                        console.error(error);
                    });

                if (idTokenResult.claims.admin) {
                    this.isAdmin = true;
                } else {
                    this.isAdmin = false;
                }
                if (idTokenResult.claims.reg) {
                    this.registration = true;
                } else {
                    this.registration = false;
                }

                // navigate to splash screen so long
                // this.router.navigate(['splash']);
                this.router.navigate(['logging-in']);

                if (result.credential) {
                    // This gives you a Google / Microsoft Access Token.
                }
                if (result.user) {
                    const displayNames: Array<string> = result.user.displayName
                        ? result.user.displayName.split(' ')
                        : undefined;
                    const firstNames = displayNames
                        ? displayNames.slice(0, displayNames.length > 2 ? displayNames.length - 1 : 1).join(' ')
                        : undefined;
                    const lastName = displayNames ? displayNames.slice(displayNames.length - 1, 2)[0] : undefined;

                    // this.isLoggedIn = true;
                    await this.updateUserData1(result.user, oauthProvider, firstNames ?? '', lastName ?? '');
                    this.oauthedUser = result.user;
                    if (!this.registration) {
                        this.subscribeToFirebaseUserProfile();
                    } else {
                        // console.log('User registration already DONE!');
                    }
                }
            });
    }

    async signInRegular(email: string, password: string) {
        // signing in with firebase using email and password
        // return this.afAuth.signInWithEmailAndPassword(email, password).then(async (result) => {

        return new Promise<void>((resolve, rejects) => {

            this.afAuth.signInWithEmailAndPassword(email, password).then(async (result) => {
                const idTokenResult: any = await firebase.auth().currentUser?.getIdTokenResult()
                    .catch((error) => {
                        console.error(error);
                    });


                if (idTokenResult.claims.admin) {
                    this.isAdmin = true;
                } else {
                    this.isAdmin = false;
                }
                if (idTokenResult.claims.reg) {
                    this.registration = true;
                } else {
                    this.registration = false;
                }

                if (result.user) {
                    setTimeout(async () => {
                        const displayNames: Array<string> | undefined = result.user.displayName
                            ? result.user.displayName.split(' ')
                            : undefined;
                        const firstNames = displayNames
                            ? displayNames.slice(0, displayNames.length > 2 ? displayNames.length - 1 : 1).join(' ')
                            : undefined;
                        const lastName = displayNames ? displayNames.slice(displayNames.length - 1, 2)[0] : undefined;

                        await this.updateUserData1(result.user, 'firebase', firstNames ?? '', lastName ?? '');
                        this.oauthedUser = result.user;
                        this.subscribeToFirebaseUserProfile();
                        resolve();
                    });
                }
            }).catch((error) => {
                rejects(error);
            });
        });
    }

    private subscribeToFirebaseUserProfile() {
        this.user$.subscribe(async (userDoc) => {
            if (userDoc) {
                this.user = userDoc;
                this.oneInFlight = this.oneInFlight + 1;
                this.setupDone = userDoc.setupDone ? true : false;

                //these needs to only happen once the first time.
                if (!userDoc.setupDone && !this.setupDone && this.oneInFlight < 2) {
                    this.setupDone = true;
                    this.updateUserData2(userDoc);
                    this.addPlans();
                    this.updateUserTransactions(userDoc);
                    await this.addLinkEmailAccountParticle(userDoc);
                    try {
                        const idToken = await firebase.auth().currentUser?.getIdToken() as string;
                        await this.apiService
                            .setUserClaimsForRegistration(idToken)
                            .toPromise()
                            .then((email) => email)
                            .catch((err) => console.error('Error occurred', err)); // TODO check if no sensitive data is printed out
                        this.registration = true;
                    } catch (error) {
                        this.registration = false;
                    }
                }
            }
        }, (ex) => {
            console.error('Error getting user doc', ex);
        });
    }

    signInAnonymous() {
        this.afAuth
            .signInAnonymously()
            .then(() => {
                // ...
            })
            .catch((error) => {
                // Handle Errors here.
            });
    }

    signOut() {
        if (location.href.includes('logout')) {
            this.analytics.logEvent('logout', {
              'event_category': 'Authentication',
              'event_label': 'Logout Screen',
            });
            this.isLoggedIn = false;
            this.clearCache().then(() => {
                this.signOutAndReloadAtLogin();
            }).catch(() => {
                this.signOutAndReloadAtLogin();
            })
        } else {
            this.router.navigate(['logout']);
        }
    }

    private signOutAndReloadAtLogin() {
        this.afAuth.signOut().then(() => {
            setTimeout(() => {
                // Hard navigate, this will reload the page and clear the cache.
                location.href = (location.href + '').replace('logout', 'login');
                // this.router.navigate(['/login']);
            }, 200);
        });
    }

    private clearCache(): Promise<void> {
        return this.afs.firestore.terminate().then(() => {
            // console.log('Firestore terminated');
            this.afs.firestore.clearPersistence().then(() => {
                // console.log('Cleared persistence');
            }).catch((ex) => {
                console.error('Error clearing persistence', ex);
            });
        }).catch((ex) => {
            console.error('Error terminating', ex);
        });
    }

    // #endregion --------------Login/Sign-up to firebase ----------------------------

    // #region -------- Create/Update user data/doc -----------------------------------

    private async addLinkEmailAccountParticle(user: any) {
        const newParticle = this.createNewLinkEmailAccountParticle();
        await this.createFirstParticle(newParticle);
    }

    public createNewLinkEmailAccountParticle() {
        const uid = firebase.auth().currentUser?.uid as string;
        const newParticle = new Particle();
        newParticle.toBlankSystem(uid);
        return newParticle;
    }

    async createFirstParticle(data: Particle): Promise<Particle | null> {
        if (data) {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async (resolve, reject) => {
                try {
                    const workspaceRef = this.afs.collection('workspace').doc(`${data.uid}`);

                    await workspaceRef.set({
                        id: data.uid,
                        inbasketCount: 0,
                        pendingCount: 0,
                        filedCount: 0,
                    });

                    const particleDocRef = this.afs
                        .collection('workspace')
                        .doc(`${data.uid}`)
                        .collection('particles')
                        .doc();

                    data.attachmentsMeta = data.attachmentsMeta !== undefined ? data.attachmentsMeta : {};
                    data.creationDate = firestoreTimestampNow();
                    data.internalDate = firestoreTimestampNow();
                    data.folders = data.folders ? data.folders : {};
                    data.forwardFromMessageId = data.forwardFromMessageId !== undefined ? data.forwardFromMessageId : '0';
                    data.particleKey = particleDocRef.ref.id;
                    data.temp = false;
                    data.content ??= '';

                    const particle = { ...data };
                    // create straight into firestore before using store

                    particleDocRef.set(particle).then(() => {
                        // ?
                    });

                    resolve(data);
                } catch (exception) {
                    console.error('Error adding document: ', exception);
                    reject(exception);
                }
            });
        }
        return Promise.resolve(null);
    }

    private async updateUserData1(user: any, oauthProvider: string, firstNames: string, lastName: string) {
        // Sets user data to firestore on login - these can change in google so we update them every login
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
        const data: Partial<User> = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            originalSignInProvider: oauthProvider,
        };
        if (firstNames) data.firstName = firstNames;
        if (lastName) data.lastName = lastName;

        return userRef.set(data, { merge: true });
    }

    private updateUserData2(user: any) {
        // Sets user data to firestore on login - these we only want to do first time setting up
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
        const data: any = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            isDeveloper: false,
            // theme: Themes['default-theme'],
            theme: Themes['light-theme'],
            // roles: {
            //     subscriber: true,
            //     editor: false,
            //     admin: false,
            // },
            // features: {
            //     feature1: false,
            //     emails: false,
            //     compose: false,
            // },
            // routes: {
            //     feature1: ['/secret'],
            //     emails: ['/emails'],
            //     compose: ['/compose'],
            // },
            preferences: {
                sidebarStatus: false,
                // photoURL: user.photoURL,
                particleLabelsOrder: ['SYSTEM', 'EMAIL', 'CHECKLIST', 'ORDER', 'DISPATCH'],
                dateSorting: 'desc',
                typeOfSort: 'date_type',
                signature: { type: 'OneForAll' },
            },
            serverExcludedLabels: [],
            setupDone: true,
            excludedLabels: ['CATEGORY_UPDATES', 'CATEGORY_PROMOTIONS', 'CATEGORY_SOCIAL', 'CATEGORY_FORUMS'],
            includedLabels: ['SAMPLE'],
            // originalSignInProvider: oauthProvider,
        };
        // if (firstNames) data.firstName = firstNames;
        // if (lastName) data.lastName = lastName;

        if (this.oauthedUser && this.oauthedUser.photoURL) {
            data.preferences.photoURL = this.oauthedUser.photoURL;
        }

        return (
            userRef.set(data, { merge: true }),
            (error: any) => {
                // Handle error here
                // Show popup with errors or just console.error
                console.error('error updateUserData: ', error);
            }
        );
    }

    private updateUserTransactions(user: any) {
        // Sets user data to firestore on login
        const transactionsRef: AngularFirestoreDocument<any> = this.afs.doc(`transactions/${user.uid}`);
        const data = {
            particleCount: 0,
        };
        return (
            transactionsRef.set(data, { merge: true }),
            (error: any) => {
                // Handle error here
                // Show popup with errors or just console.error
                console.error('error updateUserTransactions: ', error);
            }
        );
    }

    private addPlans() {
        // Sets user data to firestore on login
        let plansRef: AngularFirestoreDocument<any> = this.afs.doc(`plans/plan1`);
        let data: Plan = {
            name: 'plan1',
            // features: {
            //     feature1: true,
            //     emails: true,
            //     compose: true,
            // },
            // routes: {
            //     feature1: ['/secret'],
            //     emails: ['/content'],
            //     compose: ['/feature3'],
            // },
        };
        plansRef.set(data, { merge: true });

        plansRef = this.afs.doc(`plans/plan2`);
        data = {
            name: 'plan2',
            // features: {
            //     feature1: true,
            //     emails: false,
            //     compose: false,
            // },
            // routes: {
            //     feature1: ['/secret'],
            //     emails: ['/content'],
            //     compose: ['/feature3'],
            // },
        };
        plansRef.set(data, { merge: true });
    }

    // private addExcludeLabels(user: any) {
    //     const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    //     const data: any = {
    //         excludedLabels: ['CATEGORY_UPDATES', 'CATEGORY_PROMOTIONS', 'CATEGORY_SOCIAL', 'CATEGORY_FORUMS'],
    //         includedLabels: ['SAMPLE'],
    //         serverExcludedLabels: [''],
    //     };
    //     return userRef.set(data, { merge: true });
    // }

    // #endregion -------- Create/Update user data/doc -------------------------------------------------

    // #region -------- Linking sign-in providers -----------------------------------

    public async linkGmailSignIn() {
        const provider = new firebase.auth.GoogleAuthProvider();
        const user = firebase.auth().currentUser;
        if (user) {
            user.linkWithPopup(provider).then((result) => {
                // alert('Gmail account linked successfully');
            })
                .catch((error) => {
                    console.error('Gmail account linking failed', error)
                    // alert('Gmail account linking failed');
                });
        }
    }

    public async linkMicrosoftSignIn() {
        const provider = new firebase.auth.OAuthProvider('microsoft.com');
        const user = firebase.auth().currentUser;
        if (user) {
            user.linkWithPopup(provider).then((result) => {
                // alert('Microsoft account linked successfully');
            })
                .catch((error) => {
                    console.error('Microsoft account linking failed', error)
                    // alert('Microsoft account linking failed');
                });
        }
    }

    // #endregion ----------------- Linking sign-in providers -----------------------------------

    // #region -------- Role-based Authorization    -----------------------------------

    // public canRead(user: User): boolean {
    //     const allowedRoles = ['admin', 'editor', 'subscriber'];
    //     return this.checkAuthorization(user, allowedRoles);
    // }

    // public canEdit(user: User): boolean {
    //     const allowedRoles = ['admin', 'editor'];
    //     return this.checkAuthorization(user, allowedRoles);
    // }

    // public canDelete(user: User): boolean {
    //     const allowedRoles = ['admin'];
    //     return this.checkAuthorization(user, allowedRoles);
    // }

    // // determines if user has matching role
    // private checkAuthorization(user: User, allowedRoles: string[]): boolean {
    //     if (!user) {
    //         return false;
    //     }
    //     for (const role of allowedRoles) {
    //         if (user.roles && user.roles[role]) {
    //             return true;
    //         }
    //     }
    //     return false;
    // }

    // #endregion ----------------- Role-based Authorization -----------------------------------

    // #region -------- Feature-based Authorization -----------------------------------

    // canAccess(user: User, forFeature: string, route: string): boolean {
    //     const allowed = [forFeature]; // replace with all user features that is true
    //     // const routesAllowed = this.routesAllowed(user, forFeature);
    //     // return this.checkFeatureAuthorization(user, allowed, routesAllowed, route);
    //     return this.checkFeatureAuthorization(user, allowed, [], route);
    // }

    // // DEPRECATED
    // // private routesAllowed(user: User, forFeature: string): string[] {
    // //     if (!user) {
    // //         return [''];
    // //     }
    // //     return user.routes && user.routes[forFeature];
    // // }

    // private checkFeatureAuthorization(
    //     user: User,
    //     allowedFeatures: string[],
    //     routesAllowed: string[],
    //     route: string
    // ): boolean {
    //     if (!user) {
    //         return false;
    //     }
    //     for (const feature of allowedFeatures) {
    //         if (user.features[feature]) {
    //             for (const r of routesAllowed) {
    //                 if (r === route) {
    //                     return true;
    //                 }
    //             }
    //         }
    //     }
    //     return false;
    // }
}

// #endregion ----------- Feature-based Authorization --------------------------------------
