/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-input-rename */
import { DateSettings, ClientAbcTableDatum, DebtTranDatum, IQConfiguration, Organization, SettingsAppearance, ClientAbcGroupTableDatum, ClientAbcUserSettings } from '@newgenus/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import moment from 'moment';


@Component({
  selector: 'shared-client-abc',
  templateUrl: './client-abc.component.html',
  styleUrls: ['./client-abc.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ],
})
export class SharedClientAbcComponent implements AfterViewInit, OnDestroy, OnChanges {

  //#region Variables
  // Variables for this component that are within scope of "this" component.

  // Input/Outputs/Bindings.

  /**
   * The date for the data, this should always be the end of the month selected.
   */
  @Input()
  public dateForData: Date | null = new Date();

  @Input()
  public gatewayConfig: Partial<IQConfiguration> | null | undefined = null;

  @Input()
  public dateProfile: DateSettings | null = null;

  @Input()
  public organization: Organization | null = null;

  @Input()
  public filterValue: string | null = '';

  @Input()
  public selectedRep: number | null = null;

  @Input()
  public showFooter = true;

  @Input()
  public tableData: ClientAbcGroupTableDatum[] | ClientAbcTableDatum[] | null = [];

  @Input()
  public isClientAbcGroup = false;

  @Input()
  public isWidget = false;

  @Input()
  public settings: ClientAbcUserSettings | null = null;

  @Input()
  public tooltips: Record<string, string> = {
    'rank': 'Rank based on average sales',
    'account': 'Account number',
    'customer': 'Customer name',
    'sales average': 'Average sales for the past 6 months',
    'current month -5': 'Sales for 5 months ago',
    'current month -4': 'Sales for 4 months ago',
    'current month -3': 'Sales for 3 months ago',
    'current month -2': 'Sales for 2 months ago',
    'current month -1': 'Sales for 1 month ago',
    'current month': 'Sales for the current month',
  };

  // Table variables.
  @Input()
  public tableColumns: string[] | null = ['rank', 'account', 'customer', 'sales average', 'current month -2', 'current month -1', 'current month'];

  // Data variables.
  @Input()
  public past6MonthsIncludingCurrentMonth: moment.Moment[] = [];

  @Input()
  public dataForCurrentMonth: null | DebtTranDatum[] = [];

  @Output()
  public expandRow = new EventEmitter<{ isExpanded: boolean, row: any }>();

  @Output()
  public editGroup = new EventEmitter<ClientAbcGroupTableDatum>();

  @Output()
  public deleteGroup = new EventEmitter<ClientAbcGroupTableDatum>();

  @ViewChild(MatPaginator)
  public paginator!: MatPaginator;

  @ViewChild(MatSort)
  public sort!: MatSort;

  public embeddedTableColumns: string[] | null = ['rank', 'account', 'customer', 'sales average', 'current month -5', 'current month -4', 'current month -3', 'current month -2', 'current month -1', 'current month'];

  public columnsToDisplay: string[] = this.tableColumns?.slice() || [];
  public columnsToDisplayExtended = [...this.columnsToDisplay, 'expand'];

  /**
   * Used in HTML ref only.
   * This is the selected row to which is expanded.
   */
  public expandedElement: any; // ClientAbcGroupTableDatum | ClientAbcTableDatum | null = null;

  public dataSource = new MatTableDataSource<ClientAbcTableDatum | ClientAbcGroupTableDatum>();
  public footerData = {
    monthTarget: 0,
    weekTarget: 0,
    dayTarget: 0,
    clientMonthAverage: 0,
    monthAverageDifference: 0,
    monthAverage: 0,
    previousMonthSalesDifference: 0,
    previousMonthSalesDifferencePercentage: 0,
    salesPreviousMonth: 0,
    salesThisMonth: 0,
    salesThisWeek: 0,
    salesToday: 0
  };

  // Graph variables.
  public containerProps = { width: 0, height: 0 };

  // Subject variables.
  private destroy$ = new Subject<void>();

  //#endregion

  //#region Instantiation
  // Setup\fetches\initialization.

  //#endregion
  //#region User Interactions
  // Callbacks from html, anything the user can "interact" with from the view.

  public onDeleteGroup(group: ClientAbcGroupTableDatum) {
    if (window.confirm(`Are you sure you want to delete the group ${group['group name']}?`)) {
      this.deleteGroup.emit(group);
    }
  }

  public onEditGroup(group: ClientAbcGroupTableDatum) {
    this.editGroup.emit(group);
  }

  public applyFilter(): void {
    if (this.filterValue) {
      this.dataSource.filter = this.filterValue.trim().toLowerCase();
    } else {
      this.dataSource.filter = '';
    }

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public onChangeSort(event: any): void {
    // Doesn't do anything at the mo...
  }

  public onExpandToggle(row: any, isExpanded: boolean) {
    this.expandRow.emit({ isExpanded, row });
  }

  //#endregion
  //#region Events and Domain Functions
  // $watches/$on events, functions which don't fall into any of the other regions,
  // including any function that has to do domain functionality.

  public onContainerResize(event?: any): void {
    setTimeout(() => { // Process on "next tick".
      const container = document.getElementById('client-abc-graph-container');

      if (container) {
        const width = container.clientWidth;

        const newHeight = width < 300 ? 380 : width + 50;
        const newWidth = width < 300 ? 300 : width;
        container.style.width = (newWidth + 'px');
        container.style.height = (newHeight + 'px');

        if (this.containerProps.width !== newWidth || this.containerProps.height !== newHeight)
          this.containerProps = { width: newWidth, height: newHeight };
      }
    });
  }

  public delayedContainerUpdate(): void {
    this.containerProps = { width: 1, height: 1 };
    setTimeout(() => { this.onContainerResize(); });
  }

  //#endregion
  //#region Data Access & Subscriptions
  // API interactions, NGRX selects, etc.

  private setSoldTodayAndThisWeek() {
    if (!this.dataForCurrentMonth) return;

    // TODO use 'settings'?
    const filteredData = this.dataForCurrentMonth;
    const todayStart = moment().startOf('day').toDate();
    const todayEnd = moment().endOf('day').toDate();

    const soldToday: any[] = [];
    this.footerData.salesToday = 0;
    this.footerData.salesToday = filteredData.reduce((acc, debtTran) => {
      if (debtTran.date.getTime() >= todayStart.getTime() && debtTran.date.getTime() <= todayEnd.getTime()) {
        soldToday.push(debtTran);
        return debtTran.dc === 'C'
          ? (this.settings?.appearance === SettingsAppearance.gross ? acc - debtTran.amount + debtTran.creditTax : acc - debtTran.amount)
          : (this.settings?.appearance === SettingsAppearance.nett ? acc + debtTran.amount - debtTran.debitTax : acc + debtTran.amount);
      } else {
        return acc;
      }
    }, 0);

    const weekStart = moment().startOf('week').toDate();
    const weekEnd = moment().endOf('week').toDate();
    const soldThisWeek: any[] = [];
    this.footerData.salesThisWeek = 0;
    this.footerData.salesThisWeek = filteredData.reduce((acc, debtTran) => {
      if (debtTran.date.getTime() >= weekStart.getTime() && debtTran.date.getTime() <= weekEnd.getTime()) {
        soldThisWeek.push(debtTran);
        return debtTran.dc === 'C' ? acc - debtTran.amount : acc + debtTran.amount;
      } else {
        return acc;
      }
    }, 0);

    // let range = { monthStart: moment().startOf('month'), monthEnd: moment().endOf('month') };
    // if (this.dateProfile) {
    //   range = getCompanyMonthStartAndEnd(new Date(), this.dateProfile)
    // }

    // const monthStart = range.monthStart.toDate().getTime();
    // const monthEnd = range.monthEnd.toDate().getTime();
    // const soldThisMonth: any[] = [];
    this.footerData.salesThisMonth = 0;

    // if (this.isClientAbcGroup) {
    if (this.tableData) this.footerData.salesThisMonth = (!this.tableData || !Array.isArray(this.tableData)) ? 0 : (this.tableData as any[])?.reduce((acc, group) => {
      return group['current month'] > 0 ? acc + group['current month'] : acc - group['current month'];
    }, 0);

    // } else {
    //   this.footerData.salesThisMonth = filteredData.reduce((acc, debtTran) => {
    //     if (debtTran.date.getTime() >= monthStart && debtTran.date.getTime() <= monthEnd) {
    //       soldThisMonth.push(debtTran);
    //       return debtTran.dc === 'C' ? acc - debtTran.amount : acc + debtTran.amount;
    //     } else {
    //       return acc;
    //     }
    //   }, 0);
    // }

  }

  //#endregion
  //#region Setters, Updaters and Preloaders
  // Setter functions, and methods called from the Instantiation region.

  private updateFooterData() {
    // TODO all the values here for ClientABCGroups must be calculated differently. They must all be normalized to gross or nett.

    this.footerData = {
      monthTarget: 0,
      weekTarget: 0,
      dayTarget: 0,
      clientMonthAverage: 0,
      monthAverageDifference: 0,
      monthAverage: 0,
      salesPreviousMonth: 0,
      previousMonthSalesDifference: 0,
      previousMonthSalesDifferencePercentage: 0,
      salesThisMonth: 0,
      salesThisWeek: 0,
      salesToday: 0
    };

    this.setSoldTodayAndThisWeek();

    // Set the target details in the footer.
    if (this.isClientAbcGroup || this.organization === null || !this.gatewayConfig || this.selectedRep === null) {
      // console.error('isClientAbcGroup is true, or Organization, integrationKey or rep is undefined, cannot calculate footer target data.');
    } else {

      const orgUserOfRep = this.selectedRep !== undefined && this.gatewayConfig.userMapping ? this.gatewayConfig.userMapping[this.selectedRep] : undefined;

      if (orgUserOfRep !== undefined && orgUserOfRep.orgUserKey !== null && orgUserOfRep.orgUserKey !== undefined) {

        const orgUser = this.organization.orgUsers[orgUserOfRep.orgUserKey];
        if (this.gatewayConfig?.key && orgUser?.targets && orgUser.targets[this.gatewayConfig.key]) {

          this.footerData.monthTarget = orgUser?.targets[this.gatewayConfig.key].monthlyTarget;
          this.footerData.weekTarget = orgUser?.targets[this.gatewayConfig.key].weeklyTarget;
          this.footerData.dayTarget = orgUser?.targets[this.gatewayConfig.key].dailyTarget;
        }
      }

    }

    // Set the sales details in the footer.
    this.footerData.clientMonthAverage = this.dataSource.data.reduce((acc, curr) => {
      return acc + curr['sales average'];
    }, 0) / this.dataSource.data.length;
    this.footerData.salesPreviousMonth = this.dataSource.data.reduce((acc, curr) => {
      return acc + curr['current month -1'];
    }, 0);
    // this.footerData.salesThisMonth = this.dataSource.data.reduce((acc, curr) => {
    //   return acc + curr['current month'];
    // }, 0);
    this.footerData.monthAverage = this.dataSource.data.reduce((acc, curr) => {
      return acc
        + curr['current month']
        + curr['current month -1']
        + curr['current month -2']
        + curr['current month -3']
        + curr['current month -4']
        + curr['current month -5']
    }, 0) / 6;

    this.footerData.previousMonthSalesDifference = this.footerData.salesThisMonth - this.footerData.salesPreviousMonth;

    // calculate the percentage difference between the current month and the previous month sales.
    this.footerData.previousMonthSalesDifferencePercentage = this.footerData.salesPreviousMonth === 0 ? 0 : ((this.footerData.salesThisMonth - this.footerData.salesPreviousMonth) / this.footerData.salesPreviousMonth) * 100;
  }

  //#endregion
  //#region Getters and Filters
  // Getter functions and filter methods/functions.

  public isLoadingGroupSummary(): boolean {
    return this.expandedElement !== null && (this.expandedElement as ClientAbcGroupTableDatum).salesSummary === undefined;
  }

  public getGroupSummary(): any {
    return (this.expandedElement as ClientAbcGroupTableDatum).salesSummary;
  }

  //#endregion
  //#region Change Detection
  // Deep config comparisons, change log generation, hasChangesFn, etc.

  public ngOnChanges(changes: SimpleChanges): void {

    if (changes['filterValue'] && !changes['filterValue'].isFirstChange()) {
      setTimeout(() => { this.applyFilter(); });
    }

    // if (changes['tableColumns'] && !changes['tableColumns'].isFirstChange()) {
    if (changes['tableColumns']) {

      if (changes['tableColumns'].currentValue) {
        this.columnsToDisplay = changes['tableColumns'].currentValue;
        this.tableColumns = changes['tableColumns'].currentValue;
        this.columnsToDisplayExtended = [...this.columnsToDisplay, 'expand'];
      } else {
        this.columnsToDisplay = this.tableColumns?.slice() || [];
        this.columnsToDisplayExtended = [...this.columnsToDisplay, 'expand'];
      }
    }

    if (changes['dataForCurrentMonth'] && !changes['dataForCurrentMonth'].isFirstChange()) {
      setTimeout(() => {
        if (this.dataForCurrentMonth && this.dataForCurrentMonth.length > 0) {
          // Do nothing atm....
        } else {
          this.footerData.salesToday = 0;
          this.footerData.salesThisWeek = 0;
        }
      });
    }

    if (changes['tableData'] && !changes['tableData'].isFirstChange()) {
      setTimeout(() => {
        this.updateTableData();
      });
    }

    // Don't overwrite the tooltips, only update them.
    if (changes['tooltips']) {
      if (changes['tooltips'].previousValue) this.tooltips = changes['tooltips'].previousValue;

      if (changes['tooltips'].currentValue) {
        Object.entries(changes['tooltips'].currentValue as Record<string, string>).forEach(([key, value]) => {
          if (value) this.tooltips[key] = value;
        });
      }
    }

  }

  private updateTableData(): void {
    if (this.tableData) this.dataSource.data = this.tableData;
    this.updateFooterData();
  }

  //#endregion    
  //#region Parsing and Validation
  // Transformation/mapping methods
  // Methods returning true/false: isCurrent, areFeedsEqual, shouldProcess, etc.

  public parseGrossOrNett(str?: string): string {
    if (str) return str === SettingsAppearance.gross ? 'Inclusive of Tax' : 'Exclusive of Tax';

    return '';
  }

  //#endregion
  //#region Post Instantiation
  // Post-init fetch requests, used in such as landing page, after view init, etc.

  public ngAfterViewInit(): void {
    if (this.paginator) this.dataSource.paginator = this.paginator;
    if (this.sort) {
      this.dataSource.sort = this.sort;

      // Set the default sort on "next tick".
      setTimeout(() => { this.dataSource.sort?.sort({ id: 'sales average', start: 'desc', disableClear: false }); });
    };
  }

  //#endregion

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}