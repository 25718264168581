import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from '../reducers/user.reducers';
import * as fromUser from '../reducers/user.reducers';

export const selectUsersState = createFeatureSelector<UserState>('users');

export const selectUsers = createSelector(
    selectUsersState,
    fromUser.selectAll
);

export const selectUser = (id: string) => {
    return createSelector(
        selectUsers,
        user => user.filter(users => users.uid === id)
    );
};

export const isUserLoaded = createSelector(
    selectUsersState,
    state => {
        return state.allUsersLoaded;
    }
);
