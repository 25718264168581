/* eslint-disable no-restricted-syntax */
import { AttachmentProcess, ClientOption, DeleteProcess, DraftChangeParticle, DraftChangePend, DraftUpdate, FileUpload, Particle, ParticleContentBlocks, ParticleStatus, PendParticleResponse, SendFrom, deepCopy, encodeUtf8, firestoreTimestampNow, parseEmbeddedTimestampsToFirebaseDate } from '@newgenus/common';
import { Subject, BehaviorSubject, Observable, switchMap, of, takeUntil, debounceTime, Subscription, take, delay, merge, combineLatest, map, finalize } from 'rxjs';
import { particleDraftUpdate, particleSendAndFile, particleReadyToSend } from '../../redux/particles/particles.actions';
import { Injectable, OnDestroy, ElementRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ParticleService } from './particle.service';
import { AppState } from '../../redux/reducers';
import { Timestamp } from 'firebase/firestore';
import { FileService } from './files.service';
import { ApiService } from './api.service';
import firebase from 'firebase/compat/app';
import { Store } from '@ngrx/store';
import * as moment from 'moment';

interface DraftMeta {
    [particleKey: string]: {
        filesAreUploaded?: boolean;
        draftExists?: boolean;
        storeIsReady?: boolean;
        shouldSend?: boolean;
        labelId?: string;
        effectsCompleteLabelId?: string;
        draftExist?: boolean;
        otherParameters?: any;
        attachments?: string[];
        attachmentsUploaded?: string[];
        attachmentsFailed?: string[];
        sendingInProgress?: boolean;
    };
}

@Injectable({
    providedIn: 'root',
})
export class DraftService implements OnDestroy {
    //subscriptions
    // private particlesSubscription: Subscription;

    //objects
    public cachedParticle!: Particle;
    public cachedRecipients: string[] = [];

    //observables

    /**
     * A subject for pushing particle updates to the database.
     * The purpose of this is to allow debounce-time of updates and a single update channel of flow.
     * Changes pushed to this subject will update the draftMeta, draft on the firestore BE and NGRX DB.
     *
     * @memberof DraftService
     */
    public draftUpdate$ = new Subject<DraftChangePend | DraftChangeParticle>();

    /**
     * This subject is exactly as draftUpdate$, except it by-passes the debounce-time and executes the update immediately.
     *
     * @memberof DraftService
     */
    public draftImmediateUpdate$ = new Subject<DraftChangePend | DraftChangeParticle>();

    private draftProcessSubject$ = new Subject<DraftChangePend | DraftChangeParticle>();

    /**
     * A subject which can be subscribed to for a message indicating what's happening with the current draft.
     *
     * @memberof DraftService
     */
    public draftMessage$ = new Subject<string>();

    /**
     * A subject for publishing new messages to draftMessage$.
     *
     * @memberof DraftService
     */
    public draftMessageChange$ = new Subject<string>();

    private destroy$ = new Subject<void>();
    public instanceDestroy$ = new Subject<string>();

    //variables
    public currentFileUpload: any;
    public counter: any;
    private defaultMimeType = 'multipart/mixed';
    public busy = false;

    public draftSavingMetaData: DraftMeta = {};
    public draftParticleKey$ = new BehaviorSubject<string | null>(null);
    private draftParticlesSnapshotArray: Array<Record<string, Particle>> = [];
    public activeDraftParticleKey: string | null = null;
    public activeDrafts: any[] = [];
    public elementRef!: ElementRef;

    private initializeDraftMetric$: Observable<any>;
    // uid: any;

    constructor(
        private afs: AngularFirestore,
        private afAuth: AngularFireAuth,
        private fileService: FileService,
        private apiService: ApiService,
        private particleService: ParticleService,
        // private flashMessage: FlashMessagesService,
        // private parseToHtmlService: ParseToHtmlService,
        private store: Store<AppState>
    ) {
        this.subscribedToCachedRecipients();

        //current logged in user is ready
        this.initializeDraftMetric$ = this.afAuth.authState
            .pipe(switchMap(user => {
                if (user) {
                    const uid: string = user.uid;
                    return of(uid);
                } else {
                    return of(null);
                }
            }));

        this.initializeDraftMetric$.pipe(takeUntil(this.destroy$)).subscribe((uid) => {
            // this.uid = uid;
            this.deleteDraftMetrixOnFirestore(uid);
        });

        this.subscribeToDraftChangesCombineLatest();

        this.draftMessageChange$.pipe(debounceTime(1000)).subscribe((msg) => {
            this.draftMessage$.next(msg);

            if (msg === '') this.busy = false;
            // Self clear draft message - distinctUntilChanged will prevent a loop.
            else this.draftMessageChange$.next('');
        });

        this.draftProcessSubject$.subscribe((update) => {
            update = this.formatDraftParticle(update);

            switch (update.type) {
                case 'AUTO_SAVE':
                    this.autoSaveParticleDraft(update);
                    break;
                case 'PEND':
                    this.pendParticleDraft(update);
                    break;
                case 'SEND_PEND':
                    this.pendAndSendParticleDraft(update);
                    break;
                case 'SEND_FILE':
                    this.sendAndFileParticleDraft(update);
                    break;
                default:
                    console.debug("You\re trying to do something with the particle update that's not catered for...review the design or find where this log is and delete it.");
                    break;
            }
        });
    }

    public retrySendParticle(particle: Particle): Promise<boolean> {

        return this.apiService
            .retrySendParticle(particle)
            .then((resp) => {
                return true;
            })
            .catch((error) => {
                console.error('retrySendParticle > error:', error);
                return false;
            });
    }

    public createSubscriptionOfDraftUpdates(): Subscription {
        this.draftImmediateUpdate$.pipe(take(1), delay(10)).subscribe(() => {
            this.instanceDestroy$.next("");
        });

        return merge(
            this.draftImmediateUpdate$.asObservable(),
            this.draftUpdate$.asObservable().pipe(debounceTime(1000))
        )
            .pipe(takeUntil(this.instanceDestroy$))
            .subscribe(
                async (update) => {
                    this.busy = true;
                    this.draftProcessSubject$.next(update);

                    // Display 'Auto saved' in the editor if it's a change detection and saving is required. Otherwise, set the message to an empty string.
                    this.draftMessageChange$.next(update.type === DraftUpdate.AUTO_SAVE ? 'Auto saved' : '');
                },
                (ex) => {
                    console.error('createSubscriptionOfDraftUpdates > exception:', ex);
                }
            );
    }

    private formatDraftParticle(update: DraftChangePend | DraftChangeParticle): any {
        throw new Error('Method not implemented.');

        // switch (update.type) {
        //     case 'AUTO_SAVE':
        //     case 'SEND_FILE':
        //         update.particle = this.replaceLocalBlobUrlsWithContendIDInBlockDataHTML(update.particle);
        //         update.particle.content = update.shouldSend
        //             ? this.parseToHtmlService.parseOutputData(<any>update.particle.content)
        //             : JSON.stringify((<any>update.particle.content).blocks);
        //         break;
        //     case 'PEND':
        //     case 'SEND_PEND':
        //         update.pendArgs.particle = this.replaceLocalBlobUrlsWithContendIDInBlockDataHTML(
        //             update.pendArgs.particle
        //         );
        //         update.pendArgs.particle.content = update.shouldSend
        //             ? this.parseToHtmlService.parseOutputData(<any>update.pendArgs.particle.content)
        //             : JSON.stringify((<any>update.pendArgs.particle.content).blocks);
        //         break;
        // }
        // return update;
    }

    private autoSaveParticleDraft(update: DraftChangeParticle): void {
        this.draftSavingMetaData[update.particle.particleKey] ??= {};
        this.draftSavingMetaData[update.particle.particleKey].attachmentsUploaded ??= [];
        this.draftSavingMetaData[update.particle.particleKey].filesAreUploaded = false;
        this.draftSavingMetaData[update.particle.particleKey].labelId = 'DRAFT';
        this.draftSavingMetaData[update.particle.particleKey].draftExist = true;
        this.draftSavingMetaData[update.particle.particleKey].otherParameters = null;

        // only update relevant changes. in order to not override attachments and labels
        delete (<any>update).particle.labelIds;
        delete (<any>update).particle.attachments;
        delete (<any>update).particle.attachmentsMeta;

        this.store.dispatch(
            particleDraftUpdate({
                payload: update.particle as Particle,
            })
        );
    }

    private sendAndFileParticleDraft(update: DraftChangeParticle) {
        this.fileService.sendingInProgress = true;

        try {
            // TODO: create toast and wait 5 seconds before sending particle.
            // Toast should have "undo" button to cancel sending.
            // This might be great if we move the sending to the BE.
            // -
            // We can then leverage this 5 second delay to allow the store and BE to fully update and
            // should it be canceled, it would cancel from the BE side (consider network latency for the cancel operation)

            const labelsToADD = ['special', 'DONE'];
            const labelsToREMOVE = ['DRAFT', 'INBASKET'];
            const filedDate = Timestamp.fromDate(new Date());
            const particle = update.particle;
            particle.filedDate = filedDate;

            let labelIdsArray = particle.labelIds;
            labelIdsArray = this.particleService.removeBasketLabels(labelIdsArray, labelsToREMOVE);
            const newArray = labelIdsArray.concat(labelsToADD);
            labelIdsArray = newArray;

            const hasAttachments = (this.draftSavingMetaData[particle.particleKey]['attachments'] || []).length > 0;
            if (!hasAttachments) {
                this.draftSavingMetaData[particle.particleKey]['filesAreUploaded'] = true;
                this.draftSavingMetaData[particle.particleKey]['attachmentsUploaded'] = [];
            } else {
                this.draftSavingMetaData[update.particle.particleKey].filesAreUploaded = false;
            }
            this.draftSavingMetaData[particle.particleKey].effectsCompleteLabelId = 'DONE';

            this.updateDraftParticleLastSnapshot(particle);
            particle.labelIds = labelIdsArray;

            this.store.dispatch(
                particleSendAndFile({
                    payload: {
                        ...(particle as Particle),
                        id: particle.particleKey,
                    },

                    // payload: {
                    //     labelIds: labelIdsArray,
                    //     filedDate: filedDate,
                    //     content: update.particle.content as string,
                    //     particleType: update.particle.particleType,
                    //     // id: selectedParticle.particleKey,
                    //     id: update.particle.particleKey,
                    // },
                })
            );

            // The updating of the `draftService.draftSavingMetaData` for sending a particle has been moved to the ParticleEffects.
            // Original Comment: move this to side effect - sending the email draft particle
        } catch (ex) {
            console.error('sendAndFileParticle error: ', ex);
        }
    }

    private pendAndSendParticleDraft(update: DraftChangePend) {
        this.fileService.sendingInProgress = true;
        const particle = update.pendArgs.particle;

        this.updateDraftParticleLastSnapshot(particle);

        // TODO: create toast and wait 5 seconds before sending particle.
        // Toast should have "undo" button to cancel sending.
        // This might be great if we move the sending to the BE.
        // -
        // We can then leverage this 5 second delay to allow the store and BE to fully update and
        // should it be canceled, it would cancel from the BE side (consider network latency for the cancel operation)

        const hasAttachments = (this.draftSavingMetaData[particle.particleKey]['attachments'] || []).length > 0;
        if (!hasAttachments) {
            this.draftSavingMetaData[particle.particleKey]['filesAreUploaded'] = true;
            this.draftSavingMetaData[particle.particleKey]['attachmentsUploaded'] = [];
        }
        this.draftSavingMetaData[particle.particleKey].effectsCompleteLabelId = 'PENDING';
        this.draftParticleKey$.next(particle.particleKey);

        this.particleService.pendAndSendParticleViaStore(<PendParticleResponse>update.pendArgs, 'DRAFT');
    }

    private pendParticleDraft(update: DraftChangePend) {
        const particle = update.pendArgs.particle;

        const hasAttachments = (this.draftSavingMetaData[particle.particleKey]['attachments'] || []).length > 0;
        if (!hasAttachments) {
            this.draftSavingMetaData[particle.particleKey]['filesAreUploaded'] = true;
            this.draftSavingMetaData[particle.particleKey]['attachmentsUploaded'] = [];
        }
        this.draftSavingMetaData[particle.particleKey].effectsCompleteLabelId = 'PENDING';
        this.draftSavingMetaData[particle.particleKey].shouldSend = false;
        this.draftSavingMetaData[particle.particleKey].sendingInProgress = false;
        this.draftSavingMetaData[particle.particleKey].labelId = 'PENDING';
        this.draftSavingMetaData[particle.particleKey].draftExist = true;

        this.particleService.pendParticleViaStore(<PendParticleResponse>update.pendArgs);
        this.draftParticleKey$.next(particle.particleKey);
    }

    private replaceLocalBlobUrlsWithContendIDInBlockDataHTML(particle: ParticleContentBlocks): ParticleContentBlocks {
        particle = parseEmbeddedTimestampsToFirebaseDate(deepCopy(particle));

        if (!particle.attachmentsMeta) return particle;
        throw new Error('Method not implemented.');
        // particle.content?.blocks?.forEach((block) => {
        //     if (block.type === 'html') {
        //         let html: Document | null = new DOMParser().parseFromString(block.data.html, 'text/html');
        //         const images = [...(html as any).images];
        //         if (images.length > 0) {
        //             images.map((is) => {
        //                 if ((is.src + '').startsWith('blob:')) {
        //                     is.src = 'cid:' + is.id;
        //                 }
        //                 return is;
        //             });

        //             for (let i = 0; i < images.length; i++) {
        //                 // if (html.images.item(i).id === images[i].id) html.images.item(i).src = images[i].src;

        //                 const itemAtIdx = html.images.item(i);
        //                 if (itemAtIdx && itemAtIdx.id === images[i].id) {
        //                     itemAtIdx.src = images[i].src;
        //                     html.images[i] = itemAtIdx;
        //                 }
        //             }

        //             block.data.html = html.children[0].innerHTML;

        //             html = null;
        //         }
        //     }
        // });

        return particle;
    }

    private subscribeToDraftChangesCombineLatest() {
        combineLatest(
            this.fileService.deletedAttachmentsOb$,
            this.fileService.attachmentInProgress$,
            this.draftParticleKey$
        )
            .pipe(takeUntil(this.destroy$))
            .subscribe(async (combinedValues) => {
                //here we kinda need to know what observable was fired

                const deleteAttachmentInstructionObj = combinedValues[0];
                const fileAttachmentStatus = combinedValues[1];
                const particleKey = combinedValues[2];

                // files uploading / done uploading
                if (
                    fileAttachmentStatus !== null &&
                    fileAttachmentStatus.attachmentProgress === AttachmentProcess.startedAndEnded &&
                    fileAttachmentStatus.saved === false
                ) {
                    // upload progress done
                    this.busy = false;

                    const draftParticlesSnapshotArrayIndex = this.draftParticlesSnapshotArray.findIndex(
                        (x) => x[fileAttachmentStatus.particleKey]
                    );
                    const draftParticleSnapshot = this.draftParticlesSnapshotArray[draftParticlesSnapshotArrayIndex];
                    const doneUploadingAttachmentsIndex = fileAttachmentStatus.doneUploadingAttachments.findIndex(
                        (x) => x[fileAttachmentStatus.particleKey]
                    );
                    const filesUploadedArray =
                        fileAttachmentStatus.doneUploadingAttachments[doneUploadingAttachmentsIndex];
                    const completedUploadsForParticle = (
                        fileAttachmentStatus.doneUploadingAttachments as Array<any>
                    ).find((x) => x[fileAttachmentStatus.particleKey])[fileAttachmentStatus.particleKey];

                    draftParticleSnapshot[fileAttachmentStatus.particleKey].attachments = completedUploadsForParticle;

                    // Maybe not needed
                    this.updateAttachmentsLastSnapshot(fileAttachmentStatus.particleKey, filesUploadedArray);

                    if (fileAttachmentStatus.uploaded) {
                        this.draftSavingMetaData[fileAttachmentStatus.particleKey].attachmentsUploaded ??= [];
                        if (!this.draftSavingMetaData[fileAttachmentStatus.particleKey].attachmentsUploaded?.includes(fileAttachmentStatus.uploaded)) {
                            this.draftSavingMetaData[fileAttachmentStatus.particleKey].attachmentsUploaded?.push(fileAttachmentStatus.uploaded);
                        }
                    }
                }

                // delete instruction running
                if (
                    deleteAttachmentInstructionObj !== null &&
                    fileAttachmentStatus === null &&
                    deleteAttachmentInstructionObj.saved === false
                ) {
                    //save to backend and send if instructed

                    // check particle keys match
                    // check that particleKey does exists in uploadedStatusObj - to be deleted
                    const particleKeyOfDel = deleteAttachmentInstructionObj.particleKey;
                    const filename = deleteAttachmentInstructionObj.filename;

                    const particleSnapShotIndex = this.draftParticlesSnapshotArray.findIndex(
                        (x) => x[particleKeyOfDel]
                    );

                    const currentParticleSnapShot =
                        this.draftParticlesSnapshotArray[particleSnapShotIndex][particleKeyOfDel];

                    const location = currentParticleSnapShot.attachmentsMeta[filename].location;

                    // TODO: this snapshot update doesn't work. The particleSnapshot carries the old version of `attachments`.
                    await this.deleteAttachmentsFromLastSnapshot(particleKeyOfDel, filename);

                    const particleSnapShot = this.draftParticlesSnapshotArray[particleSnapShotIndex][particleKeyOfDel];

                    // // remove it from firebase storage if applicable & particle

                    const updatedParticle = {
                        particleKey: particleSnapShot.particleKey,
                        uid: particleSnapShot.uid,
                        attachments: particleSnapShot.attachments,
                        attachmentsMeta: particleSnapShot.attachmentsMeta,
                    };
                    this.particleService.updateParticleViaStore(updatedParticle);

                    if (location === 'storage') {
                        this.fileService.removeFilesFromStorage(filename, particleKeyOfDel);
                    }
                    deleteAttachmentInstructionObj.saved = true;
                    deleteAttachmentInstructionObj.deleteProgress = DeleteProcess.startedAndEnded;

                    if (this.draftSavingMetaData[particleKeyOfDel].attachments
                        && (this.draftSavingMetaData[particleKeyOfDel]?.attachments?.length ?? 0) > 0
                        && (this.draftSavingMetaData[particleKeyOfDel].attachments?.indexOf(filename) ?? -1) > -1) {

                        this.draftSavingMetaData[particleKeyOfDel].attachments?.splice((<string[]>this.draftSavingMetaData[particleKeyOfDel].attachments).indexOf(filename), 1);
                    }

                    if (this.draftSavingMetaData && this.draftSavingMetaData[particleKeyOfDel])

                        this.fileService.deletedAttachmentsOb$.next(null);
                }

                // note here
                if (particleKey !== null && this.draftSavingMetaData[particleKey])
                    if (particleKey && this.draftSavingMetaData[particleKey].filesAreUploaded == false) {
                        const attachmentsUploaded = this.draftSavingMetaData[particleKey].attachmentsUploaded;
                        const attachments = this.draftSavingMetaData[particleKey].attachments;

                        if (attachments !== undefined && attachments.length === attachmentsUploaded?.length) {
                            const draftParticlesSnapshotArrayIndex = this.draftParticlesSnapshotArray.findIndex(
                                (x) => x[particleKey]
                            );

                            if (draftParticlesSnapshotArrayIndex > -1) {
                                const draftParticleSnapshotCopy = {
                                    ...this.draftParticlesSnapshotArray[draftParticlesSnapshotArrayIndex][particleKey],
                                };

                                if (draftParticleSnapshotCopy.attachments) {
                                    attachments.forEach((c) => {
                                        if (!draftParticleSnapshotCopy.attachments.includes(c)) {
                                            draftParticleSnapshotCopy.attachments.push(c);
                                        }
                                    });
                                } else {
                                    draftParticleSnapshotCopy.attachments = attachments;
                                }

                                delete (<any>draftParticleSnapshotCopy).labelIds;


                                // If the particle is sending, we don't need to update it against because the next dispatch will update it.
                                this.particleService.updateDraftParticleAttachments(draftParticleSnapshotCopy);
                            }
                        }
                    }

                // all good to send. all streams are null. shouldSend = true. use particleKey to send particle
                if (deleteAttachmentInstructionObj === null && fileAttachmentStatus === null && particleKey !== null) {
                    const storeIsReady = this.draftSavingMetaData[particleKey].storeIsReady;
                    const filesAreUploaded = this.draftSavingMetaData[particleKey].filesAreUploaded;
                    const shouldSend = this.draftSavingMetaData[particleKey].shouldSend;

                    if (shouldSend && storeIsReady && filesAreUploaded) {

                        const particleSnapShotIndex = this.draftParticlesSnapshotArray.findIndex((x) => x[particleKey]);
                        const particleSnapShot = this.draftParticlesSnapshotArray[particleSnapShotIndex][particleKey];

                        // We need to move the sending of emails to the backend - instead of here.
                        // We set a flag on the particle that the particle is ready to send (offline use) and the backend picks it up and sends it.

                        // grab a copy of the store version to send
                        // this.store.pipe(select(selectParticle(particleKey)))
                        //     .pipe(take(1))
                        //     .subscribe(async (particle) => {
                        //         const selectedParticle = deepCopy(particle[0]);
                        //         if (selectedParticle.storeIsReady) {

                        //             await this.saveDraftChanges(
                        //                 selectedParticle,
                        //                 shouldSend,
                        //                 labelId,
                        //                 otherParameters,
                        //                 draftExists,
                        //                 null
                        //             ).then(async (result) => { });
                        //             this.draftParticleKey$.next(null);
                        //         }
                        //     });

                        this.insertNewCachedRecipients(particleSnapShot); // TODO we shouldn't need particle snapshot anymore...maybe this could be moved to autoSave above?

                        const payload: Partial<Particle> = {
                            particleKey: particleKey,
                            status: ParticleStatus.SCHEDULED_TO_SEND,
                            sendMeta: {
                                sentDate: firestoreTimestampNow(),
                            },
                        };

                        // Emit that the FE is done uploading files etc and the particle can be sent.
                        this.store.dispatch(
                            particleReadyToSend({
                                payload: payload,
                            })
                        );
                    }
                }
            });
    }

    public updateDraftParticleLastSnapshot(snapshot: any) {
        this.activeDraftParticleKey = snapshot.particleKey;
        this.draftParticlesSnapshotArray = this.updateDraftSnapShots(this.draftParticlesSnapshotArray, snapshot);
    }

    private updateDraftSnapShots(draftsSnapShotArray: any, currentDraftSnapshot: any): Array<Record<string, Particle>> {
        let newArray: Array<Record<string, Particle>>;
        const particleKey = currentDraftSnapshot.particleKey;

        //check if particle exist on currentAttachmentUploading
        const particleIndex = draftsSnapShotArray.findIndex((x: { [x: string]: any; }) => x[particleKey]);

        if (particleIndex === -1) {
            // create for the current snapshot
            newArray = [...draftsSnapShotArray];
            newArray.push({ [particleKey]: currentDraftSnapshot });
        } else {
            // update a new entry for the current snapshot
            newArray = [...draftsSnapShotArray];
            newArray[particleIndex] = { [particleKey]: currentDraftSnapshot };
        }

        return newArray;
    }

    public updateAttachmentsLastSnapshot(particleKey: any, filenames: { [particleKey: string]: string[]; }) {
        this.activeDraftParticleKey = particleKey;
        this.draftParticlesSnapshotArray = this.updateAttachmentsSnapShots(
            this.draftParticlesSnapshotArray,
            particleKey,
            filenames
        );
    }

    private updateAttachmentsSnapShots(
        draftsSnapShotArray: any,
        particleKey: any,
        filenames: any
    ): Array<Record<string, Particle>> {
        let newArray: Array<Record<string, Particle>> = [];

        //check if particle exist on currentAttachmentUploading
        const particleIndex = draftsSnapShotArray.findIndex((x: { [x: string]: any; }) => x[particleKey]);
        const currentDraftSnapshot = draftsSnapShotArray[particleIndex];

        if (particleIndex === -1) {
            console.error(
                'no draftParticlesSnapshotArray instance for particle :',
                particleKey,
                ' found to update with attachments'
            );
        } else {
            // update a new entry for the current snapshot
            // merge the attachments arrays
            const filesAlreadyUploaded = [{ [particleKey]: currentDraftSnapshot[particleKey].attachments }];

            const doneAttachments = [filenames];

            // NOTE - add the attachmentMeta here
            const attachmentMeta: any = {};
            for (const key of doneAttachments[0][particleKey]) {
                // eslint-disable-next-line no-prototype-builtins
                if (currentDraftSnapshot[particleKey].attachmentsMeta.hasOwnProperty(key)) {
                    // ?
                } else {
                    attachmentMeta[key] = {
                        location: 'storage',
                        filename: key,
                    };
                }
            }

            const x = this.fileService.mergeFilesUploadedArraysForParticle(
                filesAlreadyUploaded,
                doneAttachments,
                particleKey
            );
            currentDraftSnapshot[particleKey].attachments = x[0];
            const oldAttachmentMeta = currentDraftSnapshot[particleKey].attachmentsMeta;
            currentDraftSnapshot[particleKey].attachmentsMeta = {
                ...oldAttachmentMeta,
                ...attachmentMeta,
            };
            newArray = [...draftsSnapShotArray];
            newArray[particleIndex] = {
                [particleKey]: currentDraftSnapshot[particleKey],
            };
        }

        return newArray;
    }

    public deleteAttachmentsFromLastSnapshot(particleKey: string, filenameToDelete: string): void {
        this.activeDraftParticleKey = particleKey;
        this.draftParticlesSnapshotArray = this.deleteAttachmentsSnapShots(
            this.draftParticlesSnapshotArray,
            particleKey,
            filenameToDelete
        );
    }

    private deleteAttachmentsSnapShots(
        draftsSnapShotArray: any,
        particleKey: any,
        filenameToDelete: string
    ): Array<Record<string, Particle>> {
        let newParticlesArray: Array<Record<string, Particle>> = [];

        //check if particle exist on currentAttachmentUploading
        const particleIndex = draftsSnapShotArray.findIndex((x: { [x: string]: any; }) => x[particleKey]);

        if (particleIndex === -1) {
            console.error(
                'no draftParticlesSnapshotArray instance for particle :',
                particleKey,
                ' found to update with attachments'
            );
        } else {
            const currentDraftSnapshot = JSON.parse(JSON.stringify(draftsSnapShotArray[particleIndex]));

            // remove filename from the current snapshot
            const array = currentDraftSnapshot[particleKey].attachments;
            const currentAttachmentsMeta = currentDraftSnapshot[particleKey].attachmentsMeta;

            const attachmentToDeleteIndex = array.indexOf(filenameToDelete);
            array.splice(attachmentToDeleteIndex, 1);

            delete currentAttachmentsMeta[filenameToDelete];
            currentDraftSnapshot[particleKey].attachments = array;
            currentDraftSnapshot[particleKey].attachmentsMeta = currentAttachmentsMeta;

            newParticlesArray = [...draftsSnapShotArray];
            newParticlesArray[particleIndex] = {
                [particleKey]: currentDraftSnapshot[particleKey],
            };
        }

        return newParticlesArray;
    }

    public updateDraftMetrix(changes: any, typeOfUpdate: string) {
        if (typeOfUpdate === 'snapshot') {
            this.addSnapshotToDraftMetrixOnFirestore(
                changes.uid,
                changes.activeDraftParticleKey,
                changes.draftParticlesSnapshotArray
            );
        }

        if (typeOfUpdate === 'fileuploads') {
            this.addFileUploadsToDraftMetrixOnFirestore(
                changes.uid,
                changes.activeDraftParticleKey,
                changes.draftParticleFileUploads,
                changes.doneUploadingAttachments
            );
        }
    }

    private addFileUploadsToDraftMetrixOnFirestore(
        uid: string,
        activeDraftParticleKey: any,
        listOfDraftLastSnapshot: any,
        doneUploadingAttachments: any
    ) {
        const listOfDraftFilesUploaded = [];
        const obj: any = {};

        obj['particleKey'] = activeDraftParticleKey;
        obj['fileNames'] = doneUploadingAttachments;
        listOfDraftFilesUploaded.push(obj);

        this.afs.collection('draftmetrix').doc(uid).set(listOfDraftFilesUploaded, { merge: true }).then().catch();
    }

    private addSnapshotToDraftMetrixOnFirestore(
        uid: string,
        activeDraftParticleKey: any,
        listOfDraftLastSnapshot: any
    ) {
        const draftParticleObject: any = {};
        const arrayOfDraftParticles = [];

        for (const x of listOfDraftLastSnapshot) {
            for (const [, value] of Object.entries(x)) {
                arrayOfDraftParticles.push(value);
            }
        }
        // set up array to write to firestore
        // remove the type of Particle
        const stringifyListOfSnapshots = JSON.stringify(arrayOfDraftParticles);
        const parsedListOfSnapshots = JSON.parse(stringifyListOfSnapshots);

        draftParticleObject['activeDraftParticleKey'] = activeDraftParticleKey;
        draftParticleObject['listOfDraftLastSnapshot'] = parsedListOfSnapshots;
        this.afs.collection('draftmetrix').doc(uid).set(draftParticleObject, { merge: true }).then().catch();
    }

    private deleteDraftMetrixOnFirestore(uid: any) {
        if (uid) this.afs.collection('draftmetrix').doc(uid).delete().then().catch();
    }

    private subscribedToCachedRecipients() {
        this.particleService.userCachedRecipient$.pipe(takeUntil(this.destroy$)).subscribe((cachedRecipients) => {
            if (cachedRecipients) {
                cachedRecipients.forEach((recipient: any) => this.cachedRecipients.push(recipient.emailAddress));
            }
        });
    }

    public subscribeToSend() {
        // ?
    }

    // --- Service Functions --- //

    // change 2 - file upload change
    public uploadOfFileAttachments(
        selectedFiles: any,
        addToComponentBodyCallBack: (filename: string, particleKey: string) => void,
        particleKey: string
    ): void {
        const fileNames = [];

        for (let i = 0; i < selectedFiles.length; i++) {
            const file = selectedFiles.item(i);
            fileNames.push(file.name);

            this.currentFileUpload = new FileUpload(file);
            this.fileService.pushParticleAttachmentsToStorage(file, particleKey); // fix it here is the problem...
            addToComponentBodyCallBack(file.name, particleKey);
        }
        // monitor upload - all filenames = 100 %
    }

    // --- Draft BackEnd functions --- //

    // called by the 3 different kind of changes - body changes, file uploads, file removals
    // private async saveDraftChanges(
    //     draftParticle: Particle,
    //     send: boolean,
    //     labelId: string,
    //     otherParameter: any,
    //     draftExist: boolean,
    //     callback: any
    // ) {
    //     return await this.postDraftParticleToBackEnd(draftParticle, send, labelId, otherParameter, callback);
    // }

    // private postDraftParticleToBackEndExecutor(
    //     draftParticle: Particle,
    //     shouldSend: boolean,
    //     labelId: string,
    //     otherParameters: any,
    //     callback: any
    // ) {
    //     return new Promise(async (resolve: any, reject: any) => {
    //         const idToken = await firebase.auth().currentUser.getIdToken();

    //         this.apiService
    //             .sendNewDraftAndAttachmentsWithUserToken(idToken, draftParticle, shouldSend) // return await this.apiService.sendNewDraftWithUserToken(idToken, draftParticle, shouldSend)
    //             .toPromise()
    //             .then(async (response) => {
    //                 if (response) {
    //                     if (shouldSend) {
    //                         this.flashMessage.show(' Message Sent ', {
    //                             cssClass: 'flash-message',
    //                             timeout: 4000,
    //                         });
    //                     }

    //                     resolve(response);

    //                     if (callback) {
    //                         callback();
    //                     }

    //                     const currentParticleKey = response.particleKey
    //                         ? response.particleKey
    //                         : draftParticle.particleKey;

    //                     if (labelId === 'DONE') {
    //                         this.findParticleThatNeedsUpdateInDb(currentParticleKey).then(
    //                             async (particleToUpdate: any) => {
    //                                 const particleWithGmailApiResponse: any =
    //                                     this.particleService.addResponseToParticle(
    //                                         response,
    //                                         shouldSend,
    //                                         particleToUpdate,
    //                                         true,
    //                                         currentParticleKey
    //                                     );

    //                                 this.insertNewCachedRecipients(particleWithGmailApiResponse);

    //                                 if (this.particlesSubscription) {
    //                                     this.particlesSubscription.unsubscribe();
    //                                 }
    //                                 this.fileService.sendingInProgress = false;
    //                             }
    //                         );
    //                     } else if (labelId === 'PENDING') {
    //                         this.findParticleThatNeedsUpdateInDb(currentParticleKey).then((particleToUpdate: any) => {
    //                             const particleWithGmailApiResponse: any = this.particleService.addResponseToParticle(
    //                                 response,
    //                                 shouldSend,
    //                                 particleToUpdate,
    //                                 true,
    //                                 currentParticleKey
    //                             );
    //                             this.insertNewCachedRecipients(particleWithGmailApiResponse);
    //                             localStorage.removeItem('particleKey');

    //                             if (this.particlesSubscription) {
    //                                 this.particlesSubscription.unsubscribe();
    //                             }

    //                             this.fileService.sendingInProgress = false;
    //                         });
    //                     } else if (labelId === 'DRAFT') {
    //                         this.fileService.sendingInProgress = false;
    //                     }
    //                 } else {
    //                     throw new Error('particle did not receive a response with a valid response id');
    //                 }
    //             })
    //             .catch((err) => {
    //                 console.error('some error occurred while sending...', err);
    //                 this.fileService.sendingInProgress = false;
    //             });
    //     });
    // }

    // private async postDraftParticleToBackEnd(
    //     draftParticle: Particle,
    //     shouldSend: boolean,
    //     labelId: string,
    //     otherParameters: any,
    //     callback: { (): void; (): void; (): void }
    // ) {
    //     this.fileService.sendingInProgress = true;

    //     const result = await this.postDraftParticleToBackEndExecutor(
    //         draftParticle,
    //         shouldSend,
    //         labelId,
    //         otherParameters,
    //         callback
    //     );
    //     return result;
    // }

    // --- Utility functions--- //

    private insertNewCachedRecipients(particle: Particle) {
        if (particle) {
            const recipientsTo = particle.to ? particle.to : [];
            const recipientsCC = particle.cc ? particle.cc : [];
            // const particleRecipients = [].concat(recipientsTo.concat(recipientsCC));
            const particleRecipients = recipientsTo.concat(recipientsCC);

            if (particleRecipients.length > 0) {
                const recipientsToCache = this.removeRecipientsAlreadyCached(particleRecipients, this.cachedRecipients);

                recipientsToCache.forEach((recipientEmail) => {
                    this.particleService.insertNewCachedRecipient(recipientEmail, particle.uid)
                        .then(() => {
                            // ... ?
                        })
                        .catch((err) => {
                            console.error('Failed to cache recipient:', err);
                        });
                });
            }
        }
    }

    private removeRecipientsAlreadyCached(particleRecipients: string[], cachedRecipients: string[]) {
        return particleRecipients.filter((recipient) => {
            return cachedRecipients.indexOf(recipient) === -1;
        });
    }


    private findParticle(particles: any, particleKey: string) {
        return particleKey !== '' && particles ? particles.find((p: any) => p.particleKey === particleKey) : null;
    }

    public findParticleInObservable(particleKey: string) {
        return new Promise<Particle>((resolve: any) => {
            const subscription = this.particleService.userParticles$
                .pipe(
                    map((particles: Particle[]) => {
                        const particle: Particle = particles.find((p) => p.particleKey === particleKey) as Particle;
                        if (particle) {
                            resolve(particle);
                            subscription.unsubscribe();
                        } else {
                            resolve(null);
                        }
                    }),
                    finalize(() => {
                        // ?
                    })
                )
                .pipe(takeUntil(this.destroy$))
                .subscribe();
        });
    }

    public createNewDraftParticleInFirestore(sendFrom: SendFrom) {
        const newParticle = this.createNewSkeletonParticle(sendFrom);

        return this.particleService.createParticle(newParticle);
    }

    public createNewDraftReplyParticleInFirestore(
        existingParticle: Particle,
        sendFrom: SendFrom,
        clientOptions: Array<ClientOption>
    ) {
        const newParticle = this.createNewSkeletonReplyFromExistingParticle(existingParticle, sendFrom, clientOptions);

        return this.particleService.createParticle(newParticle);
    }

    public createNewDraftReplyAllParticleInFirestore(
        existingParticle: Particle,
        sendFrom: SendFrom,
        clientOptions: Array<ClientOption>
    ) {
        const newParticle = this.createNewSkeletonReplyAllFromExistingParticle(
            existingParticle,
            sendFrom,
            clientOptions
        );

        return this.particleService.createParticle(newParticle);
    }

    public createNewDraftForwardParticleInFirestore(
        existingParticle: Particle,
        filesMap: Record<string, any>,
        sendFrom: SendFrom
    ) {
        const newParticle = this.createNewSkeletonForwardFromExistingParticle(existingParticle, filesMap, sendFrom);

        return this.particleService.createParticle(newParticle);
    }

    // -- initialization  -- //

    /**
     * Create a new Blank Draft Particle.
     *
     * @param {SendFrom} sendFrom This is the "sendFrom" email address, it delegates what linked
     * email this new particle draft belongs to.
     */
    public createNewSkeletonParticle(sendFrom: SendFrom) {
        const uid = firebase.auth().currentUser?.uid as string;
        const newParticle = new Particle();
        newParticle.toBlankDraft(uid, sendFrom.emailKey, this.defaultMimeType);

        return newParticle;
    }

    /**
     * Create a new reply draft particle.
     *
     * @param {Particle} existingParticle The existing email particle.
     * @param {SendFrom} sendFrom This is the "sendFrom" email address, it delegates what linked
     * email this new particle draft belongs to.
     */
    public createNewSkeletonReplyFromExistingParticle(
        existingParticle: Particle,
        sendFrom: SendFrom,
        clientOptions: Array<ClientOption>
    ) {
        const uid = firebase.auth().currentUser?.uid as string;
        const content = this.appendReplyAnnotationToContent(existingParticle);
        const newParticle = new Particle();
        newParticle.toDraftReply(uid, sendFrom, this.defaultMimeType, content, existingParticle, clientOptions);

        return newParticle;
    }

    /**
     * Create a new reply all draft particle.
     *
     * @param {Particle} existingParticle The existing email particle.
     * @param {SendFrom} sendFrom This is the "sendFrom" email address, it delegates what linked
     * email this new particle draft belongs to.
     */
    public createNewSkeletonReplyAllFromExistingParticle(
        existingParticle: Particle,
        sendFrom: SendFrom,
        clientOptions: Array<ClientOption>
    ) {
        const uid = firebase.auth().currentUser?.uid as string;
        const content = this.appendReplyAnnotationToContent(existingParticle);
        const newParticle = new Particle();
        newParticle.toDraftReplyAll(uid, sendFrom, this.defaultMimeType, content, existingParticle, clientOptions);

        return newParticle;
    }

    /**
     * Create a new forward draft particle.
     *
     * @param {Particle} existingParticle The existing email particle.
     * @param {Record<string, any>} filesMap A map of files to be forwarded with the email.
     * @param {SendFrom} sendFrom This is the "sendFrom" email address, it delegates what linked
     * email this new particle draft belongs to.
     */
    public createNewSkeletonForwardFromExistingParticle(
        existingParticle: Particle,
        filesMap: Record<string, any>,
        sendFrom: SendFrom
    ) {
        const uid = firebase.auth().currentUser?.uid as string;
        const content = this.appendForwardAnnotationToContent(existingParticle);
        const newParticle = new Particle();
        newParticle.toDraftForward(uid, sendFrom, this.defaultMimeType, content, existingParticle, filesMap);
        this.fetchMessageAndAttachAndUploadFiles(uid, sendFrom.emailKey, existingParticle);

        return newParticle;
    }

    fetchMessageAndAttachAndUploadFiles(uid: string, email: string, existingParticle: any) {
        // ?
    }

    private appendReplyAnnotationToContent(existingParticle: Particle): string {
        const humanReadableDate = moment(existingParticle.internalDate.toDate()).format('LLLL');
        let replyAnnotation = `<p>On ${humanReadableDate} ${existingParticle.from} wrote :</p>`;
        const index = this.findStartOfTemplate(existingParticle.content);
        if (existingParticle.particleType === 'gmail') replyAnnotation = encodeUtf8(replyAnnotation);

        return this.addStr(existingParticle.content, index, replyAnnotation);
    }

    private appendForwardAnnotationToContent(existingParticle: Particle): string {
        const humanReadableDate = moment(existingParticle.internalDate.toDate()).format('LLLL');
        const to =
            existingParticle.to != void 0 && existingParticle.to.length > 0
                ? `<br>To: ${this.parseNamedRecipientListWithEmailToAnchorTag(existingParticle.to)}`
                : '';
        const cc =
            existingParticle.cc != void 0 && existingParticle.cc.length > 0
                ? `<br>CC: ${this.parseNamedRecipientListWithEmailToAnchorTag(existingParticle.cc)}`
                : '';

        let forwardAnnotation = `<div dir=\\"ltr\\" class=\\"gmail_attr\\">
    ---------- Forwarded message ---------
    <br>From: <strong class=\\"gmail_sendername\\" dir=\\"auto\\">${existingParticle.from.substring(
            0,
            existingParticle.from.indexOf('<')
        )}</strong> ${this.parseNamedRecipientListWithEmailToAnchorTag([existingParticle.from])}
    <br>Date: ${humanReadableDate}
    <br>Subject: ${existingParticle.subject}
    ${to}
    ${cc}
    <br>
    </div><br><p> </p>`;
        const index = this.findStartOfTemplate(existingParticle.content);
        if (existingParticle.particleType === 'gmail') forwardAnnotation = encodeUtf8(forwardAnnotation);

        return this.addStr(existingParticle.content, index, forwardAnnotation);
    }

    private parseNamedRecipientListWithEmailToAnchorTag(recipientList: string[]): string[] {
        if (recipientList == void 0 || recipientList.length === 0) {
            return [];
        }

        return recipientList.map((r) =>
            r.includes('<') ? this.toEmailWithChevrons(r) : this.toEmailWithoutChevrons(r)
        );
    }

    private toEmailWithChevrons(email: string): string {
        const emailName = email.substr(0, email.indexOf('<')),
            emailAddress = email.substr(email.indexOf('<') + 1, email.lastIndexOf('>'));

        return `${emailName} <span dir=\\"auto\\"> &lt;${this.wrapEmailInAnchorTag(emailAddress)}&gt; </span>`;
    }

    private toEmailWithoutChevrons(email: string): string {
        return `<span dir=\\"auto\\">${this.wrapEmailInAnchorTag(email)}</span>`;
    }

    private wrapEmailInAnchorTag(str: string): string {
        return `<a href=\\"mailto:${str}\\" target=\\"_blank\\">${str}</a>`;
    }

    private findStartOfTemplate(templateContent: string): number {
        if (templateContent == void 0 || templateContent.trim().length === 0) {
            return 0;
        }
        const firstFiveChar = templateContent.substr(0, 5);

        if (firstFiveChar.includes('html')) {
            const indexOfBodyTagOpen = templateContent.indexOf('<body');
            const indexOfBodyContentStart = templateContent.indexOf('>', indexOfBodyTagOpen) + 1;
            const wordsSectionIndex = templateContent.indexOf('WordSection', indexOfBodyContentStart);
            const templateInputIndex =
                wordsSectionIndex > -1 ? templateContent.indexOf('>', wordsSectionIndex) + 1 : +indexOfBodyTagOpen;
            const includesWindowsNewLineCharacters = templateContent.indexOf('\r\n', templateInputIndex) === 0;

            return templateInputIndex + (includesWindowsNewLineCharacters ? 4 : 0);
        } else if (firstFiveChar.includes('div')) {
            return templateContent.indexOf('>') + 1;
        } else {
            return 0;
        }
    }

    private addStr(str: string, index: number, stringToAdd: string): string {
        return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }
}
