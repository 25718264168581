/* eslint-disable no-case-declarations */
import { ClientAbcGroup, ClientAbcTransactionTypes, DefaultClientAbcSettings, DefaultDaily7RGraphTransactionTypes, DefaultMonthly7RGraphTransactionTypes, DisplayValueTyped, InstantiatedWidget, IqConfigurationStripped, RepDoc, StatisticsFilterCodes, User, WidgetSettingsDialogInputData, WidgetType, hasPermission } from '@newgenus/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSelectChange } from '@angular/material/select';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'shared-dashboard-widget-settings',
  templateUrl: './widget-settings-dialog.component.html',
  styleUrls: ['../dialog.styles.scss']
})
export class WidgetSettingsDialogComponent implements OnInit {

  //#region variables

  /**
   * Date profile options to filter by.
   * Used by:
   * - Daily 7R
   * - Monthly 7R
   * - Client ABC
   *
   * @type {DisplayValueTyped<string>[]}
   * @memberof WidgetSettingsDialogComponent
   */
  public dateProfileOptions: DisplayValueTyped<string>[] = [];

  /**
   * Integration options to filter by.
   * Used by:
   * - Daily 7R
   * - Monthly 7R
   * - Client ABC
   *
   * @type {DisplayValueTyped<string>[]}
   * @memberof WidgetSettingsDialogComponent
   */
  public integrationOptions: DisplayValueTyped<string>[] = [];

  /**
   * Reps of the Integration selected to filter by.
   * Used by:
   * - Daily 7R
   * - Monthly 7R
   * - Client ABC
   *
   * @type {DisplayValueTyped<string>[]}
   * @memberof WidgetSettingsDialogComponent
   */
  public repOptions: DisplayValueTyped<string>[] = [];

  /**
   * Sales codes to filter by.  
   * Used by:
   *  - Daily 7R
   *  - Monthly 7R
   *
   * @type {{ [type: string]: boolean }}
   * @memberof WidgetSettingsDialogComponent
   */
  public filterBySalesCodes: { [type: string]: boolean } = {}

  /**
   * Widget options to choose from.
   * Used by:
   * - Daily 7R
   * - Monthly 7R
   * - Client ABC Graph
   *
   * @type {DisplayValueTyped<WidgetType>[]}
   * @memberof WidgetSettingsDialogComponent
   */
  public widgetOptions: DisplayValueTyped<WidgetType>[] = [];

  /**
   * Column options to choose from.
   * Used by:
   * - Daily 7R
   * - Monthly 7R
   * - Client ABC Graph
   *
   * @type {DisplayValueTyped<number>[]}
   * @memberof WidgetSettingsDialogComponent
   */
  public columnOptions: DisplayValueTyped<number>[] = [];

  /**
   * Row options to choose from.
   * Used by:
   * - Daily 7R
   * - Monthly 7R
   * - Client ABC Graph
   *
   * @type {DisplayValueTyped<number>[]}
   * @memberof WidgetSettingsDialogComponent
   */
  public rowOptions: DisplayValueTyped<number>[] = [];

  public clientAbcTableColumns: DisplayValueTyped<string>[] = [
    { display: 'Account', value: 'account' },
    { display: 'Customer', value: 'customer' },
    { display: 'Rank', value: 'rank' },
    { display: 'Sales Average', value: 'sales average' },
    { display: 'Current Month -5', value: 'current month -5' },
    { display: 'Current Month -4', value: 'current month -4' },
    { display: 'Current Month -3', value: 'current month -3' },
    { display: 'Current Month -2', value: 'current month -2' },
    { display: 'Current Month -1', value: 'current month -1' },
    { display: 'Current Month', value: 'current month' },
  ];

  public clientAbcGroupTableColumns: DisplayValueTyped<string>[] = [
    { display: 'Group Name', value: 'group name' },
    { display: 'Rank', value: 'rank' },
    { display: 'Sales Average', value: 'sales average' },
    { display: 'Current Month -5', value: 'current month -5' },
    { display: 'Current Month -4', value: 'current month -4' },
    { display: 'Current Month -3', value: 'current month -3' },
    { display: 'Current Month -2', value: 'current month -2' },
    { display: 'Current Month -1', value: 'current month -1' },
    { display: 'Current Month', value: 'current month' },
  ];

  /**
   * Client ABC transaction types to filter from the table results.
   * Used by:
   * - Client ABC
   *
   * @type {DisplayValueTyped<ClientAbcTransactionTypes>[]}
   * @memberof WidgetSettingsDialogComponent
   */
  public clientAbcTransactionOptions: DisplayValueTyped<ClientAbcTransactionTypes>[] = [
    { display: 'Bank Charges', value: 'BC' },
    { display: 'Credit Notes', value: 'CN' },
    { display: 'Discounts', value: 'DS' },
    { display: 'Invoices', value: 'IN' },
    { display: 'Journal Credits', value: 'JC' },
    { display: 'Journal Debits', value: 'JD' },
    { display: 'Payments', value: 'PM' },
    { display: 'Rebates', value: 'RE' },
    { display: 'Refunds', value: 'RF' },
  ];

  /**
   * Filter by code options, these are the transactions to include in the graph.
   * Used by:
   * - Monthly 7R
   * 
   * Extended by:
   * @see dailyTransactionOptions.
   *
   * @type {DisplayValueTyped<StatisticsFilterCodes>[]}
   * @memberof WidgetSettingsDialogComponent
   */
  public monthlyTransactionOptions: DisplayValueTyped<StatisticsFilterCodes>[] = [
    { display: 'Bank Charges', value: 'debtTran-BC' },
    { display: 'Credit Notes', value: 'debtTran-CN' },
    { display: 'Discounts', value: 'debtTran-DS' },
    { display: 'Interest Charge', value: 'debtTran-IT' },
    { display: 'Invoices', value: 'debtTran-IN' },
    { display: 'Journal Credits', value: 'debtTran-JC' },
    { display: 'Journal Debits', value: 'debtTran-JD' },
    { display: 'Payments', value: 'debtTran-PM' },
    { display: 'Rebates', value: 'debtTran-RE' },
    { display: 'Refunds', value: 'debtTran-RF' },
  ];


  /**
   * Filter by code options, these are the same as the monthly filter by code but include the sales orders (monthly does not include sales orders).
   * Used by:
   * - Daily 7R
   *
   * @type {DisplayValueTyped<StatisticsFilterCodes>[]}
   * @memberof WidgetSettingsDialogComponent
   */
  public dailyTransactionOptions: DisplayValueTyped<StatisticsFilterCodes>[] = [
    ...this.monthlyTransactionOptions,
    { display: 'Sales Orders (closed)', value: 'salesOrder-closed' },
    { display: 'Open Sales Orders', value: 'salesOrder-open' },
  ];

  /**
   * Show for options to choose from, this is based on the widget type selected.
   * Used by:
   * - Daily 7R
   * - Monthly 7R
   * - Client ABC
   *
   * @type {DisplayValueTyped<string>[]} 
   * @memberof WidgetSettingsDialogComponent
   */
  public showForOptions: DisplayValueTyped<string>[] = [];

  /**
   * Flag to determine if the multiple reps option is enabled.
   *
   * @memberof WidgetSettingsDialogComponent
   */
  public isMultipleRepsEnabled = false;

  /**
   * A control to allow toggling the show for company option.
   * This is only available for those who have company-wide permissions on a given feature.
   * Used by:
   * - Daily 7R
   * - Monthly 7R
   * - Client ABC
   *
   * @memberof AddWidgetDialogComponent
   */
  public showForCompanyControl = false;

  public form = this.fb.group({
    title: new FormControl('', Validators.required),
    type: new FormControl('', [Validators.required, Validators.maxLength(20)]),

    // ID from widget.
    id: new FormControl(),

    // Adjusted for the widget type.
    settings: this.fb.group<any>({
      forCompany: new FormControl(false),
      dateProfileKey: new FormControl('', Validators.required),
      integrationKey: new FormControl('', Validators.required),
      repKeys: new FormControl([], Validators.required),
      columns: new FormControl(2, Validators.required),
      rows: new FormControl(2, Validators.required),
    }),
    auth: this.fb.group<any>({
      // forReps: new FormControl([]),
      forFeature: new FormControl(''),
    })
  });

  public widgetName = '';
  private signedInUsersRepMapping: RepDoc | undefined;

  /**
   * Flag to determine if the initiation is complete.
   * This is used to prevent template errors while the form is being setup.
   *
   * @memberof WidgetSettingsDialogComponent
   */
  public initiationComplete = false;
  public isClientAbcGroup = false;
  private clientAbcGroupsOfConfig: ClientAbcGroup[] = [];
  public clientAbcGroupOptions: DisplayValueTyped<string>[] = [];

  //#endregion

  constructor(
    private fb: FormBuilder,
    private db: AngularFirestore,
    public dialogRef: MatDialogRef<WidgetSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WidgetSettingsDialogInputData) {

    // Create widget name incase the widget doesn't have a title.
    this.widgetName = this.determineWidgetName(data.widget.type);
  }

  //#region Instantiation
  // Setup\fetches\initialization.

  public ngOnInit(): void {
    this.initDialogFormFieldOptions();

    setTimeout(() => {
      this.setupForWidgetType(this.data.widget)
    });
  }

  /**
   * Initialize the options variables used to populate the form fields.
   *
   * @private
   * @memberof AddWidgetDialogComponent
   */
  private initDialogFormFieldOptions() {

    // Set Date profile options.
    if (this.data.organization) {
      // Set the date profile options from the organization date cycles.
      this.dateProfileOptions = this.data.organization.orgDateCycles.map(dp => {
        return { display: dp.profileName + ' (' + dp.year + ')', value: dp.key } as DisplayValueTyped<string>;
      })
        // Sort alphabetically.
        .sort((a, b) => a.display.localeCompare(b.display));

    } else { this.dateProfileOptions = []; }

    // Set Integration options.
    if (this.data.integrations) {
      // Set the integration options from the array of integrations passed in.
      this.integrationOptions = this.data.integrations.map(i => {
        return { display: i.nickName, value: i.key } as DisplayValueTyped<string>;
      })
        // Sort alphabetically.
        .sort((a, b) => a.display.localeCompare(b.display));

    } else { this.integrationOptions = []; }

    // Set column options.
    if (this.data.allColumnWidths)
      this.columnOptions = this.data.allColumnWidths.map(c => ({ display: c.toString(), value: c } as DisplayValueTyped<number>));
    else this.columnOptions = [
      { display: '2', value: 2 },
      { display: '4', value: 4 },
      { display: '6', value: 6 },
      { display: '8', value: 8 },
    ];

    // Set row options.
    if (this.data.allRowsWidths)
      this.rowOptions = this.data.allRowsWidths.map(r => ({ display: r.toString(), value: r } as DisplayValueTyped<number>));
    else this.rowOptions = [
      { display: '2', value: 2 },
      { display: '4', value: 4 },
      { display: '6', value: 6 },
      { display: '8', value: 8 },
    ];
  }

  //#endregion
  //#region User Interactions
  // Callbacks from html, anything the user can "interact" with from the view.

  /**
   * Callback for when the user selects an integration/gateway.
   * This will adjust the rep options to match the integration selected.
   * if the user has a rep mapping for the integration, that rep will be selected by default.
   *
   * @param {MatSelectChange} event The event from the mat select.
   * @memberof AddWidgetDialogComponent
   */
  public onSelectIntegration(event: MatSelectChange): void {
    const match = this.data.integrations?.find(i => i.key === event.value);

    // If there is no match, or no integrations, disable the rep field and clear the options.
    if (!this.data.integrations || !match) {
      this.repOptions = [];
      this.form.get('settings')?.get('repkeys')?.disable();
      // TODO if there are widgets in the future that don't require a rep, then the validation will need to permit it based on the widget type.
      return;
    }

    this.updateRepOptionsByWidgetAndFeaturePermission();
  }

  public submit(): any {
    const formValue = this.form.value;

    // Set repKeys to empty array if forCompany is true.
    if (formValue.settings && formValue.settings['forCompany']) {
      formValue.settings['repKeys'] = [];
      if (formValue.auth) formValue.auth['forReps'] = [];
    }

    // Convert repKeys to array if it is a string - ClientABC widget has a single rep key.
    if (formValue.settings?.['repKeys'] && typeof formValue.settings?.['repKeys'] === 'string') { formValue.settings['repKeys'] = [formValue.settings['repKeys']]; }

    if (formValue.settings && (formValue.type === 'clientAbcGroup' || formValue.type === 'clientAbcGroupList')) {
      delete formValue.settings['forCompany'];
      delete formValue.settings['repKeys'];

      // Convert groupKeys to array if it's not already.
      if (formValue.settings['groupKeys'] && !Array.isArray(formValue.settings['groupKeys'])) {
        formValue.settings['groupKeys'] = [formValue.settings['groupKeys']];
      }

      // Convert userKeys to array if it's not already.
      if (formValue.settings['userKeys']) {
        if (!Array.isArray(formValue.settings['userKeys'])) {
          formValue.settings['userKeys'] = [formValue.settings['userKeys']];
        }

        // Update auth to use userKeys instead of repKeys.
        (formValue as any).auth['userKeys'] = formValue.settings['userKeys'];
        delete (formValue as any).auth['forReps'];
      }
    }

    return formValue;
  }

  /**
   * Callback for when the user selects a rep.
   *
   * @param {MatSelectChange} $event The event from the mat select.
   * @memberof AddWidgetDialogComponent
   */
  public onSelectRep($event: MatSelectChange): void {
    // Update the form's forReps field.
    (this.form.controls['auth'] as any).controls['forReps'].setValue($event.value || []);
  }

  public onToggleForCompany(event: MatSlideToggleChange): void {

    // Disable the forReps control if the forCompany control is enabled.
    if (event.checked) {
      this.form.get('settings')?.get('repKeys')?.disable();
    } else {
      this.form.get('settings')?.get('repKeys')?.enable();
      this.form.get('settings')?.get('repKeys')?.markAsTouched();
      this.form.get('settings')?.get('repKeys')?.markAsDirty();
    }

    // If the user hasn't changed the title yet, append "Company" to the title.
    switch (this.form.get('type')?.value as WidgetType) {
      case 'daily7r':
        if (this.form.get('title')?.value === 'Daily 7R') this.form.get('title')?.setValue('Daily 7R Company');
        break;
      case 'monthly7r':
        if (this.form.get('title')?.value === 'Monthly 7R') this.form.get('title')?.setValue('Monthly 7R Company');
        break;
      case 'clientAbc':
        if (this.form.get('title')?.value === 'Client ABC') this.form.get('title')?.setValue('Client ABC Company');
        break;
      default:
        // Do nothing...
        break;
    }
  }

  public onSelectClientAbcUser(event: MatSelectChange): void {

    // Change the group options to match the selected user.
    this.clientAbcGroupOptions = this.clientAbcGroupsOfConfig
      .filter(group => group.userKey === event.value)
      .map(group => ({ display: group.title, value: group.key } as DisplayValueTyped<string>))
      .sort((a, b) => a.display.localeCompare(b.display));

    // Is single value.
    if (this.form.controls['type'].value === 'clientAbcGroup') {
      // Set the default group to the first option if there is one.
      if (this.clientAbcGroupOptions.length > 0)
        this.form.controls['settings'].get('groupKeys')?.setValue(this.clientAbcGroupOptions[0].value);
      else this.form.controls['settings'].get('groupKeys')?.setValue(null);

    } else { // Is array value.
      // Set the default group to the first option if there is one.
      if (this.clientAbcGroupOptions.length > 0)
        this.form.controls['settings'].get('groupKeys')?.setValue([this.clientAbcGroupOptions[0].value]);
      else this.form.controls['settings'].get('groupKeys')?.setValue([]);
    }

  }

  public onToggleForAllGroups(event: MatSlideToggleChange): void {

    if (event.checked) {
      this.form.get('settings')?.get('groupKeys')?.disable();
    } else {
      this.form.get('settings')?.get('groupKeys')?.enable();
      this.form.get('settings')?.get('groupKeys')?.markAsTouched();
      this.form.get('settings')?.get('groupKeys')?.markAsDirty();
    }

  }

  public onSelectClientAbcGroup(event: MatSelectChange) {
    // Update title to match the selected group if this is a single client abc group.
    if (this.form.controls['type'].value === 'clientAbcGroup'
      && (this.form.controls['title'].value === 'Client ABC Group'
        || this.clientAbcGroupsOfConfig.some(g => this.form.controls['title'].value?.includes(g.title) && this.form.controls['title'].value?.includes('(Group)')))) {
      this.form.controls['title'].setValue(event.source.triggerValue + ' (Group)');

      // Confirm the length of the new title won't be too long.
      if (event.source.triggerValue.length > 12 && event.source.triggerValue.length <= 20) {
        // Only the group title.
        this.form.controls['title'].setValue(event.source.triggerValue);
      } else if (event.source.triggerValue.length <= 12) {
        // Group title + indicator.
        this.form.controls['title'].setValue(event.source.triggerValue + ' (Group)');
      } else if (event.source.triggerValue.length > 20) {
        // Trimmed group title.
        this.form.controls['title'].setValue(event.source.triggerValue.slice(0, 16) + '...');
      }
    }
  }

  //#endregion
  //#region Events and Domain Functions
  // $watches/$on events, functions which don't fall into any of the other regions,
  // including any function that has to do domain functionality.

  private updateRepOptionsByWidgetAndFeaturePermission(): void {
    const currentIntegrationKey = this.form.get('settings')?.get('integrationKey')?.value;
    const integrationMatch = this.data.integrations?.find(i => i.key === currentIntegrationKey);
    const currentWidgetType = this.form.get('type')?.value;

    // Default to no rep options.
    this.repOptions = [];

    // If any of the required fields are missing, disable the rep field.
    if (!this.data.currentUser || !this.data.integrations || !integrationMatch || !currentWidgetType) {
      this.form.get('settings')?.get('repKeys')?.disable();
      // TODO if there are widgets in the future that don't require a rep, then the validation will need to permit it based on the widget type.
      return;
    }

    // If the current user has a rep number for the selected configuration, set it as the default rep selected.
    const uid = this.data.currentUser.uid;

    // Set the rep options based on the user's permissions for the features they have access to.
    const newRepOptions = Object.values(integrationMatch.userMapping || {})
      .map(mapping => ({ display: mapping.repName + ` (${mapping.repNum})`, value: mapping.repNum } as DisplayValueTyped<string>))
      // Sort alphabetically.
      .sort((a, b) => a.display.localeCompare(b.display));

    // Find the rep mapping for the signed in user if it exists.
    this.signedInUsersRepMapping = Object.values(integrationMatch.userMapping || {})
      .find((mapping) => mapping.orgUserKey === uid);

    switch (currentWidgetType) {

      case 'daily7r':
      case 'monthly7r':
        // Show all reps if have view-company-statistics permission, else, show only themselves.
        if (hasPermission(this.data.currentUser, 'statistics', 'view-company-statistics')) {
          // Show all reps.
          this.showForCompanyControl = true;
          this.repOptions = newRepOptions;
        } else if (hasPermission(this.data.currentUser, 'statistics', 'view-own-statistics')) {
          // Show only themselves.
          this.repOptions = newRepOptions.filter(rep => rep.value === this.signedInUsersRepMapping?.repNum);;
          this.showForCompanyControl = false;
        }
        break;

      // case 'clientAbcGraph':
      case 'clientAbc':
        this.showForCompanyControl = false;
        // Show all reps if have view-company-client-abc permission, else, show only themselves.
        if (hasPermission(this.data.currentUser, 'client-abc', 'view-company-client-abc')) {
          // Show all reps.
          this.repOptions = newRepOptions;
        } else if (hasPermission(this.data.currentUser, 'client-abc', 'view-own-client-abc')) {
          // Show only themselves.
          this.repOptions = newRepOptions.filter(rep => rep.value === this.signedInUsersRepMapping?.repNum);;
        }
        break;
      case 'clientAbcGroup':
      case 'clientAbcGroupList':
        this.showForCompanyControl = false;
        // Show all users if have create-company-custom-groups permission, else, show only themselves.
        if (hasPermission(this.data.currentUser, 'client-abc', 'create-company-custom-groups')) {
          // Show all users.
          this.data.organization?.orgUsers.userKeys.forEach(userKey => {
            const user = this.data.organization?.orgUsers[userKey];
            if (user) this.repOptions.push({ display: user.name, value: user.uid });
          });
        } else if (hasPermission(this.data.currentUser, 'client-abc', 'create-own-custom-groups')) {
          // Show only themselves.
          this.repOptions = [{ display: (this.data.currentUser?.firstName || '') + ' ' + (this.data.currentUser?.lastName || ''), value: this.data.currentUser?.uid || '' }];
        }
        break;
      default:
        this.showForCompanyControl = false;
        alert('Unknown widget type: ' + currentWidgetType);
        this.dialogRef.close(null);
        break;
    }

    if (this.repOptions.length === 0) {
      this.form.get('settings')?.get('repKeys')?.disable();
    } else {
      this.form.get('settings')?.get('repKeys')?.enable();
    }

    this.fetchAllClientAbcGroupsForConfig(this.data.currentUser, integrationMatch);
  }

  //#endregion
  //#region Data Access & Subscriptions
  // API interactions, NGRX selects, etc.


  // TODO: this should be updated to use NGRX.
  private fetchAllClientAbcGroupsForConfig(currentUser: User, config: IqConfigurationStripped) {
    // /gateway/--iq-enterprise--/public/JYAL9T3BvARbCUzscVHs/custom-client-abc-groups/1sQAJdm3pGMcD6ZCYllX
    this.db.collection('gateway').doc(`--${config.type}--`).collection('public').doc(config.key).collection('custom-client-abc-groups')
      .get()
      .subscribe((snapshot) => {

        snapshot.docs.forEach((doc) => {
          this.clientAbcGroupsOfConfig = snapshot.docs.map(d => d.data() as ClientAbcGroup);
        });

        this.setClientAbcGroupOptionsForUserForCurrentConfig(currentUser);
      });
  }

  private setClientAbcGroupOptionsForUserForCurrentConfig(user: User) {
    this.clientAbcGroupOptions = this.clientAbcGroupsOfConfig
      .filter(group => group.userKey === user.uid)
      .map(group => ({ display: group.title, value: group.key } as DisplayValueTyped<string>))
      .sort((a, b) => a.display.localeCompare(b.display));
  }


  //#endregion
  //#region Setters, Updaters and Preloaders
  // Setter functions, and methods called from the Instantiation region.


  public setupForWidgetType(widget: InstantiatedWidget<any, any>): void {

    // Patch the form with the basic widget details.
    this.form.patchValue({
      title: widget.title,
      id: widget.id,
      type: widget.type,
      auth: {
        forFeature: widget.auth.forFeature,
      }
    });

    // If the widget has forReps, set the repKeys control to the widget's repKeys.
    if (widget.auth.forReps) {
      this.form.controls['auth'].addControl('forReps', new FormControl(widget.auth.forReps));
    }

    // If the widget has userKeys, set the userKeys control to the widget's userKeys.
    if (widget.auth.userKeys) {
      this.form.controls['auth'].addControl('userKeys', new FormControl(widget.auth.userKeys));
    }

    // Patch the form with the widget's settings.
    this.form.controls['settings'].patchValue({
      forCompany: widget.settings?.forCompany !== undefined ? widget.settings.forCompany : false,
      columns: widget.settings.columns,
      rows: widget.settings.rows,
      dateProfileKey: widget.settings.dateProfileKey,
      integrationKey: widget.settings.integrationKey,
      repKeys: widget?.settings?.repKeys || null,
    });

    // Disable the repKeys field if the forCompany option is enabled.
    if (widget.settings && widget.settings.forCompany === true) {
      setTimeout(() => { this.form.get('settings')?.get('repKeys')?.disable(); });
    }

    // Assign new valuable equal to the previous value.
    const previousMultiSelectValue = this.isMultipleRepsEnabled === true;
    let newMultiSelectValue = this.isMultipleRepsEnabled === true;

    switch (widget.type) {
      case 'daily7r':

        // Options from type DailyShowFor
        this.showForOptions = [
          { display: 'Today', value: 'today' },
          { display: 'Yesterday', value: 'yesterday' },
          { display: 'Day Before Yesterday', value: 'dayBeforeYesterday' },
          { display: 'Previous Business Day', value: 'previousBusinessDay' },
          { display: 'Day Before Previous Business Day', value: 'dayBeforePreviousBusinessDay' },
        ];

        // Add filtersByCode control with default value.
        const transactionTypesIncluded = widget.settings.filtersByCode !== undefined ? widget.settings.filtersByCode : DefaultDaily7RGraphTransactionTypes;
        this.form.controls['settings'].addControl('filtersByCode', new FormControl(transactionTypesIncluded, Validators.compose([Validators.required, Validators.minLength(1)])));

        newMultiSelectValue = true;
        break;

      case 'monthly7r':

        // Options from type MonthlyShowFor
        this.showForOptions = [
          { display: 'This Month', value: 'thisMonth' },
          { display: 'Last Month', value: 'lastMonth' },
          { display: 'Month Before Last', value: 'monthBeforeLast' },
        ];

        // Add filtersByCode control with default value.
        const transactionTypesIncluded2 = widget.settings.filtersByCode !== undefined ? widget.settings.filtersByCode : DefaultMonthly7RGraphTransactionTypes;
        this.form.controls['settings'].addControl('filtersByCode', new FormControl(transactionTypesIncluded2, Validators.compose([Validators.required, Validators.minLength(1)])));

        newMultiSelectValue = true;
        break;

      case 'clientAbcGraph':

        // Options from type MonthlyShowFor
        this.showForOptions = [
          { display: 'This Month', value: 'thisMonth' },
          { display: 'Last Month', value: 'lastMonth' },
          { display: 'Month Before Last', value: 'monthBeforeLast' },
        ];

        newMultiSelectValue = false;
        break;

      case 'clientAbc':
      case 'clientAbcGroup':
      case 'clientAbcGroupList':

        // Options from type MonthlyShowFor
        this.showForOptions = [
          { display: 'This Month', value: 'thisMonth' },
          { display: 'Last Month', value: 'lastMonth' },
          { display: 'Month Before Last', value: 'monthBeforeLast' },
        ];

        // Add the showFor control with thisMonth as the default and disable it.
        this.form.controls['settings'].addControl('showFor', new FormControl(widget.settings.showFor, Validators.required));

        // Add tableColumns control with default columns.
        this.form.controls['settings'].addControl('tableColumns', new FormControl(widget.settings.tableColumns, Validators.compose([Validators.required, Validators.minLength(1)])));

        newMultiSelectValue = false;
        if (widget.type === 'clientAbcGroup' || widget.type === 'clientAbcGroupList') {
          // Flag to change controls in the html.
          this.isClientAbcGroup = true;

          const repKeysControl = this.form.controls['settings'].get('repKeys');
          if (repKeysControl) this.form.controls['settings'].removeControl<string>('repKeys' as never);

          // Add forAllGroups control with default value.
          this.form.controls['settings'].addControl('forAllGroups', new FormControl(widget.settings.forAllGroups || false, Validators.required));


          if (widget.type === 'clientAbcGroup') {

            // For Client ABC Group, userKeys is NOT an array.
            this.form.controls['settings'].addControl('userKeys', new FormControl(widget.settings.userKeys[0], Validators.required));
            this.form.controls['settings'].addControl('groupKeys', new FormControl(widget.settings.groupKeys[0], Validators.required));
          }
          else if (widget.type === 'clientAbcGroupList') {
            this.form.controls['settings'].addControl('groupKeys', new FormControl(widget.settings.groupKeys, Validators.required));

            this.form.controls['settings'].addControl('userKeys', new FormControl(widget.settings.userKeys[0], Validators.required));
            // For Client ABC Group LIST, userKeys IS an array.
            // this.form.controls['settings'].addControl('userKeys', new FormControl(new Array(...(widget.settings.userKeys || [])), Validators.required));

            // Client ABC Group List will allow listing of multiple reps/users. - later on...
            // this.isMultipleRepsEnabled = true;
          }

          // Disable Group Keys form field if forAllGroups is true.
          if (widget.settings.forAllGroups === true) {
            this.form.controls['settings'].controls['groupKeys'].disable();
          }

        } else {// Only for regular Client ABC.

          // Add transactionTypes control with default value.
          const transactionTypesIncluded = widget.settings.transactionTypes !== undefined ? widget.settings.transactionTypes : DefaultClientAbcSettings.transactionTypes;
          this.form.controls['settings'].addControl('transactionTypes', new FormControl(transactionTypesIncluded, Validators.compose([Validators.required, Validators.minLength(1)])));

          // Add dynamicGrouping control with default value.
          this.form.controls['settings'].addControl('dynamicGrouping', new FormControl(widget.settings.dynamicGrouping, Validators.required));

          // Add appearance control with default value.
          this.form.controls['settings'].addControl('appearance', new FormControl(widget.settings.appearance, Validators.required));

        }

        break;

      default:
        alert('Unknown widget type: ' + widget?.type);
        this.dialogRef.close(null);
        break;
    }

    // Update the multiple reps option if it has changed.
    if (newMultiSelectValue !== previousMultiSelectValue) {
      this.isMultipleRepsEnabled = newMultiSelectValue;
    }

    if (this.isMultipleRepsEnabled === false && widget.settings.repKeys && widget.settings.repKeys.length > 0) {
      this.form.get('settings')?.get('repKeys')?.setValue(widget.settings.repKeys[0]);
    }

    // Must be called only after the switch statement.
    this.updateRepOptionsByWidgetAndFeaturePermission();

    setTimeout(() => {
      this.initiationComplete = true;
    });
  }

  private determineWidgetName(type: string): string {
    switch (type) {
      case 'daily7r':
        return 'Daily 7R';
      case 'monthly7r':
        return 'Monthly 7R';
      case 'clientAbcGraph':
        return 'Client ABC Graph';
      case 'clientAbc':
        return 'Client ABC';
      default:
        return '';
    }
  }

  //#endregion
  //#region Getters and Filters
  // Getter functions and filter methods/functions.

  //#endregion
  //#region Change Detection
  // Deep config comparisons, change log generation, hasChangesFn, etc.

  //#endregion    
  //#region Parsing and Validation
  // Transformation/mapping methods
  // Methods returning true/false: isCurrent, areFeedsEqual, shouldProcess, etc.

  //#endregion
  //#region Response Handlers
  // API/NGRX callback handlers.

  //#endregion
  //#region Navigation
  // Confirmation close dialogs, router guards, etc.

  //#endregion
  //#region Post Instantiation
  // Post-init fetch requests, used in such as landing page, after view init, etc.

  //#endregion


}
