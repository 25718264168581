import { QuestionControlService } from './question-control.service';
import { Component, Input, OnInit } from '@angular/core';
import { QuestionBase } from './question-base';
import { FormGroup } from '@angular/forms';


@Component({
  selector: 'newgenus-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  providers: [QuestionControlService]
})
export class DynamicFormComponent implements OnInit {

  @Input()
  questions: QuestionBase<any>[] = [];
  form!: FormGroup;
  payLoad = '';

  constructor(private qcs: QuestionControlService) { };

  public ngOnInit(): void {
    this.form = this.qcs.toFormGroup(this.questions);
  }

  public onSubmit(): void {
    this.payLoad = JSON.stringify(this.form.value);
  }

}
