import { SearchableSelectComponent } from '../../searchable-select/searchable-select.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DisplayValue, ECONode, Organization } from '@newgenus/common';
import { TitleCasePipe, NgFor, NgIf } from '@angular/common';
import { Position } from '../services/tree-builder.service';
import { Component, inject, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MaterialModule } from '../../material.module';

@Component({
  selector: 'shared-org-security-groups-dialog',
  standalone: true,
  imports: [MaterialModule, SearchableSelectComponent, TitleCasePipe, NgFor, NgIf],
  template: `
  <h2 mat-dialog-title>Security Groups for {{data.node.data?.name}}

    <!-- Close button. -->
    <button mat-icon-button mat-dialog-close class="close-button">
      <mat-icon>close</mat-icon>
    </button>
  </h2>

  <mat-dialog-content class="h-100-70 w-100">
    
    <div class="w-100 px-3">
        <h3 class="pl-2 mb-2 p-2">Search and select security groups</h3>
        <shared-searchable-select 
          [classes]="['w-100']"
          [label]="'Search for security groups'"
          [data]="searchableData" 
          (selected)="onSelectSG($event)"
          [multiple]="true"
        ></shared-searchable-select>
    </div>
  
    <div class="view-container m-3 w-100">
      <div class="view-header">
          <span class="viewer-title-spacer">Security group changes</span>
      </div>

      <div class="view-content">
          <div class="info-quadrant">
            <section class="flex-row">
              <div class="bordered mb-3 w-100 p-3">
                <h3>Security Groups for {{data.node.data?.name}}</h3>
                <div *ngFor="let user of groupsOnHat" class="user-list-container">
                  
                  <span class="grouped">
                    <mat-icon>badge</mat-icon>
                    <span>{{user.display | titlecase}}</span>
                  </span>

                  <button mat-icon-button color="warn" (click)="onRemoveUserFromUsersOnHat(user.value)" >
                    <mat-icon>remove_circle</mat-icon>
                  </button>

                </div>
              </div>
            </section>

            <section class="flex-row">
              <div class="bordered mb-3 mx-3 w-100 p-3">
                <h3>Security Groups to assign</h3>
                <div *ngFor="let user of groupsToAssign" class="user-list-container">
                  
                  <span class="grouped">
                    <mat-icon>badge</mat-icon>
                    <span>{{user.display | titlecase}}</span>
                  </span>

                  <button mat-icon-button color="warn" (click)="onRemoveUserFromUsersToAssign(user.value)" >
                    <mat-icon>remove_circle</mat-icon>
                  </button>

                </div>
              </div>
            </section>

            <section class="flex-row">
              <div class="bordered mb-3 w-100 p-3">
                <h3>Security Groups to remove</h3>
                <div *ngFor="let user of groupsToRemove" class="user-list-container">
                  
                  <span class="grouped">
                    <mat-icon>badge</mat-icon>
                    <span>{{user.display | titlecase}}</span>
                  </span>

                  <button mat-icon-button color="primary" (click)="onRestoreUser(user.value)" >
                    <mat-icon>restore</mat-icon>
                  </button>

                </div>
              </div>
            </section>
          </div>
      </div>
    </div>
      
    <div class="view-container m-3 w-100">
      <div class="view-header">
          <span class="viewer-title-spacer">Final security groups on {{data.node.data?.name}}</span>
      </div>

      <div class="view-content">
        <div *ngFor="let user of finalSecurityGroupsOnHat" class="user-list-container">
              
          <span class="grouped">
            <mat-icon>badge</mat-icon>
            <span>{{user.display | titlecase}}</span>
          </span>

        </div>
        <div *ngIf="!finalSecurityGroupsOnHat">
          <p>No security groups assigned to this hat.</p>
        </div>
      </div>
    </div>
  
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close color="warn">Close</button>
    <button mat-stroked-button color="primary"><mat-icon>save</mat-icon> Save</button>
  </mat-dialog-actions>
`,
  styleUrls: [
    './org-dialog.scss',
    '../organization-board.component.scss'
  ]
})
export class OrgAssignSecurityGroupsDialogComponent {

  public selectedGroupKeys: string | string[] = [];
  public searchableData: DisplayValue[] = [];

  public groupsOnHat: DisplayValue[] = [];
  public groupsToAssign: DisplayValue[] = [];
  public groupsToRemove: DisplayValue[] = [];
  public finalSecurityGroupsOnHat: DisplayValue[] = [];

  public snackbar = inject(MatSnackBar);

  constructor(
    public dialogRef: MatDialogRef<OrgAssignSecurityGroupsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { node: ECONode<Position>, organization: Organization }
  ) { }


  public ngOnInit(): void {
    console.log('OrgAssignSecurityGroupsDialogComponent > ngOnInit > this.data: ', this.data);

    // Set searchable data to the org users.
    const groupKeys = this.data.organization.orgSecurityGroups?.securityGroupsKeys || [];
    console.log('OrgAssignSecurityGroupsDialogComponent > ngOnInit > groupKeys: ', groupKeys);
    this.searchableData = groupKeys.map(key => ({
      display: this.data.organization.orgSecurityGroups[key].name,
      value: key
    }));

    this.groupsOnHat = (this.data?.node?.data?.securityGroupsArray || [])
      .map(securityGroup => ({ display: securityGroup.name, value: securityGroup.key }));

      this.updateFinalResult();
  }

  public onSelectSG(selectedGroupKeys: string[]) {
    console.log('onSelectSG > $event: ', selectedGroupKeys);
    this.selectedGroupKeys = selectedGroupKeys;

    this.groupsToAssign = this.selectedGroupKeys.map(key => ({
      display: this.data.organization.orgSecurityGroups[key].name,
      value: key
    }));

    this.updateFinalResult();
  }

  /**
   * Removes user from the groupsOnHat, and adds an entry to the groupsToRemove array.
   */
  public onRemoveUserFromUsersOnHat(userKey: string) {
    console.log('onRemoveUserFromUsersOnHat > userKey: ', userKey);
    const userRemoved = this.groupsOnHat.find(user => user.value === userKey) as DisplayValue;
    this.groupsOnHat = this.groupsOnHat.filter(user => user.value !== userKey);
    this.groupsToRemove.push(userRemoved);

    this.updateFinalResult();
  }


  /**
   * Removes user from the groupsToAssign array.
   */
  public onRemoveUserFromUsersToAssign(userKey: string) {
    console.log('onRemoveUserFromUsersToAssign > userKey: ', userKey);
    this.groupsToAssign = this.groupsToAssign.filter(user => user.value !== userKey);

    this.updateFinalResult();
  }

  /**
   * Removes a user from the groupsToRemove array, and adds them back to the groupsOnHat array.
   */
  public onRestoreUser(userKey: string) {
    console.log('onRestoreUser > userKey: ', userKey);
    const userRestored = this.groupsToRemove.find(user => user.value === userKey) as DisplayValue;
    this.groupsToRemove = this.groupsToRemove.filter(user => user.value !== userKey);
    this.groupsOnHat.push(userRestored);

    this.updateFinalResult();
  }

  private updateFinalResult(): void {
    this.finalSecurityGroupsOnHat = this.groupsOnHat.concat(this.groupsToAssign);
  }

}
