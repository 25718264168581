import { IntermediateDaily7rData, IntermediateMonthly7rData, IntermediateClientAbcGraphData, WidgetType, IntermediateClientAbcData, IntermediateClientAbcGroupData, IntermediateClientAbcGroupListData } from '@newgenus/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';


@Component({
  selector: 'shared-dashboard-intermediate',
  templateUrl: './dashboard-intermediate.component.html',
  styleUrls: ['./dashboard-intermediate.component.scss'],
})
export class SharedDashboardIntermediateComponent implements OnInit, AfterViewInit, OnChanges {

  //#region Variables
  // Variables for this component that are within scope of "this" component.

  public id = 'intermediate-' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

  @Input()
  public type: WidgetType = '';

  @Input()
  public data: any = {};

  @Input()
  public refreshScale = false;

  /**
   * Emits when the to date changes.
   * Used for the 7r graphs.
   *
   * @memberof SharedDashboardIntermediateComponent
   */
  @Output()
  public fromDateChange = new EventEmitter<Date>();

  /**
   * Emits when the to date changes.
   * Used for the 7r graphs.
   *
   * @memberof SharedDashboardIntermediateComponent
   */
  @Output()
  public toDateChange = new EventEmitter<Date>();

  @Output()
  public clientAbcGroupExpand = new EventEmitter<{ isExpanded: boolean; row: any; }>();

  // @Output()
  // public clientAbcGroupDelete = new EventEmitter<ClientAbcGroupTableDatum>();

  // @Output()
  // public clientAbcGroupEdit = new EventEmitter<ClientAbcGroupTableDatum>();

  public daily7rData!: IntermediateDaily7rData;
  public monthly7rData!: IntermediateMonthly7rData;
  public clientAbcGraphData!: IntermediateClientAbcGraphData;
  public clientAbcData!: IntermediateClientAbcData;
  public clientAbcGroupData!: IntermediateClientAbcGroupData;
  public clientAbcGroupListData!: IntermediateClientAbcGroupListData;

  public containerProps = { width: 0, height: 0 };

  //#endregion


  //#region Instantiation
  // Setup\fetches\initialization.

  public ngOnInit(): void {
    // console.log(`IntermediateComponent.ngOnInit > ${this.type}`, this.data);

    switch (this.type) {
      case 'daily7r':
        this.daily7rData = this.data as IntermediateDaily7rData;
        break;
      case 'monthly7r':
        this.monthly7rData = this.data as IntermediateMonthly7rData;
        break;
      case 'clientAbcGraph':
        this.clientAbcGraphData = this.data as IntermediateClientAbcGraphData;
        break;
      case 'clientAbc':
        this.clientAbcData = this.data as IntermediateClientAbcData;
        break;
      case 'clientAbcGroup':
        this.clientAbcGroupData = this.data as IntermediateClientAbcGroupData;
        break;
      case 'clientAbcGroupList':
        this.clientAbcGroupListData = this.data as IntermediateClientAbcGroupListData;
        break;
      default:
        console.error('IntermediateComponent.ngOnInit > type not recognized', this.type);
        break;
    }

  }

  public ngAfterViewInit(): void {
    this.onContainerResize();
  }

  //#endregion
  //#region User Interactions
  // Callbacks from html, anything the user can "interact" with from the view.


  public onClientAbcGroupExpand($event: { isExpanded: boolean; row: any; }) {
    this.clientAbcGroupExpand.emit($event);
  }

  // public onClientAbcGroupDeleteGroup($event: ClientAbcGroupTableDatum) {
  //   this.clientAbcGroupDelete.emit($event);
  // }

  // public onClientAbcEditGroup($event: ClientAbcGroupTableDatum) {
  //   this.clientAbcGroupEdit.emit($event);
  // }

  //#endregion
  //#region Events and Domain Functions
  // $watches/$on events, functions which don't fall into any of the other regions,
  // including any function that has to do domain functionality.

  public onContainerResize(event?: any): void {
    setTimeout(() => { // Process on "next tick".
      console.log('intermediate > onContainerResize:', this.id);
      const container = document.getElementById(this.id);
      console.log('intermediate > onContainerResize > container:', container);

      if (container) {
        const width = container.clientWidth;

        // const newHeight = width < 300 ? 380 : width + 50;
        // const newWidth = width < 300 ? 300 : width;
        let newHeight: any = width + 50;
        let newWidth: any = width;

        // Graphs can just be auto width and height, with overflow hidden.
        if (this.type === 'daily7r' || this.type === 'monthly7r') { 
          newHeight = 'auto';
          newWidth = 'auto';
          container.style.width = 'auto';
          container.style.height = 'auto';
          container.style.overflow = 'hidden';
        } else {
          container.style.width = (newWidth + 'px');
          container.style.height = (newHeight + 'px');
        }

        if (this.containerProps.width !== newWidth || this.containerProps.height !== newHeight) {
          this.containerProps = { width: newWidth, height: newHeight };
        }
        console.log('intermediate > onContainerResize > containerProps:', this.containerProps);
      }
    });
  }

  //#endregion
  //#region Data Access & Subscriptions
  // API interactions, NGRX selects, etc.

  //#endregion
  //#region Setters, Updaters and Preloaders
  // Setter functions, and methods called from the Instantiation region.

  //#endregion
  //#region Getters and Filters
  // Getter functions and filter methods/functions.

  //#endregion
  //#region Change Detection
  // Deep config comparisons, change log generation, hasChangesFn, etc.


  public ngOnChanges(changes: SimpleChanges): void {
    // console.log(`ngOnChanges > ${this.type} > changes:`, changes);
    // this.onContainerResize();

    if (changes['data'] && !changes['data'].isFirstChange()) {
      switch (this.type) {
        case 'daily7r':
          this.daily7rData = this.data as IntermediateDaily7rData;
          break;
        case 'monthly7r':
          this.monthly7rData = this.data as IntermediateMonthly7rData;
          break;
        case 'clientAbcGraph':
          this.clientAbcGraphData = this.data as IntermediateClientAbcGraphData;
          break;
        case 'clientAbc':
          this.clientAbcData = this.data as IntermediateClientAbcData;
          break;
        case 'clientAbcGroup':
          this.clientAbcGroupData = this.data as IntermediateClientAbcGroupData;
          break;
        case 'clientAbcGroupList':
          this.clientAbcGroupListData = this.data as IntermediateClientAbcGroupListData;
          break;
        default:
          console.error('IntermediateComponent.ngOnChanges > type not recognized', this.type);
      }
    }

    if (changes['refreshScale']) {
      setTimeout(() => {
        this.onContainerResize();
      });
    }
  }

  //#endregion    
  //#region Parsing and Validation
  // Transformation/mapping methods
  // Methods returning true/false: isCurrent, areFeedsEqual, shouldProcess, etc.

  //#endregion
  //#region Response Handlers
  // API/NGRX callback handlers.

  //#endregion
  //#region Navigation
  // Confirmation close dialogs, router guards, etc.

  //#endregion
  //#region Post Instantiation
  // Post-init fetch requests, used in such as landing page, after view init, etc.

  //#endregion

}
