/* eslint-disable no-case-declarations */
import { AddWidgetDialogInputData, ClientAbcDynamicGroups, ClientAbcGroup, ClientAbcTransactionTypes, DefaultClientAbcSettings, DefaultDaily7RGraphTransactionTypes, DefaultMonthly7RGraphTransactionTypes, DisplayValueTyped, IqConfigurationStripped, RepDoc, SettingsAppearance, StatisticsFilterCodes, User, WidgetType, hasPermission, isFeatureAvailableForUser } from '@newgenus/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MatStepper } from '@angular/material/stepper';
import { AngularFirestore } from '@angular/fire/compat/firestore';


@Component({
  selector: 'shared-dashboard-widget-add',
  templateUrl: './add-widget-dialog.component.html',
  styleUrls: ['../dialog.styles.scss']
})
export class AddWidgetDialogComponent implements OnInit {

  //#region Variables
  // Variables for this component that are within scope of "this" component.

  @ViewChild('stepper') stepper: MatStepper | undefined;

  /**
   * Date profile options to filter by.
   * Used by:
   * - Daily 7R
   * - Monthly 7R
   * - Client ABC
   *
   * @type {DisplayValueTyped<string>[]}
   * @memberof AddWidgetDialogComponent
   */
  public dateProfileOptions: DisplayValueTyped<string>[] = [];

  /**
   * Integration options to filter by.
   * Used by:
   * - Daily 7R
   * - Monthly 7R
   * - Client ABC
   *
   * @type {DisplayValueTyped<string>[]}
   * @memberof AddWidgetDialogComponent
   */
  public integrationOptions: DisplayValueTyped<string>[] = [];

  /**
   * Reps of the Integration selected to filter by.
   * Also used for USERS of organization, if being used by Client ABC Group/List.
   * Used by:
   * - Daily 7R
   * - Monthly 7R
   * - Client ABC
   *
   * @type {DisplayValueTyped<string>[]}
   * @memberof AddWidgetDialogComponent
   */
  public repOptions: DisplayValueTyped<string>[] = [];

  /**
   * Sales codes to filter by.  
   * Used by:
   *  - Daily 7R
   *  - Monthly 7R
   *
   * @type {{ [type: string]: boolean }}
   * @memberof AddWidgetDialogComponent
   */
  public filterBySalesCodes: { [type: string]: boolean } = {}

  /**
   * Widget options to choose from.
   * Used by:
   * - Daily 7R
   * - Monthly 7R
   * - Client ABC Graph
   *
   * @type {DisplayValueTyped<WidgetType>[]}
   * @memberof AddWidgetDialogComponent
   */
  public widgetOptions: DisplayValueTyped<WidgetType>[] = [];

  /**
   * Column options to choose from.
   * Used by:
   * - Daily 7R
   * - Monthly 7R
   * - Client ABC Graph
   *
   * @type {DisplayValueTyped<number>[]}
   * @memberof AddWidgetDialogComponent
   */
  public columnOptions: DisplayValueTyped<number>[] = [];

  /**
   * Row options to choose from.
   * Used by:
   * - Daily 7R
   * - Monthly 7R
   * - Client ABC Graph
   *
   * @type {DisplayValueTyped<number>[]}
   * @memberof AddWidgetDialogComponent
   */
  public rowOptions: DisplayValueTyped<number>[] = [];

  /**
   * Table columns to choose from for the Client ABC widget.
   *
   * @type {DisplayValueTyped<string>[]}
   * @memberof AddWidgetDialogComponent
   */
  public clientAbcTableColumns: DisplayValueTyped<string>[] = [
    { display: 'Account', value: 'account' },
    { display: 'Customer', value: 'customer' },
    { display: 'Rank', value: 'rank' },
    { display: 'Sales Average', value: 'sales average' },
    { display: 'Current Month -5', value: 'current month -5' },
    { display: 'Current Month -4', value: 'current month -4' },
    { display: 'Current Month -3', value: 'current month -3' },
    { display: 'Current Month -2', value: 'current month -2' },
    { display: 'Current Month -1', value: 'current month -1' },
    { display: 'Current Month', value: 'current month' },
  ];
  private defaultColumnsSelected = ['account', 'customer', 'sales average', 'current month -2', 'current month -1', 'current month'];
  private defaultGroupColumnsSelected = ['group name', 'sales average', 'current month -2', 'current month -1', 'current month'];
  public clientAbcGroupTableColumns: DisplayValueTyped<string>[] = [
    { display: 'Group Name', value: 'group name' },
    { display: 'Rank', value: 'rank' },
    { display: 'Sales Average', value: 'sales average' },
    { display: 'Current Month -5', value: 'current month -5' },
    { display: 'Current Month -4', value: 'current month -4' },
    { display: 'Current Month -3', value: 'current month -3' },
    { display: 'Current Month -2', value: 'current month -2' },
    { display: 'Current Month -1', value: 'current month -1' },
    { display: 'Current Month', value: 'current month' },
  ];

  /**
   * Client ABC transaction types to filter from the table results.
   * Used by:
   * - Client ABC
   *
   * @type {DisplayValueTyped<ClientAbcTransactionTypes>[]}
   * @memberof AddWidgetDialogComponent
   */
  public clientAbcTransactionOptions: DisplayValueTyped<ClientAbcTransactionTypes>[] = [
    { display: 'Bank Charges', value: 'BC' },
    { display: 'Credit Notes', value: 'CN' },
    { display: 'Discounts', value: 'DS' },
    { display: 'Invoices', value: 'IN' },
    { display: 'Journal Credits', value: 'JC' },
    { display: 'Journal Debits', value: 'JD' },
    { display: 'Payments', value: 'PM' },
    { display: 'Rebates', value: 'RE' },
    { display: 'Refunds', value: 'RF' },
  ];

  /**
   * Filter by code options, these are the transactions to include in the graph.
   * Used by:
   * - Monthly 7R
   * 
   * Extended by:
   * @see dailyTransactionOptions.
   *
   * @type {DisplayValueTyped<StatisticsFilterCodes>[]}
   * @memberof AddWidgetDialogComponent
   */
  public monthlyTransactionOptions: DisplayValueTyped<StatisticsFilterCodes>[] = [
    { display: 'Bank Charges', value: 'debtTran-BC' },
    { display: 'Credit Notes', value: 'debtTran-CN' },
    { display: 'Discounts', value: 'debtTran-DS' },
    { display: 'Interest Charge', value: 'debtTran-IT' },
    { display: 'Invoices', value: 'debtTran-IN' },
    { display: 'Journal Credits', value: 'debtTran-JC' },
    { display: 'Journal Debits', value: 'debtTran-JD' },
    { display: 'Payments', value: 'debtTran-PM' },
    { display: 'Rebates', value: 'debtTran-RE' },
    { display: 'Refunds', value: 'debtTran-RF' },
  ];


  /**
   * Filter by code options, these are the same as the monthly filter by code but include the sales orders (monthly does not include sales orders).
   * Used by:
   * - Daily 7R
   *
   * @type {DisplayValueTyped<StatisticsFilterCodes>[]}
   * @memberof AddWidgetDialogComponent
   */
  public dailyTransactionOptions: DisplayValueTyped<StatisticsFilterCodes>[] = [
    ...this.monthlyTransactionOptions,
    { display: 'Sales Orders (closed)', value: 'salesOrder-closed' },
    { display: 'Open Sales Orders', value: 'salesOrder-open' },
  ];

  /**
   * Show for options to choose from, this is based on the widget type selected.
   * Used by:
   * - Daily 7R
   * - Monthly 7R
   * - Client ABC
   *
   * @type {DisplayValueTyped<string>[]} 
   * @memberof AddWidgetDialogComponent
   */
  public showForOptions: DisplayValueTyped<string>[] = [];

  /**
   * Whether or not the multiple reps option is enabled.
   *
   * @memberof AddWidgetDialogComponent
   */
  public isMultipleRepsEnabled = false;

  /**
   * A control to allow toggling the show for company option.
   * This is only available for those who have company-wide permissions on a given feature.
   * Used by:
   * - Daily 7R
   * - Monthly 7R
   * - Client ABC
   *
   * @memberof AddWidgetDialogComponent
   */
  public showForCompanyControl = false;

  /**
   * Model to render in the review screen.
   *
   * @memberof AddWidgetDialogComponent
   */
  public reviewModel = {
    type: '',
    dateProfile: '',
    integration: '',
    repKeys: '',
    cols: '',
    rows: '',
    showFor: '',
    title: '',
    dynamicGrouping: '',
    appearance: '',
    tableColumns: '',
    transactionsTypes: '',
  };

  public newWidgetForm = new FormGroup({
    type: new FormControl<WidgetType>('', Validators.required),
    title: new FormControl('', [Validators.required, Validators.maxLength(20)]),

    // Random generated ID
    id: new FormControl(Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)),

    // Adjusted for the widget type.
    settings: new FormGroup<any>({
      forCompany: new FormControl(false),
      dateProfileKey: new FormControl('', Validators.required),
      integrationKey: new FormControl('', Validators.required),
      repKeys: new FormControl('', Validators.required),
      columns: new FormControl(2, Validators.required),
      rows: new FormControl(2, Validators.required),
    }),
    auth: new FormGroup<any>({
      forReps: new FormControl([]),
      forFeature: new FormControl(''),
    })
  });

  private signedInUsersRepMapping: RepDoc | undefined;

  public mayViewGroups = false;
  public isClientAbcGroup = false;

  private clientAbcGroupsOfConfig: ClientAbcGroup[] = [];
  public clientAbcGroupOptions: DisplayValueTyped<string>[] = [];


  //#endregion

  constructor(
    public dialogRef: MatDialogRef<AddWidgetDialogComponent>,
    private db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: AddWidgetDialogInputData) {
  }

  //#region Instantiation
  // Setup\fetches\initialization.

  public ngOnInit(): void {
    this.initDialogFormFieldOptions();

    setTimeout(() => {
      this.setDefaultFormValues();
    });

    // this.newWidgetForm.valueChanges.subscribe((val) => {
    //   console.log('newWidgetForm.valueChanges:', val)
    //   console.log('newWidgetForm valid:', this.newWidgetForm.valid)
    //   console.log('newWidgetForm errors:', this.newWidgetForm.errors)
    // });
  }

  /**
   * Initialize the options variables used to populate the form fields.
   *
   * @private
   * @memberof AddWidgetDialogComponent
   */
  private initDialogFormFieldOptions() {

    // Set Date profile options.
    if (this.data.organization) {
      // Set the date profile options from the organization date cycles.
      this.dateProfileOptions = this.data.organization.orgDateCycles.map(dp => {
        return { display: dp.profileName + ' (' + dp.year + ')', value: dp.key } as DisplayValueTyped<string>;
      })
        // Sort alphabetically.
        .sort((a, b) => a.display.localeCompare(b.display));

    } else { this.dateProfileOptions = []; }

    // Set Integration options.
    if (this.data.integrations) {
      // Set the integration options from the array of integrations passed in.
      this.integrationOptions = this.data.integrations.map(i => {
        return { display: i.nickName, value: i.key } as DisplayValueTyped<string>;
      })
        // Sort alphabetically.
        .sort((a, b) => a.display.localeCompare(b.display));

    } else { this.integrationOptions = []; }

    // Set column options.
    if (this.data.allColumnWidths)
      this.columnOptions = this.data.allColumnWidths.map(c => ({ display: c.toString(), value: c } as DisplayValueTyped<number>));
    else this.columnOptions = [
      { display: '2', value: 2 },
      { display: '4', value: 4 },
      { display: '6', value: 6 },
      { display: '8', value: 8 },
    ];

    // Set row options.
    if (this.data.allRowsWidths)
      this.rowOptions = this.data.allRowsWidths.map(r => ({ display: r.toString(), value: r } as DisplayValueTyped<number>));
    else this.rowOptions = [
      { display: '2', value: 2 },
      { display: '4', value: 4 },
      { display: '6', value: 6 },
      { display: '8', value: 8 },
    ];
  }

  /**
   * Set the default values for the form fields based on the user's specific settings.
   *
   * @private
   * @memberof AddWidgetDialogComponent
   */
  private setDefaultFormValues(): void {
    // If there is only one integration, set it as the default.
    if (this.integrationOptions.length === 1) {
      // Set the integration to the only option.
      this.newWidgetForm.get('settings')?.get('integrationKey')?.setValue(this.integrationOptions[0].value);
      // Trigger the integration change.
      setTimeout(() => { this.onSelectIntegration({ value: this.integrationOptions[0].value } as MatSelectChange); });
    }

    // Set the selected date profile to the user's date profile or the first option.
    if (this.dateProfileOptions.length > 0) {
      // Set to first option by default.
      let dateProfileKey = this.dateProfileOptions[0].value;

      // If the current user has a date profile for the selected configuration, set it as the default date profile selected.
      if (this.data.currentUser) {
        // Get the selected organization.
        const selectedOrg = this.data.currentUser.organizations?.selected;
        if (selectedOrg && this.data.currentUser.organizations) {
          // Get the selected gateway key.
          const selectedGatewayKey = this.data.currentUser.organizations[selectedOrg]?.selectedGatewayKey;
          if (selectedGatewayKey && this.data.currentUser.organizationSettings) {
            // Get the user's date profile key for the selected organization and gateway.
            const userDateProfileKey = this.data.currentUser.organizationSettings[selectedOrg]?.[selectedGatewayKey]?.dateProfileKey;

            // If the user has a date profile key for the selected organization and gateway, set it as the default date profile selected.
            if (userDateProfileKey) dateProfileKey = userDateProfileKey;
          }
        }

        // Add WidgetOptions based on the user's permissions.
        if (isFeatureAvailableForUser(this.data.currentUser, 'statistics')) {
          this.widgetOptions.push({ display: 'Daily 7R', value: 'daily7r' });
          this.widgetOptions.push({ display: 'Monthly 7R', value: 'monthly7r' });
        }

        if (hasPermission(this.data.currentUser, 'client-abc', 'create-own-custom-groups') || hasPermission(this.data.currentUser, 'client-abc', 'create-company-custom-groups')) {
          this.mayViewGroups = true;
        }

        if (isFeatureAvailableForUser(this.data.currentUser, 'client-abc')) {
          // this.widgetOptions.push({ display: 'Client ABC Graph', value: 'clientAbcGraph' });
          this.widgetOptions.push({ display: 'Client ABC', value: 'clientAbc' });

          if (this.mayViewGroups) {
            this.widgetOptions.push({ display: 'Custom Client ABC', value: 'clientAbcGroup' });
            this.widgetOptions.push({ display: 'Custom ABC List', value: 'clientAbcGroupList' });

            if (this.integrationOptions.length === 1) {
              const config = this.data.integrations?.find(i => i.key === this.integrationOptions[0].value);

              // Would always be true, typescript
              if (config) {
                this.fetchAllClientAbcGroupsForConfig(this.data.currentUser, config);
              }
            }
          }
        }
      }

      this.newWidgetForm.get('settings')?.get('dateProfileKey')?.setValue(dateProfileKey);
    }

    if (this.data.currentUser && this.data.currentUser.organizations) {
      const selectedOrg = this.data.currentUser.organizations[this.data.currentUser.organizations?.selected];

      const index = this.clientAbcTableColumns.findIndex(c => c.value === 'customer');
      if (index !== -1) {
        this.clientAbcTableColumns.splice(index, 1);
      }

      // Remove customer option for demo orgs.
      if (selectedOrg.key === 'JnmtX23FnxaTUATTcCca' // Stage
        || selectedOrg.key === 'SeRpo8swpTMAK5rB6TlW' // Dev
      ) {

        if (index !== -1) {
          this.clientAbcTableColumns.splice(index, 1);

          const defaultIndex = this.defaultColumnsSelected.findIndex(c => c === 'customer');
          if (defaultIndex !== -1) this.defaultColumnsSelected.splice(defaultIndex, 1);
        }

      } else if (index === -1) {
        // Add customer option for non-demo orgs.
        this.clientAbcTableColumns.splice(1, 0, { display: 'Customer', value: 'customer' });
        const defaultIndex = this.defaultColumnsSelected.findIndex(c => c === 'customer');
        if (defaultIndex === -1) this.defaultColumnsSelected.splice(1, 0, 'customer');
      }

    }

  }

  //#endregion
  //#region User Interactions
  // Callbacks from html, anything the user can "interact" with from the view.

  /**
   * Callback for when the user selects a widget type.
   * This will adjust the form fields to match the widget type selected.
   *
   * @param {string} widgetType The widget type selected.
   * @memberof AddWidgetDialogComponent
   */
  public onSelectWidgetType(widgetType: string): void {
    this.isClientAbcGroup = false;

    // Set feature type on auth.
    (this.newWidgetForm.controls['auth'] as any).controls['forFeature'].setValue(this.featureForWidgetType(widgetType as WidgetType));

    // Remove widget-specific controls if they exists.
    if (this.newWidgetForm.controls['settings'].contains('showFor')) (this.newWidgetForm.controls['settings'] as any).removeControl('showFor');
    if (this.newWidgetForm.controls['settings'].contains('tableColumns')) (this.newWidgetForm.controls['settings'] as any).removeControl('showFor');
    if (this.newWidgetForm.controls['settings'].contains('dynamicGrouping')) (this.newWidgetForm.controls['settings'] as any).removeControl('dynamicGrouping');
    if (this.newWidgetForm.controls['settings'].contains('appearance')) (this.newWidgetForm.controls['settings'] as any).removeControl('appearance');
    this.newWidgetForm.controls['settings'].get('repKeys')?.setValidators(Validators.required);


    switch (widgetType) {
      case 'daily7r':

        // Options from type DailyShowFor
        this.showForOptions = [
          { display: 'Today', value: 'today' },
          { display: 'Yesterday', value: 'yesterday' },
          { display: 'Day Before Yesterday', value: 'dayBeforeYesterday' },
          { display: 'Previous Business Day', value: 'previousBusinessDay' },
          { display: 'Day Before Previous Business Day', value: 'dayBeforePreviousBusinessDay' },
        ];

        // Add the showFor control with today as the default and disable it.
        this.newWidgetForm.controls['settings'].addControl('showFor', new FormControl('today', Validators.required));
        // this.newWidgetForm.controls.configuration.get('showFor')?.disable();

        // Add filtersByCode control with default value.
        this.newWidgetForm.controls['settings'].addControl('filtersByCode', new FormControl(new Array<StatisticsFilterCodes>(), Validators.compose([Validators.required, Validators.minLength(1)])));
        this.newWidgetForm.controls['settings'].get('filtersByCode')?.setValue(DefaultDaily7RGraphTransactionTypes.slice());

        // Set title to a default if there is no title.
        if (!this.newWidgetForm.controls.title.value) this.newWidgetForm.controls.title.setValue('Daily 7R');

        this.isMultipleRepsEnabled = true;
        break;

      case 'monthly7r':

        // Options from type MonthlyShowFor
        this.showForOptions = [
          { display: 'This Month', value: 'thisMonth' },
          { display: 'Last Month', value: 'lastMonth' },
          { display: 'Month Before Last', value: 'monthBeforeLast' },
        ];

        // Add the showFor control with thisMonth as the default and disable it.
        setTimeout(() => {
          this.newWidgetForm.controls['settings'].addControl('showFor', new FormControl('thisMonth', Validators.required));
          // this.newWidgetForm.controls['settings'].get('showFor')?.disable();
        });

        // Set title to a default if there is no title.
        if (!this.newWidgetForm.controls.title.value) this.newWidgetForm.controls.title.setValue('Monthly 7R');

        // Add filtersByCode control with default value.
        this.newWidgetForm.controls['settings'].addControl('filtersByCode', new FormControl(new Array<StatisticsFilterCodes>, Validators.compose([Validators.required, Validators.minLength(1)])));
        this.newWidgetForm.controls['settings'].get('filtersByCode')?.setValue(DefaultMonthly7RGraphTransactionTypes.slice());

        this.isMultipleRepsEnabled = true;
        break;

      case 'clientAbcGraph':

        // Options from type MonthlyShowFor
        this.showForOptions = [
          { display: 'This Month', value: 'thisMonth' },
          { display: 'Last Month', value: 'lastMonth' },
          { display: 'Month Before Last', value: 'monthBeforeLast' },
        ];

        // Add the showFor control with thisMonth as the default and disable it.
        this.newWidgetForm.controls['settings'].addControl('showFor', new FormControl('thisMonth', Validators.required));
        // this.newWidgetForm.controls['settings'].get('showFor')?.disable();

        // Set title to a default if there is no title.
        if (!this.newWidgetForm.controls.title.value) this.newWidgetForm.controls.title.setValue('Client ABC Graph');

        this.isMultipleRepsEnabled = false;
        break;

      case 'clientAbc':
      case 'clientAbcGroup':
      case 'clientAbcGroupList':

        // Options from type MonthlyShowFor
        this.showForOptions = [
          { display: 'This Month', value: 'thisMonth' },
          { display: 'Last Month', value: 'lastMonth' },
          { display: 'Month Before Last', value: 'monthBeforeLast' },
        ];

        // Add the showFor control with thisMonth as the default and disable it.
        this.newWidgetForm.controls['settings'].addControl('showFor', new FormControl('thisMonth', Validators.required));

        // Add tableColumns control with default columns.
        this.newWidgetForm.controls['settings'].addControl('tableColumns', new FormControl([], Validators.compose([Validators.required, Validators.minLength(1)])));

        // Set title to a default if there is no title.
        if (!this.newWidgetForm.controls.title.value) this.newWidgetForm.controls.title.setValue('Client ABC');

        // Regular Client ABC and Client ABC Group will always be for an individual rep/user.
        this.isMultipleRepsEnabled = false;

        if (widgetType === 'clientAbcGroup' || widgetType === 'clientAbcGroupList') {
          // Flag to change controls in the html.
          this.isClientAbcGroup = true;
          this.newWidgetForm.controls['settings'].get('repKeys')?.setValidators(Validators.compose([]));

          // Set title to a default if there is no title.
          if (this.newWidgetForm.controls.title.value === 'Client ABC') this.newWidgetForm.controls.title.setValue('Client ABC Group');

          // Add forAllGroups control with default value.
          this.newWidgetForm.controls['settings'].addControl('forAllGroups', new FormControl(false));

          // Add groupKeys control with no default value.
          this.newWidgetForm.controls['settings'].addControl('groupKeys', new FormControl(new Array<string>(), Validators.required));

          if (widgetType === 'clientAbcGroup') {
            this.newWidgetForm.controls['settings'].get('tableColumns')?.setValue(this.defaultColumnsSelected.slice());

            // For Client ABC Group, userKeys is NOT an array.
            this.newWidgetForm.controls['settings'].addControl('userKeys', new FormControl(this.data.currentUser?.uid || '', Validators.required));
          }
          else if (widgetType === 'clientAbcGroupList') {
            this.newWidgetForm.controls['settings'].get('tableColumns')?.setValue(this.defaultGroupColumnsSelected.slice());

            // For Client ABC Group LIST, userKeys IS an array.
            this.newWidgetForm.controls['settings'].addControl('userKeys', new FormControl(new Array<string>(), Validators.required));
            if (this.data.currentUser?.uid !== undefined) {
              this.newWidgetForm.controls['settings'].get('userKeys')?.setValue([this.data.currentUser.uid]);
            } else {
              this.newWidgetForm.controls['settings'].get('userKeys')?.disable();
            }

            // Client ABC Group List will allow listing of multiple reps/users. - later on...
            // this.isMultipleRepsEnabled = true;
          }

        } else {// Only for regular Client ABC.
          this.newWidgetForm.controls['settings'].get('tableColumns')?.setValue(this.defaultColumnsSelected.slice());

          // Add transactionTypes control with default value.
          this.newWidgetForm.controls['settings'].addControl('transactionTypes', new FormControl(DefaultClientAbcSettings.transactionTypes, Validators.compose([Validators.required, Validators.minLength(1)])));

          // Add dynamicGrouping control with default value.
          this.newWidgetForm.controls['settings'].addControl('dynamicGrouping', new FormControl(ClientAbcDynamicGroups['No grouping'], Validators.required));

          // Add appearance control with default value.
          this.newWidgetForm.controls['settings'].addControl('appearance', new FormControl(SettingsAppearance.gross, Validators.required));
        }

        break;

      default:
        alert('Unknown widget type: ' + widgetType);
        this.dialogRef.close(null);
        break;
    }

    // Must be called only after the switch statement.
    this.updateRepOptionsByWidgetAndFeaturePermission();
    this.newWidgetForm.updateValueAndValidity();

    // Step the user to the next step.
    // setTimeout(() => {
    //   if (this.stepper) this.stepper.next();
    // }, 700);
  }

  /**
   * Callback for when the user selects an integration/gateway.
   * This will adjust the rep options to match the integration selected.
   * if the user has a rep mapping for the integration, that rep will be selected by default.
   *
   * @param {MatSelectChange} event The event from the mat select.
   * @memberof AddWidgetDialogComponent
   */
  public onSelectIntegration(event: MatSelectChange): void {
    const match = this.data.integrations?.find(i => i.key === event.value);

    // If there is no match, or no integrations, disable the rep field and clear the options.
    if (!this.data.integrations || !match) {
      this.repOptions = [];
      this.newWidgetForm.get('settings')?.get('repkeys')?.disable();
      // TODO if there are widgets in the future that don't require a rep, then the validation will need to permit it based on the widget type.
      return;
    }

    this.updateRepOptionsByWidgetAndFeaturePermission();
  }

  /**
   * Callback for when the user selects a rep.
   *
   * @param {MatSelectChange} $event The event from the mat select.
   * @memberof AddWidgetDialogComponent
   */
  public onSelectRep($event: MatSelectChange): void {
    // Update the form's forReps field.
    (this.newWidgetForm.controls['auth'] as any).controls['forReps'].setValue($event.value || []);
  }

  public submit(): any {
    const formValue = this.newWidgetForm.value;

    // Set repKeys to empty array if forCompany is true.
    if (formValue.settings && formValue.settings['forCompany']) {
      formValue.settings['repKeys'] = [];
      if (formValue.auth) formValue.auth['forReps'] = [];
    }

    // Convert repKeys to array if it is a string - ClientABC widget has a single rep key.
    if (formValue.settings?.['repKeys'] && typeof formValue.settings?.['repKeys'] === 'string') { formValue.settings['repKeys'] = [formValue.settings['repKeys']]; }

    if (formValue.type === 'clientAbcGroup' || formValue.type === 'clientAbcGroupList') {
      delete formValue.settings['forCompany'];
      delete formValue.settings['repKeys'];

      // Convert groupKeys to array if it's not already.
      if (formValue.settings['groupKeys'] && !Array.isArray(formValue.settings['groupKeys'])) {
        formValue.settings['groupKeys'] = [formValue.settings['groupKeys']];
      }

      // Convert userKeys to array if it's not already.
      if (formValue.settings['userKeys']) {
        if (!Array.isArray(formValue.settings['userKeys'])) {
          formValue.settings['userKeys'] = [formValue.settings['userKeys']];
        }

        // Update auth to use userKeys instead of repKeys.
        formValue.auth['userKeys'] = formValue.settings['userKeys'];
        delete formValue.auth['forReps'];
      }
    }

    return formValue;
  }

  public onSelectClientAbcUser(event: MatSelectChange): void {
    // Change the group options to match the selected user.
    this.clientAbcGroupOptions = this.clientAbcGroupsOfConfig
      .filter(group => group.userKey === event.value)
      .map(group => ({ display: group.title, value: group.key } as DisplayValueTyped<string>))
      .sort((a, b) => a.display.localeCompare(b.display));

    // Is single value.
    if (this.newWidgetForm.controls['type'].value === 'clientAbcGroup') {
      // Set the default group to the first option if there is one.
      if (this.clientAbcGroupOptions.length > 0)
        this.newWidgetForm.controls['settings'].get('groupKeys')?.setValue(this.clientAbcGroupOptions[0].value);
      else this.newWidgetForm.controls['settings'].get('groupKeys')?.setValue(null);

    } else { // Is array value.
      // Set the default group to the first option if there is one.
      if (this.clientAbcGroupOptions.length > 0)
        this.newWidgetForm.controls['settings'].get('groupKeys')?.setValue([this.clientAbcGroupOptions[0].value]);
      else this.newWidgetForm.controls['settings'].get('groupKeys')?.setValue([]);
    }

  }

  //#endregion
  //#region Events and Domain Functions
  // $watches/$on events, functions which don't fall into any of the other regions,
  // including any function that has to do domain functionality.

  public onStep(): void {

    // Update the review model.
    this.reviewModel.type = this.widgetOptions.find(w => w.value === this.newWidgetForm.get('type')?.value)?.display || '';
    this.reviewModel.dateProfile = this.dateProfileOptions.find(dp => dp.value === this.newWidgetForm.get('settings')?.get('dateProfileKey')?.value)?.display || '';
    this.reviewModel.integration = this.integrationOptions.find(i => i.value === this.newWidgetForm.get('settings')?.get('integrationKey')?.value)?.display || '';
    if (this.isMultipleRepsEnabled) {
      this.reviewModel.repKeys = this.repOptions
        .filter(rep => (this.newWidgetForm.get('settings')?.get('repKeys')?.value as string[]).includes(rep.value))
        .map(rep => rep.display)
        .join(', ');
    } else {
      this.reviewModel.repKeys = this.repOptions.find(rep => rep.value === this.newWidgetForm.get('settings')?.get('repKeys')?.value)?.display || '';
    }
    this.reviewModel.cols = this.columnOptions.find(c => c.value === this.newWidgetForm.get('settings')?.get('columns')?.value)?.display || '';
    this.reviewModel.rows = this.rowOptions.find(r => r.value === this.newWidgetForm.get('settings')?.get('rows')?.value)?.display || '';
    this.reviewModel.showFor = this.showForOptions.find(s => s.value === this.newWidgetForm.get('settings')?.get('showFor')?.value)?.display || '';
    this.reviewModel.title = this.newWidgetForm.get('title')?.value || '';
    this.reviewModel.appearance = this.newWidgetForm.get('settings')?.get('appearance')?.value || '';
    this.reviewModel.dynamicGrouping = this.newWidgetForm.get('settings')?.get('dynamicGrouping')?.value || '';
    this.reviewModel.tableColumns = this.newWidgetForm.get('settings')?.get('tableColumns')?.value || '';
    this.reviewModel.transactionsTypes = this.newWidgetForm.get('settings')?.get('transactionsTypes')?.value || '';

  }

  private updateRepOptionsByWidgetAndFeaturePermission(): void {
    const currentIntegrationKey = this.newWidgetForm.get('settings')?.get('integrationKey')?.value;
    const integrationMatch = this.data.integrations?.find(i => i.key === currentIntegrationKey);
    const currentWidgetType = this.newWidgetForm.get('type')?.value;

    // Default to no rep options.
    this.repOptions = [];

    // If any of the required fields are missing, disable the rep field.
    if (!this.data.currentUser || !this.data.integrations || !integrationMatch || !currentWidgetType) {
      this.newWidgetForm.get('settings')?.get('repKeys')?.disable();
      // TODO if there are widgets in the future that don't require a rep, then the validation will need to permit it based on the widget type.
      return;
    }

    // If the current user has a rep number for the selected configuration, set it as the default rep selected.
    const uid = this.data.currentUser.uid;

    // Set the rep options based on the user's permissions for the features they have access to.
    const newRepOptions = Object.values(integrationMatch.userMapping || {})
      .map(mapping => ({ display: mapping.repName + ` (${mapping.repNum})`, value: mapping.repNum } as DisplayValueTyped<string>))
      // Sort alphabetically.
      .sort((a, b) => a.display.localeCompare(b.display));

    // Find the rep mapping for the signed in user if it exists.
    this.signedInUsersRepMapping = Object.values(integrationMatch.userMapping || {})
      .find((mapping) => mapping.orgUserKey === uid);

    switch (currentWidgetType) {

      case 'daily7r':
      case 'monthly7r':
        // Show all reps if have view-company-statistics permission, else, show only themselves.
        if (hasPermission(this.data.currentUser, 'statistics', 'view-company-statistics')) {
          // Show all reps.
          this.repOptions = newRepOptions;
          this.showForCompanyControl = true;
        } else if (hasPermission(this.data.currentUser, 'statistics', 'view-own-statistics')) {
          // Show only themselves.
          this.repOptions = newRepOptions.filter(rep => rep.value === this.signedInUsersRepMapping?.repNum);;
          this.showForCompanyControl = false;
        }
        break;
      // case 'clientAbcGraph':
      case 'clientAbc':
        this.showForCompanyControl = false;
        // Show all reps if have view-company-client-abc permission, else, show only themselves.
        if (hasPermission(this.data.currentUser, 'client-abc', 'view-company-client-abc')) {
          // Show all reps.
          this.repOptions = newRepOptions;
        } else if (hasPermission(this.data.currentUser, 'client-abc', 'view-own-client-abc')) {
          // Show only themselves.
          this.repOptions = newRepOptions.filter(rep => rep.value === this.signedInUsersRepMapping?.repNum);;
        }
        break;
      case 'clientAbcGroup':
      case 'clientAbcGroupList':
        this.showForCompanyControl = false;
        // Show all users if have create-company-custom-groups permission, else, show only themselves.
        if (hasPermission(this.data.currentUser, 'client-abc', 'create-company-custom-groups')) {
          // Show all users.
          this.data.organization?.orgUsers.userKeys.forEach(userKey => {
            const user = this.data.organization?.orgUsers[userKey];
            if (user) this.repOptions.push({ display: user.name, value: user.uid });
          });
        } else if (hasPermission(this.data.currentUser, 'client-abc', 'create-own-custom-groups')) {
          // Show only themselves.
          this.repOptions = [{ display: (this.data.currentUser?.firstName || '') + ' ' + (this.data.currentUser?.lastName || ''), value: this.data.currentUser?.uid || '' }];
        }
        break;


      default:
        this.showForCompanyControl = false;
        alert('Unknown widget type: ' + currentWidgetType);
        this.dialogRef.close(null);
        break;
    }

    // Set the default selected rep to the signed in user's rep if they have a rep mapping for the integration.
    if (this.signedInUsersRepMapping) {
      if (this.isMultipleRepsEnabled) {
        this.newWidgetForm.get('settings')?.get('repKeys')?.setValue([this.signedInUsersRepMapping['repNum']], { emitEvent: true });
      } else {
        this.newWidgetForm.get('settings')?.get('repKeys')?.setValue(this.signedInUsersRepMapping['repNum'], { emitEvent: true });
      }

      this.onSelectRep({ value: [this.signedInUsersRepMapping['repNum']] } as MatSelectChange);
    }

    if (this.repOptions.length === 0) {
      this.newWidgetForm.get('settings')?.get('repKeys')?.disable();
    } else {
      this.newWidgetForm.get('settings')?.get('repKeys')?.enable();
    }

    // Sort alphabetically.
    this.repOptions.sort((a, b) => a.display.localeCompare(b.display));
  }

  public onToggleForCompany(event: MatSlideToggleChange): void {

    // Disable the forReps control if the forCompany control is enabled.
    if (event.checked) {
      this.newWidgetForm.get('settings')?.get('repKeys')?.disable();
    } else {
      this.newWidgetForm.get('settings')?.get('repKeys')?.enable();
      this.newWidgetForm.get('settings')?.get('repKeys')?.markAsTouched();
      this.newWidgetForm.get('settings')?.get('repKeys')?.markAsDirty();
    }

    // If the user hasn't changed the title yet, append "Company" to the title.
    switch (this.newWidgetForm.get('type')?.value as WidgetType) {
      case 'daily7r':
        if (this.newWidgetForm.get('title')?.value === 'Daily 7R') this.newWidgetForm.get('title')?.setValue('Daily 7R Company');
        break;
      case 'monthly7r':
        if (this.newWidgetForm.get('title')?.value === 'Monthly 7R') this.newWidgetForm.get('title')?.setValue('Monthly 7R Company');
        break;
      case 'clientAbc':
        if (this.newWidgetForm.get('title')?.value === 'Client ABC') this.newWidgetForm.get('title')?.setValue('Client ABC Company');
        break;
      default:
        // Do nothing...
        break;
    }
  }

  public onToggleForAllGroups(event: MatSlideToggleChange): void {

    if (event.checked) {
      this.newWidgetForm.get('settings')?.get('groupKeys')?.disable();
    } else {
      this.newWidgetForm.get('settings')?.get('groupKeys')?.enable();
      this.newWidgetForm.get('settings')?.get('groupKeys')?.markAsTouched();
      this.newWidgetForm.get('settings')?.get('groupKeys')?.markAsDirty();
    }

  }

  public onCreateNewGroup() {
    // todo
  }


  public onSelectClientAbcGroup(event: MatSelectChange) {

    // Update title to match the selected group if this is a single client abc group.
    if (this.newWidgetForm.controls['type'].value === 'clientAbcGroup'
      && (this.newWidgetForm.controls['title'].value === 'Client ABC Group'
        || this.clientAbcGroupsOfConfig.some(g => this.newWidgetForm.controls['title'].value?.includes(g.title) && this.newWidgetForm.controls['title'].value?.includes('(Group)')))) {
      this.newWidgetForm.controls['title'].setValue(event.source.triggerValue + ' (Group)');

      // Confirm the length of the new title won't be too long.
      if (event.source.triggerValue.length > 12 && event.source.triggerValue.length <= 20) {
        // Only the group title.
        this.newWidgetForm.controls['title'].setValue(event.source.triggerValue);
      } else if (event.source.triggerValue.length <= 12) {
        // Group title + indicator.
        this.newWidgetForm.controls['title'].setValue(event.source.triggerValue + ' (Group)');
      } else if (event.source.triggerValue.length > 20) {
        // Trimmed group title.
        this.newWidgetForm.controls['title'].setValue(event.source.triggerValue.slice(0, 16) + '...');
      }
    }

  }

  //#endregion
  //#region Data Access & Subscriptions
  // API interactions, NGRX selects, etc.

  // TODO: this should be updated to use NGRX.
  private fetchAllClientAbcGroupsForConfig(currentUser: User, config: IqConfigurationStripped) {
    // /gateway/--iq-enterprise--/public/JYAL9T3BvARbCUzscVHs/custom-client-abc-groups/1sQAJdm3pGMcD6ZCYllX
    this.db.collection('gateway').doc(`--${config.type}--`).collection('public').doc(config.key).collection('custom-client-abc-groups')
      .get()
      .subscribe((snapshot) => {
        snapshot.docs.forEach((doc) => {
          this.clientAbcGroupsOfConfig = snapshot.docs.map(d => d.data() as ClientAbcGroup);
        });

        this.setClientAbcGroupOptionsForUserForCurrentConfig(currentUser);
      });
  }

  private setClientAbcGroupOptionsForUserForCurrentConfig(user: User) {
    this.clientAbcGroupOptions = this.clientAbcGroupsOfConfig
      .filter(group => group.userKey === user.uid)
      .map(group => ({ display: group.title, value: group.key } as DisplayValueTyped<string>))
      .sort((a, b) => a.display.localeCompare(b.display));
  }

  //#endregion
  //#region Setters, Updaters and Preloaders
  // Setter functions, and methods called from the Instantiation region.

  //#endregion
  //#region Getters and Filters
  // Getter functions and filter methods/functions.

  private featureForWidgetType(widgetType: WidgetType): string {

    switch (widgetType) {
      case 'daily7r':
      case 'monthly7r':
        return 'statistics';

      case 'clientAbcGraph':
      case 'clientAbcGroup':
      case 'clientAbcGroupList':
      case 'clientAbc':
        return 'clientabc'

      default:
        alert('Feature not recognized for widget type: ' + widgetType);
        return '';
    }

    // // Add WidgetOptions based on the user's permissions.
    // if (isFeatureAvailableForUser(this.data.currentUser, 'statistics')) {
    //   this.widgetOptions.push({ display: 'Daily 7R', value: 'daily7r' });
    //   this.widgetOptions.push({ display: 'Monthly 7R', value: 'monthly7r' });
    // }

    // if (isFeatureAvailableForUser(this.data.currentUser, 'client-abc')) {
    //   // this.widgetOptions.push({ display: 'Client ABC Graph', value: 'clientAbcGraph' });
    //   this.widgetOptions.push({ display: 'Client ABC', value: 'clientAbc' });
    // }

  }

  //#endregion
  //#region Change Detection
  // Deep config comparisons, change log generation, hasChangesFn, etc.

  //#endregion    
  //#region Parsing and Validation
  // Transformation/mapping methods
  // Methods returning true/false: isCurrent, areFeedsEqual, shouldProcess, etc.

  //#endregion
  //#region Response Handlers
  // API/NGRX callback handlers.

  //#endregion
  //#region Navigation
  // Confirmation close dialogs, router guards, etc.

  //#endregion
  //#region Post Instantiation
  // Post-init fetch requests, used in such as landing page, after view init, etc.

  //#endregion

}
