import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrgState } from './org.reducers';
import * as fromOrg from './org.reducers';

export const selectOrgsState = createFeatureSelector<OrgState>('orgs');

export const selectOrgs = createSelector(
    selectOrgsState,
    fromOrg.selectAll
);

export const selectOrg = (id: string) => {
    return createSelector(
        selectOrgs,
        org => org.find(orgs => orgs.key === id)
    );
};

export const isOrgLoaded = createSelector(
    selectOrgsState,
    state => {
        return state.allOrgsLoaded;
    }
);
