
import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ParticleService } from '../services/particle.service';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from '@newgenus/common';

@Injectable({
  providedIn: 'root'
})
export class DisplayService implements OnDestroy {

  private destroy$ = new Subject<void>();
  public userProfileSubject$ = new BehaviorSubject<User | null>(null);
  public loginSubject$ = new BehaviorSubject<boolean>(false);
  public popUpSubject$ = new BehaviorSubject<boolean>(false);
  public highlightSection$ = new BehaviorSubject<string | null>(null);
  tempFolder$: any;
  tempFolderSubject$: any;
  folders: any[] = [];


  constructor(private particleService: ParticleService) {

    this.particleService.getUserFolders()
      .pipe(takeUntil(this.destroy$))
      .subscribe((folders) => {

        this.folders = folders;
      });

    this.tempFolder$ = new BehaviorSubject<any>(this.folders);

    this.tempFolderSubject$ = this.tempFolder$.asObservable();

    this.tempFolderSubject$
      .pipe(takeUntil(this.destroy$))
      .subscribe((f: any[]) => {
        this.folders = f;
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }

  getLogginStatus() {
    return this.loginSubject$;
  }

  updateLogginStatus(value: boolean) {
    this.loginSubject$.next(value);
  }

  drop(event: any, folders: any[]) {
    this.folders = folders;
    if (event.previousContainer === event.container) {

      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

      const folderId = event.container.id.replace('particle-', '').replace('dispatch-', '').replace('order-', '');

      const folder = this.folders.find(f => {  // Bug right here, needs tobe fixed.

        return f.id === folderId

      });



      if (folder !== undefined) {

        folder.particlelist = event.container.data;

        // for(var i = 0; i < folder.particlelist.length;++i){

        //     folder.particlelist[i].position = i;
        // }

        this.particleService.updateFolder(folder);
      }

    }
    else {

      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);

      const previousFolderId = event.previousContainer.id.replace('particle-', '').replace('dispatch-', '').replace('order-', '');
      const currentFolderId = event.container.id.replace('particle-', '').replace('dispatch-', '').replace('order-', '');

      const currentFolder = this.folders.find(f => {

        return f.id === currentFolderId

      });

      const previousFolder = this.folders.find(f => {

        return f.id === previousFolderId

      });

      if (currentFolder !== undefined) {
        currentFolder.particlelist = event.container.data;
      }

      if (previousFolder !== undefined) {

        previousFolder.particlelist = event.previousContainer.data;

      }

      this.particleService.updateFolder(currentFolder);
      this.particleService.updateFolder(previousFolder);


    }
  }

  updateHighLightedValue(section: string) {
    this.highlightSection$.next(section);
  }

  getHighLightedValue() {
    return this.highlightSection$.asObservable();
  }

  getPopUpCloser() {
    return this.popUpSubject$;
  }

  updatePopUpClose(value: boolean) {
    this.popUpSubject$.next(value);
  }

  getElementToFocusOn(nativeElement: any) { // hardcoded shall be automated in future.

    let elementToFocus: any
    const tagName = (nativeElement) ? nativeElement.tagName : '';

    switch (tagName) {
      case 'TAG-INPUT':
        elementToFocus = nativeElement.querySelector('.ng2-tag-input__text-input');
        break;
      case 'DIV':

        elementToFocus = nativeElement.querySelector('.ce-paragraph');

        if (elementToFocus !== null) {
          const selection = window.getSelection();
          const range = document.createRange();
          if (elementToFocus.childElementCount > 0 && selection) {
            range.selectNodeContents(elementToFocus);
            range.setStart(elementToFocus, 1);
            range.collapse(true);
            selection.addRange(range);
          }
        }
        break;
    }

    return elementToFocus;
  }
}
