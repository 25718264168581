import { DisplayValue, Workspace, ThemeIcons, User, isFeatureAvailableForUser } from '@newgenus/common';
import { distinctUntilChanged, take, takeUntil, Observable, Subject } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { selectWorkspace } from '../../redux/workspace/workspace.selectors';
import { trigger, style, transition, animate } from '@angular/animations';
import { SecurityService } from '../../shared/services/security.service';
import { NetworkService } from '../../shared/services/network.service';
import { ThemeService } from '../../shared/services/theme.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AuthService } from '../../shared/services/auth.service';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../shared/services/api.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { StoreUser } from '../../workspace/user.abstract';
import { VERSION } from '../../../environments/version';
import { HttpClient } from '@angular/common/http';
import { AppState } from '../../redux/reducers';
import { select, Store } from '@ngrx/store';
import firebase from 'firebase/compat/app';
import { Router } from '@angular/router';


@Component({
  selector: 'newgenus-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('valueAnimation', [
      transition(':increment', [
        style({ color: '#4a4a4aa3', 'background-color': '#8a8a8aa3' }),
        animate('0.4s ease-out', style('*'))
      ]
      ),
      transition(':decrement', [
        style({ color: '#4a4a4aa3', 'background-color': '#8a8a8aa3' }),
        animate('0.4s ease-out', style('*'))
      ]
      )
    ])
  ]
})
export class HeaderComponent extends StoreUser implements OnInit {

  @Input() hats!: any;
  @Input() settings!: any;
  @Output() toggle = new EventEmitter<void>();

  public name!: string;
  public popValue!: string;
  public profileUrl!: string;
  public firstName?: string;
  public lastName?: string;
  public userInitials = '';
  public photoUrl!: string;
  public photoSrc: string | ArrayBuffer = '';
  public date = 'N/A';
  public version = VERSION;
  public popsettings = false;
  public pophats = false;
  // public clientLinkedEmails: { gmail: string, outlook: string } = { gmail: undefined, outlook: undefined };
  public themeIcon!: ThemeIcons;
  public selectedOrg?: string;
  public organizationList: Array<DisplayValue> = [];

  public hasWorkspaceAccess = false;
  public isLinked = false;
  public isSubNavSidebarClosed = false;
  public inbasketCount = 0;
  public pendingCount = 0;

  public isFocusMode = false;
  public isSmallDisplay = false;

  private destroy$ = new Subject<void>();
  public basketCount$: any;
  public environment = '';
  public emulator = false;
  private workspace$!: Observable<Workspace[]>;
  public invisibleSignatures = new Set<string>();


  constructor(
    private networkService: NetworkService,
    private storage: AngularFireStorage,
    private fbAuth: AngularFireAuth,
    private themeService: ThemeService,
    private apiService: ApiService,
    private httpClient: HttpClient,
    public override store: Store<AppState>,
    public auth: AuthService,
    public security: SecurityService,
    private router: Router
  ) {
    super(store);
    this.initStoreUser(this.destroy$);
  }

  public ngOnInit(): void {
    this.themeIcon = this.themeService.getThemeIcon();

    this.user$
      .pipe(takeUntil(this.destroy$))
      .subscribe((users) => {
        if (users && users[0]) {
          if (this.user.firstName) {
            this.firstName = this.user.firstName;
            this.lastName = this.user.lastName;
            this.userInitials = this.nameAcronym(this.firstName, this.lastName);
          }
          this.isLinked = this.isGmailLinked || this.isOutlookLinked;

          this.loadProfileImage(this.user);
          this.cacheUsersSignatures();
          this.loadUserOrganizations();
          this.hasWorkspaceAccess = isFeatureAvailableForUser(this.user, 'workspace');
        }
      });

    this.themeService.localSidebarStatus$.asObservable()
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((sidebarStatus) => {
        this.isSubNavSidebarClosed = sidebarStatus;
      });

    this.themeService.isSmallDisplay$.asObservable()
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe(isSmallDisplay => {
        setTimeout(() => {
          this.isSmallDisplay = isSmallDisplay;
        });
      });

    this.themeService.isFocusModeEnabled$.asObservable()
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe(isFocusModeEnabled => {
        this.isFocusMode = isFocusModeEnabled;
      });

    this.workspace$ = this.store.pipe(select(selectWorkspace));
    this.workspace$.subscribe(workspace => {
      this.inbasketCount = workspace.length > 0 ? workspace[0].inbasketCount : this.inbasketCount;
      this.pendingCount = workspace.length > 0 ? workspace[0].pendingCount : this.pendingCount;
    })

    this.environment = environment.env;
    this.emulator = environment.useEmulators;
  }

  private cacheUsersSignatures() {
    const htmlSignatures = this.getEmailSignatures();
    htmlSignatures
      // Find any http/s links.
      .map(html => html.match(/\bhttps?:\/\/\S+/gi))
      // Flatten array as it will be jagged from the Regex matcher.
      .flat()
      // Remove trailing closing quote from matcher.
      .map(url => url?.substring(0, url.length - 1))
      .forEach(url => {
        if (url) {
          // Append to list of invisible images to render - this is implicitly handled by the service worker.
          this.invisibleSignatures.add(url)
        }
      });
  }

  private getEmailSignatures(): string[] {
    const sigPref = this.user.preferences?.signature || {};
    const sigType = sigPref?.type || '';

    if (sigType === 'OneForAll' && sigPref.defaultContent) {
      return [sigPref.defaultContent];
    } else if (sigType === 'EachToTheirOwn' || sigType === 'LiquoriceAllSorts') {
      const results = [];

      Object.entries(this.user.linkedEmails || {}).forEach((x) => {
        Object.entries(x[1]).forEach((y) => {
          // Add signature if it exists.
          if (y[1].signature !== undefined) {
            results.push(y[1].signature);
          }
        });
      });

      // Add default signature.
      if (sigType === 'LiquoriceAllSorts' && sigPref.defaultContent) {
        results.push(sigPref.defaultContent);
      }

      // Return matching signature
      return results;

    }

    // No signature setup.
    return [];
  }

  public emitToggle(): void {
    this.toggle.emit();
  }

  async linkGmailAccount() {
    (window as any).location = environment.gcp.oauthLink;
  }

  async unlinkGmailAccount() {
    try {
      console.log('TODO: what account are you disconnecting here?');
      // // const email = await firebase.auth().currentUser.email;
      // const idToken = await firebase.auth().currentUser.getIdToken();
      // return await this.apiService.unlinkFromGmail(idToken, this.clientLinkedEmails.gmail)
      //   .toPromise().then((email) => {
      //     return email;
      //   }).catch(err => {
      //     console.error('Error occurred', err); // TODO check if no sensitive data is printed out
      //   });
    }
    catch (error) {
      console.error('Error occurred', error); // TODO check if no sensitive data is printed out
    }
  }

  public async linkMicrosoftAccount(): Promise<void> {
    const idToken = await firebase.auth().currentUser?.getIdToken();
    (window as any).location = `${environment.emulator_config.useAuthEmulator ? environment.emulator_config.azureOauthLink : environment.azure.oauthLink}?idToken=${idToken}`;
  }

  public async unlinkMicrosoftAccount(): Promise<void> {
    console.log('TODO: what account are you disconnecting here?');
    // const idToken = await firebase.auth().currentUser.getIdToken();
    // await this.apiService.unlinkMicrosoftAccount(idToken, this.clientLinkedEmails.outlook).toPromise()
    //   .then((email) => email)
    //   .catch(err => console.error('Error occurred', err)); // TODO check if no sensitive data is printed out
  }

  public toggleTheme(event: { stopPropagation: () => void; }): void {
    event.stopPropagation();
    this.themeIcon = this.themeService.toggleTheme();
  }

  public onChangeOrgs(): void {
    if (this.selectedOrg !== undefined && this.user?.organizations && this.user.organizations.selected !== this.selectedOrg) {
      this.user.organizations.selected = this.selectedOrg;
      this.updateUserStoreDoc();
    }
  }

  private loadUserOrganizations(): void {
    this.organizationList = [];
    if (this.user.organizations != undefined) {
      this.selectedOrg = this.user.organizations.selected;
      for (const [key, value] of Object.entries(this.user.organizations)) {
        if (key != 'orgIds' && key != 'selected')
          this.organizationList.push({ display: value.name, value: key });
      }
      // console.log('loadUserOrganizations > organizationList:', this.organizationList)
    }
  }

  // TODO - move to service
  private loadProfileImage(user: User) {
    // console.log('loadProfileImage > user:', user);
    // this.fbAuth.currentUser.then((authUser) => {
    //   console.log('loadProfileImage > fbAuth.currentUser > user:', user);
    //   console.log('loadProfileImage > fbAuth.currentUser > authUser:', authUser);
    //   console.log('loadProfileImage > fbAuth.currentUser > authUser.photoURL:', authUser.photoURL);
    // });

    if (user.profileImage !== undefined) {
      const ref = this.storage.ref(`user/${user.uid}/profile/` + (user.profileImage.fileName ? user.profileImage.fileName : ''));
      ref.getDownloadURL().subscribe((url: any) => {
        this.photoUrl = url;
        // This might not be needed anymore since the service worker might be handling this all gucci...
        this.updatePhotoSrc().then().catch();
        // this.photoSrc = url;
      });
    } else if (user.preferences.photoURL !== undefined) {
      this.photoUrl = user.preferences.photoURL;
      // This might not be needed anymore since the service worker might be handling this all gucci...
      this.updatePhotoSrc().then().catch();
      // this.photoSrc = user.preferences.photoURL;
    }
  }

  private async updatePhotoSrc(): Promise<void> {
    // TODO implement caching in NGRX.
    if (!this.user?.uid) return;

    let preferences: any = localStorage.getItem('userPreferences');
    if (preferences) {
      preferences = JSON.parse(preferences);
    }

    if (!preferences || preferences.uid !== this.user.uid) {
      preferences = {
        uid: this.user.uid,
        photoUrl: null,
        photoSrc: null
      }
    }

    if (!preferences.photoSrc || preferences.photoUrl !== this.photoUrl) {
      await this.createPhotoSrc();
      preferences.photoSrc = this.photoSrc;
      preferences.photoUrl = this.photoUrl;
    }

    this.photoSrc = preferences.photoSrc;
    localStorage.setItem('userPreferences', JSON.stringify(preferences));
  }

  private createPhotoSrc(): Promise<void> {
    return this.fetchImage(this.photoUrl).then(data => {
      this.createImageFromBlob(data);
    }).catch((ex) => {
      console.error('createPhotoSrc > error:', ex);
    });
  }
  
  private fetchImage(imageUrl: string): Promise<Blob> {
    return this.httpClient.get(imageUrl, { responseType: 'blob' }).toPromise() as Promise<Blob>;
  }

  private createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      this.photoSrc = reader.result as string | ArrayBuffer;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }


  // creates the acronym for the user profile when image is missing
  // using the first letter of first name and last name when available
  // and making sure there are no spaces used
  private nameAcronym(firstName?: string, lastName?: string): string {
    const regex = /\s/g;
    const first = firstName !== undefined && firstName !== undefined ? firstName.toUpperCase().replace(regex, '').substring(0, 1) : '';
    const last = lastName !== undefined && lastName !== undefined ? lastName.toUpperCase().replace(regex, '').substring(0, 1) : '';

    if (first && last) return first + last;
    else if (first) return first;
    else if (last) return last;
    else return '';
  }

  public linkGmail() {
    (window as any).location = environment.gcp.oauthLink;
  }

  public signOut() {

    // If the user is offline, by signing out they will lose their un-synced data.
    this.networkService.networkStatus$
      .pipe(take(1))
      .subscribe((isOnline) => {
        // If the user is online, we can sign them out immediately.
        if (isOnline) {
          this.router.navigate(['logout']);
        }
        // If the user is offline, we need to show a dialog to confirm they want to sign out. 
        else if (confirm('You are currently offline, by signing out you will lose all your un-synced data. Are you sure you want to sign out?')) {
          this.router.navigate(['logout']);
        }

      });

  }

  public goToUserSettings(): void {
    const url = 'app/settings/account';
    this.router.navigate([url]);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
