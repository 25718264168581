import { createAction, props } from '@ngrx/store';
import { Workspace } from '@newgenus/common';



export const loadWorkspace = createAction(
  "[Workspace Resolver] Load Workspace Document",
);

export const allWorkspaceLoaded = createAction(
  "[Load Workspace Effect] All Workspace Loaded",
  props<{ workspace: Workspace[] }>()
);

export const workspaceAdded = createAction(
  "[Workspace] added",
  props<{ payload: Workspace }>()
);

export const workspaceRemoved = createAction(
  "[Workspace] removed",
  props<{ payload: Partial<Workspace> }>()
);

export const workspaceModified = createAction(
  "[Workspace] modified",
  props<{ payload: Partial<Workspace> }>()
);

export const workspaceUpdate = createAction(
  "[Workspace updating] updating Workspace",
  props<{ payload: Partial<Workspace> }>()
);

export const workspaceUpdated = createAction(
  "[Workspace] modified",
  props<{ payload: Partial<Workspace> }>()
);

// export const userUpdateSortOrder = createAction(
//   "[User] Update Sort Order",
//   props<{payload: Partial<User>}>()
// );




