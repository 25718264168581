import { MenuConfiguration, MenuItem } from "@newgenus/common";

export const emailPattern = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,63}$');
export const urlPattern = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;

export const standardDebounce = 300;
export const mediumDebounce = 500;
export const longDebounce = 1200;
export const shortSleeper = 1500;
export const standardSleeper = 2700;
export const defaultLocaleId = 1;

/**
 * This is the MenuConfiguration for the NewGenus application.
 * It is used to configure the menu items that are displayed in the
 * side menu.
 * 
 * @notes <br>
 * - 'sections' does not include the 'app' prefix, as this is implied.
 */
export const MenuConfigurations: MenuConfiguration = {
    'HOME': {
        header: 'Home',
        showFolders: false,
        topTierMenuItems: [
            { id: "workspace", name: 'WorkSpace', section: 'workspace', path: '', count: 0, showCount: false, icon: 'account_balance_wallet', isHighlighted: false, order: 11, },
            { id: "management-tools", name: 'Management Tools', section: 'management-tools', path: '', count: 0, showCount: false, icon: 'engineering', order: 12, isHighlighted: false, },
            { id: "organization", name: 'Organization', section: 'organization', path: '', count: 0, showCount: false, icon: 'corporate_fare', order: 13, isHighlighted: false, },
        ],
        middleTierMenuItems: [],
    },
    'WORKSPACE': {
        header: 'Workspace',
        showFolders: true,
        topTierMenuItems: [
            { id: "basket-1", name: 'Inbasket', section: 'workspace', path: 'inbasket', count: 0, showCount: true, svg: 'NEW-Icons-16.svg', isHighlighted: false, order: 21, },
            { id: "basket-2", name: 'Pending', section: 'workspace', path: 'pending', count: 0, showCount: true, svg: 'NEW-Icons-17.svg', isHighlighted: false, order: 22, },
            { id: "basket-3", name: 'Outbasket', section: 'workspace', path: 'outbasket', count: 0, showCount: true, svg: 'NEW-Icons-18.svg', isHighlighted: false, order: 23, }
        ],
        middleTierMenuItems: [
            { id: "basket-4", name: 'Filed', section: 'workspace', path: 'filed', count: 0, showCount: false, children: [], icon: 'task_alt', isHighlighted: false, order: 24, },
            { id: "basket-5", name: 'Search', section: 'workspace', path: 'search', count: 0, showCount: false, children: [], icon: 'search', isHighlighted: false, order: 25, },
        ],
    },
    'MANAGEMENT-TOOLS': {
        header: 'Management Tools',
        showFolders: false,
        topTierMenuItems: [
            { id: "management-tools-dashboard", name: 'Dashboard', section: 'management-tools', path: 'dashboard', count: 0, showCount: false, icon: 'dashboard', isHighlighted: false, order: 31, },
            { id: "checklist-overview", name: 'Checklist', section: 'management-tools', path: 'checklist', count: 0, showCount: false, svg: 'checklist.svg', isHighlighted: false, order: 32, },
            { id: "statistics", name: 'Statistics', section: 'management-tools', path: 'statistics', count: 0, showCount: false, icon: 'analytics', isHighlighted: false, order: 33, },
            { id: "client-abc", name: 'Client ABC', section: 'management-tools', path: 'client-abc', count: 0, showCount: false, icon: 'hotel_class', isHighlighted: false, order: 33, },
            { id: "refactored", name: 'Refactored', section: 'management-tools', path: 'refactor', count: 0, showCount: false, icon: 'terminal', isHighlighted: false, order: 34, },
        ],
        middleTierMenuItems: [],
    },
    'ORGANIZATION': {
        header: 'Organization',
        showFolders: false,
        topTierMenuItems: [
            { id: "dashboard", name: 'Dashboard', section: 'organization', path: 'dashboard', count: 0, showCount: false, icon: 'dashboard', isHighlighted: false, order: 41, },
            { id: "targets", name: 'Targets', section: 'organization', path: 'targets', count: 0, showCount: false, icon: 'multiline_chart', isHighlighted: false, order: 42, },
            { id: "hats", name: 'Hats', section: 'organization', path: 'hats', count: 0, showCount: false, icon: 'school', isHighlighted: false, order: 43, },
            // { id: "roles", name: 'Roles', section: 'organization', path: 'roles', count: 0, showCount: false, icon: 'badge', isHighlighted: false },
            { id: "users", name: 'Users', section: 'organization', path: 'users', count: 0, showCount: false, icon: 'supervisor_account', isHighlighted: false, order: 44, },
            { id: "designer", name: 'Designer', section: 'organization', path: 'designer', count: 0, showCount: false, icon: 'draw', isHighlighted: false, order: 45, },
        ],
        middleTierMenuItems: [
            { id: "dates", name: 'Dates Cycles', section: 'organization', path: 'dates', count: 0, showCount: false, icon: 'event', isHighlighted: false, order: 46, },
            // { id: "list", name: 'List', section: 'organization', path: 'list', count: 0, showCount: false, icon: 'list', isHighlighted: false, order: 46, },
            { id: "integrations", name: 'Integrations', section: 'organization', path: 'integrations', count: 0, showCount: false, icon: 'integration_instructions', isHighlighted: false, order: 47, },
            { id: "manage", name: 'Manage', section: 'organization', path: 'manage', count: 0, showCount: false, icon: 'settings_applications', isHighlighted: false, order: 48, },
            { id: "settings", name: 'Settings', section: 'organization', path: 'settings', count: 0, showCount: false, icon: 'settings', isHighlighted: false, order: 49, },
        ],
    }
}

export const MenuHome: MenuItem = {
    id: "home",
    name: 'Home',
    section: 'home',
    path: '',
    count: 0,
    showCount: false,
    icon: 'home',
    isHighlighted: false,
    order: 1,
}
