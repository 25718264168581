<mat-toolbar color="gray">
    <div class="d-flex" [ngClass]="{'p-fixed': isFocusMode}">
        <div class="flex-center">
            <button mat-icon-button aria-label="Expand main Menu" (click)="emitToggle()">
                <mat-icon>menu</mat-icon>
            </button>
            <span class="brand" routerLink="app/home" [ngClass]="{'fs-0': isSmallDisplay}">Newgenus</span>
        </div>
    </div>


    <span class="flex-spacer spacer">
        <span *ngIf="hasWorkspaceAccess" class="d-flex flex-items-center">
            <img src="/assets/icons/NEW-Icons-16.svg" class="filter-black svg-icon" alt="inbasket" title="Inbasket"/>
            <span [ngClass]="{'d-none': isSmallDisplay}" style="margin: 0 3px;">inbasket</span>
            <span [@valueAnimation]="inbasketCount" class="highlightCount" data-cy="inbasket-particle-count">{{inbasketCount}}</span>
            
            <img src="/assets/icons/NEW-Icons-17.svg" class="filter-black svg-icon" alt="pending" title="Pending"/>
            <span [ngClass]="{'d-none': isSmallDisplay}" style="margin: 0 3px;">pending</span>
            <span [@valueAnimation]="pendingCount" class="highlightCount" data-cy="pending-particle-count">{{pendingCount}}</span>
        </span>
    </span>

    <a href="mailto:support@newgenus.org" target="_blank" rel="noopener noreferrer" class="d-none d-sm-block" #supportBtn>
        <button class="support-btn" mat-icon-button aria-label="Contact Support" title="Contact Support">
            <mat-icon>contact_support</mat-icon>
        </button>
    </a>

    <mat-form-field *ngIf="security.isOrgAvailableForUser()"
            appearance="outline"
            class="reduced-density"
            color="accent" 
            id="org-selector">
        <mat-label>Organization</mat-label>
        <mat-select [(ngModel)]="selectedOrg" placeholder="Organization" (selectionChange)="onChangeOrgs()">
            <mat-option *ngFor="let org of organizationList" [value]="org.value">{{org.display}}</mat-option>
        </mat-select>
    </mat-form-field>

    <button class="menu-button" mat-icon-button aria-label="Account Menu" data-cy="profile-picture" [matMenuTriggerFor]="menu" placement="bottom-right">
        <mat-icon *ngIf="photoSrc" class="profile-icon-no-font">
            <img [attr.src]="photoSrc" alt="{{ userInitials }}">
        </mat-icon>
        <mat-icon *ngIf="!photoSrc" class="profile-icon">account_circle</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
        <div mat-menu-item class="flex-center pb-2">

            <span class="avatar profile-avatar" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img *ngIf="(photoSrc); else noProfileImg2" [attr.src]="photoSrc"
                    alt="{{( user.firstName || '') + (user.lastName || '') }}" />
                <ng-template #noProfileImg2>{{ userInitials }}</ng-template>
            </span>
            <span class="profile-name flex-center">{{ ((user.firstName || '') + ' ' + ( user.lastName || '')) | truncate : 20 }}</span>
        </div>


        <!-- <button mat-menu-item disabled>
            <span class="flex-center">{{user.email || ''}}</span>
        </button> -->

        <span *ngIf="user?.isDeveloper"
                mat-menu-item 
                (click)="$event.stopPropagation();" 
                class="menu-accordion">
            <mat-accordion mat-menu-item [displayMode]="'flat'" style="display: contents;">
                <mat-expansion-panel>
                    <mat-expansion-panel-header collapsedHeight="48px" style="padding: 0px 16px;">
                        <mat-panel-title><mat-icon>code</mat-icon>Dev Info</mat-panel-title>
                    </mat-expansion-panel-header>
                    
                    <button mat-menu-item disabled>
                        <span>Env: {{environment}}</span>
                    </button>
                    <button mat-menu-item disabled>
                        <span>Emulator: {{emulator}}</span>
                    </button>
                </mat-expansion-panel>
            </mat-accordion>
        </span>

        <button mat-menu-item role="menuitemcheckbox" type="button" data-cy="theme-toggle-btn"
            (click)="toggleTheme($event);">
            <mat-icon>{{ themeIcon }}</mat-icon>
            <span>Toggle Theme</span>
        </button>

        <!-- Account has links. -->
        <span *ngIf="hasWorkspaceAccess" 
                (click)="$event.stopPropagation();"
                mat-menu-item
                class="menu-accordion">
            <mat-accordion mat-menu-item [displayMode]="'flat'" style="display: contents;">
                <mat-expansion-panel>
                    <mat-expansion-panel-header collapsedHeight="48px" style="padding: 0px 16px;">
                        <mat-panel-title>
                            <mat-icon>{{isLinked ? 'link' : 'link_off'}}</mat-icon>Services
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <!-- Gmail -->
                    <button mat-menu-item (click)="isGmailLinked ? '' : linkGmailAccount();">
                        <mat-icon [ngClass]="{'green': isGmailLinked, 'yellow': !isGmailLinked}">{{isGmailLinked ? 'power' : 'power_off'}}</mat-icon>
                        <span>{{isGmailLinked ? 'Gmail Connected' : 'Connect Gmail'}}</span>
                    </button>

                    <!-- Outlook -->
                    <button mat-menu-item (click)="isOutlookLinked ? '' : linkMicrosoftAccount();">
                        <mat-icon [ngClass]="{'green': isOutlookLinked, 'yellow': !isOutlookLinked}">{{isOutlookLinked ? 'power' : 'power_off'}}</mat-icon>
                        <span>{{isOutlookLinked ? 'Outlook Connected' : 'Connect Outlook'}}</span>
                    </button>
                </mat-expansion-panel>
            </mat-accordion>
        </span>

        <button mat-menu-item type="button" (click)="supportBtn.click()">
            <mat-icon>contact_support</mat-icon>
            <span>Contact Support</span>
        </button>

        <button mat-menu-item type="button" data-cy="manage-account-btn" (click)="goToUserSettings()">
            <mat-icon>manage_accounts</mat-icon>
            <span>Manage Account</span>
        </button>
        
        <button *ngIf="user?.isDeveloper" mat-menu-item type="button" [routerLink]="['/styleguide/general']" data-cy="nav-styleguide">
            <mat-icon>format_shapes</mat-icon>
            <span>Style Guide</span>
        </button>

        <button mat-menu-item type="button" data-cy="logout-btn" (click)="signOut()">
            <mat-icon>logout</mat-icon>
            <span>Logout</span>
        </button>

        <button mat-menu-item disabled class="app-version">
            <span>Version: {{version.version || ''}}</span>
            <span class="float-right">{{version.raw || ''}}</span>
        </button>

    </mat-menu>

    <!-- Fetch the signatures in the same fashion the browser would, this is meant to assist the caching process. -->
    <div style="height: 0px; width: 0px;" *ngFor="let sig of invisibleSignatures">
        <img height="0" width="0" [attr.src]="sig">
    </div>

</mat-toolbar>