import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'newgenus-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  public emailValue: string | null = null;
  public passwordValue: string | null = null;
  public isOperationRunning = false;
  public errorInReset = false;
  public unrecognizedEmail = false;

  public form = this.fb.group({
    email: ['', [Validators.compose([Validators.required, Validators.email])]],
  });

  constructor(public fb: FormBuilder, private afs: AngularFireAuth, private router: Router) { }

  public async submit() {
    this.resetErrorFieldsAndDisableInputs();
    const email = this.form.get('email')?.value;

    if (email) {
      try {
        await this.afs.sendPasswordResetEmail(email);
        this.isOperationRunning = false;
        alert('Email sent - Please check your inbox and spam/junk folder for the password reset email.');
        this.router.navigate(['/login']);

      } catch (error) {
        this.handleErrorResponseAndEnableInputs(error);
      }
    }
  }

  private handleErrorResponseAndEnableInputs(error: any) {
    this.isOperationRunning = false;
    if (error?.code?.includes('user-not-found')) {
      this.unrecognizedEmail = true;
    } else {
      console.error('error', error);
      this.unrecognizedEmail = false;
      this.errorInReset = true;
    }
  }

  private resetErrorFieldsAndDisableInputs() {
    this.errorInReset = false;
    this.unrecognizedEmail = false;
    this.isOperationRunning = true;
  }
}
