import { Directive, ElementRef, HostListener, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subject, debounceTime } from 'rxjs';

@Directive({
    selector: '[sharedResizeObserver]',
})
export class ResizeObserverDirective implements OnInit, OnDestroy {

    @Output()
    public resizeObserverChange = new EventEmitter<{ width: number, height: number }>();

    private resizeObserver!: ResizeObserver;
    private debounceMonitor = new Subject<Array<number>>();

    constructor(private elementRef: ElementRef) { }

    public ngOnInit(): void {
        // Create a new observer instance and pass a callback function.
        this.resizeObserver = new ResizeObserver(entries => {
            // Loop through the entries array, this will only be 1 element.
            for (const entry of entries) {
                this.debounceMonitor.next([entry.contentRect.width, entry.contentRect.height]);
            }
        });

        // Observe the element.
        this.resizeObserver.observe(this.elementRef.nativeElement);

        // Subscribe to the debounce monitor, emitting the width and height of the element only after 300ms.
        this.debounceMonitor
            .pipe(debounceTime(300))
            .subscribe(update => {
                this.resizeObserverChange.emit({ width: update[0], height: update[1] });
            });
    }

    @HostListener('window:resize', ['$event'])
    public onResize(event: Event): void {
        // Update the observer on window resize
        this.resizeObserver.disconnect();
        this.resizeObserver.observe(this.elementRef.nativeElement);
    }

    public ngOnDestroy(): void {
        this.resizeObserver.unobserve(this.elementRef.nativeElement);
        this.debounceMonitor.unsubscribe();
    }
}