import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Particle, PendParticleResponse } from '@newgenus/common';
import { Component, Inject } from '@angular/core';

@Component({
    selector: 'newgenus-pend-particle-display-modal',
    templateUrl: './pend-particle-display-modal.component.html',
    styleUrls: ['./pend-particle-display-modal.component.scss'],
})
export class PendParticleDisplayModalComponent {
    public model: { year: number; month: number; day: number; }//NgbDateStruct;
    public date!: { year: number; month: number; };
    public time = { hour: 12, minute: 0 };
    public meridian = true;
    public noteText = '';
    private calendar: any; //NgbCalendar,

    constructor(
        // private calendar: NgbCalendar,
        public dialogRef: MatDialogRef<PendParticleDisplayModalComponent, PendParticleResponse>,
        @Inject(MAT_DIALOG_DATA) public particle: Particle
    ) {
        this.model = this.calendar.getToday();
        const today = new Date();
        const now = new Date(today.getTime() + 200000); // add 2 minutes to current time
        this.time = { hour: now.getHours(), minute: now.getMinutes() };
    }

    public selectPendingTime() {
        this.model = this.calendar.getToday();
        const today = new Date();
        const now = new Date(today.getTime() + 200000); // add 2 minutes to current time
        this.time = { hour: now.getHours(), minute: now.getMinutes() };
    }

    public setPendingTimePlusFiveMinutes() {
        const monthIndex = this.model.month !== 0 ? this.model.month - 1 : this.model.month;
        const oldDate = new Date(this.model.year, monthIndex, this.model.day, this.time.hour, this.time.minute, 0);
        const newDate = this.addMinutes(oldDate, 5); // add 2 minutes to current time
        const minute = newDate.getMinutes();
        const hour = newDate.getHours();
        this.time = { hour, minute };
    }

    public setPendingTimePlusThirtyMinutes() {
        const monthIndex = this.model.month !== 0 ? this.model.month - 1 : this.model.month;
        const oldDate = new Date(this.model.year, monthIndex, this.model.day, this.time.hour, this.time.minute, 0);
        const newDate = this.addMinutes(oldDate, 30); // add 2 minutes to current time
        const minute = newDate.getMinutes();
        const hour = newDate.getHours();
        this.time = { hour, minute };
    }

    public setPendingTimePlusOneDay() {
        // const date: NgbDate = new NgbDate(this.model.year, this.model.month, this.model.day);
        const date = new Date(this.model.year, this.model.month, this.model.day);
        this.model = this.calendar.getNext(date, 'd', 1);
    }

    public addMinutes(date: Date, minutes: number) {
        return new Date(date.getTime() + minutes * 60000);
    }

    public selectToday() {
        this.model = this.calendar.getToday();
        const currentTime = new Date();
        const now = new Date(currentTime.getTime() + 0); // add 2 minutes to current time
        this.time = { hour: now.getHours(), minute: now.getMinutes() };
    }

    public pendingParticle() {
        this.selectToday();
    }

    public toggleMeridian() {
        this.meridian = !this.meridian;
    }

    public submit(): void {
        const args: PendParticleResponse = {
            particle: this.particle,
            model: this.model,
            time: this.time,
            noteContent: this.noteText?.trim() ? this.noteText : undefined,
        };
        this.dialogRef.close(args);
    }
}
