<div class="login-wrapper">
  
    <div id="login-formContent">
    
        <div class="login-form">
          <form #searchForm="ngForm" (ngSubmit)="login()">
            <input [ngClass]="{'no-action': isLoginRunning}" [disabled]="isLoginRunning" name="username" data-cy="username" class="fadeIn second" id="username" type="text" [(ngModel)]="usernameValue" placeholder="username" />
            <input [ngClass]="{'no-action': isLoginRunning}" [disabled]="isLoginRunning" name="password" data-cy="password" class="fadeIn third" id="password" type="password"  placeholder="password" [(ngModel)]="passwordValue"/>
            <input [ngClass]="{'no-action': isLoginRunning}" [disabled]="isLoginRunning" class="fadeIn fourth" data-cy="submit" type="submit" value="Log In" id="submit"/>
          </form>
        </div>

        <div class="login-error-msg" *ngIf="errorLoggingIn">
            <span>Login failed</span>
        </div>

      <div id="formFooter">
        <img [ngClass]="{'no-action': isLoginRunning}" (click)="loginViaGoogle()" class="cliackable" data-cy="loginViaGoogle" width="250" src="/assets/images/branding/Google/2x/btn_google_signin_dark_normal_web@2x.png" alt="Sign in with Google">
        <img [ngClass]="{'no-action': isLoginRunning}" (click)="loginViaMicrosoft()" class="cliackable" data-cy="loginViaMicrosoft" width="243" src="/assets/images/branding/Microsoft/ms-symbollockup_signin_light.svg" alt="Sign in with Microsoft">

        <!-- <span><button (click)="loginViaGoogle($event)" data-cy="loginViaGoogle" class="otherloggin">Google Login</button></span> -->
        <br />
        <br />
        <span><a class="underlineHover" routerLink="/forgot-password">Forgot Password?</a></span>

      </div>

  </div>
</div>





