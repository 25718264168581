import { Subscription, merge, fromEvent, of, map } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';


@Injectable()
export class NetworkService implements OnDestroy {

  /** TRUE = online, FALSE = offline; */
  public networkStatus: boolean = navigator.onLine;

  /** TRUE = online, FALSE = offline; */
  public networkStatus$ = merge(
    of(null),
    fromEvent(window, 'online'),
    fromEvent(window, 'offline')
  ).pipe(map(() => navigator.onLine));

  private networkStatusSub$: Subscription =
    this.networkStatus$.subscribe(status => {
      this.networkStatus = status;
    });

  // constructor() {
  // this.checkNetworkStatus();
  // }

  public unsubscribe(): void {
    this.networkStatusSub$.unsubscribe();
  }

  // private checkNetworkStatus() {
  // this.networkStatus = navigator.onLine;
  // this.networkStatus$ = merge(
  //   of(null),
  //   fromEvent(window, 'online'),
  //   fromEvent(window, 'offline')
  // )
  //   .pipe(map(() => navigator.onLine));

  // this.networkStatusSub$ = this.networkStatus$.subscribe(status => {
  //   this.networkStatus = status;
  // });
  // }

  public ngOnDestroy(): void {
    this.unsubscribe(); // Cleanup/Unsubscribe.
  }

}