<h4 class="pt-2">{{dashboardName | titlecase}} <button mat-icon-button class="add-grow-btn" color="accent"
        (click)="openAddWidgetDialog()"
        aria-label="Button to add a new widget to the dashboard"><mat-icon>add_circle</mat-icon></button>
</h4>

<ng-container *ngIf="widgets.length === 0; then dashboardWithoutContent; else dashboardWithContent"></ng-container>
<ng-template #dashboardWithContent>

    <div class="grid-container" style>
        <mat-grid-list [cols]="breakpoint" rowHeight="1:1" gutterSize="1rem">

            <mat-grid-tile *ngFor="let widget of widgets; let i= index;"
                [colspan]="widget.settings.columns > breakpoint ? breakpoint : widget.settings.columns"
                [rowspan]="widget.settings.rows > breakpoint ? breakpoint : widget.settings.rows"
                sharedResizeObserver
                (resizeObserverChange)="onWidgetResize($event, widget)"
            >
                <!-- [colspan]="widget.settings.expanded || (widget.settings.columns > breakpoint) ? breakpoint : widget.settings.columns"
                [rowspan]="widget.settings.expanded || (widget.settings.rows > breakpoint) ? breakpoint : widget.settings.rows"> -->

                <cdk-drop-list (cdkDropListDropped)="drop($event)" [cdkDropListConnectedTo]="drops"
                    [cdkDropListData]="i" #content [ngClass]="{'is-dragging': isDragging}"
                    class="widget-card-list">

                    <mat-card class="widget-card"
                        cdkDrag
                        [cdkDragData]="i" 
                        (cdkDragEntered)="entered($event)"
                        (cdkDragStarted)="dragStarted()" 
                        (cdkDragEnded)="dragEnded()"
                    >
                        <div class="widget-placeholder" #placeholder *cdkDragPlaceholder></div>

                        <mat-card-title class="widget-title"><span>{{widget.title}}</span><div class="btn-container">
                                <mat-icon class="drag-handle" cdkDragHandle>drag_indicator</mat-icon>
                                
                                <button mat-icon-button aria-label="A button to expand quick options for the widget"
                                    class="quick-opts"
                                ><mat-icon>expand_more</mat-icon></button>
                                
                                <button mat-icon-button aria-label="Button to open the widget's settings"
                                    [matMenuTriggerFor]="menu" (click)="updateMenuOptions(widget)"
                                ><mat-icon>settings</mat-icon></button>

                            </div>
                        </mat-card-title>
                        <span class="widget-subtitle" *ngIf="widget.subtitle">{{widget.subtitle}}</span>

                        <mat-card-content class="h-100 w-100" [ngClass]="{'w-minus-padding': widget.type === 'daily7r' || widget.type === 'monthly7r'}">
                            <div class="center-above-widget loader-backdrop" *ngIf="widget.isLoading || widget.hasError">
                                <mat-spinner *ngIf="widget.isLoading" class="spinner" diameter="50"></mat-spinner>
                                <div *ngIf="widget.hasError" class="error-info">

                                    <span [ngSwitch]="widget.typeOfError">
                                    <div *ngSwitchCase="'NOT_DATE_PROFILE'">
                                        <p class="error-text">
                                            The selected date profile for this widget is not available.
                                            Please select a different date profile by adjusting the settings.
                                        </p>
                                        <button mat-raised-button color="primary" (click)="openSettingsDialogForWidget(widget)">
                                            <mat-icon>settings</mat-icon> Open Settings
                                        </button>
                                    </div>
                                    <p *ngSwitchDefault class="error-text mt-2">
                                        There was an error loading the widget. Please try again later.
                                    </p>
                                    </span>

                                </div>
                            </div>
                            <shared-dashboard-intermediate
                                [data]="widget.data"
                                [type]="widget.type"
                                [refreshScale]="refreshScale"
                                (clientAbcGroupExpand)="onClientAbcGroupExpand($event, widget)"
                                (fromDateChange)="fromDate = $event; onFromOrToDateChange(widget)"
                                (toDateChange)="toDate = $event; onFromOrToDateChange(widget)"
                            ></shared-dashboard-intermediate>
                        </mat-card-content>
                    </mat-card>

                </cdk-drop-list>
            </mat-grid-tile>
        </mat-grid-list>
    </div>

</ng-template>

<ng-template #dashboardWithoutContent>
    <!-- Show a template dashboard using mat-cards -->
    <div class="dashboard-empty px-2 py-2">
        <mat-card>
            <mat-card-content>
                <p>There are no widgets on this dashboard. Click the plus button on to the right, above this message to
                    get started.</p>
            </mat-card-content>
        </mat-card>
    </div>
</ng-template>



<!-- Dynamically generated menu. -->
<mat-menu #menu="matMenu">

    <ng-container *ngFor="let item of menuOptions">

        <!-- Embedding menu option. -->
        <ng-container *ngIf="item.subMenuItems; else standardOptions">
            <button mat-menu-item (click)="item.action()" [disabled]="item.disabled"
                [matMenuTriggerFor]="secondaryMenu">
                <mat-icon [color]="item.colour">{{item.icon}}</mat-icon>
                <span>{{item.display}}</span>
            </button>

            <!-- Regular button menu option. -->
            <mat-menu #secondaryMenu="matMenu">
                <button *ngFor="let item of item.subMenuItems" mat-menu-item
                    [ngClass]="{'item-bold': item.selected}"
                    (click)="item.action()">{{item.display}}</button>
            </mat-menu>
        </ng-container>

        <!-- Regular button and icon menu option. -->
        <ng-template #standardOptions>
            <button mat-menu-item (click)="item.action()" [disabled]="item.disabled">
                <mat-icon [color]="item.colour">{{item.icon}}</mat-icon>
                <span>{{item.display}}</span>
            </button>
        </ng-template>

    </ng-container>

</mat-menu>