import { AuthService } from '../../shared/services/auth.service';
import { Component } from '@angular/core';

@Component({
  selector: 'logout',
  template: `
  <div class="logout-wrapper">
    <div style="color: black;">
      Signing out...
    </div>
  </div>`,
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {

  constructor(public auth: AuthService) {
    this.auth.isLoggedIn = false;
    setTimeout(() => this.auth.signOut(), 1000);
  }

}
