import { NewgenusRoute } from '@newgenus/common';
import { createAction, props } from '@ngrx/store';

export const loadRoutes = createAction('[Routes Resolver] Load Routes Document');

export const allRoutesLoaded = createAction('[Load Routes Effect] All Routes Loaded', props<{ routes: NewgenusRoute[] }>());

export const routeAdded = createAction('[Route] added', props<{ payload: NewgenusRoute }>());

export const routeRemoved = createAction('[Route] removed', props<{ payload: Partial<NewgenusRoute> }>());

export const routeModified = createAction('[Route] modified', props<{ payload: Partial<NewgenusRoute> }>());

export const routeUpdate = createAction('[Route updating] updating Route', props<{ payload: Partial<NewgenusRoute> }>());

/**
 * This is emitted when we handle the flow of data manually in the routes effects.
 */
 export const newRouteDataProcessed = createAction(
    '[Routes Effect] New Route Data Processed'
);