/* eslint-disable no-async-promise-executor */

import { clearAllParticles, loadAllParticles, particleDraftAdded, particlePend, particlePendAndSend, particleRemoved, particleUpdate, particleUpdateLabels, updateDraftParticleAttachments } from '../../redux/particles/particles.actions';
import { Folder, Particle, ParticleTask, PendParticleResponse, User, constructFirebaseDateFromSeconds, deepCopy, firestoreTimestampNow, parseEmbeddedTimestampsToFirebaseDate } from '@newgenus/common';
import { Observable, BehaviorSubject, Subject, map, switchMap, of, take, from } from 'rxjs';
import { updateUserParticleSort, userUpdate } from '../../redux/user/user.actions';
import { ParticleHelper } from '../../shared/utils/helpers/particle-helper';
import { selectWorkspace } from '../../redux/workspace/workspace.selectors';
import { workspaceUpdated } from '../../redux/workspace/workspace.actions';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { EmailHelper } from '../utils/helpers/email-helper';
import { CypressTestData } from '../data/cypress-test-data';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { StoreUser } from '../../workspace/user.abstract';
import { Timestamp } from 'firebase/firestore';
import { AuthService } from './auth.service';
import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import firebase from 'firebase/compat/app';
import { v4 as uuidv4 } from 'uuid';

@Injectable({ providedIn: 'root' })
export class ParticleService extends StoreUser {
    // -- Observables --
    public userParticle$!: BehaviorSubject<any>;
    public updateLocalLabelsMap$: BehaviorSubject<any>;
    public userFolders$: Observable<any>;
    public userParticles$: any;
    public userFoldersSubject$!: Observable<Folder[]>;

    public userCachedRecipient$: Observable<any>;
    public cachedParticle$: any;
    public sideBarInstantiated$ = new Subject<boolean>();
    private destroy$ = new Subject<void>();

    // --- Infinite Scrolling --- //
    public inbasket_offset: any = constructFirebaseDateFromSeconds(new Date().getTime() / 1000);
    public inbasket_batch: any = 20;
    public pendingBasket_batch: any = 20;

    public pending_offset: any = null;
    public filed_offset: any = constructFirebaseDateFromSeconds(new Date().getTime() / 1000);

    public scrollIndex = 0;
    public particleIndex = 0;
    public offset: any = null;

    // -- subscription --

    // --- collections -- //
    public folders: any[] = [];
    public displayList: Particle[] = [];
    public filingTools: any[];
    public basketTools: any[];
    public particles: any;
    private fieldToBeDefinedAtAllTime: string[];
    public particlesToRemove!: any[];

    // -- models --
    public outbasket_batch = 20;
    public filedbasket_batch = 20;

    public inbasketCount = 0;
    public pendingCount = 0;

    public listOfNewParticlesKeys = new Set();

    public inbasketParticles: Particle[] = [];
    public pendingBasketParticles: Particle[] = [];
    public outBasketParticles: Particle[] = [];
    public filedBasketParticles: Particle[] = [];
    public foldersBasketParticles: Particle[] = [];

    // infinite scroll variables ends -- //

    constructor(
        public auth: AuthService,
        private afs: AngularFirestore,
        public apiService: ApiService,
        public afAuth: AngularFireAuth,
        public emailHelper: EmailHelper,
        private cypressData: CypressTestData,
        private particleHelper: ParticleHelper,
        public override store: Store
    ) {
        super(store);
        this.initStoreUser(this.destroy$);

        (<any>window)['stubParticleService'] = {
            returnMockParticles: () => {
                this.userParticles$ = new BehaviorSubject<any>(this.cypressData.particles);
            },
        };

        // These are deprecated SideBar navigation items - these are left here are they are strongly coupled throughout the application and are rather static.
        this.basketTools = [
            {
                id: 'basket-1',
                name: 'Inbasket',
                section: 'workspace',
                count: 0,
                icon: 'NEW-Icons-16.svg',
                particles: [],
            },
            {
                id: 'basket-2',
                name: 'Pending',
                section: 'workspace',
                count: 0,
                icon: 'NEW-Icons-17.svg',
                particles: [],
            },
            {
                id: 'basket-3',
                name: 'Outbasket',
                section: 'workspace',
                count: 0,
                icon: 'NEW-Icons-18.svg',
                particles: [],
            },
        ];

        // These are deprecated SideBar navigation items - these are left here are they are strongly coupled throughout the application and are rather static.
        this.filingTools = [
            {
                id: 'basket-4',
                name: 'Filed',
                icon: 'task_alt',
                section: 'workspace',
                count: 0,
                particles: [],
                children: [],
            },
            {
                id: 'basket-5',
                name: 'Search',
                icon: 'search',
                section: 'workspace',
                count: 0,
                particles: [],
                children: [],
            },
        ];

        this.fieldToBeDefinedAtAllTime = [
            'uid',
            'id',
            'from',
            'to',
            'labelIds',
            'creationDate',
            'internalDate',
            'threadId',
            'particleKey',
            'particleType',
        ];

        this.userFolders$ = this.afAuth.authState
            .pipe(switchMap(user => {
                if (user) {
                    const uid: string = user.uid;
                    return this.afs
                        .collection(`workspace/${uid}/folders`)
                        .valueChanges()
                        .pipe(
                            map(
                                (docs) => {
                                    const folders: any[] = [];
                                    docs.forEach((folder) => {
                                        folders.push(folder);
                                    });
                                    return folders;
                                },
                                (error: any) => {
                                    // Handle error here
                                    // Show popup with errors or just console.error
                                    console.error('error userFolders$: ', error);
                                }
                            )
                        );
                } else {
                    return of(null);
                }
            }));

        //// Get auth data, then get firestore particle collection & document || null
        this.userParticles$ = this.afAuth.authState
            .pipe(switchMap(user => {
                if (user) {
                    const uid: string = user.uid;
                    return this.afs
                        .collection(`workspace/${uid}/particles`)
                        .snapshotChanges()
                        .pipe(
                            map(
                                (docs) => {
                                    const particles: any[] = [];
                                    docs.forEach((particleDoc: any) => {
                                        const particle = particleDoc.payload.doc.data();
                                        if (particle.particleKey === '') {
                                            particle.particleKey = particleDoc.payload.doc.id;
                                            this.updateParticleKey(particle);
                                        }
                                        particles.push(particle);
                                    });

                                    return particles;
                                },
                                (error: any) => {
                                    // Handle error here
                                    // Show popup with errors or just console.error
                                    console.error('error userParticles$: ', error);
                                }
                            )
                        );
                } else {
                    return of(null);
                }
            }));

        //// Get auth data, then get firestore cachedEmails collection & document || null
        this.userCachedRecipient$ = this.afAuth.authState
            .pipe(switchMap(user => {
                if (user) {
                    return this.afs
                        .collection(`workspace/${user.uid}/cachedRecipients`)
                        .valueChanges()
                        .pipe(
                            map(
                                (docs) => {
                                    const recipients: any[] = [];
                                    docs.forEach((recipient) => {
                                        recipients.push(recipient);
                                    });
                                    return recipients;
                                },
                                (error: any) => {
                                    // Handle error here
                                    // Show popup with errors or just console.error
                                    console.error('error userCachedRecipient$: ', error);
                                }
                            )
                        );
                } else {
                    return of(null);
                }
            }));

        // this.userProfile$ = this.afAuth.authState
        //     .pipe(switchMap(user => {
        //         if (user) {
        //             const uid: string = user.uid;
        //             return this.afs
        //                 .collection('users')
        //                 .doc(`${uid}`)
        //                 .valueChanges()
        //                 .pipe(
        //                     map(
        //                         (docs) => {
        //                             if (docs !== undefined && (<any>docs).preferences !== undefined) {
        //                                 const users: User[] = [];
        //                                 this.user = docs as User;
        //                                 this.updateAccountsLinked();

        //                                 // check if preferences exist first 
        //                                 // preferences
        //                                 this.localSidebarStatus$.next(this.user.preferences?.sidebarStatus ?? false);
        //                                 users.push(this.user);
        //                                 return users as any;
        //                             } else {
        //                                 return of(null);
        //                             }
        //                         },
        //                         (error: any) => {
        //                             // Handle error here
        //                             // Show popup with errors or just console.error
        //                             console.error('error userProfile$: ', error);
        //                         }
        //                     )
        //                 );
        //         } else {
        //             return of(null);
        //         }
        //     }));

        this.store.pipe(select(selectWorkspace))
            .subscribe((workspace: any) => {
                this.inbasketCount = workspace.length > 0 ? workspace[0].inbasketCount : this.inbasketCount;
                this.pendingCount = workspace.length > 0 ? workspace[0].pendingCount : this.pendingCount;
            });

        this.cachedParticle$ = new BehaviorSubject<Particle | null>(null);
        this.updateLocalLabelsMap$ = new BehaviorSubject<any>(null);
    }

    private updateAccountsLinked() {
        const carbonUser: User = this.user; //, availableClients: Array<ClientOption> = [];

        if (carbonUser.linkedEmails) {
            for (const emailKey in carbonUser.linkedEmails)
                if (Object.prototype.hasOwnProperty.call(this.user.linkedEmails, emailKey)) {
                    if (carbonUser.linkedEmails[emailKey]['gmail'] && carbonUser.linkedEmails[emailKey]['gmail'].linked) {
                        this.isGmailLinked = true;
                        // availableClients.push({ display: `Gmail <${emailKey}>`, client: 'gmail', emailKey: emailKey });
                    }
                    if (carbonUser.linkedEmails[emailKey]['outlook'] && carbonUser.linkedEmails[emailKey]['outlook'].linked) {
                        this.isOutlookLinked = true;
                        // availableClients.push({
                        //     display: `Outlook <${emailKey}>`,
                        //     client: 'outlook',
                        //     emailKey: emailKey,
                        // });
                    }
                }
        }

        // this.clientOptions = [];
        // availableClients.forEach((x) =>
        //     this.clientOptions.push({
        //         display: x.display,
        //         value: x.client + '_' + x.emailKey,
        //         emailKey: x.emailKey,
        //         client: x.client,
        //     })
        // );
    }

    // ------------------------ Get baskets Ready ends -------------------------- //

    // --- particles -- //

    cacheParticle(particle: Particle) {
        this.cachedParticle$.next(particle);
    }

    getCachedParticle() {
        return this.cachedParticle$.asObservable();
    }

    extractContentFromEmail(emailContent: any) {
        let htmlContent = '';

        if (emailContent.data) {
            // Gmail response
            const payload = emailContent.data.payload; //Production
            if (payload) {
                htmlContent = this.emailHelper.extractContentFromPart(payload);
            }
        } else if (emailContent && emailContent.content) {
            // Outlook response
            htmlContent = emailContent.content;
        }

        return htmlContent;
    }

    public cacheAttachment(idToken: string, particle: Partial<Particle>, attachmentId: string) {
        return this.apiService.downloadAttachment(idToken, particle as Particle, attachmentId);
    }

    async fetchMessageFromServer(particle: Particle) {
        try {
            if (!particle.emailId) throw new Error('No emailId found in particle');
            const idToken = await firebase.auth().currentUser?.getIdToken() as string;
            return await this.apiService
                .fetchEmailContent(idToken, particle.emailId, particle.particleType, particle.clientEmailAddress)
                .toPromise()
                .then((email) => {
                    return email;
                })
                .catch((err) => {
                    console.error('Error occurred', err); // TODO check if no sensitive data is printed out
                });
        } catch (error) {
            console.error('Error occurred', error); // TODO check if no sensitive data is printed out
        }
    }

    updateParticleWithResponseInFirestore(particle: Particle): any {
        const uid = particle.uid;
        // add the3 particle key as well
        for (let i = 0; i < particle.labelIds.length; i++) {
            const labelId = particle.labelIds[i];
            this.afs
                .collection(`workspace/${uid}/particles`)
                .doc(particle.particleKey)
                .set(
                    {
                        particleKey: particle.particleKey,
                        id: particle.id,
                        threadId: particle.threadId,
                        labelIds: firebase.firestore.FieldValue.arrayUnion(labelId),
                    },
                    { merge: true }
                )
                .then()
                .catch();
        }
    }

    updateDraftParticleInFirestoreWithGmailApiResponse(particle: Particle): any {
        const uid = particle.uid;

        this.fetchMessageFromServer(particle).then((emailContent: string) => {
            particle.content = this.extractContentFromEmail(emailContent);

            this.afs
                .collection(`workspace/${uid}/particles`)
                .doc(particle.particleKey)
                .update({
                    id: particle.id,
                    content: particle.content ? particle.content : '',
                })
                .then()
                .catch();
        });
    }

    updateParticleWithCustomFolders(particle: Particle, customFolderId: string) {
        const folderId = customFolderId;
        const folderName = customFolderId.replace('custom-', '');

        particle.folders[folderId] = folderName;
        const updatedParticle = this.updateParticleViaStore(particle);
    }

    removeCustomFolderInParticle(particle: Particle, folder: any) {
        if (particle.folders[folder.id]) {
            delete particle.folders[folder.id];
            this.updateParticleFolderViaStore(particle);
        }
    }

    updateCustomFolderInParticle(particle: Particle, oldFolder: Folder, newFolder: Folder) {
        if (particle.folders[oldFolder.id]) {
            delete particle.folders[oldFolder.id];

            if (particle.folders) {
                particle.folders[newFolder.id] = newFolder.name;
            } else {
                particle.folders = {};
                particle.folders[newFolder.id] = newFolder.name;
            }

            this.updateParticleFolderViaStore(particle);
        }
    }

    updateCustomFolder(data: Folder) {
        this.afAuth.authState
            .pipe(switchMap((user) => {
                if (user) return from(this.afs.collection('workspace')
                    .doc(`${user.uid}`)
                    .collection('folders')
                    .doc(`${data.folderKey}`)
                    .update(data));

                else return of(null);
            }))
            .pipe(take(1))
            .subscribe();

        // if (folderCollection) {
        //     folderCollection.pipe(takeUntil(this.destroy$))
        //         .subscribe((doc) => {
        //             doc.update(data);
        //         });
        // }
    }

    /**
     * @deprecated update via the store.
     */
    UpdateParticleDisplayQuantity(quantity: number, uid: any) {
        this.afs.collection('users').doc(`${uid}`).update({
            'preferences.particleQuantityToDisplay': quantity,
        });
    }

    updateUserParticleLabelSort(preferences: any) {
        this.store.dispatch(
            userUpdate({
                payload: preferences,
            })
        );
    }

    updateUserParticleSort(preferences: any) {
        this.store.dispatch(
            clearAllParticles({
                payload: null,
            })
        );
        this.store.dispatch(
            updateUserParticleSort({
                payload: preferences,
            })
        );
    }

    updateTypeOfSort(sortType: string, uid: string) {
        this.afs.collection('users').doc(`${uid}`).update({
            'preferences.typeOfSort': sortType,
        });
    }

    updateDateSortDirection(sortDirection: string, uid: string) {
        this.afs.collection('users').doc(`${uid}`).update({
            'preferences.dateSorting': sortDirection,
        });
        this.store.dispatch(
            clearAllParticles({
                payload: null,
            })
        );
        this.store.dispatch(loadAllParticles());
    }

    loadParticleBehaviorSubject() {
        return new BehaviorSubject<any>(this.particles);
    }

    public updateParticleLabels(particle: any, labelsToREMOVE: string[], labelsToADD: string[]) {
        const labelsSnapshot = particle.labelIds;

        for (const label of labelsToREMOVE) {
            const labelIndex = labelsSnapshot.indexOf(label);
            if (~labelIndex) labelsSnapshot.splice(labelIndex, 1);
        }

        for (const label of labelsToADD) {
            labelsSnapshot.push(label);
        }

        const cleanArray = [...new Set(labelsSnapshot)]; // remove duplicates
        particle.labelIds = cleanArray;
        return { ...particle };
    }

    public pendParticleViaStore(
        pendModalResponse: PendParticleResponse,
        // inbasketCount: number,
        // pendingCount: number,
        labelsToREMOVE?: any,
        labelsToADD?: any
    ): void {
        const particle = deepCopy(pendModalResponse.particle);
        const model = pendModalResponse.model;
        const time = pendModalResponse.time;
        const noteContent = pendModalResponse.noteContent;
        let newInbasketCount = this.inbasketCount,
            newPendingCount = this.pendingCount;

        let localLabelsToREMOVE = ['INBASKET', 'DONE'];
        let localLabelsToADD = ['PENDING'];
        if (labelsToREMOVE !== undefined && labelsToREMOVE !== null) {
            localLabelsToREMOVE = localLabelsToREMOVE.concat(labelsToREMOVE);
        }
        if (labelsToADD !== undefined && labelsToADD !== null) {
            localLabelsToADD = localLabelsToADD.concat(labelsToADD);
        }

        if (particle.labelIds.includes('DONE')) {
            // TODO: comment in when filedCount is tracked
            // newFiledCount = filedCount - 1;
        } else if (particle.labelIds.includes('INBASKET')) {
            newInbasketCount = this.inbasketCount - 1;
        }

        newPendingCount = this.pendingCount + 1;

        const newParticle = this.updateParticleLabels(particle, localLabelsToREMOVE, localLabelsToADD);
        this.store.dispatch(
            particlePend({
                payload: {
                    labelIds: newParticle.labelIds,
                    tasks: newParticle.tasks,
                    id: newParticle.particleKey,
                    notes: newParticle.notes,
                },
                pendingTaskSchedule: {
                    date: model,
                    time: time,
                    noteContent: noteContent,
                    uid: particle.uid,
                    particleId: newParticle.particleKey,
                },
            })
        );

        this.store.dispatch(
            workspaceUpdated({
                payload: {
                    inbasketCount: newInbasketCount,
                    pendingCount: newPendingCount,
                    id: particle.uid,
                },
            })
        );
    }

    public pendAndSendParticleViaStore(
        pendModalResponse: PendParticleResponse,
        // inbasketCount: number,
        // pendingCount: number,
        labelsToREMOVE?: any,
        labelsToADD?: any
    ): void {
        const particle = deepCopy(pendModalResponse.particle);
        const model = pendModalResponse.model;
        const time = pendModalResponse.time;
        const noteContent = pendModalResponse.noteContent;
        let newInbasketCount = this.inbasketCount,
            newPendingCount = this.pendingCount;

        let localLabelsToREMOVE = ['INBASKET', 'DONE'];
        let localLabelsToADD = ['PENDING'];
        if (labelsToREMOVE !== undefined && labelsToREMOVE !== null) {
            localLabelsToREMOVE = localLabelsToREMOVE.concat(labelsToREMOVE);
        }
        if (labelsToADD !== undefined && labelsToADD !== null) {
            localLabelsToADD = localLabelsToADD.concat(labelsToADD);
        }

        if (particle.labelIds.includes('DONE')) {
            // TODO: comment in when filedCount is tracked
            // newFiledCount = filedCount - 1;
        } else if (particle.labelIds.includes('INBASKET')) {
            newInbasketCount = this.inbasketCount - 1;
        }

        newPendingCount = this.pendingCount + 1;

        const newParticle = this.updateParticleLabels(particle, localLabelsToREMOVE, localLabelsToADD);
        this.store.dispatch(
            particlePendAndSend({
                payload: {
                    labelIds: newParticle.labelIds,
                    tasks: newParticle.tasks,
                    content: newParticle.content,
                    particleType: newParticle.particleType,
                    id: newParticle.particleKey,
                    notes: newParticle.notes,
                },
                pendingTaskSchedule: {
                    date: model,
                    time: time,
                    noteContent: noteContent,
                    uid: particle.uid,
                    particleId: newParticle.particleKey,
                },
            })
        );

        this.store.dispatch(
            workspaceUpdated({
                payload: {
                    inbasketCount: newInbasketCount,
                    pendingCount: newPendingCount,
                    id: particle.uid,
                },
            })
        );
    }

    public fileParticleViaStore(
        particle: Particle,
        inbasketCount: number,
        pendingCount: number,
        completeStatus: boolean = false
    ): void {
        const particleCopy = deepCopy(particle);
        let newInbasketCount = inbasketCount,
            newPendingCount = pendingCount;
        const labelsToREMOVE = ['INBASKET', 'PENDING'];
        const labelsToADD = ['DONE'];
        let payload: any = {};

        if (particle.labelIds.includes('INBASKET')) {
            newInbasketCount = inbasketCount - 1;
        } else if (particle.labelIds.includes('PENDING')) {
            newPendingCount = pendingCount - 1;
        }

        // TODO: comment in when filedCount is tracked
        // newFiledCount = filedCount + 1;

        const newParticle = this.updateParticleLabels(particleCopy, labelsToREMOVE, labelsToADD);

        if (particleCopy.particleType === 'checklist')
            payload = {
                isComplete: completeStatus,
                dateCompleted: completeStatus ? firestoreTimestampNow() : null,
                alerting: false,
            };

        payload.id = particleCopy.particleKey;
        payload.labelIds = newParticle.labelIds;
        payload.filedDate = Timestamp.fromDate(new Date());

        payload.filedDate.nanoseconds = 0;

        this.dispatchUpdateLabels(payload);

        this.store.dispatch(
            workspaceUpdated({
                payload: {
                    inbasketCount: newInbasketCount,
                    pendingCount: newPendingCount,
                    id: particle.uid,
                },
            })
        );
    }

    public moveParticleToInbasketViaStore(particle: Particle, inbasketCount: number, pendingCount: number): void {
        const particleCopy = deepCopy(particle);
        let newInbasketCount = inbasketCount,
            newPendingCount = pendingCount;
        const labelsToREMOVE = ['DONE', 'PENDING'];
        const labelsToADD = ['INBASKET'];
        const payload: any = {};

        // TODO: comment in when filedCount is tracked
        // if (particle.labelIds.includes('DONE')) {
        //   newFiledCount = filedCount - 1;
        // } else
        if (particle.labelIds.includes('PENDING')) {
            newPendingCount = pendingCount - 1;
        }
        newInbasketCount = inbasketCount + 1;

        const newParticle = this.updateParticleLabels(particleCopy, labelsToREMOVE, labelsToADD);
        payload.id = particleCopy.particleKey;
        payload.labelIds = newParticle.labelIds;

        this.dispatchUpdateLabels(payload);

        this.store.dispatch(
            workspaceUpdated({
                payload: {
                    inbasketCount: newInbasketCount,
                    pendingCount: newPendingCount,
                    id: particle.uid,
                },
            })
        );
    }

    private dispatchUpdateLabels(payload: { alerting: boolean; id: string; }): void {
        this.store.dispatch(
            particleUpdateLabels({
                payload: payload,
            })
        );
    }

    public async unPendAndFileParticleViaStore(particle: Particle) {
        const copyOfparticle = deepCopy(particle) as Particle;
        const labelsToADD = ["DONE"]
        const labelsToREMOVE = ["PENDING"];
        const newParticle = this.updateParticleLabels(copyOfparticle, labelsToREMOVE, labelsToADD);
        newParticle.tasks = parseEmbeddedTimestampsToFirebaseDate(newParticle.tasks);

        this.store.dispatch(particleUpdate({
            payload: {
                labelIds: newParticle.labelIds,
                tasks: newParticle.tasks,
                id: newParticle.particleKey,
            },
        }));

        const newPendingCount = this.pendingCount - 1;

        this.store.dispatch(workspaceUpdated({
            payload: {
                pendingCount: newPendingCount,
                id: particle.uid,
            },
        }));
    }

    public addNewLabel(includedLabels: string[], label: string) {
        includedLabels.push(label);
        this.updateIncludedLabels(includedLabels);
    }

    public deleteLabel(includedLabels: string[], label: string) {
        includedLabels.splice(includedLabels.indexOf(label), 1);
        this.updateIncludedLabels(includedLabels);
    }

    public updateIncludedLabels(includedLabels: string[]) {
        return this.afs.collection('users').doc(`${this.user.uid}`).update({
            includedLabels: includedLabels,
        });
    }

    public updateIncludedAndExcludedLabels(includedLabels: string[], excludedLabels: string[]) {
        const uid = this.auth.user.uid;
        return this.afs.collection('users').doc(`${uid}`).update({
            includedLabels: includedLabels,
            excludedLabels: excludedLabels,
        });
    }

    // drop(event: any) {
    //     if (event.previousContainer === event.container) {
    //         moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    //         const folderId = event.container.id.replace('particle-', '').replace('dispatch-', '').replace('order-', '');
    //         const folder = this.folders.find((f) => {
    //             return f.id === folderId;
    //         });

    //         if (folder !== undefined) {
    //             folder.particlelist = event.container.data;
    //             this.updateFolder(folder);
    //         }
    //     } else {
    //         transferArrayItem(
    //             event.previousContainer.data,
    //             event.container.data,
    //             event.previousIndex,
    //             event.currentIndex
    //         );
    //         const previousFolderId = event.previousContainer.id
    //             .replace('particle-', '')
    //             .replace('dispatch-', '')
    //             .replace('order-', '');
    //         const currentFolderId = event.container.id
    //             .replace('particle-', '')
    //             .replace('dispatch-', '')
    //             .replace('order-', '');

    //         const currentFolder = this.folders.find((f) => {
    //             return f.id === currentFolderId;
    //         });
    //         const previousFolder = this.folders.find((f) => {
    //             return f.id === previousFolderId;
    //         });
    //         if (currentFolder !== undefined) {
    //             currentFolder.particlelist = event.container.data;
    //         }
    //         if (previousFolder !== undefined) {
    //             previousFolder.particlelist = event.previousContainer.data;
    //         }
    //         this.updateFolder(currentFolder);
    //         this.updateFolder(previousFolder);
    //     }
    // }

    public updateFolder(data: Folder) {

        this.afAuth.authState
            .pipe(switchMap((user) => {
                if (user) return from(this.afs.collection('workspace')
                    .doc(`${user.uid}`)
                    .collection('folders')
                    .doc(`${data.id}`)
                    .update(data));

                else return of(null);
            }))
            .pipe(take(1))
            .subscribe();

        // const folderCollection = this.afAuth.authState
        //     .pipe(switchMap(user => {
        //         if (user) {
        //             return new BehaviorSubject(
        //                 this.afs.collection('workspace').doc(`${user.uid}`).collection('folders').doc(`${data.id}`)
        //             );
        //         } else return null;
        //     }));

        // if (folderCollection) {
        //     folderCollection.pipe(takeUntil(this.destroy$)).subscribe((doc) => {
        //         doc.update(data);
        //     });
        // }
    }

    async createParticle(data: Particle): Promise<Particle> {
        return new Promise((resolve, reject) => {
            try {
                const particleDocRef = this.afs
                    .collection('workspace')
                    .doc(`${data.uid}`)
                    .collection('particles')
                    .doc();

                data.attachmentsMeta = data.attachmentsMeta !== undefined ? data.attachmentsMeta : {};
                data.creationDate = firestoreTimestampNow();
                data.folders = data.folders ? data.folders : {};
                data.forwardFromMessageId = data.forwardFromMessageId !== undefined ? data.forwardFromMessageId : '0';
                data.particleKey = particleDocRef.ref.id;
                data.temp = false;
                data.content ??= '';

                const particle = { ...data };

                this.store.dispatch(
                    particleDraftAdded({
                        payload: particle as any,
                    })
                );

                resolve(data);
            } catch (exception) {
                console.error('Error adding document: ', exception);
                reject(exception);
            }
        });
    }

    public async insertNewCachedRecipient(email: string, uid: string) {
        if (email) {
            await this.afs.collection('workspace').doc(`${uid}`).collection('cachedRecipients').add({
                emailAddress: email,
            });
        }
    }

    public updateParticleViaStore(data: Partial<Particle>) {
        this.store.dispatch(
            particleUpdate({
                payload: {
                    ...data,
                    id: data.particleKey,
                },
            })
        );
    }

    public updateDraftParticleAttachments(data: Partial<Particle>) {
        this.store.dispatch(
            updateDraftParticleAttachments({
                payload: {
                    attachments: data.attachments,
                    attachmentsMeta: data.attachmentsMeta,
                    id: data.particleKey,
                },
            })
        );
    }

    updateParticleKey(data: Particle) {
        this.afs
            .collection('workspace')
            .doc(`${data.uid}`)
            .collection('particles')
            .doc(data.particleKey)
            .update({
                particleKey: data.particleKey,
            })
            .catch((err) => {
                console.error('updateParticleKey ERROR : ', err);
            });
    }

    updateParticleReadStatus(data: Particle) {
        this.afs.collection('workspace').doc(`${data.uid}`).collection('particles').doc(data.particleKey).update({
            labelIds: data.labelIds,
            readStatus: data.readStatus,
        });
    }

    closeAlertViaStore(particle: Particle): void {
        const updateData = { alerting: false, id: particle.particleKey };
        this.dispatchUpdateLabels(updateData);
    }

    updateParticleFolderViaStore(data: Particle) {
        const particleCopy = deepCopy(data);
        const payload: any = {
            id: particleCopy.particleKey,
            folders: particleCopy.folders,
        };

        this.dispatchUpdateLabels(payload);
    }

    shouldDisplayOnlyValidParticles(particleWithHeaders: Particle[]): Particle[] {
        const rightParticles: Particle[] = [];
        let particleIsValid: boolean;

        for (let i = 0; i < particleWithHeaders.length; i++) {
            const particle = particleWithHeaders[i];

            const isSystem =
                particle.labelIds !== undefined && particle.labelIds !== null && particle.labelIds.length > 0
                    ? particle.particleType === 'system'
                    : false;

            const isDraft =
                particle.labelIds !== undefined && particle.labelIds !== null && particle.labelIds.length > 0
                    ? particle.labelIds.includes('DRAFT')
                    : false;

            const isSearch =
                particle.labelIds !== undefined && particle.labelIds !== null && particle.labelIds.length > 0
                    ? particle.labelIds.includes('NOT_IMPORTED')
                    : false;

            const isChecklistItem =
                particle.labelIds !== undefined && particle.labelIds !== null && particle.labelIds.length > 0
                    ? particle.particleType === 'checklist'
                    : false;

            // bypass if draft, search, checklist, system etc - only check for email particles
            if (!isDraft && !isSearch && !isChecklistItem && !isSystem) {
                particleIsValid = this.particleHelper.shouldBeDisplayed(particle, this.fieldToBeDefinedAtAllTime);
                if (particleIsValid) {
                    rightParticles.push(particle);
                } else {
                    console.error('particle is not valid: ', particle);
                }
            } else {
                rightParticles.push(particle);
            }
        }
        return rightParticles;
    }

    deleteParticle(data: any) {
        this.store.dispatch(
            particleRemoved({
                payload: data,
            })
        );

        // this.afs.collection("workspace")
        //   .doc(`${data.uid}`)
        //   .collection('particles').doc("/" + data.particleKey).delete();
    }

    createFolder(data: any) {
        if (data) {
            return this.afs
                .collection('workspace')
                .doc(`${data.uid}`)
                .collection('folders')
                .add({
                    uid: data.uid,
                    id: data.id,
                    icon: data.icon,
                    name: data.name,
                    particles: [],
                    section: data.section,
                })
                .then((docRef) => {
                    data.folderKey = docRef.id;
                    this.updateFolderKey(data as Folder);
                    return data;
                })
                .catch((error) => console.error('Error adding document: ', error));
        }
        return Promise.reject('No data provided');
    }

    updateFolderKey(folder: Folder) {
        this.afs.collection('workspace').doc(`${folder.uid}`).collection('folders').doc(folder.folderKey).update({
            folderKey: folder.folderKey,
        });
    }

    updateCustomFolderDisplayPosition(folder: Folder) {
        this.afs.collection('workspace').doc(`${folder.uid}`).collection('folders').doc(folder.folderKey).update({
            position: folder.position,
        });
    }

    deleteFolder(data: Folder) {
        this.afs
            .collection('workspace')
            .doc(`${data.uid}`)
            .collection('folders')
            .doc('/' + data.folderKey)
            .delete();
    }
    // -- particle ends -- //

    // --- particle helper --- //
    sortFolders(customFolders: any): any {
        return this.particleHelper.sortFolders(customFolders);
    }

    sortParticlesByUserPreference(
        particles: Particle[],
        userPrefSortType: string,
        dateOrderDirection: boolean
    ): Particle[] {
        return this.particleHelper.sortParticlesByUserPreference(particles, userPrefSortType, dateOrderDirection);
    }

    identifyUnreadParticles(firstLoadParticles: Particle[]) {
        return this.particleHelper.identifyUnreadParticles(firstLoadParticles);
    }

    getStatus(labels: string[]): string {
        return this.particleHelper.getStatus(labels);
    }

    getLocalDate(creationDate: any): any {
        return this.particleHelper.getLocalDate(creationDate);
    }

    assignDefaultOrderToParticles(particles: any, particleLabels: any) {
        return this.particleHelper.assignDefaultOrderToParticles(particles, particleLabels);
    }

    assignHeadersToParticle(sortedParticles: any, userPrefSortType: any): any {
        return this.particleHelper.assignHeadersToParticles(sortedParticles, userPrefSortType);
    }

    sortParticles(particlesWithLocalDates: any, particleLabels: any, userPrefSortType: any, dateSortDirection: any) {
        return this.particleHelper.sortParticles(
            particlesWithLocalDates,
            particleLabels,
            userPrefSortType,
            dateSortDirection
        );
    }

    convertParticleDatesToLocal(particlesForThisFolder: any[]) {
        return this.particleHelper.convertParticleDatesToLocal(particlesForThisFolder);
    }

    _checkIfParticleExists = async (newParticle: Particle) => {
        const particlesRef = this.afs.collection('workspace').doc(`${newParticle.uid}`).collection('particles').ref;

        const item = await particlesRef.where('id', '==', newParticle.id).get();
        if (!item.empty) {
            return true;
        } else {
            console.error('element does NOT exist!');
            return false;
        }
    };
    // --- particle helper --- //

    // -- Pending codes -- //
    selectPendingTime() {
        this.particleHelper.selectPendingTime();
    }

    setPendingTimePlusFiveMinutes() {
        this.particleHelper.setPendingTimePlusFiveMinutes();
    }

    setPendingTimePlusThirtyMinutes() {
        this.particleHelper.setPendingTimePlusThirtyMinutes();
    }

    setPendingTimePlusOneDay() {
        this.particleHelper.setPendingTimePlusOneDay();
    }

    addMinutes(date: any, minutes: any) {
        return this.particleHelper.addMinutes(date, minutes);
    }

    selectToday() {
        this.particleHelper.selectToday();
    }

    pendParticle(particle: any, pendingParticleCallback: any) {
        this.particleHelper.pendParticle(particle, pendingParticleCallback);
    }

    toggleMeridian() {
        this.particleHelper.toggleMeridian();
    }

    // -- Pending codes end -- //

    // -- Particle List Helper codes -- //

    filterOutParticlesForComponent(componentName: string, particles: Particle[], labels: any): Particle[] {
        return this.particleHelper.filterOutParticlesForComponent(componentName, particles, labels);
    }

    filderOutParticleForFolder(folderName: string, particlesWithAssignedFolders: Particle[]) {
        return this.particleHelper.filterOutParticleForFolder(folderName, particlesWithAssignedFolders);
    }

    assignFoldersToParticles(particles: Particle[], folders: any) {
        return this.particleHelper.assignFoldersToParticles(particles, folders);
    }

    getTheFolderReference(particle: Particle, folderForParticle: Folder) {
        return this.particleHelper.getTheFolderReference(particle, folderForParticle);
    }

    updateDropDownName(typeofsorts: string): string {
        return this.particleHelper.updateDropDownName(typeofsorts);
    }

    linkGmailAccount() {
        this.particleHelper.linkGmailAccount();
    }

    // --- Particle List Helper codes end -- //

    // -- Email Helper --//

    addResponseToParticle(
        emailResponse: any,
        isSendResponse: boolean,
        newParticle: Particle,
        readStatus: boolean,
        currentParticleKey: string
    ) {
        newParticle.particleKey = currentParticleKey ? currentParticleKey : newParticle.particleKey;
        newParticle.readStatus = readStatus;

        if (!isSendResponse) {
            newParticle.draftId = emailResponse.draftId;
            newParticle.id = emailResponse.id;

            const array1 =
                emailResponse.labelIds === undefined || emailResponse.labelIds === null ? [] : emailResponse.labelIds;
            const array2 =
                newParticle.labelIds === undefined || newParticle.labelIds === null ? [] : newParticle.labelIds;
            const mergedArray = this.particleHelper.mergeArray(array1, array2);
            newParticle.labelIds = mergedArray ? mergedArray : [];
            newParticle.threadId = emailResponse.threadId;
        } else {
            newParticle.id = emailResponse.id;
            newParticle.threadId = emailResponse.threadId;
            const array1 =
                emailResponse.labelIds === undefined || emailResponse.labelIds === null ? [] : emailResponse.labelIds;
            const array2 =
                newParticle.labelIds === undefined || newParticle.labelIds === null ? [] : newParticle.labelIds;
            const mergedArray = this.particleHelper.mergeArray(array1, array2);
            newParticle.labelIds = mergedArray ? mergedArray : [];
        }
        return newParticle;
    }

    removeLabelsAlreadyPresent(newParticleLabels: string[], receivedLabels: string[]) {
        if (
            receivedLabels !== undefined &&
            receivedLabels !== null &&
            receivedLabels.length > 0 &&
            newParticleLabels !== undefined &&
            newParticleLabels !== null &&
            newParticleLabels.length > 0
        ) {
            return newParticleLabels.filter((labelId) => {
                return receivedLabels.indexOf(labelId) === -1;
            });
        } else if (newParticleLabels !== undefined && newParticleLabels !== null && newParticleLabels.length > 0) {
            return [];
        } else if (receivedLabels !== undefined && receivedLabels !== null && receivedLabels.length > 0) {
            return newParticleLabels;
        } else {
            return [];
        }
    }
    // -- Email Helper End --//

    // -- localLogic -- //


    // -- localLogic Ends --//

    // --- Refactored Codes ---- //

    //#region old codes not currently used, but might be used later.
    getUserFolders() {
        return this.userFolders$;
    }

    getLoadedUserFolders() {
        return this.userFoldersSubject$;
    }

    getUserParticles() {
        return this.userParticles$;
    }

    //#endregion

    // --- Refactored Codes ends ---- //

    async removeLabelsFromParticleInFirebase(particle: any, labels: string[]) {
        const promise = new Promise(async (resolve) => {
            const particleDoc = this.afs
                .collection('workspace')
                .doc(particle.uid)
                .collection('particles')
                .doc(particle.particleKey);
            const subscription = particleDoc.snapshotChanges();

            subscription.pipe(take(1)).subscribe(async (doc) => {
                if (doc) {
                    const docData = doc.payload.data();
                    const labelIds2: any = docData ? docData['labelIds'] : [];
                    for (const label of labels) {
                        if (labelIds2.includes(label)) {
                            this.particleHelper.removeElementFromArray(labelIds2, label);
                        }
                    }

                    particle.labelIds = labelIds2;

                    this.afs.firestore
                        .runTransaction((transaction) =>
                            transaction.get(particleDoc.ref).then((sfDoc) => {
                                return transaction.update(particleDoc.ref, {
                                    labelIds: labelIds2,
                                    modified: new Date(),
                                });
                            })
                        )
                        .then((response) => {
                            resolve(void 0);
                        })
                        .catch((error) =>
                            console.error(
                                'error writing to workspace particle - removeLabelFromParticleInFirebase',
                                error
                            )
                        );
                }
            });
        });
        const result = await promise;
        return result;
    }

    async addLabelsToParticleInFirebase(particle: any, labels: string[]) {
        const particleDoc = this.afs
            .collection('workspace')
            .doc(particle.uid)
            .collection('particles')
            .doc(particle.particleKey);
        const subscription = particleDoc.snapshotChanges();

        await subscription.pipe(take(1)).subscribe(async (doc) => {
            if (doc) {
                const docData = doc.payload.data();
                const labelIds2: any = docData ? docData['labelIds'] : [];
                for (const label of labels) {
                    labelIds2.push(label);
                }

                particle.labelIds = labelIds2;

                return await this.afs.firestore
                    .runTransaction((transaction) =>
                        transaction.get(particleDoc.ref).then((sfDoc) => {
                            transaction.update(particleDoc.ref, { labelIds: labelIds2, modified: new Date() });
                        })
                    )
                    .then(() => {
                        // ?
                    })
                    .catch((error) =>
                        console.error('error writing to workspace particle - removeLabelFromParticleInFirebase', error)
                    );
            }
        });
    }

    public keyGenerator(): string {
        const id = uuidv4();
        return id;
    }

    async updateLabelsFromParticleInFirebase(
        particle: any,
        labelsToREMOVE: string[],
        labelsToADD: string[],
        extra?: any,
        noteContent?: string
    ): Promise<any> {
        const promise = new Promise(async (resolve, reject) => {
            if (noteContent) {
                const notes = particle.notes ? particle.notes : {}; // Initalize notes.
                const noteKey = this.keyGenerator().split('-', 1)[0];
                notes[noteKey] = {
                    dateCreated: new Date(),
                    content: noteContent,
                };

                extra.notes = notes;

                if (extra?.tasks) {
                    const tasks: { tasks: ParticleTask } = extra.tasks;
                    for (const [taskKey, taskValue] of Object.entries(tasks)) {
                        taskValue.noteKey = noteKey;
                    }
                }
            }

            const particleDoc = this.afs
                .collection('workspace')
                .doc(particle.uid)
                .collection('particles')
                .doc(particle.particleKey);
            const subscription = particleDoc.snapshotChanges();

            subscription.pipe(take(1)).subscribe(async (doc) => {
                if (doc) {
                    const docData = doc.payload.data();
                    const labelIds2: any = docData ? docData['labelIds'] : [];

                    // removes
                    for (const label of labelsToREMOVE) {
                        if (labelIds2.includes(label)) {
                            this.particleHelper.removeElementFromArray(labelIds2, label);
                        }
                    }

                    // adds
                    for (const label of labelsToADD) {
                        labelIds2.push(label);
                    }

                    const cleanArray = [...new Set(labelIds2)]; // remove duplicates

                    particle.labelIds = cleanArray;

                    this.afs.firestore
                        .runTransaction((transaction) =>
                            transaction.get(particleDoc.ref).then((sfDoc) => {
                                let key;
                                let data;
                                const obj: any = {};
                                if (extra) {
                                    Object.keys(extra).forEach((x) => {
                                        obj[x] = extra[x];
                                        key = x;
                                    });
                                    data = { labelIds: cleanArray, modified: new Date(), ...obj };
                                } else {
                                    data = { labelIds: cleanArray, modified: new Date() };
                                }
                                // const args = [particleDoc.ref,{ labelIds: labelIds2, modified: new Date()}];
                                // return transaction.update.apply(this, args );  // [key]: extra[key]
                                return transaction.set(particleDoc.ref, data, { merge: true }); // [key]: extra[key]
                            })
                        )
                        .then((response) => {
                            resolve(void 0);
                        })
                        .catch((error) =>
                            console.error(
                                'error writing to workspace particle - updateLabelsFromParticleInFirebase',
                                error
                            )
                        );
                }
            });
        });
        const result = await promise;
        return result;
    }

    ADDLocalLabelsArray(particles: any, localLabelsMap: any, localLabelsMapPrevious: any) {
        const keys = Object.keys(particles);
        const obj = {};
        for (const key of keys) {
            const labelsArray = particles[key].labelIds;
            // if not exist yet in localLabelArray add it
            // eslint-disable-next-line no-prototype-builtins
            if (!localLabelsMap.hasOwnProperty(key)) {
                const timestamp = new Date();
                localLabelsMap[key] = {
                    labels: labelsArray,
                    processing: false,
                    timestamp: timestamp,
                    process: 'added',
                };
                localLabelsMapPrevious[key] = labelsArray;
            }
        }
        return { localLabelsMap: localLabelsMap, localLabelsMapPrevious: localLabelsMapPrevious };
    }

    UPDATELocalLabelsArray(
        labelsBEFORE: any,
        labelsAFTER: any,
        particle: { particleKey: string | number; labelIds: any; },
        localLabelsMap: any,
        localLabelsMapPrevious: any
    ) {
        // if 'PENDING','DONE','INBASKET' label changed
        // extract 'PENDING','DONE','INBASKET'
        const labelsArrayToBeExtracted = ['PENDING', 'DONE', 'INBASKET'];
        const before = this.extractsCommonItems(labelsBEFORE, labelsArrayToBeExtracted);
        const after = this.extractsCommonItems(labelsAFTER, labelsArrayToBeExtracted);
        const isDifferent = !this.arrayEquals(before, after);
        // const timestampSanpshot = this.localLabelsMap[particle.particleKey].timestamp;
        // const timestampParticle = particle.modified.toDate();
        if (isDifferent) {
            // && timestampSanpshot.getTime() < timestampParticle.getTime()
            const processing = localLabelsMap[particle.particleKey].processing;
            if (!processing) {
                const timestamp = new Date();
                localLabelsMap[particle.particleKey] = { labels: particle.labelIds }; // , 'processing': processing
                localLabelsMap[particle.particleKey].processing = processing;
                localLabelsMap[particle.particleKey].timestamp = timestamp;
                localLabelsMap[particle.particleKey].process = 'updated';
                localLabelsMapPrevious[particle.particleKey] = particle.labelIds;
            }
        }
        return { localLabelsMap: localLabelsMap, localLabelsMapPrevious: localLabelsMapPrevious };
    }

    removeBasketLabels(labelsArray: string[], labelsArrayToBeRemoved: string[]) {
        const newLabelsArray = labelsArray;
        for (const label of labelsArrayToBeRemoved) {
            const index = labelsArray.indexOf(label);
            if (index > -1) {
                newLabelsArray.splice(index, 1);
            }
        }
        return newLabelsArray;
    }

    extractsCommonItems(array1: string[], array2: string[]) {
        const newArray = [];

        const map: any = {};
        for (let i = 0; i < array1.length; i++) {
            if (!map[array1[i]]) {
                const item = array1[i];
                map[item] = true;
            }
        }

        for (let j = 0; j < array2.length; j++) {
            if (map[array2[j]]) {
                newArray.push(array2[j]);
            }
        }
        return newArray;
    }

    arrayEquals(a: any[], b: string | any[]) {
        return (
            Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index])
        );
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }
}
