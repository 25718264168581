import { ClientAbcUserSettings, SettingsAppearance, ClientAbcDynamicGroups, StatisticsFilterCodes } from "../interfaces";

export const DefaultClientAbcSettings: ClientAbcUserSettings = {
    appearance: SettingsAppearance.gross,
    dateProfileKey: '',
    field: 'gross_sales',
    tooltip: 'Nett',
    dynamicGrouping: ClientAbcDynamicGroups['No grouping'],
    transactionTypes: ['IN', 'CN', 'JC', 'RF', 'BC', 'RE', 'DS', 'JD'], // Payments excluded by default.
};

// debtTran-IN = Invoices
export const DefaultDaily7RGraphTransactionTypes: StatisticsFilterCodes[] = ['debtTran-IN', 'debtTran-CN', 'salesOrder-open', 'salesOrder-closed'];
export const DefaultMonthly7RGraphTransactionTypes: StatisticsFilterCodes[] = ['debtTran-IN', 'debtTran-CN'];

export const DefaultStatisticsSettings = {
    field: 'nett',
    tooltip: 'Nett',
    showTax: true,
    showEmptyCodes: false,
    showSalesOrders: true,
    appearance: SettingsAppearance['nett&tax'],
    transactionTypes: ['debtTran-IN', 'debtTran-CN', 'salesOrder-open', 'salesOrder-closed'] as StatisticsFilterCodes[], // Only credit notes, invoices and sales orders by default.
    view: 'daily' as 'day' | 'week' | 'month'
};