<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Order particles</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="closePopUp()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container modal-body">

        <div class="card">

            <div style="position: relative;">

                <select class="selectpicker" (change)="applyFilter($event.target)">
                    <option *ngFor="let typeitem of typeOfParticleOrders"  [value]="typeitem.val">{{typeitem.desc}}
                    </option>
                </select>
            </div>

        </div>

        <div class="card" id="particle_sort">
            <div class="card-header">
                Arrange order of particles
            </div>

            <div class="card-body canscroll" cdkDropList #particleList="cdkDropList"
                [cdkDropListData]="[particleLabels]" (cdkDropListDropped)="onDrop($event)">

                <div *ngFor="let label of particleLabels" class="particle-list column" cdkDrag>
                    <div class="particle-box card-header">
                        {{label}}
                    </div>
                </div>

            </div>
        </div>
        <div class="card" id="date_sort">
            <div class="card-header">
                <span>Select type of date ordering</span>
            </div>
            <div class="card-body">
                <label *ngFor="let dtype of dateOrderTypes let idx = index" class="radio-inline">
                    <input type="radio" name="optradio" [checked]="(idx === 1)" (click)="recordSelection(dtype.value)">
                    {{dtype.text}}
                </label>

            </div>

        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="specifyOrder()">Apply</button>
    <button type="button" class="btn btn-default" (click)="closePopUp()">Cancel</button>
</div>