import { FirebaseDatePipe } from './fb-date-pipe';
import { CommonModule } from '@angular/common';
import { TruncatePipe } from './truncate-pipe';
import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from './safeHtml-pipe';


@NgModule({
  declarations: [
    FirebaseDatePipe,
    TruncatePipe,
    SafeHtmlPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FirebaseDatePipe,
    TruncatePipe,
    SafeHtmlPipe
  ],
  providers: [
    FirebaseDatePipe,
    TruncatePipe,
    SafeHtmlPipe
  ]
})
export class PipeModule { }
