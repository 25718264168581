import { Particle } from '@newgenus/common';

export class EmailHelper {

    // -- email message functions -- //

    extractContentFromPart(payload: any) {
        try {
            let messageContent = '';

            if (payload.mimeType) {
                messageContent = this.getMessageContent(payload.mimeType, payload)
                return messageContent;
            } else if (payload.parts) {
                messageContent = this.getPartContent(payload.parts);
                return this.sanitizeContent(messageContent);
            } else { return ""; }
        } catch (error) {

            console.error('an error occurred', error);
            return payload.snippet;
        }
    }

    sanitizeContent(messageContent: string) {
        //Will santize here a bit later...
        return messageContent;
    }

    getPartContent(payloadParts: any) {

        let messageContent = '';

        for (let index = 0; index < payloadParts.length; index++) {

            const messagePart = payloadParts[index];
            const mimeType: string = (messagePart.mimeType) ? messagePart.mimeType : '';

            messageContent += this.getMessageContent(mimeType, messagePart);

        }

        return messageContent;
    }

    getMessageContent(mimeType: string, messagePart: any) {

        let messageContent = '';

        switch (mimeType) {
            case "text/plain":
                messageContent += this.convertPartContentToTextPlain(messagePart, messageContent);
                break;
            case "text/html":
                messageContent += this.convertPartContentToHtml(messagePart, messageContent);
                break;

            case "multipart/alternative":
                // eslint-disable-next-line no-case-declarations
                const firstPreferredPart = messagePart.parts.find((part: { mimeType: string; }) => part.mimeType === "text/html");
                // eslint-disable-next-line no-case-declarations
                const secondPreferredPart = messagePart.parts.find((part: { mimeType: string; }) => part.mimeType === "text/plain");
                messageContent += (firstPreferredPart) ? this.convertPartContentToHtml(firstPreferredPart, messageContent) : this.convertPartContentToTextPlain(secondPreferredPart, messageContent);
                break;

            case "message/delivery-status":
                messageContent += '';
                break;

            case "message/rfc822":
                messageContent += '';
                break;

            default:
                messageContent += (messagePart.parts) ? this.getPartContent(messagePart.parts) : '';
                break;

        }

        return messageContent;
    }

    extractDataFromMessagePart(messagePart: any) {
        const messageBody = (messagePart.body) ? messagePart.body : null;
        const messageData = (messageBody && messageBody.data) ? messageBody.data : null;
        return messageData;

    }

    convertPartContentToHtml(messagePart: any, messageContent: string) {

        const messageData = this.extractDataFromMessagePart(messagePart);
        messageContent = (messageData) ? atob(messageData.replace(/-/g, '+').replace(/_/g, '/')) : '';
        return messageContent;
    }

    convertPartContentToTextPlain(messagePart: any, messageContent: string) {

        const messageData = this.extractDataFromMessagePart(messagePart);
        //  messageContent = (messageData) ? atob(messageData) : ''; will fix this later
        messageContent = (messageData) ? atob(messageData.replace(/-/g, '+').replace(/_/g, '/')) : '';

        return messageContent;
    }

    // -- response functions -- //

    loadEmailResponse(email: any, newParticle: Particle) {

        newParticle.id = email.id;
        newParticle.threadId = email.threadId;
        newParticle.particleKey = email.particleKey;
        newParticle.labelIds = (newParticle.labelIds) ? newParticle.labelIds.concat(email.labelIds) : [].concat(email.labelIds);
        return newParticle;
    }

    // editor Js functions
    getConvertedEditorContent(savedData: any, mimeType: string) {

        let particleContentValue = '';

        switch (mimeType) {
            case "text/html":

                particleContentValue = this.convertbase64DataToRawHtmlFormat(savedData);
                break;

            case "text/plain":
                particleContentValue = this.convertBase64DataToTextFormat(savedData);

                break;
            case "text/multipart":
                particleContentValue = this.convertbase64DataToRawHtmlFormat(savedData); // temporarely will be updated.
                break;

        }
        return particleContentValue;

    }

    convertBase64DataToTextFormat(formData: any) {

        const doc = document.createElement('DIV')
        let html = '';
        formData.blocks.forEach(function (block: { type: any; data: { level: any; text: any; url: any; caption: any; items: any[]; }; }) {
            switch (block.type) {
                case 'header':
                    html += `<h${block.data.level}>${block.data.text}</h${block.data.level}>`;
                    break;
                case 'paragraph':
                    html += `<p>${block.data.text}</p>`;
                    break;
                case 'delimiter':
                    html += '<hr />';
                    break;
                case 'image':
                    html += `<img class="img-fluid" src="${block.data.url}" title="${block.data.caption}" /><br /><em>${block.data.caption}</em>`;
                    break;
                case 'list':
                    html += '<ul>';
                    block.data.items.forEach(function (li: any) {
                        html += `<li>${li}</li>`;
                    });
                    html += '</ul>';
                    break;
                default:
                    break;
            }
        });

        html += ''
        doc.innerHTML = html;
        const result = doc.textContent || doc.innerText || "";

        return result;
    }

    convertbase64DataToRawHtmlFormat(formData: any) {

        const htmlDoc = document.createElement('HTML')
        const head = document.createElement('HEAD');
        const body = document.createElement('BODY');

        let bodyContent = '';
        formData.blocks.forEach(function (block: { type: any; data: { level: any; text: any; url: any; caption: any; items: any[]; }; }) {
            switch (block.type) {
                case 'header':
                    bodyContent += `<h${block.data.level}>${block.data.text}</h${block.data.level}>`;
                    break;
                case 'paragraph':
                    bodyContent += `<p>${block.data.text}</p>`;
                    break;
                case 'delimiter':
                    bodyContent += '<hr />';
                    break;
                case 'image':
                    bodyContent += `<img class="img-fluid" src="${block.data.url}" title="${block.data.caption}" /><br /><em>${block.data.caption}</em>`;
                    break;
                case 'list':
                    bodyContent += '<ul>';
                    block.data.items.forEach(function (li: any) {
                        bodyContent += `<li>${li}</li>`;
                    });
                    bodyContent += '</ul>';
                    break;
                default:
                    break;
            }
        });

        body.innerHTML = bodyContent;
        htmlDoc.appendChild(head);
        htmlDoc.appendChild(body);

        return htmlDoc.outerHTML;
    }

    extractHtmlBodyContentFromText(htmlContent: string) {

        const domParser = new DOMParser();
        const htmlDocument = domParser.parseFromString(htmlContent, 'text/html');
        const bodyContent = htmlDocument.body.innerHTML;

        return bodyContent;
    }

}



