import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { interval, Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'newgenus-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss'],
})
export class SplashComponent implements OnInit {

  public loading = "Loading...";

  private destroy$ = new Subject<void>();
  private loading$ = interval(1000);
  private count = 0;
  private countTotal = 0;

  constructor(private router: Router) { }

  public ngOnInit(): void {
    this.loading$.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.loading = this.loading + ".";
        this.count++;
        this.countTotal++;

        if (this.count === 4) {
          this.loading = "Loading";
          this.count = 0;
        }

        // Reload the page if it's sitting on the splash screen for more than 10 seconds.
        if (this.countTotal === 10) {
          location.reload();
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public goToUserWorkspace(): void {
    const url = 'app/workspace';
    this.router.navigate([url]);
  }

}
