import { User } from '@newgenus/common';
import { createAction, props } from '@ngrx/store';

export const loadUser = createAction('[User Resolver] Load User Document');

export const allUsersLoaded = createAction('[Load User Effect] All Users Loaded', props<{ user: User[] }>());

export const usersAdded = createAction('[User] added', props<{ payload: User }>());

export const userRemoved = createAction('[User] removed', props<{ payload: Partial<User> }>());

export const userModified = createAction('[User] modified', props<{ payload: Partial<User> }>());

export const userUpdate = createAction('[User updating] updating User', props<{ payload: Partial<User> }>());

export const updateUserParticleSort = createAction('[User updating] updating User Sort', props<{ payload: Partial<User> }>());

export const userUpdateSortOrder = createAction('[User] Update Sort Order', props<{ payload: Partial<User> }>());
