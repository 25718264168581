import { Injectable, Injector, ComponentFactoryResolver, EmbeddedViewRef, ApplicationRef, Renderer2, RendererFactory2 } from '@angular/core';
import { FileUploadComponent } from '../components/file-upload/file-upload.component';

@Injectable({
  providedIn: 'root'
})
export class DomService {

  number = 0;
  componentRefMap: Map<number, any>;
  componentRef: any;
  private renderer: Renderer2;
  domElem!: HTMLElement;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    // private render: Renderer2,
    rendererFactory: RendererFactory2,
    // private elRef: ElementRef,
  ) {
    this.componentRefMap = new Map();
    this.renderer = rendererFactory.createRenderer(null, null);
    this.number = 0;
  }

  appendComponentToBody(component: any, elementRef: any, fileName: any, particleKey: string) {
    // Create a component reference from the component
    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(this.injector);

    (componentRef.instance as FileUploadComponent).fileName = fileName;
    (componentRef.instance as FileUploadComponent).particleKey = particleKey;
    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(componentRef.hostView);

    // Get DOM element from component
    //#region 
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    //#endregion

    // Append DOM element to the body -- NB!!!!!!!!!!!!!! change to use renderer2
    // document.querySelector("#files").appendChild(domElem);
    this.renderer.appendChild(elementRef.nativeElement, domElem); //domElem

    this.componentRef = componentRef;
    this.componentRefMap.set(this.number, componentRef);
  }

  //#region added logic for attachment
  appendPreExistingAttachmentsToBody(component: any, elementRef: any, fileName: any, particleKey: string) {
    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(this.injector);

    (componentRef.instance as FileUploadComponent).fileName = fileName;
    (componentRef.instance as FileUploadComponent).particleKey = particleKey;
    (componentRef.instance as FileUploadComponent).currentFileUpload = false;
    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(componentRef.hostView);

    // Get DOM element from component
    //#region I am working ...
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    //#endregion

    // Append DOM element to the body -- NB!!!!!!!!!!!!!! change to use renderer2
    // document.querySelector("#files").appendChild(domElem);
    this.renderer.appendChild(elementRef.nativeElement, domElem);

    this.componentRef = componentRef;
    this.componentRefMap.set(this.number, componentRef);
  }
  //#endregion

  listAllProperties(o: any) {
    let objectToInspect;
    let result: any[] = [];

    for (objectToInspect = o; objectToInspect !== null;
      objectToInspect = Object.getPrototypeOf(objectToInspect)) {
      result = result.concat(
        Object.getOwnPropertyNames(objectToInspect)
      );
    }

    return result;
  }

  removeComponentFromBody(componentRef: any) {
    // remove the component from DOM body
    this.appRef.detachView(componentRef.hostView);
    componentRef.destroy();

    // remove the component reference from the MAP
    const number = componentRef?._component?.number || componentRef?.instance?.number;
    this.componentRefMap.delete(number);
  }

}
