/* eslint-disable no-prototype-builtins */
import { SettingsAppearance, SettingsType } from '../interfaces/statistics-models';
import { TimezoneDifference } from '../interfaces/fe-models/app.models';
import { Feature, Permission, User } from '../interfaces';
import { Timestamp } from "firebase/firestore";
import { Subject, Subscription } from 'rxjs';
import moment from 'moment';


/**
 * Clone object
 */
export function cloneObject<T>(object: T): T {
  return JSON.parse(JSON.stringify(object));
}

export function roundTo(value: number, roundToNumber: number) {
  return Math.round(value / roundToNumber) * roundToNumber;
}

export function encodeUtf8(str: string) {
  return unescape(encodeURIComponent(str));
}


/**
 * Constructs a firebase date from a normal seconds.
 *
 * @param {number} seconds the date in MS.
 * @return {Timestamp} The parsed date.
 */
export function constructFirebaseDateFromSeconds(seconds: number): Timestamp {
  return Timestamp.fromMillis(seconds * 1000);
}

/**
 * Helper function which is short-hand for Timestamp.now().
 *
 * @return {*}  {Timestamp} The current time as a FirebaseFirestore Timestamp.
 */
export function firestoreTimestampNow(): Timestamp {
  return Timestamp.now();
}

/**
 * Parse another date format to a Firebase Timestamp.
 * This also works if the value is JSON representation of a FirebaseTimestamp or if it is an actual FirebaseTimestamp.
 *
 * @param {(Timestamp | Date | JSON)} date a JS Date, FirebaseTimestamp or the JSON of a FirebaseTimestamp.
 * @return {Timestamp} The firebase Timestamp.
 * @throws Error if the parsing fails.
 */
export function toFirebaseTimestamp(date: Timestamp | Date | JSON | string | moment.Moment): Timestamp {
  const millis = getUTCTimestamp(date);
  if (millis === 0 || isNaN(millis)) {
    throw new Error(`static function argument error: ${getUTCTimestamp.name} returned: ${millis}.`);
  }

  return Timestamp.fromMillis(millis);
}

/**
 * Parse another date format to a javascript Date object.
 *
 * @param {(Timestamp | Date | JSON | string | moment.Moment)} date any known date format.
 * @return {Date}  {Date} The parsed date.
 */
export function toDate(date: Timestamp | Date | JSON | string | moment.Moment): Date {
  return new Date(getUTCTimestamp(date));
}

/**
 * Parse another date format to a moment object.
 *
 * @param {(Timestamp | Date | JSON | string | moment.Moment)} date any known date format.
 * @return {Moment}  {moment.Moment} The parsed date.
 */
export function toMoment(date: Timestamp | Date | JSON | string | moment.Moment): moment.Moment {
  return moment(getUTCTimestamp(date));
}

/**
 * Hour must be set to 00:00 for this to work.
 *
 * @export
 * @param {(Timestamp | Date | JSON | string | moment.Moment)} date any known date format.
 * @return {*}  {moment.Moment} The parsed date.
 */
export function adjustFromLocalDateHourToUtcDateHour(date: Timestamp | Date | JSON | string | moment.Moment): moment.Moment {
  const momentDate = moment(getUTCTimestamp(date));

  if (momentDate.utc().get('hour') !== 0 && momentDate.local().get('hour') === 0) {
    if (momentDate.local().utcOffset() > 0) {
      momentDate.add(momentDate.local().utcOffset(), 'minutes')
    } else {
      momentDate.subtract(momentDate.local().utcOffset(), 'minutes');
    }
  }

  return momentDate.utc();
}


/**
 * Hour must be set to 00:00 for this to work.
 *
 * @export
 * @param {(Timestamp | Date | JSON | string | moment.Moment)} date any known date format.
 * @return {*}  {moment.Moment} The parsed date.
 */
export function adjustFromUtcDateHourToLocalDateHour(date: Timestamp | Date | JSON | string | moment.Moment): moment.Moment {
  const momentDate = moment(getUTCTimestamp(date));

  if (momentDate.utc().get('hour') === 0 && momentDate.local().get('hour') !== 0) {
    if (momentDate.local().utcOffset() > 0) {
      momentDate.add(momentDate.local().utcOffset(), 'minutes')
    } else {
      momentDate.subtract(momentDate.local().utcOffset(), 'minutes');
    }
  }

  return momentDate.local();
}

function getUTCTimestamp(date: any) {
  if (!date) return 0;

  // JSON of a FirebaseTimestamp.
  if (date._seconds) return date._seconds * 1000;

  // FirebaseTimestamp.
  if (date instanceof Timestamp) return date.toMillis();

  // Moment object.
  // Must be placed before checking for date.seconds
  if (moment.isMoment(date)) return date.valueOf();

  // Another version of a JSON FirebaseTimestamp.
  // Must be placed after checking for moment.isMoment
  if (date.seconds) return date.seconds * 1000;

  // String.
  if (typeof date == 'string') return new Date(date).getTime();

  // Date object.
  return +date;
}


export function parseEmbeddedTimestampsToFirebaseDate<T = any>(obj: T): T {

  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      if (moment.isMoment(obj[i])) {
        obj[i] = toFirebaseTimestamp(obj[i] as any);
      } else if (obj[i] !== null && typeof obj[i] == 'object') {
        parseEmbeddedTimestampsToFirebaseDate(obj[i]);
      }
    }
  } else if (typeof obj === 'object') {
    for (const [key, value] of Object.entries<any>(obj as any)) {
      if (
        // eslint-disable-next-line no-prototype-builtins
        (value?.hasOwnProperty('seconds') || value?.hasOwnProperty('_seconds')) && // Check if has FB.Timestamp.seconds
        // eslint-disable-next-line no-prototype-builtins
        (value?.hasOwnProperty('nanoseconds') || value?.hasOwnProperty('_nanoseconds')) && // Check if has FB.Timestamp.nanoseconds
        Object.keys(value).length === 2 // Not the recommended way to check the property count of objects, but this is just a safe-guard incase a non FB.Timestamp has the above two properties.
      ) {
        (<any>obj)[key] = toFirebaseTimestamp(value as any); // new Date(value.seconds * 1000)
      } else if (value !== null && typeof value == 'object') {
        // Loop if required.
        parseEmbeddedTimestampsToFirebaseDate(value);
      }
    }
  }
  return obj;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function parseEmbeddedMomentToFirebaseDate<T = Record<string, any>>(obj: T) {
  // console.log("8888888888888888  OBJ: ", obj);
  for (const [key, value] of Object.entries(obj as any)) {
    // console.log("moment.isMoment(value) : ", value, " is ", moment.isMoment(value)); 
    if (moment.isMoment(value)
      // value?.hasOwnProperty('seconds') // Check if has FB.Timestamp.seconds
      // && value?.hasOwnProperty('nanoseconds') // Check if has FB.Timestamp.nanoseconds
      // && Object.keys(value).length === 2 // Not the recommended way to check the property count of objects, but this is just a safe-guard incase a non FB.Timestamp has the above two properties.
    ) {
      (<any>obj)[key] = toFirebaseTimestamp(value as any) // new Date(value.seconds * 1000)
      // console.log("boom! >> obj[key] ", obj[key]);
    } else if (value !== null && typeof value == "object") { // Loop if required.
      // console.log("going deeper!!!", value);
      parseEmbeddedMomentToFirebaseDate(value);
    }

  }
  return obj as T;
}

export function parseEmbeddedTimestampsToJavascriptDate<T = any>(obj: T): T {

  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      if (moment.isMoment(obj[i])) {
        obj[i] = toDate(obj[i] as any);
      } else if (obj[i] !== null && typeof obj[i] == 'object') {
        parseEmbeddedTimestampsToJavascriptDate(obj[i]);
      }
    }
  } else if (typeof obj === 'object') {
    for (const [key, value] of Object.entries<any>(obj as any)) {
      if (
        // eslint-disable-next-line no-prototype-builtins
        (value?.hasOwnProperty('seconds') || value?.hasOwnProperty('_seconds')) && // Check if has FB.Timestamp.seconds
        // eslint-disable-next-line no-prototype-builtins
        (value?.hasOwnProperty('nanoseconds') || value?.hasOwnProperty('_nanoseconds')) && // Check if has FB.Timestamp.nanoseconds
        Object.keys(value).length === 2 // Not the recommended way to check the property count of objects, but this is just a safe-guard incase a non FB.Timestamp has the above two properties.
      ) {
        (<any>obj)[key] = toDate(value as any); // new Date(value.seconds * 1000)
      } else if (value !== null && typeof value == 'object') {
        // Loop if required.
        parseEmbeddedTimestampsToJavascriptDate(value);
      }
    }
  }
  return obj;
}

/**
* Adds a 0 to the beginning of a number. 
* @param {number | string} n the number which requires modification.
*/
export function zeroFill(n: number | string): number | string {
  if (+n < 10) {
    return n = '0' + n;
  }

  return n;
}

export function deepCopy<T = any>(obj: T): T {
  // return JSON.parse(JSON.stringify(obj)) as T; // serialize -> deserialize -> unique clone

  let copy: any;

  // Handle the 3 simple types, and null or undefined
  if (null == obj || 'object' !== typeof obj) {
    return obj as T;
  }

  // RxJS Subjects as well as Subscriptions are not serializable, we cannot deepcopy them as this time.
  if (obj instanceof Subject || obj instanceof Subscription) {
    return obj as T;
  }

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy as T;
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = [];
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = deepCopy(obj[i]);
    }
    return copy as T;
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {};
    for (const attr in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if ((<any>obj).hasOwnProperty(attr)) {
        copy[attr] = deepCopy((obj as any)[attr]);
      }
    }
    return copy as T;
  }

  throw new Error('Deep copy not supported for this object.');
}

/**
 * Parse string to JSON.
 * If the string is invalid or not JSON, false will be returned.
 *
 * @export
 * @param {*} jsonString JSON string.
 * @return {*} The parsed JSON object, otherwise false.
 */
export function tryParseJSONObject(jsonString: string) {
  try {
    const o = JSON.parse(jsonString);
    if (o && typeof o === "object") {
      return o;
    }
  }
  catch (e) {
    // ?
  }

  return false;
};


export function toTitleCase(sentenceToParse: string): string {
  const sentence = sentenceToParse.toLowerCase().split(" ");
  for (let i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }

  return sentence.join(" ");
}

export function getTimeDifferenceBetweenZones(
  currentOffset: number,
  otherZoneOffset: number
): TimezoneDifference {
  const a = Math.abs(currentOffset), b = Math.abs(otherZoneOffset);
  let summedOffset;

  // Opposite positives/negatives, e.g: 165, -120 || -165, 120.
  const areMixedSigns = (currentOffset > 0 && otherZoneOffset < 0) || (currentOffset < 0 && otherZoneOffset > 0);

  if (areMixedSigns) summedOffset = a + b;
  else summedOffset = (a === b ? 0 : a - b); // Both positive/negative signs, if same, return 0.

  const hourOffset = Math.floor(summedOffset / 60);
  const minOffset = summedOffset - 60 * hourOffset;
  const isAhead = currentOffset < 0;

  return {
    aheadOrBehind: isAhead ? 'ahead' : 'behind',
    isAhead: isAhead,
    hours: hourOffset,
    minutes: minOffset,
    messageLong: `${zeroFill(hourOffset)} hours and ${zeroFill(minOffset)} minutes ${isAhead ? 'ahead' : 'behind'}`,
    messageShort: `${zeroFill(hourOffset)}h:${zeroFill(minOffset)}m ${isAhead ? 'ahead' : 'behind'}`,
    messageNone: `${isAhead ? '+' : '-'}${zeroFill(hourOffset)}:${zeroFill(minOffset)}`,
    hasDifference: summedOffset > 0,
    offset: summedOffset
  }

}

export function toOrdinalString(i?: number) {
  if (!i) return '';

  const j = i % 10, k = i % 100;

  if (j === 1 && k !== 11)
    return `${i}st`;
  if (j === 2 && k !== 12)
    return `${i}nd`;

  if (j === 3 && k !== 13)
    return `${i}rd`;

  return `${i}th`;
}

/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
export function groupByKeyGetter<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
  const map = new Map<K, Array<V>>();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns an object of the array grouped by the grouping function.
 * 
 * @param list An array of type V.
 * @param key A property key of V.
 *
 * @export
 * @param {any[]} array
 * @param {string} key
 * @return {*} 
 */
export function groupByKey(array: any[], key: string) {
  // Return the end result
  return array.reduce((result: { [x: string]: any[]; }, currentValue: { [x: string]: string | number; }) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      { ...currentValue }
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};

/**
 * Deep compare two objects to determine if they are equal.
 * Credits: crazyx
 * @link https://stackoverflow.com/questions/1068834/object-comparison-in-javascript
 *
 * @return {boolean} True if they are the same/equal; False if they are not the same/unequal. 
 */
export function deepCompare(objectA: any, objectB: any) {
  let i, l, leftChain: any, rightChain: any;

  function compare2Objects(x: any, y: any) {
    let p;

    // remember that NaN === NaN returns false
    // and isNaN(undefined) returns true
    if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
      return true;
    }

    // Compare primitives and functions.     
    // Check if both arguments link to the same object.
    // Especially useful on the step where we compare prototypes
    if (x === y) {
      return true;
    }

    // Works in case when functions are created in constructor.
    // Comparing dates is a common scenario. Another built-ins?
    // We can even handle functions passed across iframes
    if ((typeof x === 'function' && typeof y === 'function') ||
      (x instanceof Date && y instanceof Date) ||
      (x instanceof RegExp && y instanceof RegExp) ||
      (x instanceof String && y instanceof String) ||
      (x instanceof Number && y instanceof Number)) {
      return x.toString() === y.toString();
    }

    // At last checking prototypes as good as we can
    if (!(x instanceof Object && y instanceof Object)) {
      return false;
    }

    // eslint-disable-next-line no-prototype-builtins
    if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
      return false;
    }

    if (x.constructor !== y.constructor) {
      return false;
    }

    if (x.prototype !== y.prototype) {
      return false;
    }

    // Check for infinitive linking loops
    if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
      return false;
    }

    // Quick checking of one object being a subset of another.
    // todo: cache the structure of arguments[0] for performance
    for (p in y) {
      // eslint-disable-next-line no-prototype-builtins
      if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
        return false;
      }
      else if (typeof y[p] !== typeof x[p]) {
        return false;
      }
    }

    for (p in x) {
      // eslint-disable-next-line no-prototype-builtins
      if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
        return false;
      }
      else if (typeof y[p] !== typeof x[p]) {
        return false;
      }

      switch (typeof (x[p])) {
        case 'object':
        case 'function':

          leftChain.push(x);
          rightChain.push(y);

          if (!compare2Objects(x[p], y[p])) {
            return false;
          }

          leftChain.pop();
          rightChain.pop();
          break;

        default:
          if (x[p] !== y[p]) {
            return false;
          }
          break;
      }
    }

    return true;
  }

  if (arguments.length < 1) {
    return true; //Die silently? Don't know how to handle such case, please help...
    // throw "Need two or more arguments to compare";
  }

  for (i = 1, l = arguments.length; i < l; i++) {

    leftChain = []; //Todo: this can be cached
    rightChain = [];

    // eslint-disable-next-line prefer-rest-params
    if (!compare2Objects(arguments[0], arguments[i])) {
      return false;
    }
  }

  return true;
}

export function enumerateDaysBetweenDates(startDate: Date | moment.Moment, endDate: Date | moment.Moment, includeStart = true, includeEnd = true) {
  const currentDate = moment(startDate).startOf('day');
  const lastDate = moment(endDate).startOf('day');
  const dates: Date[] = [];

  if (includeStart) dates.push(currentDate.clone().toDate());

  while (currentDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currentDate.clone().toDate());
  }

  if (includeEnd) dates.push(lastDate.clone().toDate());

  return dates;
};


/**
 * Parse a string of gross&tax/gross to 'Gross', and the same for nett.
 * Defaults to 'Nett' if the appearance is not recognized.
 *
 * @param {SettingsAppearance} appearance The appearance to parse.
 * @return {*}  {string} The parsed string.
 */
export function toReducedSettingsFieldName(appearance: SettingsAppearance): string {
  switch (appearance) {
    case SettingsAppearance.gross:
    case SettingsAppearance['gross&tax']:
      return 'Gross';
    case SettingsAppearance.nett:
    case SettingsAppearance['nett&tax']:
      return 'Nett';

    default:
      console.warn(`Unrecognized appearance: ${appearance}. Returning 'Nett' as default.`);
      return 'Nett';
  }
}

/**
 * Parse a string of gross&tax/gross to 'totalGross', and the same for nett.
 * Defaults to 'totalNet' if the appearance is not recognized.
 *
 * @param {SettingsAppearance} appearance The appearance to parse.
 * @return {*}  {string} The parsed string.
 */
export function toSettingsSummaryFieldName(appearance: SettingsAppearance, settingsType: SettingsType): string {

  if (settingsType === SettingsType.summaryByCode) {
    switch (appearance) {
      case SettingsAppearance.gross:
      case SettingsAppearance['gross&tax']:
        return 'totalGross';
      case SettingsAppearance.nett:
      case SettingsAppearance['nett&tax']:
        return 'totalNet';

      default:
        console.warn(`Unrecognized appearance: ${appearance}. Returning 'totalNet' as default.`);
        return 'totalNet';
    }
  } else if (settingsType === SettingsType.clientAbc) {
    switch (appearance) {
      case SettingsAppearance.gross:
      case SettingsAppearance['gross&tax']:
        return 'gross_sales';
      case SettingsAppearance.nett:
      case SettingsAppearance['nett&tax']:
        return 'nett_sales';

      default:
        console.warn(`Unrecognized appearance: ${appearance}. Returning 'nett_sales' as default.`);
        return 'nett_sales';
    }
  } else {
    console.warn(`Unrecognized settings type: ${settingsType}. Returning 'totalNet' as default.`);
    return 'totalNet';
  }
}

/**
 * Check if an object is empty (defined as does not contain any own properties / ```=== {}```)
 *
 * @param {*} obj The object to check.
 * @return {boolean} True if the object is empty, false otherwise.
 */
export function isObjectEmpty(obj: any) {
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return true;
}


/**
 * Check if an object is not empty (defined as does contain any own properties / ```!== {}```)
 *
 * @param {*} obj The object to check.
 * @return {boolean} False if the object is empty, true otherwise.
 */
export function isObjectNotEmpty(obj: any) {
  return isObjectEmpty(obj) === false;
}

/**
 * Check if a value is an object, and if so, if it is empty.
 * This will return false for non-objects (strings, numbers, etc), and for objects that are not empty.
 * 
 * @param {*} value The value to check.
 * @return {boolean} True if the value is an empty object, false otherwise.
 */
export function isObjectEmptyExtended(value: any) {
  if (value == null) {
    // null or undefined
    return false;
  }

  if (typeof value !== 'object') {
    // boolean, number, string, function, etc.
    return false;
  }

  const proto = Object.getPrototypeOf(value);

  // consider `Object.create(null)`, commonly used as a safe map
  // before `Map` support, an empty object as well as `{}`
  if (proto !== null && proto !== Object.prototype) {
    return false;
  }

  return isObjectEmpty(value);
}

/**
 * Checks the user object to see if the user has the specified permission.
 *
 * @param {User} user The user object.
 * @param {string} featureName The feature name.
 * @param {string} permissionName The permission name.
 * @return {boolean} True if the user has the permission, false otherwise. 
 */
export function hasPermission(user: User, featureName: Feature, permissionName: Permission): boolean {
  if (user?.isDeveloper) return true;

  // Short circuit to false if no user or organization
  if (!user?.organizations) return false;

  // Reference current selected org.
  const org = user.organizations[user.organizations.selected];

  // Short circuit to false if no details.
  if (!org?.features || !org?.featureDetails) {
    return false;
  }

  // Find feature with feature name.
  const featureFound = org.features.includes(featureName);

  // Find permission.
  const permissionFound = featureFound
    ? (org.featureDetails[featureName]).permissions.includes(permissionName)
    : false;

  // Check if feature has permission.
  const hasAccess = featureFound && permissionFound;

  return hasAccess;
}

export function isDeveloper(user: User): boolean {
  return user?.isDeveloper || false;
}

export function hasAnyPermissions(user: User, featureName: Feature, ...permissionNames: Permission[]): boolean {
  if (user?.isDeveloper) return true;

  // Short circuit to false if no user or organization
  if (!user?.organizations) return false;

  // Reference current selected org.
  const org = user.organizations[user.organizations.selected];

  // Short circuit to false if no details.
  if (!org?.features || !org?.featureDetails) {
    return false;
  }

  // Find feature with feature name.
  const featureFound = org.features.includes(featureName);

  // Find permission.
  const permissionFound = featureFound
    ? (org.featureDetails[featureName]).permissions.some(p => permissionNames.includes(p))
    : false;

  // Check if feature has permission.
  const hasAccess = featureFound && permissionFound;

  return hasAccess;
}

export function isFeatureAvailableForUser(user: User, feature: Feature) {
  if (user?.isDeveloper) return true;

  // Short circuit to false if no user or organization
  if (!user?.organizations) return false;

  // Reference current selected org.
  const org = user.organizations[user.organizations.selected];

  // Short circuit to false if no details.
  if (!org?.features || !org?.featureDetails) {
    return false;
  }

  return org.features
    .map(name => name.toLowerCase())
    .includes(feature.toLowerCase());
}
