<!-- an hidden div is created to set the position of appearance of the menu-->
<div style="visibility: hidden; position: fixed;" [style.left]="menuTopLeftPosition.x"
    [style.top]="menuTopLeftPosition.y" [matMenuTriggerFor]="orgBoardMenu"></div>

<!-- standard material menu -->
<mat-menu #orgBoardMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item *ngFor="let item of contextMenuItems" 
      (click)="item.action()" [disabled]="item.disabled"
    ><mat-icon [color]="item.colour">{{item.icon}}</mat-icon> {{item.display}}</button>
  </ng-template>
</mat-menu>


<!-- <div class="vh-90" [ngClass]="{'disable-mouse-events': isDragging}"> -->
<div class="org-board-component" [ngClass]="{'vh-90': !isEditing, 'h-100' : isEditing}" (contextmenu)="onRightClick($event)">
  <mat-progress-bar *ngIf="showLoader" color="primary" mode="indeterminate"></mat-progress-bar>
  <div class="org-board-header" [ngClass]="{'justify-content-between' : isEditing}">

    <!-- Enable editing button. -->
    <div *ngIf="mayEdit" class="edit-controls">
      <button mat-raised-button color="accent" class="ml-5" (click)="onEditClick(true);" *ngIf="!isEditing">Edit
        <mat-icon>edit</mat-icon></button>
      <button mat-raised-button color="primary" class="ml-5" (click)="onEditClick(false);" *ngIf="isEditing">Done
        <mat-icon>check</mat-icon></button>
    </div>

    <div class="flex-spacer" *ngIf="!isEditing"></div>

    <div *ngIf="tree?.config && !this.isEditing" [@smoothHeight] [@shrinkWidthLeave] [@delayedOpacity]
      style="display: inherit; flex-wrap: nowrap;">

      <!-- <button mat-icon-button (click)="export(); $event.stopPropagation()" title="Download current view of the org-board">
        <mat-icon>download</mat-icon>
      </button>  -->

      <!-- Link type -->
      <mat-form-field>
        <mat-label>Link type</mat-label>
        <mat-select [(ngModel)]="tree.config.linkType" (ngModelChange)="updateTree()">
          <mat-option *ngFor="let opt of lineOptions" [value]="opt.value">{{opt.display}}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Orientation -->
      <mat-form-field>
        <mat-label>Orientation</mat-label>
        <mat-select [(ngModel)]="tree.config.iRootOrientation"
          (ngModelChange)="tree.config.iRootOrientation=$event;updateTree()">
          <mat-option *ngFor="let opt of orientationOptions" [value]="opt.value">{{opt.display}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="this.isEditing" [@smoothHeight] [@shrinkWidthLeave] [@delayedOpacity] class="toolbar-functions">

      <ng-container [ngSwitch]="toolbarSelection">
          
        <div *ngSwitchCase="'users'" class="d-flex h-100" [@smoothHeight] [@slideInOut] [@delayedOpacity] >
          <div class="toolbox">
            <mat-selection-list #userSelect>
              <mat-list-option *ngFor="let user of userOptions" [value]="user.value">
                {{user.display}}
              </mat-list-option>
            </mat-selection-list>
          </div>

          <div class="toolbox">
            <p *ngFor="let u of userSelect.selectedOptions.selected">
              {{u.value}}
            </p>
          </div>

        </div>


        <div *ngSwitchCase="'hats'" class="d-flex h-100" [@smoothHeight] [@slideInOut] [@delayedOpacity] >
        
          <div class="toolbox">
            <mat-selection-list #userSelect>
              <mat-list-option *ngFor="let user of hatOptions" [value]="user.value">
                {{user.display}}
              </mat-list-option>
            </mat-selection-list>
          </div>

          <div class="toolbox">
            <p *ngFor="let u of userSelect.selectedOptions.selected">
              {{u.value}}
            </p>
          </div>

        </div>


        <div *ngSwitchCase="'policies'" class="d-flex h-100" [@smoothHeight] [@slideInOut] [@delayedOpacity] >
        
          <div class="toolbox">
            <mat-selection-list #userSelect>
              <mat-list-option *ngFor="let user of policyOptions" [value]="user.value">
                {{user.display}}
              </mat-list-option>
            </mat-selection-list>
          </div>

          <div class="toolbox">
            <p *ngFor="let u of userSelect.selectedOptions.selected">
              {{u.value}}
            </p>
          </div>

        </div>

        <div *ngSwitchCase="'security-groups'" class="d-flex h-100" [@smoothHeight] [@slideInOut] [@delayedOpacity] >
        
          <div class="toolbox">
            <mat-selection-list #userSelect>
              <mat-list-option *ngFor="let user of securityGroupOptions" [value]="user.value">
                {{user.display}}
              </mat-list-option>
            </mat-selection-list>
          </div>

          <div class="toolbox">
            <p *ngFor="let u of userSelect.selectedOptions.selected">
              {{u.value}}
            </p>
          </div>

        </div>


        <div *ngSwitchDefault>
          <!-- Nothing to show... -->
        </div>
      </ng-container>

    </div>

  </div>

  <div class="ob-container" [ngClass]="{'justify-content-end': !isEditing}">

    <!-- Side toolbar. -->
    <div *ngIf="isEditing" [@smoothHeight] [@shrinkWidthLeave] [@delayedOpacity] class="ob-toolbar">
      <h2>Toolbar</h2>
      <div class="ob-toolbar-content">
        <mat-selection-list #obSideToolbar [multiple]="false" hideSingleSelectionIndicator="true" (selectionChange)="onSelectSideToolbar($event)" >
          <div mat-subheader>Users</div>
          <mat-list-option value="users">
            <mat-icon matListItemIcon>account_circle</mat-icon>
            <div matListItemTitle>Users</div>
            <div matListItemLine>People within the organization.</div>
          </mat-list-option>
          <mat-list-option value="hats">
            <mat-icon matListItemIcon>school</mat-icon>
            <div matListItemTitle>Hats</div>
            <div matListItemLine>Hats worn by people.</div>
          </mat-list-option>
          <mat-list-option value="policies">
            <mat-icon matListItemIcon>policy</mat-icon>
            <div matListItemTitle>Policies</div>
            <div matListItemLine>Policies of the Organization.</div>
          </mat-list-option>
          <mat-list-option value="security-groups">
            <mat-icon matListItemIcon>badge</mat-icon>
            <div matListItemTitle>Security Groups</div>
            <div matListItemLine>Authorization for each Hat/User.</div>
          </mat-list-option>

          <mat-divider></mat-divider>
          <div mat-subheader>Quick Actions</div>
          <mat-list-option value="new-user">
            <mat-icon matListItemIcon>add</mat-icon>
            <div matListItemTitle>New User</div>
          </mat-list-option>
          <mat-list-option value="new-hat">
            <mat-icon matListItemIcon>add</mat-icon>
            <div matListItemTitle>New Hat</div>
          </mat-list-option>
          <mat-list-option value="new-policy">
            <mat-icon matListItemIcon>add</mat-icon>
            <div matListItemTitle>New Policy</div>
          </mat-list-option>
        </mat-selection-list>
        
      </div>
    </div>

    <!-- <div style="height: auto; position: absolute; width: calc(100% - 1px); left: 0;"> -->
    <!-- [ngClass]="{'drag-without-toolbar': !isEditing, 'drag-with-toolbar': isEditing}"  -->
    <div class="drag-container" [ngStyle]="{'width': orgBoardWidth}" id="dragScroll">

      <ng-container *ngIf="organization; else infoTemplate">
        <drag-scroll class="min-h-300 hover-hand" (dragStart)="onDragNavStart()" (dragEnd)="onDragNavEnd()"
          [drag-scroll-disabled]="isDragNavDisabled">

          <!-- Adjust as required. Just bare in mind all instances of the tree will be affected.
              This max height will allow the tree to be scrollable within the drag-scroll container.  -->
          <!-- <section drag-scroll-item id="adminTreeView" style="max-height: 80vh;"> -->
          <section id="adminTreeView" style="max-height: 80vh;">
            <div class="tree" id="dragScrollTree" *ngIf="tree.width && tree.height" [style.padding]="treePaddingScaled()" [style.width]="treeWidthScaled()" [style.height]="treeHeightScaled()">
              <div class="scroll-hover-region left" *ngIf="isDroppableItemBeingDragged"
                (touchstart)="scrollHorizontal(5)" (touchend)="stopInterval()" (touchcancel)="stopInterval()"
                (touchmove)="scrollHorizontal(5)" (mouseover)="scrollHorizontal(5)" (mouseleave)="stopInterval()"></div>
              <div class="scroll-hover-region top" *ngIf="isDroppableItemBeingDragged" (touchstart)="scrollVertical(5)"
                (touchend)="stopInterval()" (touchcancel)="stopInterval()" (touchmove)="scrollVertical(5)"
                (mouseover)="scrollVertical(5)" (mouseleave)="stopInterval()"></div>

              <!-- cdkDropListGroup -->
              <div class="wrapper" cdkDropListGroup [style.transform]="'scale(' + zoomLevel + ')'">
                <svg class="treeLink" [style.width]="tree.width + 'px'" [style.height]="tree.height + 'px'">
                  <path *ngFor="let path of childrenLinkPaths;" [attr.stroke]="path.stroke" [attr.d]="path.d" />
                </svg>

                <ng-container *ngFor="let node of tree.nDatabaseNodes;let i=index">
                  <div [ngStyle]="{
                        height: node.h +'px',
                        width: node.w +'px',
                        left: node.XPosition +'px',
                        top: node.YPosition +'px',
                        position: 'absolute',
                    }">

                    <div *ngIf="!node._isAncestorCollapsed()" class="draggable-item" [ngStyle]="{
                          height: node.h +'px',
                          width: node.w +'px'
                          }">

                      <ng-container
                        *ngTemplateOutlet="treeTemplate ? treeTemplate : defaultTemplate; context:{$implicit:node}"></ng-container>
                    </div>
                  </div>

                </ng-container>
              </div>

              <div class="scroll-hover-region bottom" *ngIf="isDroppableItemBeingDragged"
                (touchstart)="scrollVertical(-5)" (touchend)="stopInterval()" (touchcancel)="stopInterval()"
                (touchmove)="scrollVertical(-5)" (mouseover)="scrollVertical(-5)" (mouseleave)="stopInterval()"></div>
              <div class="scroll-hover-region right" *ngIf="isDroppableItemBeingDragged"
                (touchstart)="scrollHorizontal(-5)" (touchend)="stopInterval()" (touchcancel)="stopInterval()"
                (touchmove)="scrollHorizontal(-5)" (mouseover)="scrollHorizontal(-5)" (mouseleave)="stopInterval()">
              </div>
            </div>
          </section>

          <ng-template #defaultTemplate let-node>
            <div class="item" [ngStyle]="{'background-color':node.bc, color:node.c}">
              {{node.id}}
            </div>
          </ng-template>

        </drag-scroll>

        <!-- Zoom controls for board. -->
        <div class="zoom-controls" [ngClass]="{'m-0': isEditing}">
          <button mat-icon-button (click)="zoomIn(); $event.stopPropagation()">
            <mat-icon>zoom_in</mat-icon>
          </button>
          <button mat-icon-button (click)="zoomOut(); $event.stopPropagation()">
            <mat-icon>zoom_out</mat-icon>
          </button>

          <button mat-icon-button (click)="resetZoom(); $event.stopPropagation()">
            <mat-icon>zoom_out_map</mat-icon>
          </button>

          <div class="d-flex">
            <mat-slider style="flex: 1 auto;" [max]="2.5" [min]="0.15" [step]="0.15" [discrete]="true"
              [showTickMarks]="true">
              <input matSliderThumb [(ngModel)]="zoomLevel">
            </mat-slider>
          </div>
        </div>
      </ng-container>

      <ng-template #infoTemplate>
        <p>Select an organization to view the organization board.</p>
      </ng-template>
    </div>
  </div>

</div>


<!-- Template for each position on the org. -->
<ng-template #treeTemplate let-node>
  <!-- Minimize / Maximize toggle icon -->

  <div class="above-card"
    [ngStyle]="{'background-color': node?.isSelected ? node.bc : '#ccc', color: node?.isSelected ? node.c : '#000', 'opacity': node?.isSelected ? '1' : '0.7'}"
    [ngClass]="{'collapsable': node?.canCollapse}" (mousedown)="node?.canCollapse ? toggleNodeCollapse(node) : ''">
    <div class="org-position-toggle" *ngIf="node.canCollapse">
      <mat-icon>{{node.isCollapsed ? 'add' : 'remove'}}</mat-icon>
    </div>
    <span>{{node.id}}</span> {{node.data.name}}
  </div>

  <section (contextmenu)="onRightClickNode($event, node); $event.stopPropagation();" style="display: initial;">
    <ng-container [ngSwitch]="determineLayout(node.data.userDetailsArray)" cdkDropListGroup>
      <ng-container *ngSwitchCase="'ONE_USER'">

        <shared-one-user [data]="userDropDataMap[node.id]" [dropLists]="dropLists" [isDragNavDisabled]="isDragNavDisabled"
          [isEditing]="isEditing" [isDroppableItemBeingDragged]="isDroppableItemBeingDragged"
          [isNavigatingWithDrag]="isNavigatingWithDrag" [node]="node" 
          (dragEnded)="stopInterval(); onDragItemEnd()"
          (dragStarted)="onDragItemStart()" 
          (dropList)="updateDropList(node, $event)"
          (openUser)="onOpenUser($event)"
          (openTemplate)="sidebarService.openTemplate(sideBarOneUserTemplate, $event);"
          (selectSiblingNode)="selectSiblingNodes($event)" 
          (stateChange)="stateChange($event)"
          (templateDrop)="onDrop($event.event, $event.node)"
          [org]="organization"
        ></shared-one-user>

      </ng-container>
      <ng-container *ngSwitchCase="'MULTIPLE_USERS'">

        <shared-many-users [data]="userDropDataMap[node.id]" [dropLists]="dropLists" [isEditing]="isEditing"
          [isDragNavDisabled]="isDragNavDisabled" [isDroppableItemBeingDragged]="isDroppableItemBeingDragged"
          [isNavigatingWithDrag]="isNavigatingWithDrag" [node]="node" 
          (dragEnded)="stopInterval(); onDragItemEnd()"
          (dragStarted)="onDragItemStart()" 
          (dropList)="updateDropList(node, $event)"
          (openUser)="onOpenUser($event)"
          (openTemplate)="sidebarService.openTemplate(sideBarManyUserTemplate, $event);"
          (selectSiblingNode)="selectSiblingNodes($event)" 
          (stateChange)="stateChange($event)"
          (templateDrop)="onDrop($event.event, $event.node)"
          [org]="organization"
          ></shared-many-users>

      </ng-container>
      <!-- No user & default share the same template. -->
      <ng-container *ngSwitchDefault>

        <shared-no-user [data]="userDropDataMap[node.id]" [dropLists]="dropLists" [isDragNavDisabled]="isDragNavDisabled"
          [isEditing]="isEditing" [isDroppableItemBeingDragged]="isDroppableItemBeingDragged"
          [isNavigatingWithDrag]="isNavigatingWithDrag" [node]="node" 
          (dragEnded)="stopInterval(); onDragItemEnd()"
          (dragStarted)="onDragItemStart()" 
          (dropList)="updateDropList(node, $event)"
          (openUser)="onOpenUser($event)"
          (openTemplate)="sidebarService.openTemplate(sideBarEmptyUserTemplate, $event);"
          (selectSiblingNode)="selectSiblingNodes($event)" 
          (stateChange)="stateChange($event)"
          (templateDrop)="onDrop($event.event, $event.node)"
          [org]="organization"
          ></shared-no-user>

        </ng-container>
    </ng-container>
  </section>
</ng-template>

<ng-template #sideBarOneUserTemplate let-data>
  <div [@slideInOut] class="side-bar-content">
    <shared-sidebar-user 
      [node]="data.data" 
      [user]="data.data.data.userDetailsArray[0]" 
      [org]="organization" class="flex-1-auto w-100"
    ></shared-sidebar-user>

    <!-- Position Details -->
    <shared-sidebar-position-detail 
      [node]="data?.data" 
      [isEditing]="isEditing" 
      [isDev]="isDev" 
      [org]="organization" class="flex-1-auto w-100"
    ></shared-sidebar-position-detail>
  </div>
</ng-template>

<ng-template #sideBarManyUserTemplate let-data>
  <div [@slideInOut] class="side-bar-content" *ngFor="let user of data?.data?.data.userDetailsArray; first as isFirst;"
       [ngClass]="{'top-divider': data?.data?.data.userDetailsArray.length > 1 && !isFirst}">

    <shared-sidebar-user 
      [org]="organization" 
      [node]="data?.data" 
      [user]="user" class="flex-1-auto w-100"
    ></shared-sidebar-user>
  </div>

  <!-- Position Details -->
  <div [@slideInOut] class="side-bar-content">
    <shared-sidebar-position-detail 
      [org]="organization"
      [node]="data?.data" 
      [isEditing]="isEditing" 
      [isDev]="isDev" class="flex-1-auto w-100"
    ></shared-sidebar-position-detail>
  </div>

</ng-template>

<ng-template #sideBarEmptyUserTemplate let-data>
  <div [@slideInOut] class="side-bar-content">
    <shared-sidebar-empty-user 
      [org]="organization"
      [node]="data.data"
      [isEditing]="isEditing"
      [isDev]="isDev" class="flex-1-auto w-100"
    ></shared-sidebar-empty-user>

    <!-- Position Details -->
    <shared-sidebar-position-detail 
      [node]="data.data" 
      [isEditing]="isEditing" 
      [isDev]="isDev" class="flex-1-auto w-100"
    ></shared-sidebar-position-detail>
  </div>

  <!-- <div style="background-color: #909090; display: flex; height: 100%; width: 100%; flex: 1 auto;"></div> -->
</ng-template>