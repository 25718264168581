<mat-drawer-container [ngClass]="{'h-100': (!auth.isLoggedIn && !isUserInstantiated)}">

    <!-- Over laying menu content. -->
    <mat-drawer (openedStart)="hasBackDrop = true" #drawer mode="over" role="navigation">
        <section class="app-nav-list">
            <mat-selection-list [(ngModel)]="selectedOptions" id="main-nav-list" (selectionChange)="onMainNavSelect($event); drawer.toggle()" [multiple]="false" hideSingleSelectionIndicator="true">
                
                <!-- Main navigation menu items. -->
                <mat-list-option *ngFor="let item of navigationOptions" [value]="item">
                    <mat-icon mat-list-icon>{{item.icon}}</mat-icon>
                    <div mat-line>{{item.name}}</div>
                </mat-list-option>

            </mat-selection-list>
        </section>
    </mat-drawer>

    <!-- Dynamic template sidebar. -->
    <mat-drawer #drawerSecondary position="end"
        (openedStart)="hasBackDrop = false; sidebarService.markAsOpened();"
        (closedStart)="sidebarService.markAsClosed();" 
        [mode]="sidebarService.sidebarMode"
        class="min-w30-overflow-x-hidden"
    >
        <!-- Close button -->
        <div style="display: flex; flex: 1 auto; padding: 0.5rem; justify-content: space-between;">
            <button mat-icon-button (click)="drawerSecondary.toggle();">
                <mat-icon>close</mat-icon>
            </button>

            <!-- Toggle to change the mode of the drawer. -->
            <button mat-icon-button (click)="drawerSecondary.mode = drawerSecondary.mode === 'over' ? 'side' : 'over'">
                <mat-icon>swap_horiz</mat-icon>
            </button>
        </div>

        <mat-divider></mat-divider>

        <!-- Example content. Replace with template-based functionality for flexible side-bar behaviour. -->
        <ng-container *ngIf="!sidebarService.templateRef; else renderTemplate">
            <ng-container *ngTemplateOutlet="exampleTemplate"></ng-container>
        </ng-container>
        <ng-template #renderTemplate>
            <!-- Template with data. -->
            <ng-container *ngIf="sidebarService.templateData; else sidebarService.templateRef">
                <ng-container *ngTemplateOutlet="
                    sidebarService.templateRef; 
                    context: {$implicit: sidebarService.templateData}
                "></ng-container>
            </ng-container>
        </ng-template>
        
        <!-- <ng-container *ngTemplateOutlet="exampleTemplate"></ng-container> -->
    </mat-drawer>

    <!-- The entire App's content. -->
    <mat-drawer-content>

        <!-- Header Component -->
        <nav *ngIf="auth.isLoggedIn && isUserInstantiated && showHeader" [@smoothHeight] [@delayedOpacity]>
            <newgenus-header
                [hats]="this.hats"
                [settings]="this.settings" 
                (toggle)="drawer.toggle()"
                (secondSidenavToggle)="drawerSecondary.toggle();"
            ></newgenus-header>
        </nav>
    
        <!-- Focus Button -->
        <button class="sub-menu-expand" *ngIf="showFocusBtn && auth.isLoggedIn && isUserInstantiated" 
                [ngClass]="{
                    'completely-open': subNavSidebarState === 'expanded' && innerMenu.opened,
                    'rather-closed': subNavSidebarState === 'collapsed' && innerMenu.opened,
                    'completely-closed': !innerMenu.opened
                }" 
                mat-mini-fab color="secondary" aria-label="Expand Menu" role="button"
                (click)="innerMenu.toggle(); toggleFocusMode()"
            >
            <mat-icon style="line-height: 20px;">{{ innerMenu.opened ? 'keyboard_arrow_left' : 'keyboard_arrow_right'}}</mat-icon>
        </button>

        <mat-drawer-container #innerMenuContainer [ngClass]="{'height-less-header': auth.isLoggedIn && isUserInstantiated && showHeader}">

            <!-- The sub-directory navigation menu. -->
            <mat-drawer mode="side" opened #innerMenu class="side-menu-drawer">
                <div *ngIf="auth.isLoggedIn && isUserInstantiated" 
                        class="sidebar-container"
                        [ngClass]="{
                            'mobile-display': isSmallDisplay === true,
                            'collapsed': subNavSidebarState === 'collapsed', 'expanded': subNavSidebarState === 'expanded'
                    }">
                    <newgenus-sidebar 
                        [ngClass]="{
                            'd-flex h-100': (auth.isLoggedIn && isUserInstantiated),
                            'd-none': !(auth.isLoggedIn && isUserInstantiated)
                        }"
                        
                    ></newgenus-sidebar>
                </div>
            </mat-drawer>

            <!-- The app's content. -->
            <mat-drawer-content>
                <div style="overflow: hidden;">
                    <!-- <ng-container *ngIf="loadingRouteConfig">Loading route config...</ng-container> -->
                    <flash-messages></flash-messages>
                    <router-outlet></router-outlet>
                </div>
            </mat-drawer-content>
            
        </mat-drawer-container>
    </mat-drawer-content>
</mat-drawer-container>



<ng-template #exampleTemplate>
    <!-- Example content. Replace with template-based functionality for flexible side-bar behaviour. -->
    <div style="display: flex; flex: 1 auto; padding: 0.5rem; flex-direction: column;">
        <p> Side drawer example one two three four five six </p>
        <img style="display: block;" src="https://via.placeholder.com/350" alt="placeholder image">

        <div style="display: flex; flex: 1 auto; padding: 0.5rem 0;">
            <button class="mr-2" mat-raised-button color="primary">Primary</button>
            <button class="mr-2" mat-raised-button color="accent">Accent</button>
            <button class="mr-2" mat-raised-button color="warn">Warn</button>
            <button class="mr-2" mat-raised-button color="basic">Basic</button>
        </div>

        <div style="display: flex; flex: 1 auto; padding: 0.5rem 0;justify-content: space-between;">
            <img style="display: block;" src="https://via.placeholder.com/150" alt="placeholder image">
            <img style="display: block;" src="https://via.placeholder.com/150" alt="placeholder image">

        </div>
    </div>
</ng-template>