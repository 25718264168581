/* eslint-disable @angular-eslint/no-input-rename */
import { ComplexGraphData, DaySummaryDatum, GraphData, GraphDataBase, GraphLine, GraphStatus, Themes, enumerateDaysBetweenDates, onlyBusinessDays } from '@newgenus/common'
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, AfterViewInit } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import * as d3 from 'd3';

@Component({
    selector: 'shared-7r-graph-base',
    styleUrls: ['./graph-base.component.scss'],
    template: `<div [title]="title" [id]="id" class="canvas"></div> `,
})
export class SharedGraphBaseComponent implements OnDestroy, AfterViewInit, OnChanges {

    @Input()
    public title = '';

    @Input()
    public id = 'generated-' + uuidv4();

    @Input()
    // public data: Array<GraphData | ComplexGraphData> = [];
    public data: Array<GraphDataBase> = [];
    private targetData: GraphTargetData[] = []; // Calculated on `data` change.

    @Input()
    public secondaryData: Array<ComplexGraphData> = [];

    @Input()
    public tertiaryData: Array<ComplexGraphData> = [];

    @Input()
    public target = 0;

    @Input()
    public graphType: 'daily' | 'monthly' | 'client-abc' | 'un-instantiated' = 'un-instantiated';

    /**
     * This flag indicates if sales made outside of the working hours are grouped and shown in the graph.
     * For example, a working day of 8-17 with consolidateData set to true, sales made at 7am will show from 8am. Likewise, sales made after 17 will show on the next day, from 8am.
     */
    @Input()
    public consolidateData = false;

    @Input('graphMargin')
    public margin = { top: 40, right: 20, bottom: 50, left: 20 };

    @Input()
    public width = 470;
    private graphWidth!: number; // Calculated on init and on `margin` change.

    @Input()
    public height = 440;
    private graphHeight!: number; // Calculated on init and on `margin` change.

    @Input()
    public xExtent!: [Date, Date];

    @Input()
    public yExtent!: [number, number];

    @Input()
    public xTicks = 5;

    @Input()
    public xFormat = '%I %p';

    // @Input()
    public xScale!: d3.ScaleTime<number, number, never>;

    @Input()
    public repNames: { [key: number]: string } = {};

    @Input()
    public yScale!: d3.ScaleLinear<number, number, never>;

    // Default is start of day (00:00 localtime).
    @Input()
    public fromDate: Date = new Date(new Date().setHours(0, 0, 0, 0));

    // Default is end of day (23:59 localtime).
    @Input()
    public toDate: Date = new Date(new Date().setHours(23, 59, 59, 999));

    @Input('expanded')
    public isExpanded = false;

    @Input('weekends')
    public removeWeekends = false;

    public options = {
        expandMultiplier: {
            xScale: 1,
            yScale: 1,
            width: 1,
            height: 1,
            lineWidth: 1,
            fontSize: 1,
            circleRadius: 1
        },
        standardMultiplier: {
            xScale: 1,
            yScale: 1,
            width: 1,
            height: 1,
            lineWidth: 1,
            fontSize: 1,
            circleRadius: 1
        }
    }

    @Input('parentProps')
    public parentProperties = { height: 0, width: 0 };

    @Output()
    public graphStatus = new EventEmitter<GraphStatus>();

    @Input()
    public theme: Themes = Themes['default-theme'];

    @Output()
    public clickEntry = new EventEmitter<GraphData[] | ComplexGraphData[]>();
    public selectedGraphEntries: { [lineClass: string]: { index?: number; data?: GraphDataBase; reference?: any } } = {
        [GraphLine.primary]: { index: undefined, data: undefined, reference: undefined },
        [GraphLine.secondary]: { index: undefined, data: undefined, reference: undefined },
        [GraphLine.tertiary]: { index: undefined, data: undefined, reference: undefined },
    };

    // Graph fields.
    private svg!: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>;
    private graph!: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
    private xAxisGroup!: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
    private yAxisGroup!: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
    private targetLine!: d3.Line<GraphTargetData>;
    private targetPath!: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    private graphOverlay!: d3.Selection<SVGRectElement, unknown, HTMLElement, any>;
    private graphLegend!: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
    private legend!: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
    private legendData!: { color: string; class: string; text: string; marginLeft: number; }[];
    private legendItems!: d3.Selection<SVGGElement, { color: string; class: string; text: string; marginLeft: number; }, SVGGElement, unknown>;
    private clip!: d3.Selection<SVGRectElement, unknown, HTMLElement, any>;
    private legendMargin = 10;
    // --

    // Specific Graph Fields.
    private primaryLine!: d3.Line<GraphData>;
    private secondaryLine!: d3.Line<ComplexGraphData>;
    private tertiaryLine!: d3.Line<ComplexGraphData>;

    private primaryPath!: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    private salesOrdersPath!: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    private invoicesPath!: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;

    private dataGroup!: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
    private secondaryDataGroup!: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
    private tertiaryDataGroup!: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
    // --

    // Dynamic Graph Fields.
    private tooltip: { [lineClass: string]: d3.Selection<HTMLDivElement, unknown, HTMLElement, any> } = {};
    private tooltipLines: { [lineClass: string]: d3.Line<any> } = {};
    private tooltipLinePaths: { [lineClass: string]: d3.Selection<SVGPathElement, unknown, HTMLElement, any> } = {};
    private tooltipPositions: { [lineClass: string]: { x: number, y: number } } = {};
    private focus: { [lineClass: string]: d3.Selection<SVGGElement, unknown, HTMLElement, any> } = {};
    private dottedLines: { [lineClass: string]: d3.Selection<SVGGElement, unknown, HTMLElement, any> } = {};
    private xDottedLine: { [lineClass: string]: d3.Selection<SVGLineElement, unknown, HTMLElement, any> } = {};
    private yDottedLine: { [lineClass: string]: d3.Selection<SVGLineElement, unknown, HTMLElement, any> } = {};

    private previousHoverIndex: { [lineClass: string]: number | undefined } = {
        [GraphLine.primary]: undefined,
        [GraphLine.secondary]: undefined,
        [GraphLine.tertiary]: undefined
    };

    private bisectDate = d3.bisector<GraphDataBase, any>((d) => d.date).left;

    private lineSettings: { [lineClass: string]: { showLine: boolean; } } = { [GraphLine.primary]: { showLine: true }, [GraphLine.secondary]: { showLine: false }, [GraphLine.tertiary]: { showLine: false } };

    private colourPalette: { [lineClass: string]: { [theme: string]: { lineStroke: string, circleFill: string } } } = {
        [GraphLine.primary]: {
            'light-theme': {
                lineStroke: "#00bfa5",
                // circleFill: "#ccc"
                circleFill: "#fefefe00"
            },
            'dark-theme': {
                lineStroke: "#00bfa5",
                // circleFill: "#ffaaaafe"
                circleFill: "#fefefe00"
            },
            'default-theme': {
                lineStroke: "#00bfa5",
                // circleFill: "#ccc"
                circleFill: "#fefefe00"
            }
        },
        [GraphLine.secondary]: {
            'light-theme': {
                lineStroke: "#bf0079",
                circleFill: "#fefefe00"
                // circleFill: "#ccc"
            },
            'dark-theme': {
                lineStroke: "#bf0079",
                circleFill: "#fefefe00"
                // circleFill: "#aaffaafe"
            },
            'default-theme': {
                lineStroke: "#bf0079",
                circleFill: "#fefefe00"
                // circleFill: "#ccc"
            }
        },
        [GraphLine.tertiary]: {
            'light-theme': {
                lineStroke: "#b7bf00",
                circleFill: "#fefefe00"
                // circleFill: "#ccc"
            },
            'dark-theme': {
                lineStroke: "#b7bf00",
                circleFill: "#fefefe00"
                // circleFill: "#aaaafffe"
            },
            'default-theme': {
                lineStroke: "#b7bf00",
                circleFill: "#fefefe00"
                // circleFill: "#ccc"
            }
        },
    }
    // --

    private previousParentProperties = { width: 0, height: 0 };

    public ngAfterViewInit(): void {
        this.graphStatus.emit(GraphStatus.INIT);
        this.graphWidth = (this.width * (this.isExpanded ? this.options.expandMultiplier.width : this.options.standardMultiplier.width)) - this.margin.right - this.margin.left;
        this.graphHeight = (this.height * (this.isExpanded ? this.options.expandMultiplier.height : this.options.standardMultiplier.height)) - this.margin.top - this.margin.bottom;
        // document.getElementById(this.id).style.width = this.graphWidth + this.margin.right + this.margin.left + 'px';

        setTimeout(() => {
            this.drawGraph();
            if (this.isExpanded) this.updateGraphSizeAndRange();
            // else this.updateGraph(this.data);

            // Run this only after the graph has been drawn.
            setTimeout(() => { this.setDefaultForPreviousParentProperties(); });
        });
        // }, 100);
    }

    private setDefaultForPreviousParentProperties() {
        const canvas = window.document.getElementById(this.id);
        if (canvas?.clientHeight) {
            this.previousParentProperties.width = canvas.clientWidth;
            this.previousParentProperties.height = canvas.clientHeight;
        }
    }

    /**
     * Creates the elements required for the graph.
     * This is called only once, when the component is initialized.
     * 
     * Events are bound to the graph elements here.
     *
     * @memberof LineGraphBaseComponent
     */
    public drawGraph() {

        // Create the SVG element (base SVG for the entire graph, including axes and padded areas).
        this.svg = d3
            .select(`#${this.id}`)
            .append('svg')
            // .attr('width', this.graphWidth + this.margin.left + this.margin.right)
            // .attr('height', this.graphHeight + this.margin.top + this.margin.bottom + legendMargin);
            .attr('width', '100%')
            .attr('height', '100%')
            .attr("preserveAspectRatio", "xMinYMin meet")
            .attr('viewBox', `0 0 ${this.graphWidth + this.margin.left + this.margin.right} ${this.graphHeight + this.margin.top + this.margin.bottom + this.legendMargin}`);

        // let viewBoxHeight = 100;
        // let viewBoxWidth = 200;
        // this.svg = d3.select(this.hostElement).append('svg')
        //     .attr('width', '100%')
        //     .attr('height', '100%')
        //     .attr('viewBox', '0 0 ' + viewBoxWidth + ' ' + viewBoxHeight);

        // Create a group that we will use for drawing the SVG graph.
        this.graph = this.svg
            .append('g')
            .attr('id', `graph-${this.id}`)
            .attr('width', '100%')
            .attr('height', '100%')
            // .attr('width', this.graphWidth)
            // .attr('height', this.graphHeight)
            .attr('transform', `translate(${this.margin.left}, ${this.margin.top + this.legendMargin})`);

        this.graphLegend = this.svg
            .append('g')
            .attr('id', `graph-legend-${this.id}`)
            .attr('height', this.legendMargin - 10)
            .attr('width', '100%')
            // .attr('width', this.graphWidth)
            // .attr('height', this.graphHeight)
            .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);


        // Create scales for the graph.
        this.yScale = d3.scaleLinear().range([this.graphHeight, 0]);
        this.xScale = d3.scaleTime().range([0, this.graphWidth]);

        // Create the x axis group.
        this.xAxisGroup = this.graph.append('g').attr('class', 'x-axis').attr('transform', 'translate(0,' + this.graphHeight + ')');

        // Create the y axis group.
        this.yAxisGroup = this.graph.append('g').attr('class', 'y-axis');

        // Create a clip path: everything out of this area won't be drawn.
        this.clip = this.graph.append("defs").append("clipPath")
            .attr("id", `clip-${this.id}`)
            .append("rect")
            .attr("id", `clip-rect-${this.id}`)
            .attr("x", 0)
            .attr("y", 0)
            // Make the clip rect slightly larger than the graph to prevent the line from disappearing when it reaches the edge.
            .attr('width', '110%')
            .attr('height', '110%')
            // .attr("width", this.graphWidth + this.margin.left + this.margin.right)
            // .attr("height", this.graphHeight + 11 + legendMargin)
            .attr('transform', `translate(-10, 0)`);

        // Create a group for the data so we can clip it.
        this.dataGroup = this.graph
            .append('g')
            .attr('id', `data-group-${this.id}`)
            .attr("clip-path", `url(#clip-${this.id})`)
            .attr('width', '100%')
            .attr('height', '100%');
        // .attr('width', this.graphWidth)
        // .attr('height', this.graphHeight);
        this.secondaryDataGroup = this.graph
            .append('g')
            .attr('id', `secondary-data-group-${this.id}`)
            .attr("clip-path", `url(#clip-${this.id})`)
            .attr('width', '100%')
            .attr('height', '100%');
        // .attr('width', this.graphWidth)
        // .attr('height', this.graphHeight);
        this.tertiaryDataGroup = this.graph
            .append('g')
            .attr('id', `tertiary-data-group-${this.id}`)
            .attr("clip-path", `url(#clip-${this.id})`)
            .attr('width', '100%')
            .attr('height', '100%');
        // .attr('width', this.graphWidth)
        // .attr('height', this.graphHeight);

        // Generate the line for the graph data.
        this.primaryLine = d3
            .line<GraphData>()
            .x((d) => this.xScale(new Date(d.date)))
            .y((d) => this.yScale(d.valueCompounded));
        // Prepare the salesOrder line for the graph data.
        this.secondaryLine = d3
            .line<ComplexGraphData>()
            .x((d) => this.xScale(new Date(d.date)))
            .y((d) => this.yScale(d.valueCompounded));
        // Prepare the invoices line for the graph data.
        this.tertiaryLine = d3
            .line<ComplexGraphData>()
            .x((d) => this.xScale(new Date(d.date)))
            .y((d) => this.yScale(d.valueCompounded));

        // Generate the line for the target.
        this.targetLine = d3
            .line<GraphTargetData>()
            .x((d) => this.xScale(new Date(d.date)))
            .y((d) => this.yScale(d.value));

        // Create the path for the graph data (This is clipped to not overlay the axes).
        this.primaryPath = this.graph.append('path')
            .attr("clip-path", `url(#clip-${this.id})`)
            .attr("class", "primary-path");

        this.salesOrdersPath = this.graph.append('path')
            .attr("clip-path", `url(#clip-${this.id})`)
            .attr("class", "secondary-path");

        this.invoicesPath = this.graph.append('path')
            .attr("clip-path", `url(#clip-${this.id})`)
            .attr("class", "tertiary-path");

        // Create the target path.
        this.targetPath = this.graph.append('path')
            .attr("clip-path", `url(#clip-${this.id})`);

        // Create a tooltip container to prevent overlapping.
        if (d3.select(`body`).select('.tooltip-container').empty()) {
            d3.select(`body`).append('div')
                .attr('class', 'tooltip-container')
                .attr('id', `tooltip-container-${this.id}`)
                .attr('width', '100%')
                .attr('height', '100%');

            // d3.select(`body`).append('div')
            // .attr('class', 'tooltip-container')
            // .attr('id', `tooltip-container-${this.id}`)
            // .attr('width', '100%')
            // .attr('height', '100%');
            // .attr("preserveAspectRatio", "xMinYMin meet")
            // .attr('viewBox', `0 0 ${this.graphWidth + this.margin.left + this.margin.right} ${this.graphHeight + this.margin.top + this.margin.bottom + this.legendMargin}`)
        }
        // const tooltipContainer =  d3.select(`body`).select('.tooltip-container');
        const tooltipContainer = this.graph;

        // Create the tooltips paths containers.
        this.tooltipLinePaths[GraphLine.primary] = tooltipContainer.append('path');
        if (this.graphType == 'daily') {
            this.tooltipLinePaths[GraphLine.secondary] = tooltipContainer.append('path');
            this.tooltipLinePaths[GraphLine.tertiary] = tooltipContainer.append('path');
        }

        // Build tooltips.
        this.buildForGraphLine(GraphLine.primary);
        if (this.graphType == 'daily') {
            this.buildForGraphLine(GraphLine.secondary);
            this.buildForGraphLine(GraphLine.tertiary);

            // Create interactive legend, for toggling on/off the different lines (SalesOrders and Invoice line).
            this.buildLegend(); // TODO if there are other types of graphs in the future, this might need to be moved to a separate if statement outside of the build lines for graph.
        }

        // Graph overlay for crosshair (cursor tracking) and tooltip.
        this.graphOverlay = this.graph
            .append('rect')
            .attr('class', 'graph-overlay')
            .attr('width', this.graphWidth + 100)
            .attr('height', this.graphHeight + 100)
            .on('mouseover', () => {
                if (this.lineSettings[GraphLine.primary].showLine) this.focus[GraphLine.primary].style('opacity', 1);

                if (this.graphType === 'daily') {

                    if (this.lineSettings[GraphLine.secondary].showLine) this.focus[GraphLine.secondary].style('opacity', 1);

                    if (this.lineSettings[GraphLine.tertiary].showLine) this.focus[GraphLine.tertiary].style('opacity', 1);
                }
            })
            .on('mouseout', () => {
                // Hide the crosshair.
                this.focus[GraphLine.primary].style('opacity', 0);

                // Hide the tooltip and lines.
                this.tooltip[GraphLine.primary].transition().duration(200).style('opacity', 0);
                this.tooltipLinePaths[GraphLine.primary].attr('d', []);

                // Hide the secondary and tertiary lines and tooltips.
                if (this.graphType === 'daily') {
                    this.focus[GraphLine.secondary].style('opacity', 0);
                    this.focus[GraphLine.tertiary].style('opacity', 0);

                    this.tooltip[GraphLine.secondary].transition().duration(200).style('opacity', 0);
                    this.tooltip[GraphLine.tertiary].transition().duration(200).style('opacity', 0);
                    this.tooltipLinePaths[GraphLine.secondary].attr('d', []);
                    this.tooltipLinePaths[GraphLine.tertiary].attr('d', []);
                }
            })
            .on('mousemove', (event) => {
                // Get the mouse position (X, Y).
                const mouse = d3.pointer(event);
                // D3 magic to get the date from the mouse position.
                const mouseDate = this.xScale.invert(mouse[0]);
                // console.clear();

                // Order is calculated based on which lines are visible and in which order.
                const primaryIndex = this.bisectDate(this.data, mouseDate); // Primary is always visible.
                let secondaryIndex = -2, tertiaryIndex = -2; // Secondary and Tertiary are optional, so set to -2 to indicate that they are not visible.
                if (this.graphType === 'daily') {
                    secondaryIndex = this.lineSettings[GraphLine.secondary].showLine ? this.bisectDate(this.secondaryData, mouseDate) : -2;
                    tertiaryIndex = this.lineSettings[GraphLine.tertiary].showLine ? this.bisectDate(this.tertiaryData, mouseDate) : -2;
                }
                const primaryPos = this.getPositionOfDataPoint(primaryIndex, mouseDate, this.data),
                    secondaryPos = this.getPositionOfDataPoint(secondaryIndex, mouseDate, this.secondaryData),
                    tertiaryPos = this.getPositionOfDataPoint(tertiaryIndex, mouseDate, this.tertiaryData);

                let positions: Array<any> = [];
                positions.push({ ...primaryPos, line: GraphLine.primary, data: this.data, index: primaryIndex, dataGroup: this.dataGroup },
                    { ...secondaryPos, line: GraphLine.secondary, data: this.secondaryData, index: secondaryIndex, dataGroup: this.secondaryDataGroup },
                    { ...tertiaryPos, line: GraphLine.tertiary, data: this.tertiaryData, index: tertiaryIndex, dataGroup: this.tertiaryDataGroup });

                // Sort by Y descending with undefined being valued at 0. Y descending means from the bottom of the screen up.
                positions = positions
                    .filter(pos => pos?.y !== undefined)
                    .sort((pos1, pos2) => {
                        const a = (pos1?.y || 0), b = (pos2?.y || 0);
                        return a === b ? 0 : (a < b) ? -1 : 1;
                    });
                const order = positions.map(pos => pos.line);

                // Fireoff the mousemove event for each line.
                positions.forEach(pos => {
                    // Get the index to the current data item
                    this.onMouseMoveOverGraph(pos.index, mouseDate, pos.data, pos.dataGroup, pos.line, order);
                });
            })
            .on('click', (event) => {
                // Get the mouse position (X, Y).
                const mouse = d3.pointer(event);
                // D3 magic to get the date from the mouse position.
                const mouseDate = this.xScale.invert(mouse[0]);


                if (this.lineSettings[GraphLine.primary].showLine) {
                    // Get the index to the current data item
                    const primaryIndex = this.bisectDate(this.data, mouseDate); // returns the index to the current data item
                    this.onGraphLineClick(primaryIndex, mouseDate, this.data, this.dataGroup, GraphLine.primary);
                }

                if (this.graphType === 'daily') {
                    if (this.lineSettings[GraphLine.secondary].showLine) {
                        const secondaryIndex = this.bisectDate(this.secondaryData, mouseDate);
                        this.onGraphLineClick(secondaryIndex, mouseDate, this.secondaryData, this.secondaryDataGroup, GraphLine.secondary);
                    }

                    if (this.lineSettings[GraphLine.tertiary].showLine) {
                        const tertiaryIndex = this.bisectDate(this.tertiaryData, mouseDate);
                        this.onGraphLineClick(tertiaryIndex, mouseDate, this.tertiaryData, this.tertiaryDataGroup, GraphLine.tertiary);
                    }
                }

                this.emitSelectedEntries();
            });

        // Emit status update event.
        this.graphStatus.emit(GraphStatus.DRAWN);
    }

    private getPositionOfDataPoint(index: number, mouseDate: Date, data: GraphDataBase[]): { x: number, y: number } | undefined {
        // Data item before the mouse.
        const d0 = data[index - 1];
        // Data item after the mouse.
        const d1 = data[index];
        // If either are undefined, return method.
        if (!d0 && !d1) return;

        // Determine which date value is closest to the mouse
        const datum: GraphDataBase | undefined = mouseDate.getTime() - d0?.date?.getTime() > d1?.date?.getTime() - mouseDate.getTime() ? d1 : d0;

        if (datum) {
            // Get the X and Y position of the data item on the graph.
            const x = this.xScale(datum.date);
            const y = this.yScale(datum.valueCompounded);

            return { x, y };
        } else {
            return undefined;
        }
    }


    private buildLegend(): void {
        // Populate the legend data array.
        this.legendData = [
            // The "class" is set on the line when drawing it, and used to identify the line when toggling it on/off and doing other things...
            { color: this.colourPalette[GraphLine.primary][this.theme].lineStroke, class: GraphLine.primary, text: 'Total', marginLeft: 0 },
            // The "class" is set on the line when drawing it, and used to identify the line when toggling it on/off and doing other things...
            { color: this.colourPalette[GraphLine.secondary][this.theme].lineStroke, class: GraphLine.secondary, text: 'Sales Orders', marginLeft: 65 },
            // The "class" is set on the line when drawing it, and used to identify the line when toggling it on/off and doing other things...
            { color: this.colourPalette[GraphLine.tertiary][this.theme].lineStroke, class: GraphLine.tertiary, text: 'Invoices', marginLeft: 190 }
        ];

        // Set the default line settings.
        this.legendData.forEach((d) => {
            this.lineSettings[d.class] = { showLine: true };
        });

        // Create the legend on the top right of the graph.
        this.legend = this.graphLegend
            .append('g')
            .attr('class', 'legend');

        // Create the legend items.
        this.legendItems = this.legend
            .selectAll('g')
            .data(this.legendData)
            .enter()
            .append('g')
            .style('cursor', 'pointer')
            .attr('class', (d) => 'legend-item-' + d.class);

        // Create the legend rectangles.
        this.legendItems
            .append('rect')
            .attr('x', 10)
            .attr('y', 0)
            .attr('width', 10)
            .attr('height', 10)
            .attr('fill', (d) => d.color)
            .style('cursor', 'pointer')
            .on('click', (event, d) => {
                this.toggleLine(d);
            });

        // Create the legend text.
        this.legendItems
            .append('text')
            .attr('x', 28)
            .attr('y', 10)
            .attr('text-anchor', 'start')
            .text((d) => d.text)
            .on('click', (event, d) => {
                this.toggleLine(d);
            });

        // Align legend items to the top left of the graph, vertical to each other.
        this.legendItems.attr('transform', (d, i) => {
            return `translate(${d.marginLeft}, -20)`;
        });
    }

    /**
     * Takes in a GraphLine and prepares the dotted lines (focus lines) and tooltip for that line.
     * For monthly, this would only be called once.
     * For daily, this would be called three times (once for each line).
     * @param graphLine  The GraphLine to build the dotted lines and tooltip for.
     */
    private buildForGraphLine(graphLine: GraphLine): void {
        // Create dotted line group and append to graph.
        this.dottedLines[graphLine] = this.graph.append('g').attr('class', 'focus-' + graphLine).style('opacity', 0);

        // Create x dotted line and append to dotted line group.
        this.xDottedLine[graphLine] = this.dottedLines[graphLine]
            .append('line')
            .attr('stroke', '#aaa')
            .attr('stroke-width', 1)
            .attr('stroke-dasharray', 4);

        // Create y dotted line and append to dotted line group.
        this.yDottedLine[graphLine] = this.dottedLines[graphLine]
            .append('line')
            .attr('stroke', '#aaa')
            .attr('stroke-width', 1)
            .attr('stroke-dasharray', 4);

        // Create tooltip element.
        this.tooltip[graphLine] = d3.select(`body`)
            .select('.tooltip-container')
            .append('div')
            .attr('class', `graph-tooltip ${graphLine}-graph-tooltip`)
            .style('opacity', 0);

        // Create tooltip lines element, to draw a line from the tooltip to the graph datapoint.
        this.tooltipLines[graphLine] = d3.line<{ x: number, y: number }>()
            .x(d => d.x)
            .y(d => d.y);

        // Cursor tracking group.
        this.focus[graphLine] = this.graph.append('g').style('opacity', 0);

        // Cursor tracking lines.
        this.focus[graphLine]
            .append('line')
            .attr('id', `focusLineX-${graphLine}-${this.id}`)
            .attr('class', 'focusLine');
        this.focus[graphLine]
            .append('line')
            .attr('id', `focusLineY-${graphLine}-${this.id}`)
            .attr('class', 'focusLine');
    }

    private onGraphLineClick(index: any, mouseDate: Date, data: GraphDataBase[], dataGroup: d3.Selection<SVGGElement, unknown, HTMLElement, any>, lineIdentifier: GraphLine) {
        // console.log('onGraphLineClick', index, mouseDate, data, dataGroup, lineIdentifier);

        // Data item before the mouse.
        const d0 = data[index - 1]; // Data item before the mouse.
        // Data item after the mouse.
        const d1 = data[index]; // Data item after the mouse.
        // If either are undefined, return method.
        if (!d0 && !d1) return;

        // Determine which date value is closest to the mouse
        const datumIndex = mouseDate.getTime() - d0?.date?.getTime() > d1?.date?.getTime() - mouseDate.getTime() ? index : index - 1;
        const datum = mouseDate.getTime() - d0?.date?.getTime() > d1?.date?.getTime() - mouseDate.getTime() ? d1 : d0;

        const circleNode = dataGroup.selectAll('circle').nodes()[datumIndex];

        // Undo visual indicator on previous selection.
        if (this.selectedGraphEntries[lineIdentifier].reference) {
            d3.select(this.selectedGraphEntries[lineIdentifier].reference)
                .transition()
                .duration(100)
                .attr('r', 3 * (this.isExpanded ? this.options.expandMultiplier.circleRadius : this.options.standardMultiplier.circleRadius))
                .attr('fill', this.colourPalette[lineIdentifier][this.theme].circleFill)
                .attr('opacity', 0.5);
        }

        // Remove visual indicator if the same circle is clicked again.
        if (this.selectedGraphEntries[lineIdentifier].reference?.id === (<any>circleNode).id) {
            this.selectedGraphEntries[lineIdentifier].reference = undefined;
            this.selectedGraphEntries[lineIdentifier].data = undefined;
            this.selectedGraphEntries[lineIdentifier].index = undefined;


            // Shrink the circle, decolour it.
            d3.select(circleNode).transition().duration(100)
                .attr('r', 3 * (this.isExpanded ? this.options.expandMultiplier.circleRadius : this.options.standardMultiplier.circleRadius))
                .attr('fill', this.colourPalette[lineIdentifier][this.theme].circleFill)
                .attr('opacity', 0.5);

            // Hide the dotted lines.
            this.dottedLines[lineIdentifier].style('opacity', 0);

            // Emit to parent component that the section is now null.
            // const selectedGraphEntries = Object.values(this.selectedGraphEntry).map(x => x.data);
            // this.clickEntry.emit(selectedGraphEntries as any[]);

        } else { // Otherwise, show the visual indicator.

            (<any>circleNode).id = Date.now() + ''; // Generate a unique id for the circle node.
            this.selectedGraphEntries[lineIdentifier].reference = circleNode;  // Store the [heap] reference to the circle node.
            this.selectedGraphEntries[lineIdentifier].data = datum; // Store the data of the circle node.
            this.selectedGraphEntries[lineIdentifier].index = datumIndex; // Store the index of the circle node.

            // Emit the selected data to the parent component.
            // const selectedGraphEntries = Object.values(this.selectedGraphEntry).map(x => x.data);
            // this.clickEntry.emit(selectedGraphEntries as any[]);

            // Visual indicator on the selected circle - green and slightly larger.
            d3.select(circleNode).transition().duration(100)
                .attr('r', 6 * (this.isExpanded ? this.options.expandMultiplier.circleRadius : this.options.standardMultiplier.circleRadius))
                .attr('fill', '#aaeeaa')
                .attr('opacity', 1);

            // Set the dotted lines to the selected circle.
            this.xDottedLine[lineIdentifier]
                .attr('x1', this.xScale(new Date(datum.date)))
                // .attr('x2', this.xScale(moment(new Date(d.date)).set('hour', 17).set('minute', 0)))
                .attr('x2', this.xScale(new Date(datum.date)))
                .attr('y1', this.graphHeight)
                .attr('y2', this.yScale(datum.valueCompounded));
            this.yDottedLine[lineIdentifier]
                .attr('x1', 0)
                .attr('x2', this.xScale(new Date(datum.date)))
                .attr('y1', this.yScale(datum.valueCompounded))
                .attr('y2', this.yScale(datum.valueCompounded));

            // Show the dotted lines.
            this.dottedLines[lineIdentifier].style('opacity', 1);
        }
    }

    private onMouseMoveOverGraph(index: number, mouseDate: Date, data: GraphDataBase[], dataGroup: d3.Selection<SVGGElement, unknown, HTMLElement, any>, lineIdentifier: GraphLine, order: Array<GraphLine> = []): void {
        // Data item before the mouse.
        const d0 = data[index - 1];
        // Data item after the mouse.
        const d1 = data[index];
        // If either are undefined, return method.
        if (!d0 && !d1) return;

        // Determine which date value is closest to the mouse
        const datumIndex = mouseDate.getTime() - d0?.date?.getTime() > d1?.date?.getTime() - mouseDate.getTime() ? index : index - 1;
        const datum = mouseDate.getTime() - d0?.date?.getTime() > d1?.date?.getTime() - mouseDate.getTime() ? d1 : d0;

        // If datum is undefined (mouse has moved outside graph's edge), reset the tooltip, focus, previousHoverIndex and remove hover visual indicators.
        if (!datum || (datum.valueCompounded < 0 && !this.isExpanded)) {
            // Hide Tooltip and line.
            this.tooltip[lineIdentifier].transition().duration(200).style('opacity', 0);
            this.tooltipLinePaths[lineIdentifier].attr('d', []);

            // Hide focus line.
            this.focus[lineIdentifier].style('opacity', 0);

            const previousItem = this.previousHoverIndex[lineIdentifier];

            // Reset the previous hovered data point, if it's not the selected data point.
            if (previousItem !== this.selectedGraphEntries[lineIdentifier]?.index && previousItem !== undefined && ~previousItem) {
                (dataGroup.selectAll('circle').nodes()[previousItem] as any)?.setAttribute('r', 3 * (this.isExpanded ? this.options.expandMultiplier.circleRadius : this.options.standardMultiplier.circleRadius));
                (dataGroup.selectAll('circle').nodes()[previousItem] as any)?.setAttribute('opacity', 0.5);
            }

            // Reset the current hovered data point, if it is not the selected data point.
            if (datumIndex !== this.selectedGraphEntries[lineIdentifier]?.index && previousItem !== undefined && ~datumIndex) {
                (dataGroup.selectAll('circle').nodes()[datumIndex] as any)?.setAttribute('r', 3 * (this.isExpanded ? this.options.expandMultiplier.circleRadius : this.options.standardMultiplier.circleRadius));
                (dataGroup.selectAll('circle').nodes()[previousItem] as any)?.setAttribute('opacity', 0.5);
            }

            this.previousHoverIndex[lineIdentifier] = undefined;
        } else { // Otherwise, show the focus, tooltip and set the previousHoverIndex.

            // Get the X and Y position of the data item on the graph.
            const x = this.xScale(datum.date);
            const y = this.yScale(datum.valueCompounded);

            // Get the position of the svg container in the client's view.
            const graphPos = this.svg.node()?.getBoundingClientRect() as DOMRect;

            // When y is less than 0, it means the y position is above the graph - so we don't show the tooltip.
            if (y >= 0) {
                // Show the tooltip and lines.
                this.tooltip[lineIdentifier].transition().duration(100).style('opacity', 0.9);
                // Show the focus lines.
                this.focus[lineIdentifier].style('opacity', 1);

                // Height of tooltip is 97px. Aligned with the data point is 81px, as the padding adds 16px.
                // First line will have 0 base height, secondary will have 97 base height, tertiary will have 194 base height.
                let tooltipStackHeight = order.indexOf(lineIdentifier) * 97;
                // console.log(lineIdentifier, 'order', order);
                // console.log(lineIdentifier, 'tooltipStackHeight', tooltipStackHeight);
                const topOfGraphToDocument = graphPos.y + this.margin.top + this.legendMargin;
                const bottomOfGraphToDocument = graphPos.y + this.graphHeight + this.margin.bottom + this.legendMargin;
                const isDailyAndPrimaryLine = this.graphType === 'daily' && lineIdentifier === GraphLine.primary;

                this.tooltipPositions[lineIdentifier] = {
                    x: graphPos.x + x + 73, // graphPos.x is the left position of the svg container. Add the x position of the data item to it and move it 73 pixels to the right.
                    y: graphPos.y + y + 24 // graphPos.y is the top position of the svg container. Add the y position of the data item to it and move it 24 pixels down.
                };

                // Bottom of the graph is determined by the height of the tooltip relative by 2 tooltips (97 * 2).
                const isNearBottomOfGraph = isDailyAndPrimaryLine && bottomOfGraphToDocument - this.tooltipPositions[lineIdentifier].y < (97 * 2);
                // True if near the top, or if the container is smaller than the height of 3 tooltips (97 * 3).
                const shouldPositionToTheLeft = isDailyAndPrimaryLine && (this.tooltipPositions[lineIdentifier].y - topOfGraphToDocument < 97) || (bottomOfGraphToDocument - topOfGraphToDocument < (97 * 3));

                if (isNearBottomOfGraph) {
                    // Move the tooltip up by the difference in height.
                    this.tooltipPositions[lineIdentifier].y = bottomOfGraphToDocument - (97 * 2);
                }
                if (shouldPositionToTheLeft) {
                    // Move the tooltip to the left of the data point. 200px width + 2px mine adjustments.
                    this.tooltipPositions[lineIdentifier].x = graphPos.x + x - 202;
                }

                // if the calculated Y is within 97 pixels of the previous tooltip, move it down by the difference in height.
                const heightDifference = this.tooltipPositions[lineIdentifier].y - this.tooltipPositions[order[order.indexOf(lineIdentifier) - 1]]?.y;

                if (isNaN(heightDifference)) {
                    if (tooltipStackHeight === 97) tooltipStackHeight = 0
                    else if (tooltipStackHeight === 194) tooltipStackHeight = 97
                    else if (tooltipStackHeight === 291) tooltipStackHeight = 194
                };

                // console.log(lineIdentifier, '> tooltipPositions[lineIdentifier].y', this.tooltipPositions[lineIdentifier].y?.toFixed(2));
                // console.log(lineIdentifier, '> heightDifference', heightDifference?.toFixed(2));
                // console.log(lineIdentifier, '> tooltipStackHeight', tooltipStackHeight?.toFixed(2));
                // Move up if between -97 and -41.
                if (heightDifference >= -97 && heightDifference <= -41) {
                    // console.log('true here: heightDifference <= -97 && heightDifference <= -41');
                    this.tooltipPositions[lineIdentifier].y = (this.tooltipPositions[lineIdentifier].y - (105 - Math.abs(heightDifference)))
                }
                // Move down if between -41 and 0.
                else if (heightDifference >= -41 && heightDifference < 0) {
                    // console.log('true here: heightDifference <= -41 && heightDifference < 0');
                    this.tooltipPositions[lineIdentifier].y = (this.tooltipPositions[lineIdentifier].y + Math.abs(heightDifference) + 105)
                }
                else if (heightDifference >= 0 && heightDifference <= 97) {
                    // console.log('true here: heightDifference >= 0 && heightDifference <= 97')
                    this.tooltipPositions[lineIdentifier].y = (this.tooltipPositions[lineIdentifier].y + (Math.abs(heightDifference - 105)))
                };

                // console.log(lineIdentifier, '> updated Y:', this.tooltipPositions[lineIdentifier].y?.toFixed(2));

                // Set the tooltip text to the datum.
                this.tooltip[lineIdentifier]
                    .html(this.buildTooltipHtml(datum as GraphData, lineIdentifier))
                    // Position the tooltip to the right of the element being hovered.
                    .style('left', this.tooltipPositions[lineIdentifier].x + 'px')
                    .style('top', this.tooltipPositions[lineIdentifier].y - tooltipStackHeight + 'px')
                    .style('order', (order.indexOf(lineIdentifier) + 1).toString());


                // console.log(lineIdentifier, 'tooltipPositions[x]', this.tooltipPositions[lineIdentifier]);
                // console.log(lineIdentifier, 'shouldPositionToTheLeft', shouldPositionToTheLeft);
                // console.log(lineIdentifier, 'isNearBottomOfGraph', isNearBottomOfGraph);
                // console.log(lineIdentifier, 'topOfGraphToDocument', topOfGraphToDocument);

                const xDifference = shouldPositionToTheLeft ? x - 38 : x + 38; // - 216; // -216 represents the width of the tooltip plus the padding.
                const yDifference = this.tooltipPositions[lineIdentifier].y - topOfGraphToDocument + 32; // +32 = +2rem.
                const lineData = [];

                // console.log(lineIdentifier, `line start: x: ${x}, y: ${y}`);
                // console.log(lineIdentifier, `line end: x: ${xDifference}, y: ${yDifference}`);

                if (datum) lineData.push(
                    {
                        x: x,
                        y: y,
                    },
                    {
                        x: xDifference,
                        y: yDifference,
                    },
                );

                // Draw the tooltip line.
                this.tooltipLinePaths[lineIdentifier]
                    .data([lineData])
                    .attr('fill', 'none')
                    .attr('stroke', this.colourPalette[lineIdentifier][this.theme].lineStroke + '70')
                    .attr('stroke-width', '2')
                    .attr('stroke-dasharray', 5)
                    .attr('d', this.tooltipLines[lineIdentifier]);

            } else {
                // Hide the tooltip when the focus shifts to a datum (node) outside the graph scope.
                this.tooltip[lineIdentifier].transition().duration(200).style('opacity', 0);
                this.tooltipLinePaths[lineIdentifier].attr('d', []);
            }

            const previousItem = this.previousHoverIndex[lineIdentifier];

            // Reset the previous hovered data point, if it's not the clicked data point.
            if (previousItem !== this.selectedGraphEntries[lineIdentifier]?.index && previousItem !== undefined && ~previousItem) {
                (dataGroup.selectAll('circle').nodes()[previousItem] as any)?.setAttribute('r', 3 * (this.isExpanded ? this.options.expandMultiplier.circleRadius : this.options.standardMultiplier.circleRadius));
                (dataGroup.selectAll('circle').nodes()[previousItem] as any)?.setAttribute('opacity', 0.5);
            }

            // Enlarge the current hovered data point, if it's not the clicked data point.
            if (datumIndex !== this.selectedGraphEntries[lineIdentifier]?.index && ~datumIndex) {
                (dataGroup.selectAll('circle').nodes()[datumIndex] as any)?.setAttribute('r', 6 * (this.isExpanded ? this.options.expandMultiplier.circleRadius : this.options.standardMultiplier.circleRadius));
                (dataGroup.selectAll('circle').nodes()[datumIndex] as any)?.setAttribute('opacity', 1);
            }

            // Set the previousHoverIndex to the current hovered data point.
            this.previousHoverIndex[lineIdentifier] = datumIndex;

            // Set the focus lines..
            this.focus[lineIdentifier]
                .select(`#focusLineX-${lineIdentifier}-${this.id}`)
                .attr('x1', x)
                .attr('y1', this.yScale(this.yExtent[0]))
                .attr('x2', x)
                .attr('y2', this.yScale(this.yExtent[1]));
            this.focus[lineIdentifier]
                .select(`#focusLineY-${lineIdentifier}-${this.id}`)
                .attr('x1', this.xScale(this.xExtent[0]))
                .attr('y1', y)
                .attr('x2', this.xScale(this.xExtent[1]))
                .attr('y2', y);
        }

    }

    private toggleLine(d: { color: string; class: string; text: string; }) {
        this.lineSettings[d.class].showLine = !this.lineSettings[d.class].showLine;
        // Update the line visibility.
        d3.select(`.${d.class}-path`).style('display', this.lineSettings[d.class].showLine ? null : 'none' as any);
        d3.select(`.circle-${d.class}`).style('display', this.lineSettings[d.class].showLine ? null : 'none' as any);

        // Update the legend item.
        d3.select(`.legend-item-${d.class}`)
            .select('rect')
            .attr('fill', this.lineSettings[d.class].showLine ? d.color : '#ccc');

        // Update the legend text.
        d3.select(`.legend-item-${d.class}`)
            .select('text')
            .attr('opacity', this.lineSettings[d.class].showLine ? 1 : 0.5);

        // Update the data circles visibility.
        d3.selectAll(`.circle-${d.class}`).style('display', this.lineSettings[d.class].showLine ? null : 'none' as any);

        // Update the focus (dotted lines) visibility.
        d3.select(`.focus-${d.class}`).style('display', this.lineSettings[d.class].showLine ? null : 'none' as any);

        // Emit the selected entires.
        this.emitSelectedEntries();
    }

    private updateGraph(data: GraphDataBase[]) {
        // GraphData | ComplexGraphData
        if (!this.xScale) return;

        if (this.removeWeekends) {

            if (this.graphType == 'monthly') {
                const workingDatesOfMonth = enumerateDaysBetweenDates(this.xExtent[0], this.xExtent[1]).filter(onlyBusinessDays)
                this.xScale
                    .domain(workingDatesOfMonth)
                    .range(d3.range(0, this.graphWidth, this.graphWidth / workingDatesOfMonth.length));

            } else { this.xScale.domain([this.xExtent[0], this.xExtent[1]]).range([0, this.graphWidth]); }

        } else { this.xScale.domain([this.xExtent[0], this.xExtent[1]]).range([0, this.graphWidth]); }

        if (this.isExpanded || this.target === 0) {
            // Scale the yDomain to the data.

            // y-min is the minimum value of the data.valueCompounded property.
            // y-max domain is the max value of the data OR the target + 10%, whichever is greater.
            let yMax = 0, yMin = 0;
            if (this.yExtent && this.yExtent[1] > 0) {
                yMax = this.yExtent[1];
                // yMin = this.setYMinToZeroIfWithin10PercentOfYMax(this.yExtent[0], yMax);
                yMin = this.yExtent[0];
            } else {
                const dExtent = d3.extent(data as any[], (d) => d.valueCompounded);
                const targetUp10 = this.target * 1.1;
                yMax = Math.max(dExtent[1] || 0, targetUp10);
                yMin = this.setYMinToZeroIfWithin10PercentOfYMax(dExtent[0] || 0, yMax);
            }

            this.yScale.domain([yMin, yMax]);
        } else {
            // Fix the graph yDomain to between 0 and the target + 10%.
            // this.yScale.domain([0.0, this.target * 1.1]);
            this.yScale.domain([0.0, this.target]);
        }

        // update path data
        this.primaryPath
            .data([data as GraphData[]])
            .attr('fill', 'none')
            .attr('stroke', this.colourPalette[GraphLine.primary][this.theme].lineStroke)
            .attr('stroke-width', 2 * (this.isExpanded ? this.options.expandMultiplier.lineWidth : this.options.standardMultiplier.lineWidth))
            .attr('d', this.primaryLine);

        // Daily Graph may have additional lines/paths drawn, based on the user's selection.
        if (this.graphType === 'daily') {

            this.salesOrdersPath
                .data([this.secondaryData])
                .attr('fill', 'none')
                .attr('stroke', this.colourPalette[GraphLine.secondary][this.theme].lineStroke)
                .attr('stroke-width', 2 * (this.isExpanded ? this.options.expandMultiplier.lineWidth : this.options.standardMultiplier.lineWidth))
                .attr('d', this.secondaryLine);

            this.invoicesPath
                .data([this.tertiaryData])
                .attr('fill', 'none')
                .attr('stroke', this.colourPalette[GraphLine.tertiary][this.theme].lineStroke)
                .attr('stroke-width', 2 * (this.isExpanded ? this.options.expandMultiplier.lineWidth : this.options.standardMultiplier.lineWidth))
                .attr('d', this.tertiaryLine);
        }

        // create circles for points
        this.updateDataPoints(data);

        // Update the text labels of the axes.
        this.updateAxes();

        // Creates the target line.
        this.updateTarget(data);
    }

    private emitSelectedEntries(): void {
        setTimeout(() => { // Process on 'nextTick'.
            const entiresToEmit = [];
            for (const [key, value] of Object.entries(this.selectedGraphEntries)) {
                if (this.lineSettings[key].showLine) {
                    entiresToEmit.push({ ...value.data, line: key } as any)
                };
            }
            this.clickEntry.emit(entiresToEmit);
        });
    }

    private updateAxes() {
        // Update the x-axis.
        let xAxis: d3.Axis<Date | d3.NumberValue>;

        if (this.removeWeekends) {
            // const workingDatesOfMonth = enumerateDaysBetweenDates(this.xExtent[0], this.xExtent[1]).filter(removeWeekends)
            const workingDatesOfMonth = enumerateDaysBetweenDates(this.xExtent[0], this.xExtent[1]).filter(onlyBusinessDays)
            // Iterate over dates of month.
            // for (const date of workingDatesOfMonth) {
            //     console.log('updateAxes > inspecting date:', moment(date).format('DD/MMM/yyyy'), '...')
            //     if (moment(date).locale('').isHoliday()) {
            //         console.log('updateAxes > date:', moment(date).format('DD/MMM/yyyy'), 'is a holiday.')
            //     }
            //     if (moment(date).locale('').isBusinessDay()) {
            //         console.log('updateAxes > date:', moment(date).format('DD/MMM/yyyy'), 'is a business day.')
            //     }
            // }


            xAxis = d3.axisBottom(this.xScale)
                // .ticks(this.xTicks)
                // .tickValues(this.xScale.domain().filter((d, i) => (d.getDay() !== 0 && d.getDay() !== 6)))
                // .tickValues(this.data.map(d => d.date).filter((d, i) => (d.getDay() !== 0 && d.getDay() !== 6)))
                // .tickValues(datesOfMonth.filter((d, i) => (d.getDay() !== 0 && d.getDay() !== 6)))
                .tickValues(workingDatesOfMonth)
                .tickFormat(d3.timeFormat(this.xFormat) as any)
                ;
        } else {
            xAxis = d3.axisBottom(this.xScale).ticks(this.xTicks).tickFormat(d3.timeFormat(this.xFormat) as any);
        }

        // Update the y-axis.
        const yAxis = d3
            .axisLeft(this.yScale)
            .ticks(this.isExpanded ? 10 : 4)
            .tickFormat((d) => 'R' + this.numberWithCommas(d));

        // Remove the previous x-axis.
        this.xAxisGroup.remove();
        // Create the new x-axis.
        this.xAxisGroup = this.graph.append('g').attr('class', 'x-axis').attr('transform', 'translate(0,' + this.graphHeight + ')')
            .call(xAxis)
            .call(g => g.selectAll(".tick line").clone()
                .attr("y1", 0)
                .attr("y2", -this.graphHeight)
                .attr("stroke-opacity", 0.1));

        // Remove the previous y-axis.
        this.yAxisGroup.remove();
        // Create the new y-axis.
        this.yAxisGroup = this.graph
            .append('g')
            .attr('class', 'y-axis')
            .call(yAxis)
            .call(g => g.selectAll(".tick line").clone()
                .attr("x2", this.graphWidth)
                .attr("stroke-opacity", 0.1));

        // Rotate x-axis text.
        this.xAxisGroup.selectAll('text').attr('transform', 'rotate(-40)').attr('text-anchor', 'end');
    }

    private updateDataPoints(data: GraphDataBase[]) {
        const circles = this.dataGroup.selectAll('circle').data(data);

        // remove unwanted points
        circles.exit().remove();

        // update current points
        this.drawNewCircles(circles, 'valueCompounded', GraphLine.primary);

        // Add points to the sales-order and invoices line if the graph is daily.
        if (this.graphType === 'daily') {
            const sOCircles = this.secondaryDataGroup.selectAll('circle').data(this.secondaryData);
            const invoicesCircles = this.tertiaryDataGroup.selectAll('circle').data(this.tertiaryData);

            // remove unwanted points
            sOCircles.exit().remove();
            invoicesCircles.exit().remove();

            this.drawNewCircles(sOCircles, 'valueCompounded', GraphLine.secondary);
            this.drawNewCircles(invoicesCircles, 'valueCompounded', GraphLine.tertiary);
        }
    }

    private drawNewCircles(circles: d3.Selection<d3.BaseType, any, SVGGElement, unknown>, compoundFieldName: string, lineIdentifier: GraphLine) {
        circles
            .attr('r', 3 * (this.isExpanded ? this.options.expandMultiplier.circleRadius : this.options.standardMultiplier.circleRadius))
            .attr('cx', (d) => this.xScale(new Date(d.date)))
            .attr('cy', (d) => this.yScale(d[compoundFieldName]));

        // add new points
        circles
            .enter()
            .append('circle')
            .attr('class', `circle-${lineIdentifier}`)
            .attr('r', 3 * (this.isExpanded ? this.options.expandMultiplier.circleRadius : this.options.standardMultiplier.circleRadius))
            .attr('cx', (d) => this.xScale(new Date(d.date)))
            .attr('cy', (d) => this.yScale(d[compoundFieldName]))
            .attr('fill', this.colourPalette[lineIdentifier][this.theme].circleFill)
            .attr('opacity', 0.5);
    }

    private recolorGraph() {
        this.primaryPath.attr('stroke', this.colourPalette[GraphLine.primary][this.theme].lineStroke);
        const circles = this.dataGroup.selectAll('circle').data(this.data);

        // Update current points.
        circles.attr('fill', this.colourPalette[GraphLine.primary][this.theme].circleFill);

        // Update points to the sales-order, invoices line if the graph is daily.
        if (this.graphType === 'daily') {
            // Recolour the sales-order line.
            const sOCircles = this.secondaryDataGroup.selectAll('circle').data(this.secondaryData);
            sOCircles.attr('fill', this.colourPalette[GraphLine.secondary][this.theme].circleFill);
            this.salesOrdersPath.attr('stroke', this.colourPalette[GraphLine.secondary][this.theme].lineStroke);

            // Recolour the invoice line.
            const invoicesCircles = this.tertiaryDataGroup.selectAll('circle').data(this.tertiaryData);
            invoicesCircles.attr('fill', this.colourPalette[GraphLine.tertiary][this.theme].circleFill);
            this.invoicesPath.attr('stroke', this.colourPalette[GraphLine.tertiary][this.theme].lineStroke);

            // Update the colour of the legend data items.
            for (const legendData of this.legendData) {
                const palette = this.colourPalette[legendData.class][this.theme];
                legendData.color = palette.lineStroke;
            }
            // this.legendData.find((d) => d.class === GraphLine.primary).color = this.colourPalette[GraphLine.primary][this.userTheme].lineStroke;
            // this.legendData.find((d) => d.class === GraphLine.secondary).color = this.colourPalette[GraphLine.secondary][this.userTheme].lineStroke;
            // this.legendData.find((d) => d.class === GraphLine.tertiary).color = this.colourPalette[GraphLine.tertiary][this.userTheme].lineStroke;

            // Update the colour of the legend items.
            this.legendItems
                .select("rect")
                .attr("fill", (d) => d.color);
        }
    }

    private buildTooltipHtml(d: GraphData, lineIdentifier?: GraphLine): string | d3.ValueFn<HTMLDivElement, unknown, string> {

        switch (this.graphType) {
            case 'client-abc':
                return this.buildClientAbcToolTip(d);
            case 'daily':
                return this.buildDailyToolTip(d as any, lineIdentifier);
            case 'monthly':
            default:
                return this.buildMonthlyToolTip(d);
        }

    }

    private buildClientAbcToolTip(d: GraphData) {
        let base = `<div class="graph-tooltip-container"><div class="tooltip-date">${moment(d.date)
            .local()
            .format('MMMM, YYYY')}</div>
                <div class="tooltip-value">Month Total: R${this.numberWithCommas((+d.value).toFixed(2))}</div>`;

        // if (d.repKey && d.repKey > 0) {
        //     base += `<div class="tooltip-rep">Rep Nameo: ${this.toRepName(d.repKey)}</div>
        //             <div class="tooltip-rep">Rep No: ${d.repKey}</div>`;
        // }

        return (base += '</div>');
    }

    private buildMonthlyToolTip(d: GraphData) {
        let base = `<div class="graph-tooltip-container"><div class="tooltip-date">${moment(d.date)
            .local()
            .format('MMMM Do[,] dddd')}</div>
                <div class="tooltip-value">Transaction: R${this.numberWithCommas((+d.value).toFixed(2))}</div>
                <div class="tooltip-value">Accumulated: R${this.numberWithCommas((+d.valueCompounded).toFixed(2)
            )}</div>`;

        if (d.repKey && d.repKey > 0) {
            base += `<div class="tooltip-rep">Rep Nameo: ${this.toRepName(d.repKey)}</div>
                    <div class="tooltip-rep">Rep No: ${d.repKey}</div>`;
        } else if (d.repKeys && d.repKeys.length > 0) {
            const isMoreThan18 = d.repKeys && d.repKeys.length > 18;

            base += `<div class="tooltip-rep">Reps (<u>${d.repKeys.length}</u>): ${isMoreThan18 ? d.repKeys.slice(0, 18).join(', ') + '...' : d.repKeys.join(', ')}</div>`;
        }

        return (base += '</div>');
    }

    private buildDailyToolTip(d: DaySummaryDatum, lineIdentifier?: GraphLine) {
        const txType = this.toTransactionType(d.transactionType);
        const isCarriedOverEntry = d?.key && (d.key + '').includes('carried') && (d.key + '').includes('over');
        let base = '';

        // console.log(`buildDailyToolTip(isCarriedOverEntry: ${isCarriedOverEntry}) > d`, d);

        // Add carried over indicator.
        if (isCarriedOverEntry) {
            const value = lineIdentifier === GraphLine.primary ? d.valueCompounded : (lineIdentifier === GraphLine.secondary ? d.salesOrderCompounded : d.invoicedCompounded);
            const newTxType = lineIdentifier === GraphLine.primary ? 'Total' : (lineIdentifier === GraphLine.secondary ? 'Sales Order' : 'Invoiced');
            let reps: undefined | Array<any>;
            if (lineIdentifier === GraphLine.primary && d.repKeys) reps = d.repKeys;
            else if (lineIdentifier === GraphLine.secondary) reps = ((d as any).salesOrderDetails as DaySummaryDatum[]).map(x => x.userKey);
            else reps = ((d as any).invoiceDetails as DaySummaryDatum[]).map(x => x.userKey);

            const isMoreThan10 = reps && reps.length > 10;

            base = `<div class="graph-tooltip-container" style="background-color:${this.colourPalette[lineIdentifier as GraphLine][this.theme].lineStroke + '35'}"><div class="tooltip-date">${moment(d.date)
                .local()
                .format('dddd HH:mma')}</div>
                    <div class="tooltip-tx-type">${newTxType} (<i>Carried Over</i>)</div>
                    <div class="tooltip-value">Transaction: R${this.numberWithCommas((+value).toFixed(2))}</div>
                    <div class="tooltip-value">Accumulated: R${this.numberWithCommas((+d.valueCompounded).toFixed(2)
                )}</div>`
                +
                `<div class="tooltip-rep">Reps No: ${reps ? (isMoreThan10 ? reps.slice(0, 10).join(', ') + '...' : reps.join(', ')) : '-'}</div>`;
        }

        // Standard tooltip.
        else base = `<div class="graph-tooltip-container" style="background-color:${this.colourPalette[lineIdentifier as GraphLine][this.theme].lineStroke + '35'}"><div class="tooltip-date">${moment(d.date)
            .local()
            .format('dddd HH:mma')}</div>
                    ${txType ? `<div class="tooltip-tx-type">${txType}</div>` : ''}
                    <div class="tooltip-value">Transaction: R${this.numberWithCommas((+d.value).toFixed(2))}</div>
                    <div class="tooltip-value">Accumulated: R${this.numberWithCommas((+d.valueCompounded).toFixed(2)
            )}</div>`;

        if (d.repKey && (+d.repKey) > 0) {
            base += `<div class="tooltip-rep">Rep Name: ${this.toRepName(d.repKey)}</div>
                    <div class="tooltip-rep">Rep No: ${d.repKey}</div>`;
        }

        return (base += '</div>');
    }

    private toTransactionType(transactionType: string): string | null {
        // [["PM", "Payment"], ["IN", 'Invoice'], ["IT", 'Interest Charge'], ["CN", "Credit Note"], ["RE", "Rebates"], ["DS", 'Discount'], ["JC", 'Journal Credit'], ["JD", 'Journal Debit'], ["RF", 'Refund'], ["BC", 'Bank Charges']]
        switch (transactionType) {
            case 'BC':
                return 'Bank Charges';
            case 'CN':
                return 'Credit Note';
            case 'DS':
                return 'Discount';
            case 'IT':
                return 'Interest Charge';
            case 'IN':
                return 'Invoice';
            case 'JC':
                return 'Journal Credit';
            case 'JD':
                return 'Journal Debit';
            case 'PM':
                return 'Payment';
            case 'RE':
                return 'Rebates';
            case 'RF':
                return 'Refund';
            case 'SO':
                return 'Sales Order';
            default:
                console.error('toExplicitCode > Invalid code from IQ: ' + transactionType);
                return null;
        }
    }

    private toRepName(repKey: number | string): string {
        return this.repNames[+repKey] ? this.repNames[+repKey] : '<i>Unavailable</i>';
    }

    private updateTarget(graphData: GraphDataBase[]): void {
        let firstDate = graphData[0] ? graphData[0].date : new Date();
        let lastDate = graphData[1] ? graphData[1].date : new Date();

        if (this.consolidateData) {
            // Plot the target line according to the company hours.
            firstDate = this.xExtent[0];
            lastDate = this.xExtent[1];
        } else {
            // Plot the target line to the company hours by default, expanding to sales made earlier or later.
            firstDate = this.xExtent[0];
            lastDate = this.xExtent[1];

            if (this.fromDate) this.xExtent[0] = this.fromDate;
            if (this.toDate) this.xExtent[1] = this.toDate;
        }

        // console.log('updateTarget > firstDate:', moment(firstDate).format('DD/MMM/yyyy'), 'lastDate:', moment(lastDate).format('DD/MMM/yyyy'));
        // console.log('updateTarget > fromDate:', moment(this.fromDate).format('DD/MMM/yyyy'), 'toDate:', moment(this.toDate).format('DD/MMM/yyyy'));

        this.targetData = [];
        this.targetData.push(
            {
                date: firstDate,
                value: 0,
            },
            {
                date: lastDate,
                value: this.target,
            }
        );

        // If an invalid target is set, don't plot the line.
        if (this.target <= 0) {
            this.targetData = [];
        }

        // update target path data
        this.targetPath
            .data([this.targetData])
            .attr('fill', 'none')
            .attr('stroke', '#6b959c')
            .attr('stroke-width', '2')
            .attr('stroke-dasharray', 3)
            .attr('d', this.targetLine);
    }

    private updateGraphSizeAndRange(): void {
        // Calculate the graph size.
        this.graphWidth = (this.width * (this.isExpanded ? this.options.expandMultiplier.width : this.options.standardMultiplier.width)) - this.margin.right - this.margin.left;
        this.graphHeight = (this.height * (this.isExpanded ? this.options.expandMultiplier.height : this.options.standardMultiplier.height)) - this.margin.top - this.margin.bottom;

        // Update graph size.
        this.svg.attr('viewBox', `0 0 ${this.graphWidth + this.margin.right + this.margin.left} ${this.graphHeight + this.margin.top + this.margin.bottom + 10}`);

        // Update the graph text to match the graph size.
        this.xAxisGroup.attr('transform', `translate(0, ${this.graphHeight})`);

        // Update the X and Y scale to match the graph size.
        if (this.removeWeekends && this.graphType == 'monthly') {
            const workingDatesOfMonth = enumerateDaysBetweenDates(this.xExtent[0], this.xExtent[1]).filter(onlyBusinessDays)
            this.xScale
                .domain(workingDatesOfMonth)
                .range(d3.range(0, this.graphWidth, this.graphWidth / workingDatesOfMonth.length));
        } else this.xScale.range([0, this.graphWidth]);
        this.yScale.range([this.graphHeight, 0]);

        // Update overlay to match graph size.
        this.graphOverlay.attr('width', this.graphWidth).attr('height', this.graphHeight);

        // Update the clipping area to match graph size.
        this.clip
            .attr("x", 0)
            .attr("y", 0)
            .attr('width', this.graphWidth + this.margin.left + this.margin.right)
            .attr('height', this.graphHeight + 1)
            .attr('transform', `translate(-10, 0)`);


        // Update the legend to match graph size.
        if (this.graphType == 'daily') {

            // Reduce text size and legend size for XS display.
            if (this.graphWidth < 200) {
                // Update the left margin of each legend item.
                // primary: -25, secondary: 20, tertiary: 110
                this.legendData = this.legendData.map((d, i) => {
                    d.marginLeft = d.class === GraphLine.primary ? -25 : (d.class === GraphLine.secondary ? 20 : 110);
                    return d;
                });


                // Update the legend text size.
                this.legendItems
                    .select('text')
                    .attr('font-size', '0.6rem');

            }
            // Reduce text size and legend size for S displays.
            else if (this.graphWidth < 275) {
                // Update the left margin of each legend item.
                // primary: -20, secondary: 35, tertiary: 130
                this.legendData = this.legendData.map((d, i) => {
                    d.marginLeft = d.class === GraphLine.primary ? -20 : (d.class === GraphLine.secondary ? 35 : 130);
                    return d;
                });


                // Update the legend text size.
                this.legendItems
                    .select('text')
                    .attr('font-size', '0.7rem');

            }
            // Increase text size and legend size for M+ displays.
            else {
                // Update the left margin of each legend item.
                // primary: 0, secondary: 65, tertiary: 190
                this.legendData = this.legendData.map((d, i) => {
                    d.marginLeft = d.class === GraphLine.primary ? 0 : (d.class === GraphLine.secondary ? 65 : 190);
                    return d;
                });

                // Align legend items to the top left of the graph, vertical to each other.
                this.legendItems.attr('transform', (d, i) => {
                    return `translate(${d.marginLeft}, -20)`;
                });
                this.legendItems
                    .select('text')
                    .attr('font-size', '1rem')
            }

            // Update the legend item alignment.
            this.legendItems.attr('transform', (d, i) => {
                return `translate(${d.marginLeft}, -20)`;
            });
        }

        // this.legend.attr('transform', `translate(${this.graphWidth - 100}, 0)`);

        // Reset the cross hair focus lines.
        this.focus[GraphLine.primary].style('opacity', 0);
        this.dottedLines[GraphLine.primary].style('opacity', 0);

        if (this.graphType == 'daily') {
            this.focus[GraphLine.secondary].style('opacity', 0);
            this.focus[GraphLine.tertiary].style('opacity', 0);

            // this.tooltip[GraphLine.secondary].transition().duration(200).style('opacity', 0);
            // this.tooltip[GraphLine.tertiary].transition().duration(200).style('opacity', 0);

            this.dottedLines[GraphLine.secondary].style('opacity', 0);
            this.dottedLines[GraphLine.tertiary].style('opacity', 0);
        }

        // Reset the selected data.
        this.clickEntry.emit([]);
        this.selectedGraphEntries[GraphLine.primary] = { index: undefined, data: undefined, reference: undefined };
        if (this.graphType == 'daily') {
            this.selectedGraphEntries[GraphLine.secondary] = { index: undefined, data: undefined, reference: undefined };
            this.selectedGraphEntries[GraphLine.tertiary] = { index: undefined, data: undefined, reference: undefined };
        }


        this.updateGraph(this.data);
    }

    private numberWithCommas(no: string | d3.NumberValue): string {
        const parts = (no + '').split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
    }

    private setYMinToZeroIfWithin10PercentOfYMax(yMin: number, yMax: number) {
        return yMin > 0 && yMin < yMax * 0.1 ? 0 : yMin;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        // console.log('ngOnChanges > graph base:', changes);

        if ((changes['data'] && !changes['data'].isFirstChange()) || (changes['invoiceData'] && !changes['invoiceData'].isFirstChange()) || (changes['salesOrderData'] && !changes['salesOrderData'].isFirstChange())) {
            // Wrapped in settimeout to allow other variables to be instantiated before calling the function.
            setTimeout(() => { this.updateGraph(this.data); });
        }

        if (changes['isExpanded'] && !changes['isExpanded'].isFirstChange()) {
            // console.log('ngOnChanges > isExpanded:', changes['isExpanded'])
            this.updateGraphSizeAndRange();
        }

        if (changes['width'] && !changes['width'].isFirstChange() || changes['height'] && !changes['height'].isFirstChange()) {
            // console.log('ngOnChanges > width:', changes['width'], 'height:', changes['height'])
            this.updateGraphSizeAndRange();
        }

        if (changes['theme'] && !changes['theme'].isFirstChange()) {
            // Wrapped in settimeout to allow the variables to be instantiated before calling the function.
            setTimeout(() => { this.recolorGraph(); });
        }

        // if (changes['parentProperties']) {
        //     console.log('ngOnChanges > parentProperties:', changes['parentProperties'])
        // }

        if (changes['parentProperties'] && !changes['parentProperties'].isFirstChange() && this.xExtent) {
            // console.log('ngOnChanges > parentProperties:', changes['parentProperties'])
            setTimeout(() => {

                const canvas = window.document.getElementById(this.id);

                // if (canvas && (this.previousParentProperties.width != canvas.clientWidth || this.previousParentProperties.height != canvas.clientHeight)) {
                if (canvas) {
                    this.width = canvas.clientWidth;
                    this.height = canvas.clientHeight - 10; // - 10 is because the graph's svg has +10 on the height, which affects this in a compounding manner since this is calculated from the canvas itself.
                    this.previousParentProperties.width = canvas.clientWidth;
                    this.previousParentProperties.height = canvas.clientHeight;
                    this.updateGraphSizeAndRange();
                }
            });

        } else if (changes['parentProperties'] && changes['parentProperties'].isFirstChange()) {
            // console.log('ngOnChanges > parentProperties:', changes['parentProperties'])
            // setTimeout(() => {
            //     setTimeout(() => {
            //         this.setDefaultForPreviousParentProperties();
            //     });
            // });

            setTimeout(() => {
                setTimeout(() => {
                    if (this.svg)
                        this.updateGraphSizeAndRange();
                });
            });
        }
    }

    public ngOnDestroy(): void {
        this.tooltip[GraphLine.primary].remove(); // Remove tooltip from DOM.
        this.focus[GraphLine.primary].remove();

        if (this.graphType == 'daily') {
            this.tooltip[GraphLine.secondary].remove(); // Remove tooltip from DOM.
            this.focus[GraphLine.secondary].remove();

            this.tooltip[GraphLine.tertiary].remove(); // Remove tooltip from DOM.
            this.focus[GraphLine.tertiary].remove();
        }

        this.svg.remove();
    }
}
interface GraphTargetData {
    value: number;
    date: Date;
}
