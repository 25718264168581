import { Particle, BasketSortingPreference } from '@newgenus/common';
import { Injectable } from '@angular/core';
import firebase from 'firebase/compat';

@Injectable({
  providedIn: 'root'
})
export class WorkersService {

  processDates(dataSent: any) {
    return new Promise<any>((resolve, reject) => {

      if (typeof Worker !== 'undefined') {
        const worker = new Worker(new URL('../../workers/processDates.worker', import.meta.url),
          { type: 'module' });

        worker.onmessage = ({ data }) => {
          resolve(data);
          worker.terminate();
        };

        worker.postMessage(dataSent);
      }
    });
  }

  processSort(particles: Particle[], userPreference: BasketSortingPreference, typeOfSort: string | undefined, basket: string, folder?: string | undefined) {
    const data = folder ? { particles, userPreference, typeOfSort, basket, folder } : { particles, userPreference, typeOfSort, basket };
    return new Promise<any>((resolve, reject) => {

      if (typeof Worker !== 'undefined') {

        const worker = new Worker(new URL('../../workers/processSort.worker', import.meta.url),
          { type: 'module' });

        worker.onmessage = ({ data }) => {
          resolve(data);
          worker.terminate();
        };

        worker.postMessage(data);
      }
    });
  }

  processCompare(actions: { type: firebase.firestore.DocumentChangeType; payload: Particle; }[], storeParticles: Particle[]) {
    const data = { actions, storeParticles };
    return new Promise<any>((resolve, reject) => {

      if (typeof Worker !== 'undefined') {
        const worker = new Worker(new URL('../../workers/processCompare.worker', import.meta.url),
          // { type: 'module' });
          { type: 'module' });

        worker.onmessage = ({ data }) => {
          resolve(data);
          worker.terminate();
        };

        worker.postMessage(data);
      }
    });
  }
}