import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { MaterialModule } from '../material.module';
import { DisplayValue } from '@newgenus/common';
import fuzzysort from 'fuzzysort';

@Component({
  selector: 'shared-searchable-select',
  templateUrl: './searchable-select.component.html',
  standalone: true,
  imports: [MaterialModule, FormsModule, ReactiveFormsModule, NgClass, NgFor, NgIf],
  styles: [
    '.additional-selection { opacity: 0.75; font-size: 0.75em; }',
    '.hide-scrollbar { -ms-overflow-style: none; scrollbar-width: none; }',
    '.hide-scrollbar::-webkit-scrollbar { display: none; }'
  ]
})
export class SearchableSelectComponent implements OnInit, OnChanges {

  /**
   * The appearance of the component. This can be either 'fill' or 'outline', according to the Material Design spec.
   *
   * @type {('fill' | 'outline')}
   */
  @Input() public appearance: 'fill' | 'outline' = 'fill';

  /**
   * The data being injected into this component. A copy of this data is used for the filtered data, and that is what is displayed.
   */
  @Input() public data: DisplayValue[] = [];

  /**
   * Whether or not the component is disabled - if you are using a form control, you should use the formControl.disable() instead.
   */
  @Input() public disabled = false;

  /**
   * The form control that is used to control the value of the searchable select component.
   * 
   * @optional If not provided, a new form control will be created.
   */
  @Input() public control = new FormControl();

  /**
   * The label of the searchable select component.
   *
   * @optional If not provided, the label will be 'Select an option'.
   */
  @Input() public label = 'Select an option';

  /**
   * Whether or not the searchable select component is multiple.
   *
   * @optional If not provided, the searchable select component will not be multiple.
   */
  @Input() public multiple = false;

  /**
   * The placeholder of the searchable select component.
   *
   * @optional If not provided, the placeholder will be 'Enter a search term'.
   */
  @Input() public placeholder = 'Enter a search term';

  /**
   * Whether or not the searchable select component is required - if you are using a form control, you should declare that formControl as required instead.
   *
   * @optional If not provided, the searchable select component will not be required.
   * @example ```typescript 
   * // In your component:
   * public form = new FormGroup({
   *  searchableSelect: new FormControl('', [Validators.required]),
   * });
   * ```
   */
  @Input() public required = false;

  /**
   * Event emitter that emits the selected value(s) when the value(s) change.
   *
   * @type {(EventEmitter<DisplayValue | DisplayValue[]>)}
   */
  @Output() public selected: EventEmitter<any> = new EventEmitter();

  @Input() public value: any = [];
  @Output() public valueChange: EventEmitter<any> = new EventEmitter();

  /**
   * The classes that are applied to the searchable select component.
   *
   * @type {string[]}
   * @memberof SearchableSelectComponent
   */
  @Input()
  public classes: string[] = [];

  /**
   * The search term that is used to filter the data - this variable is only used if the data changes while the component is active with a search.
   */
  public searchTerm = '';

  /**
   * The filtered data that is displayed in the searchable select component.
   */
  public filteredData: DisplayValue[] = [];


  public ngOnInit(): void {
    // Set the filtered data to the data - must be done in ngOnInit() for the @inputs to have been set.
    this.filteredData = this.data.slice();

    if (this.value && this.value.length > 0) {
      setTimeout(() => {
        this.control.setValue(this.value);
      });
    }
  }

  public onSelectChange(event: MatSelectChange) {
    // Emit the selected value(s).
    this.selected.emit(event.value);
    this.value = event.value;
    this.valueChange.emit(event.value);
  }

  public onSearchChange(term: string): void {

    // If there is no term, render all options.
    if (!term) this.filteredData = this.data?.slice() || [];

    // If there is a term, filter the data using fuzzysort.
    else this.filteredData = fuzzysort.go(term, (this.data || []), { key: 'display' }).map(c => c.obj);

  }

  public ngOnChanges(changes: SimpleChanges): void {

    // If the data changes, update the filtered data.
    if (changes['data'] && changes['data'].isFirstChange() === false) {

      // Update the filtered data.
      this.filteredData = changes['data'].currentValue?.slice() || [];

      // Apply search filter if there is one.
      if (this.searchTerm && this.searchTerm.length > 0)
        setTimeout(() => { this.onSearchChange(this.searchTerm); });

    }

    if (changes['disabled']) {
      changes['disabled'].currentValue === true ? this.control.disable() : this.control.enable();
    }
  }

}
