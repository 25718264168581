import { ClientAbcDynamicGroups, SettingsAppearance } from '../statistics-models';
import { OrgDashboard } from './organization-models';
import { Permission } from '../security-models';
import Timestamp = firebase.firestore.Timestamp;
import firebase from 'firebase/compat/app';
import { Themes } from './enums';

export interface LinkedEmail {
    [email: string]: LinkedEmailDetail
}

export interface LinkedEmailDetail {
    [client: string]: LinkedClientDetails
}

export interface LinkedClientDetails {
    linked?: boolean;
    storageFileIds?: Array<string>;
    signature?: string;
    GUID?: string;
    dateDisconnected?: Timestamp;
    dateConnected?: Timestamp;
    expirationDateTime?: Timestamp;
}

export interface SendFrom {
    client: string;
    emailKey: string;
}

export interface UserOrgDetails {
    hats: string[];
    featureDetails: {
        [featureKey: string]: {
            permissions: Permission[],
        }
    };
    features: string[];
    permissions: string[];
    // roles?: string[],
    name: string;
    key?: string;
    selectedGatewayKey?: string;
    dashboards: OrgDashboard;
}

export interface UserOrganizationDetail {
    [organizationId: string]: UserOrgDetails
}

export interface UserOrganization {
    selected: string,
    permission: string;
    orgIds: string[];
}

export interface UserOrgSettings extends DefaultUserOrgSettings {
    clientAbc?: ClientAbcSettings;
}

/**
 * This is the extended version of the DefaultUserOrgSettings interface, it's the one that's used in 
 */
export interface UserOrgSettingsExtended extends DefaultUserOrgSettings {
    tooltip: string;
    field: string;
    showTax: boolean;
}

export interface ClientAbcUserSettings extends UserOrgAppearanceSettingsExtended {
    dynamicGrouping: ClientAbcDynamicGroups,
    transactionTypes: ClientAbcTransactionTypes[];
}
// "IN - D", "CN - C", "JC - C", "RF - D", "BC - C", "RE - C", "DS - C", "JD - D", "PM - C"
export type ClientAbcTransactionTypes = 'IN' | 'CN' | 'JC' | 'RF' | 'BC' | 'RE' | 'DS' | 'JD' | 'PM';
export type DebtTransactionCodes = ClientAbcTransactionTypes | 'SO' | 'IT';
export type StatisticsFilterCodes = 'debtTran-IN' | 'debtTran-CN' | 'debtTran-JC' | 'debtTran-RF' | 'debtTran-BC' | 'debtTran-RE' | 'debtTran-DS' | 'debtTran-JD' | 'debtTran-PM' | 'debtTran-IT' | 'salesOrder-closed' | 'salesOrder-open';

export interface UserOrgAppearanceSettingsExtended extends UserOrgAppearanceSettings {
    tooltip: string;
    field: string;
}

interface ClientAbcSettings extends UserOrgAppearanceSettings {
    dynamicGrouping: ClientAbcDynamicGroups;
    transactionTypes: ClientAbcTransactionTypes[];
}

export interface DefaultUserOrgSettings extends UserOrgAppearanceSettings {
    showEmptyCodes: boolean;
    showSalesOrders: boolean;
    clientAbc?: ClientAbcSettings | undefined;
}


export interface UserOrgAppearanceSettings {
    appearance: SettingsAppearance;
    dateProfileKey: string;
    transactionTypes?: string[];
    view?: 'day' | 'week' | 'month';
}

export interface SortingPreferences {
    particleLabelsOrder: string[];
    typeOfSort: string;
    dateSorting: string;
}

export interface BasketSortingPreference {
    inbasket?: SortingPreferences;
    pending?: SortingPreferences;
    outbasket?: SortingPreferences;
    filedbasket?: SortingPreferences;
    folders: {
        [foldersbasket: string]: SortingPreferences;
    };
    typeOfSort?: string;
}

export interface UserBasicPreference extends SortingPreferences {
    sidebarStatus: boolean;
    photoURL: string;
    signature?: {
        type?: 'OneForAll' | 'EachToTheirOwn' | 'LiquoriceAllSorts';
        defaultContent?: string;
        storageFileIds?: Array<string>;
    };
}

export interface User {
    alphaTester?: {
        active: boolean;
        group: number;
    }
    organizationSettings?: {
        [organizationId: string]: {
            [configKey: string]: UserOrgSettings
        }
    };
    displayName?: string;
    email: string;
    emailAlias?: string;
    excludedLabels?: string[];
    // features?: Features;
    firstName?: string;
    guids?: Array<string>;
    hasAmbiguousLinkedEmails?: boolean;
    includedLabels?: string[];
    isDeveloper?: boolean;
    lastName?: string;
    linkedEmails?: LinkedEmail;
    organizations?: UserOrganization & UserOrganizationDetail;
    originalSignInProvider?: string;
    plan?: string;
    preferences: UserBasicPreference & BasketSortingPreference;
    profileImage?: ProfileImage;
    // roles?: Roles;
    // routes?: Routes;
    sendFrom?: SendFrom;
    serverExcludedLabels?: string[];
    setupDone?: boolean;
    theme?: Themes;
    uid: string;

}

export interface ProfileImage {
    fileUrl: string;
    fileName: string;
    creationDate: Date;
}

/**
 * Language ID
 */
export enum Language {
    en = 1,
    de = 2,
}

export interface VerifyEmail {
    uid: string;
    email: string;
    emailSentSuccess?: boolean;
    locale_id?: number;
    verificationCode?: number;
    environment?: string;
    verified?: boolean;
    registered?: boolean;
    verifiedTimestamp?: Date;
    registeredTimestamp?: Date;
    loggedIn?: boolean;
    loggedInTimestamp?: Date;
    source?: string;           // where the newgenus widget was installed
    sourceUrl?: string;
    provider?: string;         // firebase, facebook.com or google.com
}
