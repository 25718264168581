import { AuthService } from '../../shared/services/auth.service';
import { StoreUser } from '../../workspace/user.abstract';
import { Component, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';

@Component({
  selector: 'logging-in',
  template: `
  <div class="logout-wrapper">
    <div style="color: black;">
      Signing in...
    </div>
  </div>`,
  styleUrls: ['./logging-in.component.scss']
})
export class LoggingInComponent extends StoreUser implements OnDestroy {

  private destroy$ = new Subject<void>();

  constructor(private auth: AuthService, private router: Router, store: Store) {
    super(store);
    this.initStoreUser(this.destroy$);

    auth.user$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        setTimeout(() => { // Just to display to the user...
          if (user && user.preferences !== undefined) {
            this.router.navigate(['/app/home']);
          }
        }, 2500);
      })
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }

}
