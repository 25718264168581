
import { deepCopy, toFirebaseTimestamp } from './static.functions';
import { Timestamp } from 'firebase/firestore';
import { Particle } from '../classes';
import moment from 'moment';


const groupParticlesOnTypeLabels = (particles: any[]): any => {
    const groupedHeader: any = {};

    for (let i = 0; i < particles.length; i += 1) {

        if (!groupedHeader[particles[i].particleTypeSort]) {
            groupedHeader[particles[i].particleTypeSort] = [];
        }

        groupedHeader[particles[i].particleTypeSort].push(particles[i]);
    }
    return groupedHeader;
}

const groupParticlesOnDateLabels = (particles: any[]): any => {
    const groupedHeader: any = {};

    for (let i = 0; i < particles.length; i += 1) {

        if (!groupedHeader[particles[i].particleDateLabel]) {
            groupedHeader[particles[i].particleDateLabel] = [];
        }

        groupedHeader[particles[i].particleDateLabel].push(particles[i]);
    }
    return groupedHeader;
}

const orderParticleTypeGroups = (particleGroups: any, requiredOrder: any, directionOfSort: string): any[] => {

    const sortedParticles = [];
    for (const group of requiredOrder) {

        if (particleGroups[group]) {

            particleGroups[group] = sortByDate(particleGroups[group], directionOfSort);
            // particleGroups[group].reverse(); // so that particles of higher order remain on top after the final reverse.

            for (let i = 0; i < particleGroups[group].length; i++) {
                const sortedParticle = particleGroups[group][i];
                sortedParticles.push(sortedParticle);
            }
        }
    }

    return sortedParticles;

}

const orderTheGroupsTwoLayersDeep = (particleGroups: any): any[] => {
    const sortedParticles: any[] = [];
    for (const [group] of Object.entries(particleGroups)) {
        for (const [group2] of Object.entries(particleGroups[group])) {
            for (let i = 0; i < particleGroups[group][group2].length; i++) {

                const sortedParticle = particleGroups[group][group2][i];
                sortedParticles.push(sortedParticle);

            }
        }
    }
    return sortedParticles;
}

const orderTheGroupsByType = (particleGroups: any = {}, requiredOrder: any, directionOfSort: string): any => {
    const newParticleGroups: any = {};
    for (const [group] of Object.entries(particleGroups)) {
        newParticleGroups[group] = []
        for (const tGroup of requiredOrder) {
            const typeGroup = [];
            for (const particle of particleGroups[group]) {
                if (particle.particleTypeSort === tGroup) {
                    typeGroup.push(particle);
                    newParticleGroups[group][tGroup] = typeGroup;
                }
            }
        }
    }
    return newParticleGroups;
}

const sortByDate = (particles: Particle[], directionOfSort: string) => {
    if (directionOfSort === 'asc') {
        particles.sort((a, b) => {
            return dateSortAsc(a, b) ? 1 : -1;
        });
    }
    else if (directionOfSort === 'desc') {
        particles.sort((a, b) => {
            return dateSortDesc(a, b) ? 1 : -1;
        });
    }
    return particles;
}

const dateSortAsc = (a: Particle, b: Particle) => {
    const time1 = a.creationDate.toDate().getTime();
    const time2 = b.creationDate.toDate().getTime();
    return time1 > time2;
}

const dateSortDesc = (a: Particle, b: Particle) => {
    const time1 = a.creationDate.toDate().getTime();
    const time2 = b.creationDate.toDate().getTime();
    return time1 < time2;
}

const setParticleType = (labels: any, particles: any) => {

    // use this technique in order to have O(a + b) / O(n) time complexity instead of O(a*b) / O(nxn - Squared)
    const newParticles = deepCopy(particles)

    // loop through first array and create object where properties === items in the array
    const map: any = {};
    for (let i = 0; i < labels.length; i++) {
        if (!map[labels[i]]) {
            const item = labels[i];
            map[item] = true;
        }
    }

    // loop through second array and check if item in second array exist on created object
    for (const particle of newParticles) {
        const particleLabels: string[] = particle.labelIds;
        if (particle.particleType === 'gmail' ||
            particle.particleType === 'outlook' ||
            particle.particleType === 'draft' && !particleLabels.includes("EMAIL")) {
            particleLabels.push("EMAIL");
        }
        if (particle.particleType === 'checklist' && !particleLabels.includes("CHECKLIST")) {
            particleLabels.push("CHECKLIST");
        }
        if (particle.particleType === 'system') {
            particleLabels.push("SYSTEM");
        }
        for (let j = 0; j < particleLabels.length; j++) {
            if (map[particleLabels[j]]) {
                particle.particleTypeSort = particleLabels[j];
            }
        }
    }
    return newParticles;
};

export const setParticleDateType = (particles: any) => {

    const newParticles = deepCopy(particles)
    const dateLabels = createDateLabel(new Date());

    for (const particle of newParticles) {
        const firestoreTimpeStamp = toFirebaseTimestamp(particle.creationDate);
        const label = getDateLabel(moment(firestoreTimpeStamp.toDate()), dateLabels);
        particle.particleDateLabel = label;
    }
    return newParticles;
};

const getDateLabel = (providedDate: any, dateLabels: any) => {

    let label: string;
    const checkdate = providedDate;
    const { today, yesterday, weekStart, lastWeek, olderDay } = dateLabels;

    if (checkdate >= today.getTime()) {
        label = 'Today'
    }
    else if (checkdate >= yesterday.getTime() && checkdate < today.getTime()) {
        label = 'Yesterday'
    }
    else if (checkdate < yesterday.getTime() && checkdate >= weekStart.getTime()) {
        label = 'Earlier this Week'
    }
    else if (checkdate < weekStart.getTime() && checkdate >= lastWeek.getTime()) {
        label = 'Last Week'
    }
    else if (checkdate < lastWeek.getTime()) {
        label = 'Older Dates'
    }
    else {
        label = 'Other Dates'
    }

    return label;
}

// -- date labels -- //
const createDateLabel = (now: Date): any => {

    now.setHours(0, 0, 0, 0);

    const today = now;
    const yesterday = moment(now).subtract(1, 'day').toDate();
    const weekStart = moment(now).startOf('week').toDate();
    const lastWeek = moment(now).startOf('week').subtract(7, 'day').toDate();
    const olderDay = moment(now).startOf('week').subtract(8, 'day').toDate();

    return { today, yesterday, weekStart, lastWeek, olderDay };
}

const getDirectionOfSort = (userPreference: any, basket: string | undefined): any => {
    let direction;
    if (basket === "DONE") {
        direction = userPreference.outbasket ? userPreference.outbasket.dateSorting : userPreference.dateSorting;
    } else {
        const b = basket?.toLowerCase() as string;
        direction = userPreference[b] ? userPreference[b].dateSorting : userPreference.dateSorting;
    }
    return direction;
}

const getParticleLabelsOrder = (userPreference: { [x: string]: { particleLabelsOrder: any; }; outbasket: { particleLabelsOrder: any; }; particleLabelsOrder: any; }, basket: string | undefined): any => {
    let particleLabelsOrder;
    if (basket === "DONE") {
        particleLabelsOrder = userPreference.outbasket ? userPreference.outbasket.particleLabelsOrder : userPreference.particleLabelsOrder;
    } else {
        const b = basket?.toLowerCase() as string;
        particleLabelsOrder = particleLabelsOrder = userPreference[b] ? userPreference[b].particleLabelsOrder : userPreference.particleLabelsOrder;
    }
    return particleLabelsOrder;
}


export function particleTypeOnlySort(particles: Particle[], userPreference: any, basket?: string) {
    if (particles === undefined || particles.length < 1) {
        return [];
    }

    const directionOfSort = getDirectionOfSort(userPreference, basket);
    const particleLabelsOrder = getParticleLabelsOrder(userPreference, basket);

    // console.log("particleLabelsOrder : ", particleLabelsOrder);
    let newParticles;
    if (particles.length > 0) {
        newParticles = setParticleType(particleLabelsOrder, particles);
        newParticles.forEach((particleCopy: { creationDate: string | Date | JSON | Timestamp | moment.Moment; }) => {
            particleCopy.creationDate = toFirebaseTimestamp(particleCopy.creationDate);
        });
    }
    const typeLabelledParticles = groupParticlesOnTypeLabels(newParticles);
    const orderedTypeGroups = orderParticleTypeGroups(typeLabelledParticles, particleLabelsOrder, directionOfSort);
    return basket ? (orderedTypeGroups.filter((particle) => particle.labelIds.includes(basket))) : orderedTypeGroups;
}

export function particleTypeDateSort(particles: Particle[], userPreference: any, basket?: string) {
    if (particles === undefined || particles.length < 1) {
        return [];
    }

    const directionOfSort = getDirectionOfSort(userPreference, basket);
    const particleLabelsOrder = getParticleLabelsOrder(userPreference, basket);

    let newParticles;
    if (particles.length > 0) {
        newParticles = setParticleType(particleLabelsOrder, particles);
        newParticles.forEach((particleCopy: { creationDate: string | Date | JSON | Timestamp | moment.Moment; }) => {
            particleCopy.creationDate = toFirebaseTimestamp(particleCopy.creationDate);
        });
    }
    const typeLabelledParticles = groupParticlesOnTypeLabels(newParticles);
    const orderedTypeGroups = orderParticleTypeGroups(typeLabelledParticles, particleLabelsOrder, directionOfSort);
    return basket ? (orderedTypeGroups.filter((particle) => particle.labelIds.includes(basket))) : orderedTypeGroups;
}

export function particleDateTypeSort(particles: Particle[], userPreference: any, basket?: string) {
    if (particles === undefined || particles.length < 1) {
        return [];
    }

    const directionOfSort = getDirectionOfSort(userPreference, basket);
    const particleLabelsOrder = getParticleLabelsOrder(userPreference, basket);

    let newParticles;
    if (particles.length > 0) {
        newParticles = setParticleType(particleLabelsOrder, particles);
        newParticles = setParticleDateType(newParticles);
        newParticles.forEach((particleCopy: { creationDate: string | Date | JSON | Timestamp | moment.Moment; }) => {
            particleCopy.creationDate = toFirebaseTimestamp(particleCopy.creationDate);
        });
    }

    const particlesWithLabelsSorted = sortByDate(newParticles, directionOfSort);

    const dateLabelledParticles = groupParticlesOnDateLabels(particlesWithLabelsSorted);
    const orderedTypeGroups = orderTheGroupsByType(dateLabelledParticles, particleLabelsOrder, directionOfSort);
    const resultedParticlesInOrder = orderTheGroupsTwoLayersDeep(orderedTypeGroups);
    return basket ? (resultedParticlesInOrder.filter((particle) => particle.labelIds.includes(basket))) : resultedParticlesInOrder;
}

export function particleDateOnlySort(particles: Particle[], userPreference: any, basket?: string) {
    if (particles === undefined || particles.length < 1) {
        return [];
    }

    const directionOfSort = getDirectionOfSort(userPreference, basket);

    let newParticles;
    if (particles.length > 0) {
        newParticles = setParticleDateType(particles);
        newParticles.forEach((particleCopy: { creationDate: string | Date | JSON | Timestamp | moment.Moment; }) => {
            particleCopy.creationDate = toFirebaseTimestamp(particleCopy.creationDate);
        });
    }

    const particlesWithLabelsSorted = sortByDate(newParticles, directionOfSort);

    return basket ? (particlesWithLabelsSorted.filter((particle) => particle.labelIds.includes(basket))) : particlesWithLabelsSorted;
}

