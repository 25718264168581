import { Component, ViewEncapsulation } from '@angular/core';
import { DraftService } from '../../services/draft.service';
import { FileService } from '../../services/files.service';
import { DomService } from '../../services/dom.service';
import { DeleteProcess } from '@newgenus/common';

@Component({
  selector: 'newgenus-file',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FileUploadComponent {
  public progress = { percentage: 0 };
  public currentFileUpload = true;
  public particleKey!: string;
  private componentRef: any;
  public fileName!: string;
  public number: number;

  constructor(private domService: DomService, private fileService: FileService, public draftService: DraftService) {

    this.componentRef = domService.componentRef;
    this.number = domService.number + 1;
    domService.number = this.number;

    try {
      if (this.fileService.uploadPercentArray &&
        this.fileService.uploadPercentArray.length > 0) {

        // TODO takeWhile(this.currentFileUpdate === true) ?
        this.fileService.uploadPercentArray[this.fileService.uploadPercentArray.length - 1].subscribe(val => {
          this.progress.percentage = Math.round(val);
          if (val === 100) {
            setTimeout(() => { this.currentFileUpload = false; }, 2000);
          }
        });
      }
    } catch (error) {
      console.error('file-upload Component error: ', error)
    }
  }

  public remove(fileName: string, particleKey: any): void {
    const deleteObj = {
      filename: fileName,
      particleKey: particleKey,
      deleteProgress: DeleteProcess.started,
      saved: false
    };

    this.domService.removeComponentFromBody(this.domService.componentRefMap.get(this.number));
    this.fileService.deletedAttachmentsOb$.next(deleteObj);
  }

  public truncate(value = '', limit = 25): string {
    const extension = ~value.lastIndexOf('.') ? value.substr(value.lastIndexOf('.')) : '.';

    if ((value.length - extension.length) < limit) { return value; }

    const limitedStr = value.substr(0, limit);

    let newLimit = this.truncateOnChar(value.length, limitedStr, ' ');
    if (~newLimit) { limit = newLimit; }
    else {
      newLimit = this.truncateOnChar(value.length, limitedStr, '_');
      if (~newLimit) { limit = newLimit; }
      else {
        newLimit = this.truncateOnChar(value.length, limitedStr, '-');
        if (~newLimit) { limit = newLimit; }
        else {
          newLimit = this.truncateOnChar(value.length, limitedStr, '.');
          if (~newLimit) { limit = newLimit; }
        }
      }
    }

    return value.length > limit ? value.substr(0, limit) + '..' + extension : value;
  }

  private truncateOnChar(strLength: number, trimmedStr: string, char: ' ' | '_' | '-' | '.'): number {
    const lastIdx = trimmedStr.lastIndexOf(char);
    return (strLength > lastIdx) ? lastIdx : -1;
  }

}
