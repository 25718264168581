<!-- Title / header -->
<h2 mat-dialog-title>Settings for {{ data.widget.title || widgetName }} </h2>

<!-- Content / Body -->
<div mat-dialog-content class="h-75">
  <ng-container *ngIf="initiationComplete; else showLoading">

    <form [formGroup]="form" class="flex-row-wrap mt-2">
      
      <!-- Title of the Widget -->
      <mat-form-field>
        <mat-label>Widget Title</mat-label>
        <input matInput maxlength="20" formControlName="title" placeholder="Widget Title">
        <mat-error *ngIf="form.controls['title'].hasError('maxlength')">Title must be less than 20 characters long.</mat-error>
        <!-- <mat-hint>Name the Widget something helpful to you.</mat-hint> -->
      </mat-form-field>
    
    </form>

    <!-- Widget settings for different widget types. -->
    <section [ngSwitch]="data.widget.type">
      
      <!-- ====================== Daily ====================== -->
      <form *ngSwitchCase="'daily7r'" [formGroup]="form.controls['settings']" class="flex-row-wrap mt-2">

        <!-- implement shared fields template -->
        <ng-container *ngTemplateOutlet="sharedFields"></ng-container>

        <!-- implement secondary shared fields template - hidden from view until feature is fully supported -->
        <!-- <ng-container *ngTemplateOutlet="secondarySharedFields"></ng-container> -->

      </form>

      <!-- ====================== Monthly ====================== -->
      <form *ngSwitchCase="'monthly7r'" [formGroup]="form.controls['settings']" class="flex-row-wrap mt-2">

        <!-- implement shared fields template -->
        <ng-container *ngTemplateOutlet="sharedFields"></ng-container>

        <!-- implement secondary shared fields template - hidden from view until feature is fully supported -->
        <!-- <ng-container *ngTemplateOutlet="secondarySharedFields"></ng-container> -->

      </form>

      <!-- ====================== Client ABC Graph ====================== -->
      <form *ngSwitchCase="'clientAbcGraph'" [formGroup]="form.controls['settings']"
        class="flex-row-wrap mt-2">

        <!-- implement shared fields template -->
        <ng-container *ngTemplateOutlet="sharedFields"></ng-container>

        <!-- implement secondary shared fields template - hidden from view until feature is fully supported -->
        <!-- <ng-container *ngTemplateOutlet="secondarySharedFields"></ng-container> -->

      </form>

      <!-- ====================== Client ABC ====================== -->
      <form *ngSwitchCase="'clientAbc'" [formGroup]="form.controls['settings']" class="flex-row-wrap mt-2">
        <ng-container *ngTemplateOutlet="clientAbcTemplate"></ng-container>

        <p>
          Dynamic Grouping:
          <mat-button-toggle-group formControlName="dynamicGrouping" class="density-down-3 mt-2">
            <mat-button-toggle value="Main Account">Main Account</mat-button-toggle>
            <mat-button-toggle value="No grouping">No grouping</mat-button-toggle>
          </mat-button-toggle-group>
        </p>
        
        <p>
          Show Values as:
          <mat-button-toggle-group formControlName="appearance" class="density-down-3 mt-2">
            <mat-button-toggle value="nett">Exclusive of Tax</mat-button-toggle>
            <mat-button-toggle value="gross">Inclusive of Tax</mat-button-toggle>
          </mat-button-toggle-group>
        </p>

      </form>

      <!-- ====================== Client ABC Group ====================== -->
      <form *ngSwitchCase="'clientAbcGroup'" [formGroup]="form.controls['settings']" class="flex-row-wrap mt-2">
        <ng-container *ngTemplateOutlet="clientAbcTemplate"></ng-container>
      </form>
      
      <!-- ====================== Client ABC Group List ====================== -->
      <form *ngSwitchCase="'clientAbcGroupList'" [formGroup]="form.controls['settings']" class="flex-row-wrap mt-2">
        <ng-container *ngTemplateOutlet="clientAbcTemplate"></ng-container>
      </form>

      <p *ngSwitchDefault>No configuration available for unrecognized widgets.</p>

      <div *ngSwitchDefault>
        <p>Settings unavailable for this widget...</p>
      </div>
    </section>
    
  </ng-container>
  <ng-template #showLoading>
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>

</div>


<!-- <pre>
  <p>Invalid: {{form.invalid}}</p>
  <p>Errors: {{form.errors | json}}</p>
  <p>Errors: {{form.controls['settings'].errors | json}}</p>
  <p>Value: {{form.value | json}}</p>
</pre> -->

<!-- Controls / Footer -->
<div mat-dialog-actions class="d-flex">
  <div class="flex-spacer"></div>
  <button mat-button [disabled]="form.invalid" [mat-dialog-close]="submit()" cdkFocusInitial color="primary">Save</button>
  <button mat-button [mat-dialog-close]="" color="warn">Cancel</button>
</div>


<!-- Templates -->

<ng-template #clientAbcTemplate>
  <form [formGroup]="form.controls['settings']" class="flex-row-wrap mt-2">

    <!-- implement shared fields template -->
    <ng-container *ngTemplateOutlet="sharedFields"></ng-container>

    <!-- implement secondary shared fields template - hidden from view until feature is fully supported -->
    <!-- <ng-container *ngTemplateOutlet="secondarySharedFields"></ng-container> -->
    <section class="flex-pair">
      <mat-form-field *ngIf="form.controls['type'].value !== 'clientAbcGroupList'" [ngClass]="{'flex-1': form.controls['type'].value !== 'clientAbc'}">
        <mat-label>Table Columns</mat-label>
        <mat-select formControlName="tableColumns" multiple>
          <mat-option *ngFor="let opt of clientAbcTableColumns" [value]="opt.value">{{opt.display}}</mat-option>
        </mat-select>
        <mat-hint>What columns to show</mat-hint>
      </mat-form-field>

      <mat-form-field *ngIf="form.controls['type'].value === 'clientAbcGroupList'">
        <mat-label>Table Columns</mat-label>
        <mat-select formControlName="tableColumns" multiple>
          <mat-option *ngFor="let opt of clientAbcGroupTableColumns" [value]="opt.value">{{opt.display}}</mat-option>
        </mat-select>
        <mat-hint>What columns to show</mat-hint>
      </mat-form-field>

      <mat-form-field class="mb-2" *ngIf="form.controls['type'].value === 'clientAbc'">
        <mat-label>Transaction Types</mat-label>
        <mat-select formControlName="transactionTypes" multiple>
          <mat-option *ngFor="let opt of clientAbcTransactionOptions" [value]="opt.value">{{opt.display}}</mat-option>
        </mat-select>
        <mat-hint>Types of transactions to include</mat-hint>
      </mat-form-field>
    </section>
<!-- 
    <section class="flex-pair">
        <span class="d-flex">Dynamic Grouping:</span>
        <mat-button-toggle-group formControlName="dynamicGrouping" class="density-down-3 mt-2">
          <mat-button-toggle value="Main Account">Main Account</mat-button-toggle>
          <mat-button-toggle value="No grouping">No grouping</mat-button-toggle>
        </mat-button-toggle-group>
    </section>
    <section class="flex-pair">
        <span class="d-flex">Show Values as:</span>
        <mat-button-toggle-group formControlName="appearance" class="density-down-3 mt-2">
          <mat-button-toggle value="nett">Exclusive of Tax</mat-button-toggle>
          <mat-button-toggle value="gross">Inclusive of Tax</mat-button-toggle>
        </mat-button-toggle-group>
    </section> -->
  </form>
</ng-template>

<ng-template #sharedFields>
  <form [formGroup]="form.controls['settings']" class="flex-row-wrap mt-2">

    <section class="flex-pair">
      <mat-form-field>
        <mat-label>Date Profile</mat-label>
        <mat-select formControlName="dateProfileKey">
          <mat-option *ngFor="let opt of dateProfileOptions" [value]="opt.value">{{opt.display}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Integration</mat-label>
        <mat-select formControlName="integrationKey" (selectionChange)="onSelectIntegration($event)">
          <mat-option *ngFor="let opt of integrationOptions" [value]="opt.value">{{opt.display}}</mat-option>
        </mat-select>
      </mat-form-field>
    </section>

    <section class="flex-pair" *ngIf="!isClientAbcGroup">
      <mat-form-field [ngStyle]="{'flex: 1 auto;': showForCompanyControl === false}">
        <mat-label>{{isMultipleRepsEnabled ? 'Reps' : 'Rep'}}</mat-label>
        <mat-select formControlName="repKeys" [multiple]="isMultipleRepsEnabled" (selectionChange)="onSelectRep($event)">
          <mat-option *ngFor="let opt of repOptions" [value]="opt.value">{{opt.display}}</mat-option>
        </mat-select>
    </mat-form-field>

      <mat-slide-toggle *ngIf="showForCompanyControl" formControlName="forCompany" class="fit-to-form-field" (change)="onToggleForCompany($event)"><span class="ml-2">For Company</span>
        <mat-icon matTooltip="When checked, you won't have to select reps, as all reps for the company will be used for this widget - even when new reps onboard.">info</mat-icon>
      </mat-slide-toggle>
    </section>

    <section class="flex-pair" *ngIf="isClientAbcGroup">
      <mat-form-field [ngStyle]="{'flex: 1 auto;': form.controls['type'].value !== 'clientAbcGroupList'}">
        <mat-label>{{isMultipleRepsEnabled ? 'Users' : 'User'}}</mat-label>
        <mat-select formControlName="userKeys" [multiple]="isMultipleRepsEnabled" (selectionChange)="onSelectClientAbcUser($event)">
          <mat-option *ngFor="let opt of repOptions" [value]="opt.value">{{opt.display}}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Only shown for Client ABC Group LIST type. -->
      <mat-slide-toggle *ngIf="form.controls['type'].value === 'clientAbcGroupList'" class="fit-to-form-field" formControlName="forAllGroups" (change)="onToggleForAllGroups($event)"><span class="ml-2">For All Groups</span>
        <mat-icon matTooltip="When checked, all groups for available will be listed and automatically updated when groups are are created, modified or deleted.">info</mat-icon>
      </mat-slide-toggle>
    </section>

    <section class="flex-pair" *ngIf="isClientAbcGroup">
      <mat-form-field style="flex: 1 auto;">
        <mat-label>{{form.controls['type'].value === 'clientAbcGroupList' ? 'Groups' : 'Group'}}</mat-label>
        <mat-select formControlName="groupKeys" [multiple]="form.controls['type'].value === 'clientAbcGroupList'" (selectionChange)="onSelectClientAbcGroup($event)">
          <mat-option *ngFor="let opt of clientAbcGroupOptions" [value]="opt.value">{{opt.display}}</mat-option>
        </mat-select>
      </mat-form-field>
    </section>

    <mat-form-field class="mb-2" *ngIf="!isClientAbcGroup">
      <mat-label>Transaction Types to Show</mat-label>
      <mat-select formControlName="filtersByCode" multiple>
        <mat-option *ngFor="let opt of (form.controls['type'].value === 'monthly7r' ? monthlyTransactionOptions : dailyTransactionOptions)" [value]="opt.value">{{opt.display}}</mat-option>
      </mat-select>
      <mat-hint>Types of transactions to include</mat-hint>
    </mat-form-field>

    <section class="flex-pair">
      <mat-form-field>
        <mat-label>Columns</mat-label>
        <mat-select formControlName="columns">
          <mat-option *ngFor="let opt of columnOptions" [value]="opt.value">{{opt.display}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Rows</mat-label>
        <mat-select formControlName="rows">
          <mat-option *ngFor="let opt of rowOptions" [value]="opt.value">{{opt.display}}</mat-option>
        </mat-select>
      </mat-form-field>
    </section>

  </form>
</ng-template>

<ng-template #secondarySharedFields>
  <form [formGroup]="form.controls['settings']" class="flex-row-wrap mt-2">

    <mat-form-field>
      <mat-label>Show For</mat-label>
      <mat-select formControlName="showFor">
        <mat-option *ngFor="let opt of showForOptions" [value]="opt.value">{{opt.display}}</mat-option>
      </mat-select>
    </mat-form-field>

  </form>
</ng-template>