import { OrgDateCycle, Organization } from "../interfaces/fe-models/organization-models";

/**
 * Create a family tree for date cycles.
 * This function will create a family tree for date cycles based on the profile name and year.
 * Safe to call on the same date cycles multiple times as it will skip date cycles that already have a family key.
 * 
 * @example
 * const statisticsCycles: OrgDateCycle[] = [
 *    {
 *       key: '123',
 *       parameters: { ... },
 *       profileName: 'John Doe',
 *       type: 'Statistics',
 *       year: '2022'
 *    },
 *    {
 *       key: '456',
 *       parameters: { ... },
 *       profileName: 'John Doe',
 *       type: 'Statistics',
 *       year: '2023'
 *    },
 *    {
 *       key: '789',
 *       parameters: { ... },
 *       profileName: 'John Doe',
 *       type: 'Statistics',
 *       year: '2024'
 *    }
 * ];
 * 
 * const org: Organization = { ... };
 * const updatedStatisticsCycles = createFamilyTreeForDateCycles(statisticsCycles, org);
 * 
 * console.log(updatedStatisticsCycles);
 * // Result:
 * // [
 * //    {
 * //       key: '123',
 * //       parameters: { ... },
 * //       profileName: 'John Doe',
 * //       type: 'Statistics',
 * //       year: '2022',
 * //       familyKey: 'abc', // <-- Family key is generated.
 * //       linkDateCycles: [] // <-- No linkDateCycles because it's the first date cycle.
 * //    },
 * //    {
 * //       key: '456',
 * //       parameters: { ... },
 * //       profileName: 'John Doe',
 * //       type: 'Statistics',
 * //       year: '2023',
 * //       familyKey: 'abc', // <-- Family key is the same as the previous date cycle.
 * //       linkDateCycles: [ // <-- Link to the previous date cycle.
 * //          {
 * //             key: '123',
 * //             name: 'John Doe',
 * //             year: '2022'
 * //          }
 * //       ]
 * //    },
 * //    {
 * //       key: '789',
 * //       parameters: { ... },
 * //       profileName: 'John Doe',
 * //       type: 'Statistics',
 * //       year: '2024',
 * //       familyKey: 'abc', // <-- Family key is the same as the previous date cycle.
 * //       linkDateCycles: [ // <-- Link to the previous date cycle.
 * //          {
 * //             key: '456',
 * //             name: 'John Doe',
 * //             year: '2023'
 * //          }
 * //       ]
 * //    }
 * // ]
 *
 * @param {OrgDateCycle[]} statisticsCycles an array of date cycles to create a family tree for.
 * @param {Organization} org the organization to create the family tree for.
 * @return {OrgDateCycle[]} an array of date cycles with the family tree created.
 */
export function createFamilyTreeForDateCycles(statisticsCycles: OrgDateCycle[], org: Organization): OrgDateCycle[] {
    const cyclesToUpdate: OrgDateCycle[] = [];
    const keysToSkip: string[] = [];

    for (let j = 0; j < statisticsCycles.length; j++) {
        const dc = statisticsCycles[j];

        // Skip date cycles already processed.
        if (keysToSkip.includes(dc.key)) {
            console.log('<' + org.key + '> skipping', dc.key, '(already processed)');
            continue;
        }

        // If the date cycle has a familyKey, skip it.
        if (dc.familyKey) {
            console.log('<' + org.key + '> skipping', dc.key, '(already has familyKey)');
            keysToSkip.push(dc.key);
            continue;
        }

        // Family relation is based on profile name or family key.
        let cycleFamily = findDateCycleFamilyTree(statisticsCycles, dc);

        // Find an existing family key in the family or create a new one.
        const familyKey = extractOrCreateFamilyKey(cycleFamily);

        // Set family key on each date cycle.
        cycleFamily = cycleFamily.map((dc, index) => {
            dc.familyKey = familyKey;
            if (index > 0) {
                const sibling = cycleFamily[index - 1];

                // If there is no linkDateCycles array or if the sibling is not already linked, link the sibling to the current date cycle.
                if (!dc.linkDateCycles || (dc.linkDateCycles && !dc.linkDateCycles.find(ldc => ldc.key === sibling.key))) {
                    console.log('<' + org.key + '> linking', dc.key, 'to', sibling.key);

                    // Create linkedDateCycle array.
                    if (!dc.linkDateCycles) dc.linkDateCycles = [];

                    dc.linkDateCycles.push({
                        key: sibling.key,
                        name: sibling.profileName,
                        year: sibling.year
                    });
                }

                // Already linked.
                else console.log('<' + org.key + '> already linked', dc.key, 'to', sibling.key);
            }

            // Set an empty array to linkDateCycles if it doesn't exist.
            else if (!dc.linkDateCycles) dc.linkDateCycles = [];


            return dc;
        });

        // Update keysToSkip and CyclesToUpdate.
        cycleFamily.forEach(dc => {
            keysToSkip.push(dc.key);
            cyclesToUpdate.push(dc);
        });

    }

    return cyclesToUpdate;
}

/**
 * Find the family tree for a date cycle.
 *
 * @param {OrgDateCycle[]} filteredDateCycles an array of date cycles to search in (this should already be filtered to the 'statistics' type or what ever type is required).
 * @param {OrgDateCycle} dateCycle the date cycle to find the family tree for.
 * @return {OrgDateCycle[]} an array of date cycles that are in the family tree.
 */
export function findDateCycleFamilyTree(filteredDateCycles: OrgDateCycle[], dateCycle: OrgDateCycle): OrgDateCycle[] {
    // Family relation is based on profile name or family key. The name and year is a unique identifier, therefore each year can have the same name.
    return filteredDateCycles
        .filter(c => (c.familyKey && c.familyKey === dateCycle.familyKey) || c.profileName.toLowerCase().trim() === dateCycle.profileName.toLowerCase().trim())
        // Sort by year ascending (earliest first).
        .sort((a, b) => +a.year - +b.year);
}

/**
 * Extract or create a family key for a date cycle family.
 * 
 * @returns {string} the family key.
 */
export function extractOrCreateFamilyKey(cycleFamily: OrgDateCycle[]): string {
    return cycleFamily?.find(c => c.familyKey)?.familyKey || Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}