import { SecurityGroupDetails, Position } from '../../services/tree-builder.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, inject, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MaterialModule } from '../../../material.module';
import { NgFor, TitleCasePipe } from '@angular/common';
import { NormalizePipe } from '../../format-pipe';
import { ECONode } from '@newgenus/common';

@Component({
  selector: 'shared-org-hat-dialog',
  standalone: true,
  imports: [MaterialModule, NgFor, TitleCasePipe, NormalizePipe],
  templateUrl: './org-hat-dialog.html',
  styleUrls: [
    './org-hat-dialog.scss',
    '../org-dialog.scss',
    '../../organization-board.component.scss'
  ],
})
export class OrgHatDialogComponent implements OnInit {

  public hatName = '';
  public securityGroups: SecurityGroupDetails[] = [];

  public snackbar = inject(MatSnackBar);

  constructor(
    public dialogRef: MatDialogRef<OrgHatDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      user?: any,
      node: ECONode<any>
    }
  ) {

  }

  public ngOnInit(): void {
    console.log('Dialog data: ', this.data);

    // TODO: we'll probably need to determine what type of EcoNode is being inputted.
    // At the moment, we only have EcoNode<Position> calling this dialog.
    const node = this.data.node as ECONode<Position>;
    console.log('Node: ', node);

    if (node.data) {
      this.hatName = node.data.name;
      this.securityGroups = node.data.securityGroupsArray || [];
    }

  }

  public get userDetailArray() {
    return this.data?.node?.data?.userDetailsArray || [];
  }

  public onClickFeature(group: SecurityGroupDetails, featureClicked: string) {
    console.log('Feature clicked: ', featureClicked);
    console.log('Feature group: ', group);
    this.snackbar.open(`Feature ${featureClicked} clicked`, 'Close', { duration: 2000 });

  }

}
