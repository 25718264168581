import { OrgDialogServiceService } from './services/org-dialog.service';
import { Component, inject, Input, OnInit } from '@angular/core';
import { Organization } from '@newgenus/common';

/**
 * Base component class with no template.
 * Used to create a base class for all components in the Orb Board.
 * 
 * Includes shared services, properties, styling, etc.
 */
@Component({
    selector: 'shared-org-board-base-component',
    template: ``,
    styleUrls: ['./org-board-base-component.scss']
})
export abstract class OrbBoardBaseComponent implements OnInit {

    public dialogService = inject(OrgDialogServiceService);

    @Input() // Required
    public org: Organization | null = null;

    constructor() { }

    public abstract ngOnInit(): void;
}
