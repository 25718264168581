import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RoutesState } from '../reducers/routes.reducers';
import * as fromRoutes from '../reducers/routes.reducers';

export const selectRoutesState = createFeatureSelector<RoutesState>('routes');

export const selectAllRoutes = createSelector(selectRoutesState, fromRoutes.selectAll);

/**
 * @deprecated - not sure this is required anymore?
 */
export const selectRoute = (destinationRoute: string) => {
    return createSelector(selectAllRoutes, (routes) =>
        routes.filter((routeDoc) => {
            return routeDoc.route === destinationRoute;
        })
    );
};

export const selectRouteByURL = (destinationRoute: string) => {
    return createSelector(selectAllRoutes, (routes) => {
        return routes.filter((routeDoc) => routeDoc.route === destinationRoute);
    });
};

export const selectRoutesByFeatureKeysOrSystemRoute = (featureKeys: string[]) => {
    return createSelector(selectAllRoutes, (routes) => {

        const matchingRoutes = routes.filter((routeDoc) => {
            return (
                featureKeys.includes(routeDoc.routeFeature) ||
                routeDoc.features.some((f) => featureKeys.includes(f)) ||
                (routeDoc.systemRoute && routeDoc.features.some((rFeatureKey) => featureKeys.includes(rFeatureKey)))
            );
        });

        return matchingRoutes;
    });
};

export const areRoutesLoaded = createSelector(selectRoutesState, (state) => {
    return state.allRoutesLoaded;
});
