import { ParticleHelper } from '../../../shared/utils/helpers/particle-helper';
import { ParticleService } from '../../services/particle.service';
import { StoreUser } from '../../../workspace/user.abstract';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Particle } from '@newgenus/common';
import { takeUntil } from 'rxjs/operators';
import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';


@Component({
  selector: 'newgenus-custom-sort',
  templateUrl: './custom-sort.component.html',
  styleUrls: ['./custom-sort.component.scss']
})
export class CustomSortComponent extends StoreUser {

  public typeOfParticleOrders: any[];

  public particleTypes: string[] = ['order', 'dispatch', 'email'];

  public dateOrderTypes: any[] = [{ text: 'Ascending', value: false }, { text: 'Descending', value: true }];

  public originalList: Particle[] = [];

  public customSortedList: Particle[] = [];

  public typeOfSort = "";

  public dateOrderDirection = false;

  public groupedHeader: any;

  public particleLabels: string[] = [];

  public dateSortDirection: any;
  private destroy$ = new Subject<void>();

  constructor(
    // public modal: NgbActiveModal,
    private particleHelper: ParticleHelper,
    private particleService: ParticleService,
    override store: Store<any>) {
    super(store);
    this.initStoreUser(this.destroy$)

    this.typeOfParticleOrders = [{ val: 'type_date', desc: 'Order dates then particles' },
    { val: 'date_type', desc: 'Order particles then dates' },
    { val: 'date', desc: 'Order date without particles' },
    { val: 'type', desc: 'Order particles without dates' }];


    this.typeOfSort = this.typeOfParticleOrders[0].value;

    this.user$
      .pipe(takeUntil(this.destroy$))
      .subscribe(users => {
        if (users) {
          this.userId = users[0].uid;
          this.particleLabels = users[0].preferences.particleLabelsOrder as string[];
          this.typeOfSort = users[0].preferences.typeOfSort;
          this.dateSortDirection = users[0].preferences.dateSorting;
        }
      })
  }

  applyFilter(target: any) {
    const _order_sort = document.getElementById('particle_sort') as HTMLElement;
    const _date_sort = document.getElementById('date_sort') as HTMLElement;

    if (target.value === 'date') {
      _order_sort.style.display = 'none';
      _date_sort.style.display = 'flex';

    }
    else
      if (target.value === 'type') {

        _date_sort.style.display = 'none'
        _order_sort.style.display = 'flex';
      }
      else {

        _order_sort.style.display = 'flex'
        _date_sort.style.display = 'flex';

      }

    this.typeOfSort = target.value;
  }

  onDrop(event: any) {

    moveItemInArray(this.particleLabels, event.previousIndex, event.currentIndex);
    this.particleService.updateUserParticleLabelSort(this.particleLabels);
  }

  specifyOrder() {
    this.originalList = this.originalList as Particle[];

    this.originalList = this.particleHelper.assignDefaultOrderToParticles(this.originalList, this.particleLabels);

    const sorts: string = (this.typeOfSort === undefined) ? this.typeOfParticleOrders[0].val : this.typeOfSort;

    this.originalList = this.particleHelper.sortParticlesByUserPreference(this.originalList, sorts, this.dateOrderDirection);

    this.particleService.updateTypeOfSort(sorts, this.userId);
    this.particleService.updateDateSortDirection((this.dateOrderDirection) ? "asc" : "desc", this.userId);

    // this.modal.close(this.originalList);
    throw new Error('NGX modal has been removed. Please replace this code with a dialog from Material.')
  }

  closePopUp() {
    throw new Error('NGX modal has been removed. Please replace this code with a dialog from Material.')
    // this.modal.close(this.originalList);
  }

  getCustomSortedList() {
    return this.customSortedList;
  }

  recordSelection(val: boolean) {
    this.dateOrderDirection = val;
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

}
