import { forEach } from "lodash";

export class OrganizationsEngine {

    private key!: string;
    private org!: any;
    private orgData!: any;

    constructor(organization: any) {
        this.org = organization;
        this.key = organization.key;
    }

    private fetchOrganization() {
        const userHats: any = {};
        const hats: any = [];
        this.orgData = { [this.key]: {} };

        for (const hatKey of this.org.orgHats.hatsKeys) {
            hats.push({
                ...this.org.orgHats[hatKey],
                key: hatKey
            });
        }

        for (const userKey of this.org.orgUsers.userKeys) {
            userHats[userKey] = { "hats": [] };
            this.org.orgUsers[userKey].hats.forEach((hatKey: any) => {

                const hat = hats.find((o: any) => {
                    if (o.key === hatKey) {
                        return o;
                    }
                });

                userHats[userKey].hats.push({
                    orgUser: { ...this.org.orgUsers[userKey] },
                    key: userKey,
                    hat: hat
                });
            });
        }

        const userHatsArray: any[] = [];
        // turn the users into an array
        for (const userKey in userHats) {
            userHatsArray.push({ [userKey]: Object.values(userHats[userKey].hats) });
        }

        this.orgData[this.key] = {
            userHats: userHatsArray,
            hats: hats,
            ...this.org[this.key]
        };

    }

    private genOrganizationStructure() {
        for (const part of this.org.orgParts) {

            const usersWithHatAndPosition: any = {};
            forEach(this.orgData[this.key].userHats, (user: any) => {
                const hats: any[] = [];
                const userKey = Object.keys(user)[0];
                for (const hat of user[userKey]) {
                    const userHat = hat;
                    const position = typeof userHat.hat.orgPosition === 'object' ? userHat.hat.orgPosition.key : userHat.hat.orgPosition;

                    if (position === part.key) {
                        hats.push(userHat);

                        if (!usersWithHatAndPosition[userKey]) {
                            usersWithHatAndPosition[userKey] = [];
                        }
                        usersWithHatAndPosition[userKey].push(hats)
                    }
                }
            });

            for (const userKey in usersWithHatAndPosition) {
                const user = usersWithHatAndPosition[userKey];

                if (!part.users) {
                    part.users = [];
                    part.uids = [];
                }
                part.users.push(user[0][0]);
                part.uids.push(userKey);

            };

            if (part.depth === 1 || part.depth === 2 ||
                part.key === "division7" ||
                part.key === "department19" ||
                part.key === "department20" ||
                part.key === "department21"
            ) {

                // add the other fields to data
                const partData = {
                    linkColor: "blue",
                    background: "blue",
                    color: "white",
                    selected: true,
                };
                part.levelUp = partData;
            }
            if (
                part.key === "division1" ||
                part.key === "department1" ||
                part.key === "department2" ||
                part.key === "department3"
            ) {

                // add the other fields to data
                const partData = {
                    linkColor: "orange",
                    background: "orange",
                    color: "white",
                    selected: true,
                };
                part.levelUp = partData;
            }
            if (part.key === "division2" ||
                part.key === "department4" ||
                part.key === "department5" ||
                part.key === "department6"
            ) {

                // add the other fields to data
                const partData = {
                    linkColor: "purple",
                    background: "purple",
                    color: "white",
                    selected: true,
                };
                part.levelUp = partData;
            }
            if (part.key === "division3" ||
                part.key === "department7" ||
                part.key === "department8" ||
                part.key === "department9"
            ) {

                // add the other fields to data
                const partData = {
                    linkColor: "pink",
                    background: "pink",
                    color: "black",
                    selected: true,
                };
                part.levelUp = partData;
            }
            if (part.key === "division4" ||
                part.key === "department10" ||
                part.key === "department11" ||
                part.key === "department12"
            ) {

                // add the other fields to data
                const partData = {
                    linkColor: "lime",
                    background: "lime",
                    color: "black",
                    selected: true,
                };
                part.levelUp = partData;
            }
            if (part.key === "division5" ||
                part.key === "department13" ||
                part.key === "department14" ||
                part.key === "department15"
            ) {

                // add the other fields to data
                const partData = {
                    linkColor: "grey",
                    background: "grey",
                    color: "white",
                    selected: true,
                };
                part.levelUp = partData;
            }
            if (part.key === "division6" ||
                part.key === "department16" ||
                part.key === "department17" ||
                part.key === "department18"
            ) {

                // add the other fields to data
                const partData = {
                    linkColor: "yellow",
                    background: "yellow",
                    color: "black",
                    selected: true,
                };
                part.levelUp = partData;
            }
        }

        const newFlat: any = {};
        for (const part of this.org.orgParts) {
            for (const key of Object.keys(part)) {
                if (key !== "levelUp") {
                    const path = part.path + "." + key;
                    const value = part[key];
                    newFlat[path] = value;
                } else {
                    const path = part.path.substring(0, (part.path.lastIndexOf('.'))); // removes last item in path "item.item.xxx"
                    for (const key2 of Object.keys(part[key])) {
                        const pathResult = path === "" ? path + key2 : path + "." + key2;

                        const value = part[key][key2];
                        newFlat[pathResult] = value;
                    }
                }
            }
        }

        const unflattenedObj = unflatten(newFlat);

        return unflattenedObj;
    }

    public build() {
        this.fetchOrganization();

        const newOrgStruct = this.genOrganizationStructure();

        const orgStructure = [newOrgStruct];
        const orgToModify = JSON.parse(JSON.stringify([newOrgStruct]));

        return { orgStructure, orgToModify };
    }
}

function isBuffer(obj: any) {
    return obj &&
        obj.constructor &&
        (typeof obj.constructor.isBuffer === 'function') &&
        obj.constructor.isBuffer(obj);
}

function keyIdentity(key: any) {
    return key;
}

function flatten(target: any, opts?: any) {
    opts = opts || {};

    const delimiter = opts.delimiter || '.';
    const maxDepth = opts.maxDepth;
    const transformKey = opts.transformKey || keyIdentity;
    const output: any = {};

    function step(object: any, prev?: any, currentDepth?: any) {
        currentDepth = currentDepth || 1;
        Object.keys(object).forEach(function (key) {
            const value = object[key];
            const isarray = opts.safe && Array.isArray(value);
            const type = Object.prototype.toString.call(value);
            const isbuffer = isBuffer(value);
            const isobject = (
                type === '[object Object]' ||
                type === '[object Array]'
            );

            const newKey = prev
                ? prev + delimiter + transformKey(key)
                : transformKey(key);

            if (!isarray && !isbuffer && isobject && Object.keys(value).length &&
                (!opts.maxDepth || currentDepth < maxDepth)) {
                return step(value, newKey, currentDepth + 1);
            }

            output[newKey] = value;
        });
    }

    step(target);

    return output;
}

function unflatten(target: any, opts?: any) {
    opts = opts || {};

    const delimiter = opts.delimiter || '.';
    const overwrite = opts.overwrite || false;
    const transformKey = opts.transformKey || keyIdentity;
    const result = {};

    const isbuffer = isBuffer(target);
    if (isbuffer || Object.prototype.toString.call(target) !== '[object Object]') {
        return target;
    }

    // safely ensure that the key is an integer.
    function getkey(key: any) {
        const parsedKey = Number(key);

        return (
            isNaN(parsedKey) ||
            key.indexOf('.') !== -1 ||
            opts.object
        ) ? key
            : parsedKey;
    }

    function addKeys(keyPrefix: any, recipient: any, _target: any) {
        return Object.keys(_target).reduce(function (_result, key) {
            _result[keyPrefix + delimiter + key] = _target[key];

            return _result;
        }, recipient);
    }

    function isEmpty(val: any) {
        const type = Object.prototype.toString.call(val);
        const isArray = type === '[object Array]';
        const isObject = type === '[object Object]';

        if (!val) {
            return true;
        } else if (isArray) {
            return !val.length;
        } else if (isObject) {
            return !Object.keys(val).length;
        }

        return false;
    }

    target = Object.keys(target).reduce(function (_result: any, key: any) {
        const type = Object.prototype.toString.call(target[key]);
        const isObject = (type === '[object Object]' || type === '[object Array]');
        if (!isObject || isEmpty(target[key])) {
            _result[key] = target[key];
            return _result;
        } else {
            return addKeys(
                key,
                _result,
                flatten(target[key], opts)
            );
        }
    }, {});

    Object.keys(target).forEach(function (key) {
        const split = key.split(delimiter).map(transformKey);
        let key1 = getkey(split.shift());
        let key2 = getkey(split[0]);
        let recipient: any = result;

        while (key2 !== undefined) {
            if (key1 === '__proto__') {
                return;
            }

            const type = Object.prototype.toString.call(recipient[key1]);
            const isobject = (
                type === '[object Object]' ||
                type === '[object Array]'
            );

            // do not write over falsey, non-undefined values if overwrite is false
            if (!overwrite && !isobject && typeof recipient[key1] !== 'undefined') {
                return;
            }

            if ((overwrite && !isobject) || (!overwrite && recipient[key1] == null)) {
                recipient[key1] = (
                    typeof key2 === 'number' &&
                        !opts.object ? [] : {}
                );
            }

            recipient = recipient[key1];
            if (split.length > 0) {
                key1 = getkey(split.shift());
                key2 = getkey(split[0]);
            }
        }

        // unflatten again for 'messy objects'
        recipient[key1] = unflatten(target[key], opts);
    });

    return result;
}
