import { DynamicTableColumn, ECONode, Organization, OrgHat, TableAction } from '@newgenus/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Component, inject, Inject, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MaterialModule } from '../../../material.module';
import { NgFor, TitleCasePipe } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { NormalizePipe } from '../../format-pipe';


@Component({
  selector: 'shared-org-new-hat-dialog',
  standalone: true,
  imports: [MaterialModule, NgFor, TitleCasePipe, NormalizePipe, ReactiveFormsModule, MatMenuModule, MatButtonModule, MatIconModule],
  templateUrl: './org-new-hat-dialog.html',
  styleUrls: [
    './org-new-hat-dialog.scss',
    '../org-dialog.scss',
    '../../organization-board.component.scss'
  ],
})
export class OrgNewHatDialogComponent implements OnInit {

  public form = new FormGroup({
    key: new FormControl<string | null>(null),
    name: new FormControl<string | null>(null, Validators.compose([Validators.required, Validators.minLength(3)])),
  });

  public columns: DynamicTableColumn<OrgHat>[] = [
    {
      columnDef: 'name',
      header: 'Name',
      cell: (element: OrgHat) => element.name || '',
    },
    {
      columnDef: 'actions',
      header: '',
      cell: (row) => '',
    },
  ];
  public columnActions: TableAction<OrgHat>[] = [
    {
      display: 'Update',
      action: (row) => this.editHat(row),
      color: 'primary',
      disabled: false
    },
  ];

  public dataSource = new MatTableDataSource<OrgHat>([]);
  public displayedColumns = this.columns.map(c => c.columnDef);
  public tabIndex = 0;

  public snackbar = inject(MatSnackBar);
  private db = inject(AngularFirestore);

  constructor(
    public dialogRef: MatDialogRef<OrgNewHatDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputData
  ) { }

  public ngOnInit(): void {
    const keys: string[] = this.data.organization?.orgHats?.hatsKeys || [];
    const newTableData: OrgHat[] = [];
    if (keys) keys.forEach(key => newTableData.push(this.data.organization.orgHats[key]));

    this.dataSource.data = newTableData;
  }

  public saveHat() {
    console.log('Save hat: ', this.form.value);
    const updateBody = this.form.value;
    const hatKey = updateBody.key || this.db.createId();
    // this.db
    //   .collection('organizations')
    //   .doc(this.data.organization.key)
    //   .collection('hats')
    //   .doc(hatKey)
    //   .set(updateBody, {merge: true})
    //   .then(() => {
    //     this.snackbar.open(`Hat saved`, 'Close', { duration: 2000 });
    //     this.form.reset();
    //   })
    //   .catch((error) => {
    //     console.error('Error saving hat: ', error);
    //     this.snackbar.open('Error saving hat', 'Close', { duration: 2000 });
    //   });
  }

  public get columnsWithoutActions(): DynamicTableColumn[] {
    return this.columns.filter(c => c.columnDef !== 'actions');
  }

  public editHat(row: OrgHat): any {
    console.log('Edit hat: ', row);

    // Set the form values.
    this.form.reset();
    this.form.patchValue(row);
    this.tabIndex = 0;
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value || '';
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}

interface InputData {
  /**
   * The organization the hat is being created for.
   */
  organization: Organization;

  /**
   * Possible node that has the position this hat is being created for.
   */
  node?: ECONode<any>;
}