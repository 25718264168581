export enum DayOfWeek {
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
}

export enum Nth {
    '0th',
    '1st',
    '2nd',
    '3rd',
    '4th',
    '5th'
}

export enum MonthlyIntervalHint {
    'SPECIFIC' = 'You will select an exact day of the month for completion, publication and for alerts. For example: Complete by 25th, publish on the 3rd, alert on the 22nd.',
    'FIRSTNTH' = 'You will select from the occurrence of day 1-15 of the month. For example: Complete by the 14th, publish 7 days before completion date, alert 2 days before completion date.',
    'LASTNTH' = 'You will select from the occurrence of day 1-15 of the month in reverse. For example: Complete by the 5th (5 days before the end of the month), publish 7 days before completion date, alert 2 days before completion date.',
    'FIRSTNTHOFWEEKDAY' = 'You will select either the 1st, 2nd, 3rd or 4th occurrence of a day of the week for the month. For example: Complete by the 3rd Wednesday from the start of the month, publish 10 days before completion date, alert 1 day and again 3 days before completion date.',
    'LASTNTHOFWEEKDAY' = 'You will select either the 1st, 2nd, 3rd or 4th occurrence of a day of the week for the month in reverse. For example: Complete by the 1st Friday from the end of the month, publish 14 days before completion date, alert 3 days before completion date.'
}

export enum Themes {
    'default-theme' = 'default-theme',
    'light-theme' = 'light-theme',
    'dark-theme' = 'dark-theme'
}

export enum ThemeIcons {
    'default-theme' = 'flare',
    'light-theme' = 'wb_sunny',
    'dark-theme' = 'dark_mode'
}

export enum DraftUpdate {
    'AUTO_SAVE' = 'AUTO_SAVE',
    'PEND' = 'PEND',
    'SEND_PEND' = 'SEND_PEND',
    'SEND_FILE' = 'SEND_FILE',
}

export enum ParticleStatus {
    'SYNCING' = 'SYNCING',
    'SYNCED' = 'SYNCED',

    'IMPORTING' = 'IMPORTING',
    'IMPORTED' = 'IMPORTED',

    'RECEIVING' = 'RECEIVING',
    'RECEIVED' = 'RECEIVED',

    'SCHEDULED_FOR_RETRY' = 'SCHEDULED_FOR_RETRY',
    'SCHEDULED_TO_SEND' = 'SCHEDULED_TO_SEND',
    'SENDING' = 'SENDING',
    'SENT' = 'SENT',
    'FAILED_TO_SEND' = 'FAILED_TO_SEND',

    'DRAFT' = 'DRAFT',
    'ERROR' = 'ERROR',
    'TEMP' = 'TEMP',
}

export enum ApiHealthIndicator {
    'healthy' = 'healthy',
    'unstable' = 'unstable',
    'offline' = 'offline',
}

export enum BasicState {
    queued = 'queued',
    error = 'error',
    draft = 'draft',
    processing = 'processing',
}

export enum AttachmentProcess {
    none,
    started,
    startedAndEnded,
    deleted,
    ended
}

export enum BasketTypes {
    DONE,
    PENDING,
    ALL
}

export enum DeleteProcess {
    none,
    started,
    startedAndEnded
}
