import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AuthService } from '../../shared/services/auth.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'newgenus-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  usernameValue: string | null = null;
  passwordValue: string | null = null;
  public isLoginRunning = false;
  public errorLoggingIn = false;


  constructor(public auth: AuthService,
    private analytics: AngularFireAnalytics,
    private router: Router) {

  }

  public login() {
    if (this.usernameValue && this.passwordValue) {
      this.isLoginRunning = true;
      this.errorLoggingIn = false;

      this.analytics.logEvent('login_regular_attempt', {
        'event_category': 'Authentication',
        'event_label': 'Login Screen',
      });
      this.auth.signInRegular(this.usernameValue, this.passwordValue)
        .then(() => {
          this.analytics.logEvent('login_regular_success', {
            'event_category': 'Authentication',
            'event_label': 'Login Screen',
          });
          // this.onSignInSuccess();
          this.isLoginRunning = false;
          this.router.navigate(['app/home']);
        }).catch(err => {
          this.isLoginRunning = false;
          this.errorLoggingIn = true;
          alert('Sign in failed! Please check your username and password');

          this.analytics.logEvent('login_regular_failure', {
            'event_category': 'Authentication',
            'event_label': 'Login Screen',
          });
        });
    } else {
      alert('Please enter username and password')
    }
  }

  public loginViaGoogle(): void {
    this.isLoginRunning = true;
    this.errorLoggingIn = false;

    this.analytics.logEvent('login_google_attempt', {
      'event_category': 'Authentication',
      'event_label': 'Login Screen',
    });
    this.auth.googleLogin().then(() => {
      this.analytics.logEvent('login_google_success', {
        'event_category': 'Authentication',
        'event_label': 'Login Screen',
      });
      this.onSignInSuccess();
      this.isLoginRunning = false;
    })
      .catch(err => {
        this.analytics.logEvent('login_google_failure', {
          'event_category': 'Authentication',
          'event_label': 'Login Screen',
        });
        this.isLoginRunning = false;
        this.errorLoggingIn = true;
        alert('Sign in failed! Please check your username and password');
      });
  }

  public loginViaMicrosoft(): void {
    this.isLoginRunning = true;
    this.errorLoggingIn = false;
    this.analytics.logEvent('login_microsoft_attempt', {
      'event_category': 'Authentication',
      'event_label': 'Login Screen',
    });
    this.auth.microsoftLogin().then(() => {
      this.analytics.logEvent('login_microsoft_success', {
        'event_category': 'Authentication',
        'event_label': 'Login Screen',
      });
      this.onSignInSuccess();
      this.isLoginRunning = false;
    })
      .catch(err => {
        this.analytics.logEvent('login_microsoft_failure', {
          'event_category': 'Authentication',
          'event_label': 'Login Screen',
        });
        this.isLoginRunning = false;
        this.errorLoggingIn = true;
        alert('Sign in failed! Please check your username and password');
      });
  }

  private onSignInSuccess() {
    // this.router.navigate(['workspace']);
    this.router.navigate(['app/home']);
  }

  public forgotPassword($event: any) {
    // console.log('forgot password called');

  }

}
