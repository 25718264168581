import { OrgDateCycle, parseEmbeddedTimestampsToFirebaseDate } from '@newgenus/common';
import { DatePickerQuestion } from './question-datepicker';
import { QuestionBase } from './question-base';
import { Injectable } from '@angular/core';

import * as everyMoment from '@newgenus/every-moment';
import * as Moment from "moment";
const moment = everyMoment.extendMoment(Moment);

@Injectable()
export class QuestionService {

  // Todo: get from a remote source of question metadata
  // Todo: make asynchronous

  getMonthName(month: string) {
    switch (month) {
      case "01":
        return "January";
      case "02":
        return "February";
      case "03":
        return "March";
      case "04":
        return "April";
      case "05":
        return "May";
      case "06":
        return "June";
      case "07":
        return "July";
      case "08":
        return "August";
      case "09":
        return "September";
      case "10":
        return "October";
      case "11":
        return "November";
      case "12":
        return "December";
      default:
        return "Month";
    }
  }

  public getDateCyclesQuestions(yearMonthCyclesParameters: any): QuestionBase<any>[] {

    const questions: QuestionBase<any>[] = [];
    const nextBusinessDay = yearMonthCyclesParameters.businessDaySelection === "next business day" ? true : false;
    const prevBusinessDay = yearMonthCyclesParameters.businessDaySelection === "previous business day" ? true : false;

    const months = moment.getYearlyMonthCycles(yearMonthCyclesParameters.monthStart,
      yearMonthCyclesParameters.monthEnd,
      yearMonthCyclesParameters.yearStart,
      yearMonthCyclesParameters.yearEnd,
      nextBusinessDay,
      prevBusinessDay);
    let order = 1;
    for (const month of months) {
      for (const key in month) {
        const monthStart = nextBusinessDay ? month[key].monthStart.monthStartNextBusinessDate :
          (prevBusinessDay ? month[key].monthStart.monthStartPrevBusinessDate : month[key].monthStart.monthStartDate);
        const monthEnd = nextBusinessDay ? month[key].monthEnd.monthEndNextBusinessDate :
          (prevBusinessDay ? month[key].monthEnd.monthEndPrevBusinessDate : month[key].monthEnd.monthEndDate);

        questions.push(new DatePickerQuestion({
          key: key,
          label: this.getMonthName(key.substring(0, 2)),
          value: {
            start: monthStart.toDate(),
            end: monthEnd.toDate(),
          },
          options: [
            { key: 'start', value: monthStart.toDate() },
            { key: 'end', value: monthEnd.toDate() },
            { key: key, value: month[key] },

          ],
          order: order
        }));

        order++;

      }
    }

    return questions.sort((a, b) => a.order - b.order);
  }

  public createDateCyclesQuestions(match: OrgDateCycle) {

    const nextBusinessDay = match.businessDaySelection === "next business day" ? true : false;
    const prevBusinessDay = match.businessDaySelection === "previous business day" ? true : false;
    const questions: QuestionBase<any>[] = [];
    const months = match.yearCycle.map((element: { key: any; value: any; }) => {
      return { [element.key]: parseEmbeddedTimestampsToFirebaseDate(element.value) };

    });

    let order = 1;
    for (const month of months) {
      for (const key in month) {

        const monthStart = month[key].monthStart.custom !== undefined &&
          month[key].monthStart.custom !== null &&
          month[key].monthStart.custom
          ? month[key].monthStart.monthStartDate
          : nextBusinessDay
            ? month[key].monthStart.monthStartNextBusinessDate
            : prevBusinessDay ?
              month[key].monthStart.monthStartPrevBusinessDate :
              month[key].monthStart.monthStartDate;

        const monthEnd = month[key].monthEnd.custom !== undefined &&
          month[key].monthEnd.custom !== null &&
          month[key].monthEnd.custom ?
          month[key].monthEnd.monthEndDate :
          nextBusinessDay ?
            month[key].monthEnd.monthEndNextBusinessDate
            : prevBusinessDay ?
              month[key].monthEnd.monthEndPrevBusinessDate :
              month[key].monthEnd.monthEndDate;

        questions.push(new DatePickerQuestion({
          key: key,
          label: this.getMonthName(key.substring(0, 2)),
          value: {
            start: monthStart.toDate(),
            end: monthEnd.toDate(),
          },
          options: [
            { key: 'start', value: monthStart.toDate() },
            { key: 'end', value: monthEnd.toDate() },
            { key: key, value: month[key] },

          ],
          order: order
        }));

        order++;

      }
    }

    return questions.sort((a, b) => a.order - b.order);
  }
}