import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, inject, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JsonPipe, NgFor, NgIf } from '@angular/common';
import { MaterialModule } from '../../material.module';
import { Organization } from '@newgenus/common';

@Component({
    selector: 'shared-org-assign-user-dialog',
    standalone: true,
    imports: [MaterialModule, ReactiveFormsModule, NgFor, NgIf, JsonPipe],
    template: `
  <h2 mat-dialog-title class="mb-0">Add users to {{orgName}}

    <!-- Close button. -->
    <button mat-icon-button mat-dialog-close class="close-button">
      <mat-icon>close</mat-icon>
    </button>
  </h2>

  <mat-dialog-content class="h-100-70 w-100">

    <div class="view-container m-3 w-100">
     <form [formGroup]="form">
        <div class="view-header">
            <span class="viewer-title-spacer">Invite by email</span>

            <button mat-stroked-button color="primary" type="submit"
                [disabled]="form.invalid"
                (click)="addUserToList()">
                <mat-icon>add</mat-icon> Add
            </button>
        </div>
        <div class="view-content">

          <!--
            Each time the user presses enter or clicks (add) button, it will add 
            That email address to a list that will be sent via API.
          -->
          <mat-form-field appearance="outline" class="w-100">
              <mat-label>Email</mat-label>
              <input matInput formControlName="email" type="email" placeholder="Enter email address">
              <mat-icon matSuffix>email</mat-icon>
              <mat-error *ngIf="form.get('email')?.hasError('required')">Email is required</mat-error>
              <mat-error *ngIf="form.get('email')?.hasError('email')">Invalid email</mat-error>
          </mat-form-field>

          <div class="email-list">
            <div *ngFor="let email of usersToInvite" class="user-list-container">
              
              <span class="grouped">
                <span>{{email}}</span>
              </span>

              <button mat-icon-button color="warn" (click)="removeUserFromList(email)" >
                <mat-icon>remove_circle</mat-icon>
              </button>

            </div>
          </div>

        </div>
      </form>
    </div>
  
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close color="warn">Close</button>
    <button mat-stroked-button color="primary" [disabled]="!usersToInvite" (click)="sendInvitations()"><mat-icon>forward_to_inbox</mat-icon> Invite</button>
  </mat-dialog-actions>
`,
    styleUrls: [
        './org-dialog.scss',
        '../organization-board.component.scss'
    ],
})
export class OrgInviteUserDialogComponent implements OnInit {

    public snackbar = inject(MatSnackBar);
    public form = new FormGroup({
        email: new FormControl('', [Validators.email])
    });

    public usersToInvite: string[] = [];

    constructor(
        public dialogRef: MatDialogRef<OrgInviteUserDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { organization: Organization }
    ) { }


    public ngOnInit(): void {
        console.log('OrgAssignUserDialogComponent > ngOnInit > this.data: ', this.data);

        // Set searchable data to the org users.
        const userKeys = this.data.organization.orgUsers.userKeys;
        console.log('OrgAssignUserDialogComponent > ngOnInit > userKeys: ', userKeys);
    }

    public get orgName(): string {
        return this.data.organization?.name || '';
    }

    public sendInvitations() {
        console.log('OrgInviteUserDialogComponent > sendInvitations > this.usersToInvite: ', this.usersToInvite);
        const message = this.usersToInvite.length > 2
            ? `Invitations sent to ${this.usersToInvite[0]} and ${this.usersToInvite.length -1} ${this.usersToInvite.length -1 > 1 ? 'others' : 'other'}.`
            : `Invitations sent to ${this.usersToInvite.join(', ')}.`;

        this.snackbar.open(message, 'Close', { duration: 5000 });
        this.dialogRef.close(this.usersToInvite);
    }

    /**
     * Adds distinct email to the list of users to invite.
     * Cannot invite a user already on the organization.
     */
    public addUserToList() {
        const email = this.form.controls['email'].value;

        // If email is empty, return.
        if (!email) return;

        // If email is already in the list, return.
        if (this.usersToInvite.includes(email)) return;

        console.log('TODO: add email onto org users and validate they are not on the org already.');
        // If email is already on the organization, return.
        // if (this.isEmailAlreadyOnOrg(email)) {
        //     this.snackbar.open(`Cannot invite ${email} because they are already on the organization.`, 'Close', { duration: 5000 });
        //     return;
        // }

        this.usersToInvite.push(email);

        // Clear the form.
        this.form.controls['email'].setValue('');
    }

    // private isEmailAlreadyOnOrg(email: string) {
    //     const userKeys = this.data.organization.orgUsers.userKeys;
    //     return userKeys.some(key => this.data.organization.orgUsers[key].email === email);
    // }

    public removeUserFromList(email: string) {
        this.usersToInvite = this.usersToInvite.filter(user => user !== email);
    }


}
