import { ClientOption, Organization, SendFrom, User, deepCompare, deepCopy, parseEmbeddedTimestampsToFirebaseDate } from "@newgenus/common";
import { Observable, Subject, distinctUntilChanged, map, startWith, switchMap, takeUntil } from "rxjs";
import { userUpdate, userModified } from "../redux/user/user.actions";
import { selectOrg } from "../redux/organizations/org.selectors";
import { selectUsers } from "../redux/user/user.selectors";
import { Store, select } from "@ngrx/store";


export abstract class StoreUser {
  public uidChange$: Observable<User> = new Observable<User>();
  public user$: Observable<Array<User>> = new Observable<Array<User>>();
  public user!: User;
  public isDevMode = false;
  public userId!: string;
  public isUserInstantiated = false;
  public shouldShowParticles = false;
  public isGmailLinked = false;
  public isOutlookLinked = false;
  public clientOptions: Array<ClientOption> = [];
  public sendFrom: SendFrom = { emailKey: '', client: '' };

  // protected store: Store = Inject(Store);
  constructor(protected store: Store) { }

  public initStoreUser(destroy$?: Subject<void>): void {
    if (destroy$) { // Only components can supply a destroy subject.
      this.user$ = this.store.pipe(select(selectUsers))
        .pipe(
          distinctUntilChanged((p, c) => deepCompare(p, c)), // Only emit if the user has changed.
          takeUntil(destroy$)
        );

      // TODO check that logout causes this to emit null.
      this.uidChange$ = this.user$.pipe(map(u => u[0] || null), distinctUntilChanged(compareUIDs()), takeUntil(destroy$));
    } else {
      this.user$ = this.store.pipe(select(selectUsers));
      // TODO check that logout causes this to emit null.
      this.uidChange$ = this.user$.pipe(map(u => u[0] || null), distinctUntilChanged(compareUIDs()));
    }

    // TODO: consider moving this to onInit...
    this.subscribeToStoreUserUpdates();
  }

  /**
   * Subscribe to the Store's User collection.
   * This will update with any changes made to the document in the store.
   */
  private subscribeToStoreUserUpdates(): void {
    this.user$.subscribe((users) => {
      if (users && users[0]) {
        this.user = parseEmbeddedTimestampsToFirebaseDate(deepCopy(users[0]));

        this.isDevMode = users[0].isDeveloper || false;
        this.userId = users[0].uid;
        this.updateClientFlags();
        this.setSendFrom();

        this.shouldShowParticles = this.isGmailLinked || this.isOutlookLinked;

        // This is bz the user is emitted from auth service as an authenticated user and then later emitted as a fully fletched user document from the store.
        if (this.user.preferences) {
          this.isUserInstantiated = true;
        }
      }
    });
  }

  public get getOrg$(): Observable<Organization | null> {
    // Monitor user if they change their selected organization.
    return this.user$.pipe(
      map(u => u[0] || null),
      startWith(null),
      distinctUntilChanged((prev, curr) => {
        // Check if selected organization has changed.
        return prev?.organizations?.selected === curr?.organizations?.selected;
      }),
      switchMap((userWithDifferentOrg) => {
        const orgKey = userWithDifferentOrg?.organizations?.selected;
        if (orgKey) {
          return this.store.pipe(select(selectOrg(orgKey)));
        } else {
          return new Observable<Organization | null>();
        }
      }),
      map(org => {
        return deepCopy(org);
      }),
    );
  }

  /**
   * Update the ClientOptions property and set isLinked{Client} flags.
   */
  private updateClientFlags() {
    let isGmailLinked = false, isOutlookLinked = false;
    const carbonUser: User = this.user, availableClients: Array<ClientOption> = [];

    if (carbonUser.linkedEmails) {
      for (const emailKey in carbonUser.linkedEmails)
        if (Object.prototype.hasOwnProperty.call(this.user.linkedEmails, emailKey)) {
          if (carbonUser.linkedEmails[emailKey]["gmail"] && carbonUser.linkedEmails[emailKey]["gmail"].linked) { isGmailLinked = true; availableClients.push({ display: `Gmail <${emailKey}>`, client: 'gmail', emailKey: emailKey }); }
          if (carbonUser.linkedEmails[emailKey]["outlook"] && carbonUser.linkedEmails[emailKey]["outlook"].linked) { isOutlookLinked = true; availableClients.push({ display: `Outlook <${emailKey}>`, client: 'outlook', emailKey: emailKey }); }
        }
    }

    this.isGmailLinked = isGmailLinked;
    this.isOutlookLinked = isOutlookLinked;

    this.clientOptions = [];
    availableClients.forEach(x => this.clientOptions.push({ display: x.display, value: x.client + '_' + x.emailKey, emailKey: x.emailKey, client: x.client }));
  }

  /**
   * Set the SendFrom to the user's sendFrom, or to the first ClientOption one the array.
   */
  private setSendFrom() {
    if (this.user?.sendFrom?.emailKey) {
      this.sendFrom = this.user.sendFrom;
    } else if (this.clientOptions.length > 0) {
      this.sendFrom = { emailKey: this.clientOptions[0].emailKey, client: this.clientOptions[0].client };
    }
  }

  /**
   * Update the user Doc in the store, this in effect will update the database.
   *
   * @param {Partial<User>} [updateData] Optional partial user doc, default the "this.user" object.
   */
  public updateUserStoreDoc(updateData?: Partial<User>): void {
    this.store.dispatch(userUpdate({
      payload: updateData || this.user
    }));
  }

  /**
   * Update the user Doc in the store only, DOES NOT update the backend.
   * Use only in cases where you want to update the store but not the backend (I.E a trigger function in the background will update the BE and not NGRX).
   *
   * @param {Partial<User>} [updateData] Optional partial user doc, default the "this.user" object.
   */
  public updateOnlyNgrxUser(updateData?: Partial<User>): void {
    this.store.dispatch(userModified({
      payload: updateData || this.user
    }));
  }

}

function compareUIDs(): (x: User, y: User) => boolean {
  return (previous, current) => (previous?.uid + '') == (current?.uid + '');
}


// /**
//  * DEPRECATE this as soon as possible. There should rather be a user service of sorts that has no dependency on modules and can be used in any app.
//  * As well as a user store that can be used in any app.
//  * As well as shared state instead of having new subscriptions to the store in every component.
//  */
// export abstract class StoreUser {
//   public uidChange$: Observable<User> = new Observable<User>();
//   // public user$: Observable<Array<User>> = new Observable<Array<User>>();
//   public user$: BehaviorSubject<Array<User>> = new BehaviorSubject<Array<User>>([]);
//   private user_obs$: Observable<Array<User>> = new Observable<Array<User>>();
//   public user!: User;
//   public isDevMode = false;
//   public userId!: string;
//   public isUserInstantiated = false;
//   public shouldShowParticles = false;
//   public isGmailLinked = false;
//   public isOutlookLinked = false;
//   public clientOptions: Array<ClientOption> = [];
//   public sendFrom: SendFrom = { emailKey: '', client: '' };

//   // protected store: Store = Inject(Store);

//   constructor(protected store: Store) { }

//   public initStoreUser(destroy$?: Subject<void>): void {
//     if (destroy$) { // Only components can supply a destroy subject.
//       this.user_obs$ = this.store.pipe(select(selectUsers))
//         .pipe(
//           distinctUntilChanged((p, c) => deepCompare(p, c)), // Only emit if the user has changed.
//           tap((users) => {
//             this.user$.next(users);
//           }), // Update the user$ behavior subject.
//           takeUntil(destroy$)
//         );

//       // TODO check that logout causes this to emit null.
//       this.uidChange$ = this.user$.pipe(map(u => u[0] || null), distinctUntilChanged(compareUIDs()), takeUntil(destroy$));

//       this.user_obs$.pipe(takeUntil(destroy$)).subscribe(); // TODO improve on this...
//     } else {
//       this.user_obs$ = this.store.pipe(select(selectUsers)).pipe(
//         distinctUntilChanged((p, c) => deepCompare(p, c)), // Only emit if the user has changed.
//         tap((users) => {
//           this.user$.next(users);
//         }), // Update the user$ behavior subject.
//       );

//       // TODO check that logout causes this to emit null.
//       this.uidChange$ = this.user$.pipe(map(u => u[0] || null), distinctUntilChanged(compareUIDs()));

//       this.user_obs$.subscribe(); // TODO improve on this...
//     }


// }
