<h2 mat-dialog-title>Details of {{data.node.data?.name}}

    <!-- Close button. -->
    <button mat-icon-button mat-dialog-close class="close-button">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="h-100-70 w-100 d-flex">


    <div class="org-user-details">

        <div class="profile-details">

            <img [src]="'https://ui-avatars.com/api/?name=' + data.node.data?.name + '&background=random' + '&color=random'">
            
            <div class="name">{{orgUserDetails.name}}</div>
            <div class="role">{{data.node.data?.name}}</div>
            <!-- <div class="email">{{orgUserDetails.email}}</div> -->
            <!-- <div class="phone">{{orgUserDetails.phone}}</div> -->
            <!-- <div class="address">{{orgUserDetails.address}}</div> -->


        </div>

        <div class="profile-actions">

            <button mat-stroked-button color="accent"><mat-icon>chat</mat-icon> Chat</button>
            <button mat-stroked-button color="accent"><mat-icon>mail</mat-icon> Contact</button>

        </div>  

    </div>



</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>