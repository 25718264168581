import { FormControl, FormGroup, Validators } from '@angular/forms';
import { QuestionBase } from './question-base';
import { Injectable } from '@angular/core';

@Injectable()
export class QuestionControlService {

  toFormGroup(questions: QuestionBase<any>[]) {
    const group: any = {};
    questions.forEach(question => {
      if(question.controlType !== 'datepicker')
      {
        group[question.key] = question.required ? new FormControl(question.value || '', Validators.required) : new FormControl(question.value || '');
      } else {
        group[question.key] = new FormGroup({
          start: new FormControl(question.value.start),
          end: new FormControl(question.value.end),
        });
  
        if (question.required) {
          group[question.key].controls['start'].setValidators(Validators.required);
          group[question.key].controls['end'].setValidators(Validators.required);
        }
      }
    });
    return new FormGroup(group);
  }

}