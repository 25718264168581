import { Organization } from '@newgenus/common';
import { createAction, props } from '@ngrx/store';

export const loadOrgs = createAction('[Org Resolver] Load Org Document');

export const allOrgsLoaded = createAction('[Load Org Effect] All Orgs Loaded', props<{ org: Organization[] }>());

export const orgDataProcessed = createAction('[Org Effects] New Org Data Processed');

export const orgAdded = createAction('[Org] added', props<{ payload: Organization }>());

export const orgRemoved = createAction('[Org] removed', props<{ payload: Partial<Organization> }>());

export const orgModified = createAction('[Org] modified', props<{ payload: Partial<Organization> }>());

export const orgUpdate = createAction('[Org updating] updating Org', props<{ payload: Partial<Organization> }>());

export const orgUserModified = createAction('[Org] user modified', props<{ payload: Organization, keyOfUserUpdated: string }>());

export const orgTargetModified = createAction('[Org] target modified', props<{ payload: Partial<Organization> }>());

export const orgUserTargetModified = createAction('[Org] user target modified', props<{ payload: Partial<Organization> }>());

export const orgSettingsModified = createAction('[Org] settings modified', props<{ payload: Partial<Organization> }>());
