import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { DisplayActionIcon, DisplayValue, ECONode, ECOTree, EcoOrientation, OrgUser, Organization } from '@newgenus/common';
import { SidebarPositionDetailComponent } from './sidebar-components/sidebar-position-detail.component';
import { CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { SidebarEmptyUserComponent } from './sidebar-components/sidebar-empty-user.component';
import { ManyUsersComponent } from './node-components/many-users/many-users.component';
import { SidebarUserComponent } from './sidebar-components/sidebar-user.component';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { OneUserComponent } from './node-components/one-user/one-user.component';
import { Position, TreeBuilderService } from './services/tree-builder.service';
import { DragScrollComponent, DragScrollItemDirective } from 'ngx-drag-scroll';
import { NoUserComponent } from './node-components/no-user/no-user.component';
import { Observable, Subject, debounceTime, interval, takeUntil } from 'rxjs';
import { RightSideBarService } from '../../services/right-sidebar.service';
import { animate, style, transition, trigger } from "@angular/animations";
import { OrgDialogServiceService } from './services/org-dialog.service';
import { OrgHattingService } from './services/org-hatting.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
// import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatMenuTrigger } from '@angular/material/menu';
import { MaterialModule } from '../material.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OrgBoardService } from './services/org-board-service';


@Component({
  selector: 'shared-organization-board',
  standalone: true,
  imports: [
    CommonModule, MaterialModule, FormsModule, OneUserComponent, DragScrollComponent, DragScrollItemDirective,
    ManyUsersComponent, NoUserComponent, MatSliderModule, SidebarEmptyUserComponent, SidebarUserComponent, SidebarPositionDetailComponent
  ],
  templateUrl: './organization-board.component.html',
  styleUrls: ['./organization-board.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(
        ':enter', [
        style({ opacity: 0 }),
        animate('0.2s ease-out',
          style({ opacity: 1 }))
      ]),
      transition(
        ':leave', [
        style({ opacity: 1 }),
        animate('0.2s ease-in',
          style({ opacity: 0 }))
      ])
    ]
    ),
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', position: 'absolute', right: '0px', top: '65px' }), // Top must align with the height of the toolbar in app.component.html.
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ]),
    trigger('smoothHeight', [
      transition(':leave', [
        style({ height: '*' }),
        animate('0.4s ease-out', style({ height: 0 }))
      ]),
      transition(':enter', [
        style({ height: '0' }),
        animate('0.4s ease-out', style({ height: '*' }))
      ]),
    ]),
    trigger('delayedOpacity', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.4s ease-out', style({ opacity: 0 }))
      ]),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.4s 0.4s ease-out', style({ opacity: 1 }))
      ]),
    ]),
    trigger('shrinkWidthLeave', [
      transition(':leave', [
        style({ width: '*' }),
        animate('0.4s ease-out', style({ width: 0 }))
      ]),
      transition(':enter', [
        style({ width: '0' }),
        animate('0.4s ease-out', style({ width: '*' }))
      ]),
    ]),
  ]
})
export class OrganizationBoardComponent implements OnInit, OnChanges, OnDestroy {
  //#region Variables
  // Variables for this component that are within scope of "this" component.

  @Input()
  public organization: Organization | null = null;

  @Input() // TODO: Create a feature and PERMISSIONS for this.
  public mayEdit = false;

  @Input() // TODO: UID of whose editing the board.
  public editingBy: string | null = null;

  @Input()
  public isDev = false;

  @Input() // TODO: this should come from the Org doc, and be linked to a UID. Only the matching UID should be allowed to edit?
  public isEditing = false;
  @Output()
  public isEditingChange = new EventEmitter<boolean>();

  @Output()
  public templateEmitted = new EventEmitter<{ templateRef: TemplateRef<any>, data?: any }>();

  /**
   * Emits a boolean value when an item is being dragged.
   * Used for hiding the sidebar menu in newgenus-frontend to aid in drag and drop navigation.
   * 
   * @see isDroppableItemBeingDragged
   */
  @Output()
  public dragItem = new EventEmitter<boolean>();

  private nodeSelected: ECONode<Position> | null = null;

  /**
   * Used to determined if a new org is selected. This will then trigger a scrollTo operation to the first node.
   */
  private previousOrgKey: string | undefined;

  public isNavigatingWithDrag = false;
  public isDroppableItemBeingDragged = false;

  public isDragNavDisabled = false;
  public isDragging = false;
  public hideScrollbar = false;
  public orgOptions: DisplayValue[] = [];
  public showLoader = true;
  public tree = new ECOTree();

  public isLocked = false; // TODO should be a field on the orgDoc.
  public lockedBy: string | null = null; // TODO should be a field on the orgDoc.

  public childrenLinkPaths: any[] = [];
  private dragScrollContainer: any;
  private interval: Observable<number> | undefined;
  private terminateInterval = new Subject<void>();
  public template!: TemplateRef<any>;

  public userOptions: DisplayValue[] = [];
  public hatOptions: DisplayValue[] = [];
  public policyOptions: DisplayValue[] = [];
  public securityGroupOptions: DisplayValue[] = [];

  // Random ID for the board.
  private orbBoardId = 'OB-' + Math.random().toString(36).substring(7);

  /**
   * The organization to display and interact with.
   * Changes made to the "organization" input property may update this _organization, unless there are
   * changes made that are conflicting with the currently edited _organization.
   * 
   * The _organization will be emitted into NGRX for local updates and changes, these will then
   * be pushed to the DB.
   * These kind of changes should always be equal to the "organization" input property.
   *
   * @protected
   * @type {Organization}
   * @memberof OrganizationBoardComponent
   */
  protected _organization!: Organization;

  public toolbarSelection: ToolbarOptionsEnum = ToolbarOptionsEnum.none;

  public lineOptions: DisplayValue[] = [
    { display: "Line", value: "L" },
    { display: "Bezier", value: "B" },
    { display: "Cross", value: "M" }
  ];
  public orientationOptions: DisplayValue[] = [
    { display: 'Top', value: EcoOrientation.RO_TOP },
    { display: 'Bottom', value: EcoOrientation.RO_BOTTOM },
    { display: 'Left', value: EcoOrientation.RO_LEFT },
    { display: 'Right', value: EcoOrientation.RO_RIGHT }
  ];

  /**
   * Tracks which node ID has what drop list.
   *
   * @type {Record<string, CdkDropList>} - The key is the node ID and the value is the CdkDropList.
   */
  public dropListTracker: Record<string, CdkDropList> = {};
  public dropLists: CdkDropList[] = [];
  public userDropDataMap: Record<string, any> = {};
  public zoomLevel = 1;

  private destroy$ = new Subject<void>();
  public orgBoardWidth = 'calc(100vw - 1.5rem)';

  public menuTopLeftPosition = { x: '0', y: '0' }
  public contextMenuItems: DisplayActionIcon[] = []
  @ViewChild(MatMenuTrigger, { static: true }) contextMenu!: MatMenuTrigger;
  @ViewChild('sideBarOneUserTemplate') sideBarOneUserTemplate!: any;
  @ViewChild('sideBarManyUserTemplate') sideBarManyUserTemplate!: any;
  @ViewChild('sideBarEmptyUserTemplate') sideBarEmptyUserTemplate!: any;
  @ViewChild('obSideToolbar') obSideToolbar!: MatSelectionList;

  //#endregion

  constructor(
    public sidebarService: RightSideBarService,
    private orgHattingService: OrgHattingService,
    private treeBuilder: TreeBuilderService,
    public snackbar: MatSnackBar,
    private dialogService: OrgDialogServiceService,
    private obService: OrgBoardService
  ) { }

  //#region Instantiation
  // Setup\fetches\initialization.

  public ngOnInit(): void {
    this.obService.updateNodeLinking
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.updateNodeLinkingLines(this.tree));
  }

  public export() {
    // // download the file using old school javascript method
    // this.exportAsService.save(this.exportAsConfig, 'My File Name').subscribe(() => {
    //   // save started
    // });
    // // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
    // this.exportAsService.get(this.exportAsConfig).subscribe(content => {
    //   console.log(content);
    // });
  }

  public onSelectSideToolbar(event: MatSelectionListChange) {
    console.log('onSelectSideToolbar > event.options[0].value:', event.options[0].value);
    this.toolbarSelection = (event.options[0].value as ToolbarOptionsEnum) || ToolbarOptionsEnum.none;

    switch (this.toolbarSelection) {
      case ToolbarOptionsEnum.hats:
        console.log('onSelectSideToolbar > hats');
        break;
      case ToolbarOptionsEnum.policies:
        console.log('onSelectSideToolbar > policies');
        break;
      case ToolbarOptionsEnum.users:
        console.log('onSelectSideToolbar > users');
        break;
      case ToolbarOptionsEnum.security_groups:
        console.log('onSelectSideToolbar > security-groups');
        break;
      case ToolbarOptionsEnum.new_user:
        this.dialogService.openInviteUserDialog();
        this.obSideToolbar.deselectAll();
        break;
      case ToolbarOptionsEnum.new_policy:
        this.dialogService.openPolicyDialog();
        this.obSideToolbar.deselectAll();
        break;
      case ToolbarOptionsEnum.new_hat:
        console.log('onSelectSideToolbar > new_hat');
        this.dialogService.openNewHatDialog();
        this.obSideToolbar.deselectAll();
        break;

      default:
        this.snackbar.open('Unrecognized instruction.', 'Close', { duration: 3000 });
        break;
    }
  }

  //#endregion
  //#region User Interactions
  // Callbacks from html, anything the user can "interact" with from the view.

  public onRightClick(event: MouseEvent) {
    // preventDefault avoids to show the visualization of the right-click menu of the browser 
    event.preventDefault();

    // we record the mouse position in our object 
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';

    this.contextMenuItems = [
      {
        display: 'Zoom In', icon: 'zoom_in', action: this.zoomIn.bind(this)
      },
      {
        display: 'Zoom Out', icon: 'zoom_out', action: this.zoomOut.bind(this)
      },
      {
        display: 'Reset Zoom', icon: 'zoom_out_map', action: this.resetZoom.bind(this)
      },
      (
        this.isEditing ? {
          display: 'Done Editing', icon: 'done', action: this.onEditClick.bind(this, !this.isEditing), disabled: !this.mayEdit
        }
          : {
            display: 'Edit', icon: 'edit', action: this.onEditClick.bind(this, !this.isEditing), disabled: !this.mayEdit
          }
      ),
      {
        display: 'Select All', icon: 'select_all', action: this.selectSiblingNodes.bind(this, this.tree.nDatabaseNodes[0])
      },
      {
        display: 'Scroll Up', icon: 'arrow_upward', action: () => {
          this.scrollVertical(50);

          setTimeout(() => {
            this.stopInterval();
          }, 500);
        }
      },
      {
        display: 'Scroll Down', icon: 'arrow_downward', action: () => {
          this.scrollVertical(-50);

          setTimeout(() => {
            this.stopInterval();
          }, 500);
        }
      },
      {
        display: 'Jump Left', icon: 'arrow_back', action: () => {
          this.scrollHorizontal(100);

          setTimeout(() => {
            this.stopInterval();
          }, 500);
        }
      },
      {
        display: 'Jump Right', icon: 'arrow_forward', action: () => {
          this.scrollHorizontal(-100);

          setTimeout(() => {
            this.stopInterval();
          }, 500);
        }
      },
    ]

    // we open the menu 
    this.contextMenu.openMenu();
  }

  public onRightClickNode(event: MouseEvent, node: any) {
    console.log('onRightClickNode > event:', event);
    // preventDefault avoids to show the visualization of the right-click menu of the browser 
    event.preventDefault();

    // we record the mouse position in our object 
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';


    // sidebarService.openTemplate(sideBarOneUserTemplate, $event)
    this.contextMenuItems = [
      {
        display: 'View Hat', icon: 'school', action: () => {
          this.dialogService.openHat(node);
        }
      },
      {
        display: 'Manage Users', icon: 'face', colour: 'accent', action: () => {
          this.dialogService.openAssignUserDialog(node);
        }
      },
      {
        display: 'Security Groups', icon: 'policy', colour: 'primary', action: () => {
          this.dialogService.openSecurityGroupsDialog(node);
        }
      },
    ];

    const layOfNode = this.determineLayout(node.data.userDetailsArray);
    switch (layOfNode) {
      case 'ONE_USER':
        this.contextMenuItems.push(
          // {
          //   display: 'View Hat', icon: 'school', action: () => {
          //     this.dialogService.openHat(node);
          //   }
          // },
          // {
          //   display: 'User', icon: 'add_circle', colour: 'accent', action: () => {
          //     this.dialogService.openAssignUserDialog(node);
          //   }
          // },
          {
            display: 'Open', icon: 'open_in_new', action: () => {
              this.sidebarService.openTemplate(this.sideBarOneUserTemplate, { node: node });
            }
          });
        break;

      case 'MULTIPLE_USERS':
        this.contextMenuItems.push(
          // {
          //   display: 'View Hat', icon: 'school', action: () => {
          //     this.dialogService.openHat(node);
          //   }
          // },
          // {
          //   display: 'User', icon: 'add_circle', colour: 'accent', action: () => {
          //     this.dialogService.openAssignUserDialog(node);
          //   }
          // },
          {
            display: 'Open', icon: 'open_in_new', action: () => {
              this.sidebarService.openTemplate(this.sideBarManyUserTemplate, { node: node });
            }
          });
        break;

      default: // Default and no user are the same.
        this.contextMenuItems.push(
          // {
          //   display: 'User', icon: 'add_circle', colour: 'accent', action: () => {
          //     this.dialogService.openAssignUserDialog(node);
          //   }
          // },
          {
            display: 'Open', icon: 'open_in_new', action: () => {
              this.sidebarService.openTemplate(this.sideBarEmptyUserTemplate, { node: node });
            }
          });
        break;

    }

    // we open the menu 
    this.contextMenu.openMenu();
  }

  public onSelectColor($event: Event) {
    console.log('onSelectColor > $event:', $event);
  }

  public onEditClick(isEditing: boolean) {
    console.log('onEditClick > isEditing:', isEditing);
    this.isEditing = isEditing;
    this.isEditingChange.emit(this.isEditing);

    this.updateTree();
    this.updateOrgBoardWidthCalculations(isEditing);
  }

  private updateOrgBoardWidthCalculations(isEditing: boolean) {
    if (isEditing) {
      this.orgBoardWidth = 'calc(100vw - 1.5rem - 300px)';
    } else {
      this.orgBoardWidth = 'calc(100vw - 1.5rem)';
    }
  }

  public onDrop(event: CdkDragDrop<any>, targetNode?: ECONode<Position>) {
    console.log('onDragAndDrop > event:', event);
    console.log('onDragAndDrop > targetNode:', targetNode);


    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }

    this.snackbar.open('Updating tree...', 'Close', { duration: 1500 });
    this.updateTree();
    // this.updateNodeDimensions();
    this.updateHeldFromAboveMapping();

    // if (!targetNode) {
    //   return;
    // }

    // this.showLoader = true;
    // this.orgHattingService.onHatTodo()
    //   .then(() => {
    //     this.showLoader = false;
    //     console.log('onDrop > orgHattingService.onHatTodo > success');
    //     // Update the UI to show that the user is now in the new container.
    //     if (event.previousContainer === event.container) {
    //       moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    //     } else {
    //       transferArrayItem(
    //         event.previousContainer.data,
    //         event.container.data,
    //         event.previousIndex,
    //         event.currentIndex,
    //       );
    //     }
    //     this.snackBar.open('Item moved successfully.', 'Close', { duration: 3000 });
    //   })
    //   .catch((error) => {
    //     this.showLoader = false;
    //     console.error('onDrop > orgHattingService.onHatTodo > error:', error);
    //     this.snackBar.open('Failed to move item.', 'Close', { duration: 3000 });
    //   });

    // TODO:
    // - update tree node heights after drop.
    // - Fireoff a save to the org/subcollection for the change.
  }

  public toggleNodeCollapse(node: ECONode<Position>) {
    this.tree.collapseNode(node.id, false); // False = don't redraw.
    this.updateNodeLinkingLines(this.tree);

    // setTimeout(() => {
    //   console.log('toggleNodeCollapse > node:', node)
    // });
  }

  public zoomIn() {
    const newZoom = this.zoomLevel + 0.15;
    // Maximum zoom level is 2.5
    if (newZoom > 2.5) this.zoomLevel = 2.5;
    else this.zoomLevel = newZoom;

    this.tree.padding = 10 * this.zoomLevel;
  }

  public zoomOut() {
    const newZoom = this.zoomLevel - 0.15;
    // Minimum zoom level is 0.15
    if (newZoom < 0.15) this.zoomLevel = 0.15;
    else this.zoomLevel = newZoom;

    this.tree.padding = 10 * this.zoomLevel;
  }

  public treeWidthScaled() {

    // if (this.zoomLevel < 1) {
    //   return this.tree.width * this.zoomLevel + 'px';
    // }

    // else if (this.zoomLevel > 1) {
    //   return this.tree.width * this.zoomLevel + 'px';
    // }

    return this.tree.width + 'px';
  }

  public treePaddingScaled() {
    // if (this.zoomLevel < 1) {
    //   return this.tree.padding * this.zoomLevel + 'rem';
    // }

    // else if (this.zoomLevel > 1) {
    //   return this.tree.padding * this.zoomLevel + 'rem';
    // }

    return this.tree.padding + 'rem';
  }

  public treeHeightScaled() {
    return this.tree.height + 'px'
  }

  public resetZoom() {
    this.zoomLevel = 1;
  }

  public onOpenUser(orgUser: OrgUser) {
    // Find the node with the user Key on it.
    let isManyUser = false;
    const node = this.tree.nDatabaseNodes.find((node: ECONode<Position>) => {
      const userDetailsArray = node.data?.userDetailsArray;

      if (userDetailsArray && userDetailsArray.length > 0) {
        const match = userDetailsArray.find(user => user.key === orgUser.uid);
        if (match) {
          isManyUser = userDetailsArray.length > 1;
          return true;
        }
      }

      return null;
    });

    console.log('TODO: onOpenUser > orgUser:', orgUser);
    console.log('TODO: onOpenUser > node:', node);

    if (node) {
      this.sidebarService.openTemplate(isManyUser ? this.sideBarManyUserTemplate : this.sideBarOneUserTemplate, { data: node });
    } else {
      this.snackbar.open('User not found.', 'Close', { duration: 3000 });
    }
  }

  //#endregion
  //#region Events and Domain Functions
  // $watches/$on events, functions which don't fall into any of the other regions,
  // including any function that has to do domain functionality.


  // ITEM
  public onDragItemStart() {
    this.isDroppableItemBeingDragged = true;
    this.dragItem.emit(true);
  }

  // ITEM
  public onDragItemEnd() {
    this.isDroppableItemBeingDragged = false;
    this.dragItem.emit(false);
  }

  // NAVIGATION
  public onDragNavStart(): void {
    setTimeout(() => {
      if (!this.isDragNavDisabled) this.isDragging = true;
      else this.isDragging = false;
      this.updateOverFlowBehaviour(false);
    }, 100);
  }

  // NAVIGATION
  public onDragNavEnd(): void {
    this.isDragging = false;
    this.updateOverFlowBehaviour(true);
  }

  public selectSiblingNodes(node: ECONode<Position>) {
    if (node == this.nodeSelected) {
      this.nodeSelected = null;
      this.tree.nDatabaseNodes.forEach(x => {
        x.isSelected = false;
      });
    } else {
      this.nodeSelected = node;
      const nodes = this.getSiblingNodes(node).map(x => x.id);
      this.tree.nDatabaseNodes.forEach(x => {
        x.isSelected = x.id == node.id || nodes.indexOf(x.id) >= 0;
      });
    }
    this.updateNodeLinkingLines(this.tree);
  }

  public stateChange(state: boolean) {
    this.isDragNavDisabled = state;
    this.updateOverFlowBehaviour(state);
  }

  private toggleDragNavOverFlowClass(allowOverFlow = false) {
    // console.log('toggleDragNavOverFlowClass > allowOverFlow:', allowOverFlow)
    const dragScrollItem = document.getElementsByClassName('drag-scroll-content')[0];
    // console.log('toggleDragNavOverFlowClass > dragScrollItem:', dragScrollItem)
    if (dragScrollItem) {

      if (allowOverFlow) {
        dragScrollItem.classList.replace('overflow-hidden', 'overflow-scroll');
      } else {
        dragScrollItem.classList.replace('overflow-scroll', 'overflow-hidden'); // TODO this should be permanent.
      }
    } else {
      console.error('Could not find drag-scroll-content element.');
    }
  }

  public scrollVertical(depl: number) {
    if (!this.dragScrollContainer) this.dragScrollContainer = document.getElementsByClassName('drag-scroll-content')[0];

    if (!this.interval) this.interval = interval(5);

    this.interval
      .pipe(takeUntil(this.terminateInterval))
      .subscribe(() => {
        if (this.dragScrollContainer) {
          this.dragScrollContainer.scrollTop -= depl;
        }
      });
  }

  public scrollHorizontal(depl: number) {
    if (!this.dragScrollContainer) this.dragScrollContainer = document.getElementsByClassName('drag-scroll-content')[0];

    if (!this.interval) this.interval = interval(5);

    this.interval
      .pipe(takeUntil(this.terminateInterval))
      .subscribe(() => {
        if (this.dragScrollContainer) {
          this.dragScrollContainer.scrollLeft -= depl;
        }
      });
  }

  public stopInterval() {
    this.terminateInterval.next();
  }

  private scrollToFirstNode(): void {
    // Settimeout to allow the treeView to render.
    setTimeout(() => {
      const node = this.tree.nDatabaseNodes[0];
      if (node) {
        const dragScrollItem = document.getElementsByClassName('drag-scroll-content')[0];

        // Set dragScrollItem overflow to hidden.

        if (dragScrollItem) {
          // Set overflow to hidden. This is to help prevent a bug where if the cursor is dragging outside the container, the drag-scroll goes in the opposite direction.
          dragScrollItem.classList.add('overflow-hidden');

          // Collect the client width. This is used to determine where the center of the screen is for the current user's device.
          const clientWidth = document.documentElement.clientWidth;

          // Half of the client view, this is center for the user.
          const halfClientView = clientWidth / 2;

          // Half of the node width, this is because the XPosition is based on the first left pixel of the node and we need to calculate where its center is.
          const halfNodeWidth = node.w / 2;

          // Calculate the new scroll position.
          const newScrollXPosition = node.XPosition - (halfClientView - halfNodeWidth);

          // Scroll to the new position.
          dragScrollItem.scrollTo(newScrollXPosition, node.YPosition);
        }
      }
    });
  }

  //#endregion
  //#region Data Access & Subscriptions
  // API interactions, NGRX selects, etc.

  //#endregion
  //#region Setters, Updaters and Preloaders
  // Setter functions, and methods called from the Instantiation region.

  public updateTree() {
    this.tree.UpdateTree();
    this.updateNodeLinkingLines(this.tree);
  }

  public updateDropList(node: ECONode<Position>, dropList: CdkDropList) {
    // console.log('\nupdateDropList > node:', node);
    // console.log('updateDropList > dropList:', dropList);
    setTimeout(() => {
      this.dropListTracker[node.id] = dropList;
      this.dropLists = Object.values(this.dropListTracker);
      // console.log('updateDropList > this.dropListTracker:', this.dropListTracker);
    });
  }

  /**
   * Every time an item is moved between containers, we need to recalculate the new node height.
   */
  private updateNodeDimensions() {
    this.tree.nDatabaseNodes.forEach((node: ECONode<Position>, i) => {
      if (node.data) {

        const userDetailsArr = node.data.userDetailsArray;
        const firstEntry = userDetailsArr[0] || undefined;

        const firstLine = (node?.id || '') + (firstEntry ? firstEntry.name : '');
        const secondLine = '';// firstEntry ? firstEntry.primaryPositionSecurityGroupsForUser[0]?.name || '' : '';
        const thirdLine = ''; // node.data?.user?.hat?.name || '';
        let fourthLine = '';
        let fifthLineHeight = 0;
        if (userDetailsArr && userDetailsArr.length > 0)
          userDetailsArr.forEach(user => { fourthLine += user?.name ? user.name + ' ' : ''; });

        // if ((<any>node.data)?.heldFromAboveUserName) {
        //   fifthLine = (<any>node.data).heldFromAboveUserName;
        // }

        if ((<any>node.data)?.heldFromAboveUid) {
          fifthLineHeight = 20; // 20px is the first span to say "HFA:".
          // Fifth line should be (32px + 0.5rem margin) x the length of heldFromAboveUid.split(',').length.
          fifthLineHeight += (((<any>node.data).heldFromAboveUid?.split(',') || []).length * 40);
        }


        // Calculate height based on the amount of text in the node.data.
        // const characterLength = firstLine.length + secondLine.length + thirdLine.length + fourthLine.length + fifthLine.length;
        const characterLength = firstLine.length + secondLine.length + thirdLine.length + fourthLine.length;
        const calculatedHeight = 150 + (40 * Math.ceil(characterLength / 25)) + fifthLineHeight;

        // Update the node's height.
        this.tree.nDatabaseNodes[i].h = calculatedHeight
      }
    });

    setTimeout(() => {
      this.updateTree();
    });
  }

  /**
   * Updates the linking lines between nodes.
   * This is must be called if the colours are changed or if the nodes are moved.
   */
  public updateNodeLinkingLines(tree: ECOTree) {
    // console.log('updateNodeLinkingLines > tree:', tree);
    // Reset the children link paths.
    this.childrenLinkPaths = [];
    // Update the children link paths.
    tree.nDatabaseNodes.forEach((node: ECONode<any>) => {
      if (node.isCollapsed) return;
      const childrenLinks = node._drawChildrenLinks(tree);

      childrenLinks.forEach((path: string, i: number) => {
        const entry: any = {};
        entry.stroke = (node.nodeChildren[i].isSelected ? (node.linkColor || null) : null);
        entry.d = (!node._isAncestorCollapsed() ? path : null);
        this.childrenLinkPaths.push(entry);
      });
    });
  }

  public updateOverFlowBehaviour(allowOverflow: boolean) {
    if (allowOverflow) {
      this.toggleDragNavOverFlowClass(true);
    } else {
      this.toggleDragNavOverFlowClass(false);
    }
  }

  private loadTreeTheNewWay(ecoTree: ECOTree) {

    this.userDropDataMap = {}; // Reset drop list data map.
    for (let i = 0; i < ecoTree.nDatabaseNodes.length; i++) {
      const ecoNode: ECONode<Position> = ecoTree.nDatabaseNodes[i];
      // Set the user drop data map for each node. This must be done this way to ensure that the memory (heap) reference is correct.
      this.userDropDataMap[ecoNode.id] = ecoNode.data?.userDetailsArray || [];
    }

    this.tree = ecoTree;
    this.updateHeldFromAboveMapping();

    // this.updateNodeLinkingLines(this.tree);
    // Update the node sizes - nodes that are HFA must be larger.
    // this.updateNodeDimensions();
  }

  private updateHeldFromAboveMapping() {
    for (let i = 0; i < this.tree.nDatabaseNodes.length; i++) {
      const ecoNode: ECONode<Position> = this.tree.nDatabaseNodes[i];
      // Set the user drop data map for each node. This must be done this way to ensure that the memory (heap) reference is correct.
      // this.userDropDataMap[ecoNode.id] = ecoNode.data?.userDetailsArray || [];
      // If no data or ID is -1, skip.
      if (!ecoNode.data || ecoNode.id === -1) continue;

      if (ecoNode.data && (ecoNode.data as any).heldFromAboveUid) {
        (ecoNode.data as any).heldFromAboveUid = undefined;
        (ecoNode.data as any).heldFromAboveUserName = undefined;
      }

      if (!ecoNode.data.userDetailsArray || ecoNode.data.userDetailsArray.length === 0) {
        const parentFamilyTree = this.getParentAndGrandParents(ecoNode);

        let hfaUIDs: string[] = [];
        let hfaNames: string[] = [];
        let depthOfHFA = -1;

        for (let j = 0; j < parentFamilyTree.length; j++) {
          const p = parentFamilyTree[j];

          if (!~p.id) continue; // If ID is -1, skip.

          if (~depthOfHFA && p.data.depth !== depthOfHFA) break; // If the depth of the HFA is found, and the current node is not at the same depth, break. This is to prevent going up the tree too far.

          if (!p.data.userDetailsArray || p.data.userDetailsArray.length === 0) continue; // Continue if no users in the node/on the position.


          // Collect UIDs and names of the HFA. This may be an array, since a org position can have multiple users.
          hfaUIDs = hfaUIDs.concat(p.data.userDetailsArray.map((x: any) => x.key)).flat();
          hfaNames.push(p.data.userDetailsArray.map((x: any) => x.name).join(', '));

          // Where the HFA is found, set the depth of the HFA. This will allow us to break out of the loop if the parents who are HFA are no longer Siblings.
          if (depthOfHFA === -1 && ~p.data.depth) depthOfHFA = p.data.depth;
        }

        if (hfaUIDs.length > 0) {
          this.tree.setNodeData(ecoNode.id, 'heldFromAboveUid', hfaUIDs.join(','), true);
          this.tree.setNodeData(ecoNode.id, 'heldFromAboveUserName', hfaNames.join(', ') as string, true);
        }


      } else {
        // No HFA if they have users in the node.
        continue;
      }

    }
    this.updateNodeLinkingLines(this.tree);
    this.updateNodeDimensions();

  }

  //#endregion
  //#region Getters and Filters
  // Getter functions and filter methods/functions.

  public getSiblingNodes(node: ECONode<Position>) {
    return [...this.getParentAndGrandParents(node), ...this.getChildren(node)];
  }

  /**
   * Recursively gets all parents of the node, and each of the parent's parents.
   */
  private getParentAndGrandParents(node: ECONode<any>, nodes: ECONode<any>[] = []) {
    if (node.nodeParent) {
      nodes = [...nodes, node.nodeParent];
      nodes = this.getParentAndGrandParents(node.nodeParent, nodes);
    }
    return nodes;
  }

  private getChildren(node: ECONode<any>, nodes: ECONode<any>[] = []) {
    const children = node.nodeChildren;
    if (children && children.length) {
      nodes = [...nodes, ...children];
      children.forEach((x) => {
        nodes = this.getChildren(x, nodes);
      });
    }
    return nodes;
  }

  //#endregion
  //#region Change Detection
  // Deep config comparisons, change log generation, hasChangesFn, etc.

  public ngOnChanges(changes: SimpleChanges): void {
    console.log('changes', changes);

    if (changes['organization']) {
      // console.log('organization', changes['organization']);
      if (!this._organization) {
        this._organization = changes['organization'].currentValue;
        this.dialogService.organization = this._organization;

        if (this._organization?.key !== undefined) {

          const orgTree = this.treeBuilder.buildOrg(this._organization, this.orbBoardId);
          console.log('orgTree:', orgTree);
          const ecoTree = new ECOTree();
          this.treeBuilder.insertPositionsToEcoTree(orgTree, ecoTree);

          this.loadTreeTheNewWay(ecoTree);

          // Select the first node in the tree.
          if (this.tree?.nDatabaseNodes?.length > 0) {
            this.selectSiblingNodes(this.tree.nDatabaseNodes[0]);
          }

          if (this._organization.key !== this.previousOrgKey) this.scrollToFirstNode();

          this.showLoader = false;
          this.previousOrgKey = this._organization.key;

          this.obService.organization = this._organization;

          const userKeys = this._organization.orgUsers.userKeys;
          this.userOptions = userKeys.map(key => {
            const user = this._organization.orgUsers[key];
            return { display: user.name, value: user.key };
          });

          const policyKeys = this._organization.orgPolicies.policiesKeys;
          this.policyOptions = policyKeys.map(key => {
            const policy = this._organization.orgPolicies[key];
            return { display: policy.name, value: policy.key };
          });

          const securityGroupKeys = this._organization.orgSecurityGroups.securityGroupsKeys;
          this.securityGroupOptions = securityGroupKeys.map(key => {
            const securityGroup = this._organization.orgSecurityGroups[key];
            return { display: securityGroup.name, value: securityGroup.key };
          });

          const hatKeys = this._organization.orgHats.hatsKeys;
          this.hatOptions = hatKeys.map(key => {
            const hat = this._organization.orgHats[key];
            return { display: hat.name, value: hat.key };
          });
        }

      } else {
        // TODO - write comparison logic.
        // Should look at the values from the sub-collections for changes that are acceptable.
        // If the changes are acceptable, update those parts of the _organization as long as they don't conflict with the user's changes.
        // If the changes are not acceptable, then don't update the _organization with the new changes.
        //
        // Sub-Collection fields:
        // - orgUsers
        // - orgHats
        // - orgFeatures?
        // - orgPermissions?
        // - orgParts?


      }
    }
  }

  //#endregion    
  //#region Parsing and Validation
  // Transformation/mapping methods
  // Methods returning true/false: isCurrent, areFeedsEqual, shouldProcess, etc.

  public determineLayout(users?: any[]) {
    if (!users) {
      return 'NO_USERS'
    } if (users.length === 0) {
      return 'NO_USERS'
    } else if (users.length === 1) {
      return 'ONE_USER'
    } else {
      return 'MULTIPLE_USERS'
    }
  }


  //#endregion
  //#region Response Handlers
  // API/NGRX callback handlers.


  //#endregion
  //#region Navigation
  // Confirmation close dialogs, router guards, etc.

  //#endregion
  //#region Post Instantiation
  // Post-init fetch requests, used in such as landing page, after view init, etc.

  //#endregion

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}

export interface Section {
  name: string;
  updated: Date;
}


enum ToolbarOptionsEnum {
  none = 'none',
  users = 'users',
  hats = 'hats',
  policies = 'policies',
  security_groups = 'security-groups',
  new_user = 'new-user',
  new_hat = 'new-hat',
  new_policy = 'new-policy'
}