<div class="sidebar"
    data-cy='side-bar'
    [@subNavAnimation]="subNavSidebarState"
    [ngClass]="{
        'mobile-display': isSmallDisplay,
        'expand-on-over': expandOnHover,
        'collapsed': subNavSidebarState === 'collapsed', 'expanded': subNavSidebarState === 'expanded'
    }"
    (mouseenter)="onMouseEnterSidebar()"
    (mouseleave)="onMouseLeaveSidebar()"
>

    <!-- Logo/Navigation section -->
    <div class="nav-header clickable">
        {{configuration.header[0]}}<span [ngClass]="{'collapsed': !isHoveringSideBar && subNavSidebarState === 'collapsed'}">{{configuration.header.slice(1)}}</span>
    </div>

    <!-- Top-tier menu items. -->
    <section *ngIf="configuration.topTierMenuItems" class="nav-items">

        <div *ngFor="let topMenuItem of configuration.topTierMenuItems"
                [attr.data-cy]="topMenuItem.id"
                [cdkDropListConnectedTo]="allFolders"
                [id]="topMenuItem.id"
                [ngClass]="{'highlighted': topMenuItem.isHighlighted}"
                cdkDropList
                class="menu-item"
                (cdkDropListDropped)="drop($event,topMenuItem)"
            >

            <div class="menu-item-contents clickable" (click)="browseTo(topMenuItem.section, topMenuItem.path); highlightNav(topMenuItem)">
                
                <img *ngIf="topMenuItem.svg; else matIcon" 
                    [attr.src]="'/assets/icons/' + topMenuItem.svg"
                    [ngClass]="{'mr-3': subNavSidebarState === 'expanded' || isHoveringSideBar}"
                    class="filter-svg-colour svg-icon"
                />
                <ng-template #matIcon>
                    <mat-icon [ngClass]="{'mr-3': subNavSidebarState === 'expanded' || isHoveringSideBar}" class="nav-mat-icon">{{topMenuItem.icon}}</mat-icon>
                </ng-template>

                <div class="menu-item-text" [ngClass]="{
                    'collapsed': !isHoveringSideBar && subNavSidebarState === 'collapsed',
                    'expanded': isHoveringSideBar || subNavSidebarState === 'expanded'
                }">
                    <span [innerText]="topMenuItem.name"></span><span *ngIf="topMenuItem.showCount" class="absolute-right">{{topMenuItem.count}}</span>
                </div>
            </div>

            <div *cdkDragPlaceholder></div>

        </div>
    </section>

    <!-- Middle-tier menu items. -->
    <section *ngIf="configuration.middleTierMenuItems" class="nav-items">

        <div *ngFor="let middleMenuItem of configuration.middleTierMenuItems"
                [attr.data-cy]="middleMenuItem.id"
                [cdkDropListConnectedTo]="allFolders"
                [id]="middleMenuItem.id"
                [ngClass]="{'highlighted': middleMenuItem.isHighlighted}"
                cdkDropList
                class="menu-item"
                (cdkDropListDropped)="drop($event,middleMenuItem)"
            >

            <div class="menu-item-contents clickable" (click)="browseTo(middleMenuItem.section, middleMenuItem.path); highlightNav(middleMenuItem)">
                
                <img *ngIf="middleMenuItem.svg; else matIcon" 
                    [attr.src]="'/assets/icons/' + middleMenuItem.svg"
                    [ngClass]="{'mr-3': subNavSidebarState === 'expanded' || isHoveringSideBar}"
                    class="filter-svg-colour svg-icon"
                />
                <ng-template #matIcon>
                    <mat-icon [ngClass]="{'mr-3': subNavSidebarState === 'expanded' || isHoveringSideBar}" class="nav-mat-icon">{{middleMenuItem.icon}}</mat-icon>
                </ng-template>

                <div class="menu-item-text" [ngClass]="{
                    'collapsed': !isHoveringSideBar && subNavSidebarState === 'collapsed', 
                    'expanded': isHoveringSideBar || subNavSidebarState === 'expanded'
                }">
                    <span [innerText]="middleMenuItem.name"></span><span *ngIf="middleMenuItem.showCount" class="absolute-right">{{middleMenuItem.count}}</span>
                </div>
            </div>

            <div *cdkDragPlaceholder></div>

        </div>
    </section>

    <!-- Folders -->
    <section *ngIf="configuration.showFolders" class="nav-items mt-3">

        <div class="menu-item" data-cy="side-menu-particle-folders">
            <div class="menu-item-contents zero-right-padding">
                <mat-icon [ngClass]="{'mr-3': isHoveringSideBar || subNavSidebarState === 'expanded'}" class="nav-mat-icon">folder</mat-icon>

                <div class="menu-item-text folders-group" [ngClass]="{
                    'collapsed': !isHoveringSideBar && subNavSidebarState === 'collapsed',
                    'expanded': isHoveringSideBar || subNavSidebarState === 'expanded'
                }">
                    <span>folders</span>
                    <button mat-icon-button [ngClass]="{'hide': !isHoveringSideBar && subNavSidebarState === 'collapsed'}" (click)="addNewFolder()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
            </div>

        </div>

        <div *ngFor="let customFolder of customFolders"
                [attr.data-cy]="customFolder.name"
                [cdkDropListConnectedTo]="allFolders"
                [cdkDropListData]="customFolder.particles"
                [id]="customFolder.id"
                [ngClass]="{'highlighted': customFolder.isHighlighted}"
                cdkDropList
                class="menu-item"
                (cdkDropListDropped)="drop($event,customFolder)"
                (contextmenu)="onRightClick($event, menuTrigger);"
            >

            <div class="menu-item-contents clickable no-icon zero-right-padding" (click)="browseTo('workspace', customFolder.name); highlightNav(customFolder)">
                
                <div class="menu-item-text folder-name" [ngClass]="subNavSidebarState">
                    <span>{{customFolder.name[0]}}</span><span [ngClass]="{'collapsed': !isHoveringSideBar && subNavSidebarState === 'collapsed'}">{{customFolder.name.slice(1)}}</span>
                </div>

                <button 
                        #menuTrigger="matMenuTrigger" 
                        [attr.data-cy]="customFolder.name + '-options'"
                        [matMenuTriggerFor]="menu"
                        [ngClass]="{'collapsed': !isHoveringSideBar && subNavSidebarState === 'collapsed'}"
                        aria-label="Example icon-button with a menu"
                        class="folder-menu-btn"
                        mat-icon-button 
                        (click)="$event.stopPropagation();"
                        >
                    <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #menu="matMenu" #menuRef>
                    <button mat-menu-item (click)="updateFolder(customFolder)" [attr.data-cy]="'rename-folder-' + customFolder.name">
                        <mat-icon>drive_file_rename_outline</mat-icon>
                        <span>Rename Folder</span>
                    </button>
                    <button mat-menu-item (click)="openSelectFolderOrderModal(customFolder)" [attr.data-cy]="'sort-folder-' + customFolder.name">
                        <mat-icon>sort</mat-icon>
                        <span>Sort Folder</span>
                    </button>
                    <button mat-menu-item (click)="deleteFolder(customFolder)" [attr.data-cy]="'delete-folder-' + customFolder.name">
                        <mat-icon>delete</mat-icon>
                        <span>Delete Folder</span>
                    </button>
                </mat-menu>
            </div>

            <div *cdkDragPlaceholder></div>

        </div>
    </section>

    <section class="empty-toggle-region" (click)="toggleExpand()">
        <button mat-icon-button data-cy="sidebar-toggle" type="button" class="expand-btn">
            <mat-icon>{{subNavSidebarState === 'expanded' ? 'arrow_back_ios' : 'arrow_forward_ios'}}</mat-icon>
        </button>
    </section>
</div>

<!-- Backdrop for mobile display when touching the menu open. -->
<div *ngIf="isSmallDisplay && subNavSidebarState === 'expanded'" class="backdrop" (click)="toggleExpand()"></div>