import {createFeatureSelector, createSelector} from '@ngrx/store';
import { WorkspaceState } from '../reducers/workspace.reducers';
import * as fromWorkspace from '../reducers/workspace.reducers';

export const selectWorkspaceState =
    createFeatureSelector<WorkspaceState>("workspace");

export const selectWorkspace = createSelector(
    selectWorkspaceState,
    fromWorkspace.selectAll
);

export const isWorkspaceLoaded = createSelector(
    selectWorkspaceState,
    (state) => { 
        return state.allWorkspaceLoaded
    }
);

